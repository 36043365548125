import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useUpdateEffect } from "react-use";

import { useSelectedClips } from "@/context/ReviewClips";
import clsx from "clsx";
import { fabric } from "fabric";
import { Spinner } from "flowbite-react";
import JSZip from "jszip";
import { nanoid } from "nanoid";
import parseSRT from "parse-srt";

import { useAppSelector } from "@/store/hooks";

import { uploadFileToS3 } from "@/api/requests";
import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetUserTemplates from "@/api/useGetUserTemplates";

import {
  ID_TITLE_TEXT,
  SUBTITLE_ASSET_PREFIX,
  SUBTITLE_EMOJI_PREFIX,
  TEXT_ASSET_PREFIX,
} from "@/constants";

import {
  GroupedTextProps,
  addSubtitleStyles,
  calculateScaleUpSizeFromBaseTemplate,
  centerFaceInClipPath,
  changeStageUrlToProd,
  generateTextFabricObj,
  getAudioDataForRender,
  getClipPath,
  getCurrentSelectedTemplate,
  getFabricVideo,
  getIsIntelliClip,
  getLogoDetails,
  getNewFabricStaticCanvas,
  getOutputDimensions,
  getProgressBar,
  getProgressBarDetails,
  getRandomStartAndEndIndexToColor,
  getScaledPropertiesForText,
  getSubsPngImages,
  getTemplatesForLayout,
  getTextbox,
  getTransformedTextboxStyles,
  getVideoCoordsDetails,
  getWordLengthWithEmoji,
  getWordsArrayWithEmojis,
  handleAddAudioClipsToCanvas,
  hasMoreThanKeys,
  loadFont,
  updateGroupedText,
  updateStylesWithNewProperties,
} from "@/helpers";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { TextboxStyles } from "@/interfaces";

import {
  ImageType,
  SubtitleStyle,
  TextAlignment,
  TextElementType,
  VideoLayout,
} from "@/enums";

import VideoJS from "@/components/VideoJs";

import {
  DEFAULT_OUTRO_LENGTH_IN_SEC,
  INIT_SUBS_CONFIG,
  INIT_TEXT_STYLES,
  MIN_LINE_HEIGHT,
  MIN_THRESHOLD_BETWEEN_SUBTITLES,
  SAFE_PADDING_FOR_TEXT,
  TEXT_TRANSFORM,
} from "@/views/editor/constant";

const BACKGROUND_CLIP_PATH = "backGroundClipPath";
const ASSET_ZIP_UPLOAD_ERROR = "Error uploading assets zip to s3";

const editorDimensions: {
  [key in VideoLayout]: { width: number; height: number };
} = {
  [VideoLayout.LAYOUT_16_9]: {
    width: 355,
    height: 200,
  },
  [VideoLayout.LAYOUT_1_1]: {
    width: 250,
    height: 250,
  },
  [VideoLayout.LAYOUT_9_16_1]: {
    width: 169,
    height: 300,
  },
  [VideoLayout.LAYOUT_9_16_2]: {
    width: 169,
    height: 300,
  },
};

const ClipPreviewEditor = () => {
  const {
    multipleRenderModeOn,
    selectedClips,
    toggleMultipleRenderMode,
    updateRenderVideoPayloadForMultipleClips,
    selectedTemplatesId,
    togglePreviewClipEditorLoaded,
    isPreviewClipEditorLoaded,
  } = useSelectedClips();

  const [textsObj, setTextsObj] = useState<any>({});

  const currentSelectedProject = useAppSelector(
    (state) => state.homeState.currentSelectedProject
  );

  const selectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );

  const [currentSelectedMicroContent, setCurrentSelectedMicroContent] =
    useState<any>({
      ...Array.from(selectedClips)[0][1],
      id: selectedTemplatesId[selectedLayout],
    });

  const userSocialMediaHandles = useAppSelector(
    (state) => state.homeState.userSocialMediaHandles
  );

  const { uid } = useAppSelector((state) => state.authState.currentUser);

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [subsArr, setSubsArr] = useState<any>([]);
  const [currentOutro, setCurrentOutro] = useState<any>(null);
  const [outroTimeLeft, setOutroTimeLeft] = useState(0);
  const [currentVideoTime, setCurrentVideoTime] = useState<number>(0);
  const [audioElements, setAudioElements] = useState<any>({});

  const [subsConfig, setSubsConfig] = useState<any>(INIT_SUBS_CONFIG);
  const fabricRef = useRef<any>(null);
  const canvasObjects = useRef<any>({});
  const progressBarRef = useRef<any>(null);
  const textFabricObj = useRef<any>({});
  const textsObjRef = useRef<any>(textsObj);
  const videoElRef = useRef<any>();
  const subsArrRef = useRef<any>();
  const subtitleTextRef = useRef<any>(null);
  const logoRef = useRef<any>({});
  const activeSubtitleIndexRef = useRef<number>(0);

  const userTemplatesData = useGetUserTemplates(uid);
  const defaultBaseTemplates = useGetDefaultBaseTemplates();

  const EDITOR_WIDTH: number =
    selectedLayout && editorDimensions[selectedLayout].width;
  const EDITOR_HEIGHT: number =
    selectedLayout && editorDimensions[selectedLayout].height;

  const getVideoUrl = useMemo(() => {
    if (
      currentSelectedProject &&
      currentSelectedProject.data &&
      !userTemplatesData.isLoading &&
      !defaultBaseTemplates.isLoading
    ) {
      return (
        JSON.parse(currentSelectedProject.data).remote_url +
        `#t=${currentSelectedMicroContent?.start / 1000}` +
        `?v=` +
        new Date().getTime()
      );
    }
    return "";
  }, [currentSelectedMicroContent?.start]);

  function handelProgressBarAnimation(
    timeStamp: number,
    isFullWidth?: boolean
  ) {
    const totalVideoLength =
      currentSelectedMicroContent?.end / 1000 -
        currentSelectedMicroContent?.start / 1000 ?? 0;
    const pxPerSec = progressBarRef?.current?.maxWidth / totalVideoLength;

    const val = progressBarRef?.current;
    if (val) {
      if (isFullWidth) {
        val.set("width", progressBarRef?.current?.maxWidth);
      } else {
        val.set(
          "width",
          pxPerSec * (timeStamp - currentSelectedMicroContent?.start / 1000)
        );
      }
      val.set("left", 0);
      val.set("dirty", true);
      progressBarRef.current = val;

      canvasObjects.current.progressBarRect.setCoords();
    }
  }

  const handlePlayPause = () => {
    if (outroTimeLeft === 0) {
      if (videoElRef.current.paused && !isVideoPlaying) {
        videoElRef.current
          .play()
          .then(() => {
            setIsVideoPlaying(!isVideoPlaying);
          })
          .catch((error: any) => {
            console.log("handlePlayPause error", error);
          });
      }
      if (!videoElRef.current.paused && isVideoPlaying) {
        setIsVideoPlaying(false);
        videoElRef.current.pause();
      }
    }
  };

  const handelAddSocialAssets = ({
    url,
    position,
    uid,
    logoWidth,
  }: {
    url: string;
    position: any;
    uid: string;
    logoWidth?: number;
  }) => {
    fabric.Image.fromURL(url, (img: any) => {
      if (fabricRef.current) {
        img.scaleToWidth(logoWidth ? logoWidth : 20).set({
          left: position.left,
          top: position.top,
          id: uid,
        });

        fabricRef.current.add(img);
        logoRef.current[uid] = {
          asset: img,
          url,
          uid,
        };
      }
    });
  };

  const addSocialTemplatesToCanvas = ({
    logoDetails,
    textDetails,
  }: {
    logoDetails: any;
    textDetails: any;
  }) => {
    handelAddSocialAssets({
      url: logoDetails.url,
      position: { top: logoDetails.top, left: logoDetails.left },
      uid: logoDetails.id,
      logoWidth: logoDetails.width,
    });
    if (textDetails) {
      addTextObject({
        initPosition: { top: textDetails.top, left: textDetails.left },
        ...textDetails,
        Id: `${logoDetails.id}_text`,
        isSocialText: true,
      });
    }
  };

  const setSubsInTemplates = (
    currentTemplate: any,
    templateScaleUpRatio: number
  ) => {
    const jsonSubs = parseSRT(currentSelectedMicroContent?.srt_string);
    let subs = jsonSubs.map((sub: any) => {
      return {
        ...sub,
        text: sub.text,
      };
    });
    setSubsArr(subs);
    const subtitleStyle = currentTemplate.subtitle.style
      ? currentTemplate.subtitle.style
      : INIT_SUBS_CONFIG.style;
    setSubsConfig({
      ...subsConfig,
      style: subtitleStyle,
      top: currentTemplate.subtitle.top * templateScaleUpRatio,
      left: currentTemplate.subtitle.left * templateScaleUpRatio,
      width: currentTemplate.subtitle.width * templateScaleUpRatio,
      font_size: currentTemplate.subtitle.fontSize * templateScaleUpRatio,
      font_color: currentTemplate.subtitle.fontColor,
      font_face: currentTemplate.subtitle.fontFamily,
      underline: currentTemplate.subtitle.underline,
      alignment: currentTemplate.subtitle.textAlign,
      effect_type: currentTemplate.subtitle.effect_type,
      effect_color: currentTemplate.subtitle.effect_color,
      bold: currentTemplate.subtitle.bold,
      italic: currentTemplate.subtitle.italic,
      maxHeight: currentTemplate.subtitle.maxHeight * templateScaleUpRatio,
      line_height: currentTemplate.subtitle.lineHeight || MIN_LINE_HEIGHT,
      noEffect: currentTemplate.subtitle.noEffect,
      shadow: currentTemplate.subtitle.shadow,
      stroke: currentTemplate.subtitle.stroke,
      textBgColor: currentTemplate.subtitle.textBgColor,
      blockBackground: currentTemplate.subtitle.blockBackground,
      textTransform: currentTemplate.subtitle?.textTransform,
      padding: currentTemplate.subtitle?.padding || INIT_SUBS_CONFIG.padding,
      margin: currentTemplate.subtitle?.margin || INIT_TEXT_STYLES.margin,
    });
  };

  const addProgressbarInLayout = ({
    currentTemplate,
    templateScaleUpRatio,
  }: any) => {
    if (currentTemplate.progressbar) {
      const progressBarRect = getProgressBar({
        height: currentTemplate.progressbar.height,
        top: currentTemplate.progressbar.top,
        left: currentTemplate.progressbar.left,
        maxWidth: currentTemplate.width,
        fill: currentTemplate.progressbar.fill,
        scaleRatio: templateScaleUpRatio,
      });
      progressBarRef.current = progressBarRect;
      fabricRef.current?.add(progressBarRect);
      fabricRef.current?.bringToFront(progressBarRect);
      canvasObjects.current.progressBarRect = progressBarRect;
    }
  };

  const reorganizeCanvasObjects = () => {
    if (fabricRef.current) {
      if (subtitleTextRef.current) {
        fabricRef.current.bringToFront(subtitleTextRef.current);
      }
      Object.values(textFabricObj.current).forEach((obj) => {
        fabricRef.current.bringToFront(obj);
      });
      Object.values(logoRef.current).forEach((obj: any) => {
        fabricRef.current.bringToFront(obj.asset);
      });

      fabricRef.current?.requestRenderAll();
    }
  };

  const handelAddUserUploadedImages = ({
    url,
    position,
    uid,
    logoWidth,
    metaData,
  }: {
    url: string;
    position: any;
    uid: string;
    logoWidth?: number;
    metaData?: any;
  }) => {
    fabric.Image.fromURL(url, (img: any) => {
      img.scaleToWidth(logoWidth ? logoWidth : 100).set({
        left: position.left,
        top: position.top,
        id: uid,
        type: ImageType.USER_UPLOADED,
      });

      fabricRef.current?.add(img);

      if (metaData?.isTemplate) {
        reorganizeCanvasObjects();
      }

      logoRef.current[uid] = {
        asset: img,
        url,
        uid,
      };
    });
  };

  const addTemplateTextAndSocialsInLayout = ({
    currentTemplate,
    backGroundClipPath,
    templateScaleUpRatio,
  }: any) => {
    if (currentTemplate.socials.length) {
      currentTemplate.socials.forEach((social: any) => {
        // updating the social content with user preference
        userSocialMediaHandles.forEach((socialHandel: any) => {
          if (socialHandel.id === social.image.id) {
            let updatedSocial = { ...social };
            updatedSocial = {
              ...updatedSocial,
              text: {
                ...updatedSocial.text,
                content: socialHandel.socialMediaHandle || social.text.content,
              },
            };
            social = updatedSocial;
          }
        });

        const socialLogoDetails = {
          top: social.image.top * templateScaleUpRatio + backGroundClipPath.top,
          left:
            social.image.left * templateScaleUpRatio + backGroundClipPath.left,
          id: social.image.id,
          url: social.image.url,
          width: social.image.width * templateScaleUpRatio,
        };
        let socialTextDetails: any = undefined;

        if (social.text) {
          socialTextDetails = {
            top:
              social.text.top * templateScaleUpRatio + backGroundClipPath.top,
            left:
              social.text.left * templateScaleUpRatio + backGroundClipPath.left,
            textContent: social.text.content,
            textSize: social.text.fontSize * templateScaleUpRatio,
            fontFace: social.text.fontFamily,
            effectType: social.text.effect_type,
            Id: `${social.image.id}_text`,
            textAlignment: social.text.textAlign,
            textBold: social.text.bold,
            textBoxWidth: social.text.width
              ? social.text.width * templateScaleUpRatio
              : backGroundClipPath.width -
                social.text.left * templateScaleUpRatio,
            lineHeight: social.text.lineHeight || MIN_LINE_HEIGHT,
            textTransform: social.text?.textTransform,
            margin: social.text?.margin || INIT_TEXT_STYLES.margin,
            padding: social.text?.padding || INIT_TEXT_STYLES.padding,
          };
        }

        addSocialTemplatesToCanvas({
          logoDetails: socialLogoDetails,
          textDetails: socialTextDetails,
        });
      });
    }

    currentTemplate.texts.forEach((text: any, index: number) => {
      addTextObject({
        initPosition: {
          left: text.left * templateScaleUpRatio,
          top: text.top * templateScaleUpRatio,
        },
        textSize: text.fontSize * templateScaleUpRatio,
        textAlign: text.textAlign,
        fontFace: text.fontFamily,
        textContent: text.content || text.text,
        textBoxWidth: text.width
          ? text.width * templateScaleUpRatio
          : backGroundClipPath.width,
        effectColor: text.effect_color,
        effectType: text.effect_type,
        textBold: text.bold,
        textItalic: text.italic,
        textUnderLine: text.underline,
        fontColor: text.fill,
        maxHeight: text.maxHeight * templateScaleUpRatio,
        lineHeight: text.lineHeight || MIN_LINE_HEIGHT,
        noEffect: text.noEffect,
        shadow: text.shadow,
        stroke: text.stroke,
        Id: index === 0 ? ID_TITLE_TEXT : null,
        textBgColor: text.textBgColor,
        blockBackground: text.blockBackground,
        hasNoEffectKey: text.hasOwnProperty("noEffect"),
        textTransform: text?.textTransform,
        margin: text?.margin || INIT_TEXT_STYLES.margin,
        padding: text?.padding || INIT_TEXT_STYLES.padding,
      });
    });
  };

  const handelAddUserUploadedImagesToCanvas = ({
    currentTemplate,
    backGroundClipPath,
    templateScaleUpRatio,
  }: any) => {
    currentTemplate?.bRolls?.forEach((image: any) => {
      const userUploadedImages = {
        top: image.top * templateScaleUpRatio + backGroundClipPath.top,
        left: image.left * templateScaleUpRatio + backGroundClipPath.left,
        id: image.id,
        url: image.url,
        width: image.width * templateScaleUpRatio,
      };

      handelAddUserUploadedImages({
        url: userUploadedImages.url,
        position: {
          top: userUploadedImages.top,
          left: userUploadedImages.left,
        },
        uid: userUploadedImages.id,
        logoWidth: userUploadedImages.width,
        metaData: { isTemplate: true },
      });
    });

    currentTemplate?.logos?.forEach((image: any) => {
      const userUploadedImages = {
        top: image.top * templateScaleUpRatio + backGroundClipPath.top,
        left: image.left * templateScaleUpRatio + backGroundClipPath.left,
        id: image.id,
        url: image.url,
        width: image.width * templateScaleUpRatio,
      };

      handelAddUserUploadedImages({
        url: userUploadedImages.url,
        position: {
          top: userUploadedImages.top,
          left: userUploadedImages.left,
        },
        uid: userUploadedImages.id,
        logoWidth: userUploadedImages.width,
      });
    });

    // for older templates
    currentTemplate?.images?.forEach((image: any) => {
      const userUploadedImages = {
        top: image.top * templateScaleUpRatio + backGroundClipPath.top,
        left: image.left * templateScaleUpRatio + backGroundClipPath.left,
        id: image.id,
        url: image.url,
        width: image.width * templateScaleUpRatio,
      };

      handelAddUserUploadedImages({
        url: userUploadedImages.url,
        position: {
          top: userUploadedImages.top,
          left: userUploadedImages.left,
        },
        uid: userUploadedImages.id,
        logoWidth: userUploadedImages.width,
      });
    });
  };

  const handelAddBackgroundImageToEditor = (
    currentTemplate: any,
    addVideoProps: any,
    backGroundClipPath?: any
  ) => {
    const img = document.createElement("img");
    img.src = currentTemplate.backgroundImageUrl;

    img.onload = function () {
      const bgImg = new fabric.Image(img, {
        left: 0,
        top: 0,
        clipPath: backGroundClipPath,
        selectable: false,
      });

      bgImg.scaleY = EDITOR_HEIGHT / img.height;
      bgImg.scaleX = EDITOR_WIDTH / img.width;
      bgImg.dirty = true;

      if (fabricRef.current) {
        fabricRef.current.bringToFront(bgImg);
        canvasObjects.current.bgImgUrl = currentTemplate.backgroundImageUrl;
        fabricRef.current.renderAll();
      }

      if (addVideoProps) {
        addVideoProps();
      }
    };
  };

  const addTemplateElementToCanvas = ({
    currentTemplate,
    templateScaleUpRatio,
    backGroundClipPath,
  }: {
    currentTemplate: any;
    templateScaleUpRatio: number;
    backGroundClipPath: any;
  }) => {
    addProgressbarInLayout({
      currentTemplate,
      templateScaleUpRatio,
    });

    if (hasMoreThanKeys(currentTemplate.subtitle, 2)) {
      setSubsInTemplates(currentTemplate, templateScaleUpRatio);
    } else {
      setSubsArr([]);
    }
    addTemplateTextAndSocialsInLayout({
      currentTemplate,
      templateScaleUpRatio,
      backGroundClipPath,
    });

    handelAddUserUploadedImagesToCanvas({
      currentTemplate,
      templateScaleUpRatio,
      backGroundClipPath,
    });
    if (currentTemplate?.audioClips?.length) {
      handleAddAudioClipsToCanvas(
        currentTemplate,
        setAudioElements,
        currentSelectedMicroContent
      );
    }
  };

  const initCanvas = () => {
    if (videoElRef.current) {
      const { videoWidth, videoHeight } = videoElRef.current;
      videoElRef.current.width = videoWidth;
      videoElRef.current.height = videoHeight;
      videoElRef.current.currentTime = currentSelectedMicroContent?.start
        ? currentSelectedMicroContent?.start / 1000
        : 0;
      videoElRef.current.muted = false;
      videoElRef.current.pause();

      initLayout(selectedLayout);

      fabric.util.requestAnimFrame(function render() {
        fabricRef.current?.requestRenderAll();
        fabric.util.requestAnimFrame(render);
      });
    }
  };

  const getTemplateDetails = (layout: VideoLayout) => {
    const userSavedTemplates = getTemplatesForLayout(
      layout,
      userTemplatesData?.data
    );

    const currentTemplate = getCurrentSelectedTemplate(
      layout,
      currentSelectedMicroContent,
      userSavedTemplates,
      defaultBaseTemplates?.data
    );
    setCurrentOutro(currentTemplate?.outro);

    const templateScaleUpRatio = calculateScaleUpSizeFromBaseTemplate(
      currentTemplate.height,
      EDITOR_HEIGHT
    );
    const backGroundClipPath = getClipPath({
      width: EDITOR_WIDTH,
      height: EDITOR_HEIGHT,
      backgroundColor: currentTemplate?.backgroundColor,
      id: BACKGROUND_CLIP_PATH,
    });

    return { currentTemplate, templateScaleUpRatio, backGroundClipPath };
  };

  const getVideoDimensions = () => {
    if (currentSelectedProject && currentSelectedProject.data) {
      return {
        originalVideoDimensions: {
          width: JSON.parse(currentSelectedProject.data).source_width,
          height: JSON.parse(currentSelectedProject.data).source_height,
        },
        currentVideoDimensions: {
          width: videoElRef.current?.videoWidth,
          height: videoElRef.current?.videoHeight,
        },
      };
    }
  };

  const initLayout = (layout: VideoLayout) => {
    clearLayout();

    const { currentTemplate, templateScaleUpRatio, backGroundClipPath } =
      getTemplateDetails(layout);

    fabricRef.current.add(backGroundClipPath);
    canvasObjects.current.backGroundClipPath = backGroundClipPath;

    const setVideoProps = () => {
      const videoProperties = currentTemplate.videoProperties;

      videoProperties.forEach((videoProperty, index) => {
        const clipPathProps = videoProperty.clipPath;
        const clipPath = getClipPath({
          width: clipPathProps.width,
          height: clipPathProps.height,
          top: clipPathProps.top,
          left: clipPathProps.left,
          scaleRatio: templateScaleUpRatio,
        });

        const videoEl = getFabricVideo({
          top: clipPath.top,
          left: clipPath.left,
          videoElement: videoElRef.current,
          clipPath,
          hasControls: false,
        });

        // we are assuming the videoProperties array will always have 1 or 2 elements
        //  so we are hardcoding the index to 0 and 1
        // 0 for top/single video and 1 for down video for 9:16:2 layout

        centerFaceInClipPath({
          fabricVideoElement: videoEl,
          clipPath,
          allFaceCoords: currentSelectedMicroContent.face_coord,
          videoLayout: selectedLayout,
          isRightFace: index === 1,
          videoDimensions: getVideoDimensions(),
        });

        if (index === 0) canvasObjects.current.videoElUp = videoEl;

        if (index === 1) canvasObjects.current.videoElDown = videoEl;

        fabricRef.current?.bringToFront(videoEl);
      });

      addTemplateElementToCanvas({
        currentTemplate,
        templateScaleUpRatio,
        backGroundClipPath,
      });

      reorganizeCanvasObjects();
      handelProgressBarAnimation(currentSelectedMicroContent?.start / 1000);
    };

    if (currentTemplate.backgroundImageUrl) {
      handelAddBackgroundImageToEditor(
        currentTemplate,
        setVideoProps,
        backGroundClipPath
      );
    } else {
      setVideoProps();
    }
  };

  const canvasRef: any = useCallback((element: any) => {
    if (!element) {
      fabricRef.current?.dispose();
      fabricRef.current = null;
      return fabricRef;
    }

    fabricRef.current = getNewFabricStaticCanvas(element);
    fabricRef.current.renderOnAddRemove = true;
    return fabricRef;
  }, []);

  const clearLayout = () => {
    setIsVideoPlaying(false);
    togglePreviewClipEditorLoaded(false);
    //get all fabric objects and delete
    const objects = fabricRef.current.getObjects();
    objects.forEach((object: any) => {
      fabricRef.current.remove(object);
    });

    canvasRef?.current?.clear();
    setTextsObj({});
    textFabricObj.current = {};
    setCurrentOutro(null);
    canvasObjects.current = {};
    subtitleTextRef.current = null;
    progressBarRef.current = null;
    textsObjRef.current = {};
    logoRef.current = {};
  };

  const onTimeUpdate = () => {
    setCurrentVideoTime(videoElRef.current.currentTime);
    if (
      videoElRef.current.currentTime >=
      currentSelectedMicroContent.end / 1000
    ) {
      videoElRef.current.paused || handlePlayPause();
      videoElRef.current.currentTime =
        currentSelectedMicroContent.start / 1000 || 0;

      if (currentOutro) {
        setOutroTimeLeft(DEFAULT_OUTRO_LENGTH_IN_SEC);
        fabricRef.current.bringToFront(canvasObjects.current.outroImg);
        fabricRef.current.requestRenderAll();
      }
    }
  };

  const setSubs = (textObj: any) => {
    if (fabricRef.current) {
      let fabricEl = subtitleTextRef.current;
      const textStyles = getTransformedTextboxStyles(subsConfig);
      const fabricTextProps = {
        left: subsConfig.left,
        top: subsConfig.top,
        width: subsConfig.width || EDITOR_WIDTH - subsConfig.left,
        maxHeight: subsConfig.maxHeight,
        type: TextElementType.SUBTITLE,
        style: textStyles,
        text: textObj.text,
      };

      if (textObj.text) {
        if (!fabricEl) {
          fabricEl = getTextbox(fabricTextProps);
          subtitleTextRef.current = fabricEl;

          loadFont(fabricEl, fabricTextProps.style as GroupedTextProps, () => {
            fabricRef.current?.add(fabricEl);
            fabricRef.current?.requestRenderAll();
            addSubtitleStyles(
              textObj,
              subsConfig,
              fabricEl,
              currentSelectedMicroContent,
              videoElRef.current.currentTime
            );
          });

          fabricRef.current.add(fabricEl);
        } else {
          updateGroupedText(fabricEl, {
            ...fabricTextProps.style,
            text: textObj.text,
          }).then(() => {
            fabricRef.current.requestRenderAll();
          });
        }
        addSubtitleStyles(
          textObj,
          subsConfig,
          fabricEl,
          currentSelectedMicroContent,
          videoElRef.current.currentTime
        );
      }
      if (fabricEl && !textObj.text) {
        updateGroupedText(fabricEl, {
          ...fabricTextProps.style,
          text: "",
        }).then(() => {
          fabricRef.current.requestRenderAll();
        });
      }
    }
  };

  const addTextToFabric = (text: any) => {
    const textStyles = text.style;
    const transformedStyleObj: TextboxStyles =
      getTransformedTextboxStyles(textStyles);
    const textboxProps = {
      id: text.id,
      text: typeof text.content === "string" ? text.content : text.content.text,
      left: textStyles.coordinate_left,
      top: textStyles.coordinate_top,
      width: textStyles.width,
      maxHeight: textStyles.maxHeight,
      style: transformedStyleObj,
      type: text.isSocialText
        ? TextElementType.SOCIAL_HANDLE_TEXT
        : TextElementType.NORMAL_TEXT,
    };
    const fabricText = getTextbox(textboxProps);

    loadFont(
      fabricText,
      textboxProps.style as GroupedTextProps,
      () => {
        fabricText.set("top", textStyles.coordinate_top);

        fabricRef.current?.add(fabricText);
        fabricRef.current?.requestRenderAll();
      },
      true
    );

    textFabricObj.current[text.id] = fabricText;
  };

  const addTextObject = ({
    initPosition,
    textContent,
    textSize = 32,
    textBoxWidth = 0,
    fontColor = "#ffffff",
    fontFace = "Open Sans",
    effectType = "none",
    Id,
    textBold = false,
    isEmpty = false,
    textAlignment = TextAlignment.CENTER,
    effectColor = "#000000",
    textUnderLine = false,
    textItalic = false,
    maxHeight,
    isSocialText = false,
    lineHeight = MIN_LINE_HEIGHT,
    noEffect = true,
    stroke,
    shadow,
    textBgColor,
    blockBackground,
    hasNoEffectKey = false,
    margin,
    padding,
    textTransform = TEXT_TRANSFORM.LOWERCASE,
  }: any) => {
    const uid = Id || nanoid();

    const {
      font_size,
      font_color,
      font_face,
      effect_type,
      effect_color,
      bold,
      italic,
      alignment,
      underline,
      line_height,
      ...textStylesModified
    } = INIT_TEXT_STYLES;

    let updatedTextProperties;

    if (hasNoEffectKey) {
      updatedTextProperties = {
        noEffect: noEffect,
        shadow: shadow,
        stroke: stroke,
        textBgColor: textBgColor,
        blockBackground: blockBackground,
      };
    } else {
      updatedTextProperties = updateStylesWithNewProperties({
        effect_type: effectType,
        effect_color: effectColor,
      });
    }

    const newText = {
      start: 0,
      end:
        (currentSelectedMicroContent.end - currentSelectedMicroContent.start) /
        1000,
      content: isEmpty ? "" : textContent || "Edit Me",
      id: uid,
      style: {
        font_size: textSize,
        font_color: fontColor,
        font_face: fontFace,
        effect_type: effectType,
        effect_color: effectColor,
        bold: textBold,
        italic: textItalic,
        underline: textUnderLine,
        alignment: textAlignment,
        line_height: lineHeight,
        ...textStylesModified,
        margin,
        padding,
        coordinate_left: initPosition.left,
        coordinate_top: initPosition.top,
        width: textBoxWidth,
        maxHeight,
        textTransform,
        ...updatedTextProperties,
      },
      isSocialText,
    };
    setTextsObj((prevState: []) => ({
      ...prevState,
      [newText.id]: newText,
    }));
    textsObjRef.current = { ...textsObjRef.current, [newText.id]: newText };
    addTextToFabric(newText);
  };

  const isSubPresentOnTheCurrentPlayerTime = (
    textObj: any,
    currentTime: number
  ) => {
    return (
      currentTime >=
        textObj.start + currentSelectedMicroContent?.start / 1000 &&
      currentTime <= textObj.end + currentSelectedMicroContent?.start / 1000
    );
  };

  useEffect(() => {
    subsArrRef.current = subsArr;
  }, [subsArr, textsObj]);

  useUpdateEffect(() => {
    if (typeof currentSelectedMicroContent?.start === "number") {
      subsArrRef.current = [];
      setSubsArr([]);
      initCanvas();
    }
  }, [
    currentSelectedMicroContent?.start,
    currentSelectedMicroContent?.id,
    selectedLayout,
  ]);

  useEffect(() => {
    if (videoElRef.current) {
      const isSubPresentOnCurrentVideoTime = subsArr.some((textObj: any) =>
        isSubPresentOnTheCurrentPlayerTime(
          textObj,
          videoElRef.current.currentTime
        )
      );

      if (isSubPresentOnCurrentVideoTime) {
        subsArr.forEach((textObj: any, index: any) => {
          if (
            isSubPresentOnTheCurrentPlayerTime(
              textObj,
              videoElRef.current.currentTime
            )
          ) {
            activeSubtitleIndexRef.current = index;
            setSubs({
              ...textObj,
              text: textObj.text,
            });
          }
        });
      } else {
        // if no subtitle is present on the current video time and there is sub present on the canvas
        // we do not want to remove it if the next sub is going to start within 100ms
        // if the next sub is going to start after 100ms then we will remove the sub from the canvas
        // we can use the curren sub index to find the next sub
        if (
          activeSubtitleIndexRef.current !== null ||
          activeSubtitleIndexRef.current !== undefined
        ) {
          const nextSubIndex = activeSubtitleIndexRef.current + 1;
          const nextSub = subsArr[nextSubIndex];
          if (nextSub) {
            const diff = nextSub.start - videoElRef.current.currentTime; // in seconds
            if (diff < MIN_THRESHOLD_BETWEEN_SUBTITLES) {
              return;
            }
          }
        }
        setSubs({});
      }
      handelProgressBarAnimation(videoElRef?.current?.currentTime);
    }
  }, [subsArr, currentVideoTime]);

  useEffect(() => {
    textsObjRef.current = textsObj;
  }, [textsObj]);

  useEffect(() => {
    if (subtitleTextRef.current) {
      const subsStyles = getTransformedTextboxStyles(subsConfig);
      updateGroupedText(subtitleTextRef.current, subsStyles);
    }
  }, [subsConfig]);

  const breakSubsArrIntoWords = (subsArr: any) => {
    const wordsArr: any = [];
    subsArr.forEach((sub: any) => {
      const words = getWordsArrayWithEmojis(sub.text);
      const subTime = sub.end - sub.start;
      const wordTime = subTime / words.length;

      // if auto emoji is enabled
      words.forEach((word: any, index: number) => {
        wordsArr.push({
          ...sub,
          start: sub.start + index * wordTime,
          end: sub.start + (index + 1) * wordTime,
          text: word,
          id: nanoid(),
        });
      });
    });
    return wordsArr;
  };

  const breakSubsArrIntoTwoWords = (subsArr: any) => {
    const wordsArr: any = [];
    subsArr.forEach((sub: any) => {
      const words = getWordsArrayWithEmojis(sub.text);
      const subTime = sub.end - sub.start;
      const wordTime = subTime / words.length;

      for (let i = 0; i < words.length; i = i + 2) {
        const word1 = words[i];
        const word2 = words[i + 1] || "";
        const wordTime2 = word2 ? wordTime * 2 : wordTime;

        const wordStart = sub.start + i * wordTime;
        const wordEnd = wordStart + wordTime2;
        wordsArr.push({
          ...sub,
          start: wordStart,
          end: wordEnd,
          text: word1 + (word2 ? " " + word2 : word2),
          id: nanoid(),
        });
      }
    });
    return wordsArr;
  };

  const breakSubsArrForWordColor = (subsArr: any) => {
    const wordsArr: any = [];
    subsArr.forEach((sub: any) => {
      const words = getWordsArrayWithEmojis(sub.text);
      const subTime = sub.end - sub.start;
      const wordTime = subTime / words.length;

      let wordStartIndex = 0;
      words.forEach((word: any, index: number) => {
        const wordEndIndex = getWordLengthWithEmoji(word) + wordStartIndex;

        wordsArr.push({
          ...sub,
          start: sub.start + index * wordTime,
          end: sub.start + (index + 1) * wordTime,
          text: sub.text,
          id: nanoid(),
          wordStartIndex,
          wordEndIndex,
        });
        const graphemeArray = fabric.util.string.graphemeSplit(word);
        wordStartIndex += graphemeArray.length + 1;
      });
    });
    return wordsArr;
  };

  const breakSubsArrForRandomWordColor = (subsArr: any) => {
    const wordsArr: any = [];
    subsArr.forEach((sub: any) => {
      const { startIndex, endIndex } = getRandomStartAndEndIndexToColor(
        sub.text
      );

      wordsArr.push({
        ...sub,
        wordStartIndex: startIndex,
        wordEndIndex: endIndex,
      });
    });
    return wordsArr;
  };

  const breakSubsArrForWordAppendStyle = (subsArr: any) => {
    const wordsArr: any = [];
    subsArr.forEach((sub: any) => {
      const words = getWordsArrayWithEmojis(sub.text);
      const subTime = sub.end - sub.start;
      const wordTime = subTime / words.length;
      let wordStartIndex = 0;
      words.forEach((word: any, index: number) => {
        const wordEndIndex = getWordLengthWithEmoji(word) + wordStartIndex;
        // append all words to text before this word
        const text = words.slice(0, index + 1).join(" ");
        wordsArr.push({
          ...sub,
          start: sub.start + index * wordTime,
          end: sub.start + (index + 1) * wordTime,
          id: nanoid(),
          text,
          wordStartIndex,
          wordEndIndex,
        });
        const graphemeArray = fabric.util.string.graphemeSplit(word);
        wordStartIndex += graphemeArray.length + 1;
      });
    });
    return wordsArr;
  };

  const getSubsArrayForActiveSubtitleStyle = (subtitleArray: any) => {
    if (subsConfig.style.type === SubtitleStyle.ONE_WORD) {
      return breakSubsArrIntoWords(subtitleArray);
    }
    if (subsConfig.style.type === SubtitleStyle.TWO_WORD) {
      return breakSubsArrIntoTwoWords(subtitleArray);
    }
    if (
      subsConfig.style.type === SubtitleStyle.WORD_COLOR_CHANGE ||
      subsConfig.style.type === SubtitleStyle.WORD_BACKGROUND_CHANGE
    ) {
      return breakSubsArrForWordColor(subtitleArray);
    }
    if (subsConfig.style.type === SubtitleStyle.RANDOM_WORD_COLOR_CHANGE) {
      return breakSubsArrForRandomWordColor(subtitleArray);
    }
    if (subsConfig.style.type === SubtitleStyle.WORD_APPENDED) {
      return breakSubsArrForWordAppendStyle(subtitleArray);
    }

    return subtitleArray;
  };

  const renderTextBoxes = async (
    textAssets: any,
    zip: any,
    microContentGist: string
  ) => {
    const ratio = 1 / canvasObjects.current.videoElUp.scaleX;
    [...Object.values(textsObjRef.current)].forEach(async (textObj: any) => {
      const newObj = {
        ...textObj,
        content:
          textObj?.id === ID_TITLE_TEXT ? microContentGist : textObj.content,
      };
      const fabricObj = generateTextFabricObj(
        newObj,
        fabricRef.current,
        loadFont
      );
      const dataUrl = fabricObj.toDataURL({
        enableRetinaScaling: false,
        multiplier: ratio + 1,
      });
      const idx = dataUrl.indexOf("base64,") + "base64,".length;
      const content = dataUrl.substring(idx);
      const assetName = `${TEXT_ASSET_PREFIX + textObj.id}.png`;
      zip.file(assetName, content, { base64: true });
      const textProps = await getScaledPropertiesForText(
        fabricObj,
        canvasObjects.current,
        0
      );

      textAssets.push({
        ...textProps,
        asset_name: assetName,
        start: textObj.start,
        end: textObj.end,
      });
    });
  };

  const onRenderTexts = async ({
    canvasObjects,
    subTitleObjects,
    subtitleArray,
    microContentGist,
  }: {
    canvasObjects: any;
    subTitleObjects: any;
    subtitleArray: any;
    microContentGist: string;
  }) => {
    let textAssets: any = [];
    let subtitleEmojis: any = [];
    const zip: JSZip = new JSZip();
    if (subtitleArray.length && subTitleObjects) {
      const subtitleProps = await getScaledPropertiesForText(
        subTitleObjects,
        canvasObjects,
        SAFE_PADDING_FOR_TEXT
      );
      const wordsArr = getSubsArrayForActiveSubtitleStyle(subtitleArray);
      wordsArr.forEach((sub: any, index: number) => {
        // we need to change the end time of the current subtitle to the start time of the next subtitle
        // if the difference between the end time of the current subtitle and the start time of the next subtitle is less than 0.5 seconds
        let end = sub.end;
        if (index < wordsArr.length - 1) {
          const nextSub = wordsArr[index + 1];
          if (nextSub.start - sub.end < MIN_THRESHOLD_BETWEEN_SUBTITLES) {
            end = nextSub.start;
          }
        }

        textAssets.push({
          ...subtitleProps,
          asset_name: `${SUBTITLE_ASSET_PREFIX + sub.id}.png`,
          start: sub.start,
          end,
        });
      });

      for (let i = 0; i < wordsArr.length; i++) {
        const sub = wordsArr[i];
        if (sub.emoji) {
          const subtitleEmojiProps = await getScaledPropertiesForText(
            sub.emoji,
            canvasObjects,
            0
          );
          subtitleEmojis.push({
            ...subtitleEmojiProps,
            asset_name: `${SUBTITLE_EMOJI_PREFIX + sub.id}.png`,
            start: sub.start,
            end: sub.end,
          });
        }
      }

      await getSubsPngImages(
        zip,
        subTitleObjects,
        fabricRef.current,
        wordsArr,
        canvasObjects,
        subsConfig
      );
    }

    await renderTextBoxes(textAssets, zip, microContentGist);

    for (let i = 0; i < subtitleEmojis.length; i++) {
      textAssets.push(subtitleEmojis[i]);
    }
    const blob = await zip.generateAsync({ type: "blob" });
    const res = await uploadFileToS3(blob);

    if (!res?.s3Url) {
      // do not proceed if s3 upload fails for assets zip
      throw new Error(ASSET_ZIP_UPLOAD_ERROR);
    }

    const reqObj = {
      assets_zip: res?.public_url || res?.s3Url,
      assets_properties: textAssets,
    };

    return reqObj;
  };

  const onRenderVideo = async ({
    microContentStart,
    microContentEnd,
    canvasObjects,
    progressBarDetail,
    logoDetails,
    subTitleObjects,
    microContentGist,
    subtitleArray,
    clip_src,
    isIntelliClip,
  }: {
    microContentStart: number;
    microContentEnd: number;
    canvasObjects: any;
    progressBarDetail: any;
    logoDetails: any;
    subTitleObjects: any;
    microContentGist: string;
    subtitleArray: any[];
    clip_src: string;
    isIntelliClip: boolean;
  }) => {
    try {
      const cropRequestData = getVideoCoordsDetails(
        canvasObjects,
        selectedLayout
      );

      const { output_height, output_width } =
        getOutputDimensions(selectedLayout);

      const progressBarReqData = progressBarDetail?.height
        ? getProgressBarDetails(canvasObjects, progressBarDetail)
        : {};

      const logoRequestData = await getLogoDetails(canvasObjects, logoDetails, {
        start: microContentStart,
        end: microContentEnd,
      });

      let faceConfigArray: any = [];

      if (Object.keys(cropRequestData.face_1_config).length) {
        faceConfigArray = [...faceConfigArray, cropRequestData.face_1_config];
      }
      if (Object.keys(cropRequestData.face_2_config).length) {
        faceConfigArray = [...faceConfigArray, cropRequestData.face_2_config];
      }

      let requestBody = {
        face_config: [...faceConfigArray],
        output_width: output_width,
        output_height: output_height,
        input_width: videoElRef.current.videoWidth,
        input_height: videoElRef.current.videoHeight,
        project_id: currentSelectedProject && currentSelectedProject.id,
        bg_color_hex:
          canvasObjects?.backGroundClipPath?.backgroundColor || "#000000",
        bg_image_url: canvasObjects?.bgImgUrl || null,
        img_config: [...logoRequestData],
        progressbar_config: progressBarReqData,
        video_play_time: (microContentEnd - microContentStart) / 1000,
        aspect_ratio: selectedLayout,
        filename: `${microContentGist || nanoid()}.mp4`,
        outro: currentOutro?.url
          ? {
              img_url: currentOutro?.url,
              duration: currentOutro?.url && currentOutro?.duration,
            }
          : {},
        input_video_uri: currentSelectedProject
          ? isIntelliClip
            ? changeStageUrlToProd(clip_src)
            : encodeURI(JSON.parse(currentSelectedProject?.data).remote_url)
          : "",
        clip_start: microContentStart,
        clip_end: microContentEnd,
        id: nanoid(),
        // need bulk_part to be true for multiple clips
        bulk_part: true,
        audio_data: getAudioDataForRender(
          audioElements,
          currentSelectedMicroContent
        ),
      };

      // What happen if there is no subtitle
      if (subsArr.length > 0 || Object.keys(textsObjRef.current).length > 0) {
        const assetRequest = await onRenderTexts({
          canvasObjects,
          subTitleObjects,
          subtitleArray,
          microContentGist,
        });
        requestBody = { ...requestBody, ...assetRequest };
      }

      return requestBody;
    } catch (error: any) {
      console.log("Possible UI Render Error", error);

      toggleMultipleRenderMode();
      showNotification(
        "Could not render videos. Please try again!",
        notificationType.FAIL
      );
    }
  };

  // need to run loop through all the clips and render them
  const onRenderVideoForMultipleClips = async () => {
    const payloadArray = await Promise.all(
      Array.from(selectedClips).map(async (clip) => {
        const clipData = clip[1];

        const jsonSubs = parseSRT(clipData.srt_string);
        const subs = jsonSubs.map((sub: any) => {
          return {
            ...sub,
            text: sub.text,
          };
        });

        const isIntelliClip = getIsIntelliClip(clipData);
        return onRenderVideo({
          microContentStart: clipData.start,
          microContentEnd: clipData.end,
          canvasObjects: canvasObjects.current,
          progressBarDetail: progressBarRef?.current,
          logoDetails: logoRef?.current,
          subTitleObjects: subtitleTextRef?.current,
          microContentGist: clipData.gist,
          subtitleArray: subs,
          clip_src: clipData.clip_src,
          isIntelliClip,
        });
      })
    );
    updateRenderVideoPayloadForMultipleClips(payloadArray);

    toggleMultipleRenderMode();
  };

  useUpdateEffect(() => {
    if (multipleRenderModeOn) {
      onRenderVideoForMultipleClips();
    }
  }, [multipleRenderModeOn]);

  return (
    <>
      <div className="h-full bg-white text-center flex items-center justify-center -mt-4 select-none w-full">
        <div className="relative group">
          <div
            style={{
              width: EDITOR_WIDTH,
              height: EDITOR_HEIGHT,
            }}
            className={clsx(
              multipleRenderModeOn && "opacity-60",
              "bg-transparent"
            )}
          >
            <div
              style={{
                width: EDITOR_WIDTH,
                height: EDITOR_HEIGHT,
              }}
              className="bg-transparent"
            >
              {getVideoUrl && (
                <>
                  <VideoJS
                    url={getVideoUrl}
                    onTimeUpdate={onTimeUpdate}
                    initCanvas={initCanvas}
                    videoElRef={videoElRef}
                    onEnded={() => {
                      setIsVideoPlaying(false);
                    }}
                    setIsVideoSeeked={togglePreviewClipEditorLoaded}
                  />
                  <canvas
                    width={EDITOR_WIDTH}
                    height={EDITOR_HEIGHT}
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.5)",
                    }}
                    ref={canvasRef}
                    key={selectedLayout}
                  />
                </>
              )}
            </div>
          </div>
          {multipleRenderModeOn || !isPreviewClipEditorLoaded ? (
            <Spinner
              size="lg"
              className="h-20 w-20 absolute z-50 inset-0 m-auto"
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ClipPreviewEditor;
