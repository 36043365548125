import moengage from "@moengage/web-sdk";

import { isProd } from "@/helpers";

export const initMoengage = (currentUser: any) => {
  moengage.initialize({
    app_id: "RJNRP8251LJHLNDYYXAIF6M9",
    debug_logs: 0,
    cluster: "DC_3",
    // swPath: "serviceworker.js",
  });

  identifyMoengageUser(currentUser);
};

export const identifyMoengageUser = (currentUser: any) => {
  if (isProd()) {
    moengage.add_unique_user_id(currentUser.uid);
    moengage.add_email(currentUser.email);
    moengage.add_user_attribute("userName", currentUser.displayName);

    // moengageEvents(MOENGAGE_EVENTS.SIGN_IN, {
    //   userId: currentUser.uid,
    //   emailId: currentUser.email,
    //   userName: currentUser.displayName,
    // });
  }
};

export const deleteMoengageSession = () =>
  isProd() && moengage.destroy_session();

export const trackMoengageEvent = (
  eventName: any,
  eventData?: any,
  enableTracking = false
) => {
  if (isProd() && enableTracking) {
    moengage.track_event(eventName, eventData);
  }
};
