import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import {
  useBoolean,
  useEffectOnce,
  usePrevious,
  useUnmount,
  useUpdateEffect,
  useKey,
  useInterval,
} from "react-use";

import Tippy from "@tippyjs/react";
import clsx from "clsx";
import { fabric } from "fabric";
import { Group } from "fabric/fabric-impl";
import { Spinner } from "flowbite-react";
import JSZip from "jszip";
import { nanoid } from "nanoid";
import parseSRT from "parse-srt";
import PubSub from "pubsub-js";
import { useDebounce } from "use-debounce";

import { store } from "@/store";
import {
  changeSelectedLayout,
  resetEditorState,
  setAssetsUrl,
  setBackgroundColor,
  setCurrentSideMenu,
  setElementsActiveTab,
  setProgressBarColor,
  setProgressBarHeight,
  setSelectedTextId,
  setSocialMediaHandels,
  setSubtitles,
  setSubsLang,
  toggleSceneChange,
  toggleShowDownloadPreferenceModal,
  toggleShowExportingModal,
  toggleShowSaveTemplateModal,
  updateAllSceneChanges,
  updateAutoAddEmojisToSubtitles,
  updateCurrentRenderId,
  updateIsBackgroundImageLoaded,
  updateIsRenderingVideo,
  updateOutroLengthInSecond,
  SubsType,
  updateCurrentVideoTime,
  updateCurrentSubIndex,
  togglePlayPause,
  setProgressBarPosition,
} from "@/store/editorSlice";
import { updateCurrentSelectedMicroContent } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useElementSize from "@/hooks/useElementSize";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";
import { uploadFileToS3 } from "@/api/requests";
import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetSingleProject from "@/api/useGetSingleProject";
import useGetUserTemplates from "@/api/useGetUserTemplates";
import useRenderVideo, { useRenderVideoCutMagic } from "@/api/useRenderVideo";
import useUpdateProject from "@/api/useUpdateProject";

import {
  SUBTITLE_ASSET_PREFIX,
  VIDEO_CURRENT_TIME_POLLING_INTERVAL,
  BUFFER_FOR_CENTERED,
  SUBTITLE_EMOJI_PREFIX,
  SOCIAL_MEDIA_SELECTED_IMAGE,
  TEXT_ASSET_PREFIX,
  ID_TITLE_TEXT,
  INTELLI_CLIP_TAG,
} from "@/constants";
import {
  ANALYTICS_CONSTANTS,
  EDITOR_INTERACTION_DATA,
} from "@/constants/amplitude";

import {
  calculateScaleUpSizeFromBaseTemplate,
  centerFaceInClipPath,
  downloadS3Url,
  generateTextFabricObj,
  getAllScenesBetweenMicroContent,
  getBackgroundImage,
  getClipPath,
  getColonSeparatedTimeWithMillis,
  getCropArea,
  getCurrentSelectedTemplate,
  getFabricObjectById,
  getFabricVideo,
  getInitialPositionFromCanvas,
  getIntro,
  getLogoDetails,
  getOutputDimensions,
  getOutro,
  getProgressBar,
  getProgressBarDetails,
  getProgressBarProperties,
  getRandomTemplateForSceneChange,
  getScaledPropertiesForText,
  getSceneAtExactTime,
  getSceneAtGivenTime,
  getSocialsProperties,
  getSubsPngImages,
  getTemplatesForLayout,
  getTextbox,
  getTextsProperties,
  getTransformedTextboxStyles,
  getUserUploadedImageProperties,
  getVideoCoordsDetails,
  getVideosProperties,
  hasMoreThanKeys,
  initVideoPosition,
  isTwoFace,
  onSceneAdd,
  onSceneDelete,
  onSceneUpdate,
  onTextModified,
  onVideoMovedOnCanvas,
  sleep,
  sortAndUpdateScenes,
  updateSubsConfig,
  getWordsArrayWithEmojis,
  getWordLengthWithEmoji,
  getRandomStartAndEndIndexToColor,
  onAssetModified,
  getUserUploadedBRollsProperties,
  alphaNumericNanoId,
  modifyVideoPropertiesForCutMagic,
  getWordAtCurrentTime,
  createSrtFromSubsArray,
  handleKeyDown,
  handleSocialMediaOverlay,
  updateStylesWithNewProperties,
  getVideoPositionForDraft,
  setVideoPositionFromDraft,
  getIsIntelliClip,
  fabricElementFunctionExecutor,
  updateGroupedText,
  addSubtitleStyles,
  getFontSizeFromGroupText,
  loadFont,
  GroupedTextProps,
  getWidthFromGroupText,
  getTextFromTheGroup,
  getTextProperties,
  handleAddAudioAsset,
  syncAudioAssetsWithMainVideo,
  handleAddAudioClipsToCanvas,
  getAudioClips,
  getAudioDataForRender,
  checkIfExternalAudioAssetPlaying,
  changeStageUrlToProd,
  handleAddOutroAsset,
  getVideoElement,
  isHlsUrl,
  isSafari,
  onSubModified,
  onVideoMovedOnCanvasForUndoRedo,
  getVideoLinkWithHash,
  getSubtitlesSplitOnAssumptionOnStart,
  getSubtitlesSplitOnAssumptionOnEnd,
} from "@/helpers";
import {
  addEmojisToSrtString,
  updateEmojiTagsWithLemmetization,
} from "@/helpers/text";

import {
  eventsDataToRedux,
  trackEditorInteractionEvent,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { downloadAndRenderConversionFbq } from "@/utils/fbq";
import { downloadAndRenderConversionGtag } from "@/utils/gtag";
import { showNotification } from "@/utils/showNotification";

import {
  ProgressBarPosition,
  Scene,
  SelectionHandle,
  TextboxStyles,
  VideoPosition,
  VideoPositionAfterCanvasModification,
} from "@/interfaces";

import {
  AssetTags,
  EditorMediaStatus,
  EditorMediaTab,
  EditorMediaTabMode,
  ImageType,
  SimpleAssetType,
  SubtitleStyle,
  TextAlignment,
  TextElementType,
  VideoDurationInMilliseconds,
  VideoLayout,
} from "@/enums";

import FullPageLoader from "@/components/Loader/FullPageLoader";
import VideoJS from "@/components/VideoJs";

import { useEditorHistory } from "@/views/editor/EditorHistoryContext";
import { HistoryActionTypes } from "@/views/editor/EditorHistoryReducer";
import styles from "@/views/editor/EditorStyles.module.scss";
import { updateScenesCommand } from "@/views/editor/commands/cutmagicCommand";
import { modifyLogoOnCanvasCommand } from "@/views/editor/commands/logosCommand";
import { modifyMediaOnCanvasCommand } from "@/views/editor/commands/mediaCommand";
import {
  adjustVideoPositionOnCanvasCommand,
  changeClipStartOrEndTimeCommand,
} from "@/views/editor/commands/miscCommand";
import { changeProgressBarPositionCommand } from "@/views/editor/commands/progressbarCommand";
import {
  addRemoveSubtitleLineCommand,
  modifySubtitleCommand,
} from "@/views/editor/commands/subtitleCommand";
import {
  addTextCommand,
  changeTextCommand,
  deleteTextCommand,
  modifyTextCommand,
} from "@/views/editor/commands/textCommand";
import DownloadPreferenceModal from "@/views/editor/components/DownloadPreferenceModal";
import { EditorCanvas } from "@/views/editor/components/EditorCanvas";
import { EditorLeftSidebar } from "@/views/editor/components/EditorLeftSidebar";
import LowResPreviewInfo from "@/views/editor/components/LowResPreviewInfo";
import SaveDraftModal from "@/views/editor/components/SaveDraftModal";
import SaveTemplateModal from "@/views/editor/components/SaveTemplateModal";
import TimelineEditor from "@/views/editor/components/TimelineEditor";
import {
  DEFAULT_OUTRO_LENGTH_IN_SEC,
  DEFAULT_PROGRESS_BAR_HEIGHT,
  EDITOR_MEDIA_LOAD_STATE,
  INIT_SUBS_CONFIG,
  INIT_SUB_EMOJI_CONFIG,
  INIT_TEXT_STYLES,
  MIN_LINE_HEIGHT,
  MIN_THRESHOLD_BETWEEN_SUBTITLES,
  SOCIAL_MEDIA_HANDLES_MAP,
  TEXT_TRANSFORM,
  editorSubRoutes,
  SAFE_PADDING_FOR_TEXT,
} from "@/views/editor/constant";
import { useEditorActions } from "@/views/editor/hooks/useEditorActions";

import Instagram from "@/assets/images/editor/instagram_reel_icon.png";
import ReelsOverlay from "@/assets/images/editor/overlay_reels.png";
import Tiktok from "@/assets/images/editor/tikTok-icon.png";
import TiktokOverlay from "@/assets/images/editor/tiktok_overlay.png";
import Youtube from "@/assets/images/editor/youtube_shorts_icon.png";
import YoutubeOverlay from "@/assets/images/editor/youtubeshorts_overlay.png";

const INIT_SUBS_LANG = "en-US";

const PROGRESS_BAR = "Progress Bar";
const SOCIALS = "Socials";
const INTRO_OUTRO = "intro-outro";

const OUTRO_ASSET = "outro-asset";

const CANVAS_CLIPPATH_TOP_BOTTOM_PADDING = 100;
const CANVAS_CLIPPATH_LEFT_RIGHT_PADDING = 200;
const DEFAULT_SUBTILE_BOTTOM_BUFFER = 100;
const DEFAULT_SUBTILE_MAX_HEIGHT = 30;

// this is needed to control the clippath size for really tall screen
// eg  2560 X 1800 or vertical mode
const MAX_CLIPPATH_HEIGHT = 800;
const MIN_DISTANCE_BETWEEN_SCENES_IN_MILLIS = 50;

const ASSET_ZIP_UPLOAD_ERROR = "Error uploading assets zip to s3";

const Editor = () => {
  const progressBarColorAsHex = useAppSelector(
    (state) => state.editorState.progressBarColorAsHex
  );

  const progressBarHeight = useAppSelector(
    (state) => state.editorState.progressBarHeight
  );

  const selectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );

  const socialMediaHandels = useAppSelector(
    (state) => state.editorState.socialMediaHandels
  );

  const backgroundColorAsHex = useAppSelector(
    (state) => state.editorState.backgroundColorAsHex
  );

  const currentSelectedProject = useAppSelector(
    (state) => state.homeState.currentSelectedProject
  );
  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const editorCurrentVideoTime = useAppSelector(
    (state) => state.editorState.editorCurrentVideoTime
  );

  const isRenderingVideo = useAppSelector(
    (state) => state.editorState.isRenderingVideo
  );

  const { selectedEditorDraft } = useAppSelector((state) => state.draftState);

  const userSocialMediaHandles = useAppSelector(
    (state) => state.homeState.userSocialMediaHandles
  );

  const { editorDispatch } = useEditorHistory();

  const outroLengthInSecond =
    useAppSelector((state) => state.editorState.outroLengthInSecond) ||
    DEFAULT_OUTRO_LENGTH_IN_SEC;

  const isBackgroundImageLoaded = useAppSelector(
    (state) => state.editorState.isBackgroundImageLoaded
  );

  const autoAddEmojis = useAppSelector(
    (state) => state.editorState.autoAddEmojisToSubtitles
  );

  const uid = useAppSelector((state) => state.authState.currentUser.uid);
  const currentUser = useAppSelector((state) => state.authState.currentUser);

  const prevTemplateId = usePrevious(currentSelectedMicroContent?.id);

  const subtitles = useAppSelector((state) => state.editorState.subtitles);
  const { subsArr, subStart, subEnd } = subtitles;

  const assetUrls = useAppSelector((state) => state.editorState.assetUrls);

  const currentSubIndex = useAppSelector(
    (state) => state.editorState.currentSubIndex
  );

  const { mutate: showInfoMutate } = useUpdateProject();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { refetch: refetchProject, data: projectData } = useGetSingleProject(
    currentSelectedProject?.id
  );

  const location = useLocation();

  const [microContentStart] = useDebounce(
    currentSelectedMicroContent.start,
    500
  );
  const [microContentEnd] = useDebounce(currentSelectedMicroContent.end, 500);

  const [
    globalCanvasRef,
    { width: globalCanvasWidth, height: globalCanvasHeight },
  ] = useElementSize();

  const [subsConfig, setSubsConfig] =
    useState<typeof INIT_SUBS_CONFIG>(INIT_SUBS_CONFIG);

  const [isMainCanvasLoaded, setIsMainCanvasLoaded] = useState(false);

  const [currentVideoLink, setCurrentVideoLink] = useState<string | null>("");

  const [textsObj, setTextsObj] = useState<any>({});

  const [fontSize] = useState(subsConfig.font_size);

  const [outroTimeLeft, setOutroTimeLeft] = useState(0);
  const [currentOutro, setCurrentOutro] = useState<any>(null);

  const outroIntervalRef = useRef<any>(null);

  const [currentBGUrl, setCurrentBGUrl] = useState<string | null>(null);

  const [enableSubs, toggleEnableSubs] = useBoolean(true);

  const [isMiddleSliderThumbDraggable, setIsMiddleSliderThumbDraggable] =
    useState(true);

  const [bRolls, setBRolls] = useState<any>({});
  const [audioElements, setAudioElements] = useState<any>({});

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const [showPreviewInfo, setShowPreviewInfo] = useState(false);

  const [isVideoSeeking, setIsVideoSeeking] = useBoolean(false);

  const videoElRef = useRef<null | HTMLVideoElement>(null);

  const fabricRef = useRef<any>(null);
  const canvasObjects = useRef<any>({});
  const outroVideoRef = useRef<any>(null);
  const subsArrRef = useRef<any>(subsArr);
  const subtitleTextRef = useRef<any>(null);
  const subtitleEmojiRef = useRef<any>(null);
  const progressBarRef = useRef<any>(null);
  const logoRef = useRef<any>({});
  const bRollsRef = useRef<any>(bRolls);
  const bRollsFabricRef = useRef<any>({});
  const backgroundClipPathRef = useRef<any>(null);
  const textFabricObj = useRef<any>({});
  const textsObjRef = useRef<any>(textsObj);
  const pollAbortController = useRef<any>(null);
  const subtitleAbortController = useRef<any>(null);
  const subsEmojiConfig = useRef<typeof INIT_SUB_EMOJI_CONFIG>(
    INIT_SUB_EMOJI_CONFIG
  );
  const audioElementsRef = useRef<any>(audioElements);

  const [isPollingCurrentTime, toggleIsPollingCurrentTime] = useBoolean(false);

  const updateSeekbar = useRef<any>();

  const {
    backwardVideo,
    backwardVideoByOneFrame,
    forwardVideo,
    forwardVideoByOneFrame,
    handlePlayPause,
    handleBRollsVideoPlayPause,
    setCurrentTime,
  } = useEditorActions({
    bRollsRef,
    canvasObjects,
    fabricRef,
    videoElRef,
    outroTimeLeft,
    setOutroTimeLeft,
    isPollingCurrentTime,
    toggleIsPollingCurrentTime,
    outroIntervalRef,
    isMiddleSliderThumbDraggable,
  });

  const throttleUpdate = () => {
    // The throttleUpdate function is called on each update,
    // but it only schedules a new update if there isn't already an update pending.
    // This prevents excessive updates and ensures that the function is called at a reasonable rate.

    if (!updateSeekbar.current) {
      updateSeekbar.current = () => {
        if (videoElRef.current && videoElRef.current.currentTime) {
          videoElRef.current?.paused || onTimeUpdate();
        }
        updateSeekbar.current = null; // Reset the update function
      };

      // requestAnimationFrame allows you to schedule a function to be executed before the next repaint in the browser
      // the animation will be synchronized with the browser's rendering, resulting in a smoother and more efficient animation.
      // The browser will optimize the animation loop, reducing unnecessary computations
      // when the tab is not in focus, saving CPU resources.
      requestAnimationFrame(updateSeekbar.current);
    }
  };

  // load up the flag in case the user is directly coming to Editor page.
  useInterval(
    () => {
      if (videoElRef.current && videoElRef.current.currentTime) {
        videoElRef.current?.paused || updateProgressBarAnimation();
      }
    },
    isPollingCurrentTime ? VIDEO_CURRENT_TIME_POLLING_INTERVAL : null
  );

  useEffect(() => {
    // this removes the event listener when the component unmounts or when the dependencies change.
    // this helps prevent memory leaks and ensures that the event listener is properly cleaned up
    throttleUpdate();

    return () => {
      // Reset the update function on unmount
      updateSeekbar.current = null;
    };
  }, [isPollingCurrentTime]);

  const handleResetEditorState = () => {
    dispatch(resetEditorState());
  };

  useEffectOnce(() => {
    updateEmojiTagsWithLemmetization();
  });

  const removeAudioPlaybackOnUnMount = (audioElements: any) => {
    Object.values(audioElements).forEach((audioAsset: any) => {
      audioAsset.audioElement.volume = audioAsset.metaData.volume / 100;
      if (!audioAsset.paused) {
        audioAsset.audioElement.pause();
        audioAsset.audioElement.currentTime = 0;
      }
    });
  };

  useUnmount(() => {
    PubSub.clearAllSubscriptions();
    handleResetEditorState();
    removeAudioPlaybackOnUnMount(audioElements);
  });

  useEffect(() => {
    subsArrRef.current = subsArr;
  }, [subsArr]);

  const handelChangeSideBarMenu = useCallback(
    (route: string, mediaType = EditorMediaTab.IMAGE) => {
      dispatch(setCurrentSideMenu(route));

      if (route === editorSubRoutes.MEDIA) {
        navigate(
          `/editor/${currentSelectedProject?.id}/${route}?type=${mediaType}&mode=${EditorMediaTabMode.BROWSE}`
        );
        return;
      }

      navigate(`/editor/${currentSelectedProject?.id}/${route}`);
    },
    []
  );

  const handelChangeElementsTab = (tabName: string) => {
    dispatch(setElementsActiveTab(tabName));
  };

  const userTemplatesData = useGetUserTemplates(uid);
  const defaultBaseTemplates = useGetDefaultBaseTemplates() as any;

  const videoLink = getVideoLinkWithHash(
    currentSelectedProject,
    currentSelectedMicroContent?.startTime as number
  );

  useEffect(() => {
    if (
      currentSelectedProject &&
      currentSelectedProject.data &&
      !userTemplatesData.isLoading &&
      !defaultBaseTemplates.isLoading
    ) {
      if (isIntelliClip) {
        setCurrentVideoLink(
          changeStageUrlToProd(currentSelectedMicroContent.clip_src)
        );
      } else if (currentSelectedProject?.use_stream && !isSafari()) {
        const streamLink = currentSelectedProject.stream_data
          ? JSON.parse(currentSelectedProject.stream_data).playbacks?.hls
          : "";
        setCurrentVideoLink(streamLink);
      } else {
        setCurrentVideoLink(videoLink);
      }
    }
  }, [
    currentSelectedProject,
    userTemplatesData.isLoading,
    defaultBaseTemplates.isLoading,
  ]);

  const closeDownloadModal = () => {
    dispatch(toggleShowDownloadPreferenceModal(false));
    dispatch(toggleShowExportingModal(false));
  };

  const pollForRenderVideo = async (renderTaskId: string, fileName?: any) => {
    try {
      let controller;
      if (!pollAbortController.current) {
        controller = new AbortController();
        pollAbortController.current = controller;
      } else {
        controller = pollAbortController.current;
      }

      const response: any = await api.get(ApiEndpoints.GET_RENDER_VIDEO, {
        params: {
          render_task_id: renderTaskId,
          project_id: currentSelectedProject && currentSelectedProject.id,
        },
        signal: controller.signal,
      });

      if (response.data.status === "submitted") {
        await sleep(5000);
        await pollForRenderVideo(
          renderTaskId,
          `${currentSelectedMicroContent?.gist}.mp4`
        );
      } else if (response.data.status === "complete") {
        dispatch(updateIsRenderingVideo(false));
        currentSelectedProject &&
          downloadS3Url(
            response.data.rendered_video_url,
            response.data.filename,
            currentSelectedProject.id
          );
        const currentRenderId = store.getState().editorState.currentRenderId;
        currentRenderId === response.data.render_task_id &&
          closeDownloadModal();
      } else {
        showNotification(
          `Failed to process ${fileName || "this clip."} Please try again.`,
          notificationType.FAIL
        );
        dispatch(updateIsRenderingVideo(false));
        dispatch(toggleShowExportingModal(false));
      }
    } catch (e: any) {
      dispatch(updateIsRenderingVideo(false));
      dispatch(toggleShowExportingModal(false));
      if (e?.message === "canceled") {
        pollAbortController.current = null;
      } else {
        showNotification(
          `Failed to process ${fileName || "this clip."} Please try again.`,
          notificationType.FAIL
        );
      }
    }
  };

  const renderVideoErrorCB = () => {
    dispatch(updateIsRenderingVideo(false));
    dispatch(toggleShowExportingModal(false));
    showNotification(
      "Could not render video. Please try again!",
      notificationType.FAIL
    );
  };

  const renderVideoSuccessCB = () => {
    dispatch(updateCurrentRenderId(null));
    dispatch(toggleShowExportingModal(true));
    dispatch(updateIsRenderingVideo(false));
  };

  const enableSceneChange = useAppSelector(
    (state) => state.editorState.enableSceneChange
  );

  const previousEnableSceneChange = usePrevious(enableSceneChange);

  const { mutate: renderVideoMutate } = useRenderVideo(
    pollForRenderVideo,
    renderVideoErrorCB,
    renderVideoSuccessCB
  );

  const { mutate: renderVideoCutMagicMutate } = useRenderVideoCutMagic(
    pollForRenderVideo,
    renderVideoErrorCB,
    renderVideoSuccessCB
  );

  const updateSubsArrayWithEmojis = (jsonSubs: any) => {
    const clipStartTime = currentSelectedMicroContent?.start / 1000;
    const clipEndTime = currentSelectedMicroContent?.end / 1000;
    if (jsonSubs.length > 0) {
      let newSubsArr = autoAddEmojis
        ? jsonSubs.map((sub: SubsType) => {
            if (sub.emoji) {
              // return { ...sub, emoji: getDefaultEmojiPosition(sub.emoji) };
              return {
                ...sub,
                emoji: {
                  text: sub.emoji.text,
                },
              };
            } else return sub;
          })
        : jsonSubs;

      if (isIntelliClip) {
        newSubsArr = newSubsArr
          .filter((sub: SubsType) => {
            if (clipStartTime <= sub.start && clipEndTime >= sub.end) {
              return sub;
            }
          })
          .map((sub: SubsType) => {
            return {
              ...sub,
              start: sub.start - clipStartTime,
              end: sub.end - clipStartTime,
            };
          });
      }

      dispatch(
        setSubtitles({
          subsArr: newSubsArr,
          subStart: currentSelectedMicroContent?.start,
          subEnd: currentSelectedMicroContent?.end,
        })
      );

      subsArrRef.current = newSubsArr;
    }
  };

  const parseAndDispatchSubs = (srt_string: string) => {
    const jsonSubs = parseSRT(srt_string);
    updateSubsArrayWithEmojis(
      autoAddEmojis ? addEmojisToSrtString(jsonSubs) : jsonSubs
    );
  };

  const removeSubtitleEmojis = (subsArr: any) => {
    let newSubsArr = subsArr.map((sub: SubsType) => {
      if (sub.emoji) {
        return { ...sub, emoji: null };
      } else return sub;
    });
    return newSubsArr;
  };

  useEffect(() => {
    if (backgroundClipPathRef.current) {
      if (autoAddEmojis) {
        updateSubsArrayWithEmojis(addEmojisToSrtString(subsArr));
      } else {
        subsArrRef.current = null;
        dispatch(
          setSubtitles({
            subsArr: removeSubtitleEmojis(subsArr),
            subStart: currentSelectedMicroContent?.start,
            subEnd: currentSelectedMicroContent?.end,
          })
        );
      }
    }
  }, [autoAddEmojis, backgroundClipPathRef]);

  const fetchSubtitles = async (start: number, end: number) => {
    if (subtitleAbortController.current) {
      subtitleAbortController.current.abort();
    }
    try {
      subtitleAbortController.current = new AbortController();
      const response: any = await api.get(ApiEndpoints.SRT_FILE, {
        params: {
          chars_per_caption: 25,
          project_id: currentSelectedProject && currentSelectedProject.id,
          start_ts: start,
          end_ts: end,
          clip: true,
        },
        signal: subtitleAbortController.current.signal,
      });
      // reset the abort controller
      subtitleAbortController.current = null;
      return response.data.srt_string;
    } catch (error: any) {
      if (error?.code !== "ERR_CANCELED") {
        showNotification(
          "Unable to generate subtitles for this video",
          notificationType.FAIL
        );
      }
      throw new Error("Request Cancelled.");
    }
  };

  const fetchAndUpdateSubs = async (start: number, end: number) => {
    let srtString = "";
    if (currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG) {
      srtString = currentSelectedMicroContent?.srt_string;
    } else {
      try {
        srtString = await fetchSubtitles(start, end);
      } catch {
        srtString = "";
      }
    }
    if (srtString) {
      parseAndDispatchSubs(srtString);
      dispatch(
        updateCurrentSelectedMicroContent({
          srt_string: srtString,
        })
      );
      toggleEnableSubs(true);
    }
  };

  const addSubsForRedoOps = (metaData: any, subsConfig: any) => {
    const { subStart, subEnd, subsArr } = metaData;
    dispatch(
      setSubtitles({
        subsArr: subsArr,
        subStart: subStart,
        subEnd: subEnd,
      })
    );
    setSubsConfig(subsConfig || INIT_SUBS_CONFIG);

    subsArrRef.current = subsArr;
    toggleEnableSubs(true);
  };

  const calculateCenter = (obj: any) => {
    return {
      x: obj.left + (obj.width - BUFFER_FOR_CENTERED.text.left) / 2,
      y: obj.top + (obj.height - BUFFER_FOR_CENTERED.text.top) / 2,
    };
  };

  const addTextObject = ({
    initPosition = getInitialPositionFromCanvas(canvasObjects.current),
    textContent,
    textSize = 32,
    textBoxWidth = 0,
    fontColor = "#ffffff",
    fontFace = "Open Sans",
    effectType = "none",
    Id,
    textBold = false,
    isEmpty = false,
    textAlignment = TextAlignment.CENTER,
    effectColor = "#000000",
    textUnderLine = false,
    textItalic = false,
    maxHeight,
    isSocialText = false,
    lineHeight = MIN_LINE_HEIGHT,
    start = currentSelectedMicroContent.start,
    end = currentSelectedMicroContent.end,
    isFullVideoLength = true,
    noEffect = true,
    stroke,
    shadow,
    textBgColor,
    blockBackground,
    hasNoEffectKey = false,
    isNewText = false, // used to check if the text is new or not
    margin = INIT_TEXT_STYLES.margin,
    padding = INIT_TEXT_STYLES.padding,
    textTransform = TEXT_TRANSFORM.LOWERCASE,
    newTextFromState = null,
  }: any) => {
    const uid = Id || nanoid();
    if (backgroundClipPathRef.current) {
      const { left, top, width, height } = backgroundClipPathRef.current;
      const { x: centerLeft, y: centerTop } = calculateCenter({
        left,
        top,
        width,
        height,
      });
      let updatedTextProperties;
      const {
        font_size,
        font_color,
        font_face,
        effect_type,
        bold,
        alignment,
        effect_color,
        underline,
        italic,
        line_height,
        ...textStylesModified
      } = INIT_TEXT_STYLES;

      if (hasNoEffectKey) {
        updatedTextProperties = {
          noEffect: noEffect,
          shadow: shadow,
          stroke: stroke,
          textBgColor: { ...INIT_TEXT_STYLES.textBgColor, ...textBgColor },
          blockBackground: {
            ...INIT_TEXT_STYLES.blockBackground,
            ...blockBackground,
          },
        };
      } else {
        updatedTextProperties = updateStylesWithNewProperties({
          effect_type: effectType,
          effect_color: effectColor,
          blockBackground,
        });
      }
      let newText = {
        start: start,
        end: end,
        content: isEmpty ? "" : textContent || "Edit Me",
        id: uid,
        isFullVideoLength,
        style: {
          font_size: textSize,
          font_color: fontColor,
          font_face: fontFace,
          effect_type: effectType,
          bold: textBold,
          alignment: textAlignment,
          line_height: lineHeight,
          ...textStylesModified,
          coordinate_left: isNewText ? centerLeft : initPosition.left,
          coordinate_top: isNewText ? centerTop : initPosition.top,
          width: textBoxWidth,
          effect_color: effectColor,
          underline: textUnderLine,
          italic: textItalic,
          maxHeight,
          margin,
          padding,
          textTransform,
          ...updatedTextProperties,
        },
        isSocialText,
      };

      if (newTextFromState) {
        newText = newTextFromState;
      }

      if (isNewText && !isSocialText) {
        const command = addTextCommand(
          newText,
          (textData) => {
            // Your logic to actually add the text to the canvas and state
            setTextsObj((prevState: any) => {
              return { ...prevState, [textData.id]: textData };
            });
          },
          onTextDelete
        );

        command.execute();

        editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
      } else {
        //  skipTopReModification setting this to true
        // so we can skip the setCurrentTextFontSizeAndTop function
        setTextsObj((prevState: any) => {
          const newPayload = {
            ...prevState,
            [newText.id]: { ...newText, skipTopReModification: true },
          };
          return newPayload;
        });
      }
    }
  };

  const selectTextObject = (id: any) => {
    const objectId = textFabricObj.current[id];

    if (objectId && fabricRef.current) {
      fabricRef.current.setActiveObject(objectId);
    }
  };

  useEffect(() => {
    textsObjRef.current = textsObj;

    addRemoveAssetsOnSeek();
  }, [JSON.stringify(textsObj)]);

  useEffect(() => {
    bRollsRef.current = bRolls;
    addRemoveAssetsOnSeek();
  }, [bRolls]);

  const applyTextChange = (id: string | number, text: string) => {
    const newTextsObj: any = { ...textsObjRef.current };
    const targetFabricEl = textFabricObj.current[id];

    // checking if current text is uppercase and then converting it to uppercase when user enters text
    // else just passing the text as it is
    newTextsObj[id].content = text;

    const transformedStyleObj = getTransformedTextboxStyles(
      newTextsObj[id]?.style
    );
    // sometimes the target element is not added to canvas and removed from the ref that stores the fabric elements
    // that is why we need to check if it is available for update or not.
    if (targetFabricEl) {
      updateGroupedText(targetFabricEl, {
        ...transformedStyleObj,
        text: newTextsObj[id].content,
      });
    }
    setTextsObj(newTextsObj);

    textFabricObj.current[id]?.set("text", newTextsObj[id].content);
  };

  const onTextChange = (text: any, id: any, isFabric = false) => {
    const oldText = textsObjRef.current[id]?.content;

    const command = changeTextCommand(id, text, oldText, applyTextChange);

    command.execute();
    editorDispatch({
      type: HistoryActionTypes.ADD_COMMAND,
      command,
    });

    if (!isFabric) {
      // Additional logic if the change wasn't initiated by the Fabric.js object
      // For example, if you have an external text input that modifies the text
      textFabricObj.current[id]?.exitEditing?.();
    }
  };

  /**
   * This function is responsible for text modification in canvas
   * First we are removing the text and then adding the updated version
   * Setting the  setTextsObj will trigger a useEffect
   * And it will call on addRemoveAssetsOnSeek
   * @param textObjects
   * @param elementId
   */

  const handleTextModificationForUndoRedo = (
    textObjects: any,
    elementId: string
  ) => {
    removeTextObjFromFabric({ id: elementId });

    setTextsObj(textObjects);
  };

  const applyTextModification = (metaData: any) => {
    const command = modifyTextCommand(
      metaData,
      handleTextModificationForUndoRedo
    );

    command.execute();
    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const addTextToFabric = (text: any) => {
    if (fabricRef.current) {
      const textStyles = text.style;
      const transformedStyleObj: TextboxStyles = getTransformedTextboxStyles({
        ...textStyles,
      });

      const textContent =
        typeof text.content === "string" ? text.content : text.content.text;
      const textboxProps = {
        id: text.id,
        text: textContent,
        left: textStyles.coordinate_left,
        top: textStyles.coordinate_top,
        width: textStyles.width,
        maxHeight: textStyles.maxHeight,
        style: transformedStyleObj,
        type: text.isSocialText
          ? TextElementType.SOCIAL_HANDLE_TEXT
          : TextElementType.NORMAL_TEXT,
        clipPath: backgroundClipPathRef.current,
        padding: textStyles.padding,
        margin: textStyles.margin,
      };
      const fabricText = getTextbox(textboxProps);

      fabricText.on("changed", () => {
        const obj = fabricRef.current.getActiveObject();
        onTextChange(obj?.text, obj?.id, true);
      });
      fabricText.on("selected", () => {
        handelChangeSideBarMenu(editorSubRoutes.TEXTS);
      });
      fabricText.on("modified", ({ transform }: { transform: any }) => {
        onTextModified({
          fabricEl: fabricText,
          textsObjs: textsObjRef?.current,
          fabricRef: fabricRef.current,
          transform,
          applyTextModification,
        });
      });

      textFabricObj.current[text.id] = fabricText;

      const setCurrentTextFontSizeAndTop = (
        fontSize: number,
        top: number,
        maxHeight: number
      ) => {
        if (text.isUndoRedoOps || text.skipTopReModification) {
          return;
        }

        const currentEl = textFabricObj.current[text.id];
        if (currentEl) {
          setTextsObj((prevState: any) => {
            if (prevState[text.id]) {
              const newStyle = {
                ...prevState[text.id].style,
                font_size: fontSize,
                coordinate_top: top,
                maxHeight,
              };
              const newTextObj = {
                ...prevState[text.id],
                style: newStyle,
              };
              return {
                ...prevState,
                [text.id]: newTextObj,
              };
            }
            return prevState;
          });
        }
      };

      loadFont(
        fabricText,
        textboxProps.style as GroupedTextProps,
        () => {
          // NOTE: setCurrentTextFontSizeAndTop for some reason giving wrong top
          // so adding the coordinate_top manually
          fabricText.set("top", textStyles.coordinate_top);
          fabricRef.current.add(fabricText);
          text?.isUndoRedoOps && fabricRef.current.setActiveObject(fabricText);
          fabricRef.current.renderAll();
        },
        true,
        setCurrentTextFontSizeAndTop
      );
    }
  };

  const deleteText = (textId: string | number) => {
    const newTextsObj = { ...textsObjRef.current };
    delete newTextsObj[textId];
    setTextsObj(newTextsObj);
    if (textFabricObj.current[textId]) {
      fabricRef.current?.remove(textFabricObj.current[textId]);
      delete textFabricObj.current[textId];
    }
  };

  const onTextDelete = (id: string | number, isUndoOperation = false) => {
    let textData = textsObjRef.current[id];

    textData = {
      newTextFromState: textData,
    };

    if (!isUndoOperation) {
      const command = deleteTextCommand(
        id,
        addTextObject,
        deleteText,
        () => textData
      );

      command.execute();
      editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
    } else {
      deleteText(id);
    }
  };

  const MIN_GAP = 1000;

  const onStartUpdate = (value: any, id: any, isBRoll = false) => {
    if (isBRoll) {
      const newBRollObj = { ...bRollsRef.current };

      if (newBRollObj[id]) {
        let timeEnd = newBRollObj[id].metaData.end;
        if (value >= newBRollObj[id].metaData.end) {
          timeEnd = value + MIN_GAP;
        }
        if (newBRollObj[id].assetType === SimpleAssetType.VIDEO) {
          timeEnd =
            newBRollObj[id].metaData.duration * 1000 >
            currentSelectedMicroContent.end
              ? currentSelectedMicroContent.end
              : value + newBRollObj[id].metaData.duration * 1000;
        }

        const metaData = {
          ...newBRollObj[id].metaData,
          start: value,
          end: timeEnd,
        };
        newBRollObj[id].metaData = metaData;

        setBRolls(newBRollObj);
      }
    } else {
      const newTextsObj = { ...textsObjRef.current };

      if (newTextsObj[id]) {
        newTextsObj[id].start = value;

        if (newTextsObj[id].start >= newTextsObj[id].end) {
          newTextsObj[id].end = newTextsObj[id].start + MIN_GAP;
        }
        setTextsObj(newTextsObj);
      }
    }
  };

  const audioAssetTimeUpdate = (
    times: {
      start?: number;
      end?: number;
    },
    id: string | number,
    makeFullVideoLength = false
  ) => {
    const audioElement = audioElements[id];
    if (audioElement) {
      const { metaData } = audioElement;

      const newMetaData = {
        ...metaData,
        ...(times.start !== undefined && { start: times.start }),
        ...(times.end !== undefined && { end: times.end }),
        isFullVideoLength: makeFullVideoLength,
      };
      const newAudioObj = {
        ...audioElements,
        [id]: { ...audioElement, metaData: newMetaData },
      };

      setAudioElements(newAudioObj);
    }
  };

  const audioAssetVolumeUpdate = (value: number, id: string | number) => {
    const audioElement = audioElements[id];
    if (audioElement) {
      const { metaData } = audioElement;

      const newMetaData = {
        ...metaData,
        volume: value,
      };

      const newAudioObj = {
        ...audioElements,
        [id]: { ...audioElement, metaData: newMetaData },
      };

      setAudioElements(newAudioObj);
    }
  };

  const onEndUpdate = (value: any, id: any, isBRoll = false) => {
    if (isBRoll) {
      const newBRollObj = { ...bRollsRef.current };

      if (newBRollObj[id]) {
        let timeStart = newBRollObj[id].metaData.start;
        let timeEnd;

        if (value <= timeStart) {
          timeEnd = timeStart + MIN_GAP;
        } else {
          timeEnd =
            value <= currentSelectedMicroContent.end
              ? value < MIN_GAP
                ? MIN_GAP
                : value
              : currentSelectedMicroContent.end;
        }
        if (newBRollObj[id].metaData.start >= timeEnd) {
          timeStart = timeEnd - MIN_GAP;
        }

        if (value === currentSelectedMicroContent.start) {
          timeEnd = currentSelectedMicroContent.start + MIN_GAP;
        }

        const metaData = {
          ...newBRollObj[id].metaData,
          start: timeStart,
          end: timeEnd,
        };
        newBRollObj[id].metaData = metaData;
        setBRolls(newBRollObj);
      }
    } else {
      const newTextsObj = { ...textsObjRef.current };

      if (newTextsObj[id]) {
        newTextsObj[id].end =
          value <= currentSelectedMicroContent.end
            ? value < MIN_GAP
              ? MIN_GAP
              : value
            : currentSelectedMicroContent.end;

        if (newTextsObj[id].start >= newTextsObj[id].end) {
          newTextsObj[id].start = newTextsObj[id].end - MIN_GAP;
        }
        setTextsObj(newTextsObj);
      }
    }
  };

  /**
   *
   * @param value
   * @param id
   * @param isBRoll
   */

  const updateIsFullVideoLength = (
    isFullVideoLength: boolean,
    id: string | number,
    isBRoll = false,
    times?: {
      start: number;
      end: number;
    }
  ) => {
    onStartUpdate(
      times?.start || currentSelectedMicroContent.start,
      id,
      isBRoll
    );
    onEndUpdate(times?.end || currentSelectedMicroContent.end, id, isBRoll);

    if (isBRoll) {
      const newBRollObj = { ...bRollsRef.current };
      if (newBRollObj[id]) {
        let metaData = {
          ...newBRollObj[id].metaData,
          isFullVideoLength: isFullVideoLength,
        };
        newBRollObj[id].metaData = metaData;
      }

      setBRolls(newBRollObj);
    } else {
      const newTextsObj = { ...textsObjRef.current };
      if (newTextsObj[id]) {
        newTextsObj[id].isFullVideoLength = isFullVideoLength;
        setTextsObj(newTextsObj);
      }
    }
  };

  const updateSelectedTextId = () => {
    const activeObj = fabricRef.current.getActiveObject();
    if (activeObj && activeObj.type !== TextElementType.SUBTITLE) {
      dispatch(setSelectedTextId(activeObj.id));
    } else {
      dispatch(setSelectedTextId(null));
    }
  };

  useEffect(() => {
    setSubsConfig({ ...subsConfig, font_size: fontSize });
  }, [fontSize]);

  const onSubChange = (subId: any, sub: any) => {
    const mappingFunctionToChangeSubtitle = (item: SubsType) => {
      if (item.id === subId) {
        const text = sub.text;
        return {
          ...sub,
          text,
        };
      }

      return { ...item };
    };
    const newSubsArr = subsArrRef.current.map(mappingFunctionToChangeSubtitle);

    if (isIntelliClip) {
      const srtString = createSrtFromSubsArray(newSubsArr);

      const completeSubs = parseSRT(currentSelectedMicroContent.srt_string);
      const newCompleteSubs = completeSubs.map(mappingFunctionToChangeSubtitle);
      const newSrtString = createSrtFromSubsArray(newCompleteSubs);

      dispatch(
        updateCurrentSelectedMicroContent({
          srt_string: newSrtString,
        })
      );
    }

    dispatch(setSubtitles({ ...subtitles, subsArr: newSubsArr }));
    fabricElementFunctionExecutor(subtitleTextRef.current, "exitEditing");
  };

  const handleAddRemoveSubtitleLine = (metaData: any) => {
    const command = addRemoveSubtitleLineCommand(metaData, (subsData: any) => {
      dispatch(setSubtitles(subsData));
    });

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const onAddSubLine = (
    addAfterIndex: string,
    start: number,
    end: number,
    text = ""
  ) => {
    // add new sub in subsArr after given index
    // get index of given sub
    const index = subsArrRef.current.findIndex(
      (sub: any) => sub.id === addAfterIndex
    );
    // create new sub

    const newSub = {
      id: nanoid(),
      text,
      start: start,
      end: end,
    };

    // add new sub in subsArr after given index
    const newSubsArr = [
      ...subsArrRef.current.slice(0, index + 1),
      newSub,
      ...subsArrRef.current.slice(index + 1),
    ];
    handleAddRemoveSubtitleLine({
      newSubs: { ...subtitles, subsArr: newSubsArr },
      oldSubs: { ...subtitles, subsArr: subsArrRef.current },
    });
  };

  const onRemoveSubLine = (index: any) => {
    // remove sub from subsArr
    const newSubsArr = subsArrRef.current.filter(
      (sub: any) => sub.id !== index
    );

    handleAddRemoveSubtitleLine({
      newSubs: { ...subtitles, subsArr: newSubsArr },
      oldSubs: { ...subtitles, subsArr: subsArrRef.current },
    });
  };

  const onSubTextChange = (index: any, text: any) => {
    const newSubsArr = subsArrRef.current.map((item: any) => {
      if (item.id === index) {
        return {
          ...item,
          text: text,
        };
      }
      return { ...item };
    });

    if (isIntelliClip) {
      const srtString = createSrtFromSubsArray(newSubsArr);
      dispatch(
        updateCurrentSelectedMicroContent({
          srt_string: srtString,
        })
      );
    }

    dispatch(setSubtitles({ ...subtitles, subsArr: newSubsArr }));
  };

  const getDefaultSubtitlePosition = () => {
    const videoHeight = backgroundClipPathRef.current?.height;
    const videoWidth = backgroundClipPathRef.current?.width;

    const initPosition: any = getInitialPositionFromCanvas(
      canvasObjects.current
    );

    const userSavedTemplates = getTemplatesForLayout(
      selectedLayout,
      userTemplatesData?.data
    );

    const currentTemplate = selectedEditorDraft
      ? selectedEditorDraft
      : getCurrentSelectedTemplate(
          selectedLayout,
          currentSelectedMicroContent,
          userSavedTemplates,
          defaultBaseTemplates?.data
        );

    const templateScaleUpRatio = calculateScaleUpSizeFromBaseTemplate(
      currentTemplate.height,
      videoHeight
    );

    const left = hasMoreThanKeys(currentTemplate?.subtitle, 2)
      ? initPosition.left + currentTemplate.subtitle.left * templateScaleUpRatio
      : initPosition.left;

    const top = hasMoreThanKeys(currentTemplate?.subtitle, 2)
      ? initPosition?.top + currentTemplate.subtitle.top * templateScaleUpRatio
      : initPosition?.top + videoHeight - DEFAULT_SUBTILE_BOTTOM_BUFFER;

    const width = hasMoreThanKeys(currentTemplate?.subtitle, 2)
      ? currentTemplate.subtitle.width * templateScaleUpRatio ||
        videoWidth - currentTemplate.subtitle.left * templateScaleUpRatio
      : videoWidth;

    const maxHeight = hasMoreThanKeys(currentTemplate?.subtitle, 2)
      ? currentTemplate.subtitle.maxHeight * templateScaleUpRatio
      : DEFAULT_SUBTILE_MAX_HEIGHT;

    return {
      left,
      top,
      width,
      maxHeight,
    };
  };

  const updateEmojiPositionConfig = () => {
    const subtitlePosition = getDefaultSubtitlePosition();
    const videoHeight = backgroundClipPathRef.current?.height;
    const videoWidth = backgroundClipPathRef.current?.width;
    const initPosition = getInitialPositionFromCanvas(canvasObjects.current);

    const emojiHeight = subsEmojiConfig.current.fontSize;
    const margin = 20;
    const emojiTop = subtitlePosition.top - emojiHeight - margin;

    if (selectedLayout === VideoLayout.LAYOUT_9_16_2) {
      subsEmojiConfig.current.top = subtitlePosition.top - emojiHeight;
      subsEmojiConfig.current.left =
        initPosition.left +
        videoWidth / 2 -
        subsEmojiConfig.current.fontSize / 2;
      return;
    }

    if (subtitlePosition.top > videoHeight / 2) {
      // sub in lower half
      subsEmojiConfig.current.top = emojiTop;
      subsEmojiConfig.current.left =
        initPosition.left +
        videoWidth / 2 -
        subsEmojiConfig.current.fontSize / 2;
    } else {
      // sub in upper half
      subsEmojiConfig.current.top = initPosition.top + videoHeight / 2;
      subsEmojiConfig.current.left =
        initPosition.left +
        videoWidth / 2 -
        subsEmojiConfig.current.fontSize / 2;
    }
  };

  const onSubtitleEmojiChange = (index: any, sub: any) => {
    const newSubsArr = subsArrRef.current.map((item: SubsType) => {
      if (item.id === index) {
        if (
          sub.emoji &&
          !subsEmojiConfig.current.top &&
          !subsEmojiConfig.current.left
        ) {
          // return { ...sub, emoji: getDefaultEmojiPosition(sub.emoji) };
          return {
            ...sub,
            emoji: {
              text: sub.emoji.text,
            },
          };
        } else return sub;
      } else return item;
    });

    dispatch(setSubtitles({ ...subtitles, subsArr: newSubsArr }));
  };

  const onFormatSubtitleText = (transformStyle: string) => {
    if (subsArrRef.current) {
      const newSubsArr = subsArrRef.current.map(
        (item: SubsType, index: number) => {
          // Capitalize the first letter of the transcript
          if (index === 0) {
            const firstLetter = item?.text?.charAt(0).toUpperCase();
            const restOfString = item?.text?.slice(1);
            return {
              ...item,
              text: firstLetter + restOfString,
            };
          }

          return {
            ...item,
            text: item.text,
          };
        }
      );

      dispatch(
        setSubtitles({
          subsArr: newSubsArr,
          subStart: currentSelectedMicroContent?.start,
          subEnd: currentSelectedMicroContent?.end,
        })
      );
    }
  };

  const applySubsModification = (metaData: any) => {
    const command = modifySubtitleCommand(metaData, setSubsConfig);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const setSubs = (sub: any) => {
    const { text, emoji } = sub || {};
    if (fabricRef.current) {
      let fabricEl = subtitleTextRef.current;
      let fabricEmojiEl = subtitleEmojiRef.current;
      const subtitlePosition = getDefaultSubtitlePosition();

      // create subs ref if not exist
      if (subsArr.length > 0 && !fabricEl) {
        const transformedStyleObj: TextboxStyles =
          getTransformedTextboxStyles(subsConfig);

        const textboxProps = {
          text: text,
          left: subtitlePosition.left,
          top: subtitlePosition.top,
          width: subtitlePosition.width,
          maxHeight: subtitlePosition.maxHeight,
          type: TextElementType.SUBTITLE,
          style: transformedStyleObj,
          clipPath: backgroundClipPathRef.current,
        };
        fabricEl = getTextbox(textboxProps);
        fabricEl.set("editable", false);
        fabricEl.set("cursorWidth", 0);
        subtitleTextRef.current = fabricEl;

        fabricEl.on("changed", () => {
          onSubTextChange(currentSubIndex, text);
        });
        fabricEl.on("selected", () => {
          handelChangeSideBarMenu(editorSubRoutes.SUBTITLES);
        });

        fabricEl.on("modified", ({ transform }: { transform: any }) => {
          onSubModified({
            fabricEl,
            transform,
            setSubsConfig,
            subsConfig,
          });
        });
        // to load up
        loadFont(fabricEl, textboxProps.style as unknown as GroupedTextProps);
      }

      if (emoji) {
        if (fabricEmojiEl) {
          fabricRef.current.remove(fabricEmojiEl);
        }

        const ZERO_PADDING = {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        };
        const ZERO_MARGIN = {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        };

        const style = {
          ...getTransformedTextboxStyles(INIT_TEXT_STYLES),
          fontSize: subsEmojiConfig.current.fontSize,
          width: subsEmojiConfig.current.width,
          padding: ZERO_PADDING,
          margin: ZERO_MARGIN,
        };

        // const emojiPosition = getDefaultEmojiPosition(emoji);

        fabricEmojiEl = getTextbox({
          text: emoji.text,
          left: subsEmojiConfig.current.left,
          top: subsEmojiConfig.current.top,
          width: subsEmojiConfig.current.fontSize,
          maxHeight: subsEmojiConfig.current.fontSize,
          type: TextElementType.SUBTITLE_EMOJI,
          clipPath: backgroundClipPathRef.current,
          style,
        });

        fabricEmojiEl.on("modified", () => {
          // the only possible way to modify emoji is to scale
          const scaleX = fabricEmojiEl.scaleX;
          const newWidth = getWidthFromGroupText(fabricEmojiEl) * scaleX;
          let currentFontSize: number = getFontSizeFromGroupText(fabricEmojiEl);
          const updatedFontSize =
            parseFloat((currentFontSize * scaleX).toFixed(2)) || 0;

          if (scaleX !== 1) {
            updateGroupedText(fabricEmojiEl, {
              ...style,
              fontSize: updatedFontSize,
              width: newWidth,
            });
          }

          subsEmojiConfig.current.top = fabricEmojiEl.top;
          subsEmojiConfig.current.left = fabricEmojiEl.left;
          subsEmojiConfig.current.fontSize = updatedFontSize;

          fabricEmojiEl.set("scaleX", 1);
          fabricEmojiEl.set("scaleY", 1);
        });

        subtitleEmojiRef.current = fabricEmojiEl;
        // get the latest height of text element.
        fabricRef.current.add(fabricEmojiEl);
        fabricRef.current.bringToFront(fabricEmojiEl);
        fabricRef.current.renderAll();
      } else if (fabricEmojiEl) {
        fabricRef.current.remove(fabricEmojiEl);
      }

      if (text) {
        const objects = fabricRef.current.getObjects();
        // find if subs is already in canvas
        const isSubsExist = objects.find((object: any) => {
          return object?.type === TextElementType.SUBTITLE;
        });
        const transformedStyleObj: TextboxStyles =
          getTransformedTextboxStyles(subsConfig);
        if (text !== fabricEl.currentText) {
          updateGroupedText(fabricEl, {
            text,
            width: subtitlePosition.width,
            maxHeight: subtitlePosition.maxHeight,
            ...transformedStyleObj,
          });
        }
        // generate new fabric element
        // remove the old one if exist
        if (videoElRef.current) {
          addSubtitleStyles(
            sub,
            subsConfig,
            fabricEl,
            currentSelectedMicroContent,
            videoElRef.current.currentTime
          );
        }
        isSubsExist || fabricRef.current.add(fabricEl);
        fabricRef.current?.renderAll();
      } else {
        fabricRef.current?.remove(fabricEl);
      }
    }
  };

  const removeBackgroundImage = () => {
    const backgroundImage = getFabricObjectById(
      "backgroundImage",
      fabricRef.current
    );

    if (backgroundImage) {
      handelChangeBackgroundColor({ hex: "#000000" });
      changeBackgroundClipPathFill("#000000");
      fabricRef.current?.remove(backgroundImage);
    }
  };

  const handlePersistsBRolls = (bRolls: any) => {
    const updatedBRolls = Object.entries(bRolls).reduce(
      (acc: any, [key, bRoll]: [string, any]) => {
        if (!bRoll.metaData?.isTemplate) {
          acc[key] = bRoll;
        }
        return acc;
      },
      {}
    );

    setBRolls(updatedBRolls);
    bRollsRef.current = updatedBRolls;
    addRemoveAssetsOnSeek();
  };

  const clearLayout = () => {
    //get all fabric objects and delete
    fabricRef.current.discardActiveObject();
    const objects = fabricRef.current.getObjects();
    objects.forEach((object: any) => {
      fabricRef.current.remove(object);
    });

    // reset canvas related state
    canvasObjects.current = {};
    subtitleTextRef.current = null;
    progressBarRef.current = null;
    backgroundClipPathRef.current = null;
    textFabricObj.current = {};
    textsObjRef.current = {};
    logoRef.current = {};
    bRollsRef.current = {};
    bRollsFabricRef.current = {};
    setTextsObj({});
    if (bRolls) {
      handlePersistsBRolls(bRolls);
    }
    setCurrentOutro(null);
    dispatch(setSocialMediaHandels([]));
    dispatch(setAssetsUrl([]));
    // setAudioElements({});
  };

  const handelChangeBackgroundColor = (color: any) => {
    if (color?.hex) {
      dispatch(setBackgroundColor(color.hex));
      dispatch(updateIsBackgroundImageLoaded(false));
    }
  };

  const changeBackgroundClipPathFill = (color: string) => {
    if (backgroundClipPathRef.current) {
      backgroundClipPathRef.current.fill = color;
      // setting dirty true will make sure fabric will invalidate the cache
      // and rerender the background clippath
      backgroundClipPathRef.current.dirty = true;
    }
  };

  // this effect listen for background color changes
  useEffect(() => {
    changeBackgroundClipPathFill(backgroundColorAsHex);
  }, [backgroundColorAsHex, backgroundClipPathRef.current]);

  useEffect(() => {
    if (!isBackgroundImageLoaded) {
      removeBackgroundImage();
    }
  }, [isBackgroundImageLoaded]);

  const handelChangeProgresssbarHeight = (height: number) => {
    dispatch(setProgressBarHeight(height));
  };

  // this effect is listening for any progress Bar Color change change
  useEffect(() => {
    if (progressBarRef.current) {
      progressBarRef.current.fill = progressBarColorAsHex;
      progressBarRef.current.dirty = true;
    }
  }, [progressBarColorAsHex]);

  // this effect is listening for any progress Bar Height
  useEffect(() => {
    if (progressBarRef.current) {
      if (progressBarHeight === 0) {
        fabricRef.current.discardActiveObject(
          canvasObjects.current.progressBarRect
        );
        // init progress bar position to bottom of the template
        canvasObjects.current.progressBarRect.left =
          backgroundClipPathRef.current.left;
        canvasObjects.current.progressBarRect.top =
          backgroundClipPathRef.current.height +
          backgroundClipPathRef.current.top -
          DEFAULT_PROGRESS_BAR_HEIGHT;
      }
      const { top, left } = store.getState().editorState.progressBarPosition;
      canvasObjects.current.progressBarRect.height = progressBarHeight;

      if (top && left) {
        canvasObjects.current.progressBarRect.top = top;
        canvasObjects.current.progressBarRect.left = left;
      }

      handleUpdateProgressBarPositionToRedux({
        top: canvasObjects.current.progressBarRect.top,
        left: canvasObjects.current.progressBarRect.left,
      });
      // needed to render progressbar correctly on the canvas
      // so it basically tells canvas to rerender the progressbar
      // when dirty set to true
      canvasObjects.current.progressBarRect.dirty = true;
      canvasObjects.current.progressBarRect.setCoords();
    }
  }, [progressBarHeight]);

  const generateTemplateJSON = async (isAdmin = false) => {
    const fabricObj = fabricRef.current;
    const fabricObjects = canvasObjects.current;

    const templateHeightWidth = fabricObj
      ?.getObjects()[0]
      ?.getBoundingRect() || {
      width: 0,
      height: 0,
    };

    const baseTemplateJSON: any = {};
    baseTemplateJSON.id = nanoid();
    baseTemplateJSON.backgroundColor = backgroundColorAsHex;
    baseTemplateJSON.backgroundImageUrl = getBackgroundImage(fabricObj);
    baseTemplateJSON.height = templateHeightWidth.height;
    baseTemplateJSON.width = templateHeightWidth.width;
    baseTemplateJSON.hasTwoFace = fabricObjects?.videoElDown ? true : false;
    baseTemplateJSON.subtitle = getTextProperties(
      subtitleTextRef.current,
      templateHeightWidth,
      subsConfig
    );
    baseTemplateJSON.autoAddEmojisToSubtitles = autoAddEmojis;
    if (
      subtitleTextRef.current &&
      Object.keys(baseTemplateJSON.subtitle).length > 0
    ) {
      baseTemplateJSON.subtitle.style = subsConfig.style;
      baseTemplateJSON.subtitle.noEffect = subsConfig.noEffect;
      baseTemplateJSON.subtitle.shadow = subsConfig.shadow;
      baseTemplateJSON.subtitle.stroke = subsConfig.stroke;
      baseTemplateJSON.subtitle.textBgColor = subsConfig.textBgColor;
      baseTemplateJSON.subtitle.blockBackground = subsConfig.blockBackground;
      baseTemplateJSON.subtitle.textTransform = subsConfig.textTransform;
      baseTemplateJSON.subtitle.fill = subsConfig.font_color;
      baseTemplateJSON.subtitle.fontColor = subsConfig.font_color;
      baseTemplateJSON.subtitle.fontFamily = subsConfig.font_face;
      baseTemplateJSON.subtitle.textAlign = subsConfig.text_align;
      baseTemplateJSON.subtitle.fontSize = subsConfig.font_size;
      baseTemplateJSON.subtitle.underline = subsConfig.underline;
      baseTemplateJSON.subtitle.bold = subsConfig.bold;
      baseTemplateJSON.subtitle.margin = subsConfig.margin;
      baseTemplateJSON.subtitle.padding = subsConfig.padding;
      baseTemplateJSON.subtitle.width = getWidthFromGroupText(
        subtitleTextRef.current
      );
      baseTemplateJSON.subtitle.lineHeight = subsConfig.line_height;
      baseTemplateJSON.subtitle.text = getTextFromTheGroup(
        subtitleTextRef.current
      );
    }

    if (subtitleEmojiRef.current) {
      baseTemplateJSON.emojiStyles = { ...subsEmojiConfig.current };
    }

    baseTemplateJSON.progressbar = getProgressBarProperties(
      fabricObjects,
      templateHeightWidth
    );
    baseTemplateJSON.autoAddEmojisToSubtitles = autoAddEmojis;
    baseTemplateJSON.texts = getTextsProperties(
      templateHeightWidth,
      textsObj,
      textFabricObj.current
    );
    baseTemplateJSON.socials = await getSocialsProperties(
      socialMediaHandels,
      fabricObj,
      templateHeightWidth,
      textsObj
    );
    baseTemplateJSON.videoProperties = getVideosProperties(
      fabricObj,
      fabricObjects,
      baseTemplateJSON.hasTwoFace,
      isAdmin
    );
    baseTemplateJSON.outro = getOutro(currentOutro);
    baseTemplateJSON.intro = getIntro();

    baseTemplateJSON.logos = await getUserUploadedImageProperties(
      fabricObj,
      templateHeightWidth,
      logoRef.current,
      AssetTags.LOGO
    );

    baseTemplateJSON.bRolls = await getUserUploadedBRollsProperties({
      templateHeightWidth,
      bRollsRef: bRollsRef.current,
      assetTag: AssetTags.B_ROLL,
      isTemplate: true,
    });

    baseTemplateJSON.audioClips = getAudioClips(
      audioElements,
      currentSelectedMicroContent
    );

    return baseTemplateJSON;
  };

  const getDraftsProperties = async () => {
    const fabricObj = fabricRef.current;
    const fabricObjects = canvasObjects.current;

    const templateHeightWidth = fabricObj
      ?.getObjects()[0]
      ?.getBoundingRect() || {
      width: 0,
      height: 0,
    };

    const draftData: any = {};

    draftData.id = alphaNumericNanoId();
    draftData.clipId = currentSelectedMicroContent?.clipId;
    draftData.project = currentSelectedProject;
    draftData.aspectRatio = selectedLayout;
    draftData.start = currentSelectedMicroContent.start;
    draftData.end = currentSelectedMicroContent.end;
    draftData.chapter_start = currentSelectedMicroContent.chapter_start;
    draftData.chapter_end = currentSelectedMicroContent.chapter_end;
    draftData.gist = currentSelectedMicroContent?.gist;
    draftData.backgroundColor = backgroundColorAsHex;
    draftData.backgroundImageUrl = getBackgroundImage(fabricObj);
    draftData.height = templateHeightWidth.height;
    draftData.width = templateHeightWidth.width;
    draftData.hasTwoFace = fabricObjects?.videoElDown ? true : false;
    draftData.face_coord = currentSelectedMicroContent.face_coord;

    draftData.subtitle = getTextProperties(
      subtitleTextRef.current,
      templateHeightWidth,
      subsConfig
    );
    if (subtitleTextRef.current && Object.keys(draftData.subtitle).length > 0) {
      draftData.subtitle.style = subsConfig.style;
      draftData.subtitle.noEffect = subsConfig.noEffect;
      draftData.subtitle.shadow = subsConfig.shadow;
      draftData.subtitle.stroke = subsConfig.stroke;
      draftData.subtitle.textBgColor = subsConfig.textBgColor;
      draftData.subtitle.blockBackground = subsConfig.blockBackground;
      draftData.subtitle.textTransform = subsConfig.textTransform;

      draftData.subtitle.fill = subsConfig.font_color;
      draftData.subtitle.fontColor = subsConfig.font_color;
      draftData.subtitle.fontFamily = subsConfig.font_face;
      draftData.subtitle.textAlign = subsConfig.text_align;
      draftData.subtitle.fontSize = subsConfig.font_size;
      draftData.subtitle.underline = subsConfig.underline;
      draftData.subtitle.bold = subsConfig.bold;
      draftData.subtitle.width = getWidthFromGroupText(subtitleTextRef.current);
      draftData.subtitle.text = getTextFromTheGroup(subtitleTextRef.current);
      draftData.subtitle.lineHeight = subsConfig.line_height;
      draftData.subtitle.padding = subsConfig.padding;
      draftData.subtitle.margin = subsConfig.margin;
    } else {
      // saving srt_string to draft data in case
      // user deleted subtitle and saving the draft
      // so when coming back to editor
      // the preview template can still show the default subtitle
      draftData.srt_string = currentSelectedMicroContent.srt_string || "";
    }

    if (subtitleEmojiRef.current) {
      draftData.emojiStyles = { ...subsEmojiConfig.current };
    }

    draftData.autoAddEmojisToSubtitles = autoAddEmojis;
    if (isIntelliClip) {
      draftData.subtitleOption = {
        ...subtitles,
        subsArr: parseSRT(currentSelectedMicroContent.srt_string),
      };
    } else {
      draftData.subtitleOption = subtitles;
    }
    draftData.allSceneChanges = allSceneChanges;
    draftData.imageUrl = currentSelectedMicroContent.imageUrl;
    draftData.progressbar = getProgressBarProperties(
      fabricObjects,
      templateHeightWidth
    );
    draftData.intro = getIntro();
    draftData.texts = getTextsProperties(
      templateHeightWidth,
      textsObj,
      textFabricObj.current
    );
    draftData.socials = await getSocialsProperties(
      socialMediaHandels,
      fabricObj,
      templateHeightWidth,
      textsObj,
      true
    );
    draftData.videoProperties = getVideosProperties(
      fabricObj,
      fabricObjects,
      draftData.hasTwoFace
    );
    draftData.videoProperties = enableSceneChange
      ? modifyVideoPropertiesForCutMagic(draftData.videoProperties)
      : draftData.videoProperties;

    draftData.outro = getOutro(currentOutro);

    draftData.logos = await getUserUploadedImageProperties(
      fabricObj,
      templateHeightWidth,
      logoRef.current,
      AssetTags.LOGO
    );

    draftData.bRolls = await getUserUploadedBRollsProperties({
      templateHeightWidth,
      bRollsRef: bRollsRef.current,
      assetTag: AssetTags.B_ROLL,
      isDraft: true,
    });

    draftData.videoPosition = getVideoPositionForDraft(fabricObjects);
    // add tag_id and clip_src for IntelliClip
    draftData.tag = currentSelectedMicroContent.tag;
    draftData.clip_src = isIntelliClip
      ? changeStageUrlToProd(currentSelectedMicroContent.clip_src)
      : currentSelectedMicroContent.clip_src;
    draftData.audioClips = getAudioClips(
      audioElements,
      currentSelectedMicroContent
    );

    return draftData;
  };

  const bringSubsToFront = () => {
    if (fabricRef.current) {
      const objects = fabricRef.current.getObjects();
      // find if subs is already in canvas
      const isSubsPresentOnCanvas = objects.find((object: any) => {
        return object?.type === TextElementType.SUBTITLE;
      });

      if (isSubsPresentOnCanvas && subtitleTextRef.current) {
        fabricRef.current.bringToFront(subtitleTextRef.current);
      }
    }
  };

  const reorganizeCanvasObjects = (skipLogoAndBRolls = false) => {
    if (fabricRef.current) {
      if (!skipLogoAndBRolls) {
        Object.values(logoRef.current).forEach((obj: any) => {
          fabricRef.current.bringToFront(obj.asset);
        });
        Object.values(bRollsFabricRef.current).forEach((obj: any) => {
          fabricRef.current.bringToFront(obj.asset);
        });
      }

      if (progressBarRef.current) {
        fabricRef.current.bringToFront(progressBarRef.current);
      }
      Object.values(textFabricObj.current).forEach((obj) => {
        fabricRef.current.bringToFront(obj);
      });

      bringSubsToFront();

      fabricRef.current.requestRenderAll();
    }
  };

  const getVideoDimensions = () => {
    if (currentSelectedProject && currentSelectedProject.data) {
      return {
        originalVideoDimensions: {
          width: JSON.parse(currentSelectedProject.data).source_width,
          height: JSON.parse(currentSelectedProject.data).source_height,
        },
        currentVideoDimensions: {
          width: videoElRef.current?.videoWidth,
          height: videoElRef.current?.videoHeight,
        },
      };
    }
  };

  const handleMediaModificationForUndoRedo = (mediaMetaData: any) => {
    // so each time user choose to undo
    // we are removing the media element and creating
    // a new one according to the available metaData
    // in the command itself
    handleRemoveAsset(mediaMetaData?.assetId);
    handleAddAsset(mediaMetaData);
  };

  const applyBRollMediaCanvasModification = (metaData: any) => {
    const command = modifyMediaOnCanvasCommand(
      metaData,
      handleMediaModificationForUndoRedo
    );

    // not executing the command here
    // because we don't need to remove the element
    // and create a new one
    handleAddAsset(metaData?.newMediaData);

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const applyLogoMediaCanvasModification = (metaData: any) => {
    const command = modifyLogoOnCanvasCommand(
      metaData,
      handleMediaModificationForUndoRedo
    );

    // not executing the command here
    // because we don't need to remove the element
    // and create a new one
    handleAddAsset(metaData?.newMediaData);

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const isIdPresentOnCanvas = (id: string) => {
    const objects = fabricRef.current?.getObjects();
    // Using Array.prototype.some() to check if any object has the specified ID
    return objects.some((obj: any) => obj.id === id);
  };

  const handleAddAsset = ({
    url,
    file,
    assetTag,
    assetType,
    metaData,
    onlyAddToCanvas = false,
    assetId,
    assetWidth = 100,
    assetHeight = 100,
    top,
    left,
  }: {
    url: string;
    file: any;
    assetTag?: AssetTags;
    metaData?: any;
    assetType?: string;
    onlyAddToCanvas?: boolean;
    assetId?: string;
    assetWidth?: number;
    assetHeight?: number;
    top?: number;
    left?: number;
  }) => {
    if (fabricRef.current && backgroundClipPathRef.current) {
      const uid = assetId || nanoid();
      //centering the asset on the canvas
      const centerLeft =
        backgroundClipPathRef.current.left +
        (backgroundClipPathRef.current.width - assetWidth) / 2;
      const centerTop =
        backgroundClipPathRef.current.top +
        (backgroundClipPathRef.current.height - assetHeight) / 2;
      const assetLeft = left || centerLeft;
      const assetTop = top || centerTop;
      const defaultHeight = 100;
      const defaultWidth = 100;

      if (!onlyAddToCanvas) {
        PubSub.publish(EDITOR_MEDIA_LOAD_STATE, {
          id: uid,
          status: EditorMediaStatus.LOADING,
        });
      }

      if (assetType?.includes(SimpleAssetType.VIDEO)) {
        let videoE = getVideoElement(url, () => {
          const videoObj = getFabricVideo({
            left: assetLeft,
            top: assetTop,
            videoElement: videoE,
            id: uid,
            type: ImageType.USER_UPLOADED,
            assetType: SimpleAssetType.VIDEO,
            assetTag,
            clipPath: backgroundClipPathRef.current,
          });

          const customClipPath = {
            height: assetHeight || defaultHeight,
            width: assetWidth || defaultWidth,
            top: assetTop,
            left: assetLeft,
          };

          centerFaceInClipPath({
            fabricVideoElement: videoObj,
            clipPath: customClipPath,
            allFaceCoords: [],
            videoLayout:
              selectedLayout === VideoLayout.LAYOUT_9_16_2
                ? VideoLayout.LAYOUT_9_16_1
                : selectedLayout,
          });
          const currentPlayerTimeInMillis =
            (videoElRef.current?.currentTime || 0) * 1000;
          videoE.currentTime =
            Math.abs((metaData?.start - currentPlayerTimeInMillis) / 1000) || 0;

          // For some reason the callback running twice
          // So adding a check if id is already present
          // so not adding duplicate element to the fabric
          if (!isIdPresentOnCanvas(uid)) {
            fabricRef.current.add(videoObj);
          }

          videoObj.on("selected", () => {
            handelChangeSideBarMenu(
              editorSubRoutes.MEDIA,
              EditorMediaTab.VIDEO
            );
          });

          const newAssetItem = {
            url,
            uid,
            metaData,
            assetType: assetType?.includes(SimpleAssetType.VIDEO)
              ? SimpleAssetType.VIDEO
              : SimpleAssetType.IMAGE,
            videoEl: videoE,
            assetTag,
          };

          const newAssetFabricRefItem = {
            asset: videoObj,
            file,
            ...newAssetItem,
            metaData: {
              ...newAssetItem.metaData,
              left: assetLeft,
              top: assetTop,
              assetWidth: assetWidth || defaultWidth,
              assetHeight: assetHeight || defaultHeight,
            },
            isLoadedOnCanvas: true,
          };
          setBRolls((prev: any) => {
            return {
              ...prev,
              [uid]: newAssetFabricRefItem,
            };
          });

          if (!onlyAddToCanvas) {
            PubSub.publish(EDITOR_MEDIA_LOAD_STATE, {
              id: uid,
              status: EditorMediaStatus.READY,
            });
          }

          bRollsFabricRef.current[uid] = newAssetFabricRefItem;
          videoObj.on("modified", (e: any) => {
            onAssetModified({
              fabricEl: e.target,
              bRolls: bRollsRef.current,
              newUpdatedAsset: e.target,
              applyMediaCanvasModification: applyBRollMediaCanvasModification,
              assetTag: AssetTags.B_ROLL,
            });
          });

          // hack to render subtitle on top
          bringSubsToFront();

          if (!onlyAddToCanvas) {
            dispatch(
              setAssetsUrl([
                ...assetUrls,
                {
                  ...newAssetItem,
                },
              ])
            );
          }
        });
      } else {
        fabric.Image.fromURL(url, function (img: any) {
          img.scaleToWidth(assetWidth || defaultWidth).set({
            left: assetLeft,
            top: assetTop,
            id: uid,
            type: ImageType.USER_UPLOADED,
            assetType: SimpleAssetType.IMAGE,
            assetTag,
            clipPath: backgroundClipPathRef.current,
          });

          img.on("selected", () => {
            handelChangeSideBarMenu(
              assetTag === AssetTags.LOGO
                ? editorSubRoutes.ELEMENTS
                : editorSubRoutes.MEDIA,
              EditorMediaTab.IMAGE
            );
            if (assetTag === AssetTags.LOGO) {
              handelChangeElementsTab(SOCIALS);
            }
          });

          // For some reason the callback running twice
          // So adding a check if id is already present
          // so not adding duplicate element to the fabric
          if (!isIdPresentOnCanvas(uid)) {
            fabricRef.current?.add(img);
          }

          const newAssetItem = {
            url,
            uid,
            metaData,
            assetType: assetType?.includes(SimpleAssetType.VIDEO)
              ? SimpleAssetType.VIDEO
              : SimpleAssetType.IMAGE,
            assetTag,
          };
          const newAssetFabricRefItem = {
            asset: img,
            file,
            ...newAssetItem,
            metaData: {
              ...newAssetItem.metaData,
              left: assetLeft,
              top: assetTop,
              assetWidth: assetWidth || defaultWidth,
              assetHeight: assetHeight || defaultHeight,
            },
            isLoadedOnCanvas: true,
          };
          if (assetTag?.includes(AssetTags.B_ROLL)) {
            if (!onlyAddToCanvas) {
              PubSub.publish(EDITOR_MEDIA_LOAD_STATE, {
                id: uid,
                status: EditorMediaStatus.READY,
              });
            }
            setBRolls((prev: any) => {
              return {
                ...prev,
                [uid]: newAssetFabricRefItem,
              };
            });

            bRollsFabricRef.current[uid] = newAssetFabricRefItem;
          } else {
            logoRef.current[uid] = newAssetFabricRefItem;
          }

          img.on("modified", (e: any) => {
            if (e?.target?.assetTag?.includes(AssetTags.B_ROLL)) {
              onAssetModified({
                fabricEl: e.target,
                bRolls: bRollsRef.current,
                newUpdatedAsset: e.target,
                applyMediaCanvasModification: applyBRollMediaCanvasModification,
                assetTag: AssetTags.B_ROLL,
              });
            }

            if (e?.target?.assetTag?.includes(AssetTags.LOGO)) {
              onAssetModified({
                fabricEl: e.target,
                bRolls: logoRef.current,
                newUpdatedAsset: e.target,
                applyMediaCanvasModification: applyLogoMediaCanvasModification,
                assetTag: AssetTags.LOGO,
              });
            }
          });

          // hack to render subtitle on top
          bringSubsToFront();

          if (metaData?.isTemplate || metaData?.isDraft) {
            // we only need to run it for the first time
            reorganizeCanvasObjects(isIdPresentOnCanvas(uid));
          }

          if (!onlyAddToCanvas) {
            dispatch(setAssetsUrl(Object.values(logoRef.current)));
          }
        });
      }
    }
  };

  const removeFromFabricRefById = (assetId: string | number) => {
    const allObjects = fabricRef.current.getObjects();
    const objectToRemove = allObjects.find((obj: any) => obj.id === assetId);
    if (objectToRemove) {
      fabricRef.current.remove(objectToRemove);
    }
  };

  /**
   * function to remove the asset from the canvas and from the state
   *
   * @param id asset id
   * @param onlyRemoveFromCanvas if true only remove from canvas
   * @param removeFromAll clear all the references
   * @returns
   */

  const handleRemoveAsset = (
    id: string | number,
    onlyRemoveFromCanvas = false,
    removeFromAll = false
  ) => {
    if (!onlyRemoveFromCanvas) {
      dispatch(setAssetsUrl(assetUrls.filter((asset) => asset.uid !== id)));

      // Check and remove logo if it exists
      if (logoRef.current[id]) {
        removeFromFabricRefById(id);
        delete logoRef.current[id];
      }
    } else {
      // Handle only canvas removal
      removeFromFabricRefById(id);
    }

    if (removeFromAll) {
      removeFromFabricRefById(id);
      delete bRollsRef.current[id];
      delete bRollsFabricRef.current[id];
      delete logoRef.current[id];

      setBRolls(bRollsRef.current);
      return;
    }

    // Clean up bRollsFabricRef if it exists
    // and also set the bRolls object
    if (bRollsFabricRef.current[id]) {
      removeFromFabricRefById(id);
      delete bRollsFabricRef.current[id];
      if (!onlyRemoveFromCanvas) {
        setBRolls(bRollsFabricRef.current);
      }
    }
  };

  const handelAddSocialAssets = ({
    url,
    position,
    uid,
    logoWidth,
    textContent,
  }: {
    url: string;
    position: any;
    uid: string;
    logoWidth?: number;
    textContent?: string | null | undefined;
  }) => {
    fabric.Image.fromURL(url, (img: any) => {
      img.scaleToWidth(logoWidth ? logoWidth : 20).set({
        left: position.left,
        top: position.top,
        id: uid,
        clipPath: backgroundClipPathRef.current,
      });
      img.on("selected", () => {
        handelChangeSideBarMenu(editorSubRoutes.ELEMENTS);
        handelChangeElementsTab(SOCIALS);
      });

      if (!isIdPresentOnCanvas(uid)) {
        fabricRef.current?.add(img);
      }

      img.on("modified", (e: any) => {
        onAssetModified({
          fabricEl: e.target,
          bRolls: logoRef.current,
          newUpdatedAsset: e.target,
          applyMediaCanvasModification: applyLogoMediaCanvasModification,
          assetTag: AssetTags.LOGO,
        });
      });

      logoRef.current[uid] = {
        asset: img,
        url,
        uid,
        textContent: textContent,
        assetTag: AssetTags.LOGO,
        metaData: {
          left: position.left,
          top: position.top,
          assetWidth: logoWidth || 20,
          isSocialLogo: true,
        },
      };
    });
  };

  const addSocialTemplatesToCanvas = ({
    logoDetails,
    textDetails,
    isNewSocial,
  }: {
    logoDetails: any;
    textDetails: any;
    isNewSocial: boolean;
  }) => {
    ////centering the Logos on the canvas
    const centerLeft =
      backgroundClipPathRef.current.left +
      (backgroundClipPathRef.current.width - BUFFER_FOR_CENTERED.logo.left) / 2;

    const centerTop =
      backgroundClipPathRef.current.top +
      (backgroundClipPathRef.current.height - BUFFER_FOR_CENTERED.logo.top) / 2;

    handelAddSocialAssets({
      url: logoDetails.url,
      position: {
        top: isNewSocial ? centerTop : logoDetails.top,
        left: isNewSocial ? centerLeft : logoDetails.left,
      },
      uid: logoDetails.id,
      logoWidth: logoDetails.width,
      textContent: textDetails?.textContent,
    });
    if (textDetails) {
      addTextObject({
        initPosition: {
          top: isNewSocial ? centerTop : textDetails.top,
          left: isNewSocial ? centerLeft + 25 : textDetails.left,
        }, //NOTE: Added 25px offset because text and logo doesn't overlap
        ...textDetails,
        Id: `${logoDetails.id}_text`,
        isSocialText: true,
      });
    }
  };

  const sendZeroIfNegative = (val: number) => {
    return val > 0 ? val : 0;
  };

  function handelProgressBarAnimation(
    timeStamp: number,
    isFullWidth?: boolean
  ) {
    const totalVideoLength =
      currentSelectedMicroContent?.end / 1000 -
        currentSelectedMicroContent?.start / 1000 ?? 0;
    const pxPerSec = progressBarRef?.current?.maxWidth / totalVideoLength;
    const val = progressBarRef?.current;
    if (val) {
      if (isFullWidth) {
        val.set("width", progressBarRef?.current?.maxWidth);
      } else {
        val.set(
          "width",
          sendZeroIfNegative(
            pxPerSec * (timeStamp - currentSelectedMicroContent?.start / 1000)
          )
        );
      }
      val.set("left", backgroundClipPathRef.current?.left || val.left);
      val.set("dirty", true);
      progressBarRef.current = val;
      canvasObjects.current.progressBarRect.setCoords();
    }
  }

  const updateProgressBarAnimation = () => {
    if (
      (videoElRef.current?.currentTime || 0) * 1000 <=
      currentSelectedMicroContent.end
    ) {
      handelProgressBarAnimation(videoElRef.current?.currentTime ?? 0);
    }
  };

  const handelAddSocialsToTemplateCanvas = (
    currentTemplate: any,
    templateScaleUpRatio: number,
    initialPosition: any
  ) => {
    const localSocialMedias: any = [];
    currentTemplate.socials.forEach((social: any) => {
      // updating the social content with user preference
      userSocialMediaHandles.forEach((socialHandel: any) => {
        if (socialHandel.id === social.image.id) {
          let updatedSocial = { ...social };

          // Checking if currentTemplate is draft,
          // if so then we need to show the social handle as it is,
          // else we need to show the social handle with the user preference if available,
          // assuming template does not have allSceneChanges property

          let updatedSocialMediaHandle = currentTemplate.hasOwnProperty(
            "allSceneChanges"
          )
            ? social?.text?.content || ""
            : socialHandel.socialMediaHandle || social.text.content;

          updatedSocial = {
            ...updatedSocial,
            text: {
              ...updatedSocial.text,
              content: updatedSocialMediaHandle,
            },
          };
          social = updatedSocial;
        }
      });

      const socialLogoDetails = {
        top: social.image.top * templateScaleUpRatio + initialPosition.top,
        left: social.image.left * templateScaleUpRatio + initialPosition.left,
        id: social.image.id,
        url: social.image.url,
        width: social.image.width * templateScaleUpRatio,
        type: SimpleAssetType.IMAGE,
        // @ts-ignore
        labelName: SOCIAL_MEDIA_HANDLES_MAP[social.image.id],
      };
      let socialTextDetails: any = undefined;

      if (hasMoreThanKeys(social.text, 2)) {
        socialTextDetails = {
          top: social.text.top * templateScaleUpRatio + initialPosition.top,
          left: social.text.left * templateScaleUpRatio + initialPosition.left,
          textContent: social.text.content,
          textSize: social.text.fontSize * templateScaleUpRatio,
          fontColor: social.text.fontColor,
          fontFace: social.text.fontFamily,
          effectType: social.text.effect_type,
          templateId: social.text.content,
          textAlignment: social.text.textAlign,
          textBold: social.text.bold,
          lineHeight: social.text.lineHeight || MIN_LINE_HEIGHT,
          noEffect: social.text.noEffect,
          shadow: social.text.shadow,
          stroke: social.text.stroke,
          textBgColor: social.text.textBgColor,
          blockBackground: social.text.blockBackground,
          hasNoEffectKey: social.text.hasOwnProperty("noEffect"),
          start: social.text?.start,
          end: social.text?.end,
          isFullVideoLength: social.text?.isFullVideoLength,
          textUnderLine: social.text.underline,
          textItalic: social.text.italic,
          padding: social.text.padding || INIT_TEXT_STYLES.padding,
          margin: social.text.margin || INIT_TEXT_STYLES.margin,
          textTransform: social.text.textTransform,
        };
      }

      localSocialMedias.push({ ...socialLogoDetails, ...socialTextDetails });

      addSocialTemplatesToCanvas({
        logoDetails: socialLogoDetails,
        textDetails: socialTextDetails,
        isNewSocial: false,
      });
    });
    // update localSocialMedias with SOCIAL_MEDIA_HANDLES

    dispatch(setSocialMediaHandels(localSocialMedias));
  };

  const handelAddUserUploadedImagesToCanvas = (
    currentTemplate: any,
    templateScaleUpRatio: number,
    initialPosition: any
  ) => {
    let assetUrlArr: any = [...assetUrls];

    currentTemplate?.images?.forEach((image: any) => {
      const userUploadedImages = {
        top: image.top * templateScaleUpRatio + initialPosition.top,
        left: image.left * templateScaleUpRatio + initialPosition.left,
        id: image.id,
        url: image.url,
        width: image.width * templateScaleUpRatio,
        assetType: SimpleAssetType.IMAGE,
        assetTag: AssetTags.LOGO,
        metaData: {
          start: microContentStart,
          end: microContentEnd,
          isFullVideoLength: true,
        },
      };

      const { url, id, metaData, assetType, width, left, top, assetTag } =
        userUploadedImages;

      if (!assetUrlArr.find((el: any) => el.uid === image.id)) {
        assetUrlArr.push({
          url,
          uid: id,
          metaData,
          assetType,
          assetTag,
        });
      }

      handleAddAsset({
        url,
        metaData,
        file: null,
        assetId: id,
        assetTag,
        assetType,
        assetWidth: width,
        top,
        left,
        onlyAddToCanvas: true,
      });
    });

    dispatch(setAssetsUrl(assetUrlArr));
  };

  const handlePreLoadedAssets = (
    userUploadedImages: any,
    assetUrlArr: any,
    asset: any
  ) => {
    const { url, id, metaData, assetType, width, left, top, assetTag } =
      userUploadedImages;

    if (!assetUrlArr.find((el: any) => el.uid === asset.id)) {
      assetUrlArr.push({
        url,
        uid: id,
        metaData,
        assetType,
        assetTag,
      });
    }

    handleAddAsset({
      url,
      metaData,
      file: null,
      assetId: id,
      assetTag,
      assetType,
      assetWidth: width,
      top,
      left,
      onlyAddToCanvas: true,
    });
  };

  const handelAddUserUploadedLogosToCanvas = (
    currentTemplate: any,
    templateScaleUpRatio: number,
    initialPosition: any
  ) => {
    let assetUrlArr: any = [...assetUrls];

    currentTemplate?.logos?.forEach((image: any) => {
      const userUploadedImages = {
        top: image.top * templateScaleUpRatio + initialPosition.top,
        left: image.left * templateScaleUpRatio + initialPosition.left,
        id: image.id,
        url: image.url,
        width: image.width * templateScaleUpRatio,
        assetType: SimpleAssetType.IMAGE,
        assetTag: AssetTags.LOGO,
        metaData: {
          start: microContentStart,
          end: microContentEnd,
          isFullVideoLength: true,
        },
      };
      handlePreLoadedAssets(userUploadedImages, assetUrlArr, image);
    });

    dispatch(setAssetsUrl(assetUrlArr));
  };

  const handelAddUserUploadedBRollsToCanvas = (
    currentTemplate: any,
    templateScaleUpRatio: number,
    initialPosition: any
  ) => {
    let assetUrlArr: any = [...assetUrls];

    currentTemplate?.bRolls?.forEach((asset: any) => {
      //  check for if template has bRolls,
      // if bRolls has metaData.isTemplate then use it
      // else use the default metaData
      //
      let metaData = {
        ...asset.metaData,
      };
      if (asset.metaData?.isTemplate) {
        metaData = {
          ...asset.metaData,
          start: microContentStart,
          end: microContentEnd,
          isFullVideoLength: true,
        };
      }
      const userUploadedAsset = {
        top: asset.top * templateScaleUpRatio + initialPosition.top,
        left: asset.left * templateScaleUpRatio + initialPosition.left,
        id: asset.id,
        url: asset.url,
        width: asset.width * templateScaleUpRatio,
        assetType: asset.assetType,
        assetTag: asset.assetTag,
        metaData: metaData,
      };

      handlePreLoadedAssets(userUploadedAsset, assetUrlArr, asset);
    });

    dispatch(setAssetsUrl(assetUrlArr));
  };

  const handleUpdateProgressBarPositionToRedux = (
    position: ProgressBarPosition
  ) => {
    dispatch(setProgressBarPosition(position));
  };

  const onModifyProgressBarPosition = (
    progressBarPosition: ProgressBarPosition
  ) => {
    const { top } = progressBarPosition;
    if (progressBarRef.current) {
      canvasObjects.current.progressBarRect.height =
        progressBarRef.current.height;
      canvasObjects.current.progressBarRect.top = top;
      canvasObjects.current.progressBarRect.dirty = true;
      canvasObjects.current.progressBarRect.setCoords();
      handleUpdateProgressBarPositionToRedux(progressBarPosition);
    }
  };

  const changeProgressBarPosition = (progressBarElement: any) => {
    const metaData = {
      newPosition: {
        top: progressBarElement.top,
        left: progressBarElement.left,
      } as ProgressBarPosition,
      oldPosition: store.getState().editorState.progressBarPosition,
    };
    const command = changeProgressBarPositionCommand(
      metaData,
      onModifyProgressBarPosition
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  // * Good candidate for helper function return the progressbar element
  const handelAddProgressBarToTemplateCanvas = (
    currentTemplate: any,
    templateScaleUpRatio: number,
    initialPosition: any
  ) => {
    if (fabricRef.current) {
      const progressBarRect = getProgressBar({
        height: currentTemplate.progressbar.height * templateScaleUpRatio,
        top:
          initialPosition.top +
          currentTemplate.progressbar.top * templateScaleUpRatio,
        left: initialPosition.left,
        maxWidth: currentTemplate.width * templateScaleUpRatio,
        fill: currentTemplate.progressbar.fill,
        clipPath: backgroundClipPathRef.current,
      });
      handleUpdateProgressBarPositionToRedux({
        top: progressBarRect.top,
        left: progressBarRect.left,
      });
      progressBarRect.on("selected", () => {
        handelChangeSideBarMenu(editorSubRoutes.ELEMENTS);
        handelChangeElementsTab(PROGRESS_BAR);
      });
      progressBarRect.on("modified", ({ transform }: any) => {
        changeProgressBarPosition(transform.target);
      });

      progressBarRef.current = progressBarRect;

      canvasObjects.current.progressBarRect = progressBarRect;
      fabricRef.current.add(progressBarRect);
      handelChangeProgresssbarHeight(progressBarRect.height);
      dispatch(setProgressBarColor(progressBarRect.fill));
    }
  };

  // * Good candidate for helper function
  const handelAddPredefinedTextToTemplateCanvas = (
    currentTemplate: any,
    templateScaleUpRatio: number,
    initialPosition: any,
    textWidth: number
  ) => {
    currentTemplate.texts.forEach((text: any, index: number) => {
      // bellow code is needed to update the new text configuration
      addTextObject({
        margin: text.margin || INIT_TEXT_STYLES.margin,
        padding: text.padding || INIT_TEXT_STYLES.padding,
        initPosition: {
          left: text.left * templateScaleUpRatio + initialPosition.left,
          top: text.top * templateScaleUpRatio + initialPosition.top,
        },
        textSize: ~~text.fontSize * templateScaleUpRatio,
        textAlign: text.textAlign,
        textAlignment: text.textAlign,
        fontFace: text.fontFamily,
        textContent: text.content || text.text,
        textBoxWidth: text.width
          ? text.width * templateScaleUpRatio
          : textWidth,
        Id: index === 0 ? ID_TITLE_TEXT : null,
        effectColor: text.effect_color,
        effectType: text.effect_type,
        textBold: text.bold,
        textItalic: text.italic,
        textUnderLine: text.underline,
        fontColor: text.fill,
        maxHeight: text.maxHeight * templateScaleUpRatio,
        lineHeight: text.lineHeight || MIN_LINE_HEIGHT,
        start: selectedEditorDraft ? text.start : microContentStart,
        end: selectedEditorDraft ? text.end : microContentEnd,
        isFullVideoLength: selectedEditorDraft ? text.isFullVideoLength : true,
        noEffect: text.noEffect,
        shadow: text.shadow,
        stroke: text.stroke,
        textBgColor: {
          ...INIT_TEXT_STYLES.textBgColor,
          ...text.textBgColor,
        },
        blockBackground: {
          ...INIT_TEXT_STYLES.blockBackground,
          ...text.blockBackground,
        },
        hasNoEffectKey: text.hasOwnProperty("noEffect"),
        textTransform: text.textTransform,
      });
    });
  };

  const handelAddBackgroundClipPathToTemplateCanvasAndReturnClippath = (
    initialValues: any,
    currentTemplate: any
  ) => {
    const backGroundClipPath = getClipPath({
      width: initialValues.width,
      height: initialValues.height,
      left: initialValues.left,
      top: initialValues.top,
      backgroundColor: currentTemplate.backgroundImageUrl
        ? null
        : currentTemplate?.backgroundColor,
    });

    backgroundClipPathRef.current = backGroundClipPath;

    fabricRef.current.add(backGroundClipPath);

    canvasObjects.current.backGroundClipPath = backGroundClipPath;
    fabricRef.current.bringToFront(backGroundClipPath);
    return backGroundClipPath;
  };

  // useEffect(() => {
  //   if (fabricRef.current && backgroundClipPathRef.current) {
  //     const line_v = new fabric.Line(
  //       [
  //         backgroundClipPathRef.current.left,
  //         fabricRef.current.height / 2,
  //         backgroundClipPathRef.current.width +
  //           backgroundClipPathRef.current.left,
  //         fabricRef.current.height / 2,
  //       ],
  //       {
  //         opacity: 0,
  //         stroke: "red",
  //         selectable: false,
  //         evented: false,
  //         id: "line_v",
  //         clipPath: backgroundClipPathRef.current,
  //       }
  //     );

  //     const line_h = new fabric.Line(
  //       [
  //         fabricRef.current.width / 2,
  //         backgroundClipPathRef.current.top,
  //         fabricRef.current.width / 2,
  //         backgroundClipPathRef.current.height +
  //           backgroundClipPathRef.current.top,
  //       ],
  //       {
  //         opacity: 0,
  //         stroke: "red",
  //         selectable: false,
  //         evented: false,
  //         id: "line_h",
  //         clipPath: backgroundClipPathRef.current,
  //       }
  //     );

  //     fabricRef.current.add(line_h);
  //     fabricRef.current.add(line_v);
  //     fabricRef.current.renderAll();

  //     fabricRef.current.on("object:moving", function (e: any) {
  //       fabricRef.current &&
  //         centerLines(line_h, line_v, fabricRef, e, backgroundClipPathRef);
  //     });
  //     fabricRef.current.on("object:scaling", function (e: any) {
  //       fabricRef.current &&
  //         centerLines(line_h, line_v, fabricRef, e, backgroundClipPathRef);
  //     });
  //     fabricRef.current.on("object:resizing", function (e: any) {
  //       fabricRef.current &&
  //         centerLines(line_h, line_v, fabricRef, e, backgroundClipPathRef);
  //     });
  //     fabricRef.current.on("mouse:up", function (opt: any) {
  //       line_h.opacity = 0;
  //       line_v.opacity = 0;
  //     });
  //   }
  // }, [backgroundClipPathRef.current]);

  const handelChangeOutro = (outro: any) => {
    if (!outro) {
      // while deleting outro
      // making the outroTimeLeft to 0 for the effect to trigger
      // which call removeOutroFromCanvas()
      setOutroTimeLeft(0);
      setCurrentOutro(null);
      return;
    }

    const commonOutroProps = {
      left: canvasObjects.current.outroClippath.left,
      top: canvasObjects.current.outroClippath.top,
      clipPath: canvasObjects.current.outroClippath,
      id: OUTRO_ASSET,
      selectable: false,
      hasControls: false,
    };
    handleAddOutroAsset({
      outro,
      backGroundClipPath: canvasObjects.current.outroClippath,
      commonProps: commonOutroProps,
      fabricObj: fabric,
      canvasObjects,
      outroVideoRef,
    });
    setCurrentOutro(outro);
    dispatch(
      updateOutroLengthInSecond(outro?.duration || DEFAULT_OUTRO_LENGTH_IN_SEC)
    );
  };

  useUnmount(() => outroVideoRef.current?.pause());

  useEffect(() => {
    // this effect is needed to set the current sidebar menu
    // when user refresh the page
    // Split the current URL pathname into segments.
    if (location.pathname) {
      const pathnameSegments = location.pathname.split("/");
      // Extract the last segment of the pathname.
      // 'pop()' removes and returns the last element of the array.
      const lastSegment = pathnameSegments.pop();
      dispatch(setCurrentSideMenu(lastSegment || ""));
    }
  }, []);

  useEffect(() => {
    audioElementsRef.current = audioElements;
  }, [JSON.stringify(audioElements)]);

  const removeAudioAsset = (id: any) => {
    // Pause and reset the audio element if it's playing
    const audioElement = audioElementsRef.current[id]?.audioElement;
    if (audioElement && !audioElement.paused) {
      audioElement.pause();
      audioElement.src = "";
    }

    // Remove the audio element from audioElementsRef
    const updatedAudioElements = { ...audioElementsRef.current };
    delete updatedAudioElements[id];

    // Update the state with the new value of audioElementsRef.current
    setAudioElements(updatedAudioElements);
  };

  const defaultTextTransform = (currentTemplate: any) => {
    if (
      currentTemplate.subtitle.textTransform &&
      currentTemplate.subtitle.textTransform !== ""
    ) {
      onFormatSubtitleText(currentTemplate.subtitle.textTransform);
    } else {
      onFormatSubtitleText(TEXT_TRANSFORM.LOWERCASE);
    }
  };

  const addTemplateElementsToCanvas = ({
    initialPosition,
    currentTemplate,
    templateScaleUpRatio,
    textWidth,
  }: any) => {
    updateEmojiPositionConfig();

    const outroClippath = getClipPath({
      width: initialPosition.width,
      height: initialPosition.height,
      left: initialPosition.left,
      top: initialPosition.top,
    });

    canvasObjects.current.outroClippath = outroClippath;

    if (currentTemplate.outro) {
      handelChangeOutro(currentTemplate.outro);
    }

    // if current selected template does not have subtitle properties
    // call initSubs to remove the subs so that the user can generate new subs

    if (hasMoreThanKeys(currentTemplate?.subtitle, 2)) {
      updateSubsConfig(
        currentTemplate,
        templateScaleUpRatio,
        setSubsConfig,
        subsEmojiConfig
      );
      selectedEditorDraft || defaultTextTransform(currentTemplate);

      if (isIntelliClip) {
        // checking if srt exists or not, if present, parse it to subsArr
        currentSelectedMicroContent.srt_string &&
          fetchAndUpdateSubs(
            Math.floor(currentSelectedMicroContent.start),
            Math.floor(currentSelectedMicroContent.end)
          );
      } else {
        // checking subtitles in the current template, if present fetch subtitles
        subsArr.length ||
          fetchAndUpdateSubs(
            Math.floor(currentSelectedMicroContent.start),
            Math.floor(currentSelectedMicroContent.end)
          );
      }
    } else {
      initSubs();
    }

    if (currentTemplate.socials.length) {
      handelAddSocialsToTemplateCanvas(
        currentTemplate,
        templateScaleUpRatio,
        initialPosition
      );
    }

    // can remove after 1 month 16/03/2023, user will most probably have updated their templates to bRolls, otherwise we can deprecate the "images" filed from the userTemplates
    if (currentTemplate?.images?.length) {
      handelAddUserUploadedImagesToCanvas(
        currentTemplate,
        templateScaleUpRatio,
        initialPosition
      );
    }

    if (currentTemplate?.bRolls?.length) {
      handelAddUserUploadedBRollsToCanvas(
        currentTemplate,
        templateScaleUpRatio,
        initialPosition
      );
    }

    if (currentTemplate?.audioClips?.length) {
      handleAddAudioClipsToCanvas(
        currentTemplate,
        setAudioElements,
        currentSelectedMicroContent
      );
    }

    if (currentTemplate?.logos?.length) {
      handelAddUserUploadedLogosToCanvas(
        currentTemplate,
        templateScaleUpRatio,
        initialPosition
      );
    }

    handelAddProgressBarToTemplateCanvas(
      currentTemplate,
      templateScaleUpRatio,
      initialPosition
    );

    if (currentTemplate?.texts.length > 0) {
      handelAddPredefinedTextToTemplateCanvas(
        currentTemplate,
        templateScaleUpRatio,
        initialPosition,
        textWidth
      );
    }
  };

  const deleteOutroAsset = () => {
    // while deleting outro
    // making the outroTimeLeft to 0 for the effect to trigger
    // which call removeOutroFromCanvas()
    setOutroTimeLeft(0);
    setCurrentOutro(null);
  };

  const addBackgroundImageToTemplate = ({
    backGroundClipPath,
    imgUrl,
    addVideoProps,
  }: any) => {
    const img = document.createElement("img");
    img.src = imgUrl;

    img.onload = function () {
      const bgImg = new fabric.Image(img, {
        renderOnAddRemove: false,
        left: backGroundClipPath.left,
        top: backGroundClipPath.top,
        clipPath: backGroundClipPath,
        id: "backgroundImage",
        selectable: false,
      });
      bgImg.set({
        clipPath: backgroundClipPathRef.current,
        scaleY: backGroundClipPath.height / bgImg.height,
        scaleX: backGroundClipPath.width / bgImg.width,
        top: backGroundClipPath.top,
      });
      fabricRef.current && fabricRef.current.add(bgImg);
      canvasObjects.current.backgroundImg = bgImg;
      dispatch(updateIsBackgroundImageLoaded(true));
      if (fabricRef.current) {
        fabricRef.current.sendToBack(bgImg);
        fabricRef.current.bringForward(bgImg);
        fabricRef.current.renderAll();
      }
      if (addVideoProps) {
        addVideoProps();
      }
    };
  };

  const getCanvasClipPathDimensions = (
    fabricCanvas: any,
    clipPathHeight: number,
    clipPathWidth: number
  ) => {
    let left = 0;
    let top = 0;

    // get left and top to center the clippath inside the canvas
    if (fabricCanvas.width > clipPathWidth) {
      left = (fabricCanvas.width - clipPathWidth) / 2;
    }

    if (fabricCanvas.height > clipPathHeight) {
      top = (fabricCanvas.height - clipPathHeight) / 2;
    }

    return {
      left,
      top,
      width: clipPathWidth,
      height: clipPathHeight,
    };
  };

  const allSceneChanges = useAppSelector(
    (state) => state.editorState.allSceneChanges
  );

  const timelineOffset = currentSelectedMicroContent.chapter_start;
  const videoDuration =
    JSON.parse(currentSelectedProject?.data || "{}").duration || 0;

  const getInitialTemplateData = ({
    layout,
    videoHeight,
    videoWidth,
  }: {
    layout: VideoLayout;
    videoHeight: number;
    videoWidth: number;
  }) => {
    const userSavedTemplates = getTemplatesForLayout(
      layout,
      userTemplatesData?.data
    );

    const currentTemplate = selectedEditorDraft
      ? selectedEditorDraft
      : getCurrentSelectedTemplate(
          layout,
          currentSelectedMicroContent,
          userSavedTemplates,
          defaultBaseTemplates?.data
        );

    const templateScaleUpRatio = calculateScaleUpSizeFromBaseTemplate(
      currentTemplate.height,
      videoHeight
    );

    const initialValuesForBackgroundClipPath = getCanvasClipPathDimensions(
      fabricRef.current,
      videoHeight,
      videoWidth
    );

    const backGroundClipPath =
      handelAddBackgroundClipPathToTemplateCanvasAndReturnClippath(
        initialValuesForBackgroundClipPath,
        currentTemplate
      );

    dispatch(
      updateAutoAddEmojisToSubtitles(
        currentTemplate?.autoAddEmojisToSubtitles || false
      )
    );

    return {
      templateScaleUpRatio,
      backGroundClipPath,
      currentTemplate,
    };
  };

  const handleVideoPositionChangeInCanvas = (
    videoPositionData: VideoPosition | null,
    isBottomVideo: boolean
  ) => {
    if (canvasObjects.current && fabricRef.current && videoPositionData) {
      const { top, left, scaleX, scaleY } = videoPositionData;

      // Temp checks need to figure our why this is happening
      if (isBottomVideo) {
        canvasObjects.current.videoElDown &&
          canvasObjects.current.videoElDown.set({
            top,
            left,
            scaleX,
            scaleY,
          });
      } else {
        canvasObjects.current.videoElUp &&
          canvasObjects.current.videoElUp.set({
            top,
            left,
            scaleX,
            scaleY,
          });
      }
    }
  };

  const handleMoveVideoOnCanvasForCutMagic = (
    metaData: any,
    isBottomVideo: boolean
  ) => {
    const command = updateScenesCommand(metaData, (scene: Scene) => {
      onSceneUpdate(scene);
      if (prevSceneChange.current?.id === scene.id) {
        handleVideoPositionChangeInCanvas(
          !isBottomVideo
            ? scene.position.videoElUp
            : scene.position.videoElDown,
          isBottomVideo
        );
      }
    });

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handleMoveVideoElOnCanvas = (
    metaData: VideoPositionAfterCanvasModification
  ) => {
    const command = adjustVideoPositionOnCanvasCommand(
      metaData,
      handleVideoPositionChangeInCanvas
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const addCommonVideoPropsToCanvas = ({
    currentTemplate,
    templateScaleUpRatio,
    backGroundClipPath,
    layout,
  }: {
    currentTemplate: any;
    templateScaleUpRatio: number;
    backGroundClipPath: any;
    layout?: VideoLayout;
  }) => {
    const clipPath = getClipPath({
      width:
        currentTemplate.videoProperties[0].clipPath.width *
        templateScaleUpRatio,
      height:
        currentTemplate.videoProperties[0].clipPath.height *
        templateScaleUpRatio,
      top:
        backGroundClipPath.top +
        currentTemplate.videoProperties[0].clipPath.top * templateScaleUpRatio,
      left:
        backGroundClipPath.left +
        currentTemplate.videoProperties[0].clipPath.left * templateScaleUpRatio,
    });

    const initialOptionsForTemplateElements = {
      initialPosition: {
        top: backGroundClipPath.top,
        left: backGroundClipPath.left,
        width: backGroundClipPath.width,
        height: backGroundClipPath.height,
      },
      currentTemplate,
      templateScaleUpRatio,
      totalProgressBarWidth: backGroundClipPath.width,
      textWidth: backGroundClipPath.width,
    };

    addTemplateElementsToCanvas(initialOptionsForTemplateElements);

    const videoElUp = getFabricVideo({
      left: clipPath.left,
      top: clipPath.top,
      // TODO: Remove the double exclamation mark.
      videoElement: videoElRef.current!!,
      clipPath,
      onModified: (e) => {
        if (store.getState().editorState.enableSceneChange) {
          onVideoMovedOnCanvas({
            videoElement: e,
            isBottomVideo: false,
            handleChange: handleMoveVideoOnCanvasForCutMagic,
          });
        } else {
          onVideoMovedOnCanvasForUndoRedo({
            transform: e.transform,
            handleChange: handleMoveVideoElOnCanvas,
            isBottomVideo: false,
          });
        }
      },
    });

    centerFaceInClipPath({
      fabricVideoElement: videoElUp,
      clipPath: clipPath,
      allFaceCoords: currentSelectedMicroContent.face_coord,
      videoLayout: selectedLayout,
      // TODO: Type the below function properly.
      // @ts-expect-error getVideoDeminsions is having different type.
      videoDimensions: getVideoDimensions(),
    });

    fabricRef.current?.add(videoElUp);
    canvasObjects.current.videoElUp = videoElUp;
    fabricRef.current?.setActiveObject(videoElUp);

    setVideoPositionFromDraft(
      selectedEditorDraft,
      videoElUp,
      null,
      templateScaleUpRatio,
      backGroundClipPath
    );

    handelChangeBackgroundColor({ hex: currentTemplate.backgroundColor });

    if (layout === VideoLayout.LAYOUT_9_16_2) {
      const clipPath2 = getClipPath({
        width:
          currentTemplate.videoProperties[1].clipPath.width *
          templateScaleUpRatio,
        height:
          currentTemplate.videoProperties[1].clipPath.height *
          templateScaleUpRatio,
        top:
          backGroundClipPath.top +
          currentTemplate.videoProperties[1].clipPath.top *
            templateScaleUpRatio,
        left:
          backGroundClipPath.left +
          currentTemplate?.videoProperties[1]?.clipPath?.left *
            templateScaleUpRatio,
      });

      const videoElDown = getFabricVideo({
        left: clipPath2.left,
        top: clipPath2.top,
        clipPath: clipPath2,
        // TODO: Remove the double exclamation mark.
        videoElement: videoElRef.current!!,
        onModified: (e) => {
          if (store.getState().editorState.enableSceneChange) {
            onVideoMovedOnCanvas({
              videoElement: e,
              isBottomVideo: true,
              handleChange: handleMoveVideoOnCanvasForCutMagic,
            });
          } else {
            onVideoMovedOnCanvasForUndoRedo({
              transform: e.transform,
              handleChange: handleMoveVideoElOnCanvas,
              isBottomVideo: true,
            });
          }
        },
      });

      centerFaceInClipPath({
        fabricVideoElement: videoElDown,
        clipPath: clipPath2,
        allFaceCoords: currentSelectedMicroContent.face_coord,
        videoLayout: selectedLayout,
        isRightFace: true,
        // TODO: Type the below function properly.
        // @ts-expect-error getVideoDeminsions is having different type
        videoDimensions: getVideoDimensions(),
      });

      setVideoPositionFromDraft(
        selectedEditorDraft,
        null,
        videoElDown,
        templateScaleUpRatio,
        backGroundClipPath
      );

      fabricRef.current.add(videoElDown);
      canvasObjects.current.videoElDown = videoElDown;
      fabricRef.current.setActiveObject(videoElUp);
    }

    reorganizeCanvasObjects();

    handelProgressBarAnimation(currentTemplate?.start / 1000);

    // Apply subs on the changed template
    subsArr?.length > 0 && renderSubsOnCanvas();
    fabricRef.current?.requestRenderAll();
  };

  const initOnePersonLayout = () => {
    clearLayout();

    const videoHeight =
      fabricRef.current.height - CANVAS_CLIPPATH_TOP_BOTTOM_PADDING >
      MAX_CLIPPATH_HEIGHT
        ? MAX_CLIPPATH_HEIGHT
        : fabricRef.current.height - CANVAS_CLIPPATH_TOP_BOTTOM_PADDING;

    const videoWidth = videoHeight * (9 / 16);

    const { templateScaleUpRatio, backGroundClipPath, currentTemplate } =
      getInitialTemplateData({
        layout: VideoLayout.LAYOUT_9_16_1,
        videoHeight,
        videoWidth,
      });

    const addVideoPropsToOneFaceTemplate = () => {
      addCommonVideoPropsToCanvas({
        currentTemplate,
        templateScaleUpRatio,
        backGroundClipPath,
      });
      prevSceneChange.current = null;
      handleSceneChange();
    };

    if (currentTemplate.backgroundImageUrl) {
      setCurrentBGUrl(currentTemplate.backgroundImageUrl);
      addBackgroundImageToTemplate({
        backGroundClipPath: backGroundClipPath,
        imgUrl: currentTemplate.backgroundImageUrl,
        addVideoProps: addVideoPropsToOneFaceTemplate,
      });
    } else {
      addVideoPropsToOneFaceTemplate();
    }
  };

  const initTwoPersonLayout = () => {
    const videoHeight =
      fabricRef.current.height - CANVAS_CLIPPATH_TOP_BOTTOM_PADDING >
      MAX_CLIPPATH_HEIGHT
        ? MAX_CLIPPATH_HEIGHT
        : fabricRef.current.height - CANVAS_CLIPPATH_TOP_BOTTOM_PADDING;
    const videoWidth = videoHeight * (9 / 16);

    clearLayout();

    const { templateScaleUpRatio, backGroundClipPath, currentTemplate } =
      getInitialTemplateData({
        layout: VideoLayout.LAYOUT_9_16_2,
        videoHeight,
        videoWidth,
      });

    const setVideoPropToTwoFaceTemplate = () => {
      addCommonVideoPropsToCanvas({
        currentTemplate,
        templateScaleUpRatio,
        backGroundClipPath,
        layout: VideoLayout.LAYOUT_9_16_2,
      });
      fabricRef.current.requestRenderAll();
    };

    if (currentTemplate.backgroundImageUrl) {
      setCurrentBGUrl(currentTemplate.backgroundImageUrl);
      addBackgroundImageToTemplate({
        backGroundClipPath: backGroundClipPath,
        imgUrl: currentTemplate.backgroundImageUrl,
        addVideoProps: setVideoPropToTwoFaceTemplate,
      });
    } else {
      setVideoPropToTwoFaceTemplate();
    }
  };

  const init16isTo9Layout = () => {
    clearLayout();

    const videoWidth =
      fabricRef.current.width - CANVAS_CLIPPATH_LEFT_RIGHT_PADDING;
    const videoHeight = (videoWidth / 16) * 9;

    const { templateScaleUpRatio, backGroundClipPath, currentTemplate } =
      getInitialTemplateData({
        layout: VideoLayout.LAYOUT_16_9,
        videoHeight,
        videoWidth,
      });

    const setVideoPropToSquareTemplate = () => {
      addCommonVideoPropsToCanvas({
        currentTemplate,
        templateScaleUpRatio,
        backGroundClipPath,
      });
    };

    if (currentTemplate.backgroundImageUrl) {
      setCurrentBGUrl(currentTemplate.backgroundImageUrl);
      addBackgroundImageToTemplate({
        backGroundClipPath: backGroundClipPath,
        imgUrl: currentTemplate.backgroundImageUrl,
        addVideoProps: setVideoPropToSquareTemplate,
      });
    } else {
      setVideoPropToSquareTemplate();
    }
    handleSocialPreviewOut();
  };

  const initSquareLayout = () => {
    clearLayout();

    const videoHeight =
      fabricRef.current.height - CANVAS_CLIPPATH_TOP_BOTTOM_PADDING >
      MAX_CLIPPATH_HEIGHT
        ? MAX_CLIPPATH_HEIGHT
        : fabricRef.current.height - CANVAS_CLIPPATH_TOP_BOTTOM_PADDING;
    const videoWidth = videoHeight;

    const { templateScaleUpRatio, backGroundClipPath, currentTemplate } =
      getInitialTemplateData({
        layout: VideoLayout.LAYOUT_1_1,
        videoHeight,
        videoWidth,
      });

    const setVideoPropToSquareTemplate = () => {
      addCommonVideoPropsToCanvas({
        currentTemplate,
        templateScaleUpRatio,
        backGroundClipPath,
      });
    };

    if (currentTemplate.backgroundImageUrl) {
      setCurrentBGUrl(currentTemplate.backgroundImageUrl);
      addBackgroundImageToTemplate({
        backGroundClipPath: backGroundClipPath,
        imgUrl: currentTemplate.backgroundImageUrl,
        addVideoProps: setVideoPropToSquareTemplate,
      });
    } else {
      setVideoPropToSquareTemplate();
    }
    handleSocialPreviewOut();
  };
  const isIntelliClip = getIsIntelliClip(currentSelectedMicroContent);

  const initCanvas = () => {
    if (videoElRef.current) {
      videoElRef.current.width = videoElRef.current.videoWidth;
      videoElRef.current.height = videoElRef.current.videoHeight;
      videoElRef.current.currentTime =
        currentSelectedMicroContent.start / 1000 || 0;
      dispatch(
        updateCurrentVideoTime(currentSelectedMicroContent.start / 1000 || 0)
      );
      videoElRef.current.muted = false;
      dispatch(togglePlayPause(false));
      videoElRef.current.pause();
      const videoDurationInMillis = Math.floor(
        videoElRef.current.duration * 1000
      );

      subsEmojiConfig.current = INIT_SUB_EMOJI_CONFIG;

      if (
        videoDurationInMillis <= VideoDurationInMilliseconds.SHORT ||
        isIntelliClip
      ) {
        dispatch(
          updateCurrentSelectedMicroContent({
            chapter_end: videoDurationInMillis,
            chapter_start: 0,
            start: 0,
            end: videoDurationInMillis,
          })
        );
      }

      // if (isDiscontinuousClip) {
      //   // need to decide on this
      // } else {
      parseAndDispatchSubs(currentSelectedMicroContent.srt_string);
      // }

      if (selectedLayout === VideoLayout.LAYOUT_16_9) {
        dispatch(changeSelectedLayout(VideoLayout.LAYOUT_16_9));
        init16isTo9Layout();
      } else if (selectedLayout === VideoLayout.LAYOUT_1_1) {
        dispatch(changeSelectedLayout(VideoLayout.LAYOUT_1_1));
        initSquareLayout();
      } else if (selectedLayout === VideoLayout.LAYOUT_9_16_2) {
        dispatch(changeSelectedLayout(VideoLayout.LAYOUT_9_16_2));
        initTwoPersonLayout();
      } else {
        dispatch(changeSelectedLayout(VideoLayout.LAYOUT_9_16_1));
        initOnePersonLayout();
      }

      fabric.util.requestAnimFrame(function render() {
        fabricRef.current?.renderAll();
        fabric.util.requestAnimFrame(render);
      });

      setIsMainCanvasLoaded(true);
    }
    return;
  };

  const handelUpdateBgUrl = (url: string) => {
    removeBackgroundImage();
    canvasObjects.current.backgroundImg = undefined;

    if (url === "") {
      setCurrentBGUrl(null);
    } else {
      setCurrentBGUrl(url);

      addBackgroundImageToTemplate({
        backGroundClipPath: backgroundClipPathRef.current,
        imgUrl: url,
      });

      handelChangeBackgroundColor({ hex: "#000000" });

      fabricRef.current.requestRenderAll();
    }
  };

  useEffect(() => {
    if (subtitleTextRef.current) {
      const subsStyles = getTransformedTextboxStyles(subsConfig);
      updateGroupedText(
        subtitleTextRef.current,
        { ...subsStyles },
        () => {
          fabricRef.current.requestRenderAll();
        },
        fabricRef.current
      );
      renderSubsOnCanvas();
    }
  }, [JSON.stringify(subsConfig)]);

  useEffect(() => {
    // updating subsArr to redux store with selectedEditorDraft
    // In case user user refresh the page
    if (selectedEditorDraft) {
      dispatch(setSubtitles(selectedEditorDraft.subtitleOption));
      dispatch(updateAllSceneChanges(selectedEditorDraft.allSceneChanges));
      dispatch(
        toggleSceneChange(selectedEditorDraft.allSceneChanges.length > 0)
      );
      dispatch(
        updateAutoAddEmojisToSubtitles(
          selectedEditorDraft.autoAddEmojisToSubtitles
        )
      );
      dispatch(changeSelectedLayout(selectedEditorDraft.aspectRatio));

      if (selectedEditorDraft?.emojiStyles) {
        subsEmojiConfig.current = { ...selectedEditorDraft.emojiStyles };
      }
    }
  }, [selectedEditorDraft]);

  const removeTextObjFromFabric = (obj: any) => {
    if (textFabricObj.current[obj.id]) {
      fabricRef.current.remove(textFabricObj.current[obj.id]);
      delete textFabricObj.current[obj.id];
    }
  };

  const addRemoveAssetsOnSeek = () => {
    Object.values(textsObjRef.current).forEach((textObj: any) => {
      const currentTimeInMillis = (videoElRef.current?.currentTime || 0) * 1000;
      if (Number.isFinite(textObj.start) && Number.isFinite(textObj.end)) {
        // make start and end time upto 3 decimal points.
        const startTime = parseFloat(textObj.start.toFixed(3));
        const endTime = parseFloat(textObj.end.toFixed(3));

        if (
          currentTimeInMillis >= startTime &&
          currentTimeInMillis <= endTime
        ) {
          if (!textFabricObj.current[textObj.id]) {
            addTextToFabric(textObj);
          }
        } else {
          removeTextObjFromFabric(textObj);
        }
      }
    });

    Object.values(bRollsRef.current).forEach((asset: any) => {
      const currentTimeInMillis = (videoElRef.current?.currentTime || 0) * 1000;
      if (!asset.isLoadedOnCanvas) return;
      // make start and end time upto 3 decimal points.
      const startTime = parseFloat(asset?.metaData?.start.toFixed(3));
      const endTime = parseFloat(asset?.metaData?.end.toFixed(3));
      if (currentTimeInMillis >= startTime && currentTimeInMillis <= endTime) {
        if (!bRollsFabricRef.current[asset.uid]) {
          handleAddAsset({
            ...asset,
            onlyAddToCanvas: true,
            assetId: asset.uid,
            top: asset.asset.top,
            left: asset.asset.left,
            assetWidth: asset.asset.width * asset.asset.scaleX,
            assetHeight: asset.asset.height * asset.asset.scaleY,
          });
        }
        handleBRollsVideoPlayPause(!videoElRef.current?.paused);
      } else {
        handleRemoveAsset(asset.uid, true);
      }
    });
  };

  const onMicroContentStartSceneUpdate = (newStart: number) => {
    if (microContentStartScene) {
      const currentScene = getSceneAtGivenTime(newStart);
      const face = currentScene?.face || [];
      const updatedScene: Scene = {
        ...microContentStartScene,
        start: newStart,
        face,
        isTwoFace: isTwoFace(face),
        position: initVideoPosition,
      };
      onSceneUpdate(updatedScene);
      sortAndUpdateScenes();
      setMicroContentStartScene(updatedScene);
    } else {
      addSceneAtMicroContentStartIfNotPresent();
    }
  };

  const timelineSelectionHandleRef = useRef<null | SelectionHandle>(null);

  useEffect(() => {
    if (timelineSelectionHandleRef.current) {
      updateSubs(timelineSelectionHandleRef.current);
      timelineSelectionHandleRef.current = null;
    }
  }, [currentSelectedMicroContent.start, currentSelectedMicroContent.end]);

  const chapterStart = parseFloat(currentSelectedMicroContent.chapter_start);
  const chapterEnd = parseFloat(currentSelectedMicroContent.chapter_end);

  const updateStartTimeOrEndTime = ({
    startTime,
    endTime,
    selectionHandle,
  }: {
    startTime: number;
    endTime: number;
    selectionHandle: SelectionHandle;
  }) => {
    let newStartTime = startTime + chapterStart;
    let newEndTime = endTime + chapterStart;

    if (newEndTime > newStartTime + 1000) {
      timelineSelectionHandleRef.current = selectionHandle;
      dispatch(
        updateCurrentSelectedMicroContent({
          start: newStartTime,
          end: newEndTime,
        })
      );
    }
    if (selectionHandle === "start") {
      onMicroContentStartSceneUpdate(newStartTime / 1000);
    }

    syncUpdatedMicroContentTimeWithAllBRolls(
      currentSelectedMicroContent?.start,
      currentSelectedMicroContent?.end,
      newStartTime,
      newEndTime
    );
  };

  const handleUpdateStartTimeOrEndTime = ({
    startTime,
    endTime,
    selectionHandle,
  }: {
    startTime: number;
    endTime: number;
    selectionHandle: SelectionHandle;
  }) => {
    const metadata = {
      newClipTimeMetadata: {
        startTime,
        endTime,
        selectionHandle,
      },
      oldClipTimeMetadata: {
        startTime: currentSelectedMicroContent?.start - chapterStart,
        endTime: currentSelectedMicroContent?.end - chapterStart,
        selectionHandle,
      },
    };
    const command = changeClipStartOrEndTimeCommand(
      metadata,
      updateStartTimeOrEndTime
    );

    trackEditorInteractionEvent(
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.SELECTION_BOX_USED
    );
    command.execute();
    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handleSeek = (time: number) => {
    if (videoElRef.current) {
      videoElRef.current.currentTime =
        (time * 1000 + currentSelectedMicroContent.start) / 1000;
    }
  };

  const initSubs = () => {
    toggleEnableSubs(false);
    dispatch(
      setSubtitles({
        ...subtitles,
        subsArr: [],
      })
    );
    setSubsLang(INIT_SUBS_LANG);
    setSubsConfig(INIT_SUBS_CONFIG);
    subsEmojiConfig.current = INIT_SUB_EMOJI_CONFIG;
    dispatch(updateAutoAddEmojisToSubtitles(false));
    fabricRef.current.remove(subtitleTextRef.current);
    fabricRef.current.discardActiveObject().renderAll();
    subtitleTextRef.current = null;
  };

  const isSubPresentOnTheCurrentPlayerTime = (
    textObj: any,
    currentTime: number
  ) => {
    const startTime = parseFloat(
      (
        (textObj.start * 1000 + currentSelectedMicroContent.start) /
        1000
      ).toFixed(3)
    );
    const endTime = parseFloat(
      ((textObj.end * 1000 + currentSelectedMicroContent.start) / 1000).toFixed(
        3
      )
    );
    return currentTime >= startTime && currentTime <= endTime;
  };

  const isSubsInitialized = useRef(false);

  const renderSubsOnCanvas = () => {
    if (!backgroundClipPathRef.current) {
      // do nothing if backgroundClipPathRef is not present
      // canvas might not be ready
      return;
    }

    // NOTE:
    // we can just find the subtitle that need to be shown once,
    // if it is found then we will show that one
    // No need to call different functions multiple times
    const isSubPresentOnCurrentVideoTime = subsArrRef?.current?.some(
      (textObj: any) =>
        isSubPresentOnTheCurrentPlayerTime(
          textObj,
          videoElRef.current?.currentTime ?? 0
        )
    );

    if (!isSubsInitialized.current) {
      // if subsArr is empty then we do not want to show any subs
      // useful when user deletes all the subs and saves draft
      // and then comes back to the editor
      if (subsArr.length === 0) return;

      const [firstSub] = subsArr;
      // need to initialize the subs with the first sub
      // then we will remove the first sub from the canvas
      // hack to remove random error popping up on the console
      // textObj is undefined
      setSubs(firstSub);
      setSubs({});
      isSubsInitialized.current = true;
    }

    if (isSubPresentOnCurrentVideoTime) {
      subsArr.forEach((textObj: any) => {
        if (
          isSubPresentOnTheCurrentPlayerTime(
            textObj,
            videoElRef.current?.currentTime ?? 0
          )
        ) {
          setSubs(textObj);
          dispatch(updateCurrentSubIndex(textObj.id));
        }
      });
    } else {
      // if no subtitle is present on the current video time and there is sub present on the canvas
      // we do not want to remove it if the next sub is going to start within 100ms
      // if the next sub is going to start after 100ms then we will remove the sub from the canvas
      // we can use the curren sub index to find the next sub

      //  if (currentSubIndex !== null || currentSubIndex !== undefined)
      //  this statement is always true ??
      //  as currentSubIndex can be null or undefined
      if (currentSubIndex != null) {
        const nextSubIndex = currentSubIndex + 1;
        const nextSub = subsArr[nextSubIndex];
        if (nextSub) {
          const diff = nextSub.start - (videoElRef.current?.currentTime || 0); // in seconds
          if (diff > 0 && diff < MIN_THRESHOLD_BETWEEN_SUBTITLES) {
            return;
          }
        }
      }
      setSubs({});
    }
  };

  useEffect(() => {
    isSubsInitialized.current = false;
  }, [currentSelectedMicroContent.id]);

  useEffect(() => {
    isMainCanvasLoaded && renderSubsOnCanvas();
  }, [subsArr, isMainCanvasLoaded]);

  const updateSubs = async (selectionHandle: SelectionHandle) => {
    const thumbIndex = selectionHandle === "start" ? 0 : 2;
    const clipStartTime = currentSelectedMicroContent.start / 1000;
    const clipEndTime = currentSelectedMicroContent.end / 1000;

    if (subtitleAbortController.current) {
      subtitleAbortController.current.abort();
    }
    if (enableSubs) {
      let updatedSubs;

      if (thumbIndex === 0) {
        const newSelectionStart = currentSelectedMicroContent.start / 1000;
        const currentSubStartInSec = subStart / 1000;
        const diff = newSelectionStart - currentSubStartInSec;

        if (isIntelliClip) {
          const srtString = currentSelectedMicroContent.srt_string;
          const jsonSubs = parseSRT(srtString);
          updatedSubs = getSubtitlesSplitOnAssumptionOnStart({
            subtitles: jsonSubs,
            subtitlesLineStartingTime: newSelectionStart,
            selectionStartingTime: currentSubStartInSec,
          });
        } else if (currentSubStartInSec <= newSelectionStart) {
          // need to delete the subs from the subs array before currentSelectedMicroContent.start
          // find index of the subs in the subs array
          // for microContentStartInSec

          const selectedSubtitles = getSubtitlesSplitOnAssumptionOnStart({
            subtitles: subsArr,
            subtitlesLineStartingTime: newSelectionStart,
            selectionStartingTime: currentSubStartInSec,
          });

          updatedSubs = selectedSubtitles.map((sub) => {
            return {
              ...sub,
              start: sub.start - diff,
              end: sub.end - diff,
              id: nanoid(),
            };
          });
        } else {
          // need to get the subs between the difference of the start time
          // and the existing subs start time
          // and add it to the subs array
          // and update the subs array
          try {
            let diffSrtString = "";
            if (isIntelliClip) {
              diffSrtString = currentSelectedMicroContent.srt_string;
            } else {
              diffSrtString = await fetchSubtitles(
                Math.floor(currentSelectedMicroContent.start),
                Math.ceil(subStart)
              );
            }

            const jsonSubs = parseSRT(diffSrtString);
            const shiftedSubs = subsArr.map((sub) => {
              return {
                ...sub,
                start: sub.start - diff,
                end: sub.end - diff,
                id: nanoid(),
              };
            });
            // check if the last sub of new subs is present in the shiftedSubs first element
            // if yes then remove it
            const lastSub = jsonSubs[jsonSubs.length - 1];
            const firstSub = shiftedSubs[0];

            if (lastSub?.end >= firstSub?.start) {
              jsonSubs?.pop();
            }

            updatedSubs = [...jsonSubs, ...shiftedSubs];
          } catch {
            return;
          }
        }
      } else if (thumbIndex === 2) {
        const newSubEndInSec = currentSelectedMicroContent.end / 1000;
        const currentSubEndInSec = subEnd / 1000;
        const currentSubStartInSec = subStart / 1000;

        if (isIntelliClip) {
          const srtString = currentSelectedMicroContent.srt_string;
          const jsonSubs = parseSRT(srtString);
          updatedSubs = getSubtitlesSplitOnAssumptionOnEnd({
            subtitles: jsonSubs,
            selectionEnd: newSubEndInSec,
            subtitlesLineStartingTime: 0,
          }).map((sub) => ({
            ...sub,
            id: nanoid(),
            end: sub.end > newSubEndInSec ? newSubEndInSec : sub.end,
          }));
        } else if (newSubEndInSec <= currentSubEndInSec) {
          // need to delete the subs from the subs array after currentSelectedMicroContent.end
          // find index of the subs in the subs array
          // for microContentStartInSec

          const index = subsArr.findIndex(
            (sub) =>
              newSubEndInSec >= sub.start + currentSubStartInSec &&
              newSubEndInSec <= sub.end + currentSubStartInSec
          );

          let newSubs: SubsType[] = [];
          if (index !== -1) {
            newSubs = subsArr.slice(0, index + 1);
            const lastSubIndex = newSubs.length - 1;
            const lastSub = newSubs[lastSubIndex];
            // remove words before the newSubStartInSec
            const word = getWordAtCurrentTime(
              newSubEndInSec,
              lastSub,
              currentSubStartInSec
            );
            const { end, wordEndIndex } = word;

            const lastSubText = lastSub.text.slice(0, wordEndIndex).trim(); // remove spaces from the start
            if (!lastSubText) {
              newSubs.pop();
            } else {
              const lastSubStart = lastSub.start;
              const lastSubEnd = end - currentSubStartInSec;
              newSubs[lastSubIndex] = {
                ...lastSub,
                start: lastSubStart,
                end: lastSubEnd,
                text: lastSubText,
              };
            }
          } else {
            // remove all the subs from the subs array after the newSubEndInSec
            // and update the subs array
            const index = subsArr.findIndex(
              (sub) => sub.start + currentSubStartInSec > newSubEndInSec
            );
            newSubs = subsArr.slice(0, index);
          }
          const selectedSubs = getSubtitlesSplitOnAssumptionOnEnd({
            subtitles: subsArr,
            selectionEnd: newSubEndInSec,
            subtitlesLineStartingTime: currentSubStartInSec,
          });
          updatedSubs = selectedSubs.map((sub) => {
            return {
              ...sub,
              id: nanoid(),
            };
          });
        } else {
          // need to get the subs between the difference of the start time
          // and the existing subs start time
          // and add it to the subs array
          // and update the subs array
          try {
            const diffSrtString = await fetchSubtitles(
              Math.floor(subEnd),
              Math.ceil(currentSelectedMicroContent.end)
            );
            const jsonSubs: SubsType[] = parseSRT(diffSrtString);
            const diff = (subEnd - subStart) / 1000;
            const shiftedSubs = subsArr.map((sub) => {
              return {
                ...sub,
                id: nanoid(),
              };
            });
            const newSubsShifted = jsonSubs.map((sub) => {
              return {
                ...sub,
                start: sub.start + diff,
                end: sub.end + diff,
                id: nanoid(),
              };
            });

            updatedSubs = [...shiftedSubs, ...newSubsShifted];
          } catch {
            return;
          }
        }
      }

      if (updatedSubs) {
        updateSubsArrayWithEmojis(
          autoAddEmojis ? addEmojisToSrtString(updatedSubs) : updatedSubs
        );
        const srtString = createSrtFromSubsArray(updatedSubs);
        if (!isIntelliClip) {
          dispatch(
            updateCurrentSelectedMicroContent({
              srt_string: srtString,
            })
          );
        }
        onFormatSubtitleText(subsConfig.textTransform);
      }
    }
  };

  const renderTextBoxes = async (textAssets: any, zip: any) => {
    const ratio = 1 / canvasObjects.current.videoElUp.scaleX;
    [...Object.values(textsObjRef.current)].forEach(async (textObj: any) => {
      const newObj = JSON.parse(JSON.stringify(textObj));
      const fabricObj = generateTextFabricObj(
        newObj,
        fabricRef.current,
        (fabricEl: Group, styles: GroupedTextProps) =>
          loadFont(fabricEl, styles, () => {
            fabricRef.current.requestRenderAll();
          })
      );
      const dataUrl = fabricObj.toDataURL({
        enableRetinaScaling: false,
        multiplier: ratio + 1,
      });
      const idx = dataUrl.indexOf("base64,") + "base64,".length;
      const content = dataUrl.substring(idx);
      const assetName = `${TEXT_ASSET_PREFIX + textObj.id}.png`;
      zip.file(assetName, content, { base64: true });
      const textProps = await getScaledPropertiesForText(
        fabricObj,
        canvasObjects.current,
        0
      );
      textAssets.push({
        ...textProps,
        asset_name: assetName,
        start: (textObj.start - currentSelectedMicroContent.start) / 1000,
        end: (textObj.end - currentSelectedMicroContent.start) / 1000,
      });
    });
  };

  const breakSubsArrIntoWords = (subsArr: any) => {
    const wordsArr: any = [];
    subsArr.forEach((sub: any) => {
      const words = getWordsArrayWithEmojis(sub.text);
      const subTime = sub.end - sub.start;
      const wordTime = subTime / words.length;

      // if auto emoji is enabled
      words.forEach((word: any, index: number) => {
        wordsArr.push({
          ...sub,
          start: sub.start + index * wordTime,
          end: sub.start + (index + 1) * wordTime,
          text: word,
          id: nanoid(),
        });
      });
    });
    return wordsArr;
  };

  const breakSubsArrIntoTwoWords = (subsArr: any) => {
    const wordsArr: any = [];
    subsArr.forEach((sub: any) => {
      const words = getWordsArrayWithEmojis(sub.text);
      const subTime = sub.end - sub.start;
      const wordTime = subTime / words.length;

      for (let i = 0; i < words.length; i = i + 2) {
        const word1 = words[i];
        const word2 = words[i + 1] || "";
        const wordTime2 = word2 ? wordTime * 2 : wordTime;

        const wordStart = sub.start + i * wordTime;
        const wordEnd = wordStart + wordTime2;
        wordsArr.push({
          ...sub,
          start: wordStart,
          end: wordEnd,
          text: word1 + (word2 ? " " + word2 : word2),
          id: nanoid(),
        });
      }
    });
    return wordsArr;
  };

  const breakSubsArrForWordColor = (subsArr: any) => {
    const wordsArr: any = [];
    subsArr.forEach((sub: any) => {
      const words = getWordsArrayWithEmojis(sub.text);
      const subTime = sub.end - sub.start;
      const wordTime = subTime / words.length;

      let wordStartIndex = 0;
      words.forEach((word: any, index: number) => {
        const wordEndIndex = getWordLengthWithEmoji(word) + wordStartIndex;

        wordsArr.push({
          ...sub,
          start: sub.start + index * wordTime,
          end: sub.start + (index + 1) * wordTime,
          text: sub.text,
          id: nanoid(),
          wordStartIndex,
          wordEndIndex,
        });
        const graphemeArray = fabric.util.string.graphemeSplit(word);
        wordStartIndex += graphemeArray.length + 1;
      });
    });
    return wordsArr;
  };

  const breakSubsArrForRandomWordColor = (subsArr: any) => {
    const wordsArr: any = [];
    subsArr.forEach((sub: any) => {
      const { startIndex, endIndex } = getRandomStartAndEndIndexToColor(
        sub.text
      );

      wordsArr.push({
        ...sub,
        wordStartIndex: startIndex,
        wordEndIndex: endIndex,
      });
    });
    return wordsArr;
  };

  const breakSubsArrForWordAppendStyle = (subsArr: any) => {
    const wordsArr: any = [];
    subsArr.forEach((sub: any) => {
      const words = getWordsArrayWithEmojis(sub.text);
      const subTime = sub.end - sub.start;
      const wordTime = subTime / words.length;
      let wordStartIndex = 0;
      words.forEach((word: any, index: number) => {
        // wordStartIndex = wordStartIndex + index > 0 ? word.length + 1 : 0;
        const wordEndIndex = getWordLengthWithEmoji(word) + wordStartIndex;
        // append all words to text before this word
        const text = words.slice(0, index + 1).join(" ");
        wordsArr.push({
          ...sub,
          start: sub.start + index * wordTime,
          end: sub.start + (index + 1) * wordTime,
          id: nanoid(),
          text,
          wordStartIndex,
          wordEndIndex,
        });
        const graphemeArray = fabric.util.string.graphemeSplit(word);
        wordStartIndex += graphemeArray.length + 1;
      });
    });
    return wordsArr;
  };

  const getSubsArrayForActiveSubtitleStyle = () => {
    if (subsConfig.style.type === SubtitleStyle.ONE_WORD) {
      return breakSubsArrIntoWords(subsArr);
    }
    if (subsConfig.style.type === SubtitleStyle.TWO_WORD) {
      return breakSubsArrIntoTwoWords(subsArr);
    }
    if (
      subsConfig.style.type === SubtitleStyle.WORD_COLOR_CHANGE ||
      subsConfig.style.type === SubtitleStyle.WORD_BACKGROUND_CHANGE
    ) {
      return breakSubsArrForWordColor(subsArr);
    }
    if (subsConfig.style.type === SubtitleStyle.RANDOM_WORD_COLOR_CHANGE) {
      return breakSubsArrForRandomWordColor(subsArr);
    }
    if (subsConfig.style.type === SubtitleStyle.WORD_APPENDED) {
      return breakSubsArrForWordAppendStyle(subsArr);
    }

    return subsArr;
  };

  const onRenderTexts = async () => {
    let textAssets: any = [];
    let subtitleEmojis: any = [];
    const zip: JSZip = new JSZip();
    if (subsArr.length) {
      const subtitleProps = await getScaledPropertiesForText(
        subtitleTextRef.current,
        canvasObjects.current,
        SAFE_PADDING_FOR_TEXT
      );
      const wordsArr = getSubsArrayForActiveSubtitleStyle();
      // TODO: use map.
      wordsArr.forEach((sub: any, index: number) => {
        // we need to change the end time of the current subtitle to the start time of the next subtitle
        // if the difference between the end time of the current subtitle and the start time of the next subtitle is less than 0.5 seconds
        let end = sub.end;
        if (index < wordsArr.length - 1) {
          const nextSub = wordsArr[index + 1];
          if (nextSub.start - sub.end < MIN_THRESHOLD_BETWEEN_SUBTITLES) {
            end = nextSub.start;
          }
        }

        textAssets.push({
          ...subtitleProps,
          asset_name: `${SUBTITLE_ASSET_PREFIX + sub.id}.png`,
          start: sub.start,
          end,
        });
      });
      // TODO: this loop only handles the emoji's need to check its rendering later
      for (let i = 0; i < wordsArr.length; i++) {
        const sub = wordsArr[i];
        if (sub.emoji) {
          let emojiObj = {
            ...sub.emoji,
            top: subsEmojiConfig.current.top,
            left: subsEmojiConfig.current.left,
            width: subsEmojiConfig.current.fontSize,
          };
          const subtitleEmojiProps = await getScaledPropertiesForText(
            emojiObj,
            canvasObjects.current,
            0,
            true
          );
          subtitleEmojis.push({
            ...subtitleEmojiProps,
            asset_name: `${SUBTITLE_EMOJI_PREFIX + sub.id}.png`,
            start: sub.start,
            end: sub.end,
          });
        }
      }

      await getSubsPngImages(
        zip,
        subtitleTextRef.current,
        fabricRef.current,
        wordsArr,
        canvasObjects.current,
        subsConfig,
        subsEmojiConfig.current
      );
    }

    await renderTextBoxes(textAssets, zip);

    // TODO: need to render the emojis as well
    for (let i = 0; i < subtitleEmojis.length; i++) {
      textAssets.push(subtitleEmojis[i]);
    }
    const blob = await zip.generateAsync({ type: "blob" });
    const res = await uploadFileToS3(blob);

    if (!res?.s3Url) {
      // do not proceed if s3 upload fails for assets zip
      throw new Error(ASSET_ZIP_UPLOAD_ERROR);
    }

    const reqObj = {
      assets_zip: res?.public_url || res?.s3Url,
      assets_properties: textAssets,
    };

    return reqObj;
  };

  const validateScenesBeforeRender = (scenes: Scene[]) => {
    // check for duplicate scenes

    const duplicateScenes = scenes.filter(
      (scene, index) =>
        scenes.findIndex((s) => s.start === scene.start) !== index
    );

    if (duplicateScenes.length) {
      // get the duplicate scene time formatted string
      const duplicateSceneTimes = duplicateScenes
        .map((s) =>
          getColonSeparatedTimeWithMillis(
            s.start * 1000 - currentSelectedMicroContent.start
          )
        )
        .join(", ");
      showNotification(
        `Please remove duplicate scenes at ${duplicateSceneTimes} before exporting video`,
        notificationType.FAIL
      );
      return false;
    }

    // minimum distance between scene time should be 50ms
    const scenesWithLessThan50msDistance = scenes.filter(
      (scene, index) =>
        index > 0 &&
        scene.start - scenes[index - 1].start <
          MIN_DISTANCE_BETWEEN_SCENES_IN_MILLIS / 1000 &&
        scene.start - scenes[index - 1].start > 0
    );

    if (scenesWithLessThan50msDistance.length) {
      // get the duplicate scene time formatted string
      const scenesWithLessThan50msDistanceTimes = scenesWithLessThan50msDistance
        .map((s) =>
          getColonSeparatedTimeWithMillis(
            s.start * 1000 - currentSelectedMicroContent.start
          )
        )
        .join(", ");
      showNotification(
        `Please increase the distance between scenes at ${scenesWithLessThan50msDistanceTimes} before exporting video. Minimum distance between scenes should be ${MIN_DISTANCE_BETWEEN_SCENES_IN_MILLIS}ms`,
        notificationType.FAIL
      );
      return false;
    }

    return true;
  };

  // * Good candidate for helper function
  const onRenderVideo = async () => {
    dispatch(updateIsRenderingVideo(true));
    try {
      let renderWithSceneChange = false;
      let layout = selectedLayout;

      if (enableSceneChange) {
        const scenes = getAllScenesBetweenMicroContent();
        if (scenes.length > 1) {
          renderWithSceneChange = true;
          if (!validateScenesBeforeRender(scenes)) {
            resetDownloadOptions();
            return;
          }
        } else {
          layout = scenes[0]?.isTwoFace
            ? VideoLayout.LAYOUT_9_16_2
            : VideoLayout.LAYOUT_9_16_1;
        }
      }

      const cropRequestData = renderWithSceneChange
        ? getCropAreaForScenesToRender()
        : getVideoCoordsDetails(canvasObjects.current, layout);

      const { output_height, output_width } = getOutputDimensions(layout);

      const progressBarReqData = progressBarRef?.current?.height
        ? getProgressBarDetails(canvasObjects.current, progressBarRef.current)
        : {};

      const logoRequestData = await getLogoDetails(
        canvasObjects.current,
        logoRef.current,
        {
          start: microContentStart,
          end: microContentEnd,
        }
      );
      const bRollsRequestData = await getLogoDetails(
        canvasObjects.current,
        bRollsRef.current,
        {
          start: microContentStart,
          end: microContentEnd,
        }
      );

      let faceConfigArray: any = [];

      if (!renderWithSceneChange) {
        if (Object.keys(cropRequestData.face_1_config).length) {
          faceConfigArray = [...faceConfigArray, cropRequestData.face_1_config];
        }
        if (Object.keys(cropRequestData.face_2_config).length) {
          faceConfigArray = [...faceConfigArray, cropRequestData.face_2_config];
        }
      } else {
        faceConfigArray = cropRequestData;
      }

      const projectData = currentSelectedProject?.data
        ? JSON.parse(currentSelectedProject.data)
        : null;

      let requestBody = {
        face_config: [...faceConfigArray],
        output_width: output_width,
        output_height: output_height,
        // checking source_width and source_height
        // because for older projects we were not
        // saving the source dimension in project data
        input_width:
          projectData && projectData?.source_width
            ? projectData.source_width
            : videoElRef.current?.videoWidth,
        input_height:
          projectData && projectData?.source_height
            ? projectData.source_height
            : videoElRef.current?.videoHeight,
        project_id: currentSelectedProject && currentSelectedProject.id,
        bg_color_hex: backgroundColorAsHex || "#000000",
        bg_image_url: currentBGUrl || null,
        img_config: [...logoRequestData, ...bRollsRequestData],
        progressbar_config: progressBarReqData,
        video_play_time: (microContentEnd - microContentStart) / 1000,
        aspect_ratio: layout,
        filename: `${currentSelectedMicroContent?.gist || nanoid()}.mp4`,
        outro: {
          img_url: currentOutro?.url,
          duration: currentOutro?.url && outroLengthInSecond,
        },
        input_video_uri: currentSelectedProject
          ? isIntelliClip
            ? changeStageUrlToProd(currentSelectedMicroContent.clip_src)
            : encodeURI(JSON.parse(currentSelectedProject?.data).remote_url)
          : "",
        clip_start: microContentStart,
        clip_end: microContentEnd,
        id: store.getState().editorState.currentRenderId,
        clip_id: currentSelectedMicroContent?.clipId,
        audio_data: getAudioDataForRender(
          audioElements,
          currentSelectedMicroContent
        ),
      };

      // What happen if there is no subtitle
      if (subsArr.length > 0 || Object.keys(textsObjRef.current).length > 0) {
        const assetRequest = await onRenderTexts();
        requestBody = { ...requestBody, ...assetRequest };
      }

      renderWithSceneChange
        ? renderVideoCutMagicMutate(requestBody)
        : renderVideoMutate(requestBody);
    } catch (error: any) {
      console.error("Possible UI Render Error", error);
      if (error.message === ASSET_ZIP_UPLOAD_ERROR) {
        showNotification(
          "Could not render video. Check your internet connection and try again!",
          notificationType.FAIL
        );
      } else {
        showNotification(
          "Could not render video. Please try again!",
          notificationType.FAIL
        );
      }

      dispatch(updateIsRenderingVideo(false));
    }
  };

  const onTextStyleChange = (id: any, styleObj: any) => {
    if (id && textsObjRef.current[id]) {
      let newTextsObj = { ...textsObjRef.current };
      newTextsObj[id] = { ...newTextsObj[id], style: styleObj };
      setTextsObj(newTextsObj);
      const transformedStyles = getTransformedTextboxStyles(styleObj);

      updateGroupedText(textFabricObj.current[id], {
        ...transformedStyles,
      });
    }
  };

  const onTextBoxTransform = (id: any, transformStyle: any) => {
    if (id && textsObjRef.current[id]) {
      let newTextsObj = { ...textsObjRef.current };

      const transformedStyles = getTransformedTextboxStyles(
        textsObjRef.current[id].style
      );

      updateGroupedText(textFabricObj.current[id], {
        ...transformedStyles,
        text: newTextsObj[id].content,
        textTransform: transformStyle,
      });
    }
  };

  // start polling for current time for accuracy ending of end
  const startPollingForCurrentTime = () => {
    videoElRef.current?.paused ||
      isPollingCurrentTime ||
      toggleIsPollingCurrentTime(true);
  };

  const [microContentStartScene, setMicroContentStartScene] = useState<
    Scene | null | undefined
  >(null);
  const addSceneAtMicroContentStartIfNotPresent = () => {
    if (enableSceneChange) {
      const microContentStartInSeconds =
        currentSelectedMicroContent.start / 1000;
      // find scene with exact start time
      const scene = getSceneAtExactTime(microContentStartInSeconds);

      if (!scene) {
        // add scene at right index in allSceneChanges
        const sceneAtCurrentTime = getSceneAtGivenTime(
          microContentStartInSeconds
        );
        const startScene = onSceneAdd(
          currentSelectedMicroContent.start / 1000,
          sceneAtCurrentTime?.face || []
        );
        setMicroContentStartScene(startScene);
      }
    }
  };

  useEffect(() => {
    if (enableSceneChange) {
      if (selectedLayout !== VideoLayout.LAYOUT_9_16_1) {
        const DEFAULT_CUTMAGIC_TEMPLATE = "eOsn-7-nAk-uzQUEzqm-Q";
        const randomTemplate = getRandomTemplateForSceneChange(
          defaultBaseTemplates.data
        );
        const templateId = randomTemplate?.id ?? DEFAULT_CUTMAGIC_TEMPLATE;
        dispatch(changeSelectedLayout(VideoLayout.LAYOUT_9_16_1));
        dispatch(
          updateCurrentSelectedMicroContent({
            id: templateId,
          })
        );
      }
      addSceneAtMicroContentStartIfNotPresent();
    }
  }, [enableSceneChange]);

  useEffect(() => {
    handleSceneChange();
    addSceneAtMicroContentStartIfNotPresent();
  }, [allSceneChanges, currentSelectedMicroContent.start]);

  const updateSceneValues = (sceneValues: number[], index: number) => {
    if (sceneValues[index] && videoElRef?.current) {
      videoElRef.current.currentTime = sceneValues[index] / 1000;
    }
  };

  const handleUpdateScene = (metaData: any) => {
    const command = updateScenesCommand(metaData, (sceneData: any) =>
      dispatch(updateAllSceneChanges(sceneData))
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const onAfterSceneValueChange = ({
    time: rawTime,
    index,
  }: {
    time: number;
    index: number;
  }) => {
    const newTime = rawTime + timelineOffset;
    // check if a scene is already present at the time
    const scene = getSceneAtExactTime(newTime / 1000);
    if (scene) {
      // convert seconds to  hh:mm:ss.ms format
      const time = getColonSeparatedTimeWithMillis(
        newTime - currentSelectedMicroContent.start
      );
      showNotification(
        `Scene already present at ${time}. Please remove it first.`,
        notificationType.WARN
      );
      return;
    }

    const newSceneChanges = JSON.parse(JSON.stringify(allSceneChanges));

    newSceneChanges[index].start = newTime / 1000;

    handleUpdateScene({
      newSceneData: newSceneChanges,
      oldSceneData: allSceneChanges,
    });
  };

  const handleChangeLayout = (scene: Scene, isSameLayout: boolean) => {
    if (canvasObjects.current.videoElUp) {
      const videoElUp = canvasObjects.current.videoElUp;
      const videoElUpClipPath = canvasObjects.current.videoElUp.clipPath;
      const layout = scene.isTwoFace
        ? VideoLayout.LAYOUT_9_16_2
        : VideoLayout.LAYOUT_9_16_1;
      if (scene.isTwoFace) {
        const videoPositionDown = scene.position.videoElDown;
        const videoPositionUp = scene.position.videoElUp;
        if (!canvasObjects.current.videoElDown && !isSameLayout) {
          const clipPath2 = getClipPath({
            width: videoElUpClipPath.width,
            height: videoElUpClipPath.height / 2,
            // need to be at bottom so add 1/2 height to top
            top: videoElUpClipPath.top + videoElUpClipPath.height / 2,
            left: videoElUpClipPath.left,
          });

          const videoElDown = getFabricVideo({
            left: videoPositionDown?.left || clipPath2.left,
            top: videoPositionDown?.top || clipPath2.top,
            clipPath: clipPath2,
            // TODO: Fix the below error proplerly.
            videoElement: videoElRef.current!!,
            onModified: (e) => {
              if (store.getState().editorState.enableSceneChange) {
                onVideoMovedOnCanvas({
                  videoElement: e,
                  isBottomVideo: true,
                  handleChange: handleMoveVideoOnCanvasForCutMagic,
                });
              } else {
                onVideoMovedOnCanvasForUndoRedo({
                  transform: e.transform,
                  handleChange: handleMoveVideoElOnCanvas,
                  isBottomVideo: true,
                });
              }
            },
          });
          fabricRef.current.add(videoElDown);
          canvasObjects.current.videoElDown = videoElDown;

          // update clip path of videoElUp
          videoElUp.clipPath.set({
            height: videoElUpClipPath.height / 2,
          });
        }
        if (videoPositionDown) {
          canvasObjects.current.videoElDown.set({
            left: videoPositionDown.left,
            top: videoPositionDown.top,
            scaleX: videoPositionDown.scaleX,
            scaleY: videoPositionDown.scaleY,
          });
        } else {
          centerFaceInClipPath({
            fabricVideoElement: canvasObjects.current.videoElDown,
            clipPath: canvasObjects.current.videoElDown.clipPath,
            allFaceCoords: scene.face,
            videoLayout: layout,
            isRightFace: true,
            // TODO: type the function properly.
            // @ts-expect-error the below function is no properly typed
            videoDimensions: getVideoDimensions(),
          });
        }

        if (videoPositionUp) {
          canvasObjects.current.videoElUp.set({
            left: videoPositionUp.left,
            top: videoPositionUp.top,
            scaleX: videoPositionUp.scaleX,
            scaleY: videoPositionUp.scaleY,
          });
        } else {
          centerFaceInClipPath({
            fabricVideoElement: videoElUp,
            clipPath: videoElUp.clipPath,
            allFaceCoords: scene.face,
            videoLayout: layout,
            // TODO: type the function properly.
            // @ts-expect-error the below function is no properly typed
            videoDimensions: getVideoDimensions(),
          });
        }
        reorganizeCanvasObjects();
      } else {
        const videoPositionUp = scene.position.videoElUp;
        if (!isSameLayout) {
          // remove videoElDown
          if (canvasObjects.current.videoElDown) {
            fabricRef.current.remove(canvasObjects.current.videoElDown);
            canvasObjects.current.videoElDown = null;
          }
          // update clip path of videoElUp
          videoElUp.clipPath.set({
            height: videoElUpClipPath.height * 2,
          });
        }

        if (videoPositionUp) {
          canvasObjects.current.videoElUp.set({
            left: videoPositionUp.left,
            top: videoPositionUp.top,
            scaleX: videoPositionUp.scaleX,
            scaleY: videoPositionUp.scaleY,
          });
        } else {
          centerFaceInClipPath({
            fabricVideoElement: videoElUp,
            clipPath: videoElUp.clipPath,
            allFaceCoords: scene.face,
            videoLayout: layout,
            // TODO: type the function properly.
            // @ts-expect-error the below function is no properly typed
            videoDimensions: getVideoDimensions(),
          });
        }
      }
    }
  };

  const prevSceneChange = useRef<Scene | null>(null);

  const handleSceneChange = () => {
    if (isMainCanvasLoaded && enableSceneChange && allSceneChanges.length > 0) {
      const currentScene = getSceneAtGivenTime(
        videoElRef.current?.currentTime ?? 0
      );
      const isSameLayout = prevSceneChange.current
        ? prevSceneChange.current.isTwoFace === currentScene?.isTwoFace
        : !currentScene?.isTwoFace;
      if (currentScene) {
        if (!isSameLayout || prevSceneChange.current?.id !== currentScene.id) {
          prevSceneChange.current = currentScene;
          handleChangeLayout(currentScene, isSameLayout);
        }
      }
    }
  };

  const getCropAreaForGivenScene = (scene: Scene) => {
    const layout = scene.isTwoFace
      ? VideoLayout.LAYOUT_9_16_2
      : VideoLayout.LAYOUT_9_16_1;
    const currentActiveScene = getSceneAtGivenTime(
      videoElRef.current?.currentTime ?? 0
    );
    const cropArea: any[] = [];
    const currentVideoElUpClipPath = canvasObjects.current.videoElUp.clipPath;

    let clipPathHeight = currentVideoElUpClipPath.height;
    if (currentActiveScene?.isTwoFace && !scene.isTwoFace) {
      clipPathHeight = clipPathHeight * 2;
    } else if (!currentActiveScene?.isTwoFace && scene.isTwoFace) {
      clipPathHeight = clipPathHeight / 2;
    }

    const newClipPathUp = getClipPath({
      width: currentVideoElUpClipPath.width,
      height: clipPathHeight,
      // need to be at bottom so add 1/2 height to top
      top: currentVideoElUpClipPath.top,
      left: currentVideoElUpClipPath.left,
    });

    const newVideoElUp = getFabricVideo({
      left: newClipPathUp.left,
      top: newClipPathUp.top,
      clipPath: newClipPathUp,
      // TODO: Fix the below error proplerly.
      videoElement: videoElRef.current!!,
    });

    if (scene.position.videoElUp) {
      newVideoElUp.set({
        left: scene.position.videoElUp.left,
        top: scene.position.videoElUp.top,
        scaleX: scene.position.videoElUp.scaleX,
        scaleY: scene.position.videoElUp.scaleY,
      });
    } else {
      centerFaceInClipPath({
        fabricVideoElement: newVideoElUp,
        clipPath: newVideoElUp.clipPath,
        allFaceCoords: scene.face,
        videoLayout: layout,
        // TODO: type the function properly.
        // @ts-expect-error the below function is no properly typed
        videoDimensions: getVideoDimensions(),
      });
    }

    const videoElUpCropArea: any = getCropArea(
      newVideoElUp,
      canvasObjects.current.backGroundClipPath
    );
    videoElUpCropArea.start = scene.start;

    cropArea.push(videoElUpCropArea);

    if (scene.isTwoFace) {
      const newClipPathDown = getClipPath({
        width: currentVideoElUpClipPath.width,
        height: clipPathHeight,
        // need to be at bottom so add 1/2 height to top
        top: currentVideoElUpClipPath.top + clipPathHeight,
        left: currentVideoElUpClipPath.left,
      });

      const newVideoElDown = getFabricVideo({
        left: newClipPathDown.left,
        top: newClipPathDown.top,
        clipPath: newClipPathDown,
        // TODO: Fix the below error proplerly.
        videoElement: videoElRef.current!!,
      });

      if (scene.position.videoElDown) {
        newVideoElDown.set({
          left: scene.position.videoElDown.left,
          top: scene.position.videoElDown.top,
          scaleX: scene.position.videoElDown.scaleX,
          scaleY: scene.position.videoElDown.scaleY,
        });
      } else {
        centerFaceInClipPath({
          fabricVideoElement: newVideoElDown,
          clipPath: newVideoElDown.clipPath,
          allFaceCoords: scene.face,
          videoLayout: layout,
          isRightFace: true,
          // TODO: type the function properly.
          // @ts-expect-error the below function is no properly typed
          videoDimensions: getVideoDimensions(),
        });
      }

      const videoElDownCropArea: any = getCropArea(
        newVideoElDown,
        canvasObjects.current.backGroundClipPath
      );
      videoElDownCropArea.start = scene.start;

      cropArea.push(videoElDownCropArea);
    }
    return cropArea;
  };

  const offsetScenesWithMicroContentStart = (scenes: Scene[]) => {
    // offset all scenes with micro content start time
    const offsetScenes = scenes.map((scene) => ({
      ...scene,
      start: scene.start - currentSelectedMicroContent.start / 1000,
    }));

    return offsetScenes;
  };

  const getCropAreaForScenesToRender = () => {
    const scenes = getAllScenesBetweenMicroContent();
    const scenesWithDuration = offsetScenesWithMicroContentStart(scenes);
    const cropAreas = scenesWithDuration.map((scene) =>
      getCropAreaForGivenScene(scene)
    );
    return cropAreas;
  };

  const syncBRollsVideoWithMainVideo = () => {
    Object.values(bRollsFabricRef.current).forEach((asset: any) => {
      const isAssetTypeVideo = asset.assetType.includes(SimpleAssetType.VIDEO);
      const videoEl = asset.videoEl;

      if (isAssetTypeVideo) {
        videoEl.currentTime = videoElRef.current?.currentTime || 0;
      }
    });
  };

  const syncBRollsVideoTimeWithMainVideo = (currentTimeInMillis: number) => {
    Object.values(bRollsFabricRef.current).forEach((asset: any) => {
      const isAssetTypeVideo = asset.assetType.includes(SimpleAssetType.VIDEO);
      const videoEl = asset.videoEl;

      // update currentTime of videoEl with respect to main video

      if (isAssetTypeVideo) {
        videoEl.currentTime = Math.abs(
          (asset.metaData.start - currentTimeInMillis) / 1000
        );
      }
    });
  };

  const syncAudioTimeWithMainVideo = (currentTimeInMillis: number) => {
    Object.values(audioElements).forEach((audioAsset: any) => {
      audioAsset.audioElement.currentTime = Math.abs(
        (audioAsset.metaData.start - currentTimeInMillis) / 1000
      );
    });
  };

  const syncUpdatedMicroContentTimeWithAllBRolls = (
    mainVideoOldStartTime: number,
    mainVideoOldEndTime: number,
    mainVideoNewStartTime: number,
    mainVideoNewEndTime: number
  ) => {
    const updatedBRolls = { ...bRolls };

    Object.entries(bRolls).forEach(([uid, bRoll]: [string, any]) => {
      let { start, end, duration } = bRoll.metaData;
      const isAssetTypeVideo = bRoll.assetType.includes(SimpleAssetType.VIDEO);

      // Calculate the potential new start and end times
      const potentialNewStart =
        start + (mainVideoNewStartTime - mainVideoOldStartTime);
      const potentialNewEnd = end + (mainVideoNewEndTime - mainVideoOldEndTime);

      // Adjust the start time if the main video start time has changed
      if (mainVideoOldStartTime !== mainVideoNewStartTime) {
        if (
          potentialNewStart >= mainVideoNewStartTime &&
          potentialNewStart <= mainVideoNewEndTime
        ) {
          start = potentialNewStart; // Adjust start only if it falls within the new timeline
        }
      }

      // Adjust the end time if the main video end time has changed
      if (mainVideoOldEndTime !== mainVideoNewEndTime) {
        if (potentialNewEnd <= mainVideoNewEndTime) {
          end = potentialNewEnd; // Adjust end only if it doesn't exceed the new end time
        } else {
          end = mainVideoNewEndTime; // Clamp end to the new end time if it exceeds it
        }
      }

      // Check for negative durations and make adjustments if necessary
      if (end < start) {
        console.error(
          `Adjustment leads to negative duration for B-roll with UID: ${uid}`
        );
        // For now skip changing bRoll
        return;
      }

      // Update the B-roll metadata
      updatedBRolls[uid] = {
        ...bRoll,
        metaData: {
          ...bRoll.metaData,
          start,
          end: isAssetTypeVideo ? duration * 1000 + start : end,
          duration: isAssetTypeVideo ? duration : end - start,
        },
      };

      // Update the bRollsFabricRef if necessary
      if (bRollsFabricRef.current && bRollsFabricRef.current[uid]) {
        bRollsFabricRef.current[uid].metaData = updatedBRolls[uid].metaData;
      }
    });

    setBRolls(updatedBRolls);

    Object.values(audioElements).forEach((audioAsset: any) => {
      setAudioElements((prev: any) => {
        return {
          ...prev,
          [audioAsset.id]: {
            ...prev[audioAsset.id],
            metaData: {
              ...prev[audioAsset.id].metaData,
              start: mainVideoNewStartTime,
              end:
                audioAsset.metaData.duration * 1000 + mainVideoNewStartTime >
                mainVideoNewEndTime
                  ? mainVideoNewEndTime
                  : audioAsset.metaData.duration * 1000 + mainVideoNewStartTime,
            },
          },
        };
      });
    });

    Object.values(logoRef.current).forEach((asset: any) => {
      const currentLogoRef = logoRef.current;
      if (currentLogoRef[asset.uid] && currentLogoRef[asset.uid].metaData) {
        const updatedAsset = {
          ...currentLogoRef[asset.uid],
          metaData: {
            ...currentLogoRef[asset.uid].metaData,
            start: mainVideoNewStartTime,
            end: mainVideoNewEndTime,
          },
        };

        currentLogoRef[asset.uid] = updatedAsset;
      }
    });
  };

  const onTimeUpdate = () => {
    if (videoElRef.current) {
      handleSceneChange();
      startPollingForCurrentTime();
      renderSubsOnCanvas();
      dispatch(updateCurrentVideoTime(videoElRef.current.currentTime));
      syncAudioAssetsWithMainVideo(videoElRef.current, audioElements);
      if (
        videoElRef.current.currentTime * 1000 >=
        currentSelectedMicroContent.end
      ) {
        videoElRef.current.paused || handlePlayPause();
        syncBRollsVideoWithMainVideo();

        // Setting the current time to outroLengthInSecond will trigger the setInterval

        if (currentOutro) {
          setOutroTimeLeft(outroLengthInSecond);
          fabricRef.current.discardActiveObject();
          const outroImg = canvasObjects.current.outroImg;
          fabricRef.current.add(outroImg);

          if (
            canvasObjects.current.outroImg?.getElement().tagName === "VIDEO"
          ) {
            canvasObjects.current.outroImg
              .getElement()
              .play()
              .then(() => {
                dispatch(togglePlayPause(true));
              })
              .catch(() => {});
          }

          fabricRef.current.renderAll();
        } else {
          videoElRef.current.currentTime =
            currentSelectedMicroContent.start / 1000;
          addRemoveAssetsOnSeek();
        }
      } else {
        addRemoveAssetsOnSeek();
      }
    }
  };

  // this is to ensure the global canvas always has proper dimension
  useLayoutEffect(() => {
    fabricRef?.current?.setWidth(globalCanvasWidth);
    fabricRef?.current?.setHeight(globalCanvasHeight);
  }, [globalCanvasWidth, globalCanvasHeight]);

  // This effect is needed for rendering video,
  // because the render button is outside the Outlet

  useUpdateEffect(() => {
    if (isRenderingVideo === true && isMainCanvasLoaded) {
      downloadAndRenderConversionGtag();
      downloadAndRenderConversionFbq();
      onRenderVideo();
    }
  }, [isRenderingVideo, isMainCanvasLoaded]);

  useEffect(() => {
    setCurrentBGUrl("");
    removeBackgroundImage();
    canvasObjects.current.backgroundImg = undefined;
  }, [currentSelectedMicroContent.id]);

  // This Effect is for determining the layouts
  useUpdateEffect(() => {
    if (
      currentSelectedMicroContent.id && // make sure the selected micro content is loaded
      isMainCanvasLoaded &&
      prevTemplateId !== currentSelectedMicroContent?.id
    ) {
      if (selectedLayout === VideoLayout.LAYOUT_16_9) {
        init16isTo9Layout();
      } else if (selectedLayout === VideoLayout.LAYOUT_1_1) {
        initSquareLayout();
      } else if (selectedLayout === VideoLayout.LAYOUT_9_16_1) {
        initOnePersonLayout();
      } else if (selectedLayout === VideoLayout.LAYOUT_9_16_2) {
        initTwoPersonLayout();
      } else {
        initOnePersonLayout();
      }
    }
  }, [currentSelectedMicroContent.id, selectedLayout, isMainCanvasLoaded]);

  const removeOutroFromCanvas = () => {
    const objects = fabricRef.current.getObjects();

    // while removing the outro asset
    // we also need to pause if the
    // outro is video
    if (outroVideoRef.current) {
      outroVideoRef.current?.pause();
    }

    objects.forEach((object: any) => {
      if (object && object.id === OUTRO_ASSET) {
        fabricRef.current.remove(object);
      }
    });

    fabricRef.current.requestRenderAll();
  };

  const updateDefaultTextId = () => {
    if (textsObj && Object.keys(textsObj).length >= 0) {
      dispatch(setSelectedTextId(Object.keys(textsObj)[0]));
    }
  };

  // This effect is for outro Interval timer
  useEffect(() => {
    if (outroTimeLeft === 0 && isMainCanvasLoaded) {
      removeOutroFromCanvas();
    }

    if (!outroTimeLeft) return;

    outroIntervalRef.current = setInterval(() => {
      setOutroTimeLeft(outroTimeLeft - 1);
    }, 1000);

    return () => {
      if (outroIntervalRef.current) {
        clearInterval(outroIntervalRef.current);
      }
    };
  }, [outroTimeLeft]);

  useEffect(() => {
    setIsMiddleSliderThumbDraggable(true);

    // update textTimeStamp when microContent Start and End changes
    if (isMainCanvasLoaded) {
      Object.keys(textsObj).forEach(function (key) {
        if (textsObj[key].isFullVideoLength) {
          onStartUpdate(microContentStart, key);
          onEndUpdate(microContentEnd, key);
        }
      });
    }
  }, [microContentEnd, microContentStart, isMainCanvasLoaded]);

  const resetDownloadOptions = () => {
    dispatch(updateIsRenderingVideo(false));
    closeDownloadModal();
    dispatch(updateCurrentRenderId(null));
    dispatch(toggleShowSaveTemplateModal(false));
  };

  useEffectOnce(() => resetDownloadOptions());

  const syncFabricTextsWithTextsObj = (
    coordinates: {
      top: number;
      left: number;
    },
    textId: string
  ) => {
    setTextsObj((prevState: any) => {
      return {
        ...prevState,
        [textId]: {
          ...prevState[textId],
          style: {
            ...prevState[textId].style,
            coordinate_top: coordinates.top,
            coordinate_left: coordinates.left,
          },
        },
      };
    });
  };

  //Arrow Keys and Spacebar controls
  useKey("ArrowLeft", (e) =>
    handleKeyDown(e, fabricRef, progressBarRef, syncFabricTextsWithTextsObj)
  );
  useKey("ArrowUp", (e) =>
    handleKeyDown(e, fabricRef, progressBarRef, syncFabricTextsWithTextsObj)
  );
  useKey("ArrowRight", (e) =>
    handleKeyDown(e, fabricRef, progressBarRef, syncFabricTextsWithTextsObj)
  );
  useKey("ArrowDown", (e) =>
    handleKeyDown(e, fabricRef, progressBarRef, syncFabricTextsWithTextsObj)
  );
  useKey(" ", (e: any) => {
    if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
      return;
    }
    handlePlayPause();
  });

  const getSocialMediaHandle = (id: string) => {
    const handle = userSocialMediaHandles.find((h) => h.id === id);
    return handle ? handle.socialMediaHandle : null;
  };

  const handleSocialPreviewOut = () => {
    if (fabricRef?.current && backgroundClipPathRef?.current) {
      fabricRef.current?.setOverlayImage(
        null,
        fabricRef.current?.renderAll.bind(fabricRef.current)
      );
      setSelectedImage(null);
      setShowPreviewInfo(false);
    }
  };

  const timelineArea = (
    <div className="border shadow">
      <TimelineEditor
        backwardVideo={backwardVideo}
        backwardVideoByOneFrame={backwardVideoByOneFrame}
        handlePlayPause={handlePlayPause}
        forwardVideo={forwardVideo}
        forwardVideoByOneFrame={forwardVideoByOneFrame}
        isIntelliClip={isIntelliClip}
        outroTimeLeft={outroTimeLeft}
        videoElRef={videoElRef}
        isMainCanvasLoaded={isMainCanvasLoaded}
        isVideoSeeking={isVideoSeeking}
        isOutroEnabled={currentOutro}
        setCurrentTime={setCurrentTime}
        handleUpdateStartTimeOrEndTime={handleUpdateStartTimeOrEndTime}
        onAfterSceneValueChange={onAfterSceneValueChange}
        audioElements={audioElements}
      />
    </div>
  );

  const operationModals = (
    <>
      <FullPageLoader
        showLoader={
          !isMainCanvasLoaded ||
          defaultBaseTemplates.isLoading ||
          userTemplatesData.isLoading
        }
      />
      <DownloadPreferenceModal
        videoWidth={videoElRef?.current?.width || 0}
        videoHeight={videoElRef?.current?.height || 0}
        resetDownloadOptions={resetDownloadOptions}
        getDraftsProperties={getDraftsProperties}
      />
      <SaveTemplateModal generateTemplateJSON={generateTemplateJSON} />
      <SaveDraftModal getDraftsProperties={getDraftsProperties} />
    </>
  );

  const outlet = (
    <div
      className={clsx(
        "hidden",
        "w-[25rem]",
        "h-full",
        "",
        "flex-col",
        "justify-between",
        "overflow-y-auto",
        "border-l",
        "border-r",
        "bg-gray-100",
        "shadow-none",
        "sm:flex",
        "2xl:w-[32vw]"
      )}
    >
      <Outlet
        context={{
          addTextObject,
          onTextChange,
          onTextDelete,
          onStartUpdate,
          onEndUpdate,
          onTextStyleChange,
          textsObj,
          handleAddAsset,
          handleRemoveAsset,
          handelAddSocialAssets,
          addSocialTemplatesToCanvas,
          setSubsConfig,
          subsConfig,
          onSubChange,
          onAddSubLine,
          onRemoveSubLine,
          onSubsDelete: initSubs,
          fetchSubtitles: fetchAndUpdateSubs,
          addSubsForRedoOps,
          handelChangeOutro,
          deleteOutroAsset,
          currentOutro,
          handelUpdateBgUrl,
          removeBackgroundImage,
          currentBGUrl,
          updateIsFullVideoLength,
          backgroundClipPath: backgroundClipPathRef.current,
          onSceneAdd,
          onSceneDelete,
          onSceneUpdate,
          bRolls: Object.values(bRolls) || [],
          handelChangeSideBarMenu,
          onSubtitleEmojiChange,
          selectTextObject,
          handleSeek,
          onFormatSubtitleText,
          handleAddAudioAsset: (audioData: any) =>
            handleAddAudioAsset(audioData, setAudioElements),
          audioElements,
          removeAudioAsset,
          audioAssetTimeUpdate,
          audioAssetVolumeUpdate,
          onTextBoxTransform,
          videoCurrentTime: videoElRef?.current?.currentTime,
        }}
      />
    </div>
  );

  const editorControls = (
    <div className="relative mx-auto flex w-[calc(100vw_-_30rem)] flex-col overflow-hidden">
      <div className="absolute right-2 top-1/2 z-50 -translate-y-1/2">
        <button className="flex flex-col justify-center">
          {selectedLayout !== VideoLayout.LAYOUT_1_1 &&
            selectedLayout !== VideoLayout.LAYOUT_16_9 && (
              <>
                <Tippy
                  content="Tiktok Preview"
                  placement="left"
                  className="rounded bg-gray-900 p-2 text-sm text-white"
                  delay={0}
                >
                  <img
                    src={Tiktok}
                    className={`mb-2 h-10 cursor-pointer rounded-full bg-white p-2 shadow-md focus:outline-none ${
                      selectedImage === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK &&
                      "border-2 border-blue-500"
                    }`}
                    onClick={() => {
                      if (
                        selectedImage === SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
                      ) {
                        handleSocialPreviewOut();
                      } else {
                        handleSocialMediaOverlay(
                          fabricRef,
                          backgroundClipPathRef,
                          selectedLayout,
                          TiktokOverlay,
                          getSocialMediaHandle("social_tiktok") || "username",
                          currentSelectedMicroContent?.gist ||
                            "your video captions",
                          currentUser?.photoURL,
                          SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK
                        );
                        setSelectedImage(SOCIAL_MEDIA_SELECTED_IMAGE.TIKTOK);
                        setShowPreviewInfo(true);
                      }
                      eventsDataToRedux(
                        ANALYTICS_CONSTANTS.SOCIAL_PREVIEW_ACCESSED
                      );
                    }}
                  />
                </Tippy>

                <Tippy
                  content="Reels Preview"
                  placement="left"
                  className="rounded bg-gray-900 p-2 text-sm text-white"
                  delay={0}
                >
                  <img
                    src={Instagram}
                    className={`my-2 h-10 cursor-pointer rounded-full bg-white p-2 shadow-md focus:outline-none ${
                      selectedImage === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM &&
                      "border-2 border-blue-500"
                    }`}
                    onClick={() => {
                      if (
                        selectedImage === SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
                      ) {
                        handleSocialPreviewOut();
                      } else {
                        handleSocialMediaOverlay(
                          fabricRef,
                          backgroundClipPathRef,
                          selectedLayout,
                          ReelsOverlay,
                          getSocialMediaHandle("social_insta") || "username",
                          currentSelectedMicroContent?.gist ||
                            "your video captions",
                          currentUser?.photoURL,
                          SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM
                        );
                        setSelectedImage(SOCIAL_MEDIA_SELECTED_IMAGE.INSTAGRAM);
                        setShowPreviewInfo(true);
                      }
                      eventsDataToRedux(
                        ANALYTICS_CONSTANTS.SOCIAL_PREVIEW_ACCESSED
                      );
                    }}
                  />
                </Tippy>

                <Tippy
                  content="Shorts Preview"
                  placement="left"
                  className="rounded bg-gray-900 p-2 text-sm text-white"
                  delay={0}
                >
                  <img
                    src={Youtube}
                    className={`mb-10 mt-2 h-10 cursor-pointer rounded-full bg-white p-2 shadow-md focus:outline-none ${
                      selectedImage === SOCIAL_MEDIA_SELECTED_IMAGE.YOUTUBE &&
                      "border-2 border-blue-500"
                    }`}
                    onClick={() => {
                      if (
                        selectedImage === SOCIAL_MEDIA_SELECTED_IMAGE.YOUTUBE
                      ) {
                        handleSocialPreviewOut();
                      } else {
                        handleSocialMediaOverlay(
                          fabricRef,
                          backgroundClipPathRef,
                          selectedLayout,
                          YoutubeOverlay,
                          getSocialMediaHandle("social_yt") || "username",
                          currentSelectedMicroContent?.gist ||
                            "your video captions",
                          currentUser?.photoURL,
                          SOCIAL_MEDIA_SELECTED_IMAGE.YOUTUBE
                        );
                        setSelectedImage(SOCIAL_MEDIA_SELECTED_IMAGE.YOUTUBE);
                        setShowPreviewInfo(true);
                      }
                      eventsDataToRedux(
                        ANALYTICS_CONSTANTS.SOCIAL_PREVIEW_ACCESSED
                      );
                    }}
                  />
                </Tippy>
              </>
            )}
        </button>
      </div>

      {currentVideoLink && isHlsUrl(currentVideoLink) && <LowResPreviewInfo />}

      <div
        ref={globalCanvasRef}
        className="canvas-container flex flex-1 flex-col rounded"
      >
        {currentVideoLink && (
          <VideoJS
            url={currentVideoLink}
            videoElRef={videoElRef}
            onTimeUpdate={() => {
              onTimeUpdate();
              updateProgressBarAnimation();
            }}
            onPause={() => {
              dispatch(togglePlayPause(false));
            }}
            initCanvas={initCanvas}
            syncBRollsVideoTimeWithMainVideo={() => {
              syncBRollsVideoTimeWithMainVideo(
                (videoElRef.current?.currentTime ?? 0) * 1000
              );
              syncAudioTimeWithMainVideo(
                (videoElRef.current?.currentTime ?? 0) * 1000
              );
            }}
            setIsVideoSeeking={setIsVideoSeeking}
          />
        )}
        {isVideoSeeking && (
          <div className="absolute left-1/2 top-[45%] z-50 -translate-x-1/2 -translate-y-1/2 transform">
            <Spinner size="xl" />
          </div>
        )}

        <div className="flex-1">
          <EditorCanvas
            fabricRef={fabricRef}
            updateSelectedTextId={updateSelectedTextId}
          />
        </div>
      </div>
    </div>
  );

  const allItems = (
    <>
      <div className="overflow-hidden">
        <div className={clsx(styles.editorUpperArea)}>
          {outlet}
          {editorControls}
        </div>
        <div>{timelineArea}</div>
      </div>
      {operationModals}
    </>
  );

  return (
    <>
      <div
        className={`search-me flex-no-wrap flex h-[calc(100vh_-_5rem)] overflow-hidden`}
      >
        <EditorLeftSidebar />
        <div style={{ height: "100%", width: "calc(100vw - 80px)" }}>
          <div style={{ height: "calc(100% - 200px)" }}>{allItems}</div>
          {/* <div>{allItems}</div> */}
        </div>
      </div>
    </>
  );
};

export default Editor;
