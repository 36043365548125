import * as FullStory from "@fullstory/browser";
import { type ClassValue, clsx } from "clsx";
import { customAlphabet } from "nanoid";
import { twMerge } from "tailwind-merge";

import { store } from "@/store";

import api from "@/api/api";

import {
  FULLSTORY_BLACKLISTED_COUNTRIES,
  PARALLEL_UPLOAD_LIMITS,
} from "@/constants";
import { layoutsArray } from "@/constants/aspect-ratio";

import {
  getValueFromLocalStorage,
  isProd,
  saveValueToLocalStorage,
} from "@/helpers/browser-storage";

import {
  COUNTRY_CURRENCY_BY_LOCATION,
  notificationType,
} from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { UserOnboarding, EditorAppliedTemplate, Timeout } from "@/interfaces";

import { PlanType, ProjectStatus, RouterPath, VideoLayout } from "@/enums";

import { DEFAULT_COLON_SEPARATED_TIME_IN_MILLIS } from "@/views/editor/constant";

const MIME_TYPES: any = {
  mp4: "video/mp4",
  mov: "video/quicktime",
  m4v: "video/x-m4v",
  webm: "video/webm",
};

export const getPresignedUrl = async (
  link: string,
  project_id: string,
  fileName?: any
) => {
  try {
    const response: any = await api.get(`/assets/download/getPresignedUrl`, {
      params: {
        remote_url: link,
        project_id: project_id,
        filename: encodeURIComponent(fileName),
      },
    });
    return response.data.url;
  } catch (error) {
    console.log(error, "From presigned url");
  }
};

export const downloadS3Url = async (
  link: string,
  fileName: string,
  project_id: string,
  isBulkDownload?: boolean
) => {
  try {
    let url = "";
    if (isBulkDownload) {
      url = link;
    } else {
      url = await getPresignedUrl(link, project_id, fileName);
    }

    download(url, fileName);
  } catch (error) {
    console.log(error, "From downloadS3Url");
  }
};

export const download = (link: string, fileName?: string) => {
  try {
    const element = document.createElement("a");
    element.setAttribute("href", link);
    element.setAttribute("download", fileName || "");
    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  } catch (error) {
    console.error("error from download", error);
    showNotification(
      "Error while downloading your clip, Please make sure your browser doesn't blocking popups",
      notificationType.FAIL
    );
  }
};

export const isValidYoutubeUrl = (url: string) => {
  const validIdLength = 11;
  // Check if URL format is valid

  const regExp =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const match = url.match(regExp);
  const youtubeVideoId = match && match[1];
  if (match && youtubeVideoId?.length === validIdLength) {
    return youtubeVideoId;
  } else {
    return false;
  }
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const calculateScaleUpSizeFromBaseTemplate = (
  baseTemplateHeight: number,
  desiredTemplateHeight: number
) => {
  return desiredTemplateHeight / baseTemplateHeight;
};

export function getRandomElement(list: any) {
  if (list) {
    return list[Math.floor(Math.random() * list.length)];
  }
  return list;
}

export const addOrReplaceObjectInArray = (arr: any, newObj: any) => [
  ...arr.filter((o: any) => o.id !== newObj.id),
  { ...newObj },
];

export const areArraysEqual = (array1: any, array2: any) => {
  if (array1.length === array2.length) {
    return array1.every((element: any) => {
      if (array2.includes(element)) {
        return true;
      }

      return false;
    });
  }

  return false;
};

export const reverseArrayForPreferredTemplates = (
  arr: EditorAppliedTemplate[]
) => {
  // reversing the array to get the correct order of the templates
  return arr.some((template: any) => template?.isPreferred)
    ? arr.reverse()
    : arr;
};

export const sortArrayByUserCreatedTemplate = (arr: any[]) => {
  return arr.sort((a: any, b: any) => {
    if (a?.isDefault === b?.isDefault) {
      return 0;
    } else if (a?.isDefault) {
      return 1;
    } else {
      return -1;
    }
  });
};

export const sortArrayForPreferredTemplate = (arr: any[]) => {
  return arr.some((template: any) => template?.isPreferred)
    ? sortArrayByUserCreatedTemplate(
        arr.sort((a: any, b: any) => {
          if (a.isPreferred === b.isPreferred) {
            return 0;
          } else if (a?.isPreferred) {
            return -1;
          } else {
            return 1;
          }
        })
      )
    : sortArrayByUserCreatedTemplate(arr);
};

export const getParsedJsonData = (data: any) => {
  return data ? JSON.parse(data) : {};
};

export const replaceObjectKey = (obj: any, oldKey: string, newKey: string) => {
  const newObject = {};
  delete Object.assign(newObject, obj, { [newKey]: obj[oldKey] })[oldKey];

  return newObject;
};

// allow following redirects after login
const allowedRedirects = [RouterPath.MANAGE_SUBSCRIPTION];
export const getLoginRedirectUrl = (location: any) => {
  const returnAuthUrl = "/auth/login";

  if (location.search === "?isNewUser=true") {
    return returnAuthUrl;
  }

  if (allowedRedirects.includes(location.pathname as RouterPath)) {
    return `${returnAuthUrl}?redirect=${location.pathname}`;
  }

  return returnAuthUrl;
  // return `${returnAuthUrl}${location.search}`;
};

export const removeDuplicateObjectsFromArray = (array: any) => {
  if (array?.length) {
    return array.filter(
      (obj: any, index: number, self: any) =>
        index === self.findIndex((t: any) => t.id === obj.id)
    );
  }
  return [];
};

export const isAdminTemplateEditor = () => {
  // only return localhost in dev
  // return window.location.host === "localhost:5173";
  return (
    window.location.host === "template-editor.vidyo-ui.pages.dev" ||
    window.location.host === "admin-template-editor.vidyo-ui.pages.dev"
  );
};

const getRandomBrightColor = (): string => {
  const letters = "56789ABCD"; // only allow bright colors
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

let usedColors: string[] = []; // array to store used colors

export const getUniqueRandomBrightColor = (
  maxAttempts: number = 100
): string => {
  let color: string;
  let attempts = 0;
  do {
    if (attempts === maxAttempts) {
      usedColors = []; // reset used colors list
      attempts = 0;
    }
    color = getRandomBrightColor();
    attempts++;
  } while (usedColors.includes(color));
  usedColors.push(color); // add color to used colors list
  return color;
};

export const isOnboardingCompleted = (
  onBoardingData: UserOnboarding | {} | undefined
) => {
  return onBoardingData && Object.keys(onBoardingData).length > 0;
};

export const getColonSeparatedTimeWithMillis = (timeInMillis: number) => {
  let timeArray = timeInMillis
    ? new Date(timeInMillis)?.toISOString()?.substring(11, 23)
    : DEFAULT_COLON_SEPARATED_TIME_IN_MILLIS;
  return timeArray;
};

export const hasMoreThanKeys = (obj: any, num: number) => {
  return obj && Object.keys(obj).length > num;
};

export const isParallelUploadAllowed = (
  allProjects: any,
  onBoardingData: any
) => {
  // if projects not loaded return true
  if (!allProjects) {
    return true;
  }

  // get count of projects with status open
  const processingCount = allProjects?.filter(
    (p: any) => p.status === ProjectStatus.OPEN
  )?.length;

  // // get count for the videos that are uploaded via uppy
  const uploadingVideosCount = Object.values(onBoardingData).filter(
    (project: any) =>
      project?.processingVideoData?.id &&
      !project?.processingVideoData?.isProcessingFailed // do not count failed videos
  ).length;

  const planType = store.getState().authState.userSubscription.planType;

  const processingLimit: number = PARALLEL_UPLOAD_LIMITS[planType];

  // if processing count is less than limit according to plan, return true
  if (processingCount + uploadingVideosCount < processingLimit) {
    return true;
  }
  return false;
};
export const getOrdinalNum = (num: number) => {
  let selector;

  if (num <= 0) {
    selector = 4;
  } else if ((num > 3 && num < 21) || num % 10 > 3) {
    selector = 0;
  } else {
    selector = num % 10;
  }

  return num + ["th", "st", "nd", "rd", ""][selector];
};

export const getOrdinalDayOfMonthFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  return getOrdinalNum(day);
};

const getFileExtension = (fileName: string) => {
  const fileExtension = fileName.split(".").pop() || "";
  return fileExtension.toLowerCase();
};

export const isFileAllowedType = (file: File, allowedTypes: string[]) => {
  return allowedTypes.includes("." + getFileExtension(file.name));
};

export const isMOVFile = (file: File) => {
  return getFileExtension(file.name) === "mov";
};

export const getMimeTypeFromFileName = (fileName: string) => {
  const fileExtension: string = (fileName.split(".").pop() || "").toLowerCase();
  return MIME_TYPES[fileExtension] || "";
};

export const getFileMimeType = (file: File) => {
  const type = file.type;
  if (type) {
    return type;
  }
  const mimeType = getMimeTypeFromFileName(file.name);
  return mimeType;
};

export const parseUserPreferenceData = (data: any) => {
  if (!data) return undefined;

  let newBaseTemplates = {};

  layoutsArray.forEach((layout) => {
    newBaseTemplates = {
      ...newBaseTemplates,
      [layout]:
        data[layout]?.length > 0
          ? data[layout]
              .filter((template: any) => template?.isPreferred)
              .map((item: any) => item.id)
          : [],
    };
  });
  return { ...newBaseTemplates };
};

export const getPreferredTemplate = ({
  baseTemplates,
  savedTemplates,
  layout,
}: {
  baseTemplates: any;
  savedTemplates: any;
  layout: VideoLayout;
}) => {
  const baseTemplateArray = baseTemplates[layout as VideoLayout];
  const preferredTemplateIDArray = savedTemplates[layout];

  const updatedBaseTemplates = baseTemplateArray?.map((obj: any) => {
    let newTemplateObj = { ...obj };
    if (preferredTemplateIDArray?.includes(obj.id)) {
      newTemplateObj = {
        ...obj,
        isPreferred: true,
      };
    }
    return newTemplateObj;
  });
  return updatedBaseTemplates || [];
};

export const updatedUserSavedPreferencesData = (
  baseTemplates: any,
  savedTemplates: any
) => {
  let newBaseTemplates = {};

  layoutsArray.forEach((layout) => {
    newBaseTemplates = {
      ...newBaseTemplates,
      [layout]: getPreferredTemplate({
        baseTemplates,
        savedTemplates,
        layout: layout as VideoLayout,
      }),
    };
  });

  return { ...newBaseTemplates };
};

export function loadFonts(fonts: any[]) {
  var newStyle = document.createElement("style");

  fonts.forEach((font) => {
    newStyle.appendChild(
      document.createTextNode(
        "@font-face{font-family: " +
          font.asset_name +
          "; src: url(" +
          font.remote_url +
          ");}"
      )
    );
  });

  document.body.appendChild(newStyle);
  console.log("success", newStyle);
}
export const invertHex = (hex: string) => {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }

  if (hex.length != 6) {
    console.warn("Hex color must be six hex numbers in length.");
    return "#" + hex;
  }

  hex = hex.toUpperCase();
  const splitNum: any = hex.split("");
  let resultNum = "";
  const simpleNum = "FEDCBA9876".split("");
  const complexNum: any = {
    A: "5",
    B: "4",
    C: "3",
    D: "2",
    E: "1",
    F: "0",
  };

  for (let i = 0; i < 6; i++) {
    if (!isNaN(Number(splitNum[i]))) {
      resultNum += simpleNum[splitNum[i]];
    } else if (complexNum[splitNum[i]]) {
      resultNum += complexNum[splitNum[i]];
    } else {
      console.warn("Hex colors must only include hex numbers 0-9, and A-F");
      return "#" + hex;
    }
  }

  return "#" + resultNum;
};

export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  // check if hexCode is rgb or rgba if yes just change the opacity
  if (hexCode.includes("rgba")) {
    const rgba = hexCode.split(",");
    rgba[3] = `${opacity})`;
    return rgba.join(",");
  }

  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

export const replaceUndefinedWithNull = (obj: any): any => {
  if (Array.isArray(obj)) {
    // If obj is an array, recursively call replaceUndefinedWithNull on each element
    return obj.map((element: any) => replaceUndefinedWithNull(element));
  } else if (typeof obj === "object" && obj !== null) {
    // If obj is an object (but not an array or null), recursively call replaceUndefinedWithNull on each value
    const newObj: any = {};
    for (const key in obj) {
      newObj[key] = replaceUndefinedWithNull(obj[key]);
    }
    return newObj;
  } else if (obj === undefined) {
    // If obj is undefined, replace it with null
    return null;
  } else {
    // Otherwise, return obj unchanged
    return obj;
  }
};

export const alphaNumericNanoId = (size = 21) => {
  return customAlphabet(
    "346789ABCDEFGHJKLMNPQRTUVWXYabcdefghijkmnpqrtwxyz",
    size
  )();
};

export const sortByUpdatedDate = (data = [] as any[]) => {
  if (!data.length) return data;
  return data.sort((a: any, b: any) => {
    return new Date(b?.updatedAt).getTime() - new Date(a?.updatedAt).getTime();
  }) as any[];
};

// input date format should be YYYY-MM-DD
export const isDateGreaterThanEqualsToday = (inputDate: Date) => {
  const today = new Date();
  return today >= inputDate;
};

// change http url to https if not
export const changeHttpToHttps = (url: string) => {
  if (url.includes("http://")) {
    return url.replace("http://", "https://");
  }
  return url;
};

export const isMobileDevice = () => {
  // when user loads the website in mobile device we save the value in local storage
  // and use it for further checks to avoid users from accessing website in desktop mode
  const isMobileLocalStorage = getValueFromLocalStorage("isMobile");
  if (isMobileLocalStorage) return isMobileLocalStorage;

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobile) saveValueToLocalStorage("isMobile", isMobile);

  return isMobile;
};

const buildQueryString = (params: { [key: string]: string | number }) => {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join(",");
};

export const generateCloudflareImageUrl = ({
  originalUrl,
  imgOptions,
}: {
  originalUrl: string;
  imgOptions?: {
    width?: number | "auto";
    height?: number | "auto";
    quality?: number;
  };
}): string | undefined => {
  if (!originalUrl) {
    console.error("Original URL must be provided.");
  }

  const url = new URL(originalUrl);
  if (!url.protocol || !url.hostname) {
    throw new Error("Invalid URL. It must include a protocol and hostname.");
  }

  const params = {
    width: imgOptions?.width || "auto",
    height: imgOptions?.height || "auto",
    quality: imgOptions?.quality || 85,
    format: "auto",
  };

  return `https://vidyo.ai/cdn-cgi/image/${buildQueryString(
    params
  )}/${originalUrl}`;
};

const generateCloudflareVideoUrl = ({ key }: { key: string }) => {
  const prodDomain = "https://prod-storage.vidyo.ai/";
  const stageDomain = "https://storage.vidyo.ai/";
  const domain = isProd() ? prodDomain : stageDomain;
  return `${domain}${key}`;
};

export const generateUploadedVideoUrl = ({
  responseBody,
}: {
  responseBody: any;
}) => {
  if (typeof responseBody === "string") {
    const httpsUrl = changeHttpToHttps(responseBody);

    return generateCloudflareVideoPreviewURL(httpsUrl);
  }

  if (responseBody?.location === "auto") {
    return generateCloudflareVideoUrl({ key: responseBody?.Key });
  } else {
    const httpsUrl = changeHttpToHttps(responseBody?.location);

    return generateCloudflareVideoPreviewURL(httpsUrl);
  }
};

const generateCloudflareVideoPreviewURL = (uploadUrl: string) => {
  if (uploadUrl.includes("r2.cloudflarestorage.com")) {
    const match = uploadUrl.split("/uploaded-videos/");
    return generateCloudflareVideoUrl({ key: `uploaded-videos/${match[1]}` });
  }
  return uploadUrl;
};

export const zE = (action: string, ...args: any[]) => {
  const typeSafeWindow = window as any;
  if (
    typeof typeSafeWindow !== "undefined" &&
    typeof typeSafeWindow.zE === "function"
  ) {
    try {
      typeSafeWindow.zE(action, ...args);
    } catch (error) {}
  }
};

export const addOnboardingDataForTracking = (
  uid: string,
  onBoardingData: any
) => {
  if (FullStory.isInitialized()) {
    FullStory.identify(uid, {
      persona: onBoardingData.occupation,
      isIndividual: onBoardingData.isIndividual,
      referer: onBoardingData.referer,
    });
  }

  (window as any).heap?.addUserProperties({
    custom_platform: onBoardingData.referer,
    custom_is_team: !onBoardingData.isIndividual,
    custom_persona: onBoardingData.occupation,
  });
};

export const convertToCamelCase = (str: any) => {
  if (str === null || str.trim() === "") {
    return ""; // Return an empty string for null or blank input
  }

  // Remove all characters other than alphabets and numbers
  const cleanStr = str?.replace(/[^a-zA-Z0-9]+/g, " ") || "";

  // Capitalize the first letter of each word
  const pascalCaseStr = cleanStr?.replace(
    /(?:^|\s)(\w)/g,
    (match: any, letter: any) => letter?.toUpperCase()
  );

  // Remove spaces
  return pascalCaseStr?.replace(/\s+/g, "") || "";
};

export const getCurrencyByCountryCode = (countryCode: string | null) => {
  const currency = COUNTRY_CURRENCY_BY_LOCATION[countryCode || "US"];
  return currency || COUNTRY_CURRENCY_BY_LOCATION.US;
};

export const forceUpgradeModalOnUser = () => {
  const planType = store.getState().authState.userSubscription.planType;
  const userCountry = store.getState().authState.country;

  if (
    planType === PlanType.FREE &&
    userCountry &&
    FULLSTORY_BLACKLISTED_COUNTRIES.indexOf(userCountry) !== -1
  ) {
    return true;
  }

  return false;
};

// convert timestamp to date-time format
// example return value - "August 21, 2023 - 5:30 PM"
export const convertTimeStampToDateTime = (timeStamp: Date | string | null) => {
  if (timeStamp) {
    const time = new Date(timeStamp);
    let hours = time.getHours();
    const minutes = String(time.getMinutes()).padStart(2, "0");

    const amOrPm = hours >= 12 ? "PM" : "AM";
    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12;

    return `${time.toLocaleString("default", {
      month: "long",
    })} ${time.getDate()}, ${time.getFullYear()} - ${hours}:${minutes} ${amOrPm}`;
  }
};

export function findElementAfterIterations<T>(
  arr: T[],
  count: number
): T | undefined {
  if (!arr.length) return undefined; // Return undefined if array is empty

  // Calculate the effective index after m iterations
  const index = (count - 1) % arr.length;

  return arr[index];
}

export const getColonSeparatedTime = (
  timeInMillis: number,
  currentSelectedMicroContent: any
) => {
  // To ensure that the time is always positive
  if (timeInMillis - currentSelectedMicroContent.start >= 0) {
    return new Date(timeInMillis - currentSelectedMicroContent.start)
      ?.toISOString()
      ?.substring(11, 23);
  } else {
    return "00:00:00:000";
  }
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const changeStageUrlToProd = (url: string) => {
  if (isProd()) {
    const prodDomain = "https://prod-storage.vidyo.ai/";
    const stageDomain = "https://storage.vidyo.ai/";

    return url.replace(stageDomain, prodDomain);
  }
  return url;
};

export const getAlphaAsPercentage = (rgba: string): number => {
  const rgbaRegex = /rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/i;
  const match = rgba.match(rgbaRegex);

  if (!match) {
    return 100; // Return 100 if the input is not a valid RGBA string.
  }

  // Destructure to get only the alpha value from the matched groups.
  const [, , , , alphaStr] = match;

  // Convert the alpha string to a floating point number.
  const alpha = parseFloat(alphaStr);

  // Check for valid alpha value before proceeding.
  if (isNaN(alpha) || alpha < 0 || alpha > 1) {
    return 100;
  }
  return Math.round(alpha * 100);
};

export function isHlsUrl(url: string): boolean {
  const regex = /\.m3u8$/i;
  return regex.test(url);
}

export function isSafari(): boolean {
  const userAgent = window.navigator.userAgent;
  return /^((?!chrome|android).)*safari/i.test(userAgent);
}

export function createDelay(
  delayMs = 10,
  addItemToTimeoutStack: (ctx: { timeout?: Timeout }) => void
): (fn: () => void) => void {
  const context: { timeout?: Timeout } = {};
  addItemToTimeoutStack(context);
  return (callback: () => void) => {
    context.timeout && clearTimeout(context.timeout);
    context.timeout = setTimeout(callback, delayMs);
  };
}

export const stringToSha256 = async (inputString: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(inputString); // Encode as UTF-8
  const hashBuffer = await crypto.subtle.digest("SHA-256", data); // Hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // convert bytes to hex string
  return hashHex;
};
