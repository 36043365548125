import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { EditorDraft } from "@/interfaces";

type DraftState = {
  selectedEditorDraft: any;
};

const initialState: DraftState = {
  selectedEditorDraft: null,
};

const draftSlice = createSlice({
  name: "draft-state",
  initialState,
  reducers: {
    updateSelectedDraft(state, action: PayloadAction<EditorDraft | null>) {
      state.selectedEditorDraft = action.payload;
    },
  },
});

export const { updateSelectedDraft } = draftSlice.actions;
export default draftSlice;
