import { useQuery } from "@tanstack/react-query";

import api from "@/api";
import { ApiEndpoints } from "@/api/constants";

import { isProd } from "@/helpers";

const getUtmDataFromDB = async () => {
  if (isProd()) {
    const { data } = await api.get(ApiEndpoints.SAVE_UTM);
    return data;
  } else {
    return {};
  }
};

export default function useGetUTM() {
  return useQuery({
    queryKey: ["User-UTM"],
    queryFn: getUtmDataFromDB,
    staleTime: 300000,
  });
}
