import { useEffect, useState } from "react";
import { useCopyToClipboard } from "react-use";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "flowbite-react";

import { updateShowPreviewClipsSideModal } from "@/store/homeSlice";
import { useAppDispatch } from "@/store/hooks";

import { REVIEW_CLIPS_INTERACTION } from "@/constants/amplitude";

import { formatMillisecondsToHHMMSS } from "@/helpers";

import { trackReviewClipsInteractionEvents } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

export const VideoTimestamps = ({
  setActivePreviewTab,
  microContentData,
}: {
  setActivePreviewTab: (value: string | null) => void;
  microContentData: any;
}) => {
  const dispatch = useAppDispatch();

  const [isCopied, setIsCopied] = useState(false);
  const [state, copyToClipboard] = useCopyToClipboard();

  const sortDataOnStartTime = (arr: any) => {
    return arr.sort((a: any, b: any) => a.start - b.start);
  };

  useEffect(() => {
    if (state.error) {
      showNotification("Could not copy timestamps", notificationType.FAIL);
    }
    if (state.value) {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [state]);

  const copyContent = () => {
    const tempData = microContentData;
    const data = sortDataOnStartTime(tempData?.chapters).map((chapter: any) => {
      return `${formatMillisecondsToHHMMSS(chapter.start)} ${chapter.gist}`;
    });

    copyToClipboard(data.join("\n"));
  };

  return (
    <div
      className="absolute h-[calc(100vh_-_5rem)] w-[25%] bg-[#F3F4F6] top-0 right-0 bottom-0 z-10 shadow-md-black pb-5 overflow-auto"
      style={{
        boxShadow: "-5px 0 10px -10px rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="flex px-5 pt-6 pb-4 relative">
        <div>
          <p className="text-xl font-semibold">Video Timestamps</p>
        </div>
        <div className="absolute right-9 top-7">
          <XMarkIcon
            onClick={() => {
              dispatch(updateShowPreviewClipsSideModal(false));
              setActivePreviewTab(null);
            }}
            className="cursor-pointer"
            height={22}
            width={22}
          />
        </div>
      </div>

      <div className="w-full bg-[#F3F4F6] px-5 pt-5">
        {microContentData?.chapters?.length ? (
          <>
            {sortDataOnStartTime(microContentData?.chapters).map(
              (chapter: any, index: number) => (
                <div
                  className="mb-2 flex"
                  key={index}
                >
                  <span className="text-[#1C64F2]">
                    {formatMillisecondsToHHMMSS(chapter.start)}
                  </span>
                  <span className="ml-3 text-sm font-light">
                    {chapter.gist}
                  </span>
                </div>
              )
            )}
            <div className="mt-8">
              <Button
                onClick={() => {
                  copyContent();
                  trackReviewClipsInteractionEvents(
                    REVIEW_CLIPS_INTERACTION.VIDEO_TIMESTAMP_COPIED
                  );
                }}
                size="sm"
                outline
              >
                {isCopied ? "Copied" : "Copy"}
              </Button>
            </div>
          </>
        ) : (
          <div>
            <span className="">No timestamp generated for this project</span>
          </div>
        )}
      </div>
    </div>
  );
};
