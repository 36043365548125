import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { clearLocalStorage } from "@/helpers";

import { Subscription } from "@/interfaces";

type HomeState = {
  currentUser: any;
  isAuthenticated: boolean;
  userSubscription: Subscription;
  paddleSubscription: any;
  country: string | null;
  paddlePlans: any;
  userIP: any;
};

const initialState: HomeState = {
  currentUser: {},
  isAuthenticated: false,
  userSubscription: {} as Subscription,
  country: null,
  paddleSubscription: {},
  paddlePlans: null,
  userIP: null,
};

const authSlice = createSlice({
  name: "auth-state",
  initialState,
  reducers: {
    setCurrentUser(state, action: PayloadAction<object>) {
      state.currentUser = action.payload;
    },
    setUserSubscription(state, action: PayloadAction<Subscription>) {
      state.userSubscription = action.payload;
    },
    setPaddleSubscription(state, action: PayloadAction<any>) {
      state.paddleSubscription = action.payload;
    },
    setCountry(state, action: PayloadAction<string>) {
      state.country = action.payload;
    },
    setAllPaddlePlansPricing(state, action: PayloadAction<any>) {
      state.paddlePlans = action.payload;
    },
    login(state, action: PayloadAction<object>) {
      state.currentUser = action.payload;
      state.isAuthenticated = true;
    },
    setUserIP(state, action: PayloadAction<string>) {
      state.userIP = action.payload;
    },
    logOut() {
      clearLocalStorage();
      return initialState;
    },
  },
});

export const {
  setCurrentUser,
  login,
  logOut,
  setUserSubscription,
  setPaddleSubscription,
  setCountry,
  setUserIP,
  setAllPaddlePlansPricing,
} = authSlice.actions;
export default authSlice;
