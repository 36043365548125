import { EditorCommandType } from "@/constants/editor-commands";

import { createCommand, Command } from "@/views/editor/EditorHistoryReducer";

export const addTextCommand = (
  textData: any,
  addText: (textData: any) => void,
  deleteText: (textId: string, isisUndoOperation: boolean) => void
): Command => {
  return createCommand({
    executeAction: () => addText(textData),
    undoAction: () => deleteText(textData.id, true),
    commandType: EditorCommandType.AddText,
  });
};

export const deleteTextCommand = (
  textId: string | number,
  addText: (textData: any) => void,
  deleteText: (textId: string | number) => void,
  getTextData: (textId: string | number) => any
): Command => {
  const textData = getTextData(textId);
  return createCommand({
    executeAction: () => deleteText(textId),
    undoAction: () => addText(textData),
    commandType: EditorCommandType.DeleteText,
  });
};

export const changeTextCommand = (
  textId: string | number,
  newText: string,
  oldText: string,
  applyTextChange: (id: string | number, text: string) => void
): Command => {
  return createCommand({
    executeAction: () => applyTextChange(textId, newText),
    undoAction: () => applyTextChange(textId, oldText),
    commandType: EditorCommandType.EditText,
  });
};

export const updateTextFullLengthCommand = (
  metaData: any,
  applyTextChange: (
    isFullLength: boolean,
    textId: string,
    isBRoll: boolean,
    value: any
  ) => void
): Command => {
  const { newValue, oldValue, textId, isBRoll = false } = metaData;
  return createCommand({
    executeAction: () =>
      applyTextChange(
        newValue?.isFullVideoLength,
        textId,
        isBRoll,
        newValue?.times
      ),
    undoAction: () =>
      applyTextChange(
        oldValue?.isFullVideoLength,
        textId,
        isBRoll,
        oldValue?.times
      ),
  });
};

export const modifyTextCommand = (
  metaData: any,
  applyTextModification: (data: any, elementId: string) => void
): Command => {
  const { newTextObjs, oldTextObjs, elementId } = metaData;
  return createCommand({
    executeAction: () => applyTextModification(newTextObjs, elementId),
    undoAction: () => applyTextModification(oldTextObjs, elementId),
  });
};

export const changeTextStylesCommand = (
  metaData: any,
  applyTextStylesModification: (
    textId: string | number,
    textObjects: any
  ) => void
): Command => {
  const { id, newTextStyles, oldTextStyles } = metaData;
  return createCommand({
    executeAction: () => applyTextStylesModification(id, newTextStyles),
    undoAction: () => applyTextStylesModification(id, oldTextStyles),
  });
};

export const textTransformCommand = (
  metaData: any,
  applyTextBoxStylesModification: (
    textId: string | number,
    textObjects: any
  ) => void
): Command => {
  const { id, newTextTransform, oldTextTextTransform } = metaData;
  return createCommand({
    executeAction: () => applyTextBoxStylesModification(id, newTextTransform),
    undoAction: () => applyTextBoxStylesModification(id, oldTextTextTransform),
  });
};

export const textStartTimeUpdate = (
  metaData: any,
  updateStartTime: (time: number, id: string | number) => void
): Command => {
  const { newTime, oldTime, id } = metaData;
  return createCommand({
    executeAction: () => updateStartTime(newTime, id),
    undoAction: () => updateStartTime(oldTime, id),
    metadata: metaData,
  });
};

export const textEndTimeUpdate = (
  metaData: any,
  updateEndTime: (time: number, id: string | number) => void
): Command => {
  const { newTime, oldTime, id } = metaData;
  return createCommand({
    executeAction: () => updateEndTime(newTime, id),
    undoAction: () => updateEndTime(oldTime, id),
    metadata: metaData,
  });
};
