import { useEffect } from "react";
import { useUpdateEffect } from "react-use";

import Hls from "hls.js";

import { isHlsUrl } from "@/helpers";

function VideoJS({
  onTimeUpdate,
  initCanvas,
  syncBRollsVideoTimeWithMainVideo,
  videoElRef,
  url,
  onEnded,
  setIsVideoSeeked,
  poster,
  playbackRate = 1,
  onPause,
  setIsVideoSeeking,
  showLoader = false,
  setShowBuffering,
  ...props
}: {
  onTimeUpdate: (event: any) => void;
  initCanvas: () => void;
  syncBRollsVideoTimeWithMainVideo?: (time: number) => void;
  videoElRef: any;
  url: string;
  onEnded?: () => void;
  setIsVideoSeeked?: (isSeeked: boolean) => void;
  setIsVideoSeeking?: (isSeeked: boolean) => void;
  poster?: string;
  playbackRate?: number;
  onPause?: any;
  showLoader?: boolean;
  setShowBuffering?: any;
}) {
  const handleBufferingStart = () => setShowBuffering && setShowBuffering(true);
  const handleBufferingEnd = () => setShowBuffering && setShowBuffering(false);

  const handleOnLoadMetadata = () => {
    const video = videoElRef.current;
    if (!video) return;

    if (video.canPlayType("application/vnd.apple.mpegurl")) {
      return;
    }
    initCanvas();
  };
  const handleVideoCanPlay = () => {
    const video = videoElRef.current;
    if (!video) return;

    if (video.canPlayType("application/vnd.apple.mpegurl")) {
      initCanvas();
    }
    return;
  };

  useEffect(() => {
    const video = videoElRef.current;

    if (!video) return;

    let hls: Hls;

    if (isHlsUrl(url)) {
      if (Hls.isSupported()) {
        function setupHls() {
          hls = new Hls({
            lowLatencyMode: false,
            maxBufferLength: 30, // Maximum buffer length (in seconds)
            autoStartLoad: true,
            maxBufferSize: 60, // Maximum buffer size in mb
          });

          hls.loadSource(url);
          hls.attachMedia(video);
          if (showLoader) {
            hls.on(Hls.Events.BUFFER_APPENDING, handleBufferingStart);
            hls.on(Hls.Events.BUFFER_APPENDED, handleBufferingEnd);
          }

          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            const availableQualities = hls.levels;
            const preferredQuality = availableQualities.find(
              (quality) => quality.height === 480 || quality.height === 720
            );
            const preferredQualityIndex =
              availableQualities.findIndex(
                (quality) => quality.height === 480 || quality.height === 720
              ) || 0;

            if (preferredQuality) {
              hls.currentLevel = preferredQualityIndex;
            }
          });

          hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  hls.recoverMediaError();
                  break;
                default:
                  break;
              }
            }
          });
        }
        setupHls();
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = url; // If the browser supports HLS natively, use the native player
      }
    } else {
      // if the url is not HLS, use a normal video
      video.src = url;
    }

    if (video) {
      video.playbackRate = playbackRate;
    }
    return () => {
      if (hls) {
        hls.destroy();
      }
      if (video) {
        video.removeAttribute("src"); // empty source
        video.load();
      }
    };
  }, [url]);

  useUpdateEffect(() => {
    if (videoElRef.current) {
      videoElRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  return (
    <>
      <video
        {...props}
        poster={poster || ""}
        ref={videoElRef}
        className="hidden"
        onSeeking={() => {
          setIsVideoSeeking && setIsVideoSeeking(true);
        }}
        muted
        onTimeUpdate={onTimeUpdate}
        onLoadedMetadata={handleOnLoadMetadata}
        onCanPlay={handleVideoCanPlay}
        onSeeked={() => {
          syncBRollsVideoTimeWithMainVideo &&
            syncBRollsVideoTimeWithMainVideo(
              videoElRef.current.currentTime * 1000
            );
          setIsVideoSeeking && setIsVideoSeeking(false);
          setIsVideoSeeked && setIsVideoSeeked(true);
        }}
        onEnded={onEnded}
        onPause={onPause}
        onWaiting={handleBufferingStart}
        onPlaying={handleBufferingEnd}
      />
    </>
  );
}

export default VideoJS;
