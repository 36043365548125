import axios from "axios";

import { store } from "@/store";
import { logOut, setCountry, setUserIP } from "@/store/authSlice";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

import { changeHttpToHttps } from "@/helpers";

import { trackAccountDelete } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { auth } from "@/utils/firebase";
import { showNotification } from "@/utils/showNotification";

import {
  FinalizeAsset,
  Asset,
  UserPreference,
  EditorDraft,
  AmplitudeTrackObject,
} from "@/interfaces";

import { AssetTags, FirebaseCollection, VideoLayout } from "@/enums";

import {
  CreateSocialMediaPayload,
  RevokeSocialMediaAccessPayload,
  UpdateScheduledPostPayload,
  UploadPostPayloadType,
} from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/types";

export const fetchThumbnail = async (
  project_id: string,
  remote_url: string,
  start: number
) => {
  const data = await api.post(ApiEndpoints.CREATE_THUMBNAIL, {
    project_id,
    remote_url,
    start,
  });
  return data;
};

export const addUserOnBoardingData = async (reqData: any) => {
  const { onBoardingData, userId } = reqData;

  const { data } = await api.post(ApiEndpoints.FIRESTORE_PROXY, {
    collection: FirebaseCollection.USER_ONBOARDING,
    doc_id: userId,
    object: onBoardingData,
  });

  return data;
};

export const addUserTemplates = async (reqData: any) => {
  const { userTemplatesData, userId } = reqData;

  const { data } = await api.post(ApiEndpoints.FIRESTORE_PROXY, {
    collection: FirebaseCollection.USER_TEMPLATES,
    doc_id: userId,
    object: userTemplatesData,
  });

  return data;
};

export const getDefaultBaseTemplates = async () => {
  const { data } = await api.get(
    `${ApiEndpoints.FIRESTORE_PROXY_BASE_TEMPLATES}`
  );

  return data as Record<VideoLayout, {}>;
};

export const getUserTemplates = async (userId: string) => {
  try {
    const { data } = await api.get(
      `${ApiEndpoints.FIRESTORE_PROXY}?collection=${FirebaseCollection.USER_TEMPLATES}&doc_id=${userId}`
    );

    return data as Record<VideoLayout, {}>;
  } catch (error: any) {
    if (error.response.status === 404) {
      return {} as Record<VideoLayout, {}>;
    }
  }
};

export const getUserOnBoardingData = async (userId: string) => {
  try {
    const { data } = await api.get(
      `${ApiEndpoints.FIRESTORE_PROXY}?collection=${FirebaseCollection.USER_ONBOARDING}&doc_id=${userId}`
    );

    return data as Record<string, {}>;
  } catch (error: any) {
    if (error.response.status === 404) {
      return {} as Record<string, {}>;
    }
  }
};

export const updateUserTemplates = async (reqData: any) => {
  const { userTemplatesData, userId } = reqData;

  const { data } = await api.post(ApiEndpoints.FIRESTORE_PROXY, {
    collection: FirebaseCollection.USER_TEMPLATES,
    doc_id: userId,
    object: userTemplatesData,
  });

  return data;
};

export const updateDefaultTemplate = async (reqData: any) => {
  const { defaultTemplatesDataForSingleLayout, layout } = reqData;

  const { data } = await api.post(ApiEndpoints.FIRESTORE_PROXY, {
    collection: FirebaseCollection.BASE_TEMPLATES,
    doc_id: layout,
    object: defaultTemplatesDataForSingleLayout,
  });

  return data;
};

export const getUserSubscription = async (uid: string) => {
  try {
    const { data } = await api.get(
      `${ApiEndpoints.FIRESTORE_PROXY}?collection=${FirebaseCollection.SUBSCRIPTION}&doc_id=${uid}`
    );

    return data;
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      return null;
    }

    throw error;
  }
};

export const getAllProjects = async (
  userId: string,
  limit: number,
  offset: number
) => {
  const {
    data,
  }: {
    data: any;
  } = await api
    .post(
      ApiEndpoints.ALL_PROJECTS_LIST,
      {
        user_id: userId,
      },
      {
        params: {
          limit,
          offset,
        },
      }
    )
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      console.log("Failed to fetch projects");
    });

  return data;
};

export const getUserLocationByIP = async () => {
  try {
    const { data }: any = await api.get(ApiEndpoints.IP_LOOKUP);

    const { country, ip } = data;

    if (country) {
      store.dispatch(setCountry(country));
    }

    if (ip) {
      store.dispatch(setUserIP(ip));
    }

    return data;
  } catch (error: any) {
    console.log(error);
  }
};

const handelGetPresignedS3Url = async (assetType: string) => {
  const response = await api.put(ApiEndpoints.PRESIGNED_URL_S3, {
    asset_type: assetType,
  });
  return response;
};

export const uploadFileToS3 = async (file: any) => {
  try {
    const s3Object: any = await handelGetPresignedS3Url(file.type);
    console.log(s3Object?.data);
    const s3Url = s3Object?.data?.url?.split("?")[0];
    await api.put(s3Object?.data?.url, file);
    return {
      s3Url,
      assetId: s3Object?.data?.asset_id,
      public_url: s3Object?.data?.public_url || "",
    };
  } catch (error: any) {
    console.log("Something went wrong while uploading to s3...", error);

    if (error.response?.data?.detail === "Asset count limit reached") {
      showNotification(
        "Free plan limit reached, please upgrade to pro for unlimited asset upload",
        notificationType.WARN
      );
    }
  }
};

export const finalizeAssetUpload = async ({
  assetType,
  assetId,
  ownerId,
  parentId,
  assetTag,
  data,
}: FinalizeAsset) => {
  const resData = await api.post(ApiEndpoints.FINALIZE_ASSET_UPLOAD, {
    asset_type: assetType,
    asset_id: assetId,
    owner_id: ownerId,
    data: {
      remote_url: changeHttpToHttps(data.remoteUrl),
      asset_name: data.assetName,
      duration: data.duration,
    },
    parent_id: parentId,
    asset_tag: assetTag,
  });
  return resData;
};

export const deleteAsset = async (assetId: string | number | undefined) => {
  const response = await api.delete(`${ApiEndpoints.ASSETS}/${assetId}`);
  return response;
};

export const getAssets = async ({ assetTag }: { assetTag: AssetTags }) => {
  const response = await api.get(`${ApiEndpoints.ASSETS}/${assetTag}`);
  return response.data as Asset[];
};

export const getAssetCount = async (isFreePlan: boolean) => {
  const response: any = await api.get(ApiEndpoints.ASSETS_COUNT);
  return {
    count: response.data.count,
    isAllowedToUpload: isFreePlan
      ? response.data.count >= 3
        ? false
        : true
      : true,
  } as {
    count: number;
    isAllowedToUpload: boolean;
  };
};

export const addUserPreferenceData = async (reqData: any) => {
  const { userPreferenceData, userId } = reqData;

  const { data } = await api.post(ApiEndpoints.FIRESTORE_PROXY, {
    collection: FirebaseCollection.USER_PREFERENCE,
    doc_id: userId,
    object: userPreferenceData,
  });

  return data;
};

export const getUserPreference = async (userId: string) => {
  try {
    const { data } = await api.get(
      `${ApiEndpoints.FIRESTORE_PROXY}?collection=${FirebaseCollection.USER_PREFERENCE}&doc_id=${userId}`
    );

    return data as UserPreference;
  } catch (error: any) {
    if (error.response.status === 404) {
      return {} as UserPreference;
    }
  }
};

export const updateUserDisplayName = (id: any, displayName: any) => {
  api.patch(ApiEndpoints.USER_UPDATE, { id, displayName });
};

export const addMoreMinutes = async (reqData: any) => {
  const { data } = await api.post(ApiEndpoints.ADD_MINUTES, reqData);

  return data;
};

export const getCaptionForTranscript = async (
  text: string,
  platform: string,
  maxChars: number
) => {
  const { data } = await api.post(ApiEndpoints.HASHTAGS, {
    microcontent_transcript: text,
    max_chars: maxChars,
    platform,
  });

  return data;
};

export const addDraft = async (reqData: any, isRenderDraft = false) => {
  const { data, projectId, draft_id } = reqData;
  try {
    const response = await api.post(
      isRenderDraft ? ApiEndpoints.RENDER_DRAFT : ApiEndpoints.EDITOR_DRAFT,
      {
        project_id: projectId,
        draft_id,
        data: {
          ...data,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error; // Re-throwing the error as we want to handle it in the calling function
  }
};

export const updateDraft = async (reqData: any) => {
  const { data, draft_id, projectId } = reqData;

  try {
    const response = await api.patch(ApiEndpoints.EDITOR_DRAFT, {
      draft_id,
      project_id: projectId,
      data: {
        ...data,
      },
    });

    return response.data;
  } catch (error) {
    throw error; // Re-throwing the error as we want to handle it in the calling function
  }
};

const parseEditorDrafts = (drafts: any, isFreePlan: boolean) => {
  const draftsArr: EditorDraft[] = [];

  drafts.data.forEach((draft: any) => {
    draftsArr.push({
      ...draft.data,
      id: draft.draft_id,
    } as EditorDraft);
  });
  // sort by updatedAt

  draftsArr.sort((a, b) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });
  return draftsArr?.length > 0 && isFreePlan
    ? draftsArr.slice(0, 1)
    : draftsArr;
};

export const getEditorDrafts = async (isFreePlan: boolean) => {
  try {
    const { data } = await api.get(`${ApiEndpoints.EDITOR_DRAFT}`);

    return parseEditorDrafts(data, isFreePlan);
  } catch (error: any) {
    if (error.response.status === 404) {
      return [];
    }
  }
};

export const deleteDraftById = async (draftData: any) => {
  const { draftId } = draftData;

  await api.delete(`${ApiEndpoints.EDITOR_DRAFT}?draft_id=${draftId}`);
};

export const getDraftById = async (draftId: string, isRenderDraft = false) => {
  try {
    const { data }: any = await api.get(
      `${
        isRenderDraft ? ApiEndpoints.RENDER_DRAFT : ApiEndpoints.EDITOR_DRAFT
      }?draft_id=${draftId}`
    );

    return data.data.data as EditorDraft;
  } catch (error: any) {
    if (error.response.status === 404) {
      return null;
    }
  }
};

export const deleteAllDraftByUserId = async (userId: string) => {
  await api.delete(
    `${ApiEndpoints.FIRESTORE_PROXY}?collection=${FirebaseCollection.DRAFTS}/${FirebaseCollection.EDITOR_DRAFTS}/${userId}`
  );
};

export const deleteUserAccount = async (showMsg = true) => {
  try {
    await api.delete(`${ApiEndpoints.USER_DELETE}`);
    showMsg &&
      showNotification(
        "Account deleted successfully",
        notificationType.SUCCESS
      );
    trackAccountDelete();
    store.dispatch(logOut());
  } catch (error: any) {
    showNotification(
      "Something went wrong. Please try again.",
      notificationType.FAIL
    );
  }
};

export const createMicroContent = async (data: any) => {
  const result = await api.put(ApiEndpoints.MICROCONTENT, data);
  return result;
};

export const updateMicroContentData = async (data: any) => {
  const result = await api.patch(ApiEndpoints.MICROCONTENT, data);
  return result;
};

// Paddle API
export const getPaddleSubscription = async (subscriptionId: number) => {
  const { data } = await api.post(ApiEndpoints.PADDLE_SUBSCRIPTION, {
    subscription_id: subscriptionId,
  });
  return data;
};

export const cancelSubscription = async (subscriptionId: number) => {
  const { data } = await api.post(ApiEndpoints.PADDLE_CANCEL_SUBSCRIPTION, {
    subscription_id: subscriptionId,
  });
  return data;
};

export const updateSubscription = async (updates: any) => {
  const { data } = await api.post(ApiEndpoints.PADDLE_UPDATE_SUBSCRIPTION, {
    ...updates,
  });
  return data;
};

export const createOneTimeCharge = async (
  subscription_id: number,
  reqData: any
) => {
  const { data } = await api.post(`subscription/${subscription_id}/charge`, {
    ...reqData,
  });
  return data;
};

export const getPaddlePlans = async () => {
  const { data } = await api.post(ApiEndpoints.PADDLE_PLANS, {
    plan: "",
  });
  return data;
};

export const getUserVidyoJourney = async () => {
  const { data } = await api.get(ApiEndpoints.USER_JOURNEY);

  return data;
};

export const completeUserFileUpload = async (reqData: any) => {
  const data = await api.post(ApiEndpoints.MULTIPART_COMPLETE, {
    ...reqData,
  });

  return data.data as {
    remote_url: string;
    project_id: string;
  };
};

export const getSocialMediaStatus = async (user_id: string) => {
  const data = await api.get(
    `${ApiEndpoints.SOCIAL_MEDIA}/${user_id}-status-type`
  );
  return data;
};

export const getQueryCreatorInfo = async (user_id: string) => {
  const data = await api.post(
    `${ApiEndpoints.SOCIAL_MEDIA}${ApiEndpoints.QUERY_CREATOR_INFO}`,
    {
      user_id,
    }
  );
  return data;
};

export const postSocialMediaVideos = async (payload: UploadPostPayloadType) => {
  const data = await api.post(
    `${ApiEndpoints.SOCIAL_MEDIA}${ApiEndpoints.SOCIAL_MEDIA_POST_VIDEOS}`,
    payload
  );
  return data;
};

export const createSocialMediaAccount = async (
  payload: CreateSocialMediaPayload
) => {
  const data = await api.post(
    `${ApiEndpoints.SOCIAL_MEDIA}${ApiEndpoints.CREATE_SOCIAL_MEDIA_ACCOUNT}`,
    payload
  );
  return data;
};

export const createPaidUserOauthDetails = async (user_id: string) => {
  const data = await api.get(
    `${ApiEndpoints.SOCIAL_MEDIA}${ApiEndpoints.CREATE_PAID_USER_OAUTH(
      user_id
    )}`
  );
  return data;
};

export const getScheduledPostsList = async (params: {
  user_id: string;
  status: string;
  recent_posts: number;
}) => {
  const { user_id, status, recent_posts } = params;
  const data = await api.get(
    `${ApiEndpoints.SOCIAL_MEDIA}${ApiEndpoints.SCHEDULED_POSTS_LIST(user_id)}`,
    {
      params: {
        status,
        recent_posts,
      },
    }
  );
  return data;
};

export const updateScheduledPost = async (
  payload: UpdateScheduledPostPayload
) => {
  const data = await api.put(
    `${ApiEndpoints.SOCIAL_MEDIA}${ApiEndpoints.UPDATE_SCHEDULED_POST}`,
    payload
  );
  return data;
};

export const deleteScheduledPost = async (payload: {
  user_id: string;
  video_id: string;
}) => {
  const data = await api.delete(
    `${ApiEndpoints.SOCIAL_MEDIA}${ApiEndpoints.DELETE_SCHEDULED_POST}`,
    {
      data: payload,
    }
  );
  return data;
};

export const revokeSocialMediaAccess = async (
  payload: RevokeSocialMediaAccessPayload
) => {
  const data = await api.post(
    `${ApiEndpoints.SOCIAL_MEDIA}${ApiEndpoints.REVOKE_SOCIAL_MEDIA_ACCESS}`,
    payload
  );
  return data;
};

export const trackAmplitudeEvents = async (
  trackObject: AmplitudeTrackObject
) => {
  try {
    const token = await auth.currentUser?.getIdToken(false);
    axios.post(ApiEndpoints.AMPLITUDE_CLOUD_FUNCTION, trackObject, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return;
  } catch (error: any) {
    console.debug("amplitude cloud function error ", error);
  }
};
