import { useCallback, useEffect, useState } from "react";
import { useCopyToClipboard } from "react-use";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "flowbite-react";
import parseSRT from "parse-srt";

import { updateShowPreviewClipsSideModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { REVIEW_CLIPS_INTERACTION } from "@/constants/amplitude";

import { trackReviewClipsInteractionEvents } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

export const TranscriptsTab = ({
  setActivePreviewTab,
}: {
  setActivePreviewTab: (value: string | null) => void;
}) => {
  const dispatch = useAppDispatch();
  const [state, copyToClipboard] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const getTranscript = useCallback(() => {
    const subsArr = parseSRT(currentSelectedMicroContent?.srt_string);

    if (subsArr.length === 0) {
      return null;
    }

    // concat subs arr to text transcript
    const textTranscript = subsArr.map((sub: any) => sub.text).join(" ");
    return textTranscript;
  }, [currentSelectedMicroContent?.srt_string]);

  useEffect(() => {
    if (state.error) {
      showNotification("Could not copy transcript", notificationType.FAIL);
    }
    if (state.value) {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [state]);

  const transcript = getTranscript();

  return (
    <div
      className="absolute h-full w-[25%] bg-[#F3F4F6] top-0 right-0 overflow-auto z-10 shadow-md-black"
      style={{ boxShadow: "-5px 0 10px -10px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="flex px-5 pt-6 pb-4 relative">
        <div>
          <p className="text-xl font-semibold">Transcript</p>
        </div>
        <div className="absolute right-9 top-7">
          <XMarkIcon
            onClick={() => {
              dispatch(updateShowPreviewClipsSideModal(false));
              setActivePreviewTab(null);
            }}
            className="cursor-pointer"
            height={22}
            width={22}
          />
        </div>
      </div>

      <div className="w-full bg-[#F3F4F6] px-5 mt-5">
        <p className="text-left font-md">
          {currentSelectedMicroContent?.gist
            ? currentSelectedMicroContent.gist
            : "Untitled clip"}
        </p>
      </div>

      <div className="w-full bg-[#F3F4F6] px-5 py-9 ">
        <div className="text-left text-sm font-normal text-[#6F6C6C]">
          {transcript ? (
            transcript
          ) : (
            <span className="">No transcript generated for this clip</span>
          )}
        </div>
        {transcript ? (
          <div className="mt-8">
            <Button
              onClick={() => {
                copyToClipboard(transcript);
                trackReviewClipsInteractionEvents(
                  REVIEW_CLIPS_INTERACTION.TRANSCRIPT_COPIED
                );
              }}
              size="sm"
              outline
            >
              {isCopied ? "Copied" : "Copy"}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
