import * as React from "react";

import { PlayPauseIcon, PauseIcon } from "@heroicons/react/20/solid";
import { PlayIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { Tooltip } from "flowbite-react";

import { ForwardFrameIcon } from "@/components/Icons/ForwardFrameIcon";
import { ForwardIcon } from "@/components/Icons/ForwardIcon";
import { RewindIcon } from "@/components/Icons/RewindIcon";

import styles from "@/views/editor/components/TimelineEditor/TimelineEditorStyles.module.scss";
import { PlaybackButtonsActionType } from "@/views/editor/types/types";

// INFO: Just a reusable button for Editor timeline

interface EditorActionButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  iconId: PlaybackButtonsActionType;
  disabled?: boolean;
  message?: string;
  isOutstandingButton?: boolean;
}

export const EditorActionButton = ({
  onClick,
  message,
  disabled,
  iconId,
  isOutstandingButton,
}: EditorActionButtonProps): JSX.Element => {
  const color = "#9ca3af";
  const size = "15";
  const iconsMap: Record<PlaybackButtonsActionType, JSX.Element> = {
    backward: (
      <RewindIcon
        height={"20"}
        width={"20"}
        className="no-transform"
        fillColor={color}
      />
    ),
    backwardByOneFrame: (
      <ForwardFrameIcon
        height={size}
        width={size}
        className={clsx(styles.rotateButton)}
        fillColor={color}
      />
    ),
    play: (
      <PlayIcon
        height="15"
        width="15"
        className="translate-x-px"
        color="#fff"
      />
    ),
    pause: (
      <PauseIcon
        height="15"
        width="15"
        color="#fff"
      />
    ),
    forwardByOneFrame: (
      <ForwardFrameIcon
        height={size}
        width={size}
        className="no-transform"
        fillColor={color}
      />
    ),
    forward: (
      <ForwardIcon
        height={"20"}
        width={"20"}
        className="no-transform"
        fillColor={color}
      />
    ),
  };

  const classNameBasic = clsx(
    "cursor-pointer secondary-controls p-2 rounded-full hover:bg-gray-100 place-content-center"
  );

  const classNameOutstanding = clsx(
    "cursor-pointer place-content-center bg-slate-900 hover:bg-slate-900 p-2 rounded-full"
  );

  const button = (
    <button
      className={isOutstandingButton ? classNameOutstanding : classNameBasic}
      onClick={onClick}
      disabled={disabled}
      id={iconId}
    >
      {iconsMap[iconId]}
    </button>
  );

  return isOutstandingButton ? (
    button
  ) : (
    <Tooltip
      placeholder="top"
      content={message}
    >
      {button}
    </Tooltip>
  );
};
