import { useDropzone } from "react-dropzone";
import { useOutletContext } from "react-router-dom";
import { useToggle } from "react-use";

import { VideoCameraIcon } from "@heroicons/react/24/outline";
import { Badge } from "flowbite-react";

import { updateOutroLengthInSecond } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { uploadFileToS3 } from "@/api/requests";
import useGetAssets from "@/api/useGetAssets";
import useUploadAsset from "@/api/useUploadAsset";

import {
  ANALYTICS_CONSTANTS,
  EDITOR_INTERACTION_DATA,
} from "@/constants/amplitude";
import { MediaIconBrandKit, MediaIconStock } from "@/constants/brand-kit";

import { eventsDataToRedux } from "@/utils/amplitudeAnalytcs";

import { AppliedCardVariant, AssetTags, SimpleAssetType } from "@/enums";

import AccordionLayout from "@/components/Accordion/AccordionLayout";

import { useEditorHistory } from "@/views/editor/EditorHistoryContext";
import { HistoryActionTypes } from "@/views/editor/EditorHistoryReducer";
import { updateOutroCommand } from "@/views/editor/commands/outroCommand";
import {
  AppliedAssetCard,
  AppliedAssetCardList,
} from "@/views/editor/components/AppliedAssetCard";
import AssetList from "@/views/editor/components/Assets/AssetList";
import { UploadAssetButton } from "@/views/editor/components/UploadAssetButton";
import {
  DEFAULT_OUTRO_LENGTH_IN_SEC,
  VIDEO_OUTRO,
} from "@/views/editor/constant";

const IntroOutroEditor = () => {
  const { editorDispatch } = useEditorHistory();

  const [isUploadLoading, toggleUploadLoading] = useToggle(false);
  const { handelChangeOutro, currentOutro } = useOutletContext<any>();

  const selectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );
  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const { mutate: finalizeUpload, isLoading: finalizeUploadLoading } =
    useUploadAsset(AssetTags.OUTRO);

  const { data, isLoading } = useGetAssets({
    assetTag: AssetTags.OUTRO,
  });

  const dispatch = useAppDispatch();

  const handleOutroChange = (item: any) => {
    const outroData = {
      url: item.data ? JSON.parse(item.data)?.remote_url : item.url,
      type: item.assetType || item.type,
      duration:
        (item.data && JSON.parse(item.data)?.duration) ||
        DEFAULT_OUTRO_LENGTH_IN_SEC,
    };

    const metaData = {
      newOutroData: outroData,
      oldOutroData: currentOutro,
    };
    const command = updateOutroCommand(metaData, handelChangeOutro);

    command.execute();

    eventsDataToRedux(ANALYTICS_CONSTANTS.OUTRO_TYPE, outroData.type, item.uid);

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const onDrop = async (acceptedFiles: any) => {
    const file = acceptedFiles?.[0];

    if (!file) {
      return;
    }

    try {
      toggleUploadLoading(true);

      if (file.type?.includes(SimpleAssetType.VIDEO)) {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        const video = document.createElement(SimpleAssetType.VIDEO);
        video.src = file.preview;
        video.preload = "metadata";
        video.muted = true;
        video.autoplay = false;
        video.playsInline = true;

        video.addEventListener("loadedmetadata", async () => {
          const res = await uploadFileToS3(file);
          res?.s3Url &&
            finalizeUpload({
              assetType: file.type,
              assetId: res?.assetId,
              ownerId: uid,
              parentId: null,
              assetTag: AssetTags.OUTRO,
              data: {
                assetName: file.name,
                remoteUrl: res?.s3Url,
                duration: Math.round(video.duration) || 0,
              },
            });
          toggleUploadLoading(false);
          removeAll();
          res?.s3Url &&
            handleOutroChange({
              url: res?.public_url || res?.s3Url,
              type: file.type,
              duration: Math.round(video.duration) || 0,
              id: res?.assetId,
            });
        });
        eventsDataToRedux(ANALYTICS_CONSTANTS.UPLOADS.OUTRO_VIDEO_UPLOAD);
      } else {
        const res = await uploadFileToS3(file);
        res?.s3Url &&
          finalizeUpload({
            assetType: file.type,
            assetId: res?.assetId,
            ownerId: uid,
            parentId: null,
            assetTag: AssetTags.OUTRO,
            data: {
              assetName: file.name,
              remoteUrl: res?.s3Url,
            },
          });
        toggleUploadLoading(false);
        removeAll();
        res?.s3Url &&
          handleOutroChange({
            url: res?.public_url || res?.s3Url,
            type: file.type,
            duration: DEFAULT_OUTRO_LENGTH_IN_SEC,
            id: res?.assetId,
          });
        eventsDataToRedux(ANALYTICS_CONSTANTS.UPLOADS.OUTRO_IMAGE_UPLOAD);
      }
    } catch (e: any) {
      console.log("Error while uploading outro", e);
      toggleUploadLoading(false);
      removeAll();
      return;
    }
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef }: any =
    useDropzone({
      onDrop,
      accept: {
        "image/jpeg": [".jpeg", ".png"],
        "video/mp4": [".mp4"],
      },
      maxFiles: 1,
    });

  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef.current) inputRef.current.value = "";
  };

  // NOTE:
  // 1. The outro length is in second

  const handleUpdateOutroLength = (value: number, currentOutro: any) => {
    const metaData = {
      newOutroData: {
        ...currentOutro,
        duration: value,
      },
      oldOutroData: currentOutro,
    };
    const command = updateOutroCommand(metaData, (outroData: any) => {
      dispatch(updateOutroLengthInSecond(outroData?.duration));
      handelChangeOutro(outroData);
    });

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handleOutroDelete = () => {
    const metaData = {
      newOutroData: null,
      oldOutroData: currentOutro,
    };
    const command = updateOutroCommand(metaData, handelChangeOutro);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  return (
    <>
      <div className="flex flex-col gap-5 p-5 border-[#EDC200] bg-[#FEF6DF] border rounded-md text-[#725C00] ">
        <div className="flex gap-5 items-center">
          <VideoCameraIcon className="h-8" />
          <div>
            <div className="text-sm font-semibold">
              Review dimensions of video or image
            </div>
            <div className="text-xs">
              For best results, upload a video or image that is same aspect
              ratio of your current clip
            </div>
          </div>
        </div>
      </div>
      <div
        className="my-4 mb-6 flex w-full items-center justify-center"
        {...getRootProps()}
        onClick={(e) => e.stopPropagation()}
      >
        <UploadAssetButton
          label="Upload Outro Image or Video"
          id="dropzone-file-outro-editor"
          isLoading={isUploadLoading || finalizeUploadLoading}
          getInputProps={getInputProps}
          isDisabled={isUploadLoading || finalizeUploadLoading}
        />
      </div>

      <AppliedAssetCardList>
        <AppliedAssetCard
          cardType={AppliedCardVariant.OUTRO}
          assetUrl={currentOutro?.url}
          onDelete={handleOutroDelete}
          isApplied={currentOutro?.url ? true : false}
          primaryLabel="Selected outro"
          assetType={currentOutro?.type}
        >
          <div className="flex items-center space-x-3">
            <p className="text-gray-400 dark:text-gray-300 text-xs">
              Outro length (in sec)
            </p>
            {currentOutro?.type?.includes(SimpleAssetType.VIDEO) ? (
              <Badge>{currentOutro?.duration}</Badge>
            ) : (
              <input
                value={currentOutro?.duration}
                onChange={(event) =>
                  handleUpdateOutroLength(~~event.target.value, currentOutro)
                }
                className={`block h-9 w-[64px] rounded-lg 
                    border border-gray-200 bg-gray-100 px-2 py-1.5 
                    text-sm font-normal text-gray-600 focus:border-blue-500 focus:ring-blue-500 ${
                      currentOutro?.type?.includes(SimpleAssetType.VIDEO) &&
                      "cursor-not-allowed caret-transparent opacity-50 selection:bg-transparent focus:border-gray-300 focus:ring-opacity-0"
                    }`}
                type="number"
                disabled={currentOutro?.type?.includes(SimpleAssetType.VIDEO)}
                min={1}
              />
            )}
          </div>
        </AppliedAssetCard>
      </AppliedAssetCardList>

      <div className="mt-[70px]">
        <AccordionLayout
          title="Brand Kit Outros"
          Icon={MediaIconBrandKit}
          eventData={{
            key: EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.EVENT_KEY,
            value:
              EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.BRAND_KIT_OUTRO_CLICKED,
          }}
        >
          <AssetList
            items={data}
            onClick={handleOutroChange}
            isLoading={isLoading}
            assetType={`Brandkit-${AssetTags.OUTRO}`}
          />
        </AccordionLayout>
        <AccordionLayout
          title="Stock Outros"
          Icon={MediaIconStock}
          eventData={{
            key: EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.EVENT_KEY,
            value: EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.STOCK_OUTRO_CLICKED,
          }}
        >
          <AssetList
            items={VIDEO_OUTRO[selectedLayout]}
            onClick={handleOutroChange}
            isStockAsset={true}
            assetType={`Stock-${AssetTags.OUTRO}`}
          />
        </AccordionLayout>
      </div>
    </>
  );
};

export default IntroOutroEditor;
