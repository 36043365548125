import { useState, useRef } from "react";
import { useToggle, useClickAway } from "react-use";

import { convertHexToRGBA, getAlphaAsPercentage } from "@/helpers";

import ColorPicker from "@/components/ColorPicker";
import ReactRangeSlider from "@/components/ReactRangeSlider";

const DropDownColorPicker = ({
  color,
  onChange,
  position = "bottom",
  height = "h-8",
  width = "w-8",
  right = "right-0",
  showTransparency = true,
  clickHandler,
}: any) => {
  const [showColorPicker, toggleColorPicker] = useToggle(false);
  const [opacity, setOpacity] = useState(getAlphaAsPercentage(color));
  const closeColorPickerRef = useRef(null);

  useClickAway(closeColorPickerRef, (e: any) => {
    const colorUploadModal = document.getElementById("closeModal");
    if (!colorUploadModal) {
      toggleColorPicker(false);
    }
  });

  const handleOpacityChange = (opacity: number, addToCommandStack = true) => {
    setOpacity(opacity);
    onChange(convertHexToRGBA(color, opacity), addToCommandStack);
  };

  return (
    <div
      className="relative select-none rounded border border-gray-300 bg-gray-100 p-1 cursor-pointer"
      onClick={(e) => {
        clickHandler?.();
        e.stopPropagation();
        toggleColorPicker();
      }}
      ref={closeColorPickerRef}
    >
      <div
        className={`${height} ${width} rounded bg-gray-100`}
        style={{ backgroundColor: color }}
      />
      {showColorPicker && (
        <div
          className={`absolute ${right} ${
            position === "top" ? "bottom-12" : ""
          } z-[100] mt-3 w-[22rem] cursor-pointer select-none rounded-lg bg-white p-2`}
          style={{
            boxShadow:
              "0px 10px 15px rgba(31, 41, 55, 0.1), 0px 0px 6px rgba(31, 41, 55, 0.05)",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {showTransparency && (
            <div className="flex flex-col bg-white pb-2 mb-2 border-b">
              <div className="flex justify-between items-center">
                <div className="text-gray-800 text-sm font-medium">
                  Transparency
                </div>
                <div className="text-gray-800 text-sm font-medium">
                  {opacity}
                </div>
              </div>
              <ReactRangeSlider
                sliderClassName="w-full flex items-center mt-3 mb-4"
                value={opacity}
                min={1}
                max={100}
                onAfterChange={(e: any) => {
                  handleOpacityChange(e);
                }}
                onChange={(e: any) => {
                  handleOpacityChange(e, false);
                }}
                thumbClassName="mt-0"
              />
            </div>
          )}
          <ColorPicker
            colorInHex={color}
            handleChangeColor={(color: any) => {
              onChange(convertHexToRGBA(color.hex, opacity));
            }}
          />
        </div>
      )}
    </div>
  );
};
export default DropDownColorPicker;
