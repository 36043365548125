export enum TextAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum FontWeight {
  NORMAL = "normal",
  BOLD = "bold",
}

export enum FontStyle {
  NORMAL = "normal",
  ITALIC = "italic",
}

export enum TextElementType {
  SUBTITLE = "subtitle",
  NORMAL_TEXT = "normal_text",
  SOCIAL_HANDLE_TEXT = "social_handle_text",
  SUBTITLE_EMOJI = "subtitle_emoji",
}

export enum TextEffect {
  NONE = "none",
  OUTLINE = "outline",
  SHADOW = "shadow",
  BACKGROUND = "background",
}

export enum VideoLayout {
  LAYOUT_16_9 = "16:9",
  LAYOUT_9_16_1 = "9:16:1",
  LAYOUT_9_16_2 = "9:16:2",
  LAYOUT_1_1 = "1:1",
}

export enum ClipType {
  PORTRAIT = "PORTRAIT",
  SQUARE = "SQUARE",
  LANDSCAPE = "LANDSCAPE",
}
export enum DownloadedClipType {
  PORTRAIT = "portrait",
  SQUARE = "square",
  LANDSCAPE = "landscape",
  BULK = "bulk",
}

export enum RouterPath {
  HOME = "/home",
  DOWNLOADS = "/downloads",
  MANAGE_SUBSCRIPTION = "/manage-subscription",
  MY_TEMPLATES = "/my-templates",
  ON_BOARDING = "/onboarding",
  BRAND_KIT = "/brand-kit",
  SCHEDULED_POSTS = "/scheduled-posts",
}

export enum SocialMediaId {
  FACEBOOK = "social_fb",
  INSTAGRAM = "social_insta",
  TWITTER = "social_twitter",
  YOUTUBE = "social_yt",
  LINKEDIN = "social_linkedin",
  TIKTOK = "social_tiktok",
  WHYZZER = "social_whyzzer",
  SNAPCHAT = "social_snapchat",
  TWITCH = "social_twitch",
  PINTEREST = "social_pinterest",
}

export enum OnBoardingStep {
  UPLOAD_FILE = 0,
  SELECT_LAYOUTS = 1,
  SELECT_TEMPLATES = 2,
  PERSONALIZED_VIDEO = 3,
  COMPLETE_ONBOARDING = 4,
}

export enum ProjectStatus {
  OPEN = "open",
  READY = "complete",
  ERROR = "error",
  ARCHIVED = "archived",
  DELETED = "deleted",
}

export enum ExportQuality {
  HD = "HD",
  FHD = "FHD",
  UHD = "UHD",
}

// 180000 milliseconds = 3 minutes
// 60000 milliseconds = 1 minute

export enum VideoDurationInMilliseconds {
  TINY = 60000,
  SHORT = 180000,
}

export enum TemplateVariant {
  SMALL = "small",
  MY_TEMPLATE_PAGE = "my-template-page",
  DRAFT = "draft",
  BULK_EXPORT = "bulk-export",
}

export enum TemplateTabVariant {
  DEFAULT_TEMPLATE = "default-template",
  USER_TEMPLATE = "user-template",
}

export enum ImageType {
  USER_UPLOADED = "user-uploaded-image",
}

export enum FirebaseCollection {
  BASE_TEMPLATES = "baseTemplate",
  USER_TEMPLATES = "userTemplate",
  USER_ONBOARDING = "onboarding",
  SUBSCRIPTION = "subscriptions",
  USER_PREFERENCE = "userPreference",
  DRAFTS = "drafts",
  EDITOR_DRAFTS = "editorDrafts",
  RENDER_DRAFTS = "renderDrafts",
}

export enum OnboardingReferer {
  FRIENDS = "Friend / Colleague",
  GOOGLE = "Google",
  YOUTUBE = "Youtube",
  TWITTER = "Twitter",
  TIKTOK = "Tiktok",
  OTHERS = "Others",
}

export enum OnboardingUserType {
  SOLO_CREATOR = "solo-creator",
  TEAM = "team",
}

export enum UserOnboardingSteps {
  OCCUPATION = 0,
  USER_TYPE = 1,
  USER_WANTS = 2,
  REFERER = 3,
}

export enum PlanType {
  FREE = 1,
  PRO = 2,
  PRO_PLUS = 3,
  STARTER = 4,
  UNLIMITED = 5,
  FULL_SERVICE = 6,
}

export enum SubscriptionIntervalInMonths {
  MONTHLY = 1,
  YEARLY = 12,
}

export enum Country {
  US = "US",
  INDIA = "IN",
}

export enum SocialMediaTypes {
  TIKTOK = "tiktok",
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  TWITTER = "twitter",
  LINKEDIN = "linkedin",
  YOUTUBE = "youtube",
}

export enum PlanTypeLabels {
  FREE = "FREE",
  PAID = "PAID",
}

export enum BrandKitMenu {
  LOGO = "Logo",
  COLOR_PALETTE = "Colors Palette",
  FONT = "Font",
  BACKGROUND = "Video Background",
  OUTRO = "Outro",
  IMAGE = "Image",
  VIDEO = "Video",
  AUDIO = "Audio",
}

export enum AppliedCardVariant {
  TEMPLATE = "template",
  MEDIA = "media",
  BACKGROUND = "background",
  OUTRO = "outro",
  B_ROLL = "b-roll",
  SOCIAL = "social",
  AUDIO = "audio",
}

export enum AssetTags {
  LOGO = "logo",
  PICTURE = "picture",
  STICKER = "sticker",
  INTRO = "intro",
  OUTRO = "outro",
  UPLOAD = "upload",
  B_ROLL = "b_roll",
  GIF = "gif",
  BG_MUSIC = "bg_music",
  VOICE_OVER = "voiceover",
  SOUND_EFFECT = "sound_effect",
  HEADING = "heading",
  SUB_HEADING = "subheading",
  BODY = "body",
  BACKGROUND = "background",
  FONT = "font",
  AUDIO = "audio",
}

export enum SubtitleStyle {
  DEFAULT = "default",
  ONE_WORD = "one_word",
  TWO_WORD = "two_word",
  WORD_COLOR_CHANGE = "word_color_change",
  WORD_BACKGROUND_CHANGE = "word_background_change",
  WORD_APPENDED = "word_appended",
  RANDOM_WORD_COLOR_CHANGE = "random_word_color_change",
}

export enum SimpleAssetType {
  VIDEO = "video",
  IMAGE = "image",
  AUDIO = "audio",
}

export enum SocialName {
  FACEBOOK = "Facebook",
  INSTAGRAM = "Instagram",
  TWITTER = "Twitter",
  YOUTUBE = "Youtube",
  LINKEDIN = "Linkedin",
  TIKTOK = "Tiktok",
  YOUTUBE_SHORTS = "Youtube Shorts",
}

export const getKeyByValue = (enumObj: any, value: any) => {
  const indexOfS = Object.values(enumObj).indexOf(value);
  const key = Object.keys(enumObj)[indexOfS];

  return key;
};

export enum KeyCode {
  LEFT_ARROW = 37,
  UP_ARROW = 38,
  RIGHT_ARROW = 39,
  DOWN_ARROW = 40,
}

export enum WorkspaceMenu {
  ALL_VIDEOS = "All Videos",
  DRAFTS = "Drafts",
}

export enum ScheduledPostsMenu {
  SCHEDULED = "Scheduled",
  COMPLETED = "Completed",
}

export enum FabricElements {
  BACKGROUND = "background",
  VIDEO_CLIP_PATH_TOP = "video-clip-path-top",
  VIDEO_CLIP_PATH_BOTTOM = "video-clip-path-bottom",
  OUTRO_CLIP_PATH = "outro-clip-path",
  INTRO_CLIP_PATH = "intro-clip-path",
  NORMAL_TEXT = "normal-text",
  SUBTITLE = "subtitle",
  SOCIAL_HANDLE_TEXT = "social-handle-text",
}

export enum ScreenName {
  HOME = "home",
  EDITOR = "editor",
  DOWNLOADS = "downloads",
  REVIEW_CLIPS = "review_clips",
  TEMPLATES = "templates",
  DRAFTS = "drafts",
  BRAND_KIT = "brand_kit",
}

export enum PreviewScreenClipTypes {
  CHAPTERS = "chapters",
  SHORTS = "preview_data",
  FULL_VIDEO = "full_video",
}

export enum STATUS {
  SUCCESS = "success",
  ERROR = "error",
  LOADING = "loading",
  IDLE = "idle",
}

export enum AudioCardType {
  BRAND_KIT = "brand-kit",
  MEDIA_SECTION_CARD = "media-section-card",
  FULL_WIDTH_CARD = "full-width-card",
}

export enum EditorMediaTab {
  VIDEO = "Videos",
  IMAGE = "Images",
  AUDIO = "Audios",
}

export enum EditorMediaStatus {
  LOADING = "loading",
  READY = "ready",
  ERROR = "error",
  IDLE = "idle",
  NOT_ADDED = "not_added",
}

export enum EditorMediaTabMode {
  SEARCH = "search",
  BROWSE = "browse",
}

export enum ABTest {
  TIMELINE_ZOOM = "timeline-zoom",
  EDITOR_UNDO_REDO = "editor-undo-redo",
}
