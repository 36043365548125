import { MusicalNoteIcon } from "@heroicons/react/20/solid";

interface ExternalAudioIndicatorProps {
  handleClick: () => void;
}
export const ExternalAudioIndicator = ({
  handleClick,
}: ExternalAudioIndicatorProps): JSX.Element => {
  return (
    <button
      className="rounded cursor-pointer"
      onClick={handleClick}
    >
      <MusicalNoteIcon className="h-6 w-6 text-slate-800" />
    </button>
  );
};
