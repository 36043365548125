export const EditorCommandType = {
  // Timeline Management
  TrimClip: "TrimClip",
  SplitClip: "SplitClip",
  MergeClips: "MergeClips",

  // Text Commands
  AddText: "AddText",
  EditText: "EditText",
  DeleteText: "DeleteText",
  MoveText: "MoveText",
  ResizeText: "ResizeText",
  ChangeTextStyle: "ChangeTextStyle",
  ChangeTextAlignment: "ChangeTextAlignment",

  // Subtitle Commands
  DeleteSubtitle: "DeleteSubtitle",
  UpdateSubsStyles: "UpdateSubsStyles",

  //Background Commands
  UpdateBackgroundColor: "UpdateBackgroundColor",
  UpdateBackgroundImage: "UpdateBackgroundImage",

  // ProgressBar Commands
  ToggleProgressbar: "ToggleProgressbar",
  ChangeProgressbarColor: "ChangeProgressbarColor",
  ChangeProgressbarHeight: "ChangeProgressbarHeight",
  ChangeProgressbarPosition: "ChangeProgressbarPosition",

  //Image Commands
  AddImage: "AddImage",
  RemoveImage: "RemoveImage",
  MoveImageElement: "MoveImageElement",
  ResizeImageElement: "ResizeImageElement",
  RotateImageElement: "RotateImageElement",
  ChangeImageStartTime: "ChangeImageStartTime",
  ChangeImageEndTime: "ChangeImageEndTime",

  // Video Commands
  AddVideoClip: "AddVideoClip",
  RemoveVideoClip: "RemoveVideoClip",
  ChangeVideoClipStartTime: "ChangeVideoClipStartTime",
  ChangeVideoClipEndTime: "ChangeVideoClipEndTime",
  MoveVideoElement: "MoveVideoElement",
  ResizeVideoElement: "ResizeVideoElement",
  RotateVideoElement: "RotateVideoElement",

  //CutMagic Commands
  UpdateCutMagicScenes: "UpdateCutMagicScenes",
  AddCutMagicScene: "AddCutMagicScene",
  DeleteCutMagicScene: "DeleteCutMagicScene",
  DisableCutMagic: "DisableCutMagic",
  ModifyCutMagicScene: "ModifyCutMagicScene",

  // Custom and Miscellaneous
  CustomCommand: "CustomCommand",
  BatchEditCommands: "BatchEditCommands",
  AdjustVideoElementOnCanvas: "AdjustVideoElementOnCanvas",
} as const;

// Example type for using these command types
export type EditorCommandTypeKeys = keyof typeof EditorCommandType;
