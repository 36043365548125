import { togglePlayPause, updateCurrentVideoTime } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { EDITOR_INTERACTION_DATA } from "@/constants/amplitude";

import { trackEditorInteractionEvent } from "@/utils/amplitudeAnalytcs";

import { SimpleAssetType } from "@/enums";

interface EditorActionsHook {
  backwardVideo: () => void;
  forwardVideo: () => void;
  forwardVideoByOneFrame: () => void;
  backwardVideoByOneFrame: () => void;
  handlePlayPause: () => void;
  handleBRollsVideoPlayPause: (isMainVideoPaused: boolean) => void;
  setCurrentTime: (value: number) => void;
}

interface EditorActionsHookProps {
  videoElRef: React.MutableRefObject<HTMLVideoElement | null>;
  bRollsRef: React.MutableRefObject<any>;
  canvasObjects: React.MutableRefObject<any>;
  outroIntervalRef: React.MutableRefObject<any>;
  outroTimeLeft: number;
  setOutroTimeLeft: (value: React.SetStateAction<number>) => void;
  fabricRef: React.MutableRefObject<fabric.Canvas>;
  isPollingCurrentTime: boolean;
  toggleIsPollingCurrentTime: (value: boolean) => void;
  isMiddleSliderThumbDraggable: boolean;
}

export const useEditorActions = ({
  videoElRef,
  bRollsRef,
  canvasObjects,
  outroTimeLeft,
  fabricRef,
  isPollingCurrentTime,
  toggleIsPollingCurrentTime,
  outroIntervalRef,
  setOutroTimeLeft,
  isMiddleSliderThumbDraggable,
}: EditorActionsHookProps): EditorActionsHook => {
  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );
  const videoFrameLengthInSeconds = useAppSelector(
    (state) => state.editorState.videoFrameLengthInSeconds
  );

  const dispatch = useAppDispatch();

  const handleBRollsVideoPlayPause = (isMainVideoPaused: boolean) => {
    if (!bRollsRef?.current) return;
    Object.values(bRollsRef.current).forEach((asset: any) => {
      const isAssetTypeVideo = asset.assetType.includes(SimpleAssetType.VIDEO);
      const videoEl = asset.videoEl;

      if (isAssetTypeVideo && videoEl) {
        if (isMainVideoPaused) {
          videoEl.play().catch(() => {});
        } else {
          videoEl.pause();
        }
      }
    });
  };

  const handlePlayPause = () => {
    const outroVideoElement = canvasObjects?.current.outroImg?.getElement();
    if (outroTimeLeft === 0 && fabricRef?.current && videoElRef?.current) {
      handleBRollsVideoPlayPause(videoElRef.current.paused);
      if (videoElRef.current.paused) {
        videoElRef.current
          .play()
          .then(() => {
            // play started!
            dispatch(togglePlayPause(true));
            fabricRef.current.discardActiveObject();
          })
          .catch((error: any) => {
            console.debug("[PLAYBACK]: Unable to play/pause - ", error);
          });
      } else {
        toggleIsPollingCurrentTime(false);
        dispatch(togglePlayPause(false));
        videoElRef.current.pause();
      }
    } else if (outroVideoElement?.paused) {
      outroVideoElement.play?.();
      dispatch(togglePlayPause(true));
      outroIntervalRef.current = setInterval(() => {
        setOutroTimeLeft(outroTimeLeft - 1);
      }, 1000);
    } else {
      outroVideoElement.pause?.();
      dispatch(togglePlayPause(false));
      if (outroIntervalRef.current) {
        clearInterval(outroIntervalRef.current);
      }
    }
  };

  const backwardVideo = () => {
    // TODO: add outro time check when the migration is done for the outro time
    // if (outroTimeLeft === 0 && videoElementRef?.current) {
    if (videoElRef?.current) {
      if (
        videoElRef.current.currentTime - 5 <=
        currentSelectedMicroContent.start / 1000
      ) {
        videoElRef.current.currentTime =
          currentSelectedMicroContent.start / 1000;
      } else {
        if (
          videoElRef.current.currentTime - 5 <=
          currentSelectedMicroContent.start / 1000
        ) {
          videoElRef.current.currentTime =
            currentSelectedMicroContent.start / 1000;
        } else {
          videoElRef.current.currentTime = videoElRef.current.currentTime - 5;
          trackEditorInteractionEvent(
            EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
            EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION
              .SKIP_5_BACK_FRAME_CLICKED
          );
        }
      }
    }
  };
  const forwardVideo = () => {
    // if (outroTimeLeft === 0) {
    if (videoElRef?.current) {
      videoElRef.current.currentTime = videoElRef.current.currentTime + 5;
      trackEditorInteractionEvent(
        EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
        EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.SKIP_5_FWD_FRAME_CLICKED
      );
    }
  };

  const backwardVideoByOneFrame = () => {
    // TODO: add outro time check
    // if (outroTimeLeft === 0 && videoElementRef?.current) {
    if (videoElRef?.current) {
      if (
        videoElRef.current.currentTime - videoFrameLengthInSeconds <=
        currentSelectedMicroContent.start / 1000
      ) {
        videoElRef.current.currentTime =
          currentSelectedMicroContent.start / 1000;
      } else {
        if (
          videoElRef.current.currentTime - videoFrameLengthInSeconds <=
          currentSelectedMicroContent.start / 1000
        ) {
          videoElRef.current.currentTime =
            currentSelectedMicroContent.start / 1000;
        } else {
          videoElRef.current.currentTime =
            videoElRef.current.currentTime - videoFrameLengthInSeconds;
          trackEditorInteractionEvent(
            EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
            EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION
              .SKIP_1_BACK_FRAME_CLICKED
          );
        }
      }
    }
  };

  const forwardVideoByOneFrame = () => {
    // TODO: add outro time check
    if (videoElRef?.current) {
      videoElRef.current.currentTime =
        videoElRef.current.currentTime + videoFrameLengthInSeconds;
      trackEditorInteractionEvent(
        EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
        EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.SKIP_1_FWD_FRAME_CLICKED
      );
    }
  };

  const setCurrentTime = (value: number) => {
    if (!videoElRef.current) {
      return;
    }
    if (outroTimeLeft !== 0) {
      setOutroTimeLeft(0);
      if (outroIntervalRef.current) {
        clearInterval(outroIntervalRef.current);
      }
      const outroVideoEl = canvasObjects.current.outroImg.getElement();
      if (!outroVideoEl.paused) {
        outroVideoEl.pause();
      }
      outroVideoEl.currentTime = 0;
    }
    // check if the value is b/w the start and end.
    if (
      value > currentSelectedMicroContent.start &&
      value < currentSelectedMicroContent.end
    ) {
      dispatch(updateCurrentVideoTime(value / 1000));
      videoElRef.current.currentTime = value / 1000;
      // if the value is less than the start then set as start time
    } else if (
      value <= currentSelectedMicroContent.start &&
      isMiddleSliderThumbDraggable
    ) {
      dispatch(
        updateCurrentVideoTime(currentSelectedMicroContent.start / 1000)
      );
      videoElRef.current.currentTime = currentSelectedMicroContent.start / 1000;
    } else if (
      value >= currentSelectedMicroContent.end &&
      isMiddleSliderThumbDraggable
      // if the value is more than the end then set as end time
    ) {
      dispatch(updateCurrentVideoTime(currentSelectedMicroContent.end / 1000));
      videoElRef.current.currentTime = currentSelectedMicroContent.end / 1000;
    }
  };

  return {
    backwardVideo,
    forwardVideo,
    forwardVideoByOneFrame,
    backwardVideoByOneFrame,
    handleBRollsVideoPlayPause,
    handlePlayPause,
    setCurrentTime,
  };
};
