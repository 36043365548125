import { useEffect, useState } from "react";
import { useCopyToClipboard } from "react-use";

import { XMarkIcon } from "@heroicons/react/24/solid";
import { Button, Card, Spinner } from "flowbite-react";
import parseSRT from "parse-srt";

import { setEditorAnalytics } from "@/store/amplitudeSlice";
import { updateCaption } from "@/store/editorSlice";
import {
  toggleUpgradeToProModal,
  updateShowPreviewClipsSideModal,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { getCaptionForTranscript } from "@/api/requests";

import {
  ANALYTICS_CONSTANTS,
  EDITOR_INTERACTION_DATA,
} from "@/constants/amplitude";

import { removeEmojisFromSrtString } from "@/helpers/text";

import {
  eventsDataToRedux,
  trackEditorInteractionEvent,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { PlanType, ScreenName, SocialName } from "@/enums";

import FacebookIcon from "@/assets/icons/social/facebook.svg";
import InstagramIcon from "@/assets/icons/social/instagram.svg";
import LinkedInIcon from "@/assets/icons/social/linkedin.svg";
import TiktokIcon from "@/assets/icons/social/tiktok.svg";
import TwitterIcon from "@/assets/icons/social/twitter.svg";
import YoutubeShortsIcon from "@/assets/icons/social/youtube-shorts.svg";
import YoutubeIcon from "@/assets/icons/social/youtube.svg";
import ProFeatureImg from "@/assets/images/pro-only-template.svg";

const socials = [
  {
    name: SocialName.TIKTOK,
    icon: TiktokIcon,
    maxChars: 150,
  },
  {
    name: SocialName.YOUTUBE_SHORTS,
    icon: YoutubeShortsIcon,
    maxChars: 150,
  },
  {
    name: SocialName.INSTAGRAM,
    icon: InstagramIcon,
    maxChars: 150,
  },
  {
    name: SocialName.FACEBOOK,
    icon: FacebookIcon,
    maxChars: 200,
  },
  {
    name: SocialName.TWITTER,
    icon: TwitterIcon,
    maxChars: 180,
  },
  {
    name: SocialName.LINKEDIN,
    icon: LinkedInIcon,
    maxChars: 1000,
  },
  {
    name: SocialName.YOUTUBE,
    icon: YoutubeIcon,
    maxChars: 2000,
  },
];

const CaptionTab = ({
  isPreviewScreenOpen,
  setActivePreviewTab,
}: {
  isPreviewScreenOpen?: boolean;
  setActivePreviewTab?: (value: string | null) => void;
}) => {
  const dispatch = useAppDispatch();
  const [state, copyToClipboard] = useCopyToClipboard();
  const [subsArr, setSubsArr] = useState<any>([]);

  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );
  const editorAnalytics = useAppSelector(
    (state) => state.amplitudeState.editorScreen
  );
  const isPaidUser = planType !== PlanType.FREE;
  const editorSubsArr = useAppSelector(
    (state) => state.editorState.subtitles.subsArr
  );
  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );
  useEffect(() => {
    if (isPreviewScreenOpen && currentSelectedMicroContent?.srt_string) {
      const jsonArr = parseSRT(currentSelectedMicroContent.srt_string);
      setSubsArr(jsonArr);
    } else {
      setSubsArr(editorSubsArr);
    }
  }, [editorSubsArr, currentSelectedMicroContent?.srt_string]);

  const captionObj = useAppSelector((state) => state.editorState.caption);
  const { transcript, text, social } = captionObj;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSocial, setSelectedSocial] = useState(
    social?.name ? social : socials[0]
  );
  const [isCopied, setIsCopied] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  useEffect(() => {
    if (state.error) {
      showNotification("Could not copy caption", notificationType.FAIL);
    }
    if (state.value) {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [state]);

  useEffect(() => {
    // do not disable if existing caption is selected
    if (social?.name && social.name === selectedSocial.name) {
      setIsButtonDisabled(true);
      return;
    }
    setIsButtonDisabled(false);
  }, [selectedSocial, subsArr]);

  const trackCaptionGenerateEvent = () => {
    switch ({ ...selectedSocial }.name) {
      case SocialName.TIKTOK:
        trackEditorInteractionEvent(
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.EVENT_KEY,
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.TIKTOK
        );
        break;
      case SocialName.YOUTUBE_SHORTS:
        trackEditorInteractionEvent(
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.EVENT_KEY,
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.YT_SHORTS
        );
        break;
      case SocialName.INSTAGRAM:
        trackEditorInteractionEvent(
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.EVENT_KEY,
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.INSTAGRAM
        );
        break;
      case SocialName.FACEBOOK:
        trackEditorInteractionEvent(
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.EVENT_KEY,
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.FACEBOOK
        );
        break;
      case SocialName.TWITTER:
        trackEditorInteractionEvent(
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.EVENT_KEY,
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.FACEBOOK
        );
        break;
      case SocialName.LINKEDIN:
        trackEditorInteractionEvent(
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.EVENT_KEY,
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.LINKEDIN
        );
        break;
      case SocialName.YOUTUBE:
        trackEditorInteractionEvent(
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.EVENT_KEY,
          EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.YOUTUBE
        );
        break;
      default:
        break;
    }
  };

  const generateCaption = async () => {
    try {
      trackCaptionGenerateEvent();
      if (subsArr.length === 0) {
        showNotification(
          "Enable subtitles to generate caption",
          notificationType.FAIL
        );
        return;
      }
      const currentSocial = { ...selectedSocial };
      setIsLoading(true);
      setIsButtonDisabled(true);
      const subsWithoutEmojis = removeEmojisFromSrtString(subsArr);
      // concat subs arr to text transcript
      const textTranscript = subsWithoutEmojis.map((sub) => sub.text).join(" ");
      if (
        textTranscript === transcript &&
        selectedSocial.name === social.name
      ) {
        showNotification(
          "Change platform or clip selection to generate new caption",
          notificationType.INFO
        );
        setIsLoading(false);
        return;
      }

      const captionText: any =
        (await getCaptionForTranscript(
          textTranscript,
          currentSocial.name,
          currentSocial.maxChars
        )) || [];

      if (captionText?.length === 0) {
        showNotification("Could not generate caption", notificationType.FAIL);
        return;
      }

      captionText &&
        dispatch(
          updateCaption({
            transcript: textTranscript,
            text:
              captionText +
              (currentSocial.name === SocialName.YOUTUBE_SHORTS
                ? " #Shorts"
                : ""),
            social: currentSocial,
          })
        );
    } catch (error) {
      console.error(error);
      showNotification("Cannot generate caption", notificationType.FAIL);
    }
    setIsLoading(false);

    eventsDataToRedux(ANALYTICS_CONSTANTS.AI_CAPTIONS, selectedSocial.name);
  };
  return (
    <div
      className={`${
        isPreviewScreenOpen
          ? "absolute h-full w-[25%] bg-[#F3F4F6] top-0 right-0 overflow-auto z-10"
          : "relative flex flex-1 flex-col"
      }`}
      style={{
        boxShadow: isPreviewScreenOpen
          ? "-5px 0 10px -10px rgba(0, 0, 0, 0.5)"
          : "",
      }}
    >
      <div
        className={`flex px-5 pt-6 pb-4 relative  ${
          isPreviewScreenOpen ? "" : "bg-white"
        }`}
      >
        <div className="flex flex-1 flex-col gap-4 text-black">
          <div className="flex justify-between items-center">
            <div className="text-lg">AI Description</div>
          </div>
        </div>
        {isPreviewScreenOpen ? (
          <div className="absolute right-9 top-7">
            <XMarkIcon
              onClick={() => {
                dispatch(updateShowPreviewClipsSideModal(false));
                setActivePreviewTab && setActivePreviewTab(null);
                dispatch(updateCaption({}));
              }}
              className="cursor-pointer"
              height={22}
              width={22}
            />
          </div>
        ) : null}
      </div>
      <div className="p-3 flex flex-col gap-5">
        <Card>
          <div className="flex justify-between mb-4">
            {socials.map((social) => (
              <span
                className={`p-1.5 flex items-center justify-center rounded-lg border-2  ${
                  !isPaidUser
                    ? "border-gray-200 cursor-default"
                    : selectedSocial.name === social.name
                    ? "border-blue-500 hover:border-blue-600 cursor-pointer"
                    : "border-gray-200 hover:border-gray-300 cursor-pointer"
                }`}
                onClick={() => setSelectedSocial(social)}
                key={social.name}
              >
                <img
                  src={social.icon}
                  alt={social.name}
                  className="w-6 h-6"
                />
              </span>
            ))}
          </div>
          <Button
            onClick={generateCaption}
            disabled={!isPaidUser ? true : isLoading || isButtonDisabled}
            id={`${
              isPreviewScreenOpen
                ? "generate-captions-btn-review-clips-screen"
                : "generate-captions-btn-editor-screen"
            }`}
            fullSized
          >
            {isLoading && (
              <Spinner
                size="sm"
                className="mr-2"
              />
            )}
            Generate Description
          </Button>
        </Card>
        {text?.length > 0 && (
          <Card className="text-gray-900 ">
            <div className="flex justify-between items-center">
              <div className="text-lg font-semibold">Description</div>
              <Button
                className="ml-2"
                onClick={() => copyToClipboard(text)}
                size="sm"
                outline
                id={`${
                  isPreviewScreenOpen
                    ? "copy-captions-btn-review-clips-screen"
                    : "copy-captions-btn-editor-screen"
                }`}
              >
                {isCopied ? "Copied" : "Copy"}
              </Button>
            </div>
            <hr />
            <div>{text}</div>
          </Card>
        )}
      </div>

      {!isPaidUser ? (
        <div className="col-span-2 mt-12 px-6 text-center text-sm text-[#5E6473]">
          <img
            src={ProFeatureImg}
            className="mx-auto"
          />
          <p className=" my-4">
            <span className="font-semibold">PRO users</span> can generate video
            title caption & hashtags with AI, specific to platform you want to
            publish on! To use this feature,{" "}
            <span className="font-semibold">upgrade to PRO</span>{" "}
          </p>
          <button
            type="button"
            id={`${
              isPreviewScreenOpen
                ? "upgrade-now-captions-tab-review-clips"
                : "upgrade-now-captions-tab-editor"
            }`}
            className={`my-6 mx-auto flex w-40 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300`}
            onClick={() => {
              dispatch(toggleUpgradeToProModal(true));
              eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
            }}
          >
            Upgrade now
          </button>
        </div>
      ) : null}
    </div>
  );
};
export default CaptionTab;
