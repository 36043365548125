import type React from "react";

import type * as PopperJS from "@popperjs/core";

import {
  AssetTags,
  DownloadedClipType,
  FontStyle,
  FontWeight,
  OnboardingReferer,
  PlanType,
  TextAlignment,
  TextEffect,
  TextElementType,
  VideoLayout,
} from "@/enums";

export interface ClipPathProps {
  id?: string;
  width: number;
  height: number;
  top?: number;
  left?: number;
  backgroundColor?: string | null;
  scaleRatio?: number;
}

interface ShadowStyle {
  color: string;
  offsetX: number;
  offsetY: number;
  blur: number;
}

export interface TextboxStyles {
  fontSize: number;
  fontFamily: string;
  fontWeight: FontWeight;
  fontStyle: FontStyle;
  textAlign: TextAlignment;
  underline?: boolean;
  fill: string;
  effectType: TextEffect;
  effectColor: string;
  lineHeight: number;
  noEffect: boolean;
  width?: number;
  maxHeight?: number;
  shadow: {
    color: string;
    enabled: boolean;
    config: {
      offsetX: number;
      offsetY: number;
      blur: number;
    };
  };
  stroke: {
    color: string;
    enabled: boolean;
    config: {
      width: number;
    };
  };
  textBgColor: {
    color: string;
    enabled: boolean;
    radius: number;
  };
  blockBackground: {
    color: string;
    enabled: boolean;
    radius: number;
    padding: number;
  };
  padding: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  // used in selection styles
  textBackgroundColor?: string;
  textTransform: string;
}

export interface TextboxProps {
  id?: string;
  width: number;
  maxHeight?: number;
  top?: number;
  left?: number;
  text?: string;
  style?: TextboxStyles;
  type?: TextElementType;
  scaleRatio?: number;
  clipPath?: fabric.Rect;
}

export interface FaceCoordinates {
  x: number;
  y: number;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}
export interface VideoProps {
  id?: string;
  top?: number;
  left?: number;
  videoElement: HTMLVideoElement;
  scaleRatio?: number;
  clipPath?: fabric.Rect;
  onModified?: (e: fabric.IEvent) => void;
  selectable?: boolean;
  hasControls?: boolean;
  type?: string;
  assetType?: string;
  assetTag?: any;
}

export interface FontProperties {
  fontFamily: string;
  fontWeight: FontWeight;
  fontStyle: FontStyle;
}

export interface ProgressBarProps {
  id?: string;
  top?: number;
  left?: number;
  maxWidth?: number;
  width?: number;
  height?: number;
  fill: string;
  scaleRatio?: number;
  clipPath?: fabric.Rect;
}

export interface DownloadedClip {
  renderedVideoUrl: string;
  createdAt: string;
  status: string;
  projectId: string;
  filename: string;
  render_task_id: string;
  clip_start: number;
  clip_end: number;
  clip_id: string;
}

export interface Downloads {
  [DownloadedClipType.SQUARE]: Array<DownloadedClip>;
  [DownloadedClipType.PORTRAIT]: Array<DownloadedClip>;
  [DownloadedClipType.LANDSCAPE]: Array<DownloadedClip>;
}

export interface TooltipProps {
  label: React.ReactElement | string;
  children?: any;
  hideTooltip?: boolean;
  placement?: PopperJS.Placement;
  enterDelay?: number;
  leaveDelay?: number;
}

export interface Subscription {
  createdAt: number;
  subscriptionStartedAt: number;
  allowedMinutes: number;
  intervalInMonths: number;
  planType: PlanType;
  cancelOn: Date | null;
  nextBillDate: Date | null;
  isPaddle: boolean;
  subscriptionId: number | null;
}

export interface Project {
  title: string;
  id: string;
  status: string;
  data: string;
  tags: string;
  parentId: string | null;
  userId: string;
  createdAt: string;
  use_stream?: boolean;
  stream_data?: string;
}

export type UserWants =
  | "Make short clips from long form videos"
  | "Edit subtitles"
  | "Cut, crop & resize videos"
  | "Noise removal"
  | "Filler word removal (like um, uh)"
  | "Publish my clips to multiple platforms"
  | "Add music to my videos"
  | "Add animations and effects"
  | "Join or combine multiple videos"
  | "Use templates";

export interface UserOnboarding {
  occupation: string;
  isIndividual: boolean | null;
  user_wants_to: UserWants[];
  referer: OnboardingReferer | string;
}

export interface VideoPosition {
  left: number;
  top: number;
  scaleX: number;
  scaleY: number;
  // needed for draft position
  backGroundClipPathTop?: number;
  backGroundClipPathLeft?: number;
}

export interface Scene {
  id: string;
  color: string;
  position: {
    videoElUp: VideoPosition | null;
    videoElDown: VideoPosition | null;
  };
  isTwoFace: boolean;
  face: FaceCoordinates[];
  start: number;
  end: number;
}

export interface SceneResponse {
  fps: number;
  scenes: Scene[];
  status: string;
}

export interface UsageData {
  usage: number;
}

interface clipPath {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface TemplateText {
  top: number;
  left: number;
  textBackgroundColor?: string;
  fontColor: string;
  fill?: string;
  fontFamily: string;
  textAlign: string;
  fontSize: string;
  text?: string;
  bold: boolean;
  underline: boolean;
  effect_type: string;
  effect_color: string;
  italic: boolean;
  width: number;
  maxHeight: number;
  content?: string;
  lineHeight: number;
}

interface TemplateImage {
  top: number;
  left: number;
  width: number;
  height: number;
  url: string;
  id: string;
}

interface socialHandel {
  text: TemplateText;
  image: TemplateImage;
}

interface VideoProperties {
  clipPath: clipPath;
}

export interface OutroObject {
  type: string;
  url: string;
  length?: number;
  duration?: number;
}
export interface Template {
  // making id optional because it is extended in EditorAppliedTemplate
  // but
  id?: string;
  hasTwoFace: boolean;
  backgroundColor: string | null;
  backgroundImageUrl: string | null;
  height: number;
  width: number;
  isCutMagicEnabled?: boolean;
  isDefault?: boolean;
  isPreferred?: boolean;
  videoProperties: VideoProperties[];
  progressbar: {
    top: number;
    left: number;
    height: number;
    fill: string;
  };
  subtitle: TemplateText;
  texts: TemplateText[];
  socials: socialHandel[];
  intro: {
    type: string;
    url: string;
    length?: number;
  };
  outro: OutroObject;
}

interface ClipsResponse {
  gist: string;
  thumbnailSrc: string;
  chapterStart: number;
  chapterEnd: number;
  text: string;
  end: number;
  start: number;
  srtString: string;
  thumbnailTimeStamp: number;
  faceCoord: FaceCoordinates[];
}

export interface EditorAppliedTemplate extends Template, ClipsResponse {}

interface FinalizeAssetMetaData {
  assetName: string;
  remoteUrl: string;
  duration?: number;
}
export interface FinalizeAsset {
  assetType: string;
  assetId: string;
  ownerId: string;
  parentId: string | null;
  assetTag: AssetTags;
  data: FinalizeAssetMetaData;
}

export interface Asset {
  assetTag: AssetTags;
  assetType: string;
  createdAt: string;
  data: string;
  id: string;
  isDeleted: boolean;
  ownerId: string;
  parentId: string | null;
}

export interface UserPreference {
  baseTemplates: string[];
  colorPallets: string[];
  createdAt: string;
  savePreference: boolean;
  selectedLayouts: string[];
  socialMediaHandles: [];
  isVisitedBrandKitPage: boolean;
  isSeenConfetti: boolean;
  userHasExistingProject?: boolean;
  allowLowResImport?: boolean;
  languagePreference?: string;
}

export type SubArrItem = {
  id: string;
  text: string;
  end: number;
  start: number;
};

type ProgressBarConfig = {
  height: number;
  width: number;
  top: number;
  left: number;
  fill: string;
};

export type Position = {
  videoElUp: null | VideoPosition;
  videoElDown: null | VideoPosition;
};

export interface EditorDraft {
  id: string;
  createdAt: string;
  updatedAt: string;
  end: number;
  start: number;
  chapter_start: number;
  chapter_end: number;
  gist: string | null | undefined;
  name: string;
  backgroundColor: string | null;
  backgroundImageUrl: string | null;
  height: number;
  width: number;
  imageUrl: string | null;
  allSceneChanges: any[];
  progressbar: ProgressBarConfig;
  texts: any[];
  face_coord: FaceCoordinates[];
  aspectRatio: VideoLayout;
  project: Project;
  socials: any[];
  subtitleOption: {
    subsArr: SubArrItem[];
    subStart: number;
    subEnd: number;
  };
  outro: any;
  intro: any;
  hasTwoFace: boolean;
  subtitle: any;
  videoProperties: VideoProperties[];
  autoAddEmojisToSubtitles: boolean;
  bRolls: any[];
  fromDownloadPage?: boolean;
  videoPosition?: Position;
  stayOnEditor?: boolean;
  templateId?: string;
  clipId?: string;
  tag?: string;
  clip_src?: string;
}

export interface SelectionStyles {
  startIndex: number;
  endIndex: number;
  style: Partial<TextboxStyles>;
}

interface PexelPhoto {
  id: number;
  width: number;
  height: number;
  url: string;
  photographer: string;
  photographer_url: string;
  photographer_id: number;
  avg_color: string;
  src: {
    original: string;
    large2x: string;
    large: string;
    medium: string;
    small: string;
    portrait: string;
    landscape: string;
    tiny: string;
  };
  liked: boolean;
  alt: string;
}

export interface PexelsPhotoResponse {
  total_results: number;
  page: number;
  per_page: number;
  photos: PexelPhoto[];
  next_page: string;
}

interface PexelVideoFile {
  id: number;
  quality: string;
  file_type: string;
  width: number | null;
  height: number | null;
  link: string;
}

interface PexelVideoPicture {
  id: number;
  picture: string;
  nr: number;
}

interface PexelVideo {
  id: number;
  width: number;
  height: number;
  url: string;
  image: string;
  duration: number;
  user: {
    id: number;
    name: string;
    url: string;
  };
  video_files: PexelVideoFile[];
  video_pictures: PexelVideoPicture[];
}

export interface PexelsVideoResponse {
  page: number;
  per_page: number;
  next_page: string;
  total_results: number;
  url: string;
  videos: PexelVideo[];
}

export interface AmplitudeTrackObject {
  data: any;
  type: string;
  uid: string;
  eventName?: string;
  identifyType?: string;
}

export enum AUDIO_GENERATION_STATUS {
  COMPLETE = "complete",
  ERROR = "error",
  SUBMITTED = "submitted",
}

export type Timeout = ReturnType<typeof setTimeout>;

export type ProgressBarPosition = { top: number; left: number };

export interface VideoPositionAfterCanvasModification {
  currentVideoPosition: VideoPosition;
  prevVideoPosition: VideoPosition;
  isBottomVideo: boolean;
}

export type SelectionHandle = "start" | "end";
