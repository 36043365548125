import { Fragment } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Menu, Transition } from "@headlessui/react";
import { ClockIcon } from "@heroicons/react/24/outline";
import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import { Tooltip } from "flowbite-react";

import { updateCaption } from "@/store/editorSlice";
import {
  setCurrentSelectedClip,
  updateShowFullLengthVideo,
  updateShowPreviewClipsSideModal,
} from "@/store/homeSlice";
import { useAppDispatch } from "@/store/hooks";

import { INTELLI_CLIP } from "@/constants";

import {
  formatMilliseconds,
  formatVideoDuration,
  generateCloudflareImageUrl,
  getIsIntelliClip,
} from "@/helpers";

import { PreviewScreenClipTypes, VideoLayout } from "@/enums";

const PreviewClipVideoCard = ({
  clipTitle,
  currentType,
  endTime,
  startTime,
  layout,
  clipThumbnail,
  clip,
  onVideoClipChange,
  setIsClipDeleteModal,
  selectModeOn,
  isCurrentClip,
}: {
  clipTitle: string;
  currentType: string;
  endTime: number;
  startTime: number;
  layout: VideoLayout;
  clipThumbnail: string;
  clip?: any;
  setIsClipDeleteModal?: (value: boolean) => void;
  onVideoClipChange?: (clip: any, layout: VideoLayout, key: string) => void;
  selectModeOn: boolean;
  isCurrentClip: boolean;
}) => {
  const dispatch = useAppDispatch();

  const isIntelliClip = getIsIntelliClip(clip);

  return (
    <div
      className={clsx("relative ", selectModeOn ? "min-w-[92%]" : "w-full")}
      key={`${clip ? clip.clipId : 1}`}
    >
      <div
        key={`${clip ? clip.clipId : 1}`}
        style={{
          border: "0.25px solid #1A56DB",
        }}
        className={`rounded-md flex p-2 h-[72px] cursor-pointer
        ${
          (isCurrentClip &&
            currentType === PreviewScreenClipTypes.FULL_VIDEO) ||
          isCurrentClip
            ? "bg-[#1C64F2]"
            : "bg-white hover:bg-blue-50 "
        }
        `}
        onClick={() => {
          // clear AI Description on clip change
          dispatch(updateCaption({}));
          if (currentType === PreviewScreenClipTypes.FULL_VIDEO) {
            dispatch(updateShowPreviewClipsSideModal(false));
            dispatch(setCurrentSelectedClip(PreviewScreenClipTypes.FULL_VIDEO));
            dispatch(updateShowFullLengthVideo(true));
          } else {
            if (!isCurrentClip) {
              onVideoClipChange && onVideoClipChange(clip, layout, clip.clipId);
            }
          }
        }}
      >
        {/* image, title, duration, icon */}

        {/* image */}
        <div className="mr-4">
          {" "}
          <LazyLoadImage
            // use normal <img> attributes as props
            src={generateCloudflareImageUrl({
              originalUrl:
                clipThumbnail ||
                "https://storage.googleapis.com/vidyo-ai-static/preload-images/default_processing_video.jpeg",
              imgOptions: {
                width: 48,
                quality: 85,
              },
            })}
            width={48}
            className="object-center object-scale-down w-[48px] h-full"
            onError={(e: any) => {
              e.target.onerror = null;
              e.target.src = clipThumbnail;
            }}
          />
        </div>

        {/* title and duration */}
        <div className="pt-1 w-[65%]">
          <div className="flex space-x-2">
            <p
              className={`tex-sm truncate
              ${
                (isCurrentClip &&
                  currentType === PreviewScreenClipTypes.FULL_VIDEO) ||
                isCurrentClip
                  ? "text-white font-semibold"
                  : "text-[#1F2A37] font-normal"
              }
              `}
            >
              {" "}
              {clipTitle.length > 0 ? clipTitle : "Untitled clip"}
            </p>
            {clip?.custom ? (
              <span className="bg-[#DEF7EC] text-[#03543F] text-xs px-2 py-1 rounded-lg font-light">
                Custom
              </span>
            ) : null}
          </div>
          <div className="flex mt-0.5">
            <div>
              {clip && (
                <p
                  className={`font-light text-sm mr-3 ${
                    (isCurrentClip &&
                      currentType === PreviewScreenClipTypes.FULL_VIDEO) ||
                    isCurrentClip
                      ? "text-white"
                      : "text-[#9CA3AF]"
                  }`}
                >
                  {isIntelliClip
                    ? INTELLI_CLIP
                    : `${formatVideoDuration(
                        startTime
                      )} - ${formatVideoDuration(endTime)}`}
                </p>
              )}
            </div>
            <div
              className={`flex justify-center items-center rounded-md text-sm font-light pr-2 ${
                (isCurrentClip &&
                  currentType === PreviewScreenClipTypes.FULL_VIDEO) ||
                isCurrentClip
                  ? "border-white text-white  "
                  : "text-[#9CA3AF]"
              }`}
            >
              {" "}
              <ClockIcon
                className={`mr-1  ${
                  (isCurrentClip &&
                    currentType === PreviewScreenClipTypes.FULL_VIDEO) ||
                  isCurrentClip
                    ? "text-white"
                    : "text-[#9CA3AF]"
                }`}
                height={20}
                width={20}
              />
              {formatMilliseconds(endTime - startTime)}
              <div className="flex space-x-2 ml-3">
                {clip?.seen &&
                  currentType !== PreviewScreenClipTypes.FULL_VIDEO && (
                    <span className="text-md">
                      <Tooltip
                        content="Seen by you"
                        placement="top"
                      >
                        👀
                      </Tooltip>
                    </span>
                  )}
                {clip?.bookmarked &&
                currentType !== PreviewScreenClipTypes.FULL_VIDEO ? (
                  <span className="text-md">
                    <Tooltip
                      content="Liked by you"
                      placement="top"
                    >
                      🤎
                    </Tooltip>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {clip?.viralityScore && clip?.viralityScore >= 0 && (
          <div className="ml-2 w-10 flex flex-col justify-around items-center text-sm">
            <p
              className={`text-center text-md font-bold ${
                isCurrentClip ? "text-white" : "text-[#1C64F2]"
              }`}
            >
              {clip?.viralityScore}
            </p>
            <p className={`${isCurrentClip ? "text-white" : "text-[#9CA3AF]"}`}>
              Score
            </p>
          </div>
        )}
      </div>
      {/* icon */}
      {!selectModeOn && (
        <div className="absolute top-1/2 right-2.5 -translate-y-1/2 z-10">
          {isCurrentClip &&
          currentType !== PreviewScreenClipTypes.FULL_VIDEO ? (
            <Menu
              as="div"
              className="relative inline-block text-left"
            >
              <div>
                <Menu.Button className="float-right text-blue-600 cursor-pointer">
                  <EllipsisVerticalIcon
                    className="float-right text-white font-semibold cursor-pointer"
                    width="20px"
                    height="20px"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-40 mt-7 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-blue-500 text-white" : "text-red-500"
                          } flex w-full cursor-pointer items-center rounded-md px-2 py-1.5 text-base`}
                          onClick={() =>
                            setIsClipDeleteModal && setIsClipDeleteModal(true)
                          }
                          id="delete-video-card"
                        >
                          <TrashIcon
                            className="mr-1.5"
                            width={20}
                            height={20}
                          />
                          <span>Delete clip</span>
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <ChevronRightIcon
              onClick={() => {
                if (!isCurrentClip) {
                  onVideoClipChange &&
                    onVideoClipChange(clip, layout, clip.clipId);
                }
              }}
              className="float-right text-blue-600 cursor-pointer"
              width="16px"
              height="16px"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PreviewClipVideoCard;
