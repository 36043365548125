import { ampli } from "@/ampli";
import * as amplitude from "@amplitude/analytics-browser";
import { nanoid } from "nanoid";

import { store } from "@/store";
import {
  setDownloadAnalytics,
  setEditorAnalytics,
  setHomeAnalytics,
} from "@/store/amplitudeSlice";

import { trackAmplitudeEvents } from "@/api/requests";

import { INTELLI_CLIP_TAG } from "@/constants";
import {
  ANALYTICS_CONSTANTS,
  AUDIO_TYPE_USED,
  BRAND_KIT_BACKGROUND,
  BRAND_KIT_OUTRO,
  B_ROLL_IMAGE,
  B_ROLL_VIDEO,
  EDITOR_INTERACTION_DATA,
  EVENTS,
  EVENT_TYPE,
  EXPORT_PREFERENCE,
  IDENTIFY_TYPE,
  IMPORT_PREFERENCE,
  OVERVIEW_CONSTANT,
  REVIEW_CLIPS_INTERACTION,
  SAVE_AS_NEW_DRAFT,
  STOCK_BACKGROUND,
  STOCK_OUTRO,
  UPDATE_DRAFT,
} from "@/constants/amplitude";

import {
  convertToCamelCase,
  getCurrencyByCountryCode,
  hasMoreThanKeys,
  isProd,
} from "@/helpers";
import {
  DATE_TIME,
  checkAndReturnStockBackground,
  checkAndReturnSubtitleStyleColor,
  convertCurrencyIntoUSD,
  convertToUSD,
  getAudioIds,
  getBRollAssetId,
  getClipDuration,
  getCurrentPlanEndTimeStamp,
  getDateTime,
  getDefaultUtmProperties,
  getEffects,
  getExportQualityResolution,
  getMonthAsString,
  getPlanName,
  getSocialLogos,
  textEffects,
} from "@/helpers/amplitudeHelper";

import { trackMoengageEvent } from "@/utils/moengage";

import { Project, AmplitudeTrackObject } from "@/interfaces";

import {
  ABTest,
  AssetTags,
  ExportQuality,
  PlanType,
  ScreenName,
  SubtitleStyle,
  VideoLayout,
} from "@/enums";

import {
  exportVideoTrackTikTok,
  identifyUserTikTok,
  importVideoTrackTikTok,
} from "./tiktokPixel";

const extractPaths = (pathname: string) => {
  // Use regular expression to extract the paths
  const regex = /\/([^/]+)/g;
  const matches = pathname.match(regex);

  // Extract the desired paths from the matches
  const paths = [];
  if (matches) {
    for (const match of matches) {
      // Remove the leading '/' and push the path to the array
      const path = match.substring(1);

      // Remove UUID-like segments and 'templates'
      if (
        !path.match(/[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}|templates/i)
      ) {
        paths.push(path);
      }
    }
  }

  if (paths.length) {
    return paths[0];
  }
  return null;
};

const getScreenNameFromHostName = () => {
  const pathname = extractPaths(window.location.pathname);

  switch (pathname) {
    case "home":
      return ScreenName.HOME;
    case "editor":
      return ScreenName.EDITOR;
    case "downloads":
      return ScreenName.DOWNLOADS;
    case "project":
      return ScreenName.REVIEW_CLIPS;
    case "my-templates":
    case null:
      return ScreenName.TEMPLATES;
    case "brand-kit":
      return ScreenName.BRAND_KIT;
    default:
      return ScreenName.HOME;
  }
};

export const eventsDataToRedux = (event: any, value?: any, id?: string) => {
  const editorAnalytics = store.getState().amplitudeState.editorScreen;
  const homeAnalytics = store.getState().amplitudeState.homeScreen;
  const downloadsAnalytics = store.getState().amplitudeState.downloadScreen;

  switch (event) {
    case ANALYTICS_CONSTANTS.CLIP_COUNT:
      store.dispatch(
        setHomeAnalytics({
          ...homeAnalytics,
          clipCount: value,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.OVERVIEW_TYPE:
      store.dispatch(
        setHomeAnalytics({
          ...homeAnalytics,
          overviewType: value,
          overviewChanged: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.DOWNLOAD_SRT:
      store.dispatch(
        setEditorAnalytics({ ...editorAnalytics, downloadSRT: true })
      );
      break;
    case ANALYTICS_CONSTANTS.SUBTITLE_REMOVED:
      store.dispatch(
        setEditorAnalytics({ ...editorAnalytics, subtitlesRemove: true })
      );
      break;
    case ANALYTICS_CONSTANTS.SUBTITLE_STYLE_CLICK:
      store.dispatch(
        setEditorAnalytics({ ...editorAnalytics, subtitleStyleClick: true })
      );
      break;
    case ANALYTICS_CONSTANTS.SOCIAL_PREVIEW_ACCESSED:
      !editorAnalytics?.socialPreviewAccessed &&
        store.dispatch(
          setEditorAnalytics({
            ...editorAnalytics,
            socialPreviewAccessed: true,
          })
        );
      break;
    case ANALYTICS_CONSTANTS.OUTRO_TYPE:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          outro: {
            ...editorAnalytics?.outro,
            type: value,
            id,
          },
        })
      );
      break;
    case ANALYTICS_CONSTANTS.LOGO_TYPE:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          logoType: [...(editorAnalytics?.logoType || []), value],
          socialLogoAdded: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.BROLL_TYPE:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          bRollType: [...(editorAnalytics?.bRollType || []), value],
        })
      );
      break;
    case ANALYTICS_CONSTANTS.BACKGROUND_TYPE:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          backgroundType: value,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.AUDIO_TYPE:
      let audioType = "";
      if (value.hasOwnProperty("ownerId"))
        audioType = AUDIO_TYPE_USED.BRAND_KIT_AUDIO;
      else audioType = AUDIO_TYPE_USED.STOCK_AUDIO;

      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          audioType,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.AI_CAPTIONS:
      trackReviewClipsInteractionEvents(
        REVIEW_CLIPS_INTERACTION.AI_CAPTIONS,
        value
      );
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          aiCaptions: [...(editorAnalytics?.aiCaptions || []), value],
          captionGenerated: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.ENABLE_CUTMAGIC:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          cutMagicEnabled: true,
        })
      );
      trackEnableCutMagicEvent();
      break;
    case ANALYTICS_CONSTANTS.FONT_SETTINGS_ACCESSED:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          fontSettingsAccessed: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOAD_CUSTOM_FONT:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          uploadCustomFont: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.TEXT_EFFECT:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          textEffectChanged: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.FONT_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          fontUpload: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.BACKGROUND_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          backgroundUpload: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.LOGO_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          logoUpload: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.OUTRO_IMAGE_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          outroImageUpload: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.OUTRO_VIDEO_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          outroVideoUpload: true,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.UPLOADS.AUDIO_UPLOAD:
      store.dispatch(
        setEditorAnalytics({
          ...editorAnalytics,
          audioUpload: true,
        })
      );
    case ANALYTICS_CONSTANTS.EXPORT_PROJECT_ID:
      store.dispatch(
        setDownloadAnalytics({
          ...downloadsAnalytics,
          projectId: value,
        })
      );
      break;
    case ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME:
      const screenName = getScreenNameFromHostName();

      store.dispatch(
        setHomeAnalytics({
          ...homeAnalytics,
          paymentScreenName: convertToCamelCase(screenName),
        })
      );
      break;

    case ANALYTICS_CONSTANTS.EDITOR_MEDIA_PEXEL_SEARCH:
      store.dispatch;
      setEditorAnalytics({
        ...editorAnalytics,
        mediaSearched: true,
      });
  }
};

export const trackSaveDraftEvent = (
  draftData: any,
  draftSaveOption: string
) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const homeAnalytics = store.getState().amplitudeState.homeScreen;
    const editorAnalytics = store.getState().amplitudeState.editorScreen;

    const currentSelectedMicroContent =
      store.getState().homeState.currentSelectedMicroContent;
    const duration =
      draftData.aspectRatio === VideoLayout.LAYOUT_16_9
        ? (draftData.chapter_end - draftData.chapter_start) / 1000
        : (draftData.end - draftData.start) / 1000;

    const areSubsEnabled =
      Object.keys(draftData.subtitle).length > 0 ||
      !editorAnalytics?.subtitlesRemove;

    const eventData = {
      clipDuration: duration,
      clipId: draftData?.clipId || nanoid(),
      clipName: draftData.gist,
      isDiscontinuousClip:
        currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
      autoAddEmoji: draftData.autoAddEmojisToSubtitles || false,
      downloadSRT: editorAnalytics?.downloadSRT || false,
      subtitleEffect: getEffects(draftData.subtitle),
      subtitleFontSize: parseInt(draftData.subtitle.fontSize),
      subtitlesRemove: !areSubsEnabled,
      subtitleStyle: areSubsEnabled
        ? convertToCamelCase(draftData.subtitle.style.type)
        : "",
      subtitleStyleClick: editorAnalytics?.subtitleStyleClick || false,
      subtitleStyleColor: areSubsEnabled
        ? checkAndReturnSubtitleStyleColor(draftData.subtitle.style)
        : "",
      aspectRatio: draftData.aspectRatio,
      newDraft: draftSaveOption === SAVE_AS_NEW_DRAFT,
      updateDraft: draftSaveOption === UPDATE_DRAFT,
      overviewChanged: homeAnalytics?.overviewChanged || false,
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      templateId: currentSelectedMicroContent?.id,
      aiCaptions: editorAnalytics?.aiCaptions || [],
      brandkitBackground:
        editorAnalytics?.backgroundType === BRAND_KIT_BACKGROUND ? true : false,
      brandkitOutros:
        editorAnalytics?.outro?.type === BRAND_KIT_OUTRO ? true : false,
      bRollImageUsed: getBRollAssetId(draftData.bRolls, B_ROLL_IMAGE),
      bRollVideoUsed: getBRollAssetId(draftData.bRolls, B_ROLL_VIDEO),
      captionGenerated: editorAnalytics?.captionGenerated || false,
      cutmagicEnabled: editorAnalytics?.cutMagicEnabled || false,
      fontSettingAccessed: editorAnalytics?.fontSettingsAccessed || false,
      fontUploaded: editorAnalytics?.fontUpload || false,
      mediaSearched: editorAnalytics?.mediaSearched || false,
      socialLogos: getSocialLogos(draftData.socials),
      socialLogoAdded: editorAnalytics?.socialLogoAdded || false,
      socialPreviewAccessed: editorAnalytics?.socialPreviewAccessed || false,
      stockAudioIds: getAudioIds(
        draftData.audioClips,
        editorAnalytics?.audioType
      ),
      stockBackground: checkAndReturnStockBackground(
        editorAnalytics?.backgroundType,
        STOCK_BACKGROUND,
        draftData.backgroundImageUrl,
        draftData.backgroundColor
      ),
      stockOutroId:
        editorAnalytics?.outro?.type === STOCK_OUTRO
          ? editorAnalytics?.outro?.id
          : "",
      textEffects: textEffects(draftData.texts),
      textEffectsAccessed: editorAnalytics?.textEffectChanged || false,
      uploadedAudio: editorAnalytics?.audioUpload || false,
      uploadedBackground: editorAnalytics?.backgroundUpload || false,
      uploadedLogo: editorAnalytics?.logoUpload || false,
      uploadedOutroImage: editorAnalytics?.outroImageUpload || false,
      uploadedOutroVideo: editorAnalytics?.outroVideoUpload || false,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      eventName: EVENTS.SAVE_DRAFT,
      type: EVENT_TYPE.TRACK,
      uid,
    };

    trackAmplitudeEvents(trackObj);
    trackMoengageEvent(EVENTS.SAVE_DRAFT, eventData);
  }
};

export const trackRenderClipEvent = (exportPlatform: string, data: any) => {
  // downloading/exporting videos from editor screen

  if (isProd()) {
    const currentUser = store.getState().authState.currentUser;
    const editorAnalytics = store.getState().amplitudeState.editorScreen;
    const homeAnalytics = store.getState().amplitudeState.homeScreen;
    const currentSelectedProject =
      store.getState().homeState.currentSelectedProject;
    const currentSelectedMicroContent =
      store.getState().homeState.currentSelectedMicroContent;

    const exportQuality = store.getState().editorState.exportQuality;

    const duration =
      data.aspectRatio === VideoLayout.LAYOUT_16_9
        ? (data.chapter_end - data.chapter_start) / 1000
        : (data.end - data.start) / 1000;

    const areSubsEnabled =
      Object.keys(data.subtitle).length > 0 &&
      !editorAnalytics?.subtitlesRemove;

    const eventData = {
      exportPlatform,
      exportPreference: getExportQualityResolution(exportQuality),
      clipDuration: duration,
      clipId: data?.clipId || nanoid(),
      clipName: data.gist,
      isDiscontinuousClip:
        currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
      autoAddEmoji: data.autoAddEmojisToSubtitles || false,
      downloadSRT: editorAnalytics?.downloadSRT || false,
      subtitleEffect: getEffects(data.subtitle),
      subtitleFontSize: parseInt(data.subtitle.fontSize),
      subtitlesRemove: !areSubsEnabled,
      subtitleStyle: areSubsEnabled
        ? convertToCamelCase(data.subtitle.style.type)
        : "",
      subtitleStyleClick: editorAnalytics?.subtitleStyleClick || false,
      subtitleStyleColor: areSubsEnabled
        ? checkAndReturnSubtitleStyleColor(data.subtitle.style)
        : "",
      overviewChanged: homeAnalytics?.overviewChanged || false,
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      templateId: currentSelectedMicroContent?.id,
      aspectRatio: data.aspectRatio,
      aiCaptions: editorAnalytics?.aiCaptions || [],
      brandkitBackground:
        editorAnalytics?.backgroundType === BRAND_KIT_BACKGROUND ? true : false,
      brandkitOutros:
        editorAnalytics?.outro?.type === BRAND_KIT_OUTRO ? true : false,
      bRollImageUsed: getBRollAssetId(data.bRolls, B_ROLL_IMAGE),
      bRollVideoUsed: getBRollAssetId(data.bRolls, B_ROLL_VIDEO),
      captionGenerated: editorAnalytics?.captionGenerated || false,
      cutmagicEnabled: editorAnalytics?.cutMagicEnabled || false,
      fontSettingAccessed: editorAnalytics?.fontSettingsAccessed || false,
      fontUploaded: editorAnalytics?.fontUpload || false,
      mediaSearched: editorAnalytics?.mediaSearched || false,
      renderId: data?.id || null,
      socialLogos: getSocialLogos(data.socials),
      socialLogoAdded: editorAnalytics?.socialLogoAdded || false,
      socialPreviewAccessed: editorAnalytics?.socialPreviewAccessed || false,
      stockAudioIds: getAudioIds(data.audioClips, editorAnalytics?.audioType),
      stockBackground: checkAndReturnStockBackground(
        editorAnalytics?.backgroundType,
        STOCK_BACKGROUND,
        data.backgroundImageUrl,
        data.backgroundColor
      ),
      stockOutroId:
        editorAnalytics?.outro?.type === STOCK_OUTRO
          ? editorAnalytics?.outro?.id
          : "",
      textEffects: textEffects(data.texts),
      textEffectsAccessed: editorAnalytics?.textEffectChanged || false,
      uploadedAudio: editorAnalytics?.audioUpload || false,
      uploadedBackground: editorAnalytics?.backgroundUpload || false,
      uploadedLogo: editorAnalytics?.logoUpload || false,
      uploadedOutroImage: editorAnalytics?.outroImageUpload || false,
      uploadedOutroVideo: editorAnalytics?.outroVideoUpload || false,
      videoDuration: JSON.parse(currentSelectedProject!.data).duration / 1000,
      videoId: currentSelectedProject?.id,
      videoName: currentSelectedProject?.title,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      eventName: EVENTS.EXPORT_CLIP,
      type: EVENT_TYPE.TRACK,
      uid: currentUser.uid,
    };

    trackAmplitudeEvents(trackObj);
    exportVideoTrackTikTok();
    trackMoengageEvent(EVENTS.EXPORT_CLIP, eventData);
  }
};

export const trackClipExportEvent = (
  projectId: string,
  clipId: string,
  exportPlatform: string
) => {
  // downloading/exporting videos from download screen, not from editor
  if (isProd()) {
    const currentUser = store.getState().authState.currentUser;
    let eventData;
    if (exportPlatform === EXPORT_PREFERENCE.DOWNLOAD) {
      eventData = {
        clipId,
        exportPlatform,
        videoId: projectId,
      };
    } else {
      const exportDescription = store.getState().editorState.caption.text;
      eventData = {
        clipId,
        exportPlatform,
        exportDescription,
        videoId: projectId,
      };
    }

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid: currentUser.uid,
      eventName: EVENTS.EXPORT_CLIP,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
    exportVideoTrackTikTok();
    trackMoengageEvent(EVENTS.EXPORT_CLIP, eventData);
  }
};

export const trackEditClipEvent = (dataObj: any, screenName: ScreenName) => {
  const microcontent = {
    id: dataObj.templateId,
    start: dataObj.start,
    end: dataObj.end,
    gist: dataObj.gist || "",
    chapter_end: dataObj.chapter_end,
    chapter_start: dataObj.chapter_start,
    clipId: dataObj?.clipId,
    tag: dataObj?.tag,
  };
  trackEndAndDownloadEvent(
    screenName,
    dataObj.aspectRatio,
    dataObj.project,
    microcontent
  );
};

export const trackEndAndDownloadEvent = (
  screenName: ScreenName,
  aspectRatio: VideoLayout,
  currentSelectedProject: any,
  currentSelectedMicroContent: any
) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const homeAnalytics = store.getState().amplitudeState.homeScreen;
    store.dispatch(setEditorAnalytics(null));

    const eventData = {
      aspectRatio,
      clipDuration: getClipDuration(
        currentSelectedMicroContent,
        homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
      ),
      clipId: currentSelectedMicroContent?.clipId || nanoid(),
      clipName: currentSelectedMicroContent.gist,
      isDiscontinuousClip:
        currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
      favouritesAdd: currentSelectedMicroContent?.bookmarked || false,
      overviewChanged: homeAnalytics?.overviewChanged || false,
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      screenName: convertToCamelCase(screenName),
      templateId: currentSelectedMicroContent?.id || "",
      videoDuration:
        JSON.parse(currentSelectedProject!.data)?.duration / 1000 || 0,
      videoId: currentSelectedProject?.id,
      videoName: currentSelectedProject?.title,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.EDIT_AND_DOWNLOAD_VIDEO_CLICK,
      type: EVENT_TYPE.TRACK,
    };

    trackAmplitudeEvents(trackObj);

    trackMoengageEvent(EVENTS.EDIT_AND_DOWNLOAD_VIDEO_CLICK, eventData);
  }
};

export const trackLayoutChangeEvent = (
  layout: VideoLayout,
  screenName: ScreenName
) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const currentSelectedProject =
      store.getState().homeState.currentSelectedProject;
    const currentSelectedMicroContent =
      store.getState().homeState.currentSelectedMicroContent;

    const homeAnalytics = store.getState().amplitudeState.homeScreen;

    const eventData = {
      aspectRatio: layout,
      clipDuration: getClipDuration(
        currentSelectedMicroContent,
        homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
      ),
      clipId: currentSelectedMicroContent?.clipId || nanoid(),
      clipName: currentSelectedMicroContent.gist,
      isDiscontinuousClip:
        currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
      screenName: convertToCamelCase(screenName),
      videoDuration: JSON.parse(currentSelectedProject!.data).duration / 1000,
      videoId: currentSelectedProject?.id,
      videoName: currentSelectedProject?.title,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.SWITCH_CLIP_ASPECT_RATIO,
      type: EVENT_TYPE.TRACK,
    };

    trackAmplitudeEvents(trackObj);
    trackMoengageEvent(EVENTS.SWITCH_CLIP_ASPECT_RATIO, eventData);
  }
};

export const identifyUser = ({
  user,
  onBoardingData,
  trackInitialUtm = false,
}: {
  user: any;
  onBoardingData?: any;
  trackInitialUtm?: boolean;
}) => {
  if (isProd() && user) {
    const { userSubscription, country, userIP } = store.getState().authState;
    if (!getPlanName(userSubscription.planType)) return;

    const { nextBillDate, subscriptionStartedAt, intervalInMonths } =
      userSubscription;

    const homeAnalytics = store.getState().amplitudeState.homeScreen;

    const { currentPlanEndDate, currentPlanEndMonth, currentPlanEndYear } =
      getCurrentPlanEndTimeStamp(subscriptionStartedAt, intervalInMonths);

    let identifyObject: any = {
      userName: user.displayName,
      emailId: user.email,
      isPaidUser: userSubscription.planType !== PlanType.FREE,
      currentPlan: getPlanName(userSubscription.planType),
      currentPlanFrequency:
        userSubscription.intervalInMonths === 12 ? "Annual" : "Monthly",
      currentPlanEndDate,
      currentPlanEndMonth,
      currentPlanEndYear,
      personaOnboarding: onBoardingData?.occupation || null,
      userIP: userIP ?? null,
      country: country ?? null,
    };

    if (trackInitialUtm) {
      identifyObject.initial_utm_campaign =
        getDefaultUtmProperties().utmCampaign;
      identifyObject.initial_utm_medium = getDefaultUtmProperties().utmMedium;
      identifyObject.initial_utm_source = getDefaultUtmProperties().utmSource;
      identifyObject.initial_utm_term =
        homeAnalytics?.utmDataObj?.utmTerm || "none";
    }

    if (userSubscription.planType === PlanType.FREE) {
      identifyObject.utm_campaign = getDefaultUtmProperties().utmCampaign;
      identifyObject.utm_medium = getDefaultUtmProperties().utmMedium;
      identifyObject.utm_source = getDefaultUtmProperties().utmSource;
    }

    const trackObj: AmplitudeTrackObject = {
      data: identifyObject,
      type: EVENT_TYPE.IDENTIFY,
      uid: user.uid,
      identifyType: IDENTIFY_TYPE.USER_IDENTIFY,
    };

    trackAmplitudeEvents(trackObj);
    identifyUserTikTok(user);
    ampli.identify(user.uid, identifyObject);
  }
};

export const trackSignupEvent = (user: any) => {
  if (isProd()) {
    const homeAnalytics = store.getState().amplitudeState.homeScreen;
    identifyUser({ user, trackInitialUtm: true });

    const eventData = {
      userId: user.uid,
      emailId: user.email,
      userName: user.displayName,
      signupSource: user.providerData[0].providerId,
      date: getDateTime(user.metadata.lastLoginAt, DATE_TIME.DATE),
      month: getMonthAsString(
        getDateTime(user.metadata.lastLoginAt, DATE_TIME.MONTH)
      ),
      year: getDateTime(user.metadata.lastLoginAt, DATE_TIME.YEAR),
      hours: getDateTime(user.metadata.lastLoginAt, DATE_TIME.HOUR),
      minutes: getDateTime(user.metadata.lastLoginAt, DATE_TIME.MINUTE),
      seconds: getDateTime(user.metadata.lastLoginAt, DATE_TIME.SECOND),
      utm_campaign: getDefaultUtmProperties().utmCampaign,
      utm_medium: getDefaultUtmProperties().utmMedium,
      utm_source: getDefaultUtmProperties().utmSource,
      utm_term: homeAnalytics?.utmDataObj?.utmTerm || "none",
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid: user.uid,
      eventName: EVENTS.SIGNUP,
      type: EVENT_TYPE.TRACK,
    };

    trackAmplitudeEvents(trackObj);
    trackMoengageEvent(EVENTS.SIGNUP, eventData, true);
  }
};

export const trackSigninEvent = (user: any) => {
  if (isProd()) {
    const eventData = {
      emailId: user.email,
      userName: user.displayName,
      signinSource: user.providerData[0].providerId,
      date: getDateTime(user.metadata.lastLoginAt, DATE_TIME.DATE),
      month: getMonthAsString(
        getDateTime(user.metadata.lastLoginAt, DATE_TIME.MONTH)
      ),
      year: getDateTime(user.metadata.lastLoginAt, DATE_TIME.YEAR),
      hours: getDateTime(user.metadata.lastLoginAt, DATE_TIME.HOUR),
      minutes: getDateTime(user.metadata.lastLoginAt, DATE_TIME.MINUTE),
      seconds: getDateTime(user.metadata.lastLoginAt, DATE_TIME.SECOND),
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      eventName: EVENTS.SIGN_IN,
      uid: user.uid,
      type: EVENT_TYPE.TRACK,
    };

    trackAmplitudeEvents(trackObj);
    trackMoengageEvent(EVENTS.SIGN_IN, eventData, true);
  }
};

export const trackUserOnboardingData = (updatedOnboardingData: any) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const eventData = {
      userGoals: updatedOnboardingData.user_wants_to,
      socialAccounts: updatedOnboardingData.referer,
      personaOnboarding: updatedOnboardingData.occupation,
      teamPlayer: updatedOnboardingData.isIndividual,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.FINNISH_ON_BOARDING,
      type: EVENT_TYPE.TRACK,
    };

    trackAmplitudeEvents(trackObj);

    trackMoengageEvent(EVENTS.FINNISH_ON_BOARDING, eventData);
  }
};

const trackEnableCutMagicEvent = () => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const currentSelectedProject =
      store.getState().homeState.currentSelectedProject;
    const currentSelectedMicroContent =
      store.getState().homeState.currentSelectedMicroContent;

    const homeAnalytics = store.getState().amplitudeState.homeScreen;
    const selectedLayout = store.getState().editorState.selectedLayout;

    const eventData = {
      clipDuration: getClipDuration(
        currentSelectedMicroContent,
        homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
      ),
      clipId: currentSelectedMicroContent?.clipId || nanoid(),
      clipName: currentSelectedMicroContent.gist,
      isDiscontinuousClip:
        currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      videoDuration: JSON.parse(currentSelectedProject!.data).duration / 1000,
      videoId: currentSelectedProject?.id,
      videoName: currentSelectedProject?.title,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.ENABLE_CUTMAGIC,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
    trackMoengageEvent(EVENTS.ENABLE_CUTMAGIC, eventData);
  }
};

export const trackViewOnboardingEvent = () => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const trackObj: AmplitudeTrackObject = {
      data: {},
      uid,
      eventName: EVENTS.VIEW_ONBOARDING,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
    trackMoengageEvent(EVENTS.VIEW_ONBOARDING);
  }
};

export const trackReviewClipsEvent = () => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const currentSelectedProject =
      store.getState().homeState.currentSelectedProject;

    const homeAnalytics = store.getState().amplitudeState.homeScreen;

    const eventData = {
      clipCount: homeAnalytics?.clipCount || 0,
      videoDuration: JSON.parse(currentSelectedProject!.data).duration / 1000,
      videoId: currentSelectedProject?.id,
      videoName: currentSelectedProject?.title,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.REVIEW_CLIPS,
      type: EVENT_TYPE.TRACK,
    };

    trackAmplitudeEvents(trackObj);
    trackMoengageEvent(EVENTS.REVIEW_CLIPS, eventData);
  }
};

export const trackAddMoreMinutesEvent = async (
  quantity: number,
  totalCost: any
) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const { userSubscription, country } = store.getState().authState;
    const currency = getCurrencyByCountryCode(country);

    let amountCharged = totalCost;
    if (currency !== "USD") {
      amountCharged = parseFloat(
        (await convertCurrencyIntoUSD(amountCharged, currency)).toFixed(2)
      );
    }
    if (amountCharged) {
      const eventData = {
        minutesAdded: quantity,
        amountCharged,
        currentPlan: getPlanName(userSubscription.planType),
      };

      const trackObj: AmplitudeTrackObject = {
        data: eventData,
        uid,
        eventName: EVENTS.ADD_MORE_MINUTES,
        type: EVENT_TYPE.TRACK,
      };

      // const identifyObj = new amplitude.Identify();
      // identifyObj.add("totalSpend", amountCharged);
      // identifyObj.add("minutesAdded", quantity);

      const trackIdentifyObj: AmplitudeTrackObject = {
        data: {
          totalSpend: amountCharged,
          minutesAdded: quantity,
        },
        uid,
        identifyType: IDENTIFY_TYPE.TRACK_MINUTES_ADDED,
        type: EVENT_TYPE.IDENTIFY,
      };

      // const event = new amplitude.Revenue()
      //   .setPrice(amountCharged)
      //   .setQuantity(1);

      const trackRevenueObject: AmplitudeTrackObject = {
        data: {
          price: amountCharged,
          quantity: 1,
        },
        uid,
        type: EVENT_TYPE.REVENUE,
      };

      trackMoengageEvent(EVENTS.ADD_MORE_MINUTES, eventData);

      Promise.all([
        trackAmplitudeEvents(trackObj),
        trackAmplitudeEvents(trackIdentifyObj),
        trackAmplitudeEvents(trackRevenueObject),
      ]);
    }
  }
};

// -------- second release events -------

export const trackDeleteClipEvent = (
  currentSelectedMicroContent: any,
  projectData: any,
  screenName = getScreenNameFromHostName()
) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const homeAnalytics = store.getState().amplitudeState.homeScreen;

    const eventData = {
      clipDuration: getClipDuration(
        currentSelectedMicroContent,
        homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
      ),
      clipId: currentSelectedMicroContent?.clipId,
      clipName: currentSelectedMicroContent?.gist,
      isDiscontinuousClip:
        currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
      overviewChanged: homeAnalytics?.overviewChanged || false,
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      screenName: convertToCamelCase(screenName),
      templateId: currentSelectedMicroContent?.id,
      videoId: projectData.id,
      videoDuration: JSON.parse(projectData.data).duration / 1000,
      videoName: projectData.title,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.DELETE_CLIP,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
  }
};

export const trackRenameClipEvent = (
  currentSelectedMicroContent: any,
  updatedClipName: any
) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const homeAnalytics = store.getState().amplitudeState.homeScreen;
    const currentSelectedProject =
      store.getState().homeState.currentSelectedProject;

    const eventData = {
      clipDuration: getClipDuration(
        currentSelectedMicroContent,
        homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
      ),
      clipId: currentSelectedMicroContent?.clipId,
      clipName: currentSelectedMicroContent.gist,
      isDiscontinuousClip:
        currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
      templateId: currentSelectedMicroContent.id,
      overviewChanged: homeAnalytics?.overviewChanged || false,
      overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
      updatedClipName,
      videoId: currentSelectedProject?.id,
      videoDuration: JSON.parse(currentSelectedProject!.data).duration / 1000,
      videoName: currentSelectedProject?.title,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.RENAME_CLIP,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
  }
};

export const trackCreateCustomClipEvent = (
  projectData: Project,
  newClipData: any
) => {
  try {
    if (isProd()) {
      const uid = store.getState().authState.currentUser.uid;
      const homeAnalytics = store.getState().amplitudeState.homeScreen;
      const videoDuration = JSON.parse(projectData.data).duration / 1000;

      const eventData = {
        customClipLength: (newClipData.end - newClipData.start) / 1000,
        customClipType: newClipData.type,
        customClipEnd: newClipData.end / 1000,
        customClipStart: newClipData.start / 1000,
        overviewChanged: homeAnalytics?.overviewChanged || false,
        overviewType:
          homeAnalytics?.overviewType || OVERVIEW_CONSTANT.FULL_VIDEO,
        videoDuration,
        videoId: projectData.id,
        videoName: projectData.title,
      };

      const trackObj: AmplitudeTrackObject = {
        data: eventData,
        uid,
        eventName: EVENTS.CREATE_CUSTOM_CLIP,
        type: EVENT_TYPE.TRACK,
      };
      trackAmplitudeEvents(trackObj);

      trackMoengageEvent(EVENTS.CREATE_CUSTOM_CLIP, eventData);
    }
  } catch (err: any) {
    // do nothing
  }
};

export const trackReviewClipsInteractionEvents = (
  event: string,
  value?: string
) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const currentSelectedMicroContent =
      store.getState().homeState.currentSelectedMicroContent;

    let eventData = null;
    switch (event) {
      case REVIEW_CLIPS_INTERACTION.TRANSCRIPT_CLICK:
        eventData = {
          transcriptClick: true,
          clipId: currentSelectedMicroContent?.clipId,
          isDiscontinuousClip:
            currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
        };
        break;

      case REVIEW_CLIPS_INTERACTION.TRANSCRIPT_COPIED:
        eventData = {
          transcriptCopied: true,
          clipId: currentSelectedMicroContent?.clipId,
          isDiscontinuousClip:
            currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
        };
        break;

      case REVIEW_CLIPS_INTERACTION.VIDEO_TIMESTAMP_CLICKED:
        eventData = {
          videoTimestampClick: true,
          clipId: currentSelectedMicroContent?.clipId,
          isDiscontinuousClip:
            currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
        };
        break;

      case REVIEW_CLIPS_INTERACTION.VIDEO_TIMESTAMP_COPIED:
        eventData = {
          videoTimestampCopied: true,
          clipId: currentSelectedMicroContent?.clipId,
          isDiscontinuousClip:
            currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
        };
        break;

      case REVIEW_CLIPS_INTERACTION.AI_CAPTION_CLICK:
        eventData = {
          aiCaptionClicked: true,
          clipId: currentSelectedMicroContent?.clipId,
        };
        break;

      case REVIEW_CLIPS_INTERACTION.AI_CAPTIONS:
        const screenName = getScreenNameFromHostName();

        if (screenName === ScreenName.REVIEW_CLIPS) {
          eventData = {
            captionGenerated: true,
            aiCaptions: value,
            clipId: currentSelectedMicroContent?.clipId,
          };
        }
        break;
    }

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.REVIEW_SCREEN_INTERATION,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
    trackMoengageEvent(EVENTS.REVIEW_SCREEN_INTERATION, eventData);
  }
};

export const trackTemplateSaveEvent = (currentSelectedMicroContent: any) => {
  return;
  // if (isProd()) {
  //   const homeAnalytics = store.getState().amplitudeState.homeScreen;
  //   const selectedLayout = store.getState().editorState.selectedLayout;
  //   ampli.saveTemplate({
  //     aspectRatio: selectedLayout,
  //     clipDuration: getClipDuration(
  //       currentSelectedMicroContent,
  //       homeAnalytics?.overviewType === OVERVIEW_CONSTANT.CHAPTERS
  //     ),
  //     clipId: currentSelectedMicroContent.clipId,
  //     clipName: currentSelectedMicroContent.gist,
  //     overviewChanged: homeAnalytics?.overviewChanged || false,
  //     overviewType: homeAnalytics?.overviewType || OVERVIEW_CONSTANT.SHORTS,
  //     templateId: currentSelectedMicroContent.id,
  //   });
  // }
};

export const trackDiscordIconClick = () => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;

    const trackObj: AmplitudeTrackObject = {
      data: {},
      uid,
      eventName: EVENTS.DISCORD_ICON_CLICK,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);

    trackMoengageEvent(EVENTS.DISCORD_ICON_CLICK);
  }
};

export const trackWhatsNewIconClick = () => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;

    const trackObj: AmplitudeTrackObject = {
      data: {},
      uid,
      eventName: EVENTS.WHATS_NEW_ICON_CLICK,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);

    trackMoengageEvent(EVENTS.WHATS_NEW_ICON_CLICK);
  }
};

export const trackImportVideoClick = () => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;

    const trackObj: AmplitudeTrackObject = {
      data: {},
      uid,
      eventName: EVENTS.IMPORT_VIDEO_CLICK,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);

    trackMoengageEvent(EVENTS.IMPORT_VIDEO_CLICK);
  }
};

export const trackProjectImportEvent = (platform: string) => {
  if (isProd()) {
    const currentUser = store.getState().authState.currentUser;
    let eventData = null;
    if (platform === "react:Dashboard") {
      eventData = {
        videoSourcePlatform: IMPORT_PREFERENCE.LOCAL_DEVICE,
      };
    } else {
      eventData = {
        videoSourcePlatform: platform,
      };
    }
    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid: currentUser.uid,
      eventName: EVENTS.CLICK_START_IMPORT,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);

    importVideoTrackTikTok();

    trackMoengageEvent(EVENTS.CLICK_START_IMPORT, eventData);
  }
};

export const trackDownloadsTabClick = () => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;

    const trackObj: AmplitudeTrackObject = {
      data: {},
      uid,
      eventName: EVENTS.DOWNLOADS_CLICK,
      type: EVENT_TYPE.TRACK,
    };

    getScreenNameFromHostName() !== ScreenName.DOWNLOADS &&
      trackAmplitudeEvents(trackObj);
    trackMoengageEvent(EVENTS.DOWNLOADS_CLICK);
  }
};

export const trackMediaTabClick = (tab: string) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const eventData = { screenName: convertToCamelCase(tab) };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.MEDIA_CLICK,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);

    trackMoengageEvent(EVENTS.MEDIA_CLICK, eventData);
  }
};

export const trackUpgradeButton = () => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const eventData = {
      screenName: convertToCamelCase(getScreenNameFromHostName()),
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.UPGRADE_BUTTON_CLICK,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);

    trackMoengageEvent(EVENTS.UPGRADE_BUTTON_CLICK, eventData);
  }
};

export const addMoreMinutesButtonClick = (source: string) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const eventData = {
      source,
      screenName: convertToCamelCase(getScreenNameFromHostName()),
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.ADD_MORE_MINUTES_CLICK,
      type: EVENT_TYPE.TRACK,
    };

    trackAmplitudeEvents(trackObj);

    trackMoengageEvent(EVENTS.ADD_MORE_MINUTES_CLICK, eventData);
  }
};

export const trackPlatformLinkedProperty = (activePlanforms: any) => {
  if (isProd()) {
    const { currentUser } = store.getState().authState;

    const eventData = {
      exportSocialAccountsLinked:
        activePlanforms?.map((socialPlatform: any) =>
          convertToCamelCase(socialPlatform)
        ) || [],
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid: currentUser.uid,
      identifyType: IDENTIFY_TYPE.SOCIAL_PLATFORM_LINKED,
      type: EVENT_TYPE.IDENTIFY,
    };
    trackAmplitudeEvents(trackObj);
  }
};

export const trackUserUsage = (usage: number) => {
  if (isProd()) {
    const { currentUser, userSubscription } = store.getState().authState;
    const eventData = {
      minutesAvailable: userSubscription.allowedMinutes,
      minutesBalance: userSubscription.allowedMinutes - Math.floor(usage / 60),
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid: currentUser.uid,
      identifyType: IDENTIFY_TYPE.TRACK_USAGE,
      type: EVENT_TYPE.IDENTIFY,
    };
    trackAmplitudeEvents(trackObj);
  }
};

export const schedulePostsEventTrack = (
  projectId: string,
  clipId: string,
  exportPlatform: string,
  scheduleDate: Date
) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const exportDescription = store.getState().editorState.caption.text;
    const eventData = {
      clipId,
      exportPlatform,
      exportDescription: exportDescription ? exportDescription : "",
      videoId: projectId,
      postScheduleDate: scheduleDate.getDate(),
      postScheduleMonth: getMonthAsString(scheduleDate.getMonth()),
      postScheduleYear: scheduleDate.getFullYear(),
      postScheduleHour: scheduleDate.getHours(),
      postScheduleMinute: scheduleDate.getMinutes(),
      postScheduleSecond: scheduleDate.getSeconds(),
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.SOCIAL_MEDIA_POST_SCHEDULE,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
  }
};

export const trackSchedulePostUpdateEvent = ({
  clipId,
  isDelete,
  scheduledDate,
}: {
  clipId: string;
  isDelete: boolean;
  scheduledDate?: Date;
}) => {
  // called when ever user modifies schedule data or delete the scheduled post
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    let eventData: any = {};
    if (isDelete) {
      (eventData.postScheduleDeleted = true), (eventData.clipId = clipId);
    } else {
      (eventData.clipId = clipId),
        eventData.clipId,
        (eventData.postScheduleDate = scheduledDate?.getDate());
      eventData.postScheduleMonth = getMonthAsString(scheduledDate?.getMonth());
      eventData.postScheduleYear = scheduledDate?.getFullYear();
      eventData.postScheduleHour = scheduledDate?.getHours();
      eventData.postScheduleMinute = scheduledDate?.getMinutes();
      eventData.postScheduleSecond = scheduledDate?.getSeconds();
    }

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.SOCIAL_MEDIA_POST_SCHEDULE,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
  }
};

export const trackSchedulePostViewEvent = (clipId: string) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    const eventData = {
      clipId,
      postViewFromLink: true,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.SOCIAL_MEDIA_POST_SCHEDULE,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
  }
};

export const trackManageSubscription = (event: string) => {
  if (isProd()) {
    const userAuth = store.getState().authState;
    const date = new Date();
    const subscriptionId = userAuth.userSubscription.subscriptionId;
    let eventData;
    switch (event) {
      case ANALYTICS_CONSTANTS.MANAGE_SUBSCRIPTION.PLAN_CHANGED:
        eventData = {
          planChanged: true,
          updatePaymentMethod: false,
          subscriptionResumed: false,
          subscriptionPaused: false,
          subscriptionCancelled: false,
        };
        break;
      case ANALYTICS_CONSTANTS.MANAGE_SUBSCRIPTION.SUBSCRIPTION_CANCELLED:
        eventData = {
          planChanged: false,
          subscriptionCancelled: true,
          updatePaymentMethod: false,
          subscriptionResumed: false,
          subscriptionPaused: false,
        };
        break;
      case ANALYTICS_CONSTANTS.MANAGE_SUBSCRIPTION.SUBSCRIPTION_PAUSED:
        eventData = {
          subscriptionPaused: true,
          updatePaymentMethod: false,
          subscriptionResumed: false,
          subscriptionCancelled: false,
          planChanged: false,
        };
        break;
      case ANALYTICS_CONSTANTS.MANAGE_SUBSCRIPTION.SUBSCRIPTION_RESUMED:
        eventData = {
          subscriptionResumed: true,
          updatePaymentMethod: false,
          subscriptionPaused: false,
          subscriptionCancelled: false,
          planChanged: false,
        };
        break;
      case ANALYTICS_CONSTANTS.MANAGE_SUBSCRIPTION.UPDATE_PAYMENT_METHOD:
        eventData = {
          updatePaymentMethod: true,
          subscriptionResumed: false,
          subscriptionPaused: false,
          subscriptionCancelled: false,
          planChanged: false,
        };
        break;
      default:
        break;
    }

    eventData = {
      ...eventData,
      date: getDateTime(date.getTime(), DATE_TIME.DATE),
      month: getMonthAsString(getDateTime(date.getTime(), DATE_TIME.MONTH)),
      year: getDateTime(date.getTime(), DATE_TIME.YEAR),
      hours: getDateTime(date.getTime(), DATE_TIME.HOUR),
      minutes: getDateTime(date.getTime(), DATE_TIME.MINUTE),
      seconds: getDateTime(date.getTime(), DATE_TIME.SECOND),
      subscriptionId,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid: userAuth.currentUser.uid,
      eventName: EVENTS.MANAGE_SUBSCRIPTION,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
    trackMoengageEvent(EVENTS.MANAGE_SUBSCRIPTION, eventData);
  }
};

export const trackProfileIconClickEvent = (event: string) => {
  if (isProd()) {
    const uid = store.getState().authState.currentUser.uid;
    let eventData;
    switch (event) {
      case ANALYTICS_CONSTANTS.PROFIL_ICON_CLICKED.ACCOUNT_DELETE:
        eventData = {
          accountDelete: true,
          nameChanged: false,
          passwordChanged: false,
        };
        break;
      case ANALYTICS_CONSTANTS.PROFIL_ICON_CLICKED.NAME_UPDATED:
        eventData = {
          accountDelete: false,
          nameChanged: true,
          passwordChanged: false,
        };
        break;
      case ANALYTICS_CONSTANTS.PROFIL_ICON_CLICKED.PASSWORD_CHANGE:
        eventData = {
          accountDelete: false,
          nameChanged: false,
          passwordChanged: true,
        };
        break;
      default:
        break;
    }

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid,
      eventName: EVENTS.PROFILE_ICON_CLICK,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
  }
};

export const trackAccountDelete = () => {
  if (isProd()) {
    const user = store.getState().authState.currentUser;
    const trackObj: AmplitudeTrackObject = {
      data: {
        emailId: user.email,
        userId: user.uid,
      },
      uid: user.uid,
      eventName: EVENTS.ACCOUNT_DELETE,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
  }
};

export const trackSubscribeNowButtonClicked = () => {
  if (isProd()) {
    const user = store.getState().authState.currentUser;
    const trackObj: AmplitudeTrackObject = {
      data: {},
      uid: user.uid,
      eventName: EVENTS.SUBSCRIBE_NOW_BUTTON_CLICK,
      type: EVENT_TYPE.TRACK,
    };
    trackAmplitudeEvents(trackObj);
  }
};

export const trackUTMParams = (data: any) => {
  const homeAnalytics = store.getState().amplitudeState.homeScreen;
  let utmDataObj: any = {};

  if (data.utmCampaign) {
    utmDataObj.utmCampaign = data.utmCampaign;
  }
  if (data.utmSource) {
    utmDataObj.utmSource = data.utmSource;
  }
  if (data.utmMedium) {
    utmDataObj.utmMedium = data.utmMedium;
  }
  if (data.utmTerm) {
    utmDataObj.utmTerm = data.utmTerm;
  }

  if (hasMoreThanKeys(utmDataObj, 0)) {
    store.dispatch(
      setHomeAnalytics({
        ...homeAnalytics,
        utmDataObj,
      })
    );
  }
};

export const trackEditorInteractionEvent = (
  key: string,
  value: string | boolean
) => {
  if (isProd()) {
    const user = store.getState().authState.currentUser;
    const currentSelectedMicroContent =
      store.getState().homeState.currentSelectedMicroContent;

    const trackObj: AmplitudeTrackObject = {
      data: {
        [key]: value,
        isDiscontinuousClip:
          currentSelectedMicroContent?.tag === INTELLI_CLIP_TAG,
      },
      uid: user.uid,
      eventName: EVENTS.EDITOR_INTERACTIONS,
      type: EVENT_TYPE.TRACK,
    };

    trackAmplitudeEvents(trackObj);
  }
};

export const AbTestingUserTrack = (experimentId: string[]) => {
  if (isProd()) {
    const { currentUser } = store.getState().authState;

    const eventData = {
      key: experimentId,
    };

    const trackObj: AmplitudeTrackObject = {
      data: eventData,
      uid: currentUser.uid,
      identifyType: IDENTIFY_TYPE.AB_TESTING_TRACK,
      type: EVENT_TYPE.IDENTIFY,
    };

    trackAmplitudeEvents(trackObj);
  }
};
