import { isProd } from "@/helpers";

const rdt = (window as any).rdt;

const EVENT_NAME = {
  NEW_USER_SIGNUP: "SignUp",
  NEW_SUBSCRIBER: "Purchase",
};

export const newUserSignupReddit = (user: any) => {
  if (isProd()) {
    try {
      rdt("track", EVENT_NAME.NEW_USER_SIGNUP, {
        transactionId: user.uid,
      });
    } catch (e) {
      console.debug("[reddit pixel error]: signup event ", e);
    }
  }
};

export const purchaseConversionTrackReddit = async (
  transactionId: any,
  value: any
) => {
  if (isProd()) {
    try {
      rdt("track", EVENT_NAME.NEW_SUBSCRIBER, {
        currency: "USD",
        transactionId,
        value,
      });
    } catch (e) {
      console.debug("[reddit pixel error]: purchase event ", e);
    }
  }
};
