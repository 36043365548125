import { useQuery } from "@tanstack/react-query";

import { getDraftById } from "@/api/requests";

export default function useGetDraftById(
  draftId: string,
  isRenderDraft = false,
  enabledQuery = false
) {
  return useQuery({
    queryKey: ["draft", draftId, isRenderDraft],
    queryFn: () => getDraftById(draftId, isRenderDraft),
    refetchOnWindowFocus: false,
    staleTime: 0,
    enabled: enabledQuery,
  });
}
