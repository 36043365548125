import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { Badge, Spinner, Tooltip } from "flowbite-react";

import { updateSelectedDraft } from "@/store/draftSlice";
import {
  changeSelectedLayout,
  setSubtitles,
  toggleSceneChange,
  updateAllSceneChanges,
  updateAutoAddEmojisToSubtitles,
} from "@/store/editorSlice";
import {
  updateBaseTemplates,
  updateCurrentSelectedMicroContent,
  updateCurrentSelectedProject,
  updateOnBoardingDataById,
  updateIsShortVideo,
  setCurrentSelectedClip,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { fetchSubtitles } from "@/api/useFetchSubtitles";
import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetUserTemplates from "@/api/useGetUserTemplates";
import useRetryProject from "@/api/useRetryVideo";
import useUpdateProject from "@/api/useUpdateProject";

import { ANALYTICS_CONSTANTS } from "@/constants/amplitude";
import { layoutsArray } from "@/constants/aspect-ratio";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import {
  formatVideoDuration,
  generateCloudflareImageUrl,
  getAllBaseTemplates,
  getRandomTemplateByLayout,
  saveValueToLocalStorage,
  updatedUserBaseTemplatesWithNewStyles,
  isProjectExpiringSoon,
  calculateProjectExpiryDateFormatted,
} from "@/helpers";

import { trackUserButtonEvent } from "@/utils/SegmentAnalytics";
import { eventsDataToRedux } from "@/utils/amplitudeAnalytcs";

import {
  PlanType,
  ProjectStatus,
  VideoDurationInMilliseconds,
  VideoLayout,
} from "@/enums";

import ExpSoonIcon from "@/components/Icons/ExpSoonIcon";

import { TitleChangeModal } from "@/views/home/components/TitleChangeModal";

import { ProjectDeleteModal } from "../ProjectDeleteModal";

import CancelIcon from "@/assets/icons/delete.svg";
import ErrorStatusIcon from "@/assets/icons/infoYellow.svg";
import ProcessingStatusIcon from "@/assets/icons/processing.svg";
import RetryIcon from "@/assets/icons/retry.svg";
import ReviewClipsIcon from "@/assets/icons/reviewClips.svg";
import ReadyStatusIcon from "@/assets/icons/successTick.svg";
import UploadStatusIcon from "@/assets/icons/uploading.svg";

const { REVIEW_CLIPS_PROJECT_CARD, PROJECT_CARD } = BUTTON_IDS.ASSET_CARD;

const defaultProcessingImage =
  "https://storage.googleapis.com/vidyo-ai-static/preload-images/default_processing_video.jpeg";

const renderStatus = (project: any) => {
  if (project?.isProcessingFailed) {
    return (
      <div>
        <div className="flex items-center space-x-1 mt-2">
          <p className="text-xs font-semibold text-red-600">Upload Failed</p>
        </div>

        <p className="text-red-400 text-[10px] mt-1.5">
          Something went wrong while uploading your video. Please try again.
        </p>
      </div>
    );
  }

  if (project?.opts?.id) {
    return (
      <div>
        <div className="flex items-center space-x-1 mt-2">
          <img
            alt="Uploading"
            src={UploadStatusIcon}
          />
          <p className="text-xs font-semibold text-blue-600">
            Uploading your video...
          </p>
        </div>

        <p className="text-yellow-400 text-[10px] mt-1.5">
          <span className="font-bold">Note:</span> Please do not close the
          browser or refresh the page
        </p>
      </div>
    );
  }

  if (project.isYoutubeLocal) {
    return (
      <div className=" flex items-center space-x-1">
        <img
          alt="Uploading"
          src={UploadStatusIcon}
        />
        <p className="text-xs font-semibold text-blue-600">Importing...</p>
      </div>
    );
  }
  switch (project.status) {
    case ProjectStatus.READY:
      return (
        <div className="flex items-center gap-x-1">
          <img
            alt="Success"
            src={ReadyStatusIcon}
          />
          <p className=" text-xs font-semibold text-green-500 !mr-2">Ready!</p>
        </div>
      );
    case ProjectStatus.ERROR:
      return (
        <div className="flex items-center space-x-1">
          <img
            alt="Error"
            src={ErrorStatusIcon}
          />
          <p className="text-xs font-semibold text-yellow-400">Error!</p>
        </div>
      );
    case ProjectStatus.OPEN:
      return (
        <div className="flex items-center space-x-1">
          <img
            alt="Processing"
            src={ProcessingStatusIcon}
          />
          <p className="text-xs font-semibold text-gray-600">Processing...</p>
        </div>
      );

    default:
      break;
  }
};

const renderCTA = (
  project: any,
  uploadPercentage: any,
  handelReviewClips: any,
  isShortVideo = false,
  isLoadingSubtitle = false,
  handleReupload = () => {}
) => {
  const onBoardingData = useAppSelector(
    (state) => state.homeState.onBoardingData
  );

  const { mutate: retryMutate, isLoading: retryIsLoading } = useRetryProject();

  const onCancel = () => {
    console.log(Object.values(onBoardingData));
    if (
      Object.values(onBoardingData).filter((p) => p.processingVideoData).length
    ) {
      project?.cancelAll();
    }
  };

  const handelRetryProject = () => {
    const remote_url = project.data && JSON.parse(project.data).remote_url;
    const yt_url = project.data && JSON.parse(project.data)?.yt_url;
    const lang = project.data && JSON.parse(project.data)?.lang;
    let body: any = {
      remote_url,
      project_id: project.id,
      lang: lang,
    };
    if (!remote_url && yt_url) {
      body = {
        ...body,
        yt_url,
      };
    }

    retryMutate(body);
  };

  if (project?.isProcessingFailed) {
    return (
      <button
        className="absolute top-20 left-1/2 hidden w-max -translate-x-1/2 -translate-y-1/2 transform items-center rounded-lg bg-red-500 px-3 py-2.5  text-center text-xs font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-4 focus:ring-red-300 group-hover:inline-flex"
        type="button"
        onClick={handleReupload}
        id="upload-again-project-card"
      >
        Upload again
      </button>
    );
  }

  if (project?.opts?.id && uploadPercentage < 100) {
    return (
      <button
        className="absolute top-20 left-1/2 hidden w-max -translate-x-1/2 -translate-y-1/2 transform items-center rounded-lg bg-red-500 px-3 py-2.5  text-center text-xs font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-4 focus:ring-red-300 group-hover:inline-flex"
        type="button"
        onClick={onCancel}
        id="cancel-upload-project-card"
      >
        <img
          className="mr-2"
          src={CancelIcon}
        />
        Cancel Upload
      </button>
    );
  }
  switch (project.status) {
    case ProjectStatus.READY:
      return (
        <button
          onClick={() => {
            trackUserButtonEvent(
              REVIEW_CLIPS_PROJECT_CARD.ID,
              REVIEW_CLIPS_PROJECT_CARD.ACTION
            );
            handelReviewClips(project);
          }}
          className="absolute top-20  left-1/2 hidden  w-max -translate-x-1/2 -translate-y-1/2 transform items-center rounded-lg bg-blue-600 px-3 py-2.5  text-center text-xs font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 group-hover:inline-flex"
          type="button"
          id={REVIEW_CLIPS_PROJECT_CARD.ID}
          disabled={isLoadingSubtitle}
        >
          {isLoadingSubtitle ? (
            <Spinner className="w-4 h-4 mr-2" />
          ) : (
            <img
              className="mr-2"
              src={ReviewClipsIcon}
            />
          )}

          {isShortVideo ? "Open in Editor" : "Review Clips"}
        </button>
      );
    case ProjectStatus.ERROR:
      return (
        <button
          disabled={retryIsLoading}
          className={`absolute top-20 left-1/2 hidden w-max -translate-x-1/2 -translate-y-1/2 transform items-center rounded-lg bg-white px-3 py-2.5  text-center text-xs font-medium text-black hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-300 group-hover:inline-flex ${
            retryIsLoading ? "cursor-progress opacity-80" : ""
          }`}
          type="button"
          onClick={handelRetryProject}
          id="retry-project-card"
        >
          {retryIsLoading ? (
            <div
              role="status"
              className="relative mx-auto mr-2 flex w-max"
            >
              <svg
                aria-hidden="true"
                className="h-4 w-4 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <img
              className="mr-2"
              src={RetryIcon}
            />
          )}
          Retry
        </button>
      );

    default:
      break;
  }
};

type AssetCardType = {
  project: any;
  localProjectID?: string;
  handelOpenVideoUploadModal?: any;
  pageOffset: number;
};

const VideoPreview = ({
  project,
  handelUpdateVideoDuration,
  handelReviewClips,
}: any) => {
  const parsedProjectData = project.data && JSON.parse(project.data);
  const isProjectReady = project?.status === ProjectStatus.READY;

  return (
    <div
      className={`relative h-44 w-full rounded-t-md border-b sm:h-36 ${
        isProjectReady && "cursor-pointer"
      }`}
      onClick={() => {
        if (isProjectReady) {
          handelReviewClips(project);
          trackUserButtonEvent(PROJECT_CARD.ID, PROJECT_CARD.ACTION);
        }
      }}
      id={PROJECT_CARD.ID}
    >
      {project.isYoutubeLocal ? (
        <img
          loading="lazy"
          className="h-44 w-full rounded-t-xl sm:h-36 object-contain"
          src={`http://img.youtube.com/vi/${project.video_id}/hqdefault.jpg`}
        />
      ) : project?.file ? (
        <video
          controls={false}
          className="h-44 w-full rounded-t-xl sm:h-36"
          src={URL.createObjectURL(project?.file)}
          onLoadedMetadata={(e: any) =>
            handelUpdateVideoDuration(e.target.duration * 1000)
          }
        />
      ) : (
        <img
          loading="lazy"
          className="h-44 w-full rounded-t-xl  sm:h-36 object-contain"
          src={generateCloudflareImageUrl({
            originalUrl:
              parsedProjectData?.thumbnail_url || defaultProcessingImage,
            imgOptions: {
              height: 200,
            },
          })}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src =
              parsedProjectData?.thumbnail_url || defaultProcessingImage;
          }}
        />
      )}
      {!isProjectReady && (
        <div className=" absolute inset-0 flex flex-col items-center justify-between rounded-t-md bg-black bg-opacity-70">
          {project.status === ProjectStatus.OPEN ? (
            <p className=" mt-12 px-4 text-center font-semibold text-white opacity-95">
              We'll update you on email once we finish processing{" "}
            </p>
          ) : null}
        </div>
      )}
    </div>
  );
};

const MemoizedVideoPreview = memo(VideoPreview);

function AssetCard({
  project,
  localProjectID,
  handelOpenVideoUploadModal,
  pageOffset,
}: AssetCardType) {
  const [videoDuration, setVideoDuration] = useState(0);
  const [isLoadingSubtitle, setIsLoadingSubtitle] = useState(false);
  const [displayTitleChangeModal, setTitleChangeModal] =
    useState<boolean>(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  if (project?.opts?.id && uploadPercentage === 0) {
    project?.on("upload-progress", (file: any, progress: any) => {
      setUploadPercentage((progress.bytesUploaded / progress.bytesTotal) * 100);
    });
  }

  const [isLoading] = useState(false);

  const [displayProjectDeleteModal, setProjectDeleteModal] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const parsedProjectData = project.data && JSON.parse(project.data);

  const projectStatusIntervalRef = useRef<any>(null);

  const [progressPercentage, setProgressPercentage] = useState(0);

  const onBoardingData = useAppSelector(
    (state) => state.homeState.onBoardingData
  );
  const uid = useAppSelector((state) => state.authState.currentUser.uid);
  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );

  const isFreeUser = planType === PlanType.FREE;

  const defaultBaseTemplates = useGetDefaultBaseTemplates();
  const userTemplatesData = useGetUserTemplates(uid);

  const { mutate: updateProject } = useUpdateProject();

  const isShortVideo =
    project.data &&
    parsedProjectData?.duration <= VideoDurationInMilliseconds.SHORT;

  const handelUpdatedUserPreferredBaseTemplates = (
    data: any,
    layout: string
  ) => {
    if (data.selectedLayouts?.includes(layout)) {
      const baseTemplates = getAllBaseTemplates(
        defaultBaseTemplates?.data,
        userTemplatesData?.data
      );
      const baseTemplateArray = baseTemplates[layout as VideoLayout];
      const preferredTemplateIDArray = data.baseTemplates[layout];

      const updatedBaseTemplates = baseTemplateArray?.map((template) => {
        let newTemplateObj = { ...template };
        if (preferredTemplateIDArray?.includes(template.id)) {
          newTemplateObj = {
            ...template,
            isPreferred: true,
          };
        }
        return newTemplateObj;
      });
      return updatedBaseTemplates;
    }

    return [];
  };

  const updatedUserSavedPreferencesData = (savedUserPrefData: any) => {
    if (!savedUserPrefData) return undefined;

    let newBaseTemplates = {};

    layoutsArray.forEach((layout) => {
      newBaseTemplates = {
        ...newBaseTemplates,
        [layout]: handelUpdatedUserPreferredBaseTemplates(
          savedUserPrefData,
          layout
        ),
      };
    });

    return { ...savedUserPrefData, baseTemplates: newBaseTemplates };
  };

  function checkIfUserSavedBaseTemplatesHasElement(obj: any) {
    for (let key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return true;
      }
    }
    return false;
  }

  const redirectToEditor = async (defaultTemplates: any) => {
    setIsLoadingSubtitle(true);

    const startTime = 0;
    const endTime =
      project.data && Math.floor(parsedProjectData?.duration || 0);
    const newMicroContent: any = {
      customClip: true,
      face_coord: [],
      hasTwoFace: false,
      gist: project?.title?.split(".")[0] || "Short Video",
      text: "",

      // update below values
      id: "",
      start: startTime,
      end: endTime,
      srt_string: "",
      imageUrl: "",
      chapter_end: endTime,
      chapter_start: startTime,
      isShortVideo,
      clip_url: "",
      tag: "",
    };

    try {
      const { data }: any = await fetchSubtitles({
        chars_per_caption: 25,
        project_id: project.id,
        start_ts: startTime,
        end_ts: endTime,
        clip: true,
      });
      newMicroContent.srt_string = data.srt_string || "";
    } catch {
      // could not fetch subtitles
    }
    setIsLoadingSubtitle(false);

    const layout = VideoLayout.LAYOUT_9_16_1;

    const template = getRandomTemplateByLayout(layout, defaultTemplates);
    newMicroContent.id = template.id;

    newMicroContent.imageUrl = project.data && parsedProjectData?.thumbnail_url;

    dispatch(updateCurrentSelectedMicroContent(newMicroContent));
    dispatch(changeSelectedLayout(layout));
    dispatch(updateCurrentSelectedProject(project));
    dispatch(updateIsShortVideo(true));

    const originalData = {
      ...parsedProjectData,
      is_visited: true,
    };
    const reqBody = {
      ...project,
      data: JSON.stringify(originalData),
    };
    !parsedProjectData?.is_visited && updateProject(reqBody);

    navigate(`/editor/${project.id}/templates`);
  };

  const handelReviewClips = async (project: any) => {
    const baseTemplates = getAllBaseTemplates(
      defaultBaseTemplates?.data,
      userTemplatesData?.data
    );

    const parsedProjectData = project.data && JSON.parse(project.data);
    const savedUserPreferences = updatedUserSavedPreferencesData(
      parsedProjectData.userPreferenceData
    );

    if (isShortVideo) {
      dispatch(updateSelectedDraft(null));
      dispatch(updateAutoAddEmojisToSubtitles(false));
      dispatch(toggleSceneChange(false));
      dispatch(updateAllSceneChanges([]));
      dispatch(
        setSubtitles({
          subsArr: [],
          subStart: 0,
        })
      );
      dispatch(updateAutoAddEmojisToSubtitles(false));
      redirectToEditor(baseTemplates);
      return;
    }

    dispatch(setCurrentSelectedClip(""));
    dispatch(updateCurrentSelectedMicroContent(null));
    dispatch(updateIsShortVideo(false));

    let data;

    const currentProjectOnBoardingData = Object.values(onBoardingData).filter(
      (p) => p.projectId === project.id
    );

    if (currentProjectOnBoardingData.length && !savedUserPreferences) {
      data = currentProjectOnBoardingData[0];
    } else {
      data = savedUserPreferences;
    }

    if (data && checkIfUserSavedBaseTemplatesHasElement(data.baseTemplates)) {
      // Temporary fix for new styles option,
      // Only needed for user who already have saved preferences in local storage
      const updatedBaseTemplates = updatedUserBaseTemplatesWithNewStyles(
        data.baseTemplates
      );
      dispatch(updateBaseTemplates(updatedBaseTemplates));
    } else {
      dispatch(updateBaseTemplates(baseTemplates));
    }

    parsedProjectData?.clip_count &&
      eventsDataToRedux(
        ANALYTICS_CONSTANTS.CLIP_COUNT,
        parsedProjectData.clip_count
      );
    navigate(`/home/project/${project.id}`);
    saveValueToLocalStorage("homePageOffset", pageOffset);
  };

  const handelUpdateVideoDuration = useCallback((val: number) => {
    setVideoDuration(val);
  }, []);

  useEffect(() => {
    if (project.data && parsedProjectData?.duration) {
      handelUpdateVideoDuration(parsedProjectData?.duration / 1000);
    }

    if (project.data && localProjectID) {
      dispatch(
        updateOnBoardingDataById({
          id: localProjectID,
          data: { processingVideoData: null },
        })
      );
    }
  }, [project, localProjectID]);

  const assumedProcessingTime = videoDuration && (videoDuration * 60) / 100;

  const updatedProgressPercentage = () => {
    const projectCreatedAt = new Date(project.createdAt);
    const timeElapsed = (Date.now() - projectCreatedAt.getTime()) / 1000;
    const percentage = (timeElapsed / assumedProcessingTime) * 100;

    if (percentage >= 0 && percentage <= 99) {
      setProgressPercentage(Math.round(percentage));

      // Explicitly update the percentage if is any random number not between 0-99 to not to show any wired UI progressbar percentage
      // in bellow if else statement
    } else if (percentage > 99) {
      setProgressPercentage(99);
    } else if (percentage < 0) {
      setProgressPercentage(0);
    }
  };

  // 30 % of total video duration as processing time

  useEffect(() => {
    if (
      videoDuration &&
      project.status === ProjectStatus.OPEN &&
      !projectStatusIntervalRef.current
    ) {
      progressPercentage || updatedProgressPercentage();
      const timer = setInterval(function () {
        updatedProgressPercentage();
      }, (assumedProcessingTime / 100) * 1000);
      projectStatusIntervalRef.current = timer;
    } else if (project.status !== ProjectStatus.OPEN) {
      projectStatusIntervalRef.current &&
        clearInterval(projectStatusIntervalRef.current);
      projectStatusIntervalRef.current = null;
    }
    return () => {
      projectStatusIntervalRef.current &&
        clearInterval(projectStatusIntervalRef.current);
      projectStatusIntervalRef.current = null;
    };
  }, [project.status, videoDuration]);

  useEffect(() => {
    const timeElapsed =
      (Date.now() - new Date(project.createdAt).getTime()) / 1000;

    if (
      videoDuration &&
      timeElapsed > videoDuration &&
      project.status === ProjectStatus.OPEN
    ) {
      console.warn("Project might be corrupted", project);
    }
  }, []);

  let ytVideoDetails: any;

  const handleTitleChange = () => {
    setTitleChangeModal(true);
  };

  const handleProjectDelete = () => {
    setProjectDeleteModal(true);
  };

  const handleReupload = () => {
    handelOpenVideoUploadModal();

    dispatch(
      updateOnBoardingDataById({
        id: localProjectID,
        data: { processingVideoData: null },
      })
    );
  };

  const showProjectExpiringSoonBadge = useMemo(() => {
    if (
      project?.status === ProjectStatus.READY &&
      isProjectExpiringSoon(project?.createdAt) &&
      isFreeUser
    ) {
      return true;
    }
    return false;
  }, [project?.createdAt, project.status, isFreeUser]);

  return (
    <>
      <div
        className={`relative ${
          project.status === ProjectStatus.READY &&
          !parsedProjectData.is_visited
            ? "assetCard_for_animation"
            : ""
        }`}
      >
        <article
          className={`group relative rounded-xl border bg-white shadow-md dark:border-gray-700 dark:bg-gray-800`}
        >
          <MemoizedVideoPreview
            project={project}
            handelUpdateVideoDuration={handelUpdateVideoDuration}
            handelReviewClips={handelReviewClips}
          />
          <div
            className={`absolute z-40 -mt-2.5 h-3 w-full bg-gray-200 ${
              project.status === ProjectStatus.OPEN ||
              project?.opts?.id ||
              project.isYoutubeLocal
                ? "block"
                : "invisible"
            } ${progressPercentage <= 0 && !project?.opts?.id ? "hidden" : ""}`}
          >
            <div
              className={` ${
                project?.opts?.id
                  ? "bg-blue-500"
                  : project.status === ProjectStatus.OPEN
                  ? "bg-yellow-300"
                  : "bg-blue-500"
              } h-3`}
              style={{
                width: project?.opts?.id
                  ? `${uploadPercentage}%`
                  : `${progressPercentage}%`,
              }}
            />

            <p
              className={`-mt-3.5 ml-[88%] text-xs font-semibold text-gray-900 `}
            >
              {project?.opts?.id
                ? Math.round(uploadPercentage || 0)
                : progressPercentage}
              %
            </p>
          </div>
          {project?.opts?.id || project?.isProcessingFailed ? null : (
            <div className="mt-4 flex items-center justify-between px-2">
              <p className="truncate text-sm font-semibold text-black">
                {project.title || project.asset_name || ytVideoDetails?.title}
              </p>
              <p className={`pl-2 text-sm text-gray-500 `}>
                {project.isYoutubeLocal
                  ? formatVideoDuration(project?.duration || 0)
                  : formatVideoDuration(
                      project.data ? parsedProjectData?.duration : videoDuration
                    )}
              </p>
            </div>
          )}

          {isShortVideo && (
            <Badge className=" absolute top-3 left-3 text-blue-600">
              <span className="py-0.5 block">Short Video</span>
            </Badge>
          )}

          <div className=" my-3 mb-4 px-2 flex">
            {renderStatus(project)}

            {showProjectExpiringSoonBadge ? (
              <Tooltip
                placement="top"
                content={`Project expires on ${calculateProjectExpiryDateFormatted(
                  project?.createdAt
                )}`}
              >
                <ExpSoonIcon
                  className="opacity-90"
                  id="expiring-soon-badge-old-ui"
                />
              </Tooltip>
            ) : null}
          </div>

          {renderCTA(
            project,
            uploadPercentage,
            handelReviewClips,
            isShortVideo,
            isLoadingSubtitle,
            handleReupload
          )}
        </article>
        {project?.opts?.id ||
        project.isYoutubeLocal ||
        project?.isProcessingFailed ? null : (
          <div className="absolute bottom-2 right-3 w-40 text-right">
            <Menu
              as="div"
              className="relative inline-block text-left"
            >
              <div>
                <Menu.Button
                  className={`inline-flex w-full justify-center rounded-md bg-gray-50 ${
                    isLoading ? "px-1.5 py-1.5" : "px-2.5 py-1.5"
                  } text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  {isLoading ? (
                    <div
                      role="status"
                      className="relative mx-auto flex w-max"
                    >
                      <svg
                        aria-hidden="true"
                        className="h-4 w-4 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <svg
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 29.96 122.88"
                      className="w-1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z"
                      />
                    </svg>
                  )}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-20 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-blue-500 text-white" : "text-gray-600"
                          } flex w-full cursor-pointer items-center rounded-md px-2 py-2 text-base`}
                          onClick={handleTitleChange}
                          id="user-menu-change-name"
                        >
                          <PencilSquareIcon className="mr-1.5 h-5 w-5" />
                          <p className="cursor-pointer text-sm font-medium focus:outline-none">
                            Edit title
                          </p>
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-blue-500 text-white" : "text-red-500"
                          } flex w-full cursor-pointer items-center rounded-md px-2 py-1.5 text-base`}
                          onClick={handleProjectDelete}
                          id={`delete-project-card-${project.id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="mr-1.5 h-4 w-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                          <span>Delete video</span>
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}
      </div>
      <TitleChangeModal
        displayTitleChangeModal={displayTitleChangeModal}
        setTitleChangeModal={setTitleChangeModal}
        projectTitle={project?.title}
        project={project}
      />
      <ProjectDeleteModal
        setProjectDeleteModal={setProjectDeleteModal}
        displayProjectDeleteModal={displayProjectDeleteModal}
        projectId={project.id}
      />
    </>
  );
}

export default AssetCard;
