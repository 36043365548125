// hook to set the visibilty of the zendesk plugin on routes
import React from "react";
import { useLocation } from "react-router-dom";

import { zE } from "@/helpers";

export const useManageZendeskPlugin = () => {
  const { pathname } = useLocation();
  const styleRef = React.useRef<HTMLStyleElement | null>(null);

  React.useEffect(() => {
    if (pathname.includes("/editor")) {
      zE("webWidget", "show");
      // inject style tag in document to hide the #launcher element
      styleRef.current = document.createElement("style");
      const styles = `
            #launcher {
                display: none;
            }
            #webWidget {
                bottom: 0 !important;
                left: 0 !important;
            }
        `;
      styleRef.current.innerHTML = styles;
      document.head.appendChild(styleRef.current);
    } else {
      // remove style tag from document
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
        styleRef.current = null;
      }
    }
  }, [pathname]);
};
