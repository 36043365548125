import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useBoolean } from "react-use";

import { XMarkIcon } from "@heroicons/react/20/solid";

// import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { toggleTopBanner, toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useUserConsumedProcessingTime from "@/api/useUserConsumedProcessingTime";

import { ANALYTICS_CONSTANTS } from "@/constants/amplitude";

import {
  calculateRemainingProcessingTime,
  checkIfProcessingHourisBellowThresholdLimit,
  getValueFromLocalStorage,
  saveValueToLocalStorage,
  secondsToHm,
} from "@/helpers";

import { eventsDataToRedux } from "@/utils/amplitudeAnalytcs";

import { PlanType, RouterPath } from "@/enums";

import InfoIconDanger from "@/assets/icons/infoDanger.svg";
import InfoIcon from "@/assets/icons/infoWhite.svg";
import NewVidyoLogo from "@/assets/icons/new-vidyo-logo.png";

type BannerVariant = "publishClips" | "demoLink" | "sub-error" | "outrage";

interface bannerProps {
  bannerVariant?: BannerVariant;
  bannerText?: string;
  isClosable?: boolean;
}

const Banner = ({
  bannerVariant,
  bannerText,
  isClosable = true,
}: bannerProps) => {
  const navigate = useNavigate();

  const showTopBanner = useAppSelector(
    (state) => state.homeState.topBannerVisible
  );

  const currentUserSubscriptionDetails = useAppSelector(
    (state) => state.authState.userSubscription
  );

  const dispatch = useAppDispatch();

  const handelShowUpgradeModal = () => {
    dispatch(toggleUpgradeToProModal(true));
    eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
  };

  const handelToggleTopBanner = (state: boolean) => {
    dispatch(toggleTopBanner(state));
  };

  // const ishideBanner = getValueFromLocalStorage("hideScheduleBanner");
  const ishideDemoBanner = getValueFromLocalStorage("hideDemoBanner");

  const [hideBanner, setHideBanner] = useBoolean(ishideDemoBanner);

  const { data: usageData } = useUserConsumedProcessingTime();

  const closeBanner = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    saveValueToLocalStorage("hideDemoBanner", true);
    setHideBanner(true);
  };

  useEffect(() => {
    if (currentUserSubscriptionDetails) {
      if (currentUserSubscriptionDetails.planType !== PlanType.FREE) {
        handelToggleTopBanner(false);
      } else {
        if (!showTopBanner) {
          handelToggleTopBanner(true);
        }
      }
    }
  }, [currentUserSubscriptionDetails.planType]);

  if (bannerVariant === "publishClips") {
    if (!hideBanner) {
      return (
        <div
          id="banner"
          className="z-50 flex h-[3rem] w-full items-start justify-center gap-8 bg-blue-500 px-4 dark:border-gray-700 dark:bg-gray-800 sm:items-center relative cursor-pointer"
          onClick={() => navigate(RouterPath.DOWNLOADS)}
        >
          <div className="flex items-start justify-center">
            <p className="ml-2 text-sm font-normal text-white text-center ">
              Schedule and Publish posts directly to 6 social platforms from
              vidyo.ai! Click on downloads to learn more
            </p>
            <span
              className="absolute right-[20px] cursor-pointer"
              onClick={closeBanner}
            >
              <XMarkIcon className="h-5 w-5 text-white" />
            </span>
          </div>
        </div>
      );
    }
    return null;
  }

  if (bannerVariant === "demoLink") {
    return (
      <div
        id="banner"
        className="z-50 flex w-full items-start justify-between p-4 dark:border-gray-700 dark:bg-gray-800 sm:items-center relative"
        style={{ background: "#943DEC" }}
      >
        <div className="flex gap-3 items-center text-white pl-2">
          <img
            className="h-4"
            src={NewVidyoLogo}
            alt="New Vidyo Logo"
          />
          <div className="flex flex-col gap-0.5">
            <p className="text-sm font-semibold">
              A new look for vidyo.ai is here: Improved interface, AI companion
              and a lot more.
            </p>
            <p className="text-xs">
              Heads up: Older version will be phased out on 1st May
            </p>
          </div>
        </div>
        <a
          href="https://app.vidyo.ai"
          target="_self"
          rel="noreferrer"
          className="w-[104px] h-8 flex items-center justify-center text-xs font-medium text-white bg-[#252521] rounded-lg mr-2"
          id="try-now-button-v2"
        >
          Try now
        </a>
      </div>
    );
  }

  if (bannerVariant === "sub-error") {
    return (
      <div
        id="banner"
        className="z-50 flex h-[3rem] w-full items-start justify-center gap-8 bg-blue-500 px-4 dark:border-gray-700 dark:bg-gray-800 sm:items-center"
      >
        <div className="flex items-start justify-center">
          <p className="text-sm font-light text-white text-center">
            A bug is affecting the subscription update for a small set of users.
            We are actively working on a fix.
          </p>
        </div>
      </div>
    );
  }

  if (bannerVariant === "outrage") {
    return (
      <div
        id="banner"
        className="z-50 flex h-[3rem] w-full items-start justify-center gap-8 bg-blue-500 px-4 dark:border-gray-700 dark:bg-gray-800 sm:items-center"
      >
        <div className="flex items-start justify-center">
          <p className="ml-2 text-sm font-light text-white text-center w-[85%]">
            Please be aware that our website will undergo scheduled maintenance{" "}
            <span className="font-extrabold text-[16px]">
              from Feb 02, 2024, 23:30 to Feb 02, 2024, 23:45 PST.{" "}
            </span>
            During this period, some features may not be available. We apologize
            for any inconvenience and thank you for your patience and
            understanding.
          </p>
        </div>
      </div>
    );
  }

  if (
    usageData !== undefined && // new users won't see red banner
    showTopBanner &&
    currentUserSubscriptionDetails.planType === PlanType.FREE
  ) {
    if (checkIfProcessingHourisBellowThresholdLimit(usageData)) {
      return (
        <div
          id="banner"
          className="z-50 flex h-[3rem] w-full items-start justify-center gap-8 bg-blue-600 px-4 dark:border-gray-700 dark:bg-gray-800 sm:items-center"
        >
          <div className="flex items-center">
            <img
              src={InfoIcon}
              alt="Info"
            />
            <p className="ml-2 text-sm font-light text-white">
              {`You’ve ${secondsToHm(
                calculateRemainingProcessingTime(usageData)
              )} of processing time remaining on your trial!`}
            </p>
            <button
              onClick={handelShowUpgradeModal}
              id="top-info-banner-upgrade-to-pro-button"
              type="button"
              className="mr-2 ml-6 rounded-lg border border-gray-200 py-1.5 px-5 text-xs font-medium text-white hover:bg-gray-50 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-2  focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
            >
              Upgrade for Unlimited
            </button>
          </div>
        </div>
      );
    }
    if (!checkIfProcessingHourisBellowThresholdLimit(usageData)) {
      return (
        <div className="z-50 flex h-[3rem] w-full items-start justify-center gap-8 bg-red-100 px-4 dark:bg-red-100 sm:items-center">
          <div className="flex items-center">
            <img
              src={InfoIconDanger}
              alt="Info"
            />
            <p className="ml-2 text-sm font-light text-red-600">
              {`You’ve ${secondsToHm(
                calculateRemainingProcessingTime(usageData)
              )} of processing time remaining on your trial!`}
            </p>
            <button
              onClick={handelShowUpgradeModal}
              id="top-danger-banner-upgrade-to-pro-button"
              type="button"
              className="mr-2 ml-6 rounded-lg  border border-red-500 py-1.5 px-5 text-xs font-medium text-red-600 hover:bg-gray-50 hover:text-red-700 focus:z-10 focus:outline-none focus:ring-2  focus:ring-red-300 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
            >
              Upgrade for Unlimited
            </button>
          </div>
        </div>
      );
    }
  }

  return <></>;
};

export default Banner;
