import * as React from "react";

import {
  createExponentialMappingFunction,
  createInverseExponentialMappingFunction,
} from "@/helpers";

export const useTimelineZoomSlider = () => {
  const [zoomSliderValue, setZoomSliderValue] = React.useState(1);
  const [pxPerSec, setPxPerSec] = React.useState(1);
  const modifiedFromSliderRef = React.useRef(false);
  const fitToScreenFunctionRef = React.useRef<VoidFunction | null>(null);
  const scaleValue = createExponentialMappingFunction([0, 100], [1, 200]);
  const descaleValue = createInverseExponentialMappingFunction(
    [0, 100],
    [1, 200]
  );

  const handleStepDecrease = () => {
    modifiedFromSliderRef.current = true;
    if (zoomSliderValue === 0) return;
    const value = zoomSliderValue - 1;
    setZoomSliderValue(value);
    const scaledValue = scaleValue(value);
    setPxPerSec(scaledValue);
  };

  const handleStepIncrease = () => {
    modifiedFromSliderRef.current = false;
    if (zoomSliderValue === 100) return;
    const value = zoomSliderValue + 1;
    setZoomSliderValue(value);
    const scaledValue = scaleValue(value);
    setPxPerSec(scaledValue);
  };

  const handleSliderChange = (rawValue: string) => {
    modifiedFromSliderRef.current = true;
    const value = parseFloat(rawValue);
    setZoomSliderValue(value);
    const scaledValue = scaleValue(value);
    setPxPerSec(scaledValue);
  };
  const setBasePxPerSex = (value: number) => {
    const descaledValue = descaleValue(value);
    setPxPerSec(value);
    setZoomSliderValue(descaledValue);
  };

  return {
    handleStepDecrease,
    handleStepIncrease,
    handleSliderChange,
    zoomSliderValue,
    pxPerSec,
    setBasePxPerSex,
    fitToScreenFunctionRef,
    modifiedFromSliderRef,
  };
};
