import { useEffect, useState } from "react";

import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import { Button, Spinner } from "flowbite-react";
import { nanoid } from "nanoid";

import {
  toggleShowDownloadPreferenceModal,
  toggleShowExportingModal,
  updateCurrentRenderId,
  updateExportQuality,
  updateIsRenderingVideo,
} from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useAddDraft from "@/api/useAddDraft";

import { EXPORT_PREFERENCE } from "@/constants/amplitude";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import {
  generateDraftName,
  getDefaultExportQuality,
  replaceUndefinedWithNull,
} from "@/helpers";

import { trackRenderClipEvent } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { ExportQuality, PlanType } from "@/enums";

import BaseModal from "@/components/BaseModal";
import Protick from "@/components/Protick";

const { DOWNLOAD_CONFIRM_BTN } = BUTTON_IDS.DOWNLOAD_PREFERENCE_MODAL;

type DownloadPreferenceModalProps = {
  videoWidth: number;
  videoHeight: number;
  handelExportingVideo?: () => void;
  isDownloadLoading?: boolean;
  resetDownloadOptions?: () => void;
  getDraftsProperties: any;
};

const DownloadPreferenceModal = ({
  videoWidth,
  videoHeight,
  handelExportingVideo,
  isDownloadLoading,
  resetDownloadOptions,
  getDraftsProperties,
}: DownloadPreferenceModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { selectedEditorDraft } = useAppSelector((state) => state.draftState);

  const showDownloadPreferenceModal = useAppSelector(
    (state) => state.editorState.showDownloadPreferenceModal
  );
  const exportQuality = useAppSelector(
    (state) => state.editorState.exportQuality
  );

  const showExportingModal = useAppSelector(
    (state) => state.editorState.showExportingModal
  );

  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const isRenderingVideo = useAppSelector(
    (state) => state.editorState.isRenderingVideo
  );

  const dispatch = useAppDispatch();

  const getExportQuality = () => {
    return getDefaultExportQuality({
      width: videoWidth,
      height: videoHeight,
    });
  };

  const handleCloseDownloadPreferenceModal = () => {
    dispatch(toggleShowExportingModal(false));
    dispatch(toggleShowDownloadPreferenceModal(false));
  };

  const handleUpdatedExportQuality = (e: any) => {
    dispatch(updateExportQuality(e.target.value));
  };

  const saveDraftMutationSuccessCB = () => {
    // dispatch(updateIsRenderingVideo(true));
  };

  const { mutate: addDraft, isLoading: addDraftLoading } = useAddDraft(
    saveDraftMutationSuccessCB,
    true
  );

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const showSpinner = () => {
    if (handelExportingVideo) {
      return isDownloadLoading;
    }
    return isRenderingVideo && !showExportingModal;
  };

  const handleSaveDraftBeforeDownload = async () => {
    setIsLoading(true);
    try {
      const newDraftId = nanoid();
      dispatch(updateCurrentRenderId(newDraftId));
      dispatch(updateIsRenderingVideo(true));

      const generatedDraftObject = await getDraftsProperties();
      let draftData = replaceUndefinedWithNull(generatedDraftObject);

      setIsLoading(false);

      draftData = {
        ...draftData,
        id: selectedEditorDraft?.id || draftData.id,
        name: generateDraftName(""),
        updatedAt: new Date(),
      };

      // resetting the id to create a new draft for each download
      draftData.id = newDraftId;

      addDraft({
        projectId: draftData?.project?.id,
        draft_id: draftData.id,
        data: {
          ...draftData,
          createdAt: new Date(),
          clipId: currentSelectedMicroContent?.clipId,
        },
      });

      trackRenderClipEvent(EXPORT_PREFERENCE.DOWNLOAD, draftData);
    } catch (error) {
      setIsLoading(false);
      console.log("Save Draft Error: ", error);
      showNotification("Failed to download video", notificationType.FAIL);
    }
  };

  useEffect(() => {
    if (showDownloadPreferenceModal) {
      dispatch(updateExportQuality(getExportQuality()));
    }
  }, [showDownloadPreferenceModal]);

  //checking is the current clip is greater than 10 minutes (600000 milliseconds)
  const isLongClip = (currentSelectedMicroContent: any) =>
    currentSelectedMicroContent.end - currentSelectedMicroContent.start >
    600000;

  return (
    <BaseModal
      isModalOpen={showDownloadPreferenceModal}
      handelModalOpen={() => {}}
      notClosable
    >
      {showDownloadPreferenceModal && (
        <div className="bg-white p-6 py-10 sm:w-[30rem]">
          {showExportingModal ? (
            <>
              <p className="mb-2 text-center text-3xl">🎉</p>
              <p className=" mb-8 text-center text-2xl font-bold text-gray-900">
                Your video is exporting!
              </p>

              <div className="mx-auto mb-5 w-fit">
                <p className="text-center font-light text-gray-500">
                  You can find your final video in the{" "}
                  <span className="font-bold">Home {">"} Downloads</span>{" "}
                  section. You can close this window and continue working on
                  more videos.
                </p>
              </div>

              {isLongClip(currentSelectedMicroContent) && (
                <div className="mx-auto mb-7 p-1 w-fit border rounded-md border-[#EDC200] bg-[#FEF6DF]  text-black ">
                  <p className="text-xs text-center">
                    Be patient, your selected clip duration is longer than usual
                    and might take extra time to download.
                  </p>
                </div>
              )}

              <div className="flex w-full justify-center">
                {/* <button
                  onClick={() => {
                    resetDownloadOptions && resetDownloadOptions();
                    handleCloseDownloadPreferenceModal();
                  }}
                  type="button"
                  id="go-to-downloads-page"
                  className="mr-4 inline-flex w-40 items-center justify-center rounded-lg border border-gray-500 bg-white text-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-4 focus:ring-gray-300"
                >
                  <Link
                    className="h-full w-full px-5 py-2.5"
                    to={RouterPath.DOWNLOADS}
                  >
                    Go to Downloads
                  </Link>
                </button> */}

                <Button
                  onClick={handleCloseDownloadPreferenceModal}
                  id="done-download-preference"
                  className="w-40"
                >
                  Done
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="mb-10 text-center text-2xl font-bold text-gray-900">
                Select export preference
              </p>

              <div className="mx-auto mb-12 w-fit">
                <div className="mb-4 flex items-center">
                  <input
                    onChange={handleUpdatedExportQuality}
                    id="radio-720p-download"
                    type="radio"
                    value={ExportQuality.HD}
                    name="download-preference-radio"
                    tabIndex={exportQuality === ExportQuality.HD ? 0 : -1} // hack for initial focus headless ui
                    checked={exportQuality === ExportQuality.HD}
                    className="h-5 w-5 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                  />
                  <label
                    htmlFor="radio-720p-download"
                    className="ml-2  text-gray-600 dark:text-gray-300"
                  >
                    720p
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    disabled={planType === PlanType.FREE}
                    onChange={handleUpdatedExportQuality}
                    id="radio-1080p-download"
                    type="radio"
                    value={ExportQuality.FHD}
                    checked={exportQuality === ExportQuality.FHD}
                    name="download-preference-radio"
                    className="h-5 w-5 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                  />
                  <label
                    htmlFor="radio-1080p-download"
                    className={`ml-2  mr-3 text-gray-600 dark:text-gray-300 ${
                      planType !== PlanType.FREE ? "" : "opacity-50"
                    }`}
                  >
                    1080p
                  </label>
                  <Protick planType={PlanType.PRO} />
                </div>
              </div>

              {planType !== PlanType.FREE && (
                <div className="flex flex-col gap-2 p-2 border-blue-300 bg-blue-200 border rounded-md text-black w-[80%] mx-auto my-4  mb-8">
                  <div className="flex gap-5 items-center">
                    <RocketLaunchIcon className="h-8" />
                    <div>
                      <div className="text-xs">
                        As as a Pro user, you can now edit your video after
                        downloading from the Downloads page.
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex w-full justify-center space-x-6">
                <Button
                  color="gray"
                  onClick={handleCloseDownloadPreferenceModal}
                  id="download-modal-cancel-btn"
                  className="w-40"
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  id={DOWNLOAD_CONFIRM_BTN.ID}
                  disabled={isRenderingVideo && !showExportingModal}
                  className="w-40"
                  onClick={() => {
                    handleSaveDraftBeforeDownload();
                  }}
                >
                  {isLoading || showSpinner() || addDraftLoading ? (
                    <div className="flex">
                      <Spinner
                        size="sm"
                        light={true}
                        className="mr-2 -mt-0.5"
                      />
                      Confirm
                    </div>
                  ) : (
                    <>Confirm</>
                  )}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </BaseModal>
  );
};

export default DownloadPreferenceModal;
