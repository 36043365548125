import { EditorCommandType } from "@/constants/editor-commands";

import { ProgressBarPosition } from "@/interfaces";

import { createCommand, Command } from "@/views/editor/EditorHistoryReducer";

export const addRemoveProgressBarCommand = (
  metaData: {
    newProgressbarHeight: number;
    oldProgressbarHeight: number;
    isProgressBarActive: boolean;
  },
  toggleProgressBar: (
    isProgressBarActive: boolean,
    progressbarHeight: number
  ) => void
): Command => {
  const { newProgressbarHeight, oldProgressbarHeight, isProgressBarActive } =
    metaData;
  return createCommand({
    executeAction: () =>
      toggleProgressBar(isProgressBarActive, newProgressbarHeight),
    undoAction: () =>
      toggleProgressBar(!isProgressBarActive, oldProgressbarHeight),
    commandType: EditorCommandType.ToggleProgressbar,
  });
};

export const changeProgressBarPositionCommand = (
  metaData: {
    newPosition: ProgressBarPosition;
    oldPosition: ProgressBarPosition;
  },
  changeProgressBarPosition: (position: ProgressBarPosition) => void
): Command => {
  const { newPosition, oldPosition } = metaData;
  return createCommand({
    executeAction: () => changeProgressBarPosition(newPosition),
    undoAction: () => changeProgressBarPosition(oldPosition),
    commandType: EditorCommandType.ChangeProgressbarPosition,
  });
};

export const changeProgressBarColorCommand = (
  metaData: {
    newColorAsHex: string;
    oldColorAsHex: string;
  },
  changeProgressBarColor: (color: string) => void
): Command => {
  const { newColorAsHex, oldColorAsHex } = metaData;
  return createCommand({
    executeAction: () => changeProgressBarColor(newColorAsHex),
    undoAction: () => changeProgressBarColor(oldColorAsHex),
    commandType: EditorCommandType.ChangeProgressbarColor,
  });
};

export const changeProgressBarHeightCommand = (
  metaData: {
    newHeight: number;
    oldHeight: number;
  },
  changeProgressBarColor: (height: number) => void
): Command => {
  const { newHeight, oldHeight } = metaData;
  return createCommand({
    executeAction: () => changeProgressBarColor(newHeight),
    undoAction: () => changeProgressBarColor(oldHeight),
    commandType: EditorCommandType.ChangeProgressbarHeight,
  });
};
