/* tslint:disable */

/* eslint-disable */
// @ts-nocheck

/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull react-app'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 7
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/vidyoai/vidyoai/implementation/react-app)
 */
import * as amplitude from "@amplitude/analytics-browser";

export type Environment = "default";

export const ApiKey: Record<Environment, string> = {
  default: "f4198062250430b00d2519f61f424e71",
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: "7",
    branch: "main",
    source: "react-app",
    versionId: "abe2765b-3780-4ca4-852f-483399d68b01",
  },
  ...{
    ingestionMetadata: {
      sourceName: "browser-typescript-ampli",
      sourceVersion: "2.0.0",
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: BrowserOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  currentPlan?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentPlanEndDate?: number;
  currentPlanEndMonth?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentPlanEndYear?: number;
  currentPlanFrequency?: string;
  emailId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  exportSocialAccountsLinked?: any[];
  isPaidUser?: boolean;
  isSubscriptionCancelled?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  minutesAdded?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  minutesAvailable?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  minutesBalance?: number;
  personaOnboarding?: string;
  planFrequency?: string;
  previousPlan?: string;
  previousPlanFrequency?: string;
  referrer?: string;
  referring_domain?: string;
  signupSource?: string;
  signupTimeStamp?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalMinutesUsed?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalSpend?: number;
  userName?: string;
  userPersona?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
}

export interface AddMoreMinutesClickProperties {
  screenName?: any;
  source?: string;
}

export interface AddMoreMinutesProperties {
  /**
   * amount charged to the user
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amountCharged?: number;
  /**
   * FREE, Starter, PRO, PRO+
   */
  currentPlan?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  minutesAdded?: number;
}

export interface ClickStartImportProperties {
  videoSourcePlatform?: any;
}

export interface CreateCustomClipProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  customClipEnd?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  customClipLength?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  customClipStart?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | chapter, short |
   */
  customClipType?: "chapter" | "short";
  overviewChanged?: any;
  overviewType?: any;
  videoDuration?: any;
  videoId?: any;
  videoName?: any;
  videoSourcePlatform?: any;
}

export interface DeleteClipProperties {
  clipDuration?: any;
  clipId?: any;
  clipName?: any;
  isDiscontinuousClip?: boolean;
  overviewChanged?: any;
  overviewType?: any;
  screenName?: any;
  templateId?: any;
  videoDuration?: any;
  videoId?: any;
  videoName?: any;
  videoSourcePlatform?: any;
}

export interface EditDownloadVideoClickedProperties {
  algorithmType?: string;
  aspectRatio?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  clipCount?: number;
  clipDuration?: any;
  clipId?: any;
  clipName?: any;
  favouritesAdd?: any;
  isDiscontinuousClip?: boolean;
  overviewChanged?: any;
  overviewType?: any;
  screenName?: any;
  templateId?: any;
  videoDuration?: any;
  videoId?: any;
  videoName?: any;
}

export interface EnableCutMagicProperties {
  clipDuration?: any;
  clipId?: any;
  clipName?: any;
  isDiscontinuousClip?: boolean;
  overviewType?: any;
  videoDuration?: any;
  videoId?: any;
  videoName?: any;
  videoSourcePlatform?: any;
}

export interface ExportClipProperties {
  aiCaptions?: any;
  aspectRatio?: any;
  autoAddEmoji?: any;
  brandkitBackground?: any;
  brandkitOutros?: any;
  brollImageId?: any;
  bRollImageUsed?: boolean;
  brollVideoId?: any;
  bRollVideoUsed?: boolean;
  captionGenerated?: any;
  clipDuration?: any;
  clipId?: any;
  clipName?: any;
  cutmagicEnabled?: any;
  downloadSRT?: any;
  exportDescription?: string;
  exportPlatform?: any;
  exportPreference?: any;
  fontSettingAccessed?: any;
  fontUploaded?: any;
  isDiscontinuousClip?: boolean;
  /**
   * Editor media tab > pexel media searched
   */
  mediaSearched?: boolean;
  overviewChanged?: any;
  overviewType?: any;
  socialLogoAdded?: any;
  socialLogos?: any;
  socialPreviewAccessed?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  stockAudioIds?: string[];
  stockBackground?: any;
  stockOutroId?: any;
  subtitleEffect?: any;
  subtitleFontSize?: any;
  subtitlesRemove?: any;
  subtitleStyle?: any;
  subtitleStyleClick?: any;
  subtitleStyleColor?: string;
  templateId?: any;
  textEffects?: any;
  textEffectsAccessed?: any;
  uploadedAudio?: boolean;
  uploadedBackground?: any;
  uploadedLogo?: any;
  uploadedOutroImage?: any;
  uploadedOutroVideo?: any;
  videoDuration?: any;
  videoId?: any;
  videoName?: any;
  videoSourcePlatform?: any;
}

export interface FinishOnBoardingProperties {
  personaOnboarding?: any;
  socialAccounts?: any;
  teamPlayer?: any;
  userGoals?: any;
}

export interface MediaClickProperties {
  screenName?: any;
}

export interface RenameClipProperties {
  clipDuration?: any;
  clipId?: any;
  clipName?: any;
  isDiscontinuousClip?: boolean;
  overviewChanged?: any;
  overviewType?: any;
  screenName?: any;
  templateId?: any;
  updatedClipName?: string;
  videoDuration?: any;
  videoId?: any;
  videoName?: any;
  videoSourcePlatform?: any;
}

export interface ReviewClipsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  clipCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  discontinuousClipCount?: number;
  overviewType?: any;
  videoDuration?: any;
  videoId?: any;
  videoName?: any;
}

export interface ReviewScreenInteractionsProperties {
  aiCaptionClicked?: boolean;
  aiCaptions?: any;
  captionGenerated?: any;
  clipDuration?: any;
  clipId?: any;
  clipName?: any;
  isDiscontinuousClip?: boolean;
  transcriptClick?: boolean;
  transcriptCopied?: boolean;
  videoTimestampClick?: boolean;
  videoTimestampCopied?: boolean;
}

export interface SaveDraftProperties {
  aiCaptions?: any;
  aspectRatio?: any;
  autoAddEmoji?: any;
  brandkitBackground?: any;
  brandkitOutros?: any;
  brollImageId?: any;
  bRollImageUsed?: boolean;
  brollVideoId?: any;
  bRollVideoUsed?: boolean;
  captionGenerated?: any;
  clipDuration?: any;
  clipId?: any;
  clipName?: any;
  cutmagicEnabled?: any;
  downloadSRT?: any;
  fontSettingAccessed?: any;
  fontUploaded?: any;
  isDiscontinuousClip?: boolean;
  /**
   * Editor media tab > pexel media searched
   */
  mediaSearched?: boolean;
  newDraft?: any;
  overviewChanged?: any;
  overviewType?: any;
  socialLogoAdded?: any;
  socialLogos?: any;
  socialPreviewAccessed?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  stockAudioIds?: string[];
  stockBackground?: any;
  stockOutroId?: any;
  subtitleEffect?: any;
  subtitleFontSize?: any;
  subtitlesRemove?: any;
  subtitleStyle?: any;
  subtitleStyleClick?: any;
  subtitleStyleColor?: string;
  templateId?: any;
  textEffects?: any;
  textEffectsAccessed?: any;
  updateDraft?: any;
  uploadedAudio?: boolean;
  uploadedBackground?: any;
  uploadedLogo?: any;
  uploadedOutroImage?: any;
  uploadedOutroVideo?: any;
}

export interface SaveTemplateProperties {
  aspectRatio?: any;
  clipDuration?: any;
  clipId?: any;
  clipName?: any;
  isDiscontinuousClip?: boolean;
  overviewChanged?: any;
  overviewType?: any;
  templateId?: any;
}

export interface SignInProperties {
  date?: any;
  emailId?: any;
  hours?: any;
  minutes?: any;
  month?: any;
  seconds?: any;
  signinSource?: any;
  signinTimeStamp?: any;
  userId?: any;
  userName?: any;
  year?: any;
}

export interface SignUpProperties {
  date?: any;
  emailId?: any;
  hours?: any;
  minutes?: any;
  month?: any;
  seconds?: any;
  signupSource?: any;
  userId?: any;
  userName?: any;
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  year?: any;
}

export interface SocialMediaPostScheduleProperties {
  clipDuration?: any;
  clipId?: any;
  clipName?: any;
  exportDescription?: string;
  exportPlatform?: any;
  isDiscontinuousClip?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  postComments?: number;
  postEdited?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  postLikes?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  postScheduleDate?: number;
  /**
   * True when scheduled posts are deleted
   */
  postScheduleDeleted?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  postScheduleHour?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  postScheduleMinute?: number;
  postScheduleMonth?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  postScheduleSecond?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  postScheduleYear?: number;
  postViewFromLink?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  postViews?: number;
  videoId?: any;
}

export interface SwitchClipAspectRatioProperties {
  aspectRatio?: any;
  clipDuration?: any;
  clipId?: any;
  clipName?: any;
  isDiscontinuousClip?: boolean;
  screenName?: any;
  videoDuration?: any;
  videoId?: any;
  videoName?: any;
  videoSourcePlatform?: any;
}

export interface UpgradeButtonClickProperties {
  screenName?: any;
}

export interface ClipExportGroupProperties {
  exportDescription?: string;
  exportPlatform?: any;
  exportPreference?: any;
}

export interface ClipPropertiesProperties {
  clipDuration?: any;
  clipId?: any;
  clipName?: any;
  isDiscontinuousClip?: boolean;
}

export interface CustomClipGroupProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  customClipEnd?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  customClipLength?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  customClipStart?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | chapter, short |
   */
  customClipType?: "chapter" | "short";
}

export interface DateProperties {
  date?: any;
  month?: any;
  year?: any;
}

export interface EditorElementsProperties {
  aiCaptions?: any;
  brandkitBackground?: any;
  brandkitOutros?: any;
  brollImageId?: any;
  bRollImageUsed?: boolean;
  brollVideoId?: any;
  bRollVideoUsed?: boolean;
  captionGenerated?: any;
  cutmagicEnabled?: any;
  fontSettingAccessed?: any;
  fontUploaded?: any;
  /**
   * Editor media tab > pexel media searched
   */
  mediaSearched?: boolean;
  socialLogoAdded?: any;
  socialLogos?: any;
  socialPreviewAccessed?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  stockAudioIds?: string[];
  stockBackground?: any;
  stockOutroId?: any;
  textEffects?: any;
  textEffectsAccessed?: any;
  uploadedAudio?: boolean;
  uploadedBackground?: any;
  uploadedLogo?: any;
  uploadedOutroImage?: any;
  uploadedOutroVideo?: any;
}

export interface SubtitleGroupProperties {
  autoAddEmoji?: any;
  downloadSRT?: any;
  subtitleEffect?: any;
  subtitleFontSize?: any;
  subtitlesRemove?: any;
  subtitleStyle?: any;
  subtitleStyleClick?: any;
  subtitleStyleColor?: string;
}

export interface TimeProperties {
  hours?: any;
  minutes?: any;
  seconds?: any;
}

export interface UtmParamsProperties {
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
}

export interface VideoPropertiesProperties {
  videoDuration?: any;
  videoId?: any;
  videoName?: any;
  videoSourcePlatform?: any;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class AddMoreMinutesClick implements BaseEvent {
  event_type = "Add More Minutes Click";

  constructor(public event_properties?: AddMoreMinutesClickProperties) {
    this.event_properties = event_properties;
  }
}

export class AddMoreMinutes implements BaseEvent {
  event_type = "addMoreMinutes";

  constructor(public event_properties?: AddMoreMinutesProperties) {
    this.event_properties = event_properties;
  }
}

export class ClickStartImport implements BaseEvent {
  event_type = "Click Start Import";

  constructor(public event_properties?: ClickStartImportProperties) {
    this.event_properties = event_properties;
  }
}

export class CreateCustomClip implements BaseEvent {
  event_type = "Create Custom Clip";

  constructor(public event_properties?: CreateCustomClipProperties) {
    this.event_properties = event_properties;
  }
}

export class DeleteClip implements BaseEvent {
  event_type = "Delete Clip";

  constructor(public event_properties?: DeleteClipProperties) {
    this.event_properties = event_properties;
  }
}

export class DiscordIconClick implements BaseEvent {
  event_type = "Discord Icon Click";
}

export class DownloadsClick implements BaseEvent {
  event_type = "Downloads Click";
}

export class EditDownloadVideoClicked implements BaseEvent {
  event_type = "Edit & Download Video clicked";

  constructor(public event_properties?: EditDownloadVideoClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class EnableCutMagic implements BaseEvent {
  event_type = "Enable CutMagic";

  constructor(public event_properties?: EnableCutMagicProperties) {
    this.event_properties = event_properties;
  }
}

export class ExportClip implements BaseEvent {
  event_type = "Export Clip";

  constructor(public event_properties?: ExportClipProperties) {
    this.event_properties = event_properties;
  }
}

export class FinishOnBoarding implements BaseEvent {
  event_type = "Finish On-boarding";

  constructor(public event_properties?: FinishOnBoardingProperties) {
    this.event_properties = event_properties;
  }
}

export class ImportVideoClick implements BaseEvent {
  event_type = "import video click";
}

export class MediaClick implements BaseEvent {
  event_type = "Media Click";

  constructor(public event_properties?: MediaClickProperties) {
    this.event_properties = event_properties;
  }
}

export class RenameClip implements BaseEvent {
  event_type = "Rename Clip";

  constructor(public event_properties?: RenameClipProperties) {
    this.event_properties = event_properties;
  }
}

export class ReviewClips implements BaseEvent {
  event_type = "Review Clips";

  constructor(public event_properties?: ReviewClipsProperties) {
    this.event_properties = event_properties;
  }
}

export class ReviewScreenInteractions implements BaseEvent {
  event_type = "Review Screen Interactions";

  constructor(public event_properties?: ReviewScreenInteractionsProperties) {
    this.event_properties = event_properties;
  }
}

export class SaveDraft implements BaseEvent {
  event_type = "Save Draft";

  constructor(public event_properties?: SaveDraftProperties) {
    this.event_properties = event_properties;
  }
}

export class SaveTemplate implements BaseEvent {
  event_type = "Save Template";

  constructor(public event_properties?: SaveTemplateProperties) {
    this.event_properties = event_properties;
  }
}

export class SignIn implements BaseEvent {
  event_type = "Sign in";

  constructor(public event_properties?: SignInProperties) {
    this.event_properties = event_properties;
  }
}

export class SignUp implements BaseEvent {
  event_type = "Sign up";

  constructor(public event_properties?: SignUpProperties) {
    this.event_properties = event_properties;
  }
}

export class SocialMediaPostSchedule implements BaseEvent {
  event_type = "Social Media Post Schedule";

  constructor(public event_properties?: SocialMediaPostScheduleProperties) {
    this.event_properties = event_properties;
  }
}

export class SwitchClipAspectRatio implements BaseEvent {
  event_type = "Switch Clip Aspect Ratio";

  constructor(public event_properties?: SwitchClipAspectRatioProperties) {
    this.event_properties = event_properties;
  }
}

export class UpgradeButtonClick implements BaseEvent {
  event_type = "Upgrade Button  Click";

  constructor(public event_properties?: UpgradeButtonClickProperties) {
    this.event_properties = event_properties;
  }
}

export class ViewOnBoarding implements BaseEvent {
  event_type = "View OnBoarding";
}

export class WhatsNewIconClick implements BaseEvent {
  event_type = "Whats New Icon Click";
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Add More Minutes Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Add%20More%20Minutes%20Click)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. screenName)
   * @param options Amplitude event options.
   */
  addMoreMinutesClick(
    properties?: AddMoreMinutesClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddMoreMinutesClick(properties), options);
  }

  /**
   * addMoreMinutes
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/addMoreMinutes)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. amountCharged)
   * @param options Amplitude event options.
   */
  addMoreMinutes(
    properties?: AddMoreMinutesProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddMoreMinutes(properties), options);
  }

  /**
   * Click Start Import
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Click%20Start%20Import)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. videoSourcePlatform)
   * @param options Amplitude event options.
   */
  clickStartImport(
    properties?: ClickStartImportProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickStartImport(properties), options);
  }

  /**
   * Create Custom Clip
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Create%20Custom%20Clip)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. customClipEnd)
   * @param options Amplitude event options.
   */
  createCustomClip(
    properties?: CreateCustomClipProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateCustomClip(properties), options);
  }

  /**
   * Delete Clip
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Delete%20Clip)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. clipDuration)
   * @param options Amplitude event options.
   */
  deleteClip(
    properties?: DeleteClipProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteClip(properties), options);
  }

  /**
   * Discord Icon Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Discord%20Icon%20Click)
   *
   * Owner: Nipun Verma
   *
   * @param options Amplitude event options.
   */
  discordIconClick(
    options?: EventOptions,
  ) {
    return this.track(new DiscordIconClick(), options);
  }

  /**
   * Downloads Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Downloads%20Click)
   *
   * Owner: Nipun Verma
   *
   * @param options Amplitude event options.
   */
  downloadsClick(
    options?: EventOptions,
  ) {
    return this.track(new DownloadsClick(), options);
  }

  /**
   * Edit & Download Video clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Edit%20%26%20Download%20Video%20clicked)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. algorithmType)
   * @param options Amplitude event options.
   */
  editDownloadVideoClicked(
    properties?: EditDownloadVideoClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditDownloadVideoClicked(properties), options);
  }

  /**
   * Enable CutMagic
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Enable%20CutMagic)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. clipDuration)
   * @param options Amplitude event options.
   */
  enableCutMagic(
    properties?: EnableCutMagicProperties,
    options?: EventOptions,
  ) {
    return this.track(new EnableCutMagic(properties), options);
  }

  /**
   * Export Clip
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Export%20Clip)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. aiCaptions)
   * @param options Amplitude event options.
   */
  exportClip(
    properties?: ExportClipProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportClip(properties), options);
  }

  /**
   * Finish On-boarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Finish%20On-boarding)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. personaOnboarding)
   * @param options Amplitude event options.
   */
  finishOnBoarding(
    properties?: FinishOnBoardingProperties,
    options?: EventOptions,
  ) {
    return this.track(new FinishOnBoarding(properties), options);
  }

  /**
   * import video click
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/import%20video%20click)
   *
   * Owner: Nipun Verma
   *
   * @param options Amplitude event options.
   */
  importVideoClick(
    options?: EventOptions,
  ) {
    return this.track(new ImportVideoClick(), options);
  }

  /**
   * Media Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Media%20Click)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. screenName)
   * @param options Amplitude event options.
   */
  mediaClick(
    properties?: MediaClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new MediaClick(properties), options);
  }

  /**
   * Rename Clip
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Rename%20Clip)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. clipDuration)
   * @param options Amplitude event options.
   */
  renameClip(
    properties?: RenameClipProperties,
    options?: EventOptions,
  ) {
    return this.track(new RenameClip(properties), options);
  }

  /**
   * Review Clips
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Review%20Clips)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. clipCount)
   * @param options Amplitude event options.
   */
  reviewClips(
    properties?: ReviewClipsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewClips(properties), options);
  }

  /**
   * Review Screen Interactions
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Review%20Screen%20Interactions)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. aiCaptionClicked)
   * @param options Amplitude event options.
   */
  reviewScreenInteractions(
    properties?: ReviewScreenInteractionsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReviewScreenInteractions(properties), options);
  }

  /**
   * Save Draft
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Save%20Draft)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. aiCaptions)
   * @param options Amplitude event options.
   */
  saveDraft(
    properties?: SaveDraftProperties,
    options?: EventOptions,
  ) {
    return this.track(new SaveDraft(properties), options);
  }

  /**
   * Save Template
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Save%20Template)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. aspectRatio)
   * @param options Amplitude event options.
   */
  saveTemplate(
    properties?: SaveTemplateProperties,
    options?: EventOptions,
  ) {
    return this.track(new SaveTemplate(properties), options);
  }

  /**
   * Sign in
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Sign%20in)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. date)
   * @param options Amplitude event options.
   */
  signIn(
    properties?: SignInProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignIn(properties), options);
  }

  /**
   * Sign up
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Sign%20up)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. date)
   * @param options Amplitude event options.
   */
  signUp(
    properties?: SignUpProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignUp(properties), options);
  }

  /**
   * Social Media Post Schedule
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Social%20Media%20Post%20Schedule)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. clipDuration)
   * @param options Amplitude event options.
   */
  socialMediaPostSchedule(
    properties?: SocialMediaPostScheduleProperties,
    options?: EventOptions,
  ) {
    return this.track(new SocialMediaPostSchedule(properties), options);
  }

  /**
   * Switch Clip Aspect Ratio
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Switch%20Clip%20Aspect%20Ratio)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. aspectRatio)
   * @param options Amplitude event options.
   */
  switchClipAspectRatio(
    properties?: SwitchClipAspectRatioProperties,
    options?: EventOptions,
  ) {
    return this.track(new SwitchClipAspectRatio(properties), options);
  }

  /**
   * Upgrade Button  Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Upgrade%20Button%20%20Click)
   *
   * Owner: Nipun Verma
   *
   * @param properties The event's properties (e.g. screenName)
   * @param options Amplitude event options.
   */
  upgradeButtonClick(
    properties?: UpgradeButtonClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpgradeButtonClick(properties), options);
  }

  /**
   * View OnBoarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/View%20OnBoarding)
   *
   * Owner: Nipun Verma
   *
   * @param options Amplitude event options.
   */
  viewOnBoarding(
    options?: EventOptions,
  ) {
    return this.track(new ViewOnBoarding(), options);
  }

  /**
   * Whats New Icon Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/vidyoai/vidyoai/events/main/latest/Whats%20New%20Icon%20Click)
   *
   * Owner: Nipun Verma
   *
   * @param options Amplitude event options.
   */
  whatsNewIconClick(
    options?: EventOptions,
  ) {
    return this.track(new WhatsNewIconClick(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
