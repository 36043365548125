import { useState } from "react";

import { FacebookAuthProvider } from "firebase/auth";
import { Spinner } from "flowbite-react";

import { signInWithFirebase } from "@/utils/FirebaseHelpers";

const FacebookAuth = ({
  setDisplayModal,
  setFirebaseUserData,
}: {
  setDisplayModal: (value: boolean) => void;
  setFirebaseUserData: (value: {}) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const signInWithFacebook = (e: any) => {
    e.preventDefault();
    const provider = new FacebookAuthProvider();
    signInWithFirebase(
      provider,
      setIsLoading,
      setDisplayModal,
      setFirebaseUserData
    );
  };
  return (
    <button
      onClick={signInWithFacebook}
      className={`h-10 border-none inline-flex w-full items-center justify-center rounded-md border text-sm font-medium text-gray-900  focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400  dark:focus:ring-gray-700 bg-[#35518D] transition-colors ${
        isLoading
          ? "border-black/60 opacity-60"
          : "hover:bg-[#1c3d85] hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white"
      }`}
      id="sign-in-with-facebook"
      disabled={isLoading}
    >
      {isLoading ? (
        <Spinner className="h-4 w-4" />
      ) : (
        <>
          <svg
            width="10"
            height="18"
            viewBox="0 0 10 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.03516 10.125L9.52734 6.89062H6.39844V4.78125C6.39844 3.86719 6.82031 3.02344 8.22656 3.02344H9.66797V0.246094C9.66797 0.246094 8.36719 0 7.13672 0C4.57031 0 2.88281 1.58203 2.88281 4.39453V6.89062H0V10.125H2.88281V18H6.39844V10.125H9.03516Z"
              fill="white"
            />
          </svg>

          <span className="hidden xl:block text-white text-md ml-3">
            Facebook
          </span>
        </>
      )}
    </button>
  );
};

export default FacebookAuth;
