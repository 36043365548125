import { useEffect, useState } from "react";

import {
  ClockIcon,
  CurrencyDollarIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";

import { useAppSelector } from "@/store/hooks";

import { getUserVidyoJourney } from "@/api/requests";

const changeMinutesToHHMM = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return `${hours}h ${minutesLeft}m`;
};

const VidyoUserJourney = ({ userJourney }: any) => {
  return (
    <div className="bg-[#1A56DB] h-[224px] px-6 py-4 rounded-lg">
      <div className="flex-col space-y-9">
        <div className="align-left">
          <p className="text-white text-xl font-normal ml-10">
            Your vidyo.ai journey at a glance 🤍
          </p>
        </div>
        <div className="flex justify-around">
          <div className="text-white flex flex-col items-center gap-5">
            <VideoCameraIcon
              height={40}
              width={48}
            />
            <p className="text-xl">Made {userJourney.renderedCount} videos</p>
          </div>
          <div className="text-white flex flex-col items-center gap-5">
            <ClockIcon
              height={40}
              width={48}
            />
            <p className="text-xl">
              Saved {changeMinutesToHHMM(userJourney.renderedCount * 30)} time
            </p>
          </div>
          <div className="text-white flex flex-col items-center gap-5">
            <CurrencyDollarIcon
              height={40}
              width={48}
            />
            <p className="text-xl">saved ${userJourney.renderedCount * 10}+ </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VidyoUserJourney;
