import * as React from "react";

import * as FullStory from "@fullstory/browser";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";

import { isProd } from "@/helpers";

import { AbTestingUserTrack } from "@/utils/amplitudeAnalytcs";

import { ABTest } from "@/enums";

const stageKey = "sdk-3vEnF7L5OFNmS85";
const prodKey = "sdk-icDKoyKxFymCiwYZ";
const clientKey = isProd() ? prodKey : stageKey;
const GROWTHBOOK_API_HOST = "https://cdn.growthbook.io";

const gb = new GrowthBook({
  apiHost: GROWTHBOOK_API_HOST,
  clientKey,
  enableDevMode: !isProd(),
  subscribeToChanges: false,
  trackingCallback: (experiment, result) => {
    console.debug("Experiment Viewed", {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

export function GrowthBookSetup(props: {
  children: React.ReactNode;
  user: { uid: string; email: string; displayName: string };
  country: string | null;
  isFSInitialized: boolean;
}) {
  const { user, country, isFSInitialized } = props;
  const initializedGrowthBook = React.useRef(false);
  React.useEffect(() => {
    if (
      user?.uid &&
      user?.email &&
      user?.displayName &&
      !initializedGrowthBook.current &&
      (!isProd() || isFSInitialized) &&
      country
    ) {
      console.log("getting attribs");
      gb.setAttributes({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
      });
      gb.loadFeatures().then(() => {
        const isOn = gb.isOn(ABTest.TIMELINE_ZOOM as string);
        const isEditorUndoRedoOn = gb.isOn(ABTest.EDITOR_UNDO_REDO as string);

        // set amplitude and fullstory properties.
        if (FullStory.isInitialized()) {
          console.debug("[GrowthBook]: Registering on FS");
          FullStory.identify(user.uid, {
            [ABTest.TIMELINE_ZOOM]: isOn,
            [ABTest.EDITOR_UNDO_REDO]: isEditorUndoRedoOn,
          });
        }

        let amplitudeAbTests: string[] = [];

        if (isEditorUndoRedoOn) {
          amplitudeAbTests.push(ABTest.EDITOR_UNDO_REDO);
        }
        if (isOn) {
          amplitudeAbTests.push(ABTest.TIMELINE_ZOOM);
        }
        AbTestingUserTrack(amplitudeAbTests);
      });
      initializedGrowthBook.current = true;
    }
  }, [user?.uid, user?.email, user?.displayName, country, isFSInitialized]);

  return (
    <GrowthBookProvider growthbook={gb}>{props.children}</GrowthBookProvider>
  );
}
