import { useState } from "react";
import ReactTooltip from "react-tooltip";

import clsx from "clsx";
import { Tooltip } from "flowbite-react";
import parseSRT from "parse-srt";

import { updateSelectedDraft } from "@/store/draftSlice";
import { updateAutoAddEmojisToSubtitles } from "@/store/editorSlice";
import { updateCurrentSelectedMicroContent } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetUserTemplates from "@/api/useGetUserTemplates";

import { EDITOR_TEMPLATE_DIMENSIONS } from "@/constants";
import { EDITOR_INTERACTION_DATA } from "@/constants/amplitude";
import { layoutsArray } from "@/constants/aspect-ratio";

import {
  isAdminTemplateEditor,
  reverseArrayForPreferredTemplates,
  sortByUpdatedDate,
} from "@/helpers";
import { updateTemplateDefaultTexts } from "@/helpers/text";

import { trackEditorInteractionEvent } from "@/utils/amplitudeAnalytcs";

import { EditorAppliedTemplate } from "@/interfaces";

import { TemplateTabVariant, TemplateVariant, VideoLayout } from "@/enums";

import Spinner from "@/components/Loader/Spinner";

import { useEditorHistory } from "@/views/editor/EditorHistoryContext";
import { HistoryActionTypes } from "@/views/editor/EditorHistoryReducer";
import { updateCurrentTemplate } from "@/views/editor/commands/templateCommand";
import TemplateStaticPreview from "@/views/home/components/TemplateStaticPreview";

import TemplatesEmpty from "./TemplatesEmpty";

const templateContainerStyles = {
  [VideoLayout.LAYOUT_16_9]: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: "2vw",
    gridAutoRows: "minmax(10vh, auto)",
    margin: "2vh 0",
  },
  [VideoLayout.LAYOUT_1_1]: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: "2vw",
    gridAutoRows: "minmax(10vh, auto)",
    margin: "2vh 0",
  },
  [VideoLayout.LAYOUT_9_16_1]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "2vw",
    gridAutoRows: "minmax(10vh, auto)",
    margin: "2vh 0",
  },
  [VideoLayout.LAYOUT_9_16_2]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "2vw",
    gridAutoRows: "minmax(10vh, auto)",
    margin: "2vh 0",
  },
};

const TemplatesTab = () => {
  const { editorDispatch } = useEditorHistory();

  const dispatch = useAppDispatch();

  const [currentSelectedTab, setCurrentSelectedTab] = useState(
    TemplateTabVariant.DEFAULT_TEMPLATE
  );

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const currentSelectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );

  const isUserChangedLayout = useAppSelector(
    (state) => state.editorState.isUserChangedLayout
  );

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const enableSceneChange = useAppSelector(
    (state) => state.editorState.enableSceneChange
  );

  const userTemplateData = useGetUserTemplates(uid);
  const defaultBaseTemplates = useGetDefaultBaseTemplates();

  const getBaseTemplates = (layout: VideoLayout) => {
    if (
      currentSelectedTab === TemplateTabVariant.USER_TEMPLATE &&
      userTemplateData?.data
    ) {
      if (Object.keys(userTemplateData?.data).includes(layout)) {
        return sortByUpdatedDate(Object.values(userTemplateData?.data[layout]));
      } else {
        return [];
      }
    }

    if (
      defaultBaseTemplates?.data &&
      Object.keys(defaultBaseTemplates?.data).includes(layout)
    ) {
      // @ts-ignore
      let templates = sortByUpdatedDate(
        Object.values(defaultBaseTemplates?.data[layout])
      );
      return templates;
    }
    return [];
  };

  const handleChangeCurrentTemplate = (metaData: any) => {
    const command = updateCurrentTemplate(metaData, (templateData: any) => {
      dispatch(updateCurrentSelectedMicroContent(templateData));
    });

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handelApplyTemplate = (template: any) => {
    const templateData = {
      id: template.id,
      face_coord: template.face_coord || [],
      start: template.start,
      end: template.end,
      srt_string: template.srt_string,
      imageUrl: template.imageUrl,
      hasTwoFace: template.hasTwoFace,
      gist: template.gist,
      text: template.text,
      chapter_end: template.chapter_end,
      chapter_start: template.chapter_start,
      backgroundColor: template.backgroundColor,
      name: template.name || "",
      isDefault: template.isDefault || false,
      // carry forward clip properties
      userClipRateExist: template.userClipRateExist,
      bookmarked: template.bookmarked,
      seen: template.seen,
      tag: template.tag,
      clip_src: template.clip_src,
    };

    handleChangeCurrentTemplate({
      templateData,
      oldTemplateData: currentSelectedMicroContent,
    });
    // dispatch(updateCurrentSelectedMicroContent(templateData));
    dispatch(updateSelectedDraft(null));
    dispatch(
      updateAutoAddEmojisToSubtitles(
        template?.autoAddEmojisToSubtitles || false
      )
    );
  };

  const getTemplatesForSelectedLayout = (layout: VideoLayout) => {
    const finalTemplateArr: EditorAppliedTemplate[] = [];

    let currentAppliedTemplate: any = getBaseTemplates(layout).filter(
      (item: any) => item.id === currentSelectedMicroContent.id
    )[0];

    currentAppliedTemplate = {
      ...currentAppliedTemplate,
      imageUrl: currentSelectedMicroContent.imageUrl,
      srt_string: currentSelectedMicroContent.srt_string,
      hasTwoFace: currentSelectedMicroContent.hasTwoFace,
      texts: currentAppliedTemplate?.texts.length
        ? updateTemplateDefaultTexts(
            currentAppliedTemplate.texts,
            currentSelectedMicroContent.gist
          )
        : [],
      gist: currentSelectedMicroContent.gist,
      face_coord: currentSelectedMicroContent.face_coord,
      start: currentSelectedMicroContent.start,
      end: currentSelectedMicroContent.end,
      chapter_end: currentSelectedMicroContent.chapter_end,
      chapter_start: currentSelectedMicroContent.chapter_start,
      // carry forward clip properties
      userClipRateExist: currentSelectedMicroContent.userClipRateExist,
      bookmarked: currentSelectedMicroContent.bookmarked,
      seen: currentSelectedMicroContent.seen,
      tag: currentSelectedMicroContent.tag,
      clip_src: currentSelectedMicroContent.clip_src,
    };

    getBaseTemplates(layout).forEach((template: any) => {
      if (isUserChangedLayout) {
        const customTemplate = {
          ...template,
          imageUrl: currentAppliedTemplate.imageUrl,
          srt_string: currentAppliedTemplate.srt_string,
          hasTwoFace: false,
          texts: template?.texts.length
            ? updateTemplateDefaultTexts(
                template.texts,
                currentAppliedTemplate.gist
              )
            : [],
          gist: currentAppliedTemplate.gist,
          face_coord: currentAppliedTemplate.face_coord,
          start: currentAppliedTemplate.start,
          end: currentAppliedTemplate.end,
          chapter_end: currentAppliedTemplate.chapter_end,
          chapter_start: currentAppliedTemplate.chapter_start,
          subtitle: {
            ...template.subtitle,
            text: parseSRT(currentAppliedTemplate.srt_string)?.length
              ? parseSRT(currentAppliedTemplate.srt_string)[0].text
              : "",
          },
          // carry forward clip properties
          userClipRateExist: currentSelectedMicroContent.userClipRateExist,
          bookmarked: currentSelectedMicroContent.bookmarked,
          seen: currentSelectedMicroContent.seen,
          tag: currentSelectedMicroContent.tag,
          clip_src: currentSelectedMicroContent.clip_src,
        };
        finalTemplateArr.push(customTemplate);
      } else {
        const customTemplate = {
          ...template,
          imageUrl: currentAppliedTemplate.imageUrl,
          srt_string: currentAppliedTemplate.srt_string,
          hasTwoFace:
            currentSelectedMicroContent?.face_coord?.length > 1 ? true : false,
          texts: template?.texts.length
            ? updateTemplateDefaultTexts(
                template.texts,
                currentAppliedTemplate.gist
              )
            : [],
          gist: currentAppliedTemplate.gist,
          face_coord: currentAppliedTemplate.face_coord,
          start: currentAppliedTemplate.start,
          end: currentAppliedTemplate.end,
          chapter_end: currentAppliedTemplate.chapter_end,
          chapter_start: currentAppliedTemplate.chapter_start,
          subtitle: {
            ...template.subtitle,
            text: parseSRT(currentAppliedTemplate.srt_string)?.length
              ? parseSRT(currentAppliedTemplate.srt_string)[0].text
              : "",
          },
          // carry forward clip properties
          userClipRateExist: currentSelectedMicroContent.userClipRateExist,
          bookmarked: currentSelectedMicroContent.bookmarked,
          seen: currentSelectedMicroContent.seen,
          tag: currentSelectedMicroContent.tag,
          clip_src: currentSelectedMicroContent.clip_src,
        };

        finalTemplateArr.push(customTemplate);
      }
    });

    return reverseArrayForPreferredTemplates(finalTemplateArr);
  };

  const renderTemplates = (layout: VideoLayout) => {
    const disabledLayout =
      layout === VideoLayout.LAYOUT_9_16_1
        ? VideoLayout.LAYOUT_9_16_2
        : VideoLayout.LAYOUT_9_16_1;

    if (
      layout === VideoLayout.LAYOUT_16_9 ||
      layout === VideoLayout.LAYOUT_1_1
    ) {
      return (
        <div
          style={templateContainerStyles[layout]}
          className="justify-items-center p-2 pt-0"
        >
          {getTemplatesForSelectedLayout(layout)?.length ? (
            getTemplatesForSelectedLayout(layout).map((template) => (
              <TemplateStaticPreview
                template={template}
                key={template.id}
                handelChangeTemplate={handelApplyTemplate}
                variant={TemplateVariant.SMALL}
                dimensions={EDITOR_TEMPLATE_DIMENSIONS[layout]}
                layout={layout}
                showDuration={false}
                isUserTemplate={
                  currentSelectedTab === TemplateTabVariant.USER_TEMPLATE
                }
              />
            ))
          ) : (
            <TemplatesEmpty />
          )}
        </div>
      );
    }

    return (
      <div
        style={templateContainerStyles[layout]}
        className="justify-items-center p-2 pt-0"
      >
        {getTemplatesForSelectedLayout(layout)?.length ||
        getTemplatesForSelectedLayout(disabledLayout)?.length ? (
          <>
            {getTemplatesForSelectedLayout(layout).map((template) => (
              <TemplateStaticPreview
                template={template}
                key={template.id}
                handelChangeTemplate={handelApplyTemplate}
                variant={TemplateVariant.SMALL}
                dimensions={EDITOR_TEMPLATE_DIMENSIONS[layout]}
                layout={layout}
                showDuration={false}
                isUserTemplate={
                  currentSelectedTab === TemplateTabVariant.USER_TEMPLATE
                }
              />
            ))}
            {getTemplatesForSelectedLayout(disabledLayout).map((template) => (
              <Tooltip
                placement="top"
                content={
                  <>
                    You can't use this template
                    <br />
                    in selected layout.
                  </>
                }
                key={template.id}
              >
                <div className="cursor-not-allowed opacity-50 blur-3 pointer-events-none">
                  <TemplateStaticPreview
                    template={template}
                    key={template.id}
                    handelChangeTemplate={handelApplyTemplate}
                    variant={TemplateVariant.SMALL}
                    dimensions={EDITOR_TEMPLATE_DIMENSIONS[disabledLayout]}
                    layout={disabledLayout}
                    showDuration={false}
                  />
                </div>
              </Tooltip>
            ))}
          </>
        ) : (
          <TemplatesEmpty />
        )}
      </div>
    );
  };

  const renderValidTemplates = () => {
    return layoutsArray.includes(currentSelectedLayout) ? (
      renderTemplates(currentSelectedLayout)
    ) : (
      <TemplatesEmpty />
    );
  };

  return (
    <div className="relative h-auto w-full">
      <div className="sticky top-0 z-10 w-full bg-white pt-2">
        <ul className="-mb-px flex w-full transition-transform">
          <li
            className={clsx(
              "w-[50%] cursor-pointer whitespace-nowrap border-b-4 text-center",
              currentSelectedTab === TemplateTabVariant.DEFAULT_TEMPLATE
                ? "border-blue-500 text-blue-500 dark:text-blue-500"
                : "border-transparent text-gray-600 hover:text-gray-800"
            )}
            onClick={() => {
              setCurrentSelectedTab(TemplateTabVariant.DEFAULT_TEMPLATE);
              currentSelectedTab !== TemplateTabVariant.DEFAULT_TEMPLATE &&
                trackEditorInteractionEvent(
                  EDITOR_INTERACTION_DATA.TEMPLATES_TAB.EVENT_KEY,
                  EDITOR_INTERACTION_DATA.TEMPLATES_TAB.ALL_TEMPLATES
                );
            }}
          >
            <p className="inline-block p-3">All Templates</p>
          </li>

          {isAdminTemplateEditor() ? null : (
            <li
              className={clsx(
                "w-[50%] cursor-pointer select-none whitespace-nowrap border-b-4 text-center",
                currentSelectedTab === TemplateTabVariant.USER_TEMPLATE
                  ? "border-blue-500 text-blue-500 dark:text-blue-500"
                  : "border-transparent text-gray-600 hover:text-gray-800"
              )}
              onClick={() => {
                setCurrentSelectedTab(TemplateTabVariant.USER_TEMPLATE);
                currentSelectedTab !== TemplateTabVariant.USER_TEMPLATE &&
                  trackEditorInteractionEvent(
                    EDITOR_INTERACTION_DATA.TEMPLATES_TAB.EVENT_KEY,
                    EDITOR_INTERACTION_DATA.TEMPLATES_TAB.MY_TEMPLATES
                  );
              }}
              data-tip={
                enableSceneChange
                  ? "This feature is unavailable when using CutMagic"
                  : ""
              }
            >
              <p className="inline-block p-3">My Templates</p>
              <ReactTooltip
                effect="solid"
                padding="8px"
                arrowColor="transparent"
                className="bg-gray-900 text-sm text-white"
              />
            </li>
          )}
        </ul>
      </div>

      {userTemplateData.isFetching ||
      defaultBaseTemplates.isFetching ||
      defaultBaseTemplates.isLoading ||
      userTemplateData.isLoading ? (
        <div className="mt-60 flex justify-center">
          <div>
            <Spinner className="mx-auto h-8 w-8" />
          </div>
        </div>
      ) : (
        <>{renderValidTemplates()}</>
      )}
    </div>
  );
};

export default TemplatesTab;
