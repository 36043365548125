import * as React from "react";
import { useNavigate } from "react-router";

import { setCurrentSideMenu, setElementsActiveTab } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { EditorMediaTab, EditorMediaTabMode } from "@/enums";

import { editorSubRoutes } from "@/views/editor/constant";

interface EditorNavigationHook {
  handleChangeSideBarMenu: (route: string, mediaType?: EditorMediaTab) => void;
  handleChangeElementsTab: (tabName: string) => void;
}

interface EditorNavigationHookProps {}

export const useEditorNavigation =
  ({}: EditorNavigationHookProps): EditorNavigationHook => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentSelectedProject = useAppSelector(
      (state) => state.homeState.currentSelectedProject
    );
    const handleChangeSideBarMenu = React.useCallback(
      (route: string, mediaType = EditorMediaTab.IMAGE) => {
        dispatch(setCurrentSideMenu(route));
        if (route === editorSubRoutes.MEDIA) {
          navigate(
            `/editor/${currentSelectedProject?.id}/${route}?type=${mediaType}&mode=${EditorMediaTabMode.BROWSE}`
          );
          return;
        }
        navigate(`/editor/${currentSelectedProject?.id}/${route}`);
      },
      []
    );
    const handleChangeElementsTab = (tabName: string) => {
      dispatch(setElementsActiveTab(tabName));
    };

    return { handleChangeSideBarMenu, handleChangeElementsTab };
  };
