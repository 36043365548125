import { useState } from "react";

import { Button } from "flowbite-react";

import { setProgressBarColor, setProgressBarHeight } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { EDITOR_INTERACTION_DATA } from "@/constants/amplitude";
import { MediaIconColors } from "@/constants/brand-kit";

import { trackEditorInteractionEvent } from "@/utils/amplitudeAnalytcs";

import AccordionLayout from "@/components/Accordion/AccordionLayout";
import ColorPicker from "@/components/ColorPicker";
import ReactRangeSlider from "@/components/ReactRangeSlider";

import { useEditorHistory } from "@/views/editor/EditorHistoryContext";
import { HistoryActionTypes } from "@/views/editor/EditorHistoryReducer";
import {
  addRemoveProgressBarCommand,
  changeProgressBarColorCommand,
  changeProgressBarHeightCommand,
} from "@/views/editor/commands/progressbarCommand";
import { DEFAULT_PROGRESS_BAR_HEIGHT } from "@/views/editor/constant";

const ProgressBarEditor = () => {
  const { editorDispatch } = useEditorHistory();
  const progressBarColorAsHex = useAppSelector(
    (state) => state.editorState.progressBarColorAsHex
  );

  const progressBarHeight = useAppSelector(
    (state) => state.editorState.progressBarHeight
  );

  const [isProgressBarActive, setIsProgressBarActive] = useState(
    progressBarHeight > 0
  );
  const dispatch = useAppDispatch();

  const handelSwitchProgressbarStyles = (
    isActive: boolean,
    progressBarHeight: number
  ) => {
    if (!isActive) {
      setIsProgressBarActive(true);
      dispatch(
        setProgressBarHeight(progressBarHeight || DEFAULT_PROGRESS_BAR_HEIGHT)
      );
      trackEditorInteractionEvent(
        EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.EVENT_KEY,
        EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.ADD_PROGRESS_BAR
      );
    } else {
      dispatch(setProgressBarHeight(0));
      setIsProgressBarActive(false);
      trackEditorInteractionEvent(
        EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.EVENT_KEY,
        EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.REMOVE_PROGRESS_BAR
      );
    }
  };

  const toggleProgressBar = () => {
    const metaData = {
      oldProgressbarHeight: progressBarHeight,
      newProgressbarHeight: isProgressBarActive
        ? DEFAULT_PROGRESS_BAR_HEIGHT
        : progressBarHeight,
      isProgressBarActive,
    };

    const command = addRemoveProgressBarCommand(
      metaData,
      handelSwitchProgressbarStyles
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handelChangeProgressBarColor = (color: string) => {
    dispatch(setProgressBarColor(color));
    trackEditorInteractionEvent(
      EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.EVENT_KEY,
      EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.PROGRESS_BAR_COLOR_CHANGED
    );
  };

  const changeProgressBarColor = (e: any) => {
    const metaData = {
      newColorAsHex: e.hex as string,
      oldColorAsHex: progressBarColorAsHex,
    };
    const command = changeProgressBarColorCommand(
      metaData,
      handelChangeProgressBarColor
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handleChangeProgressBarHeight = (height: number) => {
    const metaData = {
      newHeight: height,
      oldHeight: progressBarHeight,
    };
    const command = changeProgressBarHeightCommand(metaData, (height) =>
      dispatch(setProgressBarHeight(height))
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  return (
    <>
      {!isProgressBarActive && (
        <div className="flex items-center justify-end mt-4">
          <Button
            className="w-full py-[5px] bg-blue-600 rounded-md font-normal hover:!bg-blue-600"
            onClick={toggleProgressBar}
            id="add-progress-bar"
            size="md"
          >
            Add Progress Bar
          </Button>
        </div>
      )}

      {isProgressBarActive && (
        <>
          <div className="-mt-2">
            <AccordionLayout
              title="Progress Bar color"
              Icon={MediaIconColors}
              iconColor={progressBarColorAsHex}
            >
              <ColorPicker
                colorInHex={progressBarColorAsHex}
                handleChangeColor={changeProgressBarColor}
              />
            </AccordionLayout>
          </div>
          <div className="my-8">
            <p className=" mb-4 text-gray-500">Thickness</p>

            <div className="px-2">
              <ReactRangeSlider
                min={0}
                max={30}
                value={progressBarHeight}
                onAfterChange={(e: any) => {
                  trackEditorInteractionEvent(
                    EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.EVENT_KEY,
                    EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED
                      .PROGRESS_BAR_THICKNEDD_EDITED
                  );
                  handleChangeProgressBarHeight(e);
                }}
                trackClassName="h-3 bg-white"
                thumbClassName="h-5 w-5 mt-0"
              />
            </div>
          </div>

          <div className="mt-8 flex items-center justify-end">
            <button
              type="button"
              className="mb-2 mt-8 rounded-lg border border-gray-200 bg-white py-2.5 px-5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700 "
              onClick={toggleProgressBar}
              id="remove-progress-bar"
            >
              Remove Progress Bar
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default ProgressBarEditor;
