import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import * as FullStory from "@fullstory/browser";
import SentryFullStory from "@sentry/fullstory";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "virtual:vite-plugin-sentry/sentry-config";

import { store } from "@/store";

import "./index.scss";
import Root from "./root";

const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist;
const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release;

(window.location.hostname === "app.vidyo.ai" ||
  window.location.hostname === "legacy.vidyo.ai") &&
  Sentry.init({
    dsn: "https://501452a9089f4abeb1c65756f36454ad@o1346531.ingest.sentry.io/6624524",
    integrations: [
      new Sentry.Replay(),
      new Sentry.BrowserTracing(),
      new SentryFullStory("vidyoai", { client: FullStory }),
    ],
    ignoreErrors: [
      "TypeError: Load failed", // happens on Safari when user downloads a video - need to fix
      "INTERNAL ASSERTION FAILED: Unexpected state", // happens in firebase non breaking error - need to fix
      "INTERNAL ASSERTION FAILED: Pending promise was never set", // happens in firebase non breaking error - need to fix
      "ResizeObserver loop completed with undelivered notifications.", // internal library error, not breaking.
    ],
    tracesSampleRate: 0.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    dist,
    release,
  });

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Root />
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
