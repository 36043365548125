import isEmail from "validator/es/lib/isEmail";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

const allowedDomains = [
  "gmail.com",
  "mail.ru",
  "outlook.com",
  "yahoo.com.br",
  "icloud.com",
  "yahoo.com",
  "hotmail.fr",
  "hotmail.com",
  "gamil.com",
  "googlemail.com",
  "me.com",
  "outlook.fr",
  "hotmail.co.uk",
  "yandex.ru",
  "yahoo.co.uk",
  "outlook.de",
  "yahoo.fr",
  "proton.me",
  "gmx.de",
  "live.com",
  "protonmail.com",
  "seznam.cz",
  "web.de",
  "mail.com",
  "hotmail.it",
  "aol.com",
  "msn.com",
  "ymail.com",
  "bk.ru",
  "duck.com",
  "live.fr",
  "email.com",
  "vidyo.ai",
];

const isInAllowedDomains = (email: string) => {
  const domain = email.split("@")[1].toLowerCase();
  return allowedDomains.includes(domain);
};

interface EmailCheckResponse {
  isValidEmail: boolean;
}

export const checkTempMail = async (email: string) => {
  email = email.trim();
  if (!email) {
    return false;
  }

  if (isInAllowedDomains(email)) {
    return false;
  }

  try {
    const {
      data: { isValidEmail },
    } = await api.get<EmailCheckResponse>(ApiEndpoints.EMAIL_CHECK, {
      params: { email },
    });
    return !isValidEmail;
  } catch {
    throw new Error("Call failed for email check");
  }
};

export const validateEmail = (
  email: string,
  cb: (arg: string) => void,
  checkForTempMail = false
) => {
  email = email.trim();
  if (!email) {
    cb("*Required");
    return false;
  } else if (!isEmail(email)) {
    cb("Invalid email address");
    return false;
  }
  if (checkForTempMail) {
    return api
      .get(ApiEndpoints.EMAIL_CHECK, { params: { email } })
      .then((res) => {
        const { isValidEmail } = res.data as { isValidEmail: boolean };
        if (isValidEmail) {
          cb("");
          return true;
        }
        cb("Unsupported email type.");
        return false;
      })
      .catch(() => {
        cb("Something went wrong!");
        return false;
      });
  } else {
    cb("");
    return true;
  }
};

export const validatePassword = (password: string, cb: (arg: any) => void) => {
  password = password.trim();
  if (!password.length) {
    cb("*Required");
    return false;
  }
  if (password.length < 8) {
    cb("Password must be 8 characters long");
    return false;
  } else return true;
};

export const validateName = (name: string, cb: (arg: string) => void) => {
  name = name.trim();
  if (!name) {
    cb("*Required");
    return false;
  } else {
    cb("");
    return true;
  }
};
