import { memo, useCallback } from "react";

import { Button, Spinner } from "flowbite-react";

import {
  setCurrentSelectedClip,
  updateCurrentSelectedMicroContent,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useDeleteMicroContent from "@/api/useDeleteMicroContent";

import { trackDeleteClipEvent } from "@/utils/amplitudeAnalytcs";

import { PreviewScreenClipTypes } from "@/enums";

import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";

type ClipDeleteModalProps = {
  setIsClipDeleteModal: (value: boolean) => void;
  isClipDeleteModal: boolean;
  projectData: any;
  microContent: any;
  currentType: PreviewScreenClipTypes;
  setCurrentType: (value: PreviewScreenClipTypes) => void;
  setClipDeleteLoading: (value: boolean) => void;
};

const ClipDeleteModal = ({
  setIsClipDeleteModal,
  isClipDeleteModal,
  projectData,
  microContent,
  currentType,
  setCurrentType,
  setClipDeleteLoading,
}: ClipDeleteModalProps) => {
  const dispatch = useAppDispatch();

  const { mutate: deleteMicroContent, isLoading: deleteMicroContentLoading } =
    useDeleteMicroContent();

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const deleteCurrentSelectedMicroContent = () => {
    const params = {
      projectId: projectData.id,
      start: currentSelectedMicroContent.start,
      end: currentSelectedMicroContent.end,
      type:
        currentType === PreviewScreenClipTypes.CHAPTERS ? "chapter" : "short",
    };

    trackDeleteClipEvent(currentSelectedMicroContent, projectData);

    deleteMicroContent(params, {
      onSuccess: () => {
        if (!microContent?.preview_data.length) {
          if (microContent?.chapters.length) {
            setCurrentType(PreviewScreenClipTypes.CHAPTERS);
          } else {
            setCurrentType(PreviewScreenClipTypes.FULL_VIDEO);
          }
        } else {
          setCurrentType(PreviewScreenClipTypes.SHORTS);
        }

        setClipDeleteLoading(true);
        setIsClipDeleteModal(false);
        dispatch(setCurrentSelectedClip(""));

        // add isDeleted property to the current selected micro content
        // so we can check it in the useEffect in the LeftSidebar.tsx
        dispatch(
          updateCurrentSelectedMicroContent({
            isDeleted: true,
          })
        );
      },
    });
  };

  return (
    <DeleteConfirmationModal
      showDeleteConfirmationModal={isClipDeleteModal}
      heading="Do you really want to delete this clip?"
    >
      <div className="max-w-screen-sm w-full bg-white p-6 pb-4 pt-2">
        <div className="w-full relative">
          <p className="ml-2 mb-4 text-center text-gray-900">
            This action is <span className="text-red-600"> not reversible</span>{" "}
          </p>
        </div>
        <div className="mt-7 flex flex-row-reverse gap-10 md:gap-10">
          <Button
            id="preview-clip-delete"
            color="failure"
            className="w-full rounded-lg text-center text-sm font-medium text-white"
            onClick={deleteCurrentSelectedMicroContent}
          >
            {deleteMicroContentLoading ? (
              <Spinner
                size="sm"
                light={true}
              />
            ) : (
              <span>Delete</span>
            )}
          </Button>
          <Button
            id="cancel-account-deletion"
            onClick={() => setIsClipDeleteModal(false)}
            color="light"
            className="w-full rounded-lg border border-red-500 bg-white text-center text-sm font-medium text-blue-500 hover:bg-blue-50"
          >
            Cancel
          </Button>
        </div>
      </div>
    </DeleteConfirmationModal>
  );
};
export default memo(ClipDeleteModal);
