import * as React from "react";

import clsx from "clsx";

import useElementSize from "@/hooks/useElementSize";

import { EDITOR_INTERACTION_DATA } from "@/constants/amplitude";

import {
  createDelay,
  formatMillisecondsForTimeline,
  secondsToHumanReadable,
} from "@/helpers";

import { trackEditorInteractionEvent } from "@/utils/amplitudeAnalytcs";

import { Scene, Timeout } from "@/interfaces";

import { STATUS } from "@/enums";

import { InvertedHalfTriangleIcon } from "@/components/Icons/InvertedHalfTriangleIcon";
import { InvertedTriangleIcon } from "@/components/Icons/InvertedTriangleIcon";
import PlayHeadTriangle from "@/components/Icons/PlayheadTriangle";

import "./Timeline.styles.scss";
import { DrawWaveform, TimelineProps } from "./TimelineWithZoomInterface";
import {
  generateWaveForm,
  getPrimaryLabelNumber,
  findMinMax,
} from "./TimelineWithZoomUtils";

const TIMELINE_HEIGHT = 50;
const PARTIALLY_GENERATED = "PARTIALLY_GENERATED";
const MARKER_WIDTH = 2;

function renderLineWaveform(
  channelData: number[],
  ctx: CanvasRenderingContext2D,
  vScale: number
) {
  const drawChannel = () => {
    const channel = channelData;
    const length = channel.length;
    const { height } = ctx.canvas;
    const hScale = ctx.canvas.width / length;

    ctx.fillStyle = "#CDD0D5";
    ctx.strokeStyle = "#CDD0D5";
    // Start from the bottom of the canvas
    ctx.moveTo(0, height);

    let prevX = 0;
    let max = 0; // this is value we will be plotting, the max in a chunk
    let count = 0;
    for (let i = 0; i <= length; i++) {
      const x = Math.round(i * hScale);
      if (x > prevX) {
        // Calculate height based on max value, flipped to go upwards
        const h = Math.round(max) || 1;
        const y = height - h; // Now y starts from bottom and goes up
        count = count + 1;

        ctx.lineTo(prevX, y);

        prevX = x;
        max = 0;
      }

      const value = Math.abs(channel[i] || 0);
      if (value > max) max = value;
    }

    // Finish the drawing by connecting back to the bottom right corner
    ctx.lineTo(prevX, height);

    ctx.stroke(); // Actually draw the waveform
  };

  ctx.beginPath(); // Begin the drawing path

  drawChannel(); // Call the inner function to draw the channel

  ctx.fill(); // fill the area
}

function renderSingleCanvas(
  channelData: number[],
  width: number,
  height: number,
  start: number,
  end: number,
  canvasContainer: HTMLElement
) {
  const pixelRatio = window.devicePixelRatio || 1;
  const canvas = document.createElement("canvas");
  const length = channelData.length;
  canvas.width = Math.round((width * (end - start)) / length);
  canvas.height = height * 0.8 * pixelRatio;
  canvas.style.width = `${Math.floor(canvas.width / pixelRatio)}px`;
  canvas.style.height = `${height * 0.8}px`;
  const left = Math.floor((start * width) / pixelRatio / length);
  canvas.style.left = `${left}px`;
  canvas.id = start.toString();

  canvas.classList.add("bottom-0", "absolute");
  canvasContainer.appendChild(canvas);

  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  renderLineWaveform(channelData.slice(start, end), ctx, 1);
}

const drawWaveform = ({
  scrollContainer,
  viewportContainer,
  width,
  channelData: channelDataRaw,
  timeoutStack,
  clearAllItemsFromTimeoutStack,
  addItemToTimeoutStack,
  scrollLeft,
  afterRender,
  beforeRender,
  chapterPercentage,
}: DrawWaveform): void => {
  clearAllItemsFromTimeoutStack();
  beforeRender?.();
  timeoutStack = [];
  scrollContainer.innerHTML = "";
  const { scrollWidth, clientWidth } = viewportContainer;
  // channelData,
  const startIndex = Math.floor(
    chapterPercentage.start * channelDataRaw.length
  );
  const endIndex = Math.floor(chapterPercentage.end * channelDataRaw.length);
  const channelData = channelDataRaw.slice(startIndex, endIndex);
  const len = channelData.length;
  const scale = len / scrollWidth;
  let viewportWidth = clientWidth;

  const start = Math.floor(Math.abs(scrollLeft) * scale);
  const end = Math.floor(start + viewportWidth * scale);
  const viewportLen = end - start;

  const draw = (start: number, end: number) => {
    renderSingleCanvas(
      channelData,
      width,
      50,
      Math.max(0, start),
      Math.min(end, len),
      scrollContainer
    );
  };

  const headDelay = createDelay(10, addItemToTimeoutStack);
  const tailDelay = createDelay(10, addItemToTimeoutStack);
  const renderHead = (fromIndex: number, toIndex: number) => {
    draw(fromIndex, toIndex);
    if (fromIndex > 0) {
      headDelay(() => {
        renderHead(fromIndex - viewportLen, toIndex - viewportLen);
      });
    } else {
      afterRender?.();
    }
  };
  const renderTail = (fromIndex: number, toIndex: number) => {
    draw(fromIndex, toIndex);
    if (toIndex < len) {
      tailDelay(() => {
        renderTail(fromIndex + viewportLen, toIndex + viewportLen);
      });
    }
  };
  renderHead(start, end);
  if (end < len) {
    renderTail(end, end + viewportLen);
  }
};

/** Timeline component for the editor, provides seek, in and out points for the media. */
export const TimelineWithZoom: React.FC<TimelineProps> = ({
  mediaURL: mediaURLRaw,
  startTime,
  endTime,
  currentTime,
  setCurrentTime,
  updateStartAndEndTime: updateStartAndEndTimeRaw,
  duration,
  cutmagicData,
  updateCutmagicScene,
  chapterPercentage,
  isIntelligentCut,
  id,
  pxPerSec: pxPerSecRaw,
  setBasePxPerSec,
  fitToScreenFunctionRef,
  modifiedFromSliderRef,
}) => {
  const [left, setLeft] = React.useState(0);
  const [, setPxPerSec] = React.useState(pxPerSecRaw);
  const waveformFabricRef = React.useRef<fabric.Canvas | null>(null);
  const waveformWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const canvasContainer = React.useRef<HTMLDivElement | null>(null);
  const [timelineCanvasContainer, { width: timelineCanvasContainerWidth }] =
    useElementSize();

  const [shouldShowWaveform, setShowWaveform] = React.useState<boolean>(false);

  const channelDataRef = React.useRef<{
    channelData?: number[];
    loadRequestedStatus: boolean;
    // objectsData?: Record<number, fabric.Rect>;
  } | null>(null);

  const [mouseInsideSelection, setMouseInsideSelection] = React.useState(false);
  const [waveFormStatus, setWaveFormStatus] = React.useState<
    STATUS | typeof PARTIALLY_GENERATED
  >(STATUS.IDLE);

  const controllerRef = React.useRef<null | AbortController>(null);

  const isMarkerDragging = React.useRef(false);
  const cutmagicMarkersDraggingMap = React.useRef<null | Record<
    string,
    boolean
  >>(null);
  const [cutmagicMarkersPositionsMap, setCutmagicMarkerPositionsMap] =
    React.useState<Record<string, number>>({});
  const [selectionPosition, setSelectionPosition] = React.useState<{
    start: number;
    end: number;
    pxPerSec?: number;
    scrollWidth?: number;
  }>({ start: 0, end: 0 });
  const [selectionTime, setSelectionTime] = React.useState<{
    startTime: number;
    endTime: number;
  }>({ startTime: 0, endTime: 0 });
  const isStartHandleDragging = React.useRef(false);
  let url: null | URL = null;

  if (mediaURLRaw) {
    url = new URL(mediaURLRaw);
  }

  let mediaURL: null | string = null;
  if (url) {
    mediaURL = url.origin + url.pathname;
  }

  const timeoutStackRef = React.useRef<{ timeout?: Timeout }[]>([]);

  const pushToTimeoutStack = (context: { timeout?: Timeout }) => {
    timeoutStackRef.current.push(context);
  };

  const clearAllItemsFromTimeoutStack = () => {
    timeoutStackRef.current.forEach(
      (context) => context.timeout && clearTimeout(context.timeout)
    );
    timeoutStackRef.current = [];
  };

  const isEndHandleDragging = React.useRef(false);
  const oneSecondInPixels = React.useRef(0);
  const updateStartAndEndTime = React.useRef(updateStartAndEndTimeRaw);

  const calculateTime = React.useCallback(
    (position: number, timelineWidth: number) => {
      let time = 0;
      if (duration) {
        time = (position / timelineWidth) * duration * 1000;
      }
      return time;
    },
    [duration]
  );

  // Generate waveform on mediaURL change. This will draw the wave form as soon as the mediaURL is available.
  React.useEffect(() => {
    if (
      waveFormStatus === STATUS.IDLE &&
      mediaURL &&
      mediaURL.includes(".json")
    ) {
      timeoutStackRef.current.forEach(
        (context) => context.timeout && clearTimeout(context.timeout)
      );
      timeoutStackRef.current = [];
      setWaveFormStatus(STATUS.LOADING);
      controllerRef.current = new AbortController();
      try {
        generateWaveForm({
          src: mediaURL,
          id,
          cb: (channelData) => {
            setWaveFormStatus(STATUS.SUCCESS);
            channelDataRef.current = { channelData, loadRequestedStatus: true };
          },
          chapterPercentage,
          controller: controllerRef.current,
        });
      } catch (e) {
        console.debug("[DEBUG]:", e);
      }
    } else if (mediaURL && !mediaURL.includes(".json")) {
      console.debug("[DEBUG]: Not a json file, skipping waveform generation.");
      setWaveFormStatus(STATUS.IDLE);
    }
  }, [mediaURL, id, waveFormStatus, JSON.stringify(chapterPercentage)]);

  React.useEffect(() => {
    if (
      waveFormStatus === STATUS.SUCCESS &&
      canvasContainer.current &&
      viewportContainerRef.current &&
      selectionPosition.scrollWidth &&
      channelDataRef.current?.channelData
    ) {
      const scrollLeft =
        playheadLeftRef.current -
        (viewportContainerRef.current?.clientWidth || 0) / 2;
      drawWaveform({
        scrollContainer: canvasContainer.current,
        viewportContainer: viewportContainerRef.current,
        width: selectionPosition.scrollWidth * (window.devicePixelRatio || 1),
        channelData: channelDataRef.current?.channelData,
        timeoutStack: timeoutStackRef.current,
        addItemToTimeoutStack: pushToTimeoutStack,
        clearAllItemsFromTimeoutStack,
        scrollLeft: Math.max(scrollLeft, 0),
        afterRender: () => setShowWaveform(true),
        chapterPercentage,
      });
    }
  }, [
    waveFormStatus,
    selectionPosition.scrollWidth,
    JSON.stringify(chapterPercentage),
  ]);

  React.useEffect(() => {
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  const viewportContainerRef = React.useRef<HTMLDivElement | null>(null);
  const pxPerSec =
    (viewportContainerRef.current?.clientWidth || 0) / duration +
    (pxPerSecRaw > 1 ? pxPerSecRaw : 0);

  React.useLayoutEffect(() => {
    if (cutmagicData?.length) {
      cutmagicMarkersDraggingMap.current = cutmagicData?.reduce(
        (acc, curr): Record<string, boolean> => {
          acc[curr.id] = false;
          return acc;
        },
        {} as Record<string, boolean>
      );
      const positionMap = cutmagicData?.reduce(
        (acc: Record<string, number>, curr: Scene): Record<string, number> => {
          acc[curr.id] = calculatePosition(curr.start * 1000, pxPerSec);
          return acc;
        },
        {}
      );
      setCutmagicMarkerPositionsMap(positionMap);
    }
  }, [JSON.stringify(cutmagicData), pxPerSec]);

  // this is to ensure the global canvas always has proper dimension
  React.useLayoutEffect(() => {
    waveformFabricRef.current?.setWidth(timelineCanvasContainerWidth);
  }, [timelineCanvasContainerWidth]);

  const cutmagicDataRef = React.useRef(cutmagicData);
  const setCurrentTimeRef = React.useRef(setCurrentTime);
  const cutmagicMarkersPositionsMapRef = React.useRef(
    cutmagicMarkersPositionsMap
  );
  const startTimeRef = React.useRef(startTime);
  const endTimeRef = React.useRef(endTime);
  const currentTimeRef = React.useRef(currentTime);
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
  const timelineCanvasContainerWidthRef = React.useRef(
    timelineCanvasContainerWidth
  );
  const updateCutmagicSceneRef = React.useRef(updateCutmagicScene);

  const selectedPosRef = React.useRef(selectionPosition);
  const selectedTimeRef = React.useRef(selectionTime);
  const isDragging =
    isStartHandleDragging.current || isEndHandleDragging.current;
  const viewportWidth = viewportContainerRef.current?.clientWidth || 0;
  // const pxPerSec = pxPerSecRaw;
  const pxPerSecRef = React.useRef(pxPerSec);
  const selectionWidth = selectionPosition.end - selectionPosition.start;
  const selectionLeft = selectionPosition.start;
  const isZoomingRef = React.useRef(false);
  const [playheadLeft, setPlayheadLeft] = React.useState(0);
  const playheadLeftRef = React.useRef(0);

  const calculatePosition = React.useCallback(
    (time: number, pxPerSecInput?: number) => {
      let position = 0;
      if (duration) {
        position = time / 1000 / duration;

        if (pxPerSecInput) {
          position = position * duration * pxPerSecInput;
        } else {
          const wrapperWidth =
            Math.max(
              (pxPerSecInput || pxPerSec) * duration,
              viewportContainerRef.current?.clientWidth || 0
            ) ||
            waveformWrapperRef.current?.getBoundingClientRect()?.width ||
            0;

          position = position * wrapperWidth;
        }
      }
      return position;
    },
    [duration, pxPerSec]
  );

  const calculatePositionBasedOnWidth = React.useCallback(
    (time: number, width: number) => {
      let position = 0;
      if (duration) {
        position = time / 1000 / duration;
        position = position * width;
      }
      return position;
    },
    [duration]
  );

  const handleCutmagicMarkerDrag = (e: MouseEvent) => {
    if (cutmagicMarkersDraggingMap.current) {
      Object.keys(cutmagicMarkersDraggingMap.current).forEach((key) => {
        if (cutmagicMarkersDraggingMap.current) {
          const isDragging = cutmagicMarkersDraggingMap.current[key];
          if (isDragging) {
            const clientX = e.clientX;
            const { x } =
              scrollContainerRef.current?.getBoundingClientRect() || {
                x: 0,
              };

            const left = clientX - x;
            const index = cutmagicDataRef.current?.findIndex(
              (item) => item.id === key
            );
            const lastIndex = (cutmagicDataRef.current?.length || 0) - 1;
            if (!index) {
              return;
            }

            const lowerBound = calculatePosition(
              (cutmagicDataRef.current?.[index - 1]?.start || 0) * 1000
            );
            const upperBound =
              index === lastIndex
                ? selectedPosRef.current.end
                : calculatePosition(
                    (cutmagicDataRef.current?.[index + 1]?.start || 0) * 1000
                  );

            // checking if the value we are going to set is not lower than the previous item.
            if (
              left < lowerBound ||
              left > upperBound
              // || left > selectionPosition.end ||
              // left < selectionPosition.start
            ) {
              return;
            }

            setCutmagicMarkerPositionsMap((prev) => {
              return {
                ...prev,
                [key]: left,
              };
            });
          }
        }
      });
    }
  };

  const makeAllMarkersFalse = (e: MouseEvent) => {
    cutmagicMarkersDraggingMap.current = Object.keys(
      cutmagicMarkersDraggingMap?.current || {}
    ).reduce((acc, curr) => {
      acc[curr] = false;
      return acc;
    }, {} as Record<string, boolean>);
    isStartHandleDragging.current = false;
    isEndHandleDragging.current = false;
    isMarkerDragging.current = false;
    e.stopPropagation();
  };

  const setCutmagicMarkerPosition = () => {
    if (cutmagicMarkersDraggingMap.current) {
      Object.keys(cutmagicMarkersDraggingMap.current).forEach((key) => {
        if (cutmagicMarkersDraggingMap.current) {
          const isDragging = cutmagicMarkersDraggingMap.current[key];
          if (isDragging) {
            const index = cutmagicDataRef.current?.findIndex(
              (item) => item.id === key
            );
            if (index) {
              const time = calculateTime(
                cutmagicMarkersPositionsMapRef.current[key] || 0,
                scrollContainerRef.current?.clientWidth || 0
              );
              updateCutmagicSceneRef.current?.({ time, index });
            }
          }
        }
      });
    }
  };

  // manages the cursor, start and end trim handles
  const handleStartAndEndMarkerDrag = (e: MouseEvent) => {
    const { x } = scrollContainerRef.current?.getBoundingClientRect() || {
      x: 0,
    };

    const scrollWidth = scrollContainerRef.current?.clientWidth || 0;
    const viewportWidth = viewportContainerRef.current?.clientWidth || 0;
    const leftPosition = e.clientX - x > 0 ? e.clientX - x : 0;

    if (leftPosition >= 0 && leftPosition < scrollWidth) {
      setLeft(leftPosition);
      // Calculate the new current time based on the position of the mouse.
      const percentage = leftPosition / scrollWidth;

      const newCurrentTime =
        Math.round(percentage * duration * 1000 * 10 ** 3) / 10 ** 3;
      const currentTimeRounded =
        Math.round(currentTimeRef.current * 10 ** 3) / 10 ** 3;

      if (isMarkerDragging.current && duration) {
        setCurrentTimeRef.current(newCurrentTime);
      }
      if (
        isStartHandleDragging.current &&
        selectedPosRef.current.end - leftPosition > oneSecondInPixels.current
      ) {
        const isNearStartPosition = currentTimeRounded - newCurrentTime < 500;
        if (
          !isIntelligentCut &&
          (newCurrentTime <= currentTimeRounded || isNearStartPosition)
        ) {
          const value = isNearStartPosition
            ? calculatePosition(currentTimeRef.current)
            : leftPosition;

          setSelectionPosition((prev) => ({ ...prev, start: value }));
        }
      }
      if (
        isEndHandleDragging.current &&
        leftPosition - selectedPosRef.current.start > oneSecondInPixels.current
      ) {
        const isNearEndPosition = newCurrentTime - currentTimeRounded < 500;
        if (
          !isIntelligentCut &&
          (newCurrentTime > currentTimeRounded || isNearEndPosition)
        ) {
          const value = isNearEndPosition
            ? calculatePosition(currentTimeRef.current)
            : leftPosition;

          setSelectionPosition((prev) => ({ ...prev, end: value }));
        }
      }
    }
  };

  const setStartAndEndMarkerPosition = () => {
    if (isStartHandleDragging.current || isEndHandleDragging.current) {
      const startTime = calculateTime(
        Math.round(selectedPosRef.current.start),
        scrollContainerRef.current?.clientWidth || 0
      );

      const endTime = calculateTime(
        Math.round(selectedPosRef.current.end),
        scrollContainerRef.current?.clientWidth || 0
      );

      if (isStartHandleDragging.current && currentTimeRef.current < startTime) {
        // adding 1 ms because it shouldn't exactly overlap with starttime
        // setCurrentTimeRef.current(startTime + 1);
      }
      if (isEndHandleDragging.current && currentTimeRef.current > endTime) {
        // removing 1 ms because it shouldn't exactly overlap with endTime
        // setCurrentTimeRef.current(endTime - 1);
      }

      updateStartAndEndTime.current?.({
        endTime,
        startTime,
        selectionHandle: isStartHandleDragging.current ? "start" : "end",
      });
    }
  };

  React.useEffect(() => {
    cutmagicDataRef.current = cutmagicData;
    cutmagicMarkersPositionsMapRef.current = cutmagicMarkersPositionsMap;
    startTimeRef.current = startTime;
    endTimeRef.current = endTime;
    timelineCanvasContainerWidthRef.current = timelineCanvasContainerWidth;
    selectedPosRef.current = selectionPosition;
    selectedTimeRef.current = selectionTime;
    currentTimeRef.current = currentTime;
    updateCutmagicSceneRef.current = updateCutmagicScene;
    oneSecondInPixels.current = timelineCanvasContainerWidth / duration;
    setCurrentTimeRef.current = setCurrentTime;
    updateStartAndEndTime.current = updateStartAndEndTimeRaw;
    pxPerSecRef.current = pxPerSec;
    playheadLeftRef.current = playheadLeft;
  }, [
    JSON.stringify(cutmagicData),
    JSON.stringify(cutmagicMarkersPositionsMap),
    JSON.stringify(selectionPosition),
    JSON.stringify(selectionTime),
    startTime,
    endTime,
    timelineCanvasContainerWidth,
    currentTime,
    updateCutmagicScene,
    duration,
    setCurrentTime,
    updateStartAndEndTimeRaw,
    pxPerSec,
    playheadLeft,
  ]);

  // Event listeners so that the dragging of markers can work properly.
  React.useEffect(() => {
    const handleMouseUp = (e: MouseEvent) => {
      setCutmagicMarkerPosition();
      setStartAndEndMarkerPosition();
      makeAllMarkersFalse(e);
    };

    const handleMouseMove = (e: MouseEvent) => {
      handleStartAndEndMarkerDrag(e);
      handleCutmagicMarkerDrag(e);
    };

    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [pxPerSec]);

  const scrollWidthRef = React.useRef(0);
  const notStop = React.useRef(true);

  React.useLayoutEffect(() => {
    const start = calculatePosition(startTime);
    const end = calculatePosition(endTime);

    const playheadLeftPos = calculatePosition(currentTime);
    setPlayheadLeft(playheadLeftPos);
    const basePxPerSec =
      (viewportContainerRef.current?.clientWidth || 0) / duration;
    setPxPerSec(basePxPerSec + (pxPerSecRaw > 1 ? pxPerSecRaw : 0));
    const finalPxPerSec = basePxPerSec + (pxPerSecRaw > 1 ? pxPerSecRaw : 0);
    const finalWidth = Math.max(
      finalPxPerSec * duration,
      viewportContainerRef.current?.clientWidth || 0
    );

    const scrollLeft = Math.max(
      playheadLeftPos - (viewportContainerRef.current?.clientWidth || 0) / 2,
      0
    );
    if (
      viewportContainerRef.current &&
      modifiedFromSliderRef.current &&
      finalWidth !== scrollWidthRef.current &&
      notStop.current
    ) {
      viewportContainerRef.current.scrollLeft = scrollLeft;
      scrollWidthRef.current = finalWidth;
    }
    setSelectionPosition({
      start,
      end,
      pxPerSec: finalPxPerSec,
      scrollWidth: finalWidth,
    });
  }, [startTime, endTime, currentTime, duration, pxPerSecRaw]);

  const booleanRefToggle =
    (ref: React.MutableRefObject<boolean>, value: boolean, cb?: VoidFunction) =>
    () => {
      ref.current = value;
      // cb?.();
    };

  const handleInAndOutClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const { x: xBeforeCal } =
      waveformWrapperRef.current?.getBoundingClientRect() || {
        x: 0,
      };

    const x = xBeforeCal;
    const leftPosition = e.clientX - x;
    const scrollWidth = scrollContainerRef.current?.clientWidth || 0;
    if (
      leftPosition > 0 &&
      leftPosition < scrollWidth &&
      !isStartHandleDragging.current &&
      !isEndHandleDragging.current &&
      duration
    ) {
      const percentage = leftPosition / scrollWidth;
      const newCurrentTime = percentage * duration * 1000;
      setCurrentTimeRef.current(newCurrentTime);
      isMarkerDragging.current = true;
    }
  };

  const hoverTime =
    calculateTime(left, scrollContainerRef.current?.clientWidth || 0) -
    startTime;

  const cutmagicSliders = cutmagicData
    ?.map((sceneData) => ({
      ...sceneData,
      start: sceneData.start * 1000,
    }))
    .map((sceneData, index) => {
      const startTimeOfScene = Math.round(sceneData.start * 1000) / 1000;
      const startTimeOfChapter = Math.round(startTime * 1000) / 1000;
      const endTimeOfChapter = Math.round(endTime * 1000) / 1000;
      if (
        startTimeOfScene >= startTimeOfChapter &&
        startTimeOfScene < endTimeOfChapter
      ) {
        const cutmagicSliderLeft = cutmagicMarkersPositionsMap[sceneData.id];
        const TriangleIcon = sceneData.isTwoFace
          ? InvertedHalfTriangleIcon
          : InvertedTriangleIcon;

        return (
          <div
            style={
              {
                "--cutmagic-slider-left": `${cutmagicSliderLeft}px`,
                "--cutmagic-slider-color": `${sceneData.color}`,
                transform: "translateX(-50%)",
                cursor: index === 0 ? "not-allowed" : "ew-resize",
                zIndex: 50,
              } as React.CSSProperties
            }
            key={sceneData.id}
            id={sceneData.id}
            className="cutmagic-slider"
          >
            <div
              className="cutmagic-slider-handle"
              onMouseDown={() => {
                if (index !== 0 && cutmagicMarkersDraggingMap.current) {
                  cutmagicMarkersDraggingMap.current[sceneData.id] = true;
                }
              }}
            >
              <TriangleIcon
                height="15"
                width="15"
                className="cutmagic-slider-icon"
                fillColor={sceneData.color}
              />
              <div className={clsx("cm-stick")} />
            </div>
          </div>
        );
      }
    });

  const timelineLabels = (
    <div className="time-scale relative w-full flex justify-between">
      {Array.from({ length: duration }, (_, k) => k).map((k) => {
        const [primaryNumber, secondaryNumber] = getPrimaryLabelNumber(
          selectionPosition.pxPerSec!
        );
        // const secondaryNumber = getSecondaryLabelNumber(pxPerSec);
        const isPrimary = k % primaryNumber === 0;
        const isSecondary = k % secondaryNumber === 0;
        return (
          <span
            key={k}
            className="time-scale-item"
            style={
              {
                "--opacity": isPrimary || isSecondary ? 1 : 1,
                "--left": `${(selectionPosition.pxPerSec || 0) * k}px`,
                "--width": "3px",
                "--scaleValue": isPrimary || isSecondary ? 1 : 0,
              } as React.CSSProperties
            }
          >
            <span className="flex gap-0">
              {isPrimary ? secondsToHumanReadable(k) : <span className="dot" />}
            </span>
          </span>
        );
      })}
    </div>
  );
  const isFitted = React.useRef(false);

  const fit = () => {
    const selectionDuration = Math.floor(endTime - startTime);
    // 11 seconds right and 11 seconds left
    const durationToFit = selectionDuration / 1000 + 10;
    if (duration && durationToFit < duration && viewportContainerRef.current) {
      const newPxPerSec =
        (viewportContainerRef.current?.clientWidth || 0) / durationToFit;
      const basePxPerSec =
        (viewportContainerRef.current?.clientWidth || 0) / duration;
      const finalPxPerSec = newPxPerSec - basePxPerSec;

      modifiedFromSliderRef.current = false;
      setBasePxPerSec(finalPxPerSec);

      const startingPointInPx = calculatePosition(
        startTime - 5_000,
        finalPxPerSec + basePxPerSec
      );

      setTimeout(() => {
        if (viewportContainerRef.current) {
          viewportContainerRef.current.scrollLeft = startingPointInPx;
        }
      }, 10);
    }
  };

  fitToScreenFunctionRef.current = fit;

  // Auto Fit by default, upon load.
  React.useEffect(() => {
    if (!isFitted.current) {
      fit();
    }
  }, []);

  return (
    <>
      <div
        className={clsx("viewport")}
        ref={viewportContainerRef}
      >
        <div
          style={
            {
              "--timeline-zoom-height": `${TIMELINE_HEIGHT}px`,
              width: `${selectionPosition.scrollWidth}px`,
              boxSizing: "border-box",
              marginTop: "10px",
            } as React.CSSProperties
          }
          ref={scrollContainerRef}
          className={`mx-auto rounded-md relative`}
        >
          {timelineLabels}
          <div style={{ height: "20px" }} />
          <div
            ref={waveformWrapperRef}
            className={clsx(
              "relative",
              "timeline-wrapper",
              mouseInsideSelection &&
                !isStartHandleDragging.current &&
                !isEndHandleDragging.current &&
                "mouseInsideSelection",
              isMarkerDragging.current && "marker-dragging",
              "bg-gray-100",
              "rounded-sm"
            )}
          >
            {hoverTime >= 0 && (
              <div
                id="time-tooltip"
                className="time-tooltip drop-shadow-sm select-none"
                style={
                  {
                    "--time-tooltip-left": `${
                      isMarkerDragging.current
                        ? calculatePosition(currentTime)
                        : left
                    }px`,
                  } as React.CSSProperties
                }
              >
                {formatMillisecondsForTimeline(hoverTime)}
              </div>
            )}
            <div
              id="cursor"
              className="absolute z-10 c-pointer bg-slate-800"
              style={{
                height: "100%",
                width: "2px",
                top: 0,
                left: left,
                transition: "none",
              }}
            />
            <div
              id="marker playhead"
              onMouseDown={booleanRefToggle(isMarkerDragging, true)}
              onMouseUp={() => {
                booleanRefToggle(isMarkerDragging, false);
                trackEditorInteractionEvent(
                  EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
                  EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.PLAYHEAD_USED
                );
              }}
              className="marker-with-zoom absolute cursor-ew-resize"
              style={
                {
                  "--left-position": `${playheadLeft}px`,
                  "--marker-width": `${MARKER_WIDTH}px`,
                  transition:
                    !isMarkerDragging.current && !isZoomingRef.current
                      ? "var(--timeline-zoom-animation)"
                      : "",
                  userSelect: "none",
                } as React.CSSProperties
              }
            >
              <button>
                <PlayHeadTriangle
                  height={15}
                  width={15}
                />
              </button>
            </div>
            {cutmagicSliders}
            <div
              id={clsx("in-and-out")}
              onMouseDown={handleInAndOutClick}
              onMouseEnter={() => {
                setMouseInsideSelection(true);
              }}
              onMouseLeave={() => {
                setMouseInsideSelection(false);
              }}
              className={clsx(
                "absolute z-40 flex rounded-lg border-2 box-border in-and-out",
                isIntelligentCut && "remove-pointer-events"
              )}
              style={{
                height: "100%",
                width: `${selectionWidth}px`,
                top: 0,
                left: selectionLeft,
                cursor: isDragging ? "ew-resize" : "default",
                transition: !isDragging ? "var(--timeline-zoom-animation)" : "",
              }}
            >
              <div
                id="in"
                onMouseDown={booleanRefToggle(isStartHandleDragging, true, () =>
                  setMouseInsideSelection(false)
                )}
                className="start-handle"
              />
              <div
                id="body"
                className="flex-1"
                style={{ height: "100%", background: "transparent" }}
              />
              <div
                id="out"
                onMouseDown={booleanRefToggle(isEndHandleDragging, true, () =>
                  setMouseInsideSelection(false)
                )}
                className="end-handle"
              />
            </div>
            <div
              className={clsx(
                "timeline-canvas-container",
                " absolute bottom-0",
                "w-full",
                "flex",
                shouldShowWaveform ? "opacity-100" : "opacity-0"
              )}
              ref={canvasContainer}
            />
            <div
              id="in-and-out-shadow"
              className={clsx(
                "absolute",
                "z-10",
                "bg-blue-300",
                "opacity-50",
                "rounded-md"
              )}
              style={{
                height: "100%",
                width: `${selectionWidth}px`,
                top: 0,
                left: `${selectionLeft}px`,
              }}
            />
            {waveFormStatus === STATUS.LOADING && (
              <div className="loader"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
