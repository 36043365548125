import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

const parseDataWithOnBoardingID = (data: any, onBoardingId: any) => {
  return { ...data, onBoardingId };
};

const postYoutubeLink = async (
  ytLink: string | null,
  onBoardingId?: any,
  lang?: string
) => {
  // @see https://stackoverflow.com/questions/72665299/how-to-destructure-data-or-error-from-mutation-react-query
  try {
    const { data } = await api.post(ApiEndpoints.POST_MICRO_CONTENT, {
      yt_url: ytLink,
      lang,
    });

    return parseDataWithOnBoardingID(data, onBoardingId);
  } catch (e) {
    throw { e, onBoardingId };
  }

  // return data;
};

export default function usePostYTLink(
  youtubeMutateSuccessCB?: any,
  youtubeMutateErrorCB?: any
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ ytLink, onBoardingId, lang }: any) =>
      postYoutubeLink(ytLink, onBoardingId, lang),
    onSuccess: (data: any) => {
      if (youtubeMutateSuccessCB) {
        youtubeMutateSuccessCB(data, data.onBoardingId);
      }
      queryClient.invalidateQueries({ queryKey: ["all-projects"] });
    },
    onError: (error: any) => {
      if (youtubeMutateErrorCB) {
        youtubeMutateErrorCB(error.e, error.onBoardingId);
      }
    },
  });
}
