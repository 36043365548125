import * as React from "react";
import { useState } from "react";

import { updateCurrentSelectedProject } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import api from "@/api";
import { ApiEndpoints } from "@/api/constants";
import useGetSingleProject from "@/api/useGetSingleProject";

import { sleep } from "@/helpers";

import { AUDIO_GENERATION_STATUS } from "@/interfaces";

// INFO: hook to get audio data from the server.
// It will trigger the audio generation call if the audio is not present.
interface AudioDataHook {
  audioDataURL: string | null;
}

const useAudioData = () => {
  const [audioDataURL, setAudioDataURL] = useState<string | null>(null);
  const currentSelectedProject = useAppSelector(
    (state) => state.homeState.currentSelectedProject
  );
  const dispatch = useAppDispatch();
  const { refetch: refetchProject, data: projectData } = useGetSingleProject(
    currentSelectedProject?.id
  );

  const projectCallPollingRef = React.useRef<any>(null);
  const calledCreateAudio = React.useRef<any>(null);
  const triesCreateCall = React.useRef(0);

  const [audioCreationTries, setAudioCreationTries] = useState(0);
  const [shouldRetryPolling, setShouldRetryPolling] = useState(true);

  const createAudioRequest = () => {
    const parsedData = JSON.parse(currentSelectedProject?.data || "{}");
    return api.post(ApiEndpoints.CREATE_AUDIO, {
      yt_url: parsedData?.yt_url,
      remote_url: parsedData?.remote_url,
      project_id: currentSelectedProject?.id,
    });
  };

  let audioDataURLFromProjectData: null | string = null;

  if (currentSelectedProject?.data) {
    const projectDataParsed = JSON.parse(currentSelectedProject.data);
    audioDataURLFromProjectData = projectDataParsed.audio_remote_url;
  }

  const pollProjectCall = async (refetchProject: any, interval: number) => {
    if (!projectCallPollingRef.current) {
      return;
    }

    await sleep(interval);
    await refetchProject();
    pollProjectCall(refetchProject, interval);
  };

  React.useEffect(() => {
    if (currentSelectedProject?.data) {
      const projectData = JSON.parse(currentSelectedProject.data);
      if (projectData.audio_remote_url) {
        setAudioDataURL(projectData.audio_remote_url);
      } else {
        setAudioDataURL(null);
      }
    }
  }, [currentSelectedProject?.data]);

  React.useEffect(() => {
    // if polling already happening then exit.
    if (
      projectCallPollingRef.current ||
      calledCreateAudio.current ||
      audioDataURLFromProjectData
    ) {
      return;
    }
    // Only run if audio URL is not present or the create audio called is already done.
    if (
      (audioDataURL === null ||
        (audioDataURL && !audioDataURL?.includes(".json"))) &&
      !calledCreateAudio.current &&
      audioCreationTries !== 2 &&
      shouldRetryPolling
    ) {
      calledCreateAudio.current = true;
      // Polling logic: https://vidyoai.slack.com/canvas/C02SDS1JSF5
      console.log("[DEBUG]: Create audio call started. 🟡");
      createAudioRequest()
        .then(() => {
          // increase the counter by 1.
          setAudioCreationTries((prev) => prev + 1);
          triesCreateCall.current = triesCreateCall.current + 1;
          console.debug("[DEBUG]: Create audio call success. 🟢");
          // mark the call as true.
          projectCallPollingRef.current = true;
          setShouldRetryPolling(false);
          // poll the current project call.
          pollProjectCall(refetchProject, 3_000);
        })
        .catch((e) => {
          console.debug("[DEBUG]: Create audio call failed. 🔴", e);
        });
    }
  }, [
    shouldRetryPolling,
    audioDataURL,
    audioCreationTries,
    audioDataURLFromProjectData,
  ]);

  React.useEffect(() => {
    if (projectData && !audioDataURLFromProjectData) {
      const projectDataParsed = JSON.parse(projectData.data);
      const audioExtractionStatus = projectDataParsed.audio_extraction_status;

      if (audioExtractionStatus === AUDIO_GENERATION_STATUS.COMPLETE) {
        dispatch(
          updateCurrentSelectedProject({
            ...projectData,
            data: JSON.stringify({
              ...JSON.parse(currentSelectedProject?.data || ""),
              ...JSON.parse(projectData.data),
            }),
          })
        );

        if (projectCallPollingRef.current) {
          projectCallPollingRef.current = false;
          calledCreateAudio.current = false;
        }
      }
      if (audioExtractionStatus === AUDIO_GENERATION_STATUS.ERROR) {
        if (triesCreateCall.current < 2) {
          // manage retries
          setShouldRetryPolling(true);
        } else {
          setAudioDataURL(null);
        }
        calledCreateAudio.current = false;
        projectCallPollingRef.current = false;
      }
    }
  }, [
    JSON.stringify(projectData),
    // we need to set this data with what we recieved from project api.
    JSON.parse(currentSelectedProject?.data || ""),
    audioDataURLFromProjectData,
  ]);

  return { audioDataURL };
};

export default useAudioData;
