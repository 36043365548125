// f(x) = 1.055^x
export function createExponentialMappingFunction(
  inputRange: [number, number],
  outputRange: [number, number]
): (input: number) => number {
  // Destructure the ranges for readability
  const [inputStart, inputEnd] = inputRange;
  const [outputStart, outputEnd] = outputRange;

  // Calculate the base of the exponential function
  const b = Math.pow(outputEnd / outputStart, 1 / (inputEnd - inputStart));

  // Calculate the scale factor
  const a = outputStart / Math.pow(b, inputStart);

  // Return the mapping function
  return function (input: number): number {
    if (input > inputEnd) {
      return outputStart; // or handle this case as you see fit
    }
    if (input > inputEnd) {
      return outputEnd; // or handle this case as you see fit
    }
    return a * Math.pow(b, input);
  };
}

export function createInverseExponentialMappingFunction(
  inputRange: [number, number],
  outputRange: [number, number]
): (output: number) => number {
  // Destructure the ranges for readability
  const [inputStart, inputEnd] = inputRange;
  const [outputStart, outputEnd] = outputRange;

  // Calculate the base of the exponential function
  const b = Math.pow(outputEnd / outputStart, 1 / (inputEnd - inputStart));

  // Calculate the scale factor
  const a = outputStart / Math.pow(b, inputStart);

  // Return the inverse mapping function
  return function (output: number): number {
    // Handle cases where output is outside the expected range
    if (output < outputStart) return inputStart;
    if (output > outputEnd) return inputEnd;

    // Calculate and return the input corresponding to the given output
    return Math.log(output / a) / Math.log(b);
  };
}
