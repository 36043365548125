const REDUX_LOCAL_STORAGE_KEY = "redux";
export function loadStateForReduxFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem(REDUX_LOCAL_STORAGE_KEY);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export async function saveStateForReduxFromLocalStorage(state: any) {
  try {
    if (state) {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(REDUX_LOCAL_STORAGE_KEY, serializedState);
    }
  } catch (e) {
    // Ignore
  }
}
export const isProd = () =>
  window.location.hostname === "app.vidyo.ai" ||
  window.location.hostname === "legacy.vidyo.ai";

export const getBaseUrl = () => {
  if (isProd()) {
    return "https://api.vidyo.ai";
  }
  return "https://staging.vidyo.ai";
};

export const getCRUDBaseUrl = () => {
  if (isProd()) {
    return "https://api.vidyo.ai/api";
  }
  return "https://staging.vidyo.ai/api";
};

export const getValueFromLocalStorage = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (!serializedState) return undefined;
    // If the value is a string, we need to parse it
    if (serializedState[0] === '"') {
      return JSON.parse(serializedState);
    }
    return serializedState;
  } catch (e) {
    console.warn("Error while getting value from local storage", e);
    return undefined;
  }
};

export const saveValueToLocalStorage = (key: string, value: any) => {
  try {
    if (typeof value === "undefined") return;
    let updatedValue: string;

    if (typeof value === "string") {
      updatedValue = value;
    } else {
      updatedValue = JSON.stringify(value);
    }
    localStorage.setItem(key, updatedValue);
  } catch (e) {
    console.warn("Error while saving to local storage", e);
    return undefined;
  }
};

export const removeValueFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.warn("Error while removing value from local storage", e);
    return undefined;
  }
};

export const clearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (e) {
    console.warn("Error while clearing local storage", e);
    return undefined;
  }
};
