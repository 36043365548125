import { Fragment, useCallback, useRef, useState } from "react";

import { Menu, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { fabric } from "fabric";
import { Group } from "fabric/fabric-impl";
import parseSRT from "parse-srt";

import { updateSelectedDraft } from "@/store/draftSlice";
import {
  changeSelectedLayout,
  toggleSceneChange,
  updateAllSceneChanges,
  updateAutoAddEmojisToSubtitles,
} from "@/store/editorSlice";
import { updateCurrentSelectedMicroContent } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useDeleteUserTemplate from "@/api/useDeleteUserTemplate";
import useUserConsumedProcessingTime from "@/api/useUserConsumedProcessingTime";

import { BUTTON_IDS } from "@/constants/segment-analytics";

import {
  GroupedTextProps,
  addSubtitleStyles,
  calculateScaleUpSizeFromBaseTemplate,
  centerFaceInClipPath,
  formatVideoDuration,
  getClipPath,
  getNewFabricStaticCanvas,
  getProgressBar,
  getTextbox,
  hasMoreThanKeys,
  loadFont,
  updateGroupedText,
} from "@/helpers";

import { trackUserButtonEvent } from "@/utils/SegmentAnalytics";
import { scaleEachProperty, scaleMultiplier } from "@/utils/scaleMultiplier";

import { TextboxStyles } from "@/interfaces";

import {
  FontStyle,
  FontWeight,
  TemplateVariant,
  TextElementType,
  VideoLayout,
} from "@/enums";

import {
  INIT_TEXT_STYLES,
  MIN_LINE_HEIGHT,
  TEXT_TRANSFORM,
} from "@/views/editor/constant";

import PreviewBlueIcon from "@/assets/icons/preview-blue.svg";

const DEFAULT_IMAGE_URL =
  "https://storage.googleapis.com/vidyo-ai-static/thumbnail/default-template-image.jpeg";

const DEFAULT_FACE_COORDS = [
  { x: 466, y: 426, x1: 368, y1: 298, x2: 565, y2: 555 },
  { x: 1497, y: 429, x1: 1390, y1: 291, x2: 1605, y2: 567 },
];

const { PREVIEW_CLIP_TEMPLATE_INDIVIDUAL } = BUTTON_IDS.TEMPLATE_STATIC_PREVIEW;

const TemplateStaticPreview = ({
  template,
  variant,
  handelChangeTemplate,
  dimensions,
  layout,
  showDuration = true,
  isUserTemplate = false,
  isSelected = false,
}: {
  template: any;
  variant?: TemplateVariant;
  handelChangeTemplate?: (template: any) => void;
  dimensions: { width: number; height: number };
  layout: VideoLayout;
  showDuration?: boolean;
  isUserTemplate?: boolean;
  isSelected?: boolean;
}) => {
  const previewFabricRef = useRef<any>(null);

  const dispatch = useAppDispatch();

  const { isLoading: usageDataIsLoading } = useUserConsumedProcessingTime();

  const { mutate, isLoading } = useDeleteUserTemplate();

  const userSocialMediaHandles = useAppSelector(
    (state) => state.homeState.userSocialMediaHandles
  );

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const uid = useAppSelector((state) => state.authState.currentUser.uid);
  const [isHovered, setIsHovered] = useState(false);

  const resetReduxStateRelatedToDrafts = () => {
    dispatch(updateSelectedDraft(null));
    dispatch(updateAutoAddEmojisToSubtitles(false));
    dispatch(toggleSceneChange(false));
    dispatch(updateAllSceneChanges([]));
  };
  const handelOpenPreviewClip = () => {
    const templateData = {
      id: template.id,
      face_coord: template.face_coord || [],
      start: template.start,
      end: template.end,
      srt_string: template.srt_string,
      imageUrl: template.imageUrl,
      hasTwoFace: template.hasTwoFace,
      gist: template.gist,
      text: template.text,
      chapter_end: template.chapter_end,
      chapter_start: template.chapter_start,
      backgroundColor: template?.backgroundColor,
    };

    dispatch(updateCurrentSelectedMicroContent(templateData));
    dispatch(changeSelectedLayout(layout));

    // to reset the draft state from redux
    resetReduxStateRelatedToDrafts();
  };

  const TEMPLATE_WIDTH = dimensions?.width;
  const TEMPLATE_HEIGHT = dimensions?.height;

  const addTextToCanvas = ({
    text,
    scaleRatio,
    isSocialText = false,
    cb,
    adjustHeight = false,
  }: {
    text: any;
    scaleRatio: number;
    isSocialText?: boolean;
    cb?: (el: Group) => void;
    adjustHeight?: boolean;
  }) => {
    const textStyles: TextboxStyles = {
      fontSize: text.fontSize * scaleRatio,
      fill: text.fontColor,
      fontFamily: text.fontFamily,
      textAlign: text.textAlign,
      effectType: text.effect_type,
      effectColor: text.effect_color,
      underline: text.underline,
      fontStyle: text.italic ? FontStyle.ITALIC : FontStyle.NORMAL,
      fontWeight: text.bold ? FontWeight.BOLD : FontWeight.NORMAL,
      lineHeight: text.lineHeight || MIN_LINE_HEIGHT,
      noEffect: text.noEffect,
      stroke: text.stroke
        ? {
            ...text.stroke,
            config: {
              ...text.stroke.config,
              width: text.stroke.config.width * scaleRatio,
            },
          }
        : null,
      shadow: text.shadow,
      textBgColor: {
        ...text.textBgColor,
        radius: text.textBgColor?.radius * scaleRatio,
      },
      blockBackground: {
        ...text.blockBackground,
        radius: text.blockBackground?.radius * scaleRatio,
      },
      margin: scaleEachProperty(
        text.margin || INIT_TEXT_STYLES.margin,
        scaleRatio
      ),
      padding: scaleEachProperty(
        text.padding || INIT_TEXT_STYLES.padding,
        scaleRatio
      ),
      maxHeight: scaleMultiplier(text.maxHeight, scaleRatio),
      textTransform: text?.textTransform || TEXT_TRANSFORM.DEFAULT,
    };

    let textToDisplay = text.content || text.text;

    const fabricText = getTextbox({
      text: textToDisplay,
      // Default offset because of the default padding
      left: text.left + (isSocialText ? 5 : 0),
      top: text.top - (isSocialText ? 3 : 0),
      width: text.width,
      maxHeight: text.maxHeight,
      scaleRatio: scaleRatio,
      style: textStyles,
      type: isSocialText
        ? TextElementType.SOCIAL_HANDLE_TEXT
        : TextElementType.NORMAL_TEXT,
    });

    loadFont(
      fabricText,
      textStyles as GroupedTextProps,
      () => {
        previewFabricRef.current.renderAll();
        if (cb) {
          cb(fabricText);
        }
      },
      adjustHeight
    );
    previewFabricRef.current.add(fabricText);
    previewFabricRef.current.bringToFront(fabricText);
    previewFabricRef.current.renderAll();

    return fabricText;
  };

  const addSubsToCanvas = (template: any, templateScaleUpRatio: any) => {
    const updateStyles = (el: Group) => {
      if (template.subtitle.style) {
        const style = template.subtitle;
        const subStyle = {
          fontSize: style.fontSize * templateScaleUpRatio,
          fill: style.fontColor,
          fontFamily: style.fontFamily,
          textAlign: style.textAlign,
          effectType: style.effect_type,
          effectColor: style.effect_color,
          underline: style.underline,
          fontStyle: style.italic ? FontStyle.ITALIC : FontStyle.NORMAL,
          fontWeight: style.bold ? FontWeight.BOLD : FontWeight.NORMAL,
          lineHeight: style.lineHeight || MIN_LINE_HEIGHT,
          noEffect: style.noEffect,
          stroke: style.stroke
            ? {
                ...style.stroke,
                config: {
                  ...style.stroke.config,
                  width: style.stroke.config.width * templateScaleUpRatio,
                },
              }
            : null,
          shadow: style.shadow,
          styleBgColor: {
            ...style.styleBgColor,
            radius: style.styleBgColor?.radius * templateScaleUpRatio,
          },
          blockBackground: {
            ...style.blockBackground,
            radius: style.blockBackground?.radius * templateScaleUpRatio,
          },
          margin: scaleEachProperty(
            style.margin || INIT_TEXT_STYLES.margin,
            templateScaleUpRatio
          ),
          padding: scaleEachProperty(
            style.padding || INIT_TEXT_STYLES.padding,
            templateScaleUpRatio
          ),
          textBgColor: {
            ...style.textBgColor,
            radius: style.textBgColor?.radius * templateScaleUpRatio,
          },
          textTransform: style.textTransform,
          style: style.style,
        };
        const subsArr = template.srt_string
          ? parseSRT(template.srt_string)
          : [];
        if (subsArr.length > 0 || template.subtitle.text) {
          const sub = subsArr[0] || {
            text: template.subtitle.text,
            id: 1,
            start: 0,
            end: 1,
          };
          template.start = template.start || 0;
          addSubtitleStyles(
            sub,
            {
              ...subStyle,
              alreadyTransformed: true,
            },
            el,
            template,
            template.start / 1000
          );
          previewFabricRef.current.renderAll();
        }
      }
    };
    const fabricEl = addTextToCanvas({
      text: template.subtitle,
      scaleRatio: templateScaleUpRatio,
      cb: updateStyles,
    });
    updateStyles(fabricEl);
  };

  const templatePreviewCanvasRef: any = useCallback((element: any) => {
    if (!element) {
      previewFabricRef.current?.dispose();
      previewFabricRef.current = null;
      return previewFabricRef;
    }

    previewFabricRef.current = getNewFabricStaticCanvas(
      element,
      template?.backgroundColor
    );

    if (previewFabricRef.current) {
      if (template.backgroundImageUrl) {
        fabric.Image.fromURL(template.backgroundImageUrl, function (img: any) {
          previewFabricRef.current?.setBackgroundImage(
            img,
            previewFabricRef.current.renderAll.bind(previewFabricRef.current),
            {
              scaleX: previewFabricRef.current.width / img.width,
              scaleY: previewFabricRef.current.height / img.height,
            }
          );
        });
      }

      const templateScaleUpRatio = calculateScaleUpSizeFromBaseTemplate(
        template.height,
        TEMPLATE_HEIGHT
      );

      const clipPathProps = template.videoProperties[0].clipPath;
      const clipPath = getClipPath({
        left: clipPathProps.left,
        top: clipPathProps.top,
        width: clipPathProps.width,
        height: clipPathProps.height,
        scaleRatio: templateScaleUpRatio,
      });

      if (!template?.imageUrl) {
        const updatedTemplateWithDefaultImgUrl = {
          ...template,
          imageUrl: DEFAULT_IMAGE_URL,
          face_coord: DEFAULT_FACE_COORDS,
        };
        template = updatedTemplateWithDefaultImgUrl;
      }

      fabric.Image.fromURL(template.imageUrl, (img: any) => {
        if (previewFabricRef.current) {
          img.set({
            left: clipPath.left,
            top: clipPath.top,
            selectable: false,
            hasControls: false,
            clipPath: clipPath,
          });

          centerFaceInClipPath({
            fabricVideoElement: img,
            clipPath: clipPath,
            allFaceCoords: template.face_coord,
            videoLayout: layout,
          });

          previewFabricRef.current.add(img);
          previewFabricRef.current.bringToFront(img);

          if (template.progressbar) {
            const progressBarRect = getProgressBar({
              height: template.progressbar.height,
              width: template.progressbar.width,
              top: template.progressbar.top,
              left: template.progressbar.left,
              maxWidth: template.width,
              fill: template.progressbar.fill,
              scaleRatio: templateScaleUpRatio,
            });

            previewFabricRef.current.add(progressBarRect);
            previewFabricRef.current.bringToFront(progressBarRect);
          }

          if (template.socials.length) {
            template.socials.forEach((social: any) => {
              fabric.Image.fromURL(social.image.url, (img: any) => {
                // need better way to handle add error
                if (previewFabricRef.current) {
                  img
                    .scaleToWidth(social.image.width * templateScaleUpRatio)
                    .set({
                      left: social.image.left * templateScaleUpRatio,
                      top: social.image.top * templateScaleUpRatio,
                    });

                  previewFabricRef.current.add(img);

                  // updating the social content with user preference
                  userSocialMediaHandles.forEach((socialHandel: any) => {
                    if (socialHandel.id === social.image.id && social.text) {
                      let updatedSocial = { ...social };
                      updatedSocial = {
                        ...updatedSocial,
                        text: {
                          ...updatedSocial.text,
                          content:
                            socialHandel.socialMediaHandle ||
                            social.text.content,
                        },
                      };
                      social = updatedSocial;
                    }
                  });

                  if (social.text) {
                    addTextToCanvas({
                      text: social.text,
                      scaleRatio: templateScaleUpRatio,
                      isSocialText: true,
                      adjustHeight: true,
                    });
                  }
                }
              });
            });
          }

          if (template?.images?.length) {
            template?.images?.forEach((image: any) => {
              fabric.Image.fromURL(image.url, (img: any) => {
                // need better way to handle add error
                if (previewFabricRef.current) {
                  img.scaleToWidth(image.width * templateScaleUpRatio).set({
                    left: image.left * templateScaleUpRatio,
                    top: image.top * templateScaleUpRatio,
                  });

                  previewFabricRef.current.add(img);
                }
              });
            });
          }
          if (template?.logos?.length) {
            template?.logos?.forEach((image: any) => {
              fabric.Image.fromURL(image.url, (img: any) => {
                // need better way to handle add error
                if (previewFabricRef.current) {
                  img.scaleToWidth(image.width * templateScaleUpRatio).set({
                    left: image.left * templateScaleUpRatio,
                    top: image.top * templateScaleUpRatio,
                  });

                  previewFabricRef.current.add(img);
                }
              });
            });
          }

          const addTextAndSubtitleToTemplate = () => {
            template.texts.forEach((text: any) =>
              addTextToCanvas({
                text,
                scaleRatio: templateScaleUpRatio,
                adjustHeight: true,
              })
            );

            if (hasMoreThanKeys(template.subtitle, 2)) {
              addSubsToCanvas(template, templateScaleUpRatio);
            }
          };

          // to get the text in top of second video
          if (template.videoProperties.length > 1) {
            const clipPath1Props = template.videoProperties[1].clipPath;
            const clipPath1 = getClipPath({
              left: clipPath1Props.left,
              top: clipPath1Props.top,
              width: clipPath1Props.width,
              height: clipPath1Props.height,
              scaleRatio: templateScaleUpRatio,
            });

            fabric.Image.fromURL(template.imageUrl, (img: any) => {
              if (previewFabricRef.current) {
                img.set({
                  left: clipPath1.left,
                  top: clipPath1.top,
                  selectable: false,
                  hasControls: false,
                  clipPath: clipPath1,
                });

                centerFaceInClipPath({
                  fabricVideoElement: img,
                  clipPath: clipPath1,
                  allFaceCoords: template.face_coord,
                  videoLayout: layout,
                  isRightFace: true,
                });

                previewFabricRef.current.add(img);
                previewFabricRef.current.bringToFront(img);
                if (template?.bRolls?.length) {
                  template?.bRolls?.forEach((image: any) => {
                    fabric.Image.fromURL(image.url, (img: any) => {
                      // need better way to handle add error
                      if (previewFabricRef.current) {
                        img
                          .scaleToWidth(image.width * templateScaleUpRatio)
                          .set({
                            left: image.left * templateScaleUpRatio,
                            top: image.top * templateScaleUpRatio,
                          });

                        previewFabricRef.current.add(img);
                        addTextAndSubtitleToTemplate();
                      }
                    });
                  });
                } else {
                  addTextAndSubtitleToTemplate();
                }
              }
            });
          } else {
            if (template?.bRolls?.length) {
              template?.bRolls?.forEach((image: any) => {
                fabric.Image.fromURL(image.url, (img: any) => {
                  // need better way to handle add error
                  if (previewFabricRef.current) {
                    img.scaleToWidth(image.width * templateScaleUpRatio).set({
                      left: image.left * templateScaleUpRatio,
                      top: image.top * templateScaleUpRatio,
                    });

                    previewFabricRef.current.add(img);
                    addTextAndSubtitleToTemplate();
                  }
                });
              });
            } else {
              addTextAndSubtitleToTemplate();
            }
          }
        }
      });
    }

    return previewFabricRef;
  }, []);

  return (
    <div
      className={`${
        variant === TemplateVariant.SMALL ||
        variant === TemplateVariant.BULK_EXPORT
          ? "w-min"
          : "w-full"
      } flex justify-center`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={clsx(
          "relative rounded-t-2xl border-2 bg-transparent p-1",
          variant === TemplateVariant.SMALL && "rounded-b-xl",
          variant === TemplateVariant.BULK_EXPORT && "rounded-b-xl",
          variant === TemplateVariant.DRAFT && "rounded-b-md border-none p-0",
          variant === TemplateVariant.MY_TEMPLATE_PAGE && "border-none p-0",
          {
            "border-blue-600":
              variant === TemplateVariant.BULK_EXPORT
                ? isSelected
                : currentSelectedMicroContent?.id === template.id,
            "border-transparent":
              variant === TemplateVariant.BULK_EXPORT
                ? !isSelected
                : currentSelectedMicroContent?.id !== template.id,
          }
        )}
        onClick={() => {
          handelChangeTemplate && handelChangeTemplate(template);
        }}
      >
        <div
          onClick={() => {
            if (usageDataIsLoading || handelChangeTemplate) return;
            handelOpenPreviewClip();
          }}
          className={clsx(
            variant === TemplateVariant.MY_TEMPLATE_PAGE ||
              variant === TemplateVariant.DRAFT
              ? "cursor-default"
              : "cursor-pointer"
          )}
          id="template-preview"
        >
          {showDuration && (
            <span
              className={clsx(
                "absolute bottom-28 right-0 z-10 mr-2 rounded bg-blue-100 px-2.5 py-0.5 text-xs font-semibold text-blue-800 dark:bg-blue-200 dark:text-blue-800",
                variant === TemplateVariant.SMALL && "!bottom-2",
                variant === TemplateVariant.BULK_EXPORT && "!bottom-2",
                variant === TemplateVariant.DRAFT && "!bottom-0"
              )}
            >
              {formatVideoDuration(template.end - template.start)}
            </span>
          )}
          <canvas
            width={TEMPLATE_WIDTH}
            height={TEMPLATE_HEIGHT}
            className={clsx(
              variant === TemplateVariant.SMALL && "rounded-lg",
              variant === TemplateVariant.BULK_EXPORT && "rounded-lg",
              variant === TemplateVariant.DRAFT && "!rounded-none",
              "rounded-t-xl"
            )}
            ref={templatePreviewCanvasRef}
          />
        </div>
        {variant === TemplateVariant.SMALL ||
        variant === TemplateVariant.DRAFT ||
        variant === TemplateVariant.BULK_EXPORT ? (
          <></>
        ) : (
          <div className="rounded-b-xl bg-white p-2 pt-4 shadow-2xl">
            <div className="flex items-center justify-between">
              <p className="w-44 truncate">
                {variant === TemplateVariant.MY_TEMPLATE_PAGE
                  ? template?.name || template?.id
                  : template.gist}
              </p>
              {variant === TemplateVariant.MY_TEMPLATE_PAGE ? (
                <div className="absolute bottom-2 right-3 w-44 text-right">
                  <Menu
                    as="div"
                    className="relative inline-block text-left"
                  >
                    <div>
                      <Menu.Button
                        className={`inline-flex w-full justify-center rounded-md bg-gray-50 ${
                          isLoading ? "px-1.5 py-1.5" : "px-2.5 py-1.5"
                        } text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        {isLoading ? (
                          <div
                            role="status"
                            className="relative mx-auto flex w-max"
                          >
                            {/* TODO: MOVE SVG ELEMENTS TO DIFFERENT FILE */}
                            <svg
                              aria-hidden="true"
                              className="h-4 w-4 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <svg
                            id="Layer_1"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 29.96 122.88"
                            className="w-1"
                          >
                            <path
                              fillRule="evenodd"
                              d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z"
                            />
                          </svg>
                        )}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-20 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-2 ">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-blue-500 text-white"
                                    : "text-red-500"
                                } flex w-full cursor-pointer items-center rounded-md px-2 py-1.5 text-base`}
                                onClick={() =>
                                  mutate({
                                    userId: uid,
                                    templateId: `${layout}.${template.id}`,
                                  })
                                }
                                id={`delete-project-card-${template.id}`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  className="mr-1.5 h-4 w-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                                <span>Delete Template</span>
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              ) : null}
            </div>
            {variant === TemplateVariant.MY_TEMPLATE_PAGE ? (
              <></>
            ) : (
              <button
                type="button"
                onClick={() => {
                  trackUserButtonEvent(
                    PREVIEW_CLIP_TEMPLATE_INDIVIDUAL.ID,
                    PREVIEW_CLIP_TEMPLATE_INDIVIDUAL.ACTION
                  );
                  handelOpenPreviewClip();
                }}
                className="my-2 inline-flex w-full items-center  justify-center rounded-lg border border-blue-600 bg-white px-5 py-2 text-center text-sm font-medium text-blue-600 hover:bg-gray-50 focus:outline-none"
                id={PREVIEW_CLIP_TEMPLATE_INDIVIDUAL.ID}
              >
                <img
                  src={PreviewBlueIcon}
                  className="mr-2"
                />
                Preview Clip
              </button>
            )}
          </div>
        )}
        {isUserTemplate && isHovered && (
          <div className="absolute right-0 top-0 z-20 m-1">
            <TrashIcon
              className="border-3 h-6 w-6 cursor-pointer rounded-full border-solid border-gray-200 bg-white p-1 text-red-500"
              onClick={(e) => {
                e.stopPropagation();
                mutate({
                  userId: uid,
                  templateId: `${layout}.${template.id}`,
                });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateStaticPreview;
