import * as React from "react";
import { useCopyToClipboard } from "react-use";

import clsx from "clsx";
import { Tooltip } from "flowbite-react";

import { useAppSelector } from "@/store/hooks";

import { EDITOR_INTERACTION_DATA } from "@/constants/amplitude";

import { getColonSeparatedTimeWithMillis } from "@/helpers";

import { trackEditorInteractionEvent } from "@/utils/amplitudeAnalytcs";

import {
  DEFAULT_COLON_SEPARATED_TIME_IN_MILLIS,
  DEFAULT_OUTRO_LENGTH_IN_SEC,
} from "@/views/editor/constant";

interface VideoPlayTimeProps {
  outroTimeLeft: number;
  videoElRef: React.MutableRefObject<HTMLVideoElement | null>;
  isMainCanvasLoaded: boolean;
  outroEnabled: boolean;
  handleOutroTimeClick: () => void;
}

export const VideoPlayTime = ({
  outroTimeLeft,
  videoElRef,
  isMainCanvasLoaded,
  outroEnabled,
  handleOutroTimeClick,
}: VideoPlayTimeProps): JSX.Element => {
  const [stateStartTime, copyToClipboardStartTime] = useCopyToClipboard();
  const [startTimeCopied, setStartTimeCopied] = React.useState(false);
  const [stateEndTime, copyToClipboardEndTime] = useCopyToClipboard();
  const [endTimeCopied, setEndTimeCopied] = React.useState(false);
  const editorCurrentVideoTime = useAppSelector(
    (state) => state.editorState.editorCurrentVideoTime
  );
  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const outroLengthInSecond =
    useAppSelector((state) => state.editorState.outroLengthInSecond) ||
    DEFAULT_OUTRO_LENGTH_IN_SEC;

  // to give constant width to each character, we have to do this.
  const beforeSlashItems = !isMainCanvasLoaded
    ? DEFAULT_COLON_SEPARATED_TIME_IN_MILLIS
    : getColonSeparatedTimeWithMillis(
        editorCurrentVideoTime * 1000 - currentSelectedMicroContent.start
      );

  const afterSlashItems = getColonSeparatedTimeWithMillis(
    currentSelectedMicroContent.end - currentSelectedMicroContent.start
  );

  const timeItemsMapper = (i: string, index: number) => (
    <span
      key={`${i}-${index}`}
      className={clsx("block", ![":", "."].includes(i) && "w-2")}
    >
      {i}
    </span>
  );

  React.useEffect(() => {
    let startTimeTimeoutId: null | ReturnType<typeof setTimeout> = null;
    if (stateStartTime && stateStartTime.value) {
      setStartTimeCopied(true);
      startTimeTimeoutId = setTimeout(() => {
        setStartTimeCopied(false);
      }, 3_000);
    }
    return () => {
      if (startTimeTimeoutId) {
        clearTimeout(startTimeTimeoutId);
      }
    };
  }, [stateStartTime]);
  React.useEffect(() => {
    let endTimeTimeoutId: null | ReturnType<typeof setTimeout> = null;
    if (stateEndTime && stateEndTime.value) {
      setEndTimeCopied(true);
      endTimeTimeoutId = setTimeout(() => {
        setEndTimeCopied(false);
      }, 3_000);
    }
    return () => {
      if (endTimeTimeoutId) {
        clearTimeout(endTimeTimeoutId);
      }
    };
  }, [stateEndTime]);

  const copyMessage = "Click to copy";
  const copiedMessage = "Copied";

  const formatOutroTime = (time: number) => {
    return new Date(time)?.toISOString()?.substring(11, 19);
  };

  const outroPlayTime = (
    <div
      className={`${
        outroTimeLeft > 0 ? "border-blue-500" : "border-green-300"
      } flex cursor-pointer updateSubs rounded-md border-1 bg-green-300 p-1.5 text-xs`}
      onClick={handleOutroTimeClick}
    >
      <div>
        {outroTimeLeft
          ? formatOutroTime(
              currentSelectedMicroContent.end -
                currentSelectedMicroContent.start +
                (outroLengthInSecond - outroTimeLeft) * 1000
            )
          : formatOutroTime(
              currentSelectedMicroContent.end -
                currentSelectedMicroContent.start
            )}
      </div>
      /
      <div>
        {formatOutroTime(
          currentSelectedMicroContent.end -
            currentSelectedMicroContent.start +
            outroLengthInSecond * 1000
        )}
      </div>
    </div>
  );

  return (
    <>
      <div
        className={clsx(
          "flex ml-2 items-center rounded-lg  bg-gray-100 p-0.5 text-xs font-bold",
          outroTimeLeft <= 0 && !videoElRef.current?.paused
            ? "border-blue-500"
            : "border-gray-200",
          "text-slate-600 font-normal text-center"
        )}
      >
        <Tooltip
          content={startTimeCopied ? copiedMessage : copyMessage}
          placement="top"
          className="z-50"
        >
          <button
            onClick={() => {
              copyToClipboardStartTime(beforeSlashItems);
              setEndTimeCopied(false);
              trackEditorInteractionEvent(
                EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
                EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.TIMESTAMP_COPIED
              );
            }}
            id="copy-start-time"
            className="flex mr-0.5 p-1 rounded-lg hover:bg-gray-200"
          >
            {beforeSlashItems.split("").map(timeItemsMapper)}
          </button>
        </Tooltip>
        /
        <Tooltip
          placement="top"
          content={endTimeCopied ? copiedMessage : copyMessage}
          className="z-50"
        >
          <button
            onClick={() => {
              copyToClipboardEndTime(afterSlashItems);
              setStartTimeCopied(false);
              trackEditorInteractionEvent(
                EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
                EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.TIMESTAMP_COPIED
              );
            }}
            id="copy-end-time"
            className="flex ml-0.5 p-1 rounded-lg hover:bg-gray-200"
          >
            {afterSlashItems.split("").map(timeItemsMapper)}
          </button>
        </Tooltip>
      </div>
      {outroEnabled && outroPlayTime}
    </>
  );
};

export default VideoPlayTime;
