import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useBoolean,
  useUpdateEffect,
  useEffectOnce,
  useUnmount,
} from "react-use";

import { SelectedClipsProvider } from "@/context/ReviewClips/SelectedClips";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import confetti from "canvas-confetti";
import parseSRT from "parse-srt";

import { changeSelectedLayout } from "@/store/editorSlice";
import {
  setCurrentSelectedClip,
  updateBaseTemplates,
  updateCurrentSelectedMicroContent,
  updateCurrentSelectedProject,
  updateOnBoardingData,
  updateShowConfettiModal,
  updateShowFullLengthVideo,
  updateShowPreviewClipsSideModal,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";
import useAddUserPreferenceData from "@/api/useAddUserPreferenceData";
import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetSingleProject from "@/api/useGetSingleProject";
import useGetUserPreference from "@/api/useGetUserPreference";
import useGetUserTemplates from "@/api/useGetUserTemplates";
import useUpdateProject from "@/api/useUpdateProject";

import { layoutsArray } from "@/constants/aspect-ratio";

import { getAllBaseTemplates, getRandomElement, sleep, zE } from "@/helpers";
import { nlpGist, updateTemplateDefaultTexts } from "@/helpers/text";

import { trackReviewClipsEvent } from "@/utils/amplitudeAnalytcs";

import { PlanType, PreviewScreenClipTypes, VideoLayout } from "@/enums";

import BulkExportTemplateSelectionModal from "@/views/home/components/BulkExportClipsModal";
// import Banner from "@/components/Banner/Banner";
import FilesCountModal from "@/views/home/components/FilesCountModal";
import {
  ErrorDisplay,
  LoadingDisplay,
} from "@/views/home/components/PreviewClip";
import CenterEditor from "@/views/home/components/PreviewClip/CenterEditor/CenterEditor";
import LeftSidebar from "@/views/home/components/PreviewClip/LeftSidebar/LeftSidebar";
import ReviewClipsHeader from "@/views/home/components/ReviewClipsHeader";

const INTELLI_CLIPS_STATUS = {
  COMPLETED: "completed",
  SUBMITTED: "submitted",
  ERROR: "error",
};

const ReviewClips = () => {
  const { uid } = useAppSelector((state) => state.authState.currentUser);
  const { planType } = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const fabricRef = useRef<any>(null);
  const canvasObjects = useRef<any>({});
  const outroVideoRef = useRef<any>(null);
  const templatePreviewRef = useRef({
    fabricRef,
    canvasObjects,
    outroVideoRef,
  });
  const { projectID } = useParams();
  const duration = 3 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 30, spread: 360, ticks: 160, zIndex: 999 };

  const baseTemplates = useAppSelector(
    (state) => state.homeState.baseTemplates
  );
  const onBoardingData = useAppSelector(
    (state) => state.homeState.onBoardingData
  );

  const showPreviewClipsSideModal = useAppSelector(
    (state) => state.homeState.showPreviewClipsSideModal
  );

  const showFullVideo = useAppSelector(
    (state) => state.homeState.showFullVideo
  );

  const [parsedProjectData, setParsedProjectData] = useState<any>();
  const [isBaseTemplatesUpdated, toggleIsBaseTemplatesUpdated] =
    useBoolean(false);

  const [
    isSelectExportMultipleClipsModalOpen,
    toggleSelectExportMultipleClipsModal,
  ] = useBoolean(false);
  const [activePreviewTab, setActivePreviewTab] = useState<any>(null);
  const [clipDeleteLoading, setClipDeleteLoading] = useState<boolean>(false);
  const [outroTimeLeft, setOutroTimeLeft] = useState<number>(0);
  const [showDeleteMultipleClipsModal, toggleShowDeleteMultipleClipsModal] =
    useBoolean(false);
  const [showIntelliClipModal, setShowIntelliClipModal] = useBoolean(false);

  const pollAbortController = useRef<any>(null);

  const updateActivePreviewTab = useCallback((tab: any) => {
    setActivePreviewTab(tab);
  }, []);

  function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  const { data: projectData, isLoading: projectDataLoading } =
    useGetSingleProject(projectID);
  const defaultBaseTemplates = useGetDefaultBaseTemplates();
  const userTemplatesData = useGetUserTemplates(uid);
  const { data: firestoreUserPreferenceData } = useGetUserPreference(uid);
  const { mutate: addUserPreferenceData } = useAddUserPreferenceData();
  const { mutate } = useUpdateProject();

  const getUserPreferredTemplates = (Layout: string) => {
    let templatesArr = baseTemplates[Layout].filter(
      (template: any) => template?.isPreferred
    );
    // for some older projects, return all templates
    return templatesArr.length ? templatesArr : baseTemplates[Layout];
  };

  const sortTemplates = (arr: any) => {
    let sortedArray = [];

    // sort on clip bookmark
    sortedArray = arr.sort((a: any, b: any) => {
      if (!a.bookmarked && !b.bookmarked) return 0;
      else {
        if (a.bookmarked) return -1;
        else return 1;
      }
    });

    // custom clip, should be displayed first
    sortedArray = sortedArray.sort((a: any, b: any) => {
      if (a.custom === undefined && b.custom === undefined) return 0;
      else {
        if (a.custom) return -1;
        else return 1;
      }
    });

    return sortedArray;
  };

  const createCustomTemplate = (
    item: any,
    template: any,
    isChapter: boolean
  ) => {
    const userClipRateExist = item.hasOwnProperty("rating");
    const bookmarked = item.hasOwnProperty("bookmarked")
      ? item.bookmarked
      : false;
    const seen = item.hasOwnProperty("seen") ? item.seen : false;
    const srtText = item.srt_string ? parseSRT(item.srt_string)[0].text : "";
    const hasTwoFace = template.hasTwoFace && item.face_coord[1];

    let returnObj = {
      ...template,
      imageUrl: item.thumbnail_src,
      srt_string: item.srt_string ? item.srt_string : "",
      hasTwoFace,
      texts: template?.texts.length
        ? updateTemplateDefaultTexts(template.texts, item.gist)
        : [],
      gist: item.gist,
      face_coord: item.face_coord,
      start: item.start,
      end: item.end,
      text: item.text,
      chapter_end: item.chapter_end,
      chapter_start: item.chapter_start,
      custom: item.custom,
      userClipRateExist,
      bookmarked,
      seen,
      subtitle: {
        ...template.subtitle,
        text: srtText,
      },
      tag: item?.tag || "default",
      clip_src: item?.clip_src,
      viralityScore: item?.virality_score,
    };

    if (isChapter) {
      returnObj.clipId =
        item?.chapter_id ||
        `${projectData?.id}-${item.start}-${item.end}-${PreviewScreenClipTypes.CHAPTERS}`;
      returnObj.clipOrigin = PreviewScreenClipTypes.CHAPTERS;
    } else {
      returnObj.clipId =
        item?.clip_id ||
        `${projectData?.id}-${item.start}-${item.end}-${PreviewScreenClipTypes.SHORTS}`;
      returnObj.clipOrigin = PreviewScreenClipTypes.SHORTS;
    }
    return returnObj;
  };

  const handleChapterTemplateData = (originalArr: any) => {
    let finalArr: any = [];
    const userPreferredTemplates = getUserPreferredTemplates(
      VideoLayout.LAYOUT_16_9
    );

    originalArr.forEach((item: any) => {
      item.gist = nlpGist(item.gist);
      item.chapter_end = item.end;
      item.chapter_start = item.start;
      const randomTemplateChapter = getRandomElement(userPreferredTemplates);
      const customTemplate = createCustomTemplate(
        item,
        randomTemplateChapter,
        true
      );
      finalArr.push(customTemplate);
    });

    return sortTemplates(finalArr);
  };

  const handleUpdateTemplateData = (originalArr: any) => {
    let finalArr: any = [];

    if (baseTemplates[VideoLayout.LAYOUT_9_16_1].length) {
      const userPreferredTemplatesOneFace = getUserPreferredTemplates(
        VideoLayout.LAYOUT_9_16_1
      );
      const userPreferredTemplatesTwoFace = getUserPreferredTemplates(
        VideoLayout.LAYOUT_9_16_2
      );

      originalArr.forEach((item: any) => {
        item.gist = nlpGist(item.gist);

        const randomTemplateOneFace = getRandomElement(
          userPreferredTemplatesOneFace
        );
        const randomTemplateTwoFace = getRandomElement(
          userPreferredTemplatesTwoFace
        );

        // handles the case where if clip fails to generate due to some reason it should not show no clip generated
        try {
          if (item.face_coord?.length === 2) {
            // For two face
            finalArr.push(
              createCustomTemplate(item, randomTemplateTwoFace, false)
            );
          } else {
            // For every other case
            finalArr.push(
              createCustomTemplate(item, randomTemplateOneFace, false)
            );
          }
        } catch (error) {
          console.error(error);
        }
      });
    }

    return sortTemplates(finalArr);
  };

  const getMicroContentByProjectId = async (projectID: any) => {
    const { data }: any = await api.get(
      `${ApiEndpoints.GET_MICRO_CONTENT}?project_id=${projectID}`
    );

    if (data === null) {
      // Temp hack as BE team is not sending proper response
      return "Transcription not found";
    }

    if (data) {
      data.chapters_length = data.chapters.length;
      data.chapters = handleChapterTemplateData(data.chapters);
      data.preview_data = handleUpdateTemplateData(data.preview_data);
    }

    return data;
  };

  const {
    isLoading: microContentLoading,
    data: microContent,
    error: microContentError,
  } = useQuery(
    ["micro-content-data", projectID],
    async () => getMicroContentByProjectId(projectID),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: isBaseTemplatesUpdated,
    }
  );

  const handleUpdatedUserPreferredBaseTemplates = (
    data: any,
    layout: string
  ) => {
    const baseTemplates = getAllBaseTemplates(
      defaultBaseTemplates?.data,
      userTemplatesData?.data
    );
    const baseTemplateArray = baseTemplates[layout as VideoLayout];
    const preferredTemplateIDArray = data.baseTemplates[layout];

    const updatedBaseTemplates = baseTemplateArray?.map((obj: any) => {
      let newTemplateObj = { ...obj };
      if (preferredTemplateIDArray?.includes(obj.id)) {
        newTemplateObj = {
          ...obj,
          isPreferred: true,
        };
      }
      return newTemplateObj;
    });
    return updatedBaseTemplates;
  };

  const updatedUserSavedPreferencesData = (savedUserPrefData: any) => {
    if (!savedUserPrefData) return undefined;

    let newBaseTemplates = {};

    layoutsArray.forEach((layout) => {
      newBaseTemplates = {
        ...newBaseTemplates,
        [layout]: handleUpdatedUserPreferredBaseTemplates(
          savedUserPrefData,
          layout
        ),
      };
    });

    return { ...savedUserPrefData, baseTemplates: newBaseTemplates };
  };

  const checkIfUserSavedBaseTemplatesHasElement = (obj: any) => {
    for (let key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return true;
      }
    }
    return false;
  };

  const parseUserPreferenceData = (data: any) => {
    if (!data) return undefined;

    let newBaseTemplates = {};

    layoutsArray.forEach((layout) => {
      newBaseTemplates = {
        ...newBaseTemplates,
        [layout]:
          data?.baseTemplates && data?.baseTemplates[layout]?.length >= 0
            ? data.baseTemplates[layout]
                .filter((template: any) => template?.isPreferred)
                .map((item: any) => item.id)
            : baseTemplates[layout]
                .filter((template: any) => template?.isPreferred)
                .map((item: any) => item.id),
      };
    });
    return { ...data, baseTemplates: newBaseTemplates };
  };

  const onVideoClipChange = (
    clip: any,
    layout: VideoLayout,
    clipId?: string,
    aspectRatioChange?: false
  ) => {
    dispatch(updateShowFullLengthVideo(false));

    const templateData = {
      id: clip.id,
      face_coord: clip.face_coord || [],
      start: clip.start,
      end: clip.end,
      srt_string: clip.srt_string,
      imageUrl: clip.imageUrl,
      hasTwoFace: clip.hasTwoFace,
      gist: clip.gist,
      text: clip.text,
      chapter_end: clip.chapter_end,
      chapter_start: clip.chapter_start,
      backgroundColor: clip?.backgroundColor,
      custom: clip?.custom,
      userClipRateExist: clip?.hasOwnProperty("rating"),
      bookmarked: clip?.bookmarked,
      seen: clip?.seen,
      tag: clip?.tag,
      clip_src: clip?.clip_src,
      clipId,
      clipOrigin: clip?.clipOrigin || PreviewScreenClipTypes.SHORTS,
      viralityScore: clip?.viralityScore,
    };

    dispatch(updateCurrentSelectedMicroContent(templateData));
    dispatch(changeSelectedLayout(layout));

    // when we change the aspect ratio, selected clip should remain same
    !aspectRatioChange && dispatch(setCurrentSelectedClip(clipId || ""));

    // on clip change, pause outro and remove it from fabric
    // also, set outro timeleft as 0 to avoid replay screen
    if (
      canvasObjects.current?.outroImg &&
      canvasObjects.current?.outroImg?.getElement().tagName === "VIDEO"
    ) {
      canvasObjects.current?.outroImg?.getElement().pause();
      fabricRef.current?.remove(canvasObjects.current?.outroImg);
    }
    setOutroTimeLeft(0);
  };

  useUnmount(() => outroVideoRef.current?.pause());

  const getRandomTemplateByLayout = (layout: VideoLayout) => {
    const userPreferredTemplates = getUserPreferredTemplates(layout);
    return getRandomElement(userPreferredTemplates);
  };

  const showPageLoader = () =>
    microContentLoading ||
    projectDataLoading ||
    defaultBaseTemplates.isLoading ||
    userTemplatesData.isLoading ||
    !isBaseTemplatesUpdated;

  useUpdateEffect(() => {
    const parsedProjectData = projectData?.data && JSON.parse(projectData.data);
    projectData?.data && setParsedProjectData(JSON.parse(projectData.data));
    // if new video, and data present, show confetti
    if (
      !defaultBaseTemplates.isLoading &&
      !userTemplatesData.isLoading &&
      !projectDataLoading &&
      parsedProjectData &&
      !parsedProjectData.is_visited &&
      !parsedProjectData.userPreferenceData &&
      projectData
    ) {
      const baseTemplates = getAllBaseTemplates(
        defaultBaseTemplates?.data,
        userTemplatesData?.data
      );

      const savedUserPreferences = updatedUserSavedPreferencesData(
        parsedProjectData.userPreferenceData
      );

      let data = savedUserPreferences;

      if (data && checkIfUserSavedBaseTemplatesHasElement(data.baseTemplates)) {
        dispatch(updateBaseTemplates(data.baseTemplates));
      } else {
        dispatch(updateBaseTemplates(baseTemplates));
      }

      const userPreferenceData = parseUserPreferenceData(
        Object.values(onBoardingData).find(
          (p) => p.projectId === projectData.id
        )
      );

      const originalData = {
        ...parsedProjectData,
        is_visited: true,
        userPreferenceData: userPreferenceData,
      };
      const reqBody = {
        ...projectData,
        data: JSON.stringify(originalData),
      };

      if (!firestoreUserPreferenceData?.isSeenConfetti) {
        const requestData = {
          ...firestoreUserPreferenceData,
          isSeenConfetti: true,
        };
        addUserPreferenceData(
          {
            userId: uid,
            userPreferenceData: requestData,
          },
          {
            onSuccess: () => {
              dispatch(updateShowConfettiModal(true));
              const interval: any = setInterval(function () {
                const timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                  return clearInterval(interval);
                }

                const particleCount = 65 * (timeLeft / duration);

                confetti(
                  Object.assign({}, defaults, {
                    particleCount,
                    origin: {
                      x: randomInRange(0.1, 0.3),
                      y: Math.random() - 0.2,
                    },
                  })
                );
                confetti(
                  Object.assign({}, defaults, {
                    particleCount,
                    origin: {
                      x: randomInRange(0.7, 0.9),
                      y: Math.random() - 0.2,
                    },
                  })
                );
              }, 500);

              return () => {
                clearInterval(interval);
              };
            },
          }
        );
      }

      dispatch(updateCurrentSelectedProject(reqBody));
      let updatedOnboardingData = { ...onBoardingData };
      userPreferenceData?.key &&
        delete updatedOnboardingData[userPreferenceData?.key];

      dispatch(updateOnBoardingData(updatedOnboardingData));

      mutate(reqBody);
      toggleIsBaseTemplatesUpdated(true);
      trackReviewClipsEvent();
    }

    updateActivePreviewTab(null);
    dispatch(updateShowPreviewClipsSideModal(false));
    dispatch(updateShowFullLengthVideo(false));
  }, [
    microContentLoading,
    projectDataLoading,
    defaultBaseTemplates.isLoading,
    userTemplatesData.isLoading,
  ]);

  useEffect(() => {
    const parsedProjectData = projectData?.data && JSON.parse(projectData.data);
    projectData?.data && setParsedProjectData(JSON.parse(projectData.data));

    if (
      !defaultBaseTemplates.isLoading &&
      !userTemplatesData.isLoading &&
      !projectDataLoading &&
      parsedProjectData &&
      parsedProjectData.is_visited &&
      projectData
    ) {
      toggleIsBaseTemplatesUpdated(true);
      const baseTemplates = getAllBaseTemplates(
        defaultBaseTemplates?.data,
        userTemplatesData?.data
      );

      const savedUserPreferences = updatedUserSavedPreferencesData(
        parsedProjectData.userPreferenceData
      );
      let data = savedUserPreferences;

      if (data && checkIfUserSavedBaseTemplatesHasElement(data.baseTemplates)) {
        dispatch(updateBaseTemplates(data.baseTemplates));
      } else {
        dispatch(updateBaseTemplates(baseTemplates));
      }

      toggleIsBaseTemplatesUpdated(true);
      dispatch(updateCurrentSelectedProject(projectData));
      trackReviewClipsEvent();
    }
    updateActivePreviewTab(null);
    dispatch(updateShowFullLengthVideo(false));
    dispatch(updateShowPreviewClipsSideModal(false));
  }, [
    projectDataLoading,
    defaultBaseTemplates.isLoading,
    userTemplatesData.isLoading,
  ]);

  useEffectOnce(() => {
    zE("webWidget", "helpCenter:setSuggestions", {
      search: "Custom clip",
    });
  });

  const pollForIntelliClips = async () => {
    if (planType === PlanType.FREE) return;
    console.log("polling for intelliclip");
    const projectId = projectData?.id;
    try {
      let controller;
      if (!pollAbortController.current) {
        controller = new AbortController();
        pollAbortController.current = controller;
      } else {
        controller = pollAbortController.current;
      }

      const response: any = await api.get(
        `${ApiEndpoints.GET_PROJECT_BY_ID}?project_id=${projectId}`,
        {
          signal: controller.signal,
        }
      );

      const parsedData = JSON.parse(response.data.data);

      if (parsedData.intelliclip_status === INTELLI_CLIPS_STATUS.SUBMITTED) {
        await sleep(10000);
        await pollForIntelliClips();
      } else if (
        parsedData.intelliclip_status === INTELLI_CLIPS_STATUS.COMPLETED
      ) {
        // abort polling
        setShowIntelliClipModal(false);
        queryClient.invalidateQueries({ queryKey: ["micro-content-data"] });
        if (controller) controller.abort();
        pollAbortController.current = null;
      } else {
        // for error or undefined status
        // abort polling
        setShowIntelliClipModal(false);
        // if (controller) controller.abort();
        // pollAbortController.current = null;
      }
    } catch (error: any) {
      pollAbortController.current = null;
      console.log("failed to fetch intelliclips: ", error);
    }
  };

  useEffect(() => {
    if (!projectDataLoading && projectData && planType !== PlanType.FREE) {
      const parsedProjectData = JSON.parse(projectData.data);
      const intelliClipStatus = parsedProjectData?.intelliclip_status;

      if (intelliClipStatus === INTELLI_CLIPS_STATUS.SUBMITTED) {
        // poll to intelliclips
        setShowIntelliClipModal(true);
        pollForIntelliClips();
      }
    }
  }, [projectDataLoading]);

  useUnmount(
    () => pollAbortController.current && pollAbortController.current.abort()
  );

  return (
    <>
      {/* <Banner bannerVariant="outrage" /> */}
      <ReviewClipsHeader />
      {/* NOTE: change the height from 8rem to 4.5rem bellow
          while Banner is not present
       */}
      <main className="mx-auto h-[calc(100vh_-_4.5rem)] bg-[#e8e8ee] max-w-screen-2xl w-full relative overscroll-y-none border overflow-y-none">
        {showPageLoader() ? (
          <LoadingDisplay />
        ) : (
          <>
            {
              // @ts-ignore
              microContentError?.response?.data?.detail ===
                "Transcription not found" ||
              microContent === "Transcription not found" ? (
                <ErrorDisplay />
              ) : (
                <SelectedClipsProvider>
                  <div className="w-full h-full flex -mt-[3px]">
                    {/* left side  */}
                    <LeftSidebar
                      microContent={microContent}
                      onVideoClipChange={onVideoClipChange}
                      projectData={projectData}
                      parsedProjectData={parsedProjectData}
                      setClipDeleteLoading={setClipDeleteLoading}
                      toggleSelectExportMultipleClipsModal={
                        toggleSelectExportMultipleClipsModal
                      }
                      toggleShowDeleteMultipleClipsModal={
                        toggleShowDeleteMultipleClipsModal
                      }
                      getRandomTemplateByLayout={getRandomTemplateByLayout}
                      showDeleteMultipleClipsModal={
                        showDeleteMultipleClipsModal
                      }
                      showIntelliClipModal={showIntelliClipModal}
                    />

                    <CenterEditor
                      onVideoClipChange={onVideoClipChange}
                      clipDeleteLoading={clipDeleteLoading}
                      projectData={projectData}
                      updateActivePreviewTab={updateActivePreviewTab}
                      microContent={microContent}
                      isSelectExportMultipleClipsModalOpen={
                        isSelectExportMultipleClipsModalOpen
                      }
                      showDeleteMultipleClipsModal={
                        showDeleteMultipleClipsModal
                      }
                      showFullVideo={showFullVideo}
                      showPreviewClipsSideModal={showPreviewClipsSideModal}
                      activePreviewTab={activePreviewTab}
                      ref={templatePreviewRef}
                      outroTimeLeft={outroTimeLeft}
                      setOutroTimeLeft={setOutroTimeLeft}
                    />
                  </div>
                  <BulkExportTemplateSelectionModal
                    isSelectExportMultipleClipsModalOpen={
                      isSelectExportMultipleClipsModalOpen
                    }
                    toggleSelectExportMultipleClipsModal={
                      toggleSelectExportMultipleClipsModal
                    }
                  />
                </SelectedClipsProvider>
              )
            }
          </>
        )}
        <FilesCountModal
          counts={{
            chapter_count: microContent?.chapters?.length,
            micro_content_count:
              microContent?.preview_data?.length ||
              microContent?.squareTemplates?.length,
          }}
        />
      </main>
    </>
  );
};
export default ReviewClips;
