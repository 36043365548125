import React from "react";
import ReactSlider from "react-slider";

import { cn } from "@/helpers";

/**
 * Props for the ReactRangeSlider component.
 */
interface CustomSliderProps {
  /** The minimum value of the slider. */
  min: number;
  /** The maximum value of the slider. */
  max: number;
  /** The current value of the slider. */
  value: number;
  /** Callback function triggered after the value change is committed. */
  onAfterChange: (value: number) => void;
  /** Optional callback function triggered when the value changes. */
  onChange?: (value: number) => void;
  /** The step size of the slider. Default is 1. */
  step?: number;
  /**
   * Custom class for the slider container.
   * Defaults to "relative".
   */
  sliderClassName?: string;
  /**
   * Custom class for the track.
   * Defaults to "mt-1 h-2 rounded-xl bg-gray-200".
   */
  trackClassName?: string;
  /**
   * Custom class for the thumb.
   * Defaults to "bg-blue-600 rounded-full h-5 w-5 mt-1".
   */
  thumbClassName?: string;
}

/**
 * Custom slider component based on ReactSlider.
 * @component
 * @returns {ReactElement} The custom slider component.
 */

export const ReactRangeSlider: React.FC<CustomSliderProps> = ({
  min,
  max,
  value,
  onAfterChange,
  onChange,
  step = 1,
  sliderClassName,
  trackClassName,
  thumbClassName,
}) => {
  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e);
    }
    return () => {};
  };
  return (
    <ReactSlider
      className={cn("relative", sliderClassName || "")}
      min={min}
      max={max}
      value={value}
      step={step}
      onAfterChange={onAfterChange}
      onChange={handleOnChange}
      renderTrack={(props, state) => (
        <div
          {...props}
          className={cn(
            "mt-1 h-2 rounded-xl bg-gray-200",
            trackClassName || ""
          )}
          key={state.index} // Important for React keys
        />
      )}
      renderThumb={(props, state) => (
        <div
          {...props}
          className={cn(
            "bg-blue-600 rounded-full h-5 w-5 mt-1",
            thumbClassName || ""
          )}
          key={state.index} // Important for React keys
        />
      )}
    />
  );
};
