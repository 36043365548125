import { useDropzone } from "react-dropzone";
import { useOutletContext } from "react-router-dom";
import { useToggle } from "react-use";

import { Card } from "flowbite-react";

import {
  setBackgroundColor,
  updateIsBackgroundImageLoaded,
} from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { uploadFileToS3 } from "@/api/requests";
import useGetAssets from "@/api/useGetAssets";
import useUploadAsset from "@/api/useUploadAsset";

import {
  ANALYTICS_CONSTANTS,
  EDITOR_INTERACTION_DATA,
} from "@/constants/amplitude";
import { MediaIconBrandKit, MediaIconStock } from "@/constants/brand-kit";

import {
  eventsDataToRedux,
  trackEditorInteractionEvent,
} from "@/utils/amplitudeAnalytcs";

import { AppliedCardVariant, AssetTags, SimpleAssetType } from "@/enums";

import AccordionLayout from "@/components/Accordion/AccordionLayout";
import DropDownColorPicker from "@/components/DropDownColorPicker";

import { useEditorHistory } from "@/views/editor/EditorHistoryContext";
import { HistoryActionTypes } from "@/views/editor/EditorHistoryReducer";
import {
  updateBackgroundColorCommand,
  updateBackgroundImageCommand,
} from "@/views/editor/commands/backgroundCommand";
import { AppliedAssetCard } from "@/views/editor/components/AppliedAssetCard";
import AssetList from "@/views/editor/components/Assets/AssetList";
import { UploadAssetButton } from "@/views/editor/components/UploadAssetButton";
import { StockBackgrounds } from "@/views/editor/constant";

const BackgroundTab = () => {
  const { editorDispatch } = useEditorHistory();

  const dispatch = useAppDispatch();
  const [isUploadLoading, toggleUploadLoading] = useToggle(false);

  const { handelUpdateBgUrl, removeBackgroundImage, currentBGUrl } =
    useOutletContext<any>();

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const { mutate: finalizeUpload, isLoading: finalizeUploadLoading } =
    useUploadAsset(AssetTags.BACKGROUND);

  const { data, isLoading } = useGetAssets({
    assetTag: AssetTags.BACKGROUND,
  });

  const backgroundColorAsHex = useAppSelector(
    (state) => state.editorState.backgroundColorAsHex
  );
  const selectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );

  const onDrop = async (acceptedFiles: any) => {
    const file = acceptedFiles?.[0];

    if (!file) {
      return;
    }

    try {
      toggleUploadLoading(true);
      const res = await uploadFileToS3(file);
      finalizeUpload({
        assetType: file.type,
        assetId: res?.assetId,
        ownerId: uid,
        parentId: null,
        assetTag: AssetTags.BACKGROUND,
        data: {
          assetName: file.name,
          remoteUrl: res?.s3Url,
        },
      });
      toggleUploadLoading(false);
      removeAll();
      updatedStockBackgrounds(
        { url: res?.public_url || res?.s3Url },
        file.type
      );
      eventsDataToRedux(ANALYTICS_CONSTANTS.UPLOADS.BACKGROUND_UPLOAD);
    } catch (e: any) {
      toggleUploadLoading(false);
      console.log("Error while uploading background", e);
      return;
    }
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef }: any =
    useDropzone({
      onDrop,
      accept: {
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
      },
      maxFiles: 1,
    });

  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef.current) inputRef.current.value = "";
  };

  const handelRemoveBgImage = () => {
    removeAll();
    handelUpdateBgUrl("");
    removeBackgroundImage();
  };

  const handelRemoveCurrentBackgroundImage = () => {
    const metaData = {
      newUrl: "",
      oldUrl: currentBGUrl,
    };
    const command = updateBackgroundImageCommand(metaData, handelUpdateBgUrl);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handelChangeBackgroundColor = (color: any) => {
    handelRemoveBgImage();
    dispatch(setBackgroundColor(color));
    dispatch(updateIsBackgroundImageLoaded(false));
    trackEditorInteractionEvent(
      EDITOR_INTERACTION_DATA.BACKGROUND_CLICKED.EVENT_KEY,
      EDITOR_INTERACTION_DATA.BACKGROUND_CLICKED.BACKGROUND_COLOR_CHANGE
    );
  };

  const changeBackgroundColor = (color: any) => {
    const metaData = {
      newColor: color,
      oldColor: backgroundColorAsHex,
    };
    const command = updateBackgroundColorCommand(
      metaData,
      handelChangeBackgroundColor
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const updatedStockBackgrounds = (item: any, assetType: string) => {
    const metaData = {
      newUrl: item.data ? JSON.parse(item.data).remote_url : item.url,
      oldUrl: currentBGUrl,
    };
    const command = updateBackgroundImageCommand(metaData, handelUpdateBgUrl);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });

    eventsDataToRedux(ANALYTICS_CONSTANTS.BACKGROUND_TYPE, assetType);
  };

  return (
    <div className="relative w-full">
      <div className=" sticky top-0 z-10 mb-2 w-full bg-white p-2 py-4 pt-6">
        <p className="text-lg text-gray-800">Background</p>
      </div>

      <div className="px-2">
        <Card className="flowbite-custom-card mt-2">
          <div className="flex items-center justify-between">
            <p className="text-gray-800 font-semibold text-sm">
              Background color
            </p>
            <DropDownColorPicker
              color={backgroundColorAsHex}
              onChange={changeBackgroundColor}
              showTransparency={false}
            />
          </div>
        </Card>
        <div
          className="my-6 flex w-full items-center justify-center"
          {...getRootProps()}
          onClick={(e) => e.stopPropagation()}
        >
          <UploadAssetButton
            label="Upload Image"
            id="dropzone-file-background-editor"
            isLoading={isUploadLoading || finalizeUploadLoading}
            isDisabled={isUploadLoading || finalizeUploadLoading}
            getInputProps={getInputProps}
          />
        </div>

        <AppliedAssetCard
          cardType={AppliedCardVariant.BACKGROUND}
          assetUrl={currentBGUrl}
          color={backgroundColorAsHex}
          onDelete={handelRemoveCurrentBackgroundImage}
          primaryLabel="Selected background"
          assetType={currentBGUrl ? SimpleAssetType.IMAGE : ""}
          isApplied={currentBGUrl ? true : false}
        />

        <div className="mt-[70px]">
          <AccordionLayout
            title="Brand Kit Backgrounds"
            Icon={MediaIconBrandKit}
            eventData={{
              key: EDITOR_INTERACTION_DATA.BACKGROUND_CLICKED.EVENT_KEY,
              value:
                EDITOR_INTERACTION_DATA.BACKGROUND_CLICKED
                  .BRAND_KIT_BACKGROUND_ACCESSED,
            }}
          >
            <AssetList
              items={data}
              onClick={updatedStockBackgrounds}
              isLoading={isLoading}
              assetType={`Brandkit-${AssetTags.BACKGROUND}`}
            />
          </AccordionLayout>
          <AccordionLayout
            title="Stock Backgrounds"
            Icon={MediaIconStock}
            eventData={{
              key: EDITOR_INTERACTION_DATA.BACKGROUND_CLICKED.EVENT_KEY,
              value:
                EDITOR_INTERACTION_DATA.BACKGROUND_CLICKED
                  .STOCK_BACKGROUND_ACCESSED,
            }}
          >
            <AssetList
              items={StockBackgrounds[selectedLayout]}
              onClick={updatedStockBackgrounds}
              isStockAsset={true}
              assetType={`Stock-${AssetTags.BACKGROUND}`}
            />
          </AccordionLayout>
        </div>
      </div>
    </div>
  );
};

export default BackgroundTab;
