import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  Firestore,
  doc, // enableIndexedDbPersistence,
  enableMultiTabIndexedDbPersistence,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

import { isProd } from "@/helpers";

import { PlanType, SubscriptionIntervalInMonths } from "@/enums";

const stageFirebaseConfig = {
  apiKey: "AIzaSyDddt8WRl-yybHaTos1O69GXZO3K3YezW0",
  authDomain: "auth-stage.vidyo.ai",
  databaseURL:
    "https://vidyo-ai-poc-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "vidyo-ai-poc",
  storageBucket: "vidyo-ai-poc.appspot.com",
  messagingSenderId: "368192008638",
  appId: "1:368192008638:web:a265de5c0113bb999f89ef",
  measurementId: "G-Q9HBRS0X45",
};

const firebaseConfig = {
  apiKey: "AIzaSyB1GkN3JdZdEGbjrb-mMzVemFkjfMufRVo",
  authDomain: "auth.vidyo.ai",
  projectId: "vidyoai",
  storageBucket: "vidyoai.appspot.com",
  messagingSenderId: "496499201626",
  appId: "1:496499201626:web:492b9f871b46b64a57ea63",
  measurementId: "G-MBJ2EYFS45",
};

let app;
let database: Firestore;

app = initializeApp(isProd() ? firebaseConfig : stageFirebaseConfig);
database = getFirestore(app);

enableMultiTabIndexedDbPersistence(database).catch((err) => {
  console.warn(err);
});

export const DB = database;

export const auth = getAuth(app);

const FREE_USER_ALLOWED_MINUTES = 75;

export const updateUserSubscription = async (uid: string) => {
  const updatedSubscriptionData = {
    subscriptionStartedAt: serverTimestamp(),
    allowedMinutes: FREE_USER_ALLOWED_MINUTES,
    intervalInMonths: SubscriptionIntervalInMonths.MONTHLY,
    planType: PlanType.FREE,
    cancelOn: null,
    isPaddle: false,
    nextBillDate: null,
    subscriptionId: null,
  };
  console.log("updateUserSubscription", uid, updatedSubscriptionData);
  await setDoc(doc(DB, "subscriptions", uid), updatedSubscriptionData, {
    merge: true,
  });
};

async function hashEmail(email: string) {
  const msgBuffer = new TextEncoder().encode(email); // Encode as UTF-8
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer); // Hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // Convert bytes to hex string
  return hashHex;
}

const ALLOW_ACCOUNT_REACTIVATION_AFTER_DAYS = 28;
export async function wasEmailRegisteredAndDeleted(email: string) {
  try {
    const hashedEmail = await hashEmail(email);
    const docRef = doc(database, "deletedAccounts", hashedEmail);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      // check for date
      const deletionDate = data?.deletionDate.toDate();
      const today = new Date();

      const diffTime = Math.abs(today.getTime() - deletionDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays <= ALLOW_ACCOUNT_REACTIVATION_AFTER_DAYS) {
        return true;
      }
    }
  } catch (error) {
    console.error("Error in checkIfEmailWasRegistered: ", error);
  }

  return false;
}
