import { useCallback, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { ampli } from "@/ampli";
import AuthLayout from "@/layouts/authLayout";
import EditorLayout from "@/layouts/editorLayout";
import MediaLayout from "@/layouts/mediaLayout/MediaLayout";
import PrivateRoute from "@/router/PrivateRoute";
import { setOptOut } from "@amplitude/analytics-browser";
import * as FullStory from "@fullstory/browser";
import { useGrowthBook } from "@growthbook/growthbook-react";
import * as Sentry from "@sentry/react";
import { debounce } from "debounce";
import { getAnalytics, logEvent } from "firebase/analytics";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot, Timestamp } from "firebase/firestore";

import { store } from "@/store";
import {
  logOut,
  setCountry,
  setPaddleSubscription,
  setUserSubscription,
} from "@/store/authSlice";
import {
  resetHomeState,
  toggleManageSubscriptionModal,
  updateOnBoardingData,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import {
  getPaddleSubscription,
  getUserLocationByIP,
  getUserSubscription,
} from "@/api/requests";
import useGetAssets from "@/api/useGetAssets";
import useGetUserOnBoardingData from "@/api/useGetUserOnBoardingData";

import {
  FULLSTORY_BLACKLISTED_COUNTRIES,
  UPGRADE_MODAL_DEEPLINK,
} from "@/constants";
import { USER_PAYMENT_CONSTANTS } from "@/constants/segment-analytics";

import {
  convertFireBaseTimeToDate,
  getLoginRedirectUrl,
  isProd,
  saveStateForReduxFromLocalStorage,
  loadFonts,
  clearLocalStorage,
  isDateGreaterThanEqualsToday,
  getDaysDifference,
  zE,
  addOnboardingDataForTracking,
  loadPaddlePlans,
  forceUpgradeModalOnUser,
} from "@/helpers";

import { handelLogout } from "@/utils/FirebaseHelpers";
import {
  identifyLoggedInUser,
  trackUserPageActivity,
  trackUserSubscription,
} from "@/utils/SegmentAnalytics";
import { identifyUser, trackUTMParams } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { auth, DB, updateUserSubscription } from "@/utils/firebase";
import { initMoengage } from "@/utils/moengage";
import { showNotification } from "@/utils/showNotification";

import { Subscription } from "@/interfaces";

import { AssetTags, getKeyByValue, PlanType, RouterPath } from "@/enums";

import PaymentsStatusModal from "@/components/PaymentsModal/PaymentsStatusModal";

import AuthPage from "@/views/auth/AuthPage";
import { VidyoAICustomFonts } from "@/views/editor/constant";
import EditorRoutes from "@/views/editor/routes";
import InternalErrorPage from "@/views/error/InternalErrorPage";
import NotFoundPage from "@/views/error/NotFoundPage";
import BrandKit from "@/views/home/BrandKit";
import Downloads from "@/views/home/Downloads";
import ManageSubscription from "@/views/home/ManageSubscription";
import MyTemplatesPage from "@/views/home/MyTemplatesPage";
import ReviewClips from "@/views/home/ReviewClips";
import UserOnboarding from "@/views/home/UserOnboarding";
import Workspace from "@/views/home/Workspace";
import { ScheduledPostsComponent } from "@/views/home/components/ScheduledPosts";
import UpdateToProModal from "@/views/home/components/UpdateToProModal";

import GrowthBookSetup from "./components/Growthbook";
import { useManageZendeskPlugin } from "./hooks/useManageZendeskPlugin";
import { setUndoRedoForNewSignups } from "./store/editorSlice";

const analytics = getAnalytics();

// ampli.load({ environment: "default" });

// using this variable to save user_country value even after logout and pass it as props to <AuthPage /> to avoid extra ip fetch
let userCountryFromIP: string = "";

const setPlanSubscribeDate = async (
  subscription: Subscription,
  paddleSubscription: any
) => {
  const { isPaddle, planType } = subscription;
  if (isPaddle && planType !== PlanType.FREE) {
    FullStory.isInitialized() &&
      FullStory.setUserVars({
        planSubscribeDate: paddleSubscription?.signup_date,
      });
  }
};

export const loadPaddleSubscription = async (subscription: Subscription) => {
  if (subscription.isPaddle) {
    // load paddle subscription
    try {
      const response: any =
        subscription.subscriptionId &&
        (await getPaddleSubscription(subscription.subscriptionId));
      if (response?.success) {
        const paddleSubscription = response.response[0];
        store.dispatch(setPaddleSubscription(paddleSubscription));
        setPlanSubscribeDate(subscription, paddleSubscription);
      } else {
        console.error("failed to load paddle subscription", response);
      }
    } catch (error) {
      console.error("failed to load paddle subscription.");
    }
  }
};

function App() {
  const [user, setUser] = useState<any>(null);
  const [currentRedirectUrl, setCurrentRedirectUrl] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userCountry = useAppSelector((state) => state.authState.country);
  const [isFSInitTriggered, setFSInitTriggered] = useState(false);
  const showProModalModal = useAppSelector(
    (state) => state.homeState.upgradeToProModalVisible
  );
  const paddleSubscription = useAppSelector(
    (state) => state.authState.paddleSubscription
  );

  const { data: onBoardingData, isLoading: onBoardingDataLoading }: any =
    useGetUserOnBoardingData(user?.uid, !!user?.uid);

  const { data: userFonts } = useGetAssets({
    assetTag: AssetTags.FONT,
  });

  const [searchParams] = useSearchParams();

  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");
  const utmTerm = searchParams.get("utm_term");
  const displayPaymentModal = searchParams.get(UPGRADE_MODAL_DEEPLINK);

  useEffect(() => {
    if (displayPaymentModal) {
      sessionStorage.setItem(UPGRADE_MODAL_DEEPLINK, "visible");
    }
  }, [displayPaymentModal]);

  trackUTMParams({ utmCampaign, utmMedium, utmSource, utmTerm });

  // syncing with localStorage
  store.subscribe(
    // we use debounce to save the state once ea=ch 800ms
    // for better performances in case multiple changes occur in a short time
    debounce(() => {
      saveStateForReduxFromLocalStorage({
        authState: store.getState().authState,
        homeState: store.getState().homeState,
        draftState: store.getState().draftState,
      });
    }, 800)
  );

  useEffect(() => {
    if (
      paddleSubscription?.state === "active" &&
      paddleSubscription?.paused_at
    ) {
      dispatch(toggleManageSubscriptionModal(true));
    }
  }, [paddleSubscription]);

  const updateGrowthBook = useCallback(() => {
    if (userCountry) {
      userCountryFromIP = userCountry;
    }
  }, [userCountry]);

  const getSubscriptionFromFirestore = async (user: any) => {
    const retries = 12;
    try {
      // try fetching in interval of 5 seconds for a minute if subscription is not found
      const subscription = await getUserSubscription(user.uid);
      if (subscription) {
        onUserSubscription(user, transformSubscription(subscription));
      } else {
        let retryCount = 0;
        const interval = setInterval(async () => {
          retryCount++;
          const subscription = await getUserSubscription(user.uid);
          if (subscription) {
            onUserSubscription(user, transformSubscription(subscription));
            clearInterval(interval);
          }
          if (retryCount === retries) {
            clearInterval(interval);
            console.error("Failed to fetch subscription from firestore", user);
            showNotification(
              "Failed to fetch your subscription details. Please refresh app to continue.",
              notificationType.FAIL
            );
          }
        }, 5000);
      }
    } catch (error) {
      console.error("Failed to fetch subscription from firestore", error);
    }
  };

  const initFullStory = (user: any, subscription: Subscription) => {
    if (!isProd()) {
      return;
    }

    if (FullStory.isInitialized()) {
      return;
    }

    if (userCountry) {
      if (
        subscription.planType === PlanType.FREE &&
        FULLSTORY_BLACKLISTED_COUNTRIES.indexOf(userCountry) !== -1
      ) {
        FullStory.isInitialized() && FullStory.shutdown();
        return;
      }
    }
    FullStory.init({
      orgId: "o-1HFBVZ-na1",
    });
    FullStory.identify(user.uid, {
      displayName: user.displayName,
      email: user.email,
      plan: getKeyByValue(PlanType, subscription.planType),
      allowedMinutes: subscription.allowedMinutes,
      subscriptionStartedAt: new Date(subscription.subscriptionStartedAt),
      createdAt: new Date(subscription.createdAt),
    });
    setFSInitTriggered(true);
  };

  useEffect(() => {
    // check if fullstory is initialized
    // if initialized add onboarding data to fullstory if available
    if (onBoardingData && !onBoardingDataLoading) {
      addOnboardingDataForTracking(user.uid, onBoardingData);
      identifyUser({ user, onBoardingData });
    }
  }, [onBoardingData]);

  const isSubscriptionExpired = async (user: any): Promise<boolean> => {
    const subscription = await getUserSubscription(user.uid);
    const transformedSub = transformSubscription(subscription);
    const isPaidUser = transformedSub.planType !== PlanType.FREE;

    if (!isPaidUser) {
      const userSubDate: any = convertFireBaseTimeToDate(
        transformedSub.subscriptionStartedAt
      );

      const subscriptionEndDate = new Date(
        userSubDate.getFullYear(),
        userSubDate.getMonth() + transformedSub.intervalInMonths,
        userSubDate.getDate()
      );

      const currentDate = new Date();
      const currentTimeInMillis = new Date().getTime();

      const resetSubs =
        currentDate.getMonth() - userSubDate.getMonth() > 1 ||
        currentTimeInMillis > subscriptionEndDate.getTime();
      return resetSubs;
    } else {
      if (transformedSub.cancelOn) {
        const isCancelOnGreaterThanToday = isDateGreaterThanEqualsToday(
          transformedSub.cancelOn
        );
        if (isCancelOnGreaterThanToday) {
          // checking if the churn date is today, if yes, track duration of user, else do nothing
          // this reduces unnecessary track events
          trackUserSubscription(
            USER_PAYMENT_CONSTANTS.CHURN,
            transformedSub.planType,
            transformedSub.intervalInMonths === 12 ? "yearly" : "monthly",
            getDaysDifference(
              new Date(transformedSub.createdAt).getTime(),
              new Date(transformedSub.cancelOn).getTime()
            )
          );
        }
        return isCancelOnGreaterThanToday;
      }
    }

    return false;
  };

  const onUserSubscription = async (user: any, subscription?: Subscription) => {
    try {
      console.log(
        "onUserSubscription",
        new Date().toISOString(),
        user.email,
        user.uid,
        subscription
      );

      // hide help when forcing upgrade modal on users
      if (forceUpgradeModalOnUser()) {
        zE("webWidget", "hide");
      }

      // AB TESTING: setting undo redo true for users who signed up at/after 26 december 2023, 2:58pm
      store.dispatch(
        setUndoRedoForNewSignups(
          subscription ? subscription.createdAt >= 1703582909692 : false
        )
      );

      identifyUser({ user });

      if (!subscription) {
        getSubscriptionFromFirestore(user);
        return;
      }

      initFullStory(user, subscription);

      const isExpired = await isSubscriptionExpired(user);

      if (isExpired) {
        await updateUserSubscription(user.uid);
        return;
      }
      store.dispatch(setUserSubscription(subscription));

      // update user's plan type in segment
      identifyLoggedInUser(user);

      loadPaddleSubscription(subscription);
    } catch (error) {
      console.error(error);
    }
  };

  // transforms subscription from firestore using proxy
  const transformSubscription = (subscription: any): Subscription => {
    return {
      allowedMinutes:
        subscription.allowedMinutes * subscription.intervalInMonths +
        (subscription.addOnMinutes || 0),
      intervalInMonths: subscription.intervalInMonths,
      planType: subscription.planType || PlanType.FREE,
      createdAt:
        subscription.createdAt && new Date(subscription.createdAt).getTime(),
      subscriptionStartedAt:
        subscription.subscriptionStartedAt &&
        new Date(subscription?.subscriptionStartedAt).getTime(),
      isPaddle: subscription.isPaddle || false,
      nextBillDate: subscription.nextBillDate
        ? new Date(subscription.nextBillDate)
        : null,
      subscriptionId: subscription.subscriptionId
        ? parseInt(subscription.subscriptionId)
        : null,
      cancelOn: subscription.cancelOn ? new Date(subscription.cancelOn) : null,
    };
  };

  // transforms subscription from firebase using snapshot listener
  const transformFireBaseSubscription = (subscription: any): Subscription => {
    return {
      allowedMinutes:
        subscription.allowedMinutes * subscription.intervalInMonths +
        (subscription.addOnMinutes || 0),
      intervalInMonths: subscription.intervalInMonths,
      planType: subscription.planType || PlanType.FREE,
      createdAt: subscription.createdAt?.toMillis(),
      subscriptionStartedAt: subscription.subscriptionStartedAt?.toMillis(),
      isPaddle: subscription.isPaddle || false,
      nextBillDate: subscription.nextBillDate
        ? new Date(subscription.nextBillDate)
        : null,
      subscriptionId: subscription.subscriptionId
        ? parseInt(subscription.subscriptionId)
        : null,
      cancelOn: subscription.cancelOn ? new Date(subscription.cancelOn) : null,
    };
  };

  const handleAuthStateChange = useCallback(() => {
    let subscriptionListener: any;

    const authListener = onAuthStateChanged(auth, (user) => {
      if (user?.email) {
        setUser(user);
        getSubscriptionFromFirestore(user);

        subscriptionListener = onSnapshot(
          doc(DB, "subscriptions", user.uid),
          (snap) => {
            const snapData = snap.data();
            const subscription: Subscription | undefined =
              snapData && transformFireBaseSubscription(snapData);

            if (subscription && snap.metadata.hasPendingWrites) {
              // set local timestamp until server timestamp is updated
              if (!subscription.subscriptionStartedAt) {
                subscription.subscriptionStartedAt = Timestamp.now().toMillis();
              }
              if (!subscription.createdAt) {
                subscription.createdAt = Timestamp.now().toMillis();
              }
            }
            onUserSubscription(user, subscription);
          },
          (error) => {
            console.log("Encountered error onSnapshot: ", error);
          }
        );

        loadPaddlePlans(); // get paddle plans

        // Google Analytics
        logEvent(analytics, "login");

        if (isProd()) {
          // Heap user specific data
          (window as any).heap.identify(user.email);
          (window as any).heap.addUserProperties({ Name: user.displayName });
          // eslint-disable-next-line no-undef

          // Sentry identify user
          Sentry.setUser({
            email: user.email,
            username: user.displayName ?? "",
          });

          // setup profitwell
          (window as any)?.profitwell("start", {
            user_email: user.email,
          });

          ampli.load({ environment: "default" });

          initMoengage(user);
        } else {
          // disable amplitude for stage
          setOptOut(false);
        }

        zE("webWidget", "identify", {
          name: user.displayName,
          email: user.email,
        });
        zE("webWidget", "prefill", {
          name: {
            value: user.displayName,
            readOnly: true,
          },
          email: {
            value: user.email,
            readOnly: true,
          },
        });
        zE("webWidget", "show");
      } else {
        console.log("User is signed out");

        zE("webWidget", "hide");

        subscriptionListener && subscriptionListener();
        handelLogout();
        dispatch(logOut());

        const redirectUri = getLoginRedirectUrl(location);
        navigate(redirectUri);
        const paramsString = redirectUri.split("?")[1];
        const searchParams = new URLSearchParams(paramsString);
        setCurrentRedirectUrl(searchParams.get("redirect") || "");

        clearLocalStorage();
        dispatch(resetHomeState());
      }
    });
    getUserLocationByIP();

    return () => {
      subscriptionListener && subscriptionListener();
      authListener();
    };
  }, []);

  const loadUserFonts = useCallback(() => {
    if (userFonts?.length) {
      const fontsArray = userFonts.map(
        (asset) => asset.data && JSON.parse(asset.data)
      );
      const updatedArrWithVideoAiCustomFonts = [
        ...VidyoAICustomFonts,
        ...fontsArray,
      ];
      loadFonts(updatedArrWithVideoAiCustomFonts);
    } else {
      loadFonts(VidyoAICustomFonts);
    }
  }, [userFonts]);

  const onBoardingDataUpdate = () => {
    const onBoardingData = store.getState().homeState.onBoardingData;
    let newOnBoardingData = { ...onBoardingData };
    Object.values(onBoardingData).forEach((value: any) => {
      newOnBoardingData = {
        ...newOnBoardingData,
        [value.key]: {
          ...value,
          processingVideoData: null,
        },
      };
    });
    store.dispatch(updateOnBoardingData(newOnBoardingData));
  };

  useEffect(() => {
    updateGrowthBook();
  }, [updateGrowthBook]);

  useEffect(() => {
    loadUserFonts();
  }, [loadUserFonts]);

  useEffect(() => {
    // Load features asynchronously when the app renders

    handleAuthStateChange();
    onBoardingDataUpdate();
  }, []);

  // while user is logged out, if user's country is not set in redux, set it from stored variable.
  if (!userCountry && userCountryFromIP) {
    dispatch(setCountry(userCountryFromIP));
  }

  useManageZendeskPlugin();

  return (
    <Sentry.ErrorBoundary
      fallback={<InternalErrorPage />}
      showDialog
      dialogOptions={{
        user: {
          name: user?.displayName,
          email: user?.email,
        },
      }}
    >
      <GrowthBookSetup
        isFSInitialized={isFSInitTriggered}
        country={userCountry}
        user={user}
      >
        <div className="App font-inter">
          <Routes>
            <Route
              path="/auth"
              element={<AuthLayout />}
            >
              <Route
                path="login"
                element={
                  <AuthPage userCountry={userCountry || userCountryFromIP} />
                }
              />
            </Route>

            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Navigate
                    replace
                    to={
                      store.getState().authState?.currentUser?.showOnBoarding
                        ? RouterPath.ON_BOARDING
                        : currentRedirectUrl
                        ? currentRedirectUrl
                        : RouterPath.HOME
                    }
                  />
                </PrivateRoute>
              }
            />
            <Route
              path={RouterPath.HOME}
              element={
                <PrivateRoute>
                  <Workspace />
                </PrivateRoute>
              }
            />
            <Route
              path={RouterPath.ON_BOARDING}
              element={<UserOnboarding country={userCountryFromIP} />}
            />
            <Route
              path="/home/project/:projectID"
              element={
                <PrivateRoute>
                  <ReviewClips />
                </PrivateRoute>
              }
            />
            <Route
              element={
                <PrivateRoute>
                  <MediaLayout />
                </PrivateRoute>
              }
            >
              <Route
                path={RouterPath.BRAND_KIT}
                element={<BrandKit />}
              />
              <Route
                path={RouterPath.MY_TEMPLATES}
                element={<MyTemplatesPage />}
              />
              <Route
                path={RouterPath.DOWNLOADS}
                element={<Downloads />}
              />
              <Route
                path={RouterPath.SCHEDULED_POSTS}
                element={<ScheduledPostsComponent />}
              />
            </Route>

            <Route
              path={RouterPath.MANAGE_SUBSCRIPTION}
              element={
                <PrivateRoute>
                  <ManageSubscription />
                </PrivateRoute>
              }
            />
            <Route
              path="/editor"
              element={
                <PrivateRoute>
                  <EditorLayout />
                </PrivateRoute>
              }
            >
              <Route
                path="/editor/*"
                element={<EditorRoutes />}
              />
            </Route>
            <Route
              path="*"
              element={<NotFoundPage />}
            />
          </Routes>
        </div>

        <UpdateToProModal />
        <PaymentsStatusModal />
        <Toaster position="bottom-right" />
      </GrowthBookSetup>
    </Sentry.ErrorBoundary>
  );
}

export default App;
