import { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { updateSelectedDraft } from "@/store/draftSlice";
import {
  changeSelectedLayout,
  updateAutoAddEmojisToSubtitles,
  updateIsUserChangeLayout,
} from "@/store/editorSlice";
import { updateCurrentSelectedMicroContent } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";

import { EDITOR_INTERACTION_DATA } from "@/constants/amplitude";
import { aspectRatioTitle } from "@/constants/aspect-ratio";

import { getRandomTemplateByLayout } from "@/helpers";

import {
  trackEditorInteractionEvent,
  trackLayoutChangeEvent,
} from "@/utils/amplitudeAnalytcs";

import { ScreenName, VideoLayout } from "@/enums";

import { useEditorHistory } from "@/views/editor/EditorHistoryContext";
import { HistoryActionTypes } from "@/views/editor/EditorHistoryReducer";

const AspectRatioChangeDropdown = () => {
  const dispatch = useAppDispatch();

  const { editorDispatch } = useEditorHistory();

  const enableSceneChange = useAppSelector(
    (state) => state.editorState.enableSceneChange
  );

  const isUserChangedLayout = useAppSelector(
    (state) => state.editorState.isUserChangedLayout
  );

  const selectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );
  const isRenderingVideo = useAppSelector(
    (state) => state.editorState.isRenderingVideo
  );

  const defaultBaseTemplates = useGetDefaultBaseTemplates();

  const handelChangeLayout = (layout: VideoLayout) => {
    dispatch(changeSelectedLayout(layout));

    if (!isUserChangedLayout) {
      dispatch(updateIsUserChangeLayout(true));
    }

    const randomTemplate = getRandomTemplateByLayout(
      layout,
      defaultBaseTemplates?.data
    );

    if (layout === VideoLayout.LAYOUT_16_9) {
      dispatch(
        updateCurrentSelectedMicroContent({
          id: randomTemplate.id,
        })
      );
    } else if (
      layout === VideoLayout.LAYOUT_1_1 ||
      layout === VideoLayout.LAYOUT_9_16_1
    ) {
      dispatch(
        updateCurrentSelectedMicroContent({
          hasTwoFace: false,
          id: randomTemplate.id,
        })
      );
    } else {
      dispatch(
        updateCurrentSelectedMicroContent({
          hasTwoFace: true,
          id: randomTemplate.id,
        })
      );
    }

    editorDispatch({ type: HistoryActionTypes.RESET });
    trackLayoutChangeEvent(layout, ScreenName.EDITOR);
  };

  return (
    <div className=" relative z-50 text-right">
      <Menu
        as="div"
        className="relative inline-block text-left"
        data-tip={
          enableSceneChange
            ? "This feature is unavailable when using CutMagic"
            : ""
        }
      >
        <div>
          <Menu.Button
            disabled={enableSceneChange}
            className="inline-flex w-48 justify-between rounded-md bg-gray-500  px-4 py-2 text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            style={{
              cursor: enableSceneChange ? "not-allowed" : "",
            }}
            onClick={() => {
              trackEditorInteractionEvent(
                EDITOR_INTERACTION_DATA.ASPECT_RATIO.EVENT_KEY,
                true
              );
            }}
          >
            {aspectRatioTitle[selectedLayout]}
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-[50] right-0 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              {Object.keys(aspectRatioTitle).map(function (key) {
                return (
                  <Menu.Item
                    key={key}
                    disabled={isRenderingVideo || selectedLayout === key}
                  >
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-blue-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2`}
                        onClick={() => {
                          dispatch(updateSelectedDraft(null));
                          dispatch(updateAutoAddEmojisToSubtitles(false));
                          handelChangeLayout(key as VideoLayout);
                        }}
                      >
                        {aspectRatioTitle[key]}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default AspectRatioChangeDropdown;
