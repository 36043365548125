import { useSelectedClips } from "@/context/ReviewClips/SelectedClips";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { FolderArrowDownIcon, TrashIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { Button } from "flowbite-react";

import { toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { ANALYTICS_CONSTANTS } from "@/constants/amplitude";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import { eventsDataToRedux } from "@/utils/amplitudeAnalytcs";
import { showNotification } from "@/utils/showNotification";

import { PlanType } from "@/enums";

import { ReactComponent as CrownIcon } from "@/assets/icons/crown.svg";

const { CREATE_CUSTOM_CLIP_BTN_NEW_UI } = BUTTON_IDS.REVIEW_CLIPS;

type BottomCTAProps = {
  handleOpenCustomClipModal: () => void;
  toggleShowDeleteMultipleClipsModal: () => void;
  toggleSelectExportMultipleClipsModal: () => void;
};

const BottomCTA = ({
  handleOpenCustomClipModal,
  toggleShowDeleteMultipleClipsModal,
  toggleSelectExportMultipleClipsModal,
}: BottomCTAProps) => {
  const { selectedClipsCount, selectModeOn } = useSelectedClips();

  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );
  const isPaidUser = planType !== PlanType.FREE;
  const dispatch = useAppDispatch();

  const handleExportButtonClick = () => {
    if (!isPaidUser) {
      dispatch(toggleUpgradeToProModal(true));
      eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
      return;
    }
    // For now, we are limiting the export to 5 clips at a time
    if (selectedClipsCount > 5) {
      showNotification(
        "You can only export up to 5 clips at a time",
        "warning"
      );
    } else {
      toggleSelectExportMultipleClipsModal();
    }
  };

  return (
    <div className="z-20 bg-[#F3F4F6] py-4 absolute bottom-0 w-full px-6 flex items-center space-x-4 transition-all">
      <Button
        color="light"
        id={CREATE_CUSTOM_CLIP_BTN_NEW_UI.ID}
        className={clsx(
          "z-20 h-12 w-full inline-flex justify-center items-center rounded-lg border border-[#1C64F2] bg-white text-center text-sm font-medium text-blue-500 hover:bg-blue-50  focus:outline-none focus:ring-4 ring-blue-300",
          selectModeOn && "hidden"
        )}
        onClick={handleOpenCustomClipModal}
      >
        <PlusCircleIcon
          width={22}
          height={22}
        />
        <span className="text-md ml-4">Create custom Clip</span>
      </Button>
      <Button
        className={clsx(
          "flex",
          selectModeOn ? "w-1/2" : " hidden",
          !selectedClipsCount && "opacity-70 cursor-not-allowed"
        )}
        onClick={toggleShowDeleteMultipleClipsModal}
        color="red"
        disabled={!selectedClipsCount}
        id="delete-multiple-clips-btn"
      >
        <TrashIcon className="text-red-500 w-4 h-4 mr-1 -mt-0.5" />
        <span className="text-red-500 whitespace-nowrap">Delete All</span>
      </Button>
      <Button
        className={clsx(
          selectModeOn ? "w-1/2" : " hidden",
          !selectedClipsCount && "opacity-70 cursor-not-allowed"
        )}
        onClick={handleExportButtonClick}
        disabled={!selectedClipsCount}
        id="export-multiple-clips-btn"
      >
        {isPaidUser ? (
          <FolderArrowDownIcon className="text-white w-4 h-4 mr-1.5 -mt-0.5" />
        ) : (
          <CrownIcon className="w-5 h-5 mr-1.5" />
        )}
        Export All
      </Button>
    </div>
  );
};

export default BottomCTA;
