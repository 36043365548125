import { useDropzone } from "react-dropzone";
import { useOutletContext } from "react-router-dom";
import { useToggle } from "react-use";

import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "flowbite-react";

import { store } from "@/store";
import { setSocialMediaHandels } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { uploadFileToS3 } from "@/api/requests";
import useGetAssets from "@/api/useGetAssets";
import useUploadAsset from "@/api/useUploadAsset";

import { DEFAULT_SOCIAL_IMAGE_SIZE } from "@/constants";
import {
  ANALYTICS_CONSTANTS,
  EDITOR_INTERACTION_DATA,
} from "@/constants/amplitude";
import { MediaIconSocial, MediaIconBrandKit } from "@/constants/brand-kit";

import {
  eventsDataToRedux,
  trackEditorInteractionEvent,
} from "@/utils/amplitudeAnalytcs";

import {
  AppliedCardVariant,
  AssetTags,
  SimpleAssetType,
  TextAlignment,
} from "@/enums";

import AccordionLayout from "@/components/Accordion/AccordionLayout";

import { useEditorHistory } from "@/views/editor/EditorHistoryContext";
import { HistoryActionTypes } from "@/views/editor/EditorHistoryReducer";
import {
  addLogosCommand,
  addSocialCalloutCommand,
  deleteLogoCommand,
  removeSocialCalloutCommand,
} from "@/views/editor/commands/logosCommand";
import {
  AppliedAssetCard,
  AppliedAssetCardList,
} from "@/views/editor/components/AppliedAssetCard";
import AssetList from "@/views/editor/components/Assets/AssetList";
import { UploadAssetButton } from "@/views/editor/components/UploadAssetButton";
import {
  INIT_TEXT_STYLES,
  SOCIAL_MEDIA_HANDLES,
} from "@/views/editor/constant";

const LogoEditor = () => {
  const dispatch = useAppDispatch();
  const { editorDispatch } = useEditorHistory();

  const [isUploadLoading, toggleUploadLoading] = useToggle(false);

  const socialMediaHandels: any[] = useAppSelector(
    (state) => state.editorState.socialMediaHandels
  );

  const assetUrls = useAppSelector((state) => state.editorState.assetUrls);

  const userSocialMediaHandles = useAppSelector(
    (state) => state.homeState.userSocialMediaHandles
  );
  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const { mutate: finalizeUpload, isLoading: finalizeUploadLoading } =
    useUploadAsset(AssetTags.LOGO);

  const { data, isLoading } = useGetAssets({
    assetTag: AssetTags.LOGO,
  });

  const {
    onTextDelete,
    addSocialTemplatesToCanvas,
    handleRemoveAsset,
    backgroundClipPath,
    handleAddAsset,
    onTextChange,
    textsObj,
    handelChangeSideBarMenu,
  } = useOutletContext<any>();

  const onDrop = async (acceptedFiles: any) => {
    const file = acceptedFiles?.[0];

    if (!file) {
      return;
    }

    try {
      toggleUploadLoading(true);
      const res = await uploadFileToS3(file);
      res?.s3Url &&
        finalizeUpload({
          assetType: file.type,
          assetId: res?.assetId,
          ownerId: uid,
          parentId: null,
          assetTag: AssetTags.LOGO,
          data: {
            assetName: file.name,
            remoteUrl: res?.s3Url,
          },
        });
      toggleUploadLoading(false);
      res?.s3Url &&
        handleAddLogo(
          {
            url: res?.public_url || res?.s3Url,
            file: null,
            id: res?.assetId,
          },
          SimpleAssetType.IMAGE
        );

      removeAll();
      eventsDataToRedux(ANALYTICS_CONSTANTS.UPLOADS.LOGO_UPLOAD);
    } catch (e: any) {
      toggleUploadLoading(false);
      removeAll();
      console.log("Error while uploading Logo", e);
      return;
    }
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef }: any =
    useDropzone({
      onDrop,
      accept: {
        "image/jpeg": [".jpeg", ".png"],
      },
      maxFiles: 1,
    });

  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef.current) inputRef.current.value = "";
  };

  const handleAddSocialCallout = (item: any, assetType: SimpleAssetType) => {
    const metadata = {
      item: { ...item },
      assetType,
    };

    const command = addSocialCalloutCommand(
      metadata,
      handelAddSocialHandel,
      handelRemoveSocialMediaHandel
    );

    eventsDataToRedux(ANALYTICS_CONSTANTS.LOGO_TYPE, assetType);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handelAddSocialHandel = (item: any) => {
    // need to read from the store itself
    // because at the time of undo/redo
    // the component socialMediaHandels using useAppSelector will be stale
    const socialMediaHandlesFromRedux =
      store.getState().editorState.socialMediaHandels;

    if (socialMediaHandlesFromRedux.find((el: any) => el.id === item.id)) {
      return;
    }

    const userSavedSocialHandel = userSocialMediaHandles.find(
      (socialHandel: any) => socialHandel.id === item.id
    );

    const socialLogoDetails = {
      top: backgroundClipPath?.top,
      left: backgroundClipPath?.left,
      id: item.id,
      url: item.url,
    };
    const socialTextDetails = {
      top: backgroundClipPath?.top,
      left: backgroundClipPath?.left + DEFAULT_SOCIAL_IMAGE_SIZE,
      textBoxWidth: 100,
      textContent: userSavedSocialHandel
        ? userSavedSocialHandel.socialMediaHandle
        : item.textContent,
      textSize: 14,
      fontFace: "Montserrat",
      effectType: "none",
      Id: `${item.id}_text`,
      textAlignment: TextAlignment.LEFT,
      textBold: true,
      blockBackground: {
        ...INIT_TEXT_STYLES.blockBackground,
        radius: 0,
        padding: 0,
      },
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      margin: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
    };

    const updatedItem = {
      ...item,
      textContent: userSavedSocialHandel
        ? userSavedSocialHandel.socialMediaHandle
        : item.textContent,
    };

    dispatch(
      setSocialMediaHandels([...socialMediaHandlesFromRedux, updatedItem])
    );

    addSocialTemplatesToCanvas({
      logoDetails: socialLogoDetails,
      textDetails: socialTextDetails,
      isNewSocial: true,
    });
  };

  const handelRemoveSocialMediaHandel = (item: any) => {
    // need to read from the store itself
    // because at the time of undo/redo
    // the component socialMediaHandels using useAppSelector will be stale
    const socialMediaHandlesFromRedux =
      store.getState().editorState.socialMediaHandels;

    const filteredArr = socialMediaHandlesFromRedux.filter(
      (el: any) => el.id !== item.id
    );
    dispatch(setSocialMediaHandels(filteredArr));

    onTextDelete(`${item.id}_text`, item?.isUndoOperation);
    handleRemoveAsset(item.id);
  };

  const handleRemoveSocialCallout = (item: any) => {
    const metadata = {
      item: { ...item },
    };

    const command = removeSocialCalloutCommand(
      metadata,
      handelAddSocialHandel,
      handelRemoveSocialMediaHandel
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handleAddLogoCb = (assetData: any) => {
    const { item } = assetData;
    handleAddAsset({
      url: item.data ? JSON.parse(item.data).remote_url : item.url,
      file: null,
      assetTag: AssetTags.LOGO,
      assetId: item.id,
      assetWidth: item.assetWidth || 100,
      assetHeight: item.assetHeight || 100,
      top: item?.top,
      left: item?.left,
    });
  };

  const handleAddLogo = (item: any, assetType: SimpleAssetType) => {
    const metadata = {
      item: { ...item },
      assetType,
    };

    const command = addLogosCommand(
      metadata,
      handleAddLogoCb,
      handleRemoveAsset
    );

    eventsDataToRedux(ANALYTICS_CONSTANTS.LOGO_TYPE, assetType);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handleRemoveLogo = (item: any, assetType: SimpleAssetType) => {
    const metadata = {
      item: {
        ...item,
        id: item.uid,
        assetWidth: item?.metaData?.assetWidth || 100,
        assetHeight: item?.metaData?.assetHeight || 100,
        top: item?.metaData?.top,
        left: item?.metaData?.left,
      },
      assetType,
    };
    const command = deleteLogoCommand(
      metadata,
      handleAddLogoCb,
      handleRemoveAsset
    );

    eventsDataToRedux(ANALYTICS_CONSTANTS.LOGO_TYPE, assetType);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  return (
    <div>
      <div
        className="my-4 flex w-full items-center justify-center"
        {...getRootProps()}
        onClick={(e) => e.stopPropagation()}
      >
        <UploadAssetButton
          label="Upload Logo"
          id="dropzone-file-logo-editor"
          isLoading={isUploadLoading || finalizeUploadLoading}
          getInputProps={getInputProps}
          isDisabled={isUploadLoading || finalizeUploadLoading}
        />
      </div>

      <AppliedAssetCardList>
        {assetUrls
          ?.filter(
            (asset) =>
              asset.assetTag?.includes(AssetTags.LOGO) &&
              !asset?.metaData?.isSocialLogo
          )
          .map((asset) => (
            <AppliedAssetCard
              key={asset.uid}
              cardType={AppliedCardVariant.SOCIAL}
              assetUrl={asset.url}
              onDelete={() => handleRemoveLogo(asset, SimpleAssetType.IMAGE)}
              primaryLabel="Custom Logo"
              // setting assetType to image, because logo can only be image for now
              assetType={SimpleAssetType.IMAGE}
            >
              <p></p>
            </AppliedAssetCard>
          ))}
        {socialMediaHandels.map((social: any) => (
          <AppliedAssetCard
            key={social.id}
            cardType={AppliedCardVariant.SOCIAL}
            assetUrl={social.url}
            onDelete={() => handleRemoveSocialCallout(social)}
            primaryLabel={social.labelName}
            assetType={social.type}
            isSocialLogo={true}
          >
            <div className="flex items-center space-x-3">
              <input
                value={textsObj[`${social.id}_text`]?.content}
                onChange={(e: any) =>
                  onTextChange(e.target.value, `${social.id}_text`)
                }
                className="block h-9 w-44 rounded-lg 
                    border border-gray-200 bg-gray-100 px-2 py-1.5 
                    text-sm font-normal text-gray-600 focus:border-blue-500 focus:ring-blue-500"
                disabled={!textsObj[`${social.id}_text`]?.id}
              />
              {textsObj[`${social.id}_text`]?.content ? (
                <Tooltip
                  content="Edit style"
                  arrow={false}
                  placement="bottom"
                  className="px-4 py-[5px]"
                >
                  <PencilSquareIcon
                    className="h-5 w-5 cursor-pointer text-gray-500 transition-colors hover:text-gray-600"
                    strokeWidth={2}
                    onClick={() => handelChangeSideBarMenu("texts")}
                  />
                </Tooltip>
              ) : (
                <PencilSquareIcon
                  className="h-5 w-5 text-gray-500  opacity-60"
                  strokeWidth={2}
                />
              )}
            </div>
          </AppliedAssetCard>
        ))}
      </AppliedAssetCardList>

      <div className="mt-[70px]">
        <AccordionLayout
          title="Brand Kit Logos"
          Icon={MediaIconBrandKit}
          eventData={{
            key: EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.EVENT_KEY,
            value:
              EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.BRAND_KIT_LOGO_CLICKED,
          }}
        >
          <AssetList
            items={data}
            onClick={handleAddLogo}
            isLoading={isLoading}
            assetType={`Brandkit-${AssetTags.LOGO}`}
          />
        </AccordionLayout>
        <AccordionLayout
          title="Social Logos"
          Icon={MediaIconSocial}
          eventData={{
            key: EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.EVENT_KEY,
            value: EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.SOCIAL_LOGO_CLICKED,
          }}
        >
          <AssetList
            items={SOCIAL_MEDIA_HANDLES}
            onClick={handleAddSocialCallout}
            isSocialMediaIcon={true}
            assetType={`Stock-${AssetTags.LOGO}`}
          />
        </AccordionLayout>
      </div>
    </div>
  );
};

export default LogoEditor;
