import { createCommand, Command } from "@/views/editor/EditorHistoryReducer";

export const updateOutroCommand = (
  metaData: any,
  handleUpdateOutro: (outroData: any) => void
): Command => {
  const { newOutroData, oldOutroData } = metaData;
  return createCommand({
    executeAction: () => handleUpdateOutro(newOutroData),
    undoAction: () => handleUpdateOutro(oldOutroData),
  });
};
