import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";

import useKeyboardShortcutForEditorUndoRedo from "@/hooks/useKeyboardShortcutForEditorUndoRedo";

import { useEditorHistory } from "@/views/editor/EditorHistoryContext";
import { HistoryActionTypes } from "@/views/editor/EditorHistoryReducer";

const UndoRedoButton = () => {
  const { editorHistoryState, editorDispatch } = useEditorHistory();

  const handleUndo = () => {
    editorDispatch({ type: HistoryActionTypes.UNDO });
  };
  const handleRedo = () => {
    editorDispatch({ type: HistoryActionTypes.REDO });
  };

  useKeyboardShortcutForEditorUndoRedo(handleUndo, handleRedo);

  return (
    <div className="flex space-x-3 mr-2">
      <button
        disabled={!editorHistoryState.canUndo}
        onClick={handleUndo}
        id="editor-undo-button"
      >
        <ArrowUturnLeftIcon
          width={24}
          strokeWidth={2}
          className={clsx(
            "transition-colors",
            editorHistoryState.canUndo
              ? "text-gray-800 cursor-pointer "
              : "text-gray-400 cursor-default"
          )}
        />
      </button>
      <button
        disabled={!editorHistoryState.canRedo}
        onClick={handleRedo}
        id="editor-redo-button"
      >
        <ArrowUturnRightIcon
          width={24}
          strokeWidth={2}
          className={clsx(
            "transition-colors",
            editorHistoryState.canRedo
              ? "text-gray-800 cursor-pointer "
              : "text-gray-400 cursor-default"
          )}
        />
      </button>
    </div>
  );
};

export default UndoRedoButton;
