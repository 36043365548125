import { AssetTags } from "@/enums";

import EditorRoutes from "@/views/editor/routes";

export const EVENT_TYPE = {
  TRACK: "TRACK",
  IDENTIFY: "IDENTIFY",
  REVENUE: "REVENUE",
};

export const IDENTIFY_TYPE = {
  USER_IDENTIFY: "USER_IDENTIFY",
  SOCIAL_PLATFORM_LINKED: "SOCIAL_PLATFORM_LINKED",
  TRACK_USAGE: "TRACK_USAGE",
  TRACK_MINUTES_ADDED: "TRACK_MINUTES_ADDED",
  AB_TESTING_TRACK: "AB_TESTING_TRACK",
};

// actual event title on Amplitude,
// changing anything here will create a separate event on Amplitude and MoEngage with same properties
export const EVENTS = {
  ACCOUNT_DELETE: "Account Delete",
  ADD_MORE_MINUTES_CLICK: "Add More Minutes Click",
  ADD_MORE_MINUTES: "addMoreMinutes",
  CANCEL_SUBSCRIPTION: "Cancel Subscription",
  CLICK_START_IMPORT: "Click Start Import",
  COUPON_APPLIED: "Coupon Applied",
  CREATE_CUSTOM_CLIP: "Create Custom Clip",
  DELETE_CLIP: "Delete Clip",
  DISCORD_ICON_CLICK: "Discord Icon Click",
  DOWNLOADS_CLICK: "Downloads Click",
  EDIT_AND_DOWNLOAD_VIDEO_CLICK: "Edit & Download Video clicked",
  EDITOR_INTERACTIONS: "Editor Interactions",
  ENABLE_CUTMAGIC: "Enable CutMagic",
  EXPORT_CLIP: "Export Clip",
  FINNISH_ON_BOARDING: "Finish On-boarding",
  IMPORT_VIDEO_CLICK: "import video click",
  MANAGE_SUBSCRIPTION: "Manage Subscription",
  MEDIA_CLICK: "Media Click",
  MINUTES_RENEWD: "Minutes Renewed",
  PLAN_CHANGED: "Plan Changed",
  PROFILE_ICON_CLICK: "Profile Icon Click",
  RENAME_CLIP: "Rename Clip",
  REVIEW_CLIPS: "Review Clips",
  REVIEW_SCREEN_INTERATION: "Review Screen Interactions",
  SAVE_DRAFT: "Save Draft",
  SAVE_TEMPLATE: "Save Template",
  SIGN_IN: "Sign in",
  SIGNUP: "Sign up",
  SOCIAL_MEDIA_POST_SCHEDULE: "Social Media Post Schedule",
  SUBSCRIPTION_PAYMENT_FAILED: "Subscription Payment Failed",
  SUBSCRIBE_NOW_BUTTON_CLICK: "Subscribe Now Button Click",
  SWITCH_CLIP_ASPECT_RATIO: "Switch Clip Aspect Ratio",
  UPGRADE_BUTTON_CLICK: "Upgrade Button Click",
  VIEW_ONBOARDING: "View OnBoarding",
  WHATS_NEW_ICON_CLICK: "Whats New Icon Click",
};

export const SAVE_AS_NEW_DRAFT = "new-draft";
export const UPDATE_DRAFT = "update-draft";

export const B_ROLL_IMAGE = "image";
export const B_ROLL_VIDEO = "video";

export const STOCK_BACKGROUND = `Stock-${AssetTags.BACKGROUND}`;
export const BRAND_KIT_BACKGROUND = `Brandkit-${AssetTags.BACKGROUND}`;
export const BRAND_KIT_OUTRO = `Brandkit-${AssetTags.OUTRO}`;
export const STOCK_OUTRO = `Stock-${AssetTags.OUTRO}`;

export const AUDIO_TYPE_USED = {
  STOCK_AUDIO: "StockAudio",
  BRAND_KIT_AUDIO: "BrandKitAudio",
};

export const IMPORT_PREFERENCE = {
  LOCAL_DEVICE: "local device",
};

export const EXPORT_PREFERENCE = {
  DOWNLOAD: "download",
  TIKTOK: "tiktok",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedin",
  TWITTER: "twitter",
  YOUTUBE: "youtube",
};

export const OVERVIEW_CONSTANT = {
  SHORTS: "shorts",
  CHAPTERS: "chapters",
  FULL_VIDEO: "full video",
};

export const ANALYTICS_CONSTANTS = {
  CLIP_COUNT: "CLIP_COUNT",
  OVERVIEW_TYPE: "OVERVIEW_TYPE",
  SUBTITLE_REMOVED: "SUBTITLE_REMOVED",
  DOWNLOAD_SRT: "DOWNLOAD_SRT",
  SUBTITLE_STYLE_CLICK: "SUBTITLE_STYLE_CLICK",
  SOCIAL_PREVIEW_ACCESSED: "SOCIAL_PREVIEW_ACCESSED",
  OUTRO_TYPE: "OUTRO_TYPE",
  LOGO_TYPE: "LOGO_TYPE",
  BROLL_TYPE: "BROLL_TYPE",
  BACKGROUND_TYPE: "BACKGROUND_TYPE",
  AUDIO_TYPE: "AUDIO_TYPE",
  AI_CAPTIONS: "AI_CAPTIONS",
  ENABLE_CUTMAGIC: "ENABLE_CUTMAGIC",
  FONT_SETTINGS_ACCESSED: "FONT_SETTINGS_ACCESSED",
  UPLOAD_CUSTOM_FONT: "UPLOAD_CUSTOM_FONT",
  TEXT_EFFECT: "TEXT_EFFECT",
  BLOCK_BACKGROUND: "Block Background",
  SHADOW: "Shadow",
  OUTLINE: "Outline",
  TEXT_BACKGROUND: "Text Background",
  NO_EFFECT: "No Effect",
  UPLOADS: {
    BACKGROUND_UPLOAD: "BACKGROUND_UPLOAD",
    FONT_UPLOAD: "FONT_UPLOAD",
    LOGO_UPLOAD: "LOGO_UPLOAD",
    OUTRO_IMAGE_UPLOAD: "OUTRO_IMAGE_UPLOAD",
    OUTRO_VIDEO_UPLOAD: "OUTRO_VIDEO_UPLOAD",
    AUDIO_UPLOAD: "AUDIO_UPLOAD",
  },
  EXPORT_PROJECT_ID: "EXPORT_PROJECT_ID",
  PAYMENT_SCREEN_NAME: "PAYMENT_SCREEN_NAME",
  EDITOR_MEDIA_PEXEL_SEARCH: "EDITOR_MEDIA_PEXEL_SEARCH",
  MANAGE_SUBSCRIPTION: {
    UPDATE_PAYMENT_METHOD: "UPDATE_PAYMENT_METHOD",
    PLAN_CHANGED: "PLAN_CHANGED",
    SUBSCRIPTION_CANCELLED: "SUBSCRIPTION_CANCELLED",
    SUBSCRIPTION_PAUSED: "SUBSCRIPTION_PAUSED",
    SUBSCRIPTION_RESUMED: "SUBSCRIPTION_RESUMED",
  },
  PROFIL_ICON_CLICKED: {
    ACCOUNT_DELETE: "ACCOUNT_DELETE",
    PASSWORD_CHANGE: "PASSWORD_CHANGE",
    NAME_UPDATED: "NAME_UPDATE",
  },
};

export const REVIEW_CLIPS_INTERACTION = {
  TRANSCRIPT_CLICK: "TRANSCRIPT_CLICK",
  TRANSCRIPT_COPIED: "TRANSCRIPT_COPIED",
  AI_CAPTION_CLICK: "AI_CAPTION_CLICK",
  AI_CAPTIONS: "AI_CAPTIONS",
  VIDEO_TIMESTAMP_CLICKED: "VIDEO_TIMESTAMP_CLICKED",
  VIDEO_TIMESTAMP_COPIED: "VIDEO_TIMESTAMP_COPIED",
};

export const AMPLITUDE_CLICK_EVENTS = {
  UPLOAD_VIDEO_MODAL: "Upload Video Modal",
  UPDATE_TO_PRO_MODAL: "Update to Pro Modal",
  PROFILE_DROPDOWN_MENU: "Profile Dropdown Menu",
  HEADER: "Header",
};

export const EDITOR_INTERACTION_DATA = {
  TEMPLATES_TAB: {
    EVENT_KEY: "templateInteraction",
    ALL_TEMPLATES: "allTemplates",
    MY_TEMPLATES: "myTemplates",
  },
  CUTMAGIC: {
    EVENT_KEY: "cutMagicClicked",
  },
  SUBTITLES_TAB: {
    EVENT_KEY: "subtitlesAccessed",
    STYLES: "styles",
    SUBTITLE_EDITOR: "subtitlesEditor",
    FONT_SETTINGS: "fontSettings",
    SRT_DOWNLOAD: "srtDownload",
    AUTO_ADD_EMOJI: "autoAddEmoji",
    SUBTITLES_DELETED: "subtitlesDeleted",
    ENABLE_SUBTITLES: "enableSubtitles",
  },
  ELEMENTS_CLICKED: {
    EVENT_KEY: "elementsClicked",
    PROGRESS_BAR_CLICKED: "progressBarClicked",
    LOGO_CLICKED: "logosClicked",
    OUTRO_CLICKED: "outroClicked",
    STOCK_OUTRO_CLICKED: "stockOutroClicked",
    BRAND_KIT_OUTRO_CLICKED: "brandkitOutrosClicked",
    SOCIAL_LOGO_CLICKED: "socialLogosClicked",
    BRAND_KIT_LOGO_CLICKED: "brandkitLogosClicked",
    PROGRESS_BAR_THICKNEDD_EDITED: "progressbarThicknessEdited",
    PROGRESS_BAR_COLOR_CHANGED: "progressBarColorChanged",
    REMOVE_PROGRESS_BAR: "removeProgressbar",
    ADD_PROGRESS_BAR: "addProgressBar",
  },
  MEDIA_CLICKED: {
    EVENT_KEY: "mediaClicked",
    IMAGE_CLICKED: "imageClicked",
    AUDIO_CLICKED: "audioClicked",
    VIDEO_CLICKED: "videoClicked",
    IMAGE_UPLOADED: "imageUploaded",
    VIDEO_UPLOADED: "videoUploaded",
    STOCK_AUDIO_ACCESSED: "stockAudioAccessed",
    ADDED_B_ROLLS_VIEWED: "addedBRollsViewed",
    MEDIA_SEARCHED: "mediaSearched",
  },
  TEXT_CLICKED: {
    EVENT_KEY: "textClicked",
    ADD_NEW_TEXT_BUTTON: "addNewtextButtonClicked",
    STYLES_ACCESSED: "stylesAccessed",
  },
  BACKGROUND_CLICKED: {
    EVENT_KEY: "backgroundClicked",
    BACKGROUND_UPLOADED: "backgroundUploaded",
    BRAND_KIT_BACKGROUND_ACCESSED: "brandKitBackgroundAccessed",
    STOCK_BACKGROUND_ACCESSED: "stockBackgroundAccessed",
    BACKGROUND_COLOR_CHANGE: "backgroundColorChanged",
  },
  AI_DESCRIPTION_CLICKED: {
    EVENT_KEY: "aiDescriptionClicked",
    TIKTOK: "tiktok",
    YT_SHORTS: "youtubeShorts",
    INSTAGRAM: "instagram",
    FACEBOOK: "facebook",
    TWITTER: "twitter",
    LINKEDIN: "linkedin",
    YOUTUBE: "youtubeVideo",
  },
  SOCIAL_PREVIEW: {
    EVENT_KEY: "socialPreviewClicked",
    TIKTOK: "tiktokPreview",
    REELS: "reelsPreview",
    YT_SHORTS: "shortsPreview",
  },

  SAVE_DRAFT: {
    EVENT_KEY: "saveDraftClicked",
  },
  ASPECT_RATIO: {
    EVENT_KEY: "aspectRatioButtonClicked",
  },
  SAVE_TEMPLATE: {
    EVENT_KEY: "saveTemplateClicked",
  },
  DOWNLOAD_VIDEO: {
    EVENT_KEY: "downloadVideoClicked",
  },
  TIMELINE_INTERACTION: {
    EVENT_KEY: "timelineInteractions",
    ZOOM_IN_CLICKED: "zoomInClicked",
    ZOOM_OUT_CLICKED: "zoomOutClicked",
    SLIDER_USED: "sliderUsed",
    PLAY_PAUSE_CLICKED: "playPauseClicked",
    SKIP_1_BACK_FRAME_CLICKED: "skip1FrameBackClicked",
    SKIP_1_FWD_FRAME_CLICKED: "skip1FrameForwardClicked",
    SKIP_5_BACK_FRAME_CLICKED: "skip5SecBackClicked",
    SKIP_5_FWD_FRAME_CLICKED: "skip5SecForwardClicked",
    TIMESTAMP_COPIED: "timeStampCopied",
    PLAYHEAD_USED: "playheadUsed",
    SELECTION_BOX_USED: "selectionBoxUsed",
    FIT_CLICKED: "fitClicked",
  },
  UNDO: "undo",
  REDO: "redo",
};

export const EDITOR_ROUTE_MAP = {
  ["templates"]: EDITOR_INTERACTION_DATA.TEMPLATES_TAB.EVENT_KEY,
  ["background"]: EDITOR_INTERACTION_DATA.BACKGROUND_CLICKED.EVENT_KEY,
  ["elements"]: EDITOR_INTERACTION_DATA.ELEMENTS_CLICKED.EVENT_KEY,
  ["texts"]: EDITOR_INTERACTION_DATA.TEXT_CLICKED.EVENT_KEY,
  ["subtitles"]: EDITOR_INTERACTION_DATA.SUBTITLES_TAB.EVENT_KEY,
  ["scene-change"]: EDITOR_INTERACTION_DATA.CUTMAGIC.EVENT_KEY,
  ["caption"]: EDITOR_INTERACTION_DATA.AI_DESCRIPTION_CLICKED.EVENT_KEY,
  ["media"]: EDITOR_INTERACTION_DATA.MEDIA_CLICKED.EVENT_KEY,
};
