import { USER_WANTS_LIST } from "@/constants/on-boarding";

import { UserWants } from "@/interfaces";

import { OnBoardingCheckbox } from "./OnBoardingInputs";
import { HandelOnboardingDataType } from "./types";

export const UserMainFocus = ({
  handleOnBoardingData,
  userWants,
}: {
  handleOnBoardingData: (data: HandelOnboardingDataType) => void;
  userWants: UserWants[];
}) => {
  const handelLocalUserWants = (value: string) => {
    const isUserWantsExist = userWants?.find((userWant) => userWant === value);
    if (isUserWantsExist) {
      const newUserWants = userWants?.filter((userWant) => userWant !== value);

      handleOnBoardingData({
        onBoardingData: {
          user_wants_to: newUserWants,
        },
      });
    } else {
      const updatedUserWants = [...userWants, value];
      handleOnBoardingData({
        onBoardingData: {
          user_wants_to: updatedUserWants,
        },
      });
    }
  };

  return (
    <div>
      <p className="mt-3 text-xs leading-6 text-gray-400 lg:text-base">
        Tell us about your immediate goals with video content
      </p>

      <p className="my-4 mb-2 text-xl font-semibold leading-6 text-gray-900 sm:text-2xl">
        What do you want to achieve with vidyo.ai ?
      </p>

      <p className="mt-4 text-xs leading-6 text-gray-400 lg:text-sm">
        You can select multiple options
      </p>

      <div className=" mt-3.5 w-full px-4">
        {USER_WANTS_LIST.map((referer, index) => {
          // wrap every two checkboxes in a div with flex and space-x classes
          if (index % 2 === 0) {
            return (
              <div
                className="flex justify-between gap-4"
                key={referer.value}
              >
                <OnBoardingCheckbox
                  key={referer.id}
                  id={referer.id}
                  label={referer.value}
                  value={referer.value}
                  className="w-1/2" // half the width of its parent
                  onChange={(e) =>
                    handelLocalUserWants(e.currentTarget.value as UserWants)
                  }
                  isSelected={userWants?.includes(referer.value as UserWants)}
                />
                {USER_WANTS_LIST[index + 1] && (
                  <OnBoardingCheckbox
                    key={USER_WANTS_LIST[index + 1].id}
                    id={USER_WANTS_LIST[index + 1].id}
                    label={USER_WANTS_LIST[index + 1].value}
                    value={USER_WANTS_LIST[index + 1].value}
                    className="w-1/2" // half the width of its parent
                    onChange={(e) =>
                      handelLocalUserWants(e.currentTarget.value as UserWants)
                    }
                    isSelected={userWants?.includes(
                      USER_WANTS_LIST[index + 1].value as UserWants
                    )}
                  />
                )}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
