import { SVGProps, memo } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <rect
      width={22}
      height={22}
      fill="#FFC847"
      rx={4}
    />
    <path
      fill="#252521"
      d="M15.5 7.728V5.5a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v2.25a1.004 1.004 0 0 0 .4.8L10.167 11 6.9 13.45a1.004 1.004 0 0 0-.4.8v2.25a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-2.227a1.005 1.005 0 0 0-.397-.799L11.83 11l3.274-2.474a1.005 1.005 0 0 0 .397-.798Zm-1-2.228V7h-7V5.5h7Zm0 11h-7v-2.25l3.5-2.625 3.5 2.647V16.5Z"
    />
  </svg>
);
const ExpSoonIcon = memo(SvgComponent);
export default ExpSoonIcon;
