import { useMutation } from "@tanstack/react-query";
import { nanoid } from "nanoid";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";

import {
  getUniqueRandomBrightColor,
  initVideoPosition,
  isTwoFace,
  sortScenesByStartTime,
} from "@/helpers";

import { SceneResponse } from "@/interfaces";

export const getSceneChanges = async (body: any): Promise<SceneResponse> => {
  const { data }: { data: SceneResponse } = await api.post(
    ApiEndpoints.CREATE_SCENES,
    {
      ...body,
    }
  );

  if (["completed", "Success"].includes(data.status)) {
    const sortedScenes = sortScenesByStartTime(data.scenes);
    const updatedSceneChanges = sortedScenes.map((scene) => {
      return {
        ...scene,
        id: nanoid(),
        color: getUniqueRandomBrightColor(),
        position: initVideoPosition,
        isTwoFace: isTwoFace(scene.face),
      };
    });
    return { ...data, scenes: updatedSceneChanges };
  }
  return data;
};
