import { useInfiniteQuery } from "@tanstack/react-query";

import { PexelsPhotoResponse, PexelsVideoResponse } from "@/interfaces";

import { SimpleAssetType } from "@/enums";

import api from "./api";
import { ApiEndpoints } from "./constants";

type Orientation = "landscape" | "portrait" | "square";

const getPexelsAssets = async ({
  searchQuery,
  page,
  orientation,
  assetType,
  per_page = 18,
}: {
  searchQuery: string;
  page: number;
  orientation?: Orientation;
  assetType: SimpleAssetType;
  per_page?: number;
}): Promise<PexelsPhotoResponse | PexelsVideoResponse> => {
  const response = await api.get(
    assetType === SimpleAssetType.IMAGE
      ? ApiEndpoints.PEXELS_IMAGES
      : ApiEndpoints.PEXELS_VIDEOS,
    {
      params: { query: searchQuery, page, orientation, per_page },
    }
  );

  return response.data as PexelsPhotoResponse | PexelsVideoResponse;
};

export default function usePexel({
  assetType,
  disableQuery = false,
  searchQuery,
  orientation,
}: {
  assetType: SimpleAssetType;
  disableQuery?: boolean;
  searchQuery: string;
  orientation?: Orientation;
}) {
  return useInfiniteQuery({
    queryKey: ["Pexels-Assets", assetType, searchQuery, orientation],
    queryFn: ({ pageParam = 1 }) =>
      getPexelsAssets({
        searchQuery,
        page: pageParam,
        assetType: assetType,
        orientation,
      }),
    staleTime: 3000000,
    enabled: !disableQuery,
    getNextPageParam: (lastPage) => {
      if (lastPage.next_page) {
        const url = new URL(lastPage.next_page);
        const page = url.searchParams.get("page");
        return page ? parseInt(page, 10) : undefined;
      }
      return undefined;
    },
    refetchOnWindowFocus: false,
  });
}
