import React, { createContext, useReducer, useContext } from "react";

import {
  editorHistoryReducer,
  initialState,
  HistoryAction,
  HistoryState,
} from "./EditorHistoryReducer";

export const EditorHistoryContext = createContext<{
  editorHistoryState: HistoryState;
  editorDispatch: React.Dispatch<HistoryAction>;
}>({
  editorHistoryState: initialState,
  editorDispatch: () => null, // Default empty function
});

export const EditorHistoryProvider = ({ children }: any) => {
  const [editorHistoryState, editorDispatch] = useReducer(
    editorHistoryReducer,
    initialState
  );

  return (
    <EditorHistoryContext.Provider
      value={{ editorHistoryState, editorDispatch }}
    >
      {children}
    </EditorHistoryContext.Provider>
  );
};

export const useEditorHistory = () => useContext(EditorHistoryContext);
