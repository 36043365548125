import {
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from "@heroicons/react/20/solid";
import { Button, RangeSlider, Tooltip } from "flowbite-react";

interface TimelineZoomSliderProps {
  fitToScreen: () => void;
  value: number;
  handleStepIncrease: () => void;
  handleStepDecrease: () => void;
  handleSliderChange: (value: string) => void;
  trackSliderChange: () => void;
}

export const TimelineZoomSlider = ({
  value,
  trackSliderChange,
  handleSliderChange,
  handleStepDecrease,
  handleStepIncrease,
  fitToScreen,
}: TimelineZoomSliderProps): JSX.Element => {
  return (
    <div className="flex gap-2 items-center">
      <Tooltip
        placement="top"
        content="Fit timeline to screen"
      >
        <Button
          onClick={fitToScreen}
          size="xs"
          className="border-none"
          id="fit-timeline-button"
          color="light"
        >
          Fit
        </Button>
      </Tooltip>
      <Tooltip
        content="Zoom Out"
        placement="top"
      >
        <Button
          disabled={value === 0}
          onClick={handleStepDecrease}
          size="xs"
          className="border-none"
          id="zoom-out-button"
          color="light"
        >
          <MagnifyingGlassMinusIcon className="w-4 h-4" />
        </Button>
      </Tooltip>
      <RangeSlider
        className="items-center -translate-y-1"
        sizing="sm"
        min="0"
        max="100"
        step="1"
        id="zoom-slider"
        value={value}
        onChange={(e) => handleSliderChange(e.target.value)}
        onMouseUp={trackSliderChange}
      />
      <Tooltip
        content="Zoom In"
        placement="top"
      >
        <Button
          disabled={value === 100}
          onClick={handleStepIncrease}
          id="zoom-in-button"
          size="xs"
          className="border-none"
          color="light"
        >
          <MagnifyingGlassPlusIcon className="w-4 h-4" />
        </Button>
      </Tooltip>
    </div>
  );
};
