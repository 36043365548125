import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CheckIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Spinner, Tooltip } from "flowbite-react";

import {
  toggleAddMoreMinModal,
  toggleManageSubscriptionModal,
  toggleUpgradeToProModal,
  updatedPaymentStatus,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetUserOnBoardingData from "@/api/useGetUserOnBoardingData";

import { UPGRADE_MODAL_DEEPLINK } from "@/constants";
import { AMPLITUDE_CLICK_EVENTS } from "@/constants/amplitude";
import {
  BUTTON_IDS,
  USER_PAYMENT_CONSTANTS,
} from "@/constants/segment-analytics";

import {
  forceUpgradeModalOnUser,
  getPaddlePriceByPlanId,
  getPaddlePriceDetailsByPlanTypeAndInterval,
  getProductIdByPlanTypeAndDuration,
  isProd,
  planIdInterval,
} from "@/helpers";

import { handelLogout } from "@/utils/FirebaseHelpers";
import {
  addMoreMinutesButtonClick,
  trackSubscribeNowButtonClicked,
} from "@/utils/amplitudeAnalytcs";
import { purchaseConversionFbq } from "@/utils/fbq";
import { purchaseConversionGtag } from "@/utils/gtag";
import { purchaseConversionTrackReddit } from "@/utils/redditPixel";
import { showNotification } from "@/utils/showNotification";
import { purchaseConversionTrackTikTok } from "@/utils/tiktokPixel";

import { Subscription } from "@/interfaces";

import {
  PlanType,
  RouterPath,
  ScreenName,
  SubscriptionIntervalInMonths,
} from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";

const { SUBSCRIBE_NOW_BTN } = BUTTON_IDS.UPDATE_TO_PRO_MODAL;

const UNLIMITED_1080P_RENDERS = "1080p video renders";
const UPLOAD_TO_ALL_SOCIALS =
  "Publish and schedule to Tiktok, Reels, YT, Linkedin, Twitter, FB";
const BULK_DOWNLOAD = "Bulk download clips";
const TIKTOK_UPLOAD = "Publish to Tiktok only";
const INTELLICLIPS = "Intelliclips";
const SCHEDULE_SOCIAL_POSTS = "Schedule social posts";
const SAVE_DRAFTS = "Save unlimited drafts";
const CUSTOM_BRANDKITS = "Create custom Brand Kits";
const NEW_FEATURES_ACCESS = "New features access";
const NO_WATERMARKS = "No Watermarks";

const FREE_PLAN_FEATURES = ["720p video renders", TIKTOK_UPLOAD, NO_WATERMARKS];

const NOT_FREE_PLAN_FEATURES = [
  INTELLICLIPS,
  SCHEDULE_SOCIAL_POSTS,
  SAVE_DRAFTS,
  BULK_DOWNLOAD,
  CUSTOM_BRANDKITS,
  NEW_FEATURES_ACCESS,
];

const PRO_AND_PRO_PLUS_FEATURES = (isAnnual: boolean) => [
  UNLIMITED_1080P_RENDERS,
  INTELLICLIPS,
  SAVE_DRAFTS,
  BULK_DOWNLOAD,
  CUSTOM_BRANDKITS,
  NEW_FEATURES_ACCESS,
  UPLOAD_TO_ALL_SOCIALS,
];

const LEARN_MORE_NOTION =
  "https://vidyo-ai.notion.site/Full-Service-with-vidyo-ai-7bf0b673ab43450e865ef347c035fade";

const getSupportedFeatures = (planType: PlanType, isAnnual: boolean) => {
  if (planType === PlanType.PRO || planType === PlanType.PRO_PLUS) {
    return PRO_AND_PRO_PLUS_FEATURES(isAnnual);
  } else {
    return FREE_PLAN_FEATURES;
  }
};

const getNotSupportedFeatures = (planType: PlanType) => {
  if (planType === PlanType.FREE) {
    return NOT_FREE_PLAN_FEATURES;
  } else {
    return [];
  }
};

const PRICING_MODAL = "PRICING_MODAL";

function ListItem({ text = "", isSupported = true, isHalfWidth = false }) {
  return (
    <li
      className={`flex items-center space-x-3 gap-2 text-sm ${
        isSupported ? "" : "line-through text-gray-400"
      } ${isHalfWidth && "w-1/2"} `}
    >
      <CheckIcon
        className={`h-5 ${isSupported ? "text-green-400" : "text-gray-400"}`}
      />
      <div className="text-wrap">{text}</div>
    </li>
  );
}

const PriceLabel = ({
  isAnnual,
  planType,
}: {
  isAnnual: boolean;
  planType: PlanType;
}) => {
  // adding following to invoke re render for price
  const country = useAppSelector((state) => state.authState.country);
  const allPaddlePlans = useAppSelector((state) => state.authState.paddlePlans);
  const { plan_id } = useAppSelector(
    (state) => state.authState.paddleSubscription
  );
  const { planType: userPlanType, intervalInMonths: userSubscriptionInterval } =
    useAppSelector((state) => state.authState.userSubscription);

  const intervalTime = isAnnual
    ? SubscriptionIntervalInMonths.YEARLY
    : SubscriptionIntervalInMonths.MONTHLY;

  const isCurrentActivePlanIdAvailable =
    userPlanType === planType &&
    userSubscriptionInterval === intervalTime &&
    plan_id;

  let price =
    planType === PlanType.FREE
      ? 0
      : isCurrentActivePlanIdAvailable
      ? getPaddlePriceByPlanId(plan_id)
      : getPaddlePriceDetailsByPlanTypeAndInterval(planType, isAnnual);

  const planId =
    planType === PlanType.FREE
      ? 0
      : isCurrentActivePlanIdAvailable
      ? plan_id
      : getProductIdByPlanTypeAndDuration(planType, true);

  const pricePerMonthPaidAnnually = getPaddlePriceByPlanId(planId, true);

  if (isAnnual) {
    return (
      <>
        <div
          className={clsx("text-3xl font-bold text-gray-900 dark:text-white")}
        >
          {pricePerMonthPaidAnnually}
          {planType !== PlanType.FREE && (
            <span className="ml-2 text-xl font-light text-gray-500 text-light dark:text-gray-400">
              / mo
            </span>
          )}
        </div>
        {isAnnual && planType !== PlanType.FREE && (
          <p className="my-1 text-gray-500 font-normal dark:text-gray-400 relative top-[2px]">
            {price} paid annually
          </p>
        )}
      </>
    );
  } else {
    return (
      <>
        <div
          className={clsx("text-3xl font-bold text-gray-900 dark:text-white")}
        >
          {price}
          {planType !== PlanType.FREE && (
            <span className="ml-2 text-xl font-light text-gray-500 text-light dark:text-gray-400">
              / mo
            </span>
          )}
        </div>
        {planType !== PlanType.FREE && (
          <p className="my-1 text-gray-500 font-normal dark:text-gray-400 relative top-[2px]">
            paid monthly
          </p>
        )}
      </>
    );
  }
};

const PlanActionButton = ({
  subscription,
  handleCheckout,
  btnType,
  isLoading,
  isAnnual,
  id,
  planType,
}: {
  subscription: Subscription;
  handleCheckout: () => void;
  btnType: PlanType;
  isLoading: boolean;
  isAnnual: boolean;
  id: string;
  planType: PlanType;
}) => {
  let showCurrentPlanButton = subscription.planType === btnType;

  // if user is on annual plan than do not show on monthly plan
  if (
    !isAnnual &&
    subscription.intervalInMonths === SubscriptionIntervalInMonths.YEARLY
  ) {
    showCurrentPlanButton = false;
  }

  // if user is on monthly plan than do not show on annual plan
  if (
    isAnnual &&
    subscription.intervalInMonths === SubscriptionIntervalInMonths.MONTHLY
  ) {
    showCurrentPlanButton = false;
  }

  // if paddle subscription and cancelled than do not show current plan button
  if (subscription.isPaddle && subscription.cancelOn) {
    showCurrentPlanButton = false;
  }

  if (subscription.planType === PlanType.FREE && btnType === PlanType.FREE) {
    showCurrentPlanButton = true;
  }

  if (planType === PlanType.FREE) {
    return (
      <div
        className={clsx(
          "text-gray-600 bg-gray-100 font-medium rounded-lg text-md px-5 py-2.5 text-center dark:bg-gray-600 dark:text-white mb-2 h-10 mt-[40px]"
        )}
      >
        Free forever
      </div>
    );
  }

  return showCurrentPlanButton ? (
    <div
      className={clsx(
        "text-white bg-gray-900 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:text-white my-3"
      )}
    >
      Your Current Plan
    </div>
  ) : (
    <div
      id={id}
      className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-3 cursor-pointer"
      onClick={() => {
        handleCheckout();
        trackSubscribeNowButtonClicked();
      }}
      // disabled={shouldDisableButton()}
    >
      {isLoading && (
        <div className="mr-3">
          <Spinner
            size="sm"
            light={true}
          />
        </div>
      )}
      Subscribe Now
    </div>
  );
};

const Paddle = (window as any).Paddle;

const plans: Array<{
  name: string;
  type: PlanType;
  hide?: string;
}> = [
  // {
  //   name: "FULL SERVICE",
  //   type: PlanType.FULL_SERVICE,
  //   hide: "annual",
  // },
  {
    name: "FREE",
    type: PlanType.FREE,
  },
  {
    name: "PRO",
    type: PlanType.PRO,
  },
  {
    name: "PRO+",
    type: PlanType.PRO_PLUS,
  },
  // {
  //   name: "STARTER",
  //   type: PlanType.STARTER,
  //   hide: "monthly",
  // },
];

const UpdateToProModal = () => {
  const showProModalModal = useAppSelector(
    (state) => state.homeState.upgradeToProModalVisible
  );

  const currentUser = useAppSelector((state) => state.authState.currentUser);
  const currentUserSubscriptionDetails = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const homeAnalytics = useAppSelector(
    (state) => state.amplitudeState.homeScreen
  );

  const [isAnnual, setIsAnnual] = useState(true);
  const [loadingPlanType, setLoadingPlanType] = useState<PlanType | null>(null);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(PRICING_MODAL);

  const { data: onBoardingData } = useGetUserOnBoardingData(
    currentUser.uid,
    true
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const activeSubscriptionType = isAnnual ? "annual" : "monthly";

  useEffect(() => {
    setIsAnnual(
      currentUserSubscriptionDetails.intervalInMonths ===
        SubscriptionIntervalInMonths.YEARLY || true
    );
    setCurrentComponent(PRICING_MODAL);
  }, [showProModalModal]);

  // select plan if it is available with current subscription
  // else select Pro+ by default
  useEffect(() => {
    const availablePlan = plans.find(
      (planType) =>
        planType.type === selectedPlan.type &&
        planType.hide !== activeSubscriptionType
    );
    setSelectedPlan(availablePlan || plans[1]);
  }, [isAnnual]);

  const handelRedirectToSubscription = () => {
    navigate(RouterPath.MANAGE_SUBSCRIPTION);
    handelToggleUpdateToProModal(false);
  };

  const handelToggleUpdateToProModal = (state: boolean) => {
    dispatch(toggleUpgradeToProModal(state));
    sessionStorage.removeItem(UPGRADE_MODAL_DEEPLINK);
  };

  const errorCB = () => {
    // show notification
    showNotification(
      "If you are already subscribed, please give a minute to update your subscription."
    );
    dispatch(toggleUpgradeToProModal(false));
  };

  const successCB = () => {
    dispatch(toggleUpgradeToProModal(false));
  };

  const paddleInlineCheckout = (productId: number) => {
    const referral =
      (window as any).Rewardful && (window as any).Rewardful.referral;

    Paddle?.Checkout.open({
      product: productId, // replace with a product ID or plan ID
      allowQuantity: false,
      disableLogout: true,
      customData: {
        user_persona: onBoardingData?.occupation || "",
        isIndividual: onBoardingData?.isIndividual ?? false,
        referrer: onBoardingData?.referer ?? "",
      },
      passthrough: JSON.stringify({
        user_uid: currentUser.uid,
        email: currentUser.email,
        customer_name: currentUser.displayName,
        rewardful: { referral: referral },
        screenName: homeAnalytics?.paymentScreenName || ScreenName.HOME,
      }),
      email: currentUser.email,
      loadCallback: function (e: any) {
        setIsLoadingCheckout(false);
      },
      successCallback: function (e: any) {
        // gtm
        if (isProd()) {
          // TODO: Nipun will update this.
          // (window as any).dataLayer?.push({
          //   event: "order_success",
          //   order_value: e.checkout.prices.vendor.total,
          //   order_id: e.checkout.id,
          // });

          purchaseConversionGtag(
            currentUser.email,
            e.checkout.prices.vendor.total,
            e.checkout.id
          );
          purchaseConversionFbq(e.checkout.prices.vendor.total, e.checkout.id);

          purchaseConversionTrackTikTok(
            currentUser,
            e.checkout.id,
            e.checkout.prices.vendor.total
          );
          purchaseConversionTrackReddit(
            e.checkout.id,
            e.checkout.prices.vendor.total
          );
        }

        successCB();
        // plan purchase event
        dispatch(toggleUpgradeToProModal(false));
        dispatch(updatedPaymentStatus("Success"));
      },
    });
  };

  const handleCheckout = async (planType: PlanType, isAnnual: boolean) => {
    if (currentUserSubscriptionDetails.planType !== PlanType.FREE) {
      if (currentUserSubscriptionDetails.isPaddle) {
        // if user has cancelled the subscription we will allow them to start new subscription
        if (!currentUserSubscriptionDetails.cancelOn) {
          dispatch(toggleManageSubscriptionModal(true));
          dispatch(toggleUpgradeToProModal(false));
          return;
        }
      } else {
        handelRedirectToSubscription();
        return;
      }
    }

    setIsLoadingCheckout(true);
    setLoadingPlanType(planType);
    paddleInlineCheckout(getProductIdByPlanTypeAndDuration(planType, isAnnual));
    setIsLoadingCheckout(false);
  };

  const [selectedPlan, setSelectedPlan] = useState<{
    name: string;
    type: PlanType;
  }>(plans[1]);

  const selectPlan = (plan: any) => {
    setSelectedPlan(plan);
  };

  const forceUpgradeModal = forceUpgradeModalOnUser();

  const allowClose = !forceUpgradeModal;

  const getHDClipsNum = (planType: PlanType, isAnnual: boolean) => {
    if (planType === PlanType.PRO) {
      if (isAnnual) {
        return 3600 / 2.5;
      } else {
        return 300 / 2.5;
      }
    } else if (planType === PlanType.PRO_PLUS) {
      if (isAnnual) {
        return 6000 / 2.5;
      } else {
        return 500 / 2.5;
      }
    }
  };

  if (showProModalModal) {
    return (
      <BaseModal
        isModalOpen={showProModalModal}
        handelModalOpen={handelToggleUpdateToProModal}
        notClosable={forceUpgradeModal}
      >
        <div className="flex max-w-7xl items-center justify-center p-8">
          <div
            className={clsx(
              "relative w-[70rem] rounded-lg dark:bg-gray-700 pt-4",
              forceUpgradeModal ? "h-[630px]" : "h-[620px]"
            )}
          >
            {allowClose ? (
              <button
                type="button"
                className="absolute right-6 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={() => {
                  handelToggleUpdateToProModal(false);
                }}
              >
                <svg
                  aria-hidden="true"
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            ) : (
              <button
                type="button"
                className="absolute group right-6 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 px-3 text-sm hover:bg-blue-600 hover:text-white dark:hover:bg-gray-100 dark:hover:text-white border border-blue-600"
                onClick={() => {
                  handelLogout();
                  handelToggleUpdateToProModal(false);
                }}
              >
                <span className="text-blue-600 group-hover:text-white font-bold">
                  Logout
                </span>
              </button>
            )}

            <section className="bg-white dark:bg-gray-900 px-4">
              <div className="text-center">
                <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  Save time & get more done with PRO plans 🤩
                </h2>
                {forceUpgradeModal && (
                  <div className="-mt-7 mb-7 text-base font-medium text-red-500">
                    Free plans are unavailable in your country. Choose a paid
                    plan to proceed
                  </div>
                )}
                <div className="flex justify-center items-center pl-36">
                  <span className="text-base font-medium text-gray-800 dark:text-white">
                    Monthly
                  </span>
                  <div>
                    <label
                      htmlFor="toggle-annual-monthly"
                      className="flex relative items-center mx-4 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        id="toggle-annual-monthly"
                        className="sr-only"
                        checked={isAnnual}
                        onChange={() => setIsAnnual(!isAnnual)}
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full border-2 border-gray-200 toggle-bg dark:bg-gray-700 dark:border-gray-700"></div>
                    </label>
                  </div>
                  <span className="text-base font-medium text-gray-800 dark:text-gray-400">
                    Yearly{" "}
                    <span className="text-gray-500 font-light">
                      (Save 40% 🎉)
                    </span>
                  </span>
                </div>
              </div>
              <section className="bg-white dark:bg-gray-900 mt-6">
                <div className={"flex h-full justify-center items-start gap-4"}>
                  <div
                    key={PlanType.FREE}
                    className={clsx(
                      "flex flex-col p-6 mx-auto max-w-xl text-center bg-white rounded-lg border shadow xl:max-w-xl dark:border-gray-700 dark:bg-gray-800 xl:p-6 min-w-[352px] border-gray-200 h-[403px]"
                    )}
                    onClick={() => selectPlan(PlanType.FREE)}
                  >
                    <h3 className=" text-2xl font-medium text-gray-900 dark:text-white">
                      Free
                    </h3>
                    <PriceLabel
                      isAnnual={isAnnual}
                      planType={PlanType.FREE}
                    />

                    <PlanActionButton
                      btnType={PlanType.FREE}
                      subscription={currentUserSubscriptionDetails}
                      handleCheckout={() =>
                        handleCheckout(PlanType.FREE, isAnnual)
                      }
                      isLoading={
                        isLoadingCheckout && loadingPlanType === PlanType.FREE
                      }
                      isAnnual={isAnnual}
                      id={`${PlanType.FREE}-${isAnnual ? "yearly" : "monthly"}`}
                      planType={PlanType.FREE}
                    />

                    <div
                      className={clsx(
                        "flex text-lg items-center space-x-3 gap-2 font-semibold justify-center mb-7"
                      )}
                    >
                      75 upload mins
                    </div>
                    <ul
                      role="list"
                      className="space-y-3 text-left text-gray-900 dark:text-gray-400 mt-3 pt-3 border-t"
                    >
                      {getSupportedFeatures(PlanType.FREE, isAnnual).map(
                        (feature) => (
                          <ListItem
                            key={feature}
                            text={feature}
                            isSupported={true}
                          />
                        )
                      )}
                    </ul>
                  </div>
                  <div className="p-6  text-center bg-white rounded-lg border shadow  dark:border-gray-700 dark:bg-gray-800 border-gray-200">
                    <div
                      key={PlanType.PRO}
                      className={clsx(
                        "flex text-center justify-center gap-[50px]"
                      )}
                      onClick={() => selectPlan(PlanType.FREE)}
                    >
                      <div className="w-1/2">
                        <h3 className="text-2xl font-medium text-gray-900 dark:text-white">
                          PRO
                        </h3>
                        <PriceLabel
                          isAnnual={isAnnual}
                          planType={PlanType.PRO}
                        />

                        {/* {plan.type !== PlanType.FREE && ( */}
                        <PlanActionButton
                          btnType={PlanType.PRO}
                          subscription={currentUserSubscriptionDetails}
                          handleCheckout={() =>
                            handleCheckout(PlanType.PRO, isAnnual)
                          }
                          isLoading={
                            isLoadingCheckout &&
                            loadingPlanType === PlanType.PRO
                          }
                          isAnnual={isAnnual}
                          id={`${PlanType.PRO}-${
                            isAnnual ? "yearly" : "monthly"
                          }`}
                          planType={PlanType.PRO}
                        />
                        <div className="flex text-lg items-center space-x-3 gap-2 font-semibold justify-center">
                          {`${isAnnual ? 3600 : 300} upload mins`}
                          <span className="text-gray-500 text-sm font-normal">
                            (~{getHDClipsNum(PlanType.PRO, isAnnual)} HD clips)
                          </span>
                        </div>
                        <p className="text-gray-500 text-sm flex relative top-3 mb-1">
                          All paid plans include
                        </p>
                      </div>
                      <div className="w-1/2">
                        <h3 className=" text-2xl font-medium text-gray-900 dark:text-white">
                          PRO+
                        </h3>
                        <PriceLabel
                          isAnnual={isAnnual}
                          planType={PlanType.PRO_PLUS}
                        />
                        <PlanActionButton
                          btnType={PlanType.PRO_PLUS}
                          subscription={currentUserSubscriptionDetails}
                          handleCheckout={() =>
                            handleCheckout(PlanType.PRO_PLUS, isAnnual)
                          }
                          isLoading={
                            isLoadingCheckout &&
                            loadingPlanType === PlanType.PRO_PLUS
                          }
                          isAnnual={isAnnual}
                          id={`${PlanType.PRO_PLUS}-${
                            isAnnual ? "yearly" : "monthly"
                          }`}
                          planType={PlanType.PRO_PLUS}
                        />
                        <div className="flex text-lg items-center space-x-3 gap-2 font-semibold justify-center">
                          {`${isAnnual ? 6000 : 500} upload mins`}
                          <span className="text-gray-500 text-sm font-normal">
                            (~{getHDClipsNum(PlanType.PRO_PLUS, isAnnual)} HD
                            clips)
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center items-center mt-3 border-t">
                      <ul
                        role="list"
                        className="flex flex-wrap items-baseline space-y-3 text-gray-900 dark:text-gray-400 text-center"
                      >
                        {getSupportedFeatures(PlanType.PRO_PLUS, isAnnual).map(
                          (feature, index) => (
                            <ListItem
                              key={feature}
                              text={feature}
                              isSupported={true}
                              isHalfWidth={index !== 6}
                            />
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <div className="flex justify-center items-center mt-6">
                Need top-up minutes?
                <Tooltip
                  content={
                    currentUserSubscriptionDetails.planType === PlanType.FREE
                      ? "Available on paid plans"
                      : ""
                  }
                  className={
                    currentUserSubscriptionDetails.planType !== PlanType.FREE
                      ? "hidden"
                      : ""
                  }
                >
                  <button
                    className="relative inline-flex items-center justify-center 
                    p-0.5 overflow-hidden text-sm font-medium text-gray-900 
                    rounded-lg group bg-gradient-to-br from-purple-600 
                    to-blue-500 group-hover:from-purple-600 
                    group-hover:to-blue-500 hover:text-white dark:text-white 
                    focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 mx-2"
                    onClick={() => {
                      handelToggleUpdateToProModal(false);
                      dispatch(toggleAddMoreMinModal(true));
                      addMoreMinutesButtonClick(
                        AMPLITUDE_CLICK_EVENTS.UPDATE_TO_PRO_MODAL
                      );
                    }}
                    disabled={
                      currentUserSubscriptionDetails.planType === PlanType.FREE
                    }
                  >
                    <span className="relative px-5 py-1 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                      Click here
                    </span>
                  </button>
                </Tooltip>
              </div>
              <div className="text-center text-gray-500 mt-2">
                All our plans come with money back guarantee.{" "}
              </div>
            </section>
          </div>
        </div>
      </BaseModal>
    );
  }
  return null;
};

export default UpdateToProModal;
