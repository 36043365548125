import { EditorCommandType } from "@/constants/editor-commands";

import { SubArrItem } from "@/interfaces";

import { createCommand, Command } from "@/views/editor/EditorHistoryReducer";
import { INIT_SUBS_CONFIG } from "@/views/editor/constant";

export const deleteSubtitleCommand = (
  metaData: any,
  deleteSubtitle: () => void,
  addSubtitle: (
    subtitleData: {
      subsArr: SubArrItem[];
      subStart: number;
      subEnd: number;
    },
    subsConfig: typeof INIT_SUBS_CONFIG
  ) => void
): Command => {
  const { oldSubsData, oldSubsConfig } = metaData;
  return createCommand({
    executeAction: () => deleteSubtitle(),
    undoAction: () => addSubtitle(oldSubsData, oldSubsConfig),
    commandType: EditorCommandType.DeleteSubtitle,
  });
};

export const changeSubtitleCommand = (
  metaData: any,
  changeSubtitle: (id: any, subs: any) => void
): Command => {
  const { id, newSubs, oldSubs } = metaData;
  return createCommand({
    executeAction: () => changeSubtitle(id, newSubs),
    undoAction: () => changeSubtitle(id, oldSubs),
  });
};

export const addRemoveSubtitleLineCommand = (
  metaData: any,
  changeSubtitle: (subs: any) => void
): Command => {
  const { newSubs, oldSubs } = metaData;
  return createCommand({
    executeAction: () => changeSubtitle(newSubs),
    undoAction: () => changeSubtitle(oldSubs),
  });
};

export const changeSubsStylesCommand = (
  metaData: any,
  applySubsStylesModification: (styles: any) => void
): Command => {
  const { newSubsStyles, oldSubsStyles } = metaData;
  return createCommand({
    executeAction: () => applySubsStylesModification(newSubsStyles),
    undoAction: () => applySubsStylesModification(oldSubsStyles),
  });
};

export const modifySubtitleCommand = (
  metaData: any,
  applySubtitleModification: (data: any) => void
): Command => {
  const { newSubsStyles, oldSubsStyles } = metaData;
  return createCommand({
    executeAction: () => applySubtitleModification(newSubsStyles),
    undoAction: () => applySubtitleModification(oldSubsStyles),
  });
};

export const changeSubtitleEmojiCommand = (
  metaData: any,
  applySubsEmojiChange: (id: string | number, styles: any) => void
): Command => {
  const { id, newSubtitle, oldSubtitle } = metaData;
  return createCommand({
    executeAction: () => applySubsEmojiChange(id, newSubtitle),
    undoAction: () => applySubsEmojiChange(id, oldSubtitle),
    elementId: id,
  });
};
