import { useEffect, useState } from "react";

import { useAppSelector } from "@/store/hooks";

import { VideoLayout } from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";

type EnableCutMagicModalProps = {
  isOpen: boolean;
  onSuccess: (dontShowAgain: boolean) => void;
  onCancel: () => void;
};
const EnableCutMagicModal = ({
  isOpen,
  onSuccess,
  onCancel,
}: EnableCutMagicModalProps) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const selectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );

  useEffect(() => {
    isOpen || setDontShowAgain(isOpen);
  }, [isOpen]);

  return (
    <BaseModal
      isModalOpen={isOpen}
      handelModalOpen={() => {}}
      notClosable
    >
      <div className="sm:w-[30rem]">
        <div className="flex flex-col justify-center bg-white px-11 pt-7 pb-8 text-center">
          <div className="mb-5 text-2xl font-bold text-gray-900">
            Remember ⚠️
          </div>
          <div className="text-base font-normal text-gray-500 mb-4">
            {selectedLayout === VideoLayout.LAYOUT_9_16_1 ? (
              <>
                <span className="text-gray-600">CutMagic™</span> exclusively
                supports portrait layout, and once activated, you cannot alter
                the layout.
              </>
            ) : (
              <>
                Using <span className="text-gray-600">CutMagic™ </span>
                will disable aspect ratio changes to your video. You will be
                switched to full height portrait layout and all changes to your
                current layout will be lost.
              </>
            )}
          </div>
          {selectedLayout === VideoLayout.LAYOUT_9_16_1 && (
            <div className="select-none">
              <input
                id="dont-show-enable-scene-checkbox"
                type="checkbox"
                value=""
                checked={dontShowAgain}
                onChange={() => setDontShowAgain(!dontShowAgain)}
                className="h-4 w-4 cursor-pointer rounded border-gray-300 bg-gray-100 
            text-blue-600 focus:ring-2 focus:ring-blue-500 
            dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
              />
              <label
                htmlFor="dont-show-enable-scene-checkbox"
                className="ml-2 cursor-pointer text-base font-medium text-gray-600 dark:text-gray-300 "
              >
                Don’t show me this again
              </label>
            </div>
          )}

          <div
            className={`flex justify-center gap-5  ${
              selectedLayout === VideoLayout.LAYOUT_9_16_1 ? "mt-9" : "mt-4"
            }`}
          >
            <button
              onClick={onCancel}
              type="button"
              id="cancel-cutmagic-modal-cancel-btn"
              className="inline-flex items-center justify-center 
              rounded-lg border border-gray-500 
              bg-white px-5 py-2.5 text-center text-sm font-medium 
              text-gray-800 hover:bg-gray-50 focus:outline-none  focus:ring-4 focus:ring-gray-200 
              "
            >
              Cancel
            </button>
            <button
              type="button"
              id="enable-cutmagic-modal-continue-btn"
              className={`flex items-center justify-center 
              rounded-lg bg-blue-600 px-5 py-2.5 
              text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 
              focus:ring-blue-300`}
              onClick={() => onSuccess(dontShowAgain)}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default EnableCutMagicModal;
