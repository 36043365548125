import produce from "immer";

import { store } from "@/store";

import {
  EDITOR_INTERACTION_DATA,
  EDITOR_ROUTE_MAP,
} from "@/constants/amplitude";
import { EditorCommandTypeKeys } from "@/constants/editor-commands";

import { trackEditorInteractionEvent } from "@/utils/amplitudeAnalytcs";

// Define a type for the command object
export type Command = {
  execute: () => void;
  undo: () => void;
  elementId?: string | number;
  commandType?: EditorCommandTypeKeys;
  metadata?: Record<string, any>; // Flexible object to store additional info
};

type UpdatedCommandType = {
  command: Command;
  _analytics_type: keyof typeof EDITOR_ROUTE_MAP;
};

// Define the shape of the state
export type HistoryState = {
  undoStack: UpdatedCommandType[];
  redoStack: UpdatedCommandType[];
  lastActionWasUndo: boolean;
  canUndo: boolean;
  canRedo: boolean;
};

export enum HistoryActionTypes {
  ADD_COMMAND = "ADD_COMMAND",
  UNDO = "UNDO",
  REDO = "REDO",
  RESET = "RESET",
}

type AddCommandAction = {
  type: HistoryActionTypes.ADD_COMMAND;
  command: Command;
};

type UndoAction = {
  type: HistoryActionTypes.UNDO;
};

type RedoAction = {
  type: HistoryActionTypes.REDO;
};

type ResetAction = {
  type: HistoryActionTypes.RESET;
};

export type HistoryAction =
  | AddCommandAction
  | UndoAction
  | RedoAction
  | ResetAction;

// Initial state
export const initialState: HistoryState = {
  undoStack: [],
  redoStack: [],
  lastActionWasUndo: false,
  canUndo: false,
  canRedo: false,
};

export const editorHistoryReducer = (
  state: HistoryState,
  action: HistoryAction
): HistoryState =>
  produce(state, (draft) => {
    const currentEditorTab = store.getState().editorState.currentSideMenu;
    switch (action.type) {
      case HistoryActionTypes.ADD_COMMAND:
        // adding _analytics_type to track the current action being performed by the user

        draft.undoStack.push({
          command: action.command,
          _analytics_type: currentEditorTab
            ? // @ts-ignore
              EDITOR_ROUTE_MAP[currentEditorTab]
            : "",
        });

        if (draft.undoStack.length > 20) {
          draft.undoStack.shift();
        }

        draft.redoStack = [];

        draft.lastActionWasUndo = false;
        draft.canUndo = draft.undoStack.length > 0;
        draft.canRedo = draft.redoStack.length > 0;
        break;

      case HistoryActionTypes.UNDO:
        if (draft.undoStack.length > 0) {
          const undoCommand = draft.undoStack.pop();

          if (undoCommand) {
            undoCommand.command.undo();
            trackEditorInteractionEvent(
              EDITOR_INTERACTION_DATA.UNDO,
              undoCommand._analytics_type
            );
            draft.redoStack.unshift(undoCommand);
          }

          if (draft.redoStack.length > 20) {
            draft.redoStack.pop();
          }
          draft.lastActionWasUndo = true;
          draft.canUndo = draft.undoStack.length > 0;
          draft.canRedo = draft.redoStack.length > 0;
        }
        break;

      case HistoryActionTypes.REDO:
        if (draft.redoStack.length > 0) {
          const redoCommand = draft.redoStack.shift();
          if (redoCommand) {
            redoCommand.command.execute();
            trackEditorInteractionEvent(
              EDITOR_INTERACTION_DATA.REDO,
              redoCommand._analytics_type
            );
            draft.undoStack.push(redoCommand);
          }

          if (draft.undoStack.length > 20) {
            draft.undoStack.shift();
          }
          draft.lastActionWasUndo = false;
          draft.canUndo = draft.undoStack.length > 0;
          draft.canRedo = draft.redoStack.length > 0;
        }
        break;

      case HistoryActionTypes.RESET:
        draft.undoStack = [];
        draft.redoStack = [];
        draft.lastActionWasUndo = false;
        draft.canUndo = false;
        draft.canRedo = false;
        break;

      default:
        break;
    }
  });

export const createCommand = ({
  executeAction,
  undoAction,
  elementId,
  commandType,
  metadata,
}: {
  executeAction: () => void;
  undoAction: () => void;
  elementId?: string | number;
  commandType?: EditorCommandTypeKeys;
  metadata?: Record<string, any>;
}): Command => ({
  execute: executeAction,
  undo: undoAction,
  ...(elementId !== undefined && { elementId }),
  ...(commandType !== undefined && { commandType }),
  ...(metadata !== undefined && { metadata }),
});
