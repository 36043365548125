import { createCommand, Command } from "@/views/editor/EditorHistoryReducer";

export const updateCurrentTemplate = (
  metaData: any,
  changeCurrentTemplate: (templateData: any) => void
): Command => {
  const { templateData, oldTemplateData } = metaData;
  return createCommand({
    executeAction: () => changeCurrentTemplate(templateData),
    undoAction: () => changeCurrentTemplate(oldTemplateData),
  });
};
