import { useNavigate } from "react-router-dom";

import InternalError from "@/assets/images/internal-error.svg";

const InternalErrorPage = () => {
  const navigate = useNavigate();
  return (
    <section className="h-screen bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl grid-cols-2 content-center gap-8 py-8 px-4 md:grid lg:py-16 lg:px-6">
        <div className="self-center">
          <h1 className="mb-4 text-2xl font-bold text-primary-600 dark:text-primary-500">
            500 Internal Error
          </h1>
          <p className="mb-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white md:text-4xl lg:mb-10">
            We are already working to solve the problem.
          </p>
          <p
            onClick={() => window.location.reload()}
            className="my-4 inline-flex cursor-pointer rounded-lg border bg-white px-5 py-2.5 text-center text-sm font-medium text-primary-600 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            Try Again
          </p>
          <p
            onClick={() => {
              navigate("/", { replace: true });
              window.location.reload();
            }}
            className="my-4 ml-6 inline-flex cursor-pointer rounded-lg bg-primary-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            Go to home
          </p>
        </div>
        {/* <InternalError /> */}
        <img
          className="mx-auto hidden md:flex ml-10 mt-10"
          src={InternalError}
          alt="500 Server Error"
        />
      </div>
    </section>
  );
};

export default InternalErrorPage;
