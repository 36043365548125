import { EditorCommandType } from "@/constants/editor-commands";

import { createCommand, Command } from "@/views/editor/EditorHistoryReducer";

export const updateScenesCommand = (
  metaData: any,
  handleUpdateScene: (sceneData: any) => void
): Command => {
  const { newSceneData, oldSceneData } = metaData;
  return createCommand({
    executeAction: () => handleUpdateScene(newSceneData),
    undoAction: () => handleUpdateScene(oldSceneData),
    commandType: EditorCommandType.UpdateCutMagicScenes,
  });
};

export const addSceneCommand = (
  metaData: any,
  handleAddNewScene: (sceneTime: number) => void,
  handleRestoreScenes: (scenesData: any) => void
): Command => {
  const { oldScenesData, newSceneTime } = metaData;
  return createCommand({
    executeAction: () => handleAddNewScene(newSceneTime),
    undoAction: () => handleRestoreScenes(oldScenesData),
    commandType: EditorCommandType.AddCutMagicScene,
  });
};

export const deleteSceneCommand = (
  metaData: any,
  handleAddScene: (sceneData: any) => void,
  handleDeleteScene: (sceneId: string) => void
): Command => {
  const { oldSceneData, sceneIdToDelete } = metaData;
  return createCommand({
    executeAction: () => handleDeleteScene(sceneIdToDelete),
    undoAction: () => handleAddScene(oldSceneData),
    commandType: EditorCommandType.DeleteCutMagicScene,
  });
};

export const disableCutmagicCommand = (
  metaData: any,
  handlePersistsScene: (sceneData: any, enable: boolean) => void
): Command => {
  const { newSceneData, oldSceneData, enable } = metaData;
  return createCommand({
    executeAction: () => handlePersistsScene(newSceneData, enable),
    undoAction: () => handlePersistsScene(oldSceneData, !enable),
    commandType: EditorCommandType.DisableCutMagic,
  });
};

export const modifySceneCommand = (
  metaData: any,
  handleModifyScene: (sceneData: any) => void
): Command => {
  const { newSceneData, oldSceneData } = metaData;
  return createCommand({
    executeAction: () => handleModifyScene(newSceneData),
    undoAction: () => handleModifyScene(oldSceneData),
    commandType: EditorCommandType.ModifyCutMagicScene,
  });
};
