import { useEffect, useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { TrashIcon } from "@heroicons/react/20/solid";
import { Button, Tooltip } from "flowbite-react";

import { setSelectedTextId } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { EDITOR_INTERACTION_DATA } from "@/constants/amplitude";

import { formatSeconds, getSecondsFromHMSM } from "@/helpers";

import { trackEditorInteractionEvent } from "@/utils/amplitudeAnalytcs";

import TimeField from "@/components/TimeField";

import { useEditorHistory } from "@/views/editor/EditorHistoryContext";
import { HistoryActionTypes } from "@/views/editor/EditorHistoryReducer";
import {
  changeTextStylesCommand,
  textEndTimeUpdate,
  textStartTimeUpdate,
  textTransformCommand,
  updateTextFullLengthCommand,
} from "@/views/editor/commands/textCommand";
import { TextStyleEditor } from "@/views/editor/components/TextStyleEditor";
import { INIT_TEXT_STYLES, TEXT_TRANSFORM } from "@/views/editor/constant";

const TEXTS = "texts";
const STYLES = "styles";

type TimeEntity = "start" | "end";

const RenderSub = ({
  sub,
  onTextChange,
  onStartUpdate,
  onEndUpdate,
  onDelete,
  isUppercase,
}: any) => {
  const dispatch = useAppDispatch();

  const { updateIsFullVideoLength } = useOutletContext<any>();

  const inputRef = useRef<any>();
  const [cursorPosition, setCursorPosition] = useState(0);
  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const { selectTextObject } = useOutletContext<any>();

  const { editorDispatch } = useEditorHistory();

  const textStartUpdateCommand = (metaData: any) => {
    const command = textStartTimeUpdate(metaData, onStartUpdate);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const textEndUpdateCommand = (metaData: any) => {
    const command = textEndTimeUpdate(metaData, onEndUpdate);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handleTimeChange = (ent: TimeEntity) => (value: string) => {
    const newTimeInSec = getSecondsFromHMSM(value);

    if (typeof newTimeInSec === "undefined") {
      return;
    }
    const newTime = newTimeInSec * 1000 + currentSelectedMicroContent.start;

    const setter = {
      start: textStartUpdateCommand,
      end: textEndUpdateCommand,
    };

    if (
      newTime >= currentSelectedMicroContent.start &&
      newTime <= currentSelectedMicroContent.end
    ) {
      setter[ent]({
        id: sub.id,
        newTime,
        oldTime: sub[ent],
      });
      return newTimeInSec.toString();
    }
  };

  const handelTextLengthToggle = (value: any) => {
    const metaData = {
      textId: sub.id,
      newValue: {
        times: {
          start: value ? currentSelectedMicroContent.start : sub.start,
          end: value ? currentSelectedMicroContent.end : sub.end,
        },
        isFullVideoLength: value,
      },
      oldValue: {
        times: {
          start: sub.start,
          end: sub.end,
        },
        isFullVideoLength: !value,
      },
    };
    const command = updateTextFullLengthCommand(
      metaData,
      updateIsFullVideoLength
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const differenceFromStart =
    (sub.start - currentSelectedMicroContent.start) / 1000;
  const differenceFromEnd =
    (sub.end - currentSelectedMicroContent.start) / 1000;

  useEffect(() => {
    // hack by ChatGPT

    // set the cursor position after rendering
    inputRef.current?.setSelectionRange(cursorPosition, cursorPosition);
  }, [cursorPosition]);

  return (
    <div
      className="flex flex-col rounded-lg bg-white p-2"
      onClick={() => {
        dispatch(setSelectedTextId(sub.id));
        selectTextObject(sub.id);
      }}
    >
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center space-x-1">
          <TimeField
            time={formatSeconds(
              differenceFromStart > 0 ? differenceFromStart : 0
            )}
            handleTimeChange={handleTimeChange("start")}
            disabled={sub.isFullVideoLength}
          />
          <p>-</p>

          <TimeField
            time={formatSeconds(differenceFromEnd > 0 ? differenceFromEnd : 0)}
            disabled={sub.isFullVideoLength}
            handleTimeChange={handleTimeChange("end")}
          />
        </div>
        {sub.id.includes("social_") ? (
          <Tooltip
            content="Please delete the social media Logo to delete this text"
            className="w-60 !z-50"
            animation="duration-500"
            placement="bottom"
          >
            <TrashIcon className="h-4 w-4 cursor-pointer fill-red-500 opacity-50 " />
          </Tooltip>
        ) : (
          <TrashIcon
            className="h-4 w-4 cursor-pointer fill-red-500"
            onClick={onDelete}
          />
        )}
      </div>

      <div className="mb-4 flex items-center">
        <input
          checked={sub.isFullVideoLength}
          id={"checked-checkbox-" + sub.id}
          type="checkbox"
          value=""
          onChange={(e) => handelTextLengthToggle(e.target.checked)}
          className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
        />
        <label
          htmlFor={"checked-checkbox-" + sub.id}
          className="ml-2 text-gray-400 dark:text-gray-300 text-sm"
        >
          Apply to full clip
        </label>
      </div>

      <div className="flex items-center justify-between">
        <input
          ref={inputRef}
          className={`block w-full rounded border border-gray-300 bg-gray-50 p-3 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 ${
            isUppercase && "uppercase"
          }`}
          onChange={(e) => {
            onTextChange(e.target.value);
            setCursorPosition(inputRef.current?.selectionStart || 0);
          }}
          value={sub.content}
        />
      </div>
    </div>
  );
};
const TextEditor = () => {
  const [currentTab, setCurrentTab] = useState<string>(TEXTS);

  const { editorDispatch } = useEditorHistory();

  const selectedTextId = useAppSelector(
    (state) => state.editorState.selectedTextId
  );

  const {
    addTextObject,
    onTextChange,
    onTextDelete,
    onStartUpdate,
    onEndUpdate,
    onTextStyleChange,
    textsObj,
    onTextBoxTransform,
  } = useOutletContext<any>();

  // hack to switch to text tab if no text is selected
  useEffect(() => {
    if (selectedTextId && !textsObj[selectedTextId]?.style) {
      setCurrentTab(TEXTS);
    }

    if (!selectedTextId) setCurrentTab(TEXTS);
  }, [selectedTextId]);

  const handleTextStyleChange = (metaData: any, addToCommandStack: boolean) => {
    const command = changeTextStylesCommand(metaData, onTextStyleChange);

    command.execute();

    if (addToCommandStack) {
      editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
    }
  };

  const handleTextTransform = (metaData: any) => {
    const command = textTransformCommand(metaData, onTextBoxTransform);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const currentTextStyles = useMemo(() => {
    return (
      (selectedTextId && textsObj[selectedTextId]?.style) || INIT_TEXT_STYLES
    );
  }, [selectedTextId, textsObj]);

  return (
    <div className=" relative h-full w-full">
      <div className="w-full bg-white p-2 pt-6">
        <p className="text-lg text-gray-800">Text Editor</p>
      </div>

      <div className="sticky top-0 z-50 overflow-x-auto  overflow-y-hidden border-b border-gray-200 bg-white text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400">
        <ul className="-mb-px flex overflow-x-auto transition-colors">
          <li
            className="mr-2 w-36 cursor-pointer"
            onClick={() => setCurrentTab(TEXTS)}
          >
            <p
              className={`inline-block p-4 ${
                currentTab === TEXTS
                  ? "border-blue-600 text-blue-600 dark:text-blue-500"
                  : "border-transparent hover:border-gray-300 hover:text-gray-600"
              } whitespace-nowrap rounded-t-lg border-b-4`}
            >
              Texts
            </p>
          </li>
          <li
            className={`mr-2 w-36 ${
              selectedTextId && textsObj[selectedTextId]?.style
                ? "cursor-pointer"
                : "opacity-50 cursor-not-allowed"
            }`}
            onClick={() => {
              if (selectedTextId && textsObj[selectedTextId]?.style) {
                setCurrentTab(STYLES);
                trackEditorInteractionEvent(
                  EDITOR_INTERACTION_DATA.TEXT_CLICKED.EVENT_KEY,
                  EDITOR_INTERACTION_DATA.TEXT_CLICKED.STYLES_ACCESSED
                );
              }
            }}
          >
            <p
              className={`inline-block p-4 ${
                currentTab === STYLES
                  ? "border-blue-600 text-blue-600 dark:text-blue-500"
                  : `${
                      selectedTextId &&
                      textsObj[selectedTextId]?.style &&
                      "hover:border-gray-300 hover:text-gray-600"
                    } border-transparent`
              } whitespace-nowrap rounded-t-lg border-b-4`}
            >
              Styles
            </p>
          </li>
        </ul>
      </div>

      <div className="p-2">
        {currentTab === TEXTS ? (
          <div className="flex flex-col gap-6">
            {Object.values(textsObj).map((sub: any) => (
              <RenderSub
                key={sub.id}
                sub={sub}
                onTextChange={(text: any) => onTextChange(text, sub.id)}
                onStartUpdate={onStartUpdate}
                onEndUpdate={onEndUpdate}
                onDelete={() => onTextDelete(sub.id)}
                isUppercase={
                  sub?.style?.textTransform === TEXT_TRANSFORM.UPPERCASE
                }
              />
            ))}
            <div className="flex items-center justify-end ">
              <Button
                className="w-full py-[5px] bg-blue-600 rounded-md font-normal hover:!bg-blue-600"
                onClick={() => {
                  addTextObject({ isNewText: true });
                  trackEditorInteractionEvent(
                    EDITOR_INTERACTION_DATA.TEXT_CLICKED.EVENT_KEY,
                    EDITOR_INTERACTION_DATA.TEXT_CLICKED.ADD_NEW_TEXT_BUTTON
                  );
                }}
                id="add-new-text"
                size="md"
              >
                Add New Text
              </Button>
            </div>
          </div>
        ) : (
          <TextStyleEditor
            styles={currentTextStyles}
            onChange={(styles: any, addToCommandStack = true) => {
              handleTextStyleChange(
                {
                  id: selectedTextId,
                  newTextStyles: styles,
                  oldTextStyles: currentTextStyles,
                },
                addToCommandStack
              );
            }}
            onTextBoxTransform={(transformStyle: any, defaultTextType: any) => {
              handleTextTransform({
                id: selectedTextId,
                newTextTransform: transformStyle,
                oldTextTextTransform: defaultTextType,
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TextEditor;
