import { useState } from "react";
import { useDropzone } from "react-dropzone";

import { useQueryClient } from "@tanstack/react-query";
import { nanoid } from "nanoid";

import {
  toggleUploadVideoModal,
  updateCurrentOnBoardingId,
  updateOnBoardingDataById,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";
import usePostYTLink from "@/api/useUploadYoutubeVideo";
import useUserConsumedProcessingTime from "@/api/useUserConsumedProcessingTime";

import {
  BUFFER_TIME_FREE_USER_IN_MINS,
  BUFFER_TIME_PAID_USER_IN_MINS,
  FREE_USER_UPLOAD_FILE_SIZE_LIMIT,
  PRO_USER_UPLOAD_FILE_SIZE_LIMIT,
} from "@/constants";

import { calculateRemainingProcessingTime, isValidYoutubeUrl } from "@/helpers";

import { notificationType } from "@/utils/constants";
import { MultipartUploader } from "@/utils/multipartUploader";
import { showNotification } from "@/utils/showNotification";

import { PlanType, SimpleAssetType } from "@/enums";

import BaseModal from "@/components/BaseModal";
import Spinner from "@/components/Loader/Spinner";

import YoutubeIcon from "@/assets/icons/youtube.svg";

const UploadVideoModal = () => {
  const onBoardingId = nanoid();

  const queryClient = useQueryClient();

  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [error, setError] = useState("");
  const [isYoutubeMetaDataLoading, setIsYoutubeMetaDataLoading] =
    useState(false);

  const { data: usageData } = useUserConsumedProcessingTime();

  const dispatch = useAppDispatch();

  const currentUserSubscriptionDetails = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const { planType } = currentUserSubscriptionDetails;
  const showUploadVideoModal = useAppSelector(
    (state) => state.homeState.uploadVideoModalVisible
  );

  const addOnBoardingData = (processingVideoData: any) => {
    dispatch(
      updateOnBoardingDataById({
        id: onBoardingId,
        data: {
          key: onBoardingId,
          selectedLayouts: [],
          baseTemplates: {},
          progressPercentage: 0,
          processingVideoData,
          createdAt: new Date().toISOString(),
        },
      })
    );
    dispatch(updateCurrentOnBoardingId(onBoardingId));
  };

  const handleUpdateOnBoardingData = (updatedData: any, Id?: any) => {
    dispatch(
      updateOnBoardingDataById({
        id: Id || onBoardingId,
        data: { ...updatedData },
      })
    );
  };

  const youtubeMutateErrorCB = (error: any, Id?: any) => {
    handleUpdateOnBoardingData(
      {
        processingVideoData: null,
      },
      Id
    );

    showNotification(
      error.response.data.detail === "Could not download youtube video"
        ? "Sorry, this YouTube video seems to have some problems. Please try and upload the video instead."
        : error.response.data.detail || "Something went wrong!",
      notificationType.FAIL
    );
  };

  const youtubeMutateSuccessCB = (data: any, Id?: any) => {
    handleUpdateOnBoardingData(
      {
        projectId: data.project_id,
      },
      Id
    );

    setYoutubeUrl("");
  };

  const { mutate } = usePostYTLink(
    youtubeMutateSuccessCB,
    youtubeMutateErrorCB
  );

  const checkIfUserAllowedToProceed = (
    currentVideoDuration: any,
    successCB: any,
    fileSize?: number
  ) => {
    const fileSizeInBytes =
      planType === PlanType.FREE
        ? FREE_USER_UPLOAD_FILE_SIZE_LIMIT
        : PRO_USER_UPLOAD_FILE_SIZE_LIMIT;

    if (fileSize && fileSize > fileSizeInBytes) {
      if (planType !== PlanType.FREE) {
        setError(
          "Uhh! This video is too large. Currently, we only support videos up to 15GB. Please try again with a smaller video."
        );
      } else {
        setError(
          "Uhh! This video is too large. Please upload a video less than 5 GB or update to Pro to upload larger videos."
        );
      }
    } else if (
      currentVideoDuration <=
      calculateRemainingProcessingTime(
        usageData,
        planType === PlanType.FREE
          ? BUFFER_TIME_FREE_USER_IN_MINS
          : BUFFER_TIME_PAID_USER_IN_MINS
      )
    ) {
      successCB && successCB();
    } else {
      setError(
        "The video you're trying to upload exceeds the processing minutes remaining on your account."
      );
    }
  };

  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles?.[0];

    if (file) {
      setError("");
      const refetchProjectCB = () => {
        queryClient.invalidateQueries({ queryKey: ["all-projects"] });
      };

      const videoUploaderOptions = {
        asset_name: file.name,
        file,
        onBoardingId,
        refetchProjectCB,
      };

      let percentage: any = undefined;

      const uploader = new MultipartUploader(videoUploaderOptions);
      const newUploaderDetails = {
        id: onBoardingId,
        uploader,
      };

      uploader
        .onProgress(({ percentage: newPercentage }: any) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;

            newPercentage >= 0 &&
              handleUpdateOnBoardingData({ progressPercentage: newPercentage });
          }
        })
        .onError((error: any) => {
          console.log(error);

          handleUpdateOnBoardingData({
            processingVideoData: null,
            progressPercentage: 0,
          });
        });

      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      const video = document.createElement(SimpleAssetType.VIDEO);
      video.src = file.preview;
      video.preload = "metadata";
      video.muted = true;
      video.autoplay = false;
      video.playsInline = true;

      video.addEventListener("loadedmetadata", () => {
        checkIfUserAllowedToProceed(
          video.duration,
          () => {
            addOnBoardingData(newUploaderDetails);
            uploader.start();
            dispatch(toggleUploadVideoModal(false));
            return;
          },
          file?.size
        );
      });
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "video/*": [".mp4", ".mov", ".webm"],
    },
    maxFiles: 1,
  });

  const getVideoDetailsFromYoutube = async (youtubeUrl: any) => {
    setIsYoutubeMetaDataLoading(true);
    let requestBody;
    try {
      requestBody = {
        yt_url: youtubeUrl,
      };

      const resData = await api.post(
        ApiEndpoints.YOUTUBE_DATA_API,
        requestBody
      );
      setIsYoutubeMetaDataLoading(false);
      return resData.data;
    } catch (error) {
      console.error(
        "getVideoDetailsFromYoutube Failed to send data.",
        requestBody
      );
      setIsYoutubeMetaDataLoading(false);
      throw error;
    }
  };

  const handleUploadYouTubeVideo = async () => {
    setError("");
    const youtubeVideoId = isValidYoutubeUrl(youtubeUrl);

    if (youtubeVideoId) {
      try {
        const ytVideoDetails: any = await getVideoDetailsFromYoutube(
          youtubeUrl
        );

        const ytOption = {
          id: onBoardingId,
          isYoutubeLocal: true,
          video_id: youtubeVideoId,
          title: ytVideoDetails.title,
          duration: ytVideoDetails.duration,
        };

        const videoDuration = ytVideoDetails?.duration / 1000 || 0;

        if (ytVideoDetails.available) {
          checkIfUserAllowedToProceed(videoDuration, () => {
            addOnBoardingData(ytOption);
            mutate({ ytLink: youtubeUrl, onBoardingId });

            dispatch(toggleUploadVideoModal(false));
            return;
          });
        } else {
          showNotification(
            "Sorry, this YouTube video seems to have some problems. Please try and upload the video instead.",
            notificationType.WARN,
            false
          );
          handleUpdateOnBoardingData({
            processingVideoData: null,
          });
        }
      } catch {
        showNotification(
          "Please try again with another video!",
          notificationType.FAIL,
          false
        );
        handleUpdateOnBoardingData({
          processingVideoData: null,
        });
      }
    } else {
      showNotification("Invalid youtube URL", notificationType.WARN, false);
    }
  };

  const handelToggleVideoUploadModal = (state: boolean) => {
    dispatch(toggleUploadVideoModal(state));
  };

  return (
    <BaseModal
      isModalOpen={showUploadVideoModal}
      notClosable={true}
    >
      <div className="relative flex h-[90vh] max-w-lg items-center  justify-center sm:w-[95vw] sm:max-w-[90rem]">
        <button
          type="button"
          className="absolute top-4 right-2.5 ml-auto inline-flex items-center rounded-lg bg-blue-100 p-1.5 text-sm text-gray-400 hover:bg-blue-200 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white"
          onClick={() => handelToggleVideoUploadModal(false)}
        >
          <svg
            aria-hidden="true"
            className="h-5 w-5"
            fill="#1C64F2"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <div className={`relative rounded-lg  dark:bg-gray-700`}>
          <div className="py-6 px-4 lg:px-6">
            <section className="bg-white ">
              <h2 className="mb-2 text-center  text-xl font-medium tracking-tight text-gray-900 dark:text-white sm:text-3xl">
                Upload your video
              </h2>

              <div className="mx-auto w-full sm:w-[28rem]">
                <p className=" text-center text-sm font-light text-gray-400 sm:text-lg">
                  Youtube import works best with files at 720p or more
                </p>

                <div className="relative mt-8">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <img
                      src={YoutubeIcon}
                      alt="Youtube Link"
                    />
                  </div>
                  <input
                    type="text"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-sm text-gray-900 placeholder:font-light placeholder:text-gray-500 focus:border-blue-500 focus:ring-blue-500"
                    value={youtubeUrl}
                    onChange={(e) => setYoutubeUrl(e.target.value)}
                    placeholder="Enter YouTube link (Beta)"
                  />
                </div>
                <div className="relative flex w-full items-center py-5">
                  <div className="flex-grow border-t border-gray-200"></div>
                  <span className="mx-2 flex-shrink text-xs font-light text-gray-500">
                    OR
                  </span>
                  <div className="flex-grow border-t border-gray-200"></div>
                </div>
                <div
                  className="mb-2 flex w-full items-center justify-center"
                  {...getRootProps()}
                  onClick={(e) => e.stopPropagation()}
                >
                  <label
                    htmlFor="dropzone-file"
                    className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="mb-3 h-10 w-10 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        Click to upload or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        Max. File Size: 5GB(Free), 15GB(Pro)
                      </p>

                      <button
                        type="button"
                        className=" mt-4 rounded-lg border border-blue-600 bg-white px-5 py-2 text-sm font-medium text-blue-600  hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-blue-300"
                        onClick={open}
                      >
                        Browse File
                      </button>
                    </div>
                    <input
                      {...getInputProps()}
                      id="dropzone-file"
                    />
                  </label>
                </div>

                <span className="text-xs text-red-500">{error}</span>

                <button
                  type="button"
                  onClick={() => {
                    handleUploadYouTubeVideo();
                  }}
                  disabled={isYoutubeMetaDataLoading || !youtubeUrl}
                  className={`mt-4 flex w-full items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 sm:py-3.5 ${
                    isYoutubeMetaDataLoading ? "cursor-wait opacity-75" : ""
                  } ${!youtubeUrl ? "cursor-not-allowed opacity-75" : ""}`}
                  id="generate-clip-btn"
                >
                  <div className="flex items-center justify-center">
                    {isYoutubeMetaDataLoading && (
                      <Spinner className="h-5 w-5" />
                    )}
                    Generate Clip
                  </div>
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default UploadVideoModal;
