import { useEffect, useState } from "react";

import { getAuth, updateProfile } from "firebase/auth";
import { Button, TextInput } from "flowbite-react";

import { store } from "@/store";
import { setCurrentUser } from "@/store/authSlice";
import { useAppSelector } from "@/store/hooks";

import { updateUserDisplayName } from "@/api/requests";

import { ANALYTICS_CONSTANTS } from "@/constants/amplitude";

import { trackProfileIconClickEvent } from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import BaseModal from "@/components/BaseModal/BaseModal";
import Spinner from "@/components/Loader/Spinner";

const UpdateNameModal = ({
  displayNameChangeModal,
  setDisplayNameChangeModal,
}: {
  displayNameChangeModal: boolean;
  setDisplayNameChangeModal: (value: boolean) => void;
}) => {
  const currentUser = useAppSelector((state) => state.authState.currentUser);

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState({
    name: currentUser?.displayName || "",
    error: false,
    errorMessage: "",
  });

  useEffect(() => {
    setName({ name: currentUser.displayName, error: false, errorMessage: "" });
  }, [displayNameChangeModal]);

  const handleFormSubmit = async (e: any) => {
    const auth = getAuth();
    e.preventDefault();
    if (!name.name.trim()) {
      setName({ ...name, error: true });
    } else {
      setIsLoading(true);

      try {
        if (auth.currentUser) {
          await updateProfile(auth.currentUser, {
            displayName: name.name.trim(),
          });
          setDisplayNameChangeModal(false);
          showNotification(
            "Name changed successfully",
            notificationType.SUCCESS
          );
          trackProfileIconClickEvent(
            ANALYTICS_CONSTANTS.PROFIL_ICON_CLICKED.NAME_UPDATED
          );
        }
      } catch (error: any) {
        if (
          error?.message !==
          "Cannot assign to read only property 'displayName' of object '#<Object>'"
        ) {
          showNotification(
            "Failed to update name, please try again",
            notificationType.FAIL
          );
        } else {
          showNotification(
            "Name changed successfully",
            notificationType.SUCCESS
          );
          setDisplayNameChangeModal(false);
        }
      }
      setIsLoading(false);

      store.dispatch(
        setCurrentUser({
          ...currentUser,
          displayName: auth.currentUser?.displayName,
        })
      );

      auth?.currentUser &&
        updateUserDisplayName(
          auth.currentUser.uid,
          auth.currentUser.displayName
        );
    }
  };

  const buttonDisable = () => {
    if (currentUser?.displayName === name.name) return true;
    if (!name?.name?.length) return true;
    return false;
  };

  const nameChangeValidate = (e: any) => {
    const name = e.target.value;
    if (name.trim()) {
      setName({ ...name, name, error: false });
    } else {
      setName({ ...name, error: true });
    }
  };
  return (
    <BaseModal
      isModalOpen={displayNameChangeModal}
      handelModalOpen={() => {}}
    >
      <form
        className="w-[25rem] bg-white p-6 pb-10 pt-8"
        onSubmit={handleFormSubmit}
      >
        <p className="mb-6 text-center text-2xl font-bold text-gray-900">
          Update your name
        </p>
        <div className="mt-4">
          <div className="w-full relative">
            <TextInput
              type="text"
              name="fullName"
              id="edit-name"
              placeholder="Enter name"
              value={name.name}
              onChange={nameChangeValidate}
              className=" text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pt-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              color={`${name.error && "failure"}`}
              helperText={
                <span style={{ marginTop: "-10px" }}>
                  {name.error && <>*Required</>}
                </span>
              }
            />
          </div>
        </div>
        <div className="flex flex-row-reverse gap-10 mt-6">
          <Button
            disabled={buttonDisable()}
            type={isLoading ? "button" : "submit"}
            id="name-change-submit-btn"
            className={`w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
              isLoading ? "cursor-wait opacity-70" : ""
            }`}
          >
            {isLoading ? (
              <Spinner className="h-4 w-4" />
            ) : (
              <span>Continue</span>
            )}
          </Button>
          <Button
            color="light"
            id="name-change-cancel-btn"
            className="w-full rounded-lg border border-blue-500 bg-white text-center text-sm font-medium text-blue-500 hover:bg-blue-50  focus:outline-none focus:ring-4 focus:ring-blue-300"
            onClick={() => {
              setDisplayNameChangeModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </BaseModal>
  );
};

export default UpdateNameModal;
