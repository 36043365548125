import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ProgressBarPosition, Scene } from "@/interfaces";

import { AssetTags, ExportQuality, VideoLayout } from "@/enums";

import { DEFAULT_OUTRO_LENGTH_IN_SEC } from "../views/editor/constant";

const DEFAULT_BACKGROUND_COLOR = "#008000";
const INIT_SUBS_LANG = "en-US";

const PROGRESS_BAR = "Progress Bar";
const TEMPLATE_ROUTE = "templates";

type AssetUrlType = {
  url: string;
  uid: string;
  metaData?: any;
  assetType?: string;
  assetTag?: AssetTags;
};

export type SubsType = {
  id: string;
  start: number;
  end: number;
  text: string;
  emoji?: any;
};

type EditorState = {
  selectedLayout: VideoLayout;
  progressBarColorAsHex: string;
  progressBarHeight: number;
  progressBarPosition: ProgressBarPosition;
  currentTemplate: string | null;
  currentVideoFilterTab: string;
  assetUrls: AssetUrlType[];
  socialMediaHandels: [];
  selectedTemplate: string | null;
  backgroundColorAsHex: string;
  subsLang: string;
  subtitles: {
    subsArr: SubsType[];
    subStart: number;
    subEnd: number;
  };
  selectedTextId: string | null;
  elementsActiveTab: string;
  currentSideMenu: string | null;
  isRenderingVideo: boolean;
  isUserChangedLayout: boolean;
  outroLengthInSecond: number;
  isBackgroundImageLoaded: boolean;
  showDownloadPreferenceModal: boolean;
  exportQuality: ExportQuality;
  showExportingModal: boolean;
  autoAddEmojisToSubtitles: boolean;
  enableSceneChange: boolean;
  currentRenderId: string | null;
  showSaveTemplateModal: boolean;
  allSceneChanges: Scene[];
  videoFrameLengthInSeconds: number;
  caption: {
    text: string;
    transcript: string;
    social: any;
  };
  showSaveDraftModal: boolean;
  editorCurrentVideoTime: number;
  currentSubIndex: any;
  isEditorVideoPaused: boolean;
  timelineZoomFlag: boolean;
  isUndoRedoEnabledNewUser: boolean;
};

const initialState: EditorState = {
  selectedLayout: VideoLayout.LAYOUT_9_16_1,
  progressBarColorAsHex: "#EB144C",
  progressBarHeight: 0,
  progressBarPosition: {
    top: 0,
    left: 0,
  },
  currentTemplate: null,
  currentVideoFilterTab: "all",
  assetUrls: [],
  socialMediaHandels: [],
  selectedTemplate: null,
  backgroundColorAsHex: DEFAULT_BACKGROUND_COLOR,
  subsLang: INIT_SUBS_LANG,
  subtitles: {
    subsArr: [],
    subStart: 0,
    subEnd: 0,
  },
  selectedTextId: null,
  elementsActiveTab: PROGRESS_BAR,
  currentSideMenu: TEMPLATE_ROUTE,
  isRenderingVideo: false,
  isUserChangedLayout: false,
  outroLengthInSecond: DEFAULT_OUTRO_LENGTH_IN_SEC,
  isBackgroundImageLoaded: false,
  showDownloadPreferenceModal: false,
  exportQuality: ExportQuality.HD,
  showExportingModal: false,
  autoAddEmojisToSubtitles: false,
  currentRenderId: null,
  showSaveTemplateModal: false,
  enableSceneChange: false,
  allSceneChanges: [],
  videoFrameLengthInSeconds: 0.04, // 25 fps
  caption: {
    text: "",
    transcript: "",
    social: {},
  },
  showSaveDraftModal: false,
  editorCurrentVideoTime: 0,
  currentSubIndex: null,
  isEditorVideoPaused: false,
  timelineZoomFlag: false,
  isUndoRedoEnabledNewUser: false,
};

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setProgressBarPosition(state, action: PayloadAction<ProgressBarPosition>) {
      state.progressBarPosition = action.payload;
    },
    setProgressBarHeight(state, action: PayloadAction<number>) {
      state.progressBarHeight = action.payload;
    },
    setProgressBarColor(state, action: PayloadAction<string>) {
      state.progressBarColorAsHex = action.payload;
    },
    setAssetsUrl(state, action: PayloadAction<any>) {
      state.assetUrls = action.payload;
    },
    changeSelectedLayout(state, action: PayloadAction<VideoLayout>) {
      state.selectedLayout = action.payload;
    },
    setBackgroundColor(state, action: PayloadAction<string>) {
      state.backgroundColorAsHex = action.payload;
    },
    setSubsLang(state, action: PayloadAction<string>) {
      state.subsLang = action.payload;
    },
    setSubtitles(state, action: PayloadAction<any>) {
      state.subtitles = action.payload;
    },
    setSelectedTextId(state, action: PayloadAction<string | null>) {
      state.selectedTextId = action.payload;
    },
    setElementsActiveTab(state, action: PayloadAction<string>) {
      state.elementsActiveTab = action.payload;
    },
    setCurrentSideMenu(state, action: PayloadAction<string>) {
      state.currentSideMenu = action.payload;
    },
    setSocialMediaHandels(state, action: PayloadAction<any>) {
      state.socialMediaHandels = action.payload;
    },
    updateIsRenderingVideo(state, action: PayloadAction<boolean>) {
      state.isRenderingVideo = action.payload;
    },
    updateIsUserChangeLayout(state, action: PayloadAction<boolean>) {
      state.isUserChangedLayout = action.payload;
    },
    updateOutroLengthInSecond(state, action: PayloadAction<number>) {
      state.outroLengthInSecond = action.payload;
    },
    updateIsBackgroundImageLoaded(state, action: PayloadAction<boolean>) {
      state.isBackgroundImageLoaded = action.payload;
    },
    updateExportQuality(state, action: PayloadAction<ExportQuality>) {
      state.exportQuality = action.payload;
    },
    toggleShowDownloadPreferenceModal(state, action: PayloadAction<boolean>) {
      state.showDownloadPreferenceModal = action.payload;
    },
    toggleShowExportingModal(state, action: PayloadAction<boolean>) {
      state.showExportingModal = action.payload;
    },
    updateAutoAddEmojisToSubtitles(state, action: PayloadAction<boolean>) {
      state.autoAddEmojisToSubtitles = action.payload;
    },
    updateCurrentRenderId(state, action: PayloadAction<string | null>) {
      state.currentRenderId = action.payload;
    },
    toggleShowSaveTemplateModal(state, action: PayloadAction<boolean>) {
      state.showSaveTemplateModal = action.payload;
    },
    toggleSceneChange(state, action: PayloadAction<boolean>) {
      state.enableSceneChange = action.payload;
    },
    updateAllSceneChanges(state, action: PayloadAction<any>) {
      state.allSceneChanges = action.payload;
    },
    updateVideoFrameLengthInMillis(state, action: PayloadAction<any>) {
      state.videoFrameLengthInSeconds = action.payload;
    },

    updateCaption(state, action: PayloadAction<any>) {
      state.caption = action.payload;
    },
    toggleSaveDraft(state, action: PayloadAction<boolean>) {
      state.showSaveDraftModal = action.payload;
    },
    updateCurrentVideoTime(state, action: PayloadAction<number>) {
      state.editorCurrentVideoTime = action.payload;
    },
    updateCurrentSubIndex(state, action: PayloadAction<any>) {
      state.currentSubIndex = action.payload;
    },
    togglePlayPause(state, action: PayloadAction<boolean>) {
      state.isEditorVideoPaused = action.payload;
    },
    setTimelineZoomFlag(state, action: PayloadAction<boolean>) {
      state.timelineZoomFlag = action.payload;
    },
    setUndoRedoForNewSignups(state, action: PayloadAction<boolean>) {
      state.isUndoRedoEnabledNewUser = action.payload;
    },
    resetEditorState: (state) => {
      state.subtitles = initialState.subtitles;
      state.autoAddEmojisToSubtitles = initialState.autoAddEmojisToSubtitles;
      state.outroLengthInSecond = initialState.outroLengthInSecond;
      state.isBackgroundImageLoaded = initialState.isBackgroundImageLoaded;
      state.progressBarColorAsHex = initialState.progressBarColorAsHex;
      state.progressBarHeight = initialState.progressBarHeight;
      state.elementsActiveTab = initialState.elementsActiveTab;
      state.selectedTextId = initialState.selectedTextId;
      state.currentRenderId = initialState.currentRenderId;
      state.enableSceneChange = initialState.enableSceneChange;
      state.allSceneChanges = initialState.allSceneChanges;
      state.videoFrameLengthInSeconds = initialState.videoFrameLengthInSeconds;
      state.caption = initialState.caption;
      state.showSaveDraftModal = initialState.showSaveDraftModal;
      state.isEditorVideoPaused = initialState.isEditorVideoPaused;
    },
  },
});

export const {
  setProgressBarPosition,
  setProgressBarHeight,
  setProgressBarColor,
  changeSelectedLayout,
  setBackgroundColor,
  setSubsLang,
  setSubtitles,
  setSelectedTextId,
  setElementsActiveTab,
  setCurrentSideMenu,
  setAssetsUrl,
  setSocialMediaHandels,
  updateIsRenderingVideo,
  resetEditorState,
  updateIsUserChangeLayout,
  updateOutroLengthInSecond,
  updateIsBackgroundImageLoaded,
  updateExportQuality,
  toggleShowDownloadPreferenceModal,
  toggleShowExportingModal,
  updateAutoAddEmojisToSubtitles,
  updateCurrentRenderId,
  toggleShowSaveTemplateModal,
  toggleSceneChange,
  updateAllSceneChanges,
  updateVideoFrameLengthInMillis,
  updateCaption,
  toggleSaveDraft,
  updateCurrentVideoTime,
  updateCurrentSubIndex,
  togglePlayPause,
  setTimelineZoomFlag,
  setUndoRedoForNewSignups,
} = editorSlice.actions;
export default editorSlice;
