import { memo } from "react";

import { DocumentTextIcon, HashtagIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "flowbite-react";

import { updateShowPreviewClipsSideModal } from "@/store/homeSlice";
import { useAppDispatch } from "@/store/hooks";

import { REVIEW_CLIPS_INTERACTION } from "@/constants/amplitude";

import { trackReviewClipsInteractionEvents } from "@/utils/amplitudeAnalytcs";

import TemplatesIcon from "@/assets/icons/templates-icon.svg";
import VideoTimestampIcon from "@/assets/icons/video-timestamps-icon.svg";

const TRANSCRIPT = "Transcript";
const TEMPLATES = "Templates";
const AI_CAPTIONS = "AI_Captions";
const INSTANT_CHAPTERS = "Instant_Chapters";

export const TemplatePreviewSideTabs = memo(
  ({
    setActivePreviewTab,
  }: {
    setActivePreviewTab: (value: string) => void;
  }) => {
    const dispatch = useAppDispatch();
    const displaySideMenu = (value: string) => {
      setActivePreviewTab(value);
      dispatch(updateShowPreviewClipsSideModal(true));
    };
    return (
      <div className="absolute top-1/2 -translate-y-1/2 right-5 z-50">
        <div className="">
          <div className="flex flex-col space-y-5 items-end">
            <div
              className=" bg-[#F3F4F6] flex justify-center items-center w-12 rounded-full h-12 p-3 text-black ml-5 cursor-pointer"
              onClick={() => displaySideMenu(TEMPLATES)}
            >
              <Tooltip
                content="Change Template"
                arrow={false}
                placement="left"
                className="mr-32"
                animation={false}
              >
                <img src={TemplatesIcon} />
              </Tooltip>
            </div>
            <div
              className=" bg-[#F3F4F6] flex justify-center items-center w-12 rounded-full h-12 p-3 cursor-pointer"
              onClick={() => {
                displaySideMenu(TRANSCRIPT);
                trackReviewClipsInteractionEvents(
                  REVIEW_CLIPS_INTERACTION.TRANSCRIPT_CLICK
                );
              }}
            >
              <Tooltip
                content="Transcript"
                arrow={false}
                placement="left"
                animation={false}
              >
                <DocumentTextIcon
                  className=""
                  width={22}
                  height={22}
                />
              </Tooltip>
            </div>
            <div
              className=" bg-[#F3F4F6] flex justify-center items-center w-12 rounded-full h-12 p-3 cursor-pointer"
              onClick={() => {
                displaySideMenu(AI_CAPTIONS);
                trackReviewClipsInteractionEvents(
                  REVIEW_CLIPS_INTERACTION.AI_CAPTION_CLICK
                );
              }}
            >
              <Tooltip
                content="AI Description"
                arrow={false}
                placement="left"
                animation={false}
              >
                <HashtagIcon
                  width={22}
                  height={22}
                />
              </Tooltip>
            </div>
            <div
              className=" bg-[#F3F4F6] flex justify-center items-center w-12 rounded-full h-12 p-3 cursor-pointer"
              onClick={() => {
                displaySideMenu(INSTANT_CHAPTERS);
                trackReviewClipsInteractionEvents(
                  REVIEW_CLIPS_INTERACTION.VIDEO_TIMESTAMP_CLICKED
                );
              }}
            >
              <Tooltip
                content="Video Timestamps"
                arrow={false}
                placement="left"
                animation={false}
              >
                <img src={VideoTimestampIcon} />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
