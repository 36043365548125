import { EditorCommandType } from "@/constants/editor-commands";

import { createCommand, Command } from "@/views/editor/EditorHistoryReducer";

export const updateBackgroundColorCommand = (
  metaData: any,
  changeBackgroundColor: (color: any) => void
): Command => {
  const { newColor, oldColor } = metaData;

  return createCommand({
    executeAction: () => changeBackgroundColor(newColor),
    undoAction: () => changeBackgroundColor(oldColor),
    commandType: EditorCommandType.UpdateBackgroundColor,
  });
};

export const updateBackgroundImageCommand = (
  metaData: any,
  updateBackgroundImage: (url: string) => void
): Command => {
  const { newUrl, oldUrl } = metaData;

  return createCommand({
    executeAction: () => updateBackgroundImage(newUrl),
    undoAction: () => updateBackgroundImage(oldUrl),
    commandType: EditorCommandType.UpdateBackgroundImage,
  });
};
