import VideoAILogo from "@/assets/images/video_ai_logo.png";

const LogoSpinner = () => {
  return (
    <div
      className="relative h-min w-min"
      role="status"
    >
      <div className="relative flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-r from-purple-400 via-blue-500  to-red-400 motion-safe:animate-spin">
        <div className="h-9 w-9 rounded-full bg-gray-50"></div>
      </div>
      <img
        src={VideoAILogo}
        className="absolute top-1/2 left-1/2 mt-px h-5 -translate-x-1/2 -translate-y-1/2 transform  motion-safe:animate-pulse"
        alt="VidyoAI Logo"
      />
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default LogoSpinner;
