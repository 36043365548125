import api from "@/api";
import { ApiEndpoints } from "@/api/constants";

import { isProd } from "@/helpers";

export const saveUtmToDB = async (data: any) => {
  if (isProd()) {
    api
      .post(ApiEndpoints.SAVE_UTM, data)
      .catch((e) => console.error("Failed to save UTM data to DB: ", e));
  }
};
