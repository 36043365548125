import * as React from "react";

import clsx from "clsx";

import { useAppSelector } from "@/store/hooks";

import { EDITOR_INTERACTION_DATA } from "@/constants/amplitude";

import { checkIfExternalAudioAssetPlaying } from "@/helpers";

import { trackEditorInteractionEvent } from "@/utils/amplitudeAnalytcs";

import { SelectionHandle } from "@/interfaces";

import { EditorMediaTab } from "@/enums";

import { EditorActionButton } from "@/views/editor/components/TimelineEditor/EditorActionButton";
import { ExternalAudioIndicator } from "@/views/editor/components/TimelineEditor/ExternalAudioIndicator";
import { IntelliClipIndicator } from "@/views/editor/components/TimelineEditor/IntelliClipIndicator";
import styles from "@/views/editor/components/TimelineEditor/TimelineEditorStyles.module.scss";
import { TimelineZoomSlider } from "@/views/editor/components/TimelineEditor/TimelineZoomSlider";
import { VideoPlayTime } from "@/views/editor/components/TimelineEditor/VideoPlayTime";
import TimelineWithZoom from "@/views/editor/components/TimelineWithZoom";
import { INTRO_OUTRO, editorSubRoutes } from "@/views/editor/constant";
import useAudioData from "@/views/editor/hooks/useAudioData";
import { useEditorNavigation } from "@/views/editor/hooks/useEditorNavigation";
import { useTimelineZoomSlider } from "@/views/editor/hooks/useTimelineZoomSlider";
import {
  AudioElement,
  PlaybackButtonsAction,
} from "@/views/editor/types/types";

type TimeChangeProps = {
  startTime: number;
  endTime: number;
  selectionHandle: SelectionHandle;
};

interface TimelineEditorProps {
  backwardVideo: () => void;
  backwardVideoByOneFrame: () => void;
  forwardVideo: () => void;
  forwardVideoByOneFrame: () => void;
  handlePlayPause: () => void;
  isIntelliClip: boolean;
  outroTimeLeft: number;
  isMainCanvasLoaded: boolean;
  videoElRef: React.MutableRefObject<HTMLVideoElement | null>;
  isVideoSeeking: boolean;
  isOutroEnabled: boolean;
  setCurrentTime: (value: number) => void;
  handleUpdateStartTimeOrEndTime: (props: TimeChangeProps) => void;
  onAfterSceneValueChange: (props: { time: number; index: number }) => void;
  audioElements: Record<string, AudioElement>;
}

// Refactoring NOTE:
// We are just passing the props from component to useEditorActions hook
// This might not seem a good practice but we are doing this because cannot make
// make a global state of all the refs.

export const TimelineEditor = ({
  isIntelliClip,
  backwardVideo,
  backwardVideoByOneFrame,
  forwardVideo,
  forwardVideoByOneFrame,
  handlePlayPause,
  outroTimeLeft,
  isMainCanvasLoaded,
  videoElRef,
  isVideoSeeking,
  isOutroEnabled,
  setCurrentTime,
  handleUpdateStartTimeOrEndTime,
  onAfterSceneValueChange,
  audioElements,
}: TimelineEditorProps): JSX.Element => {
  const { handleChangeSideBarMenu, handleChangeElementsTab } =
    useEditorNavigation({});
  const {
    pxPerSec,
    zoomSliderValue,
    handleSliderChange,
    handleStepDecrease,
    handleStepIncrease,
    fitToScreenFunctionRef,
    setBasePxPerSex,
    modifiedFromSliderRef,
  } = useTimelineZoomSlider();
  const { audioDataURL } = useAudioData();
  const isEditorVideoPaused = useAppSelector(
    (state) => state.editorState.isEditorVideoPaused
  );

  const currentSelectedProject = useAppSelector(
    (state) => state.homeState.currentSelectedProject
  );
  const editorCurrentVideoTime = useAppSelector(
    (state) => state.editorState.editorCurrentVideoTime
  );
  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );
  const allSceneChanges = useAppSelector(
    (state) => state.editorState.allSceneChanges
  );

  const chapterStart = parseFloat(currentSelectedMicroContent.chapter_start);
  const chapterEnd = parseFloat(currentSelectedMicroContent.chapter_end);
  const timelineOffset = currentSelectedMicroContent.chapter_start;
  const videoDuration =
    JSON.parse(currentSelectedProject?.data || "{}").duration || 0;

  const handleAudioAssetButtonClick = () => {
    handleChangeSideBarMenu(editorSubRoutes.MEDIA, EditorMediaTab.AUDIO);
  };

  const handlePlayPauseWrapper = () => {
    handlePlayPause();
    trackEditorInteractionEvent(
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.PLAY_PAUSE_CLICKED
    );
  };

  const playButtonListItem: PlaybackButtonsAction = {
    id: "play",
    label: "Play",
    action: handlePlayPauseWrapper,
    isDisabled: isVideoSeeking,
    isOutstandingButton: true,
  };
  const pauseButtonListItem: PlaybackButtonsAction = {
    id: "pause",
    label: "Pause",
    action: handlePlayPauseWrapper,
    isDisabled: isVideoSeeking,
    isOutstandingButton: true,
  };

  const buttonsList: PlaybackButtonsAction[] = [
    {
      id: "backward",
      message: "Skip 5 sec back",
      label: "Backward video",
      action: backwardVideo,
      isDisabled: isVideoSeeking,
      isOutstandingButton: false,
    },
    {
      id: "backwardByOneFrame",
      message: "Skip 1 frame back",
      label: "Backward 1 frame",
      action: backwardVideoByOneFrame,
      isDisabled: isVideoSeeking,
      isOutstandingButton: false,
    },
    ...(isEditorVideoPaused || outroTimeLeft
      ? [pauseButtonListItem]
      : [playButtonListItem]),
    {
      id: "forwardByOneFrame",
      message: "Skip 1 frame forward",
      label: "Forward 1 frame",
      action: forwardVideoByOneFrame,
      isDisabled: isVideoSeeking,
      isOutstandingButton: false,
    },
    {
      id: "forward",
      message: "Skip 5 sec forward",
      label: "Forward Video",
      action: forwardVideo,
      isDisabled: isVideoSeeking,
      isOutstandingButton: false,
    },
  ];

  const actionButtons = buttonsList.map((button) => {
    return (
      <EditorActionButton
        key={button.id}
        iconId={button.id}
        disabled={button.isDisabled}
        onClick={button.action}
        message={button.message}
        isOutstandingButton={button.isOutstandingButton}
      />
    );
  });

  const handleOutroTimeClick = () => {
    handleChangeSideBarMenu(editorSubRoutes.ELEMENTS);
    handleChangeElementsTab(INTRO_OUTRO);
  };

  const handleFitToScreenWithAnalytics = () => {
    fitToScreenFunctionRef?.current?.();
    trackEditorInteractionEvent(
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.FIT_CLICKED
    );
  };

  const handleStepDecreaseWithAnalytics = () => {
    handleStepDecrease();
    trackEditorInteractionEvent(
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.ZOOM_OUT_CLICKED
    );
  };

  const handleStepIncreaseWithAnalytics = () => {
    handleStepIncrease();
    trackEditorInteractionEvent(
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.ZOOM_IN_CLICKED
    );
  };

  const trackSliderAnalytics = () => {
    trackEditorInteractionEvent(
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.EVENT_KEY,
      EDITOR_INTERACTION_DATA.TIMELINE_INTERACTION.SLIDER_USED
    );
  };

  const currentTime =
    (editorCurrentVideoTime
      ? editorCurrentVideoTime * 1000
      : currentSelectedMicroContent.start) - chapterStart;

  const isExternalAudioPlaying = checkIfExternalAudioAssetPlaying(
    currentTime,
    audioElements,
    isEditorVideoPaused
  );

  return (
    <>
      <div className={clsx(styles.timelineButtonWrapper, "mx-2")}>
        <div className="flex gap-2">
          {isIntelliClip && <IntelliClipIndicator />}
          {isExternalAudioPlaying && (
            <ExternalAudioIndicator handleClick={handleAudioAssetButtonClick} />
          )}
        </div>
        <div
          className={clsx(
            styles.playbackControlsWrapper,
            isVideoSeeking ? "opacity-50" : "opacity-100",
            "justify-self-center"
          )}
        >
          <div />
          <div
            className={clsx(
              styles.playBackButtonsWrapper,
              "flex",
              "items-center"
            )}
          >
            {actionButtons}
          </div>
          <div
            className={clsx(
              "flex",
              "gap-2",
              "justify-self-start",
              "self-center"
            )}
          >
            <VideoPlayTime
              outroEnabled={isOutroEnabled}
              handleOutroTimeClick={handleOutroTimeClick}
              outroTimeLeft={outroTimeLeft}
              videoElRef={videoElRef}
              isMainCanvasLoaded={isMainCanvasLoaded}
            />
          </div>
        </div>

        <TimelineZoomSlider
          fitToScreen={handleFitToScreenWithAnalytics}
          value={zoomSliderValue}
          trackSliderChange={trackSliderAnalytics}
          handleSliderChange={handleSliderChange}
          handleStepDecrease={handleStepDecreaseWithAnalytics}
          handleStepIncrease={handleStepIncreaseWithAnalytics}
        />
      </div>

      <div className={clsx("mx-2")}>
        {isMainCanvasLoaded && currentSelectedProject?.id ? (
          <TimelineWithZoom
            mediaURL={audioDataURL || ""}
            currentTime={currentTime}
            id={currentSelectedProject.id}
            startTime={currentSelectedMicroContent.start - chapterStart}
            endTime={currentSelectedMicroContent.end - chapterStart}
            setCurrentTime={(value: number) => {
              setCurrentTime(value + chapterStart);
            }}
            updateStartAndEndTime={handleUpdateStartTimeOrEndTime}
            cutmagicData={allSceneChanges.map((scene) => ({
              ...scene,
              start: scene.start - timelineOffset / 1000,
              end: scene.end - timelineOffset / 1000,
            }))}
            updateCutmagicScene={onAfterSceneValueChange}
            duration={(chapterEnd - chapterStart) / 1000}
            chapterPercentage={{
              start: chapterStart / videoDuration,
              end: chapterEnd / videoDuration,
            }}
            isIntelligentCut={false}
            pxPerSec={pxPerSec}
            fitToScreenFunctionRef={fitToScreenFunctionRef}
            setBasePxPerSec={setBasePxPerSex}
            modifiedFromSliderRef={modifiedFromSliderRef}
          />
        ) : (
          <div
            className="animate-pulse rounded-md bg-gray-200 dark:bg-gray-700"
            style={{
              height: "100px",
              marginBottom: 0,
            }}
          ></div>
        )}
      </div>
    </>
  );
};
