import { useState } from "react";

import { setCurrentUser } from "@/store/authSlice";
import {
  toggleUpgradeToProModal,
  toggleUploadVideoModal,
  updateBaseTemplates,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useUserConsumedProcessingTime from "@/api/useUserConsumedProcessingTime";

import { ANALYTICS_CONSTANTS } from "@/constants/amplitude";

import {
  calculateRemainingProcessingTime,
  getAllBaseTemplates,
} from "@/helpers";

import { eventsDataToRedux } from "@/utils/amplitudeAnalytcs";

import UserOnboardingCompletedModal from "./components/UserOnBoardingStages/UserOnboardingCompletedModal";

import ExplainerVideo from "@/assets/images/on-boarding/explainer.mp4";

const WelcomeScreen = () => {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state) => state.authState.currentUser);

  const [showOnboardingCompletedModal, setShowOnboardingCompletedModal] =
    useState(currentUser?.showOnBoarding || false);

  const { data: usageData, isLoading: usageDataIsLoading } =
    useUserConsumedProcessingTime();

  const defaultBaseTemplates = useGetDefaultBaseTemplates();

  const handelOpenVideoUploadModal = () => {
    if (calculateRemainingProcessingTime(usageData) > 0) {
      const baseTemplates = getAllBaseTemplates(defaultBaseTemplates?.data);

      dispatch(toggleUploadVideoModal(true));
      dispatch(updateBaseTemplates(baseTemplates));
    } else {
      dispatch(toggleUpgradeToProModal(true));
      eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
    }
  };

  const handleCloseOnboardingCompletedModal = () => {
    dispatch(setCurrentUser({ ...currentUser, showOnBoarding: false }));
    setShowOnboardingCompletedModal(false);
  };

  return (
    <>
      {/* NOTE: change the height from 20rem to 16.5rem bellow
          while Banner is not present
       */}
      <div className="relative w-full h-[calc(100vh_-_16.5rem)] overflow-y-auto">
        <div className="absolute top-1/2 left-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-between space-y-4 px-2">
          <div className="flex w-full items-center justify-center">
            <div className="hidden w-[50%] sm:block">
              <video
                src={ExplainerVideo}
                className="mx-auto w-[600px]"
                autoPlay
                muted
                loop
              ></video>
              <p className="my-4 text-center text-sm font-medium text-gray-600">
                Upload your long video to begin & see the magic. It's absolutely
                free!
              </p>
            </div>

            <div className="w-[50%]">
              <div className="mx-auto flex h-80 w-full items-center justify-center rounded-[32px] bg-transparent text-center sm:bg-white sm:shadow-[0_4px_60px_0_rgba(0,0,0,0.12)] md:w-[300px] lg:w-[400px]">
                <div>
                  <button
                    type="button"
                    id="welcome-upload-file-modal-button"
                    className={`mr-2 mb-2  mt-10 inline-flex items-center whitespace-nowrap rounded-lg bg-blue-600 px-8 py-4 text-center text-sm font-medium text-white  focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:focus:ring-blue-800 ${
                      usageDataIsLoading || defaultBaseTemplates.isLoading
                        ? "cursor-not-allowed opacity-75"
                        : "hover:bg-blue-700 dark:hover:bg-blue-700"
                    }`}
                    onClick={handelOpenVideoUploadModal}
                    disabled={
                      usageDataIsLoading || defaultBaseTemplates.isLoading
                    }
                  >
                    <svg
                      width="17"
                      height="12"
                      className=" mr-2"
                      viewBox="0 0 17 12"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.5 0C1.395 0 0.5 0.895 0.5 2V10C0.5 11.105 1.395 12 2.5 12H14.5C15.605 12 16.5 11.105 16.5 10V4C16.5 2.895 15.605 2 14.5 2H9.5L7.5 0H2.5ZM9.5 5C9.5 4.448 9.052 4 8.5 4C7.948 4 7.5 4.448 7.5 5V6H6.5C5.948 6 5.5 6.448 5.5 7C5.5 7.552 5.948 8 6.5 8H7.5V9C7.5 9.552 7.948 10 8.5 10C9.052 10 9.5 9.552 9.5 9V8H10.5C11.052 8 11.5 7.552 11.5 7C11.5 6.448 11.052 6 10.5 6H9.5V5Z"
                        fill="white"
                      />
                    </svg>
                    Upload File
                  </button>
                  <p className="my-1 text-sm text-gray-600">or</p>
                  <p className="font-semibold text-gray-600">
                    Paste{" "}
                    <span
                      className="cursor-pointer underline"
                      onClick={() => {
                        if (
                          !usageDataIsLoading &&
                          !defaultBaseTemplates.isLoading
                        ) {
                          handelOpenVideoUploadModal();
                        }
                      }}
                    >
                      URL
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserOnboardingCompletedModal
        showUserOnBoardingCompletedModal={showOnboardingCompletedModal}
        setShowOnboardingCompletedModal={handleCloseOnboardingCompletedModal}
      />
    </>
  );
};

export default WelcomeScreen;
