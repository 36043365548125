export const getPrimaryLabelNumber = (pxPerSec: number): [number, number] => {
  if (pxPerSec >= 130) {
    return [1, NaN];
  } else if (pxPerSec >= 50) {
    return [5, 1];
  } else if (pxPerSec <= 50 && pxPerSec >= 20) {
    return [10, 5];
  } else if (pxPerSec <= 20 && pxPerSec >= 4) {
    return [30, 10];
  } else if (pxPerSec <= 4 && pxPerSec >= 1) {
    return [60 * 2, 30];
  } else if (pxPerSec <= 1 && pxPerSec >= 0.5) {
    return [60 * 5, 60];
  } else if (pxPerSec <= 0.5 && pxPerSec >= 0.3) {
    return [60 * 5, 60];
  } else if (pxPerSec <= 0.3) {
    return [60 * 10, 60 * 5];
  }
  return [60, 10];
};

const loadMap: Record<
  string,
  {
    channelData?: number[];
    loadRequestedStatus: boolean;
    objectsData?: Record<number, fabric.Rect>;
  }
> = {};

export function normalize(channelData: Float32Array): Float32Array {
  const firstChannel = channelData;
  const length = firstChannel.length;
  let max = 0;
  let min = 1_00_000;
  for (let i = 0; i < length; i++) {
    const absN = Math.abs(firstChannel[i]);
    const absM = Math.abs(firstChannel[i]);
    if (absN > max) max = absN;
    if (absM < min) min = absM;
  }
  for (let i = 0; i < length; i++) {
    firstChannel[i] /= max;
  }
  return firstChannel;
}
export function findMinMax(arr: number[]) {
  let min = Infinity;
  let max = -Infinity;
  let indexOfMax = 0;
  let indexOfMin = 0;

  for (let i = 0; i < arr.length; i++) {
    const currentValue = arr[i];

    // Update min and max if necessary
    if (currentValue < min) {
      min = currentValue;
      indexOfMin = i;
    }
    if (currentValue > max) {
      indexOfMax = i;
      max = currentValue;
    }
  }

  return { min, max, indexOfMax, indexOfMin };
}

function calculateAverageEfficiently(largeDataset: number[]) {
  // Use a typed array for memory efficiency and faster numerical operations:
  const typedArray = new Float64Array(largeDataset);

  // Optimized accumulator for sum to minimize precision errors:
  let sum = 0;
  for (let i = 0; i < typedArray.length; i++) {
    const value = typedArray[i];
    sum = value + sum; // Add in this order to reduce precision loss
  }

  // Calculate the average using the more reliable `Math.fround` for large numbers:
  const average = Math.fround(sum / typedArray.length);

  return average;
}

function normalizePCMDataTo0100(channelData: number[]) {
  const normalizedData = [];
  const { max } = findMinMax(channelData);

  const scale = 100 / max;

  // Normalize each sample to 0-100
  for (let i = 0; i < channelData.length; i++) {
    normalizedData[i] = channelData[i] * scale; // Shift and scale
  }

  const average = calculateAverageEfficiently(normalizedData);

  for (let i = 0; i < normalizedData.length; i++) {
    normalizedData[i] = normalizedData[i] * (15 / average); // Shift and scale
  }

  return normalizedData;
}

/** Function to generate the wave form, takes in the mediaSourceURL, canvas and fabric Instance. */
export async function generateWaveForm({
  src,
  cb,
  controller,
  id,
}: {
  src: string | null;
  id: string;
  cb?: (channelData?: number[]) => void;
  chapterPercentage: {
    start: number;
    end: number;
  };
  controller: AbortController;
}) {
  loadMap[id] = loadMap[id] || {
    loadRequestedStatus: false,
    objectsData: [],
    channelData: [],
  };

  let audioContext: AudioContext | null = null;

  let channelData: number[];
  // if the cached items are already available, use them.
  if (
    loadMap[id] &&
    loadMap[id].loadRequestedStatus &&
    loadMap[id].channelData !== undefined
  ) {
    channelData = loadMap[id].channelData!;
    cb?.(channelData);
  } else {
    if (src) {
      try {
        const response = await fetch(src, { signal: controller.signal });
        channelData = normalizePCMDataTo0100(await response.json());
        loadMap[id].channelData = channelData;
        loadMap[id].loadRequestedStatus = true;
      } catch (e) {
        console.debug("[DEBUG]:", e);
        return;
      }
      cb?.(channelData);
    }
  }
}
