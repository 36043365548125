import { Button } from "flowbite-react";

import { toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppDispatch } from "@/store/hooks";

import { ANALYTICS_CONSTANTS } from "@/constants/amplitude";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import { trackUserButtonEvent } from "@/utils/SegmentAnalytics";
import { eventsDataToRedux } from "@/utils/amplitudeAnalytcs";

import BaseModal from "@/components/BaseModal/BaseModal";

const { UPGRADE_NOW_FEATURE_DISCOVERY_MODAL_BTN } =
  BUTTON_IDS.PRO_FEATURE_DISCOVERY_MODAL;

const ProFeatureDiscoveryModal = ({
  showProFeatureDiscoveryModal,
  setShowProFeatureDiscoveryModal,
}: {
  setShowProFeatureDiscoveryModal: (value: boolean) => void;
  showProFeatureDiscoveryModal: boolean;
}) => {
  const dispatch = useAppDispatch();

  return (
    <BaseModal
      isModalOpen={showProFeatureDiscoveryModal}
      handelModalOpen={() => {}}
      notClosable
    >
      <div className="w-[30rem] p-6">
        <p className="mb-2 text-center text-3xl">🤩</p>
        <p className=" mb-8 text-center text-2xl font-bold text-gray-900">
          You've discovered a PRO feature!
        </p>

        <div className="mx-auto mb-10 w-fit">
          <p className="mx-auto w-[80%] text-center font-light text-gray-500">
            <span className="font-bold">PRO</span> users can edit clip even
            after download. To use this feature,{" "}
            <span className="font-bold">upgrade to PRO</span>
          </p>
        </div>

        <div className="flex w-full justify-center space-x-6">
          <Button
            onClick={() => {
              setShowProFeatureDiscoveryModal(false);
            }}
            color="gray"
            id="maybe-later-upgrade"
            className="w-44"
            tabIndex={-1}
          >
            Maybe later
          </Button>

          <Button
            onClick={() => {
              trackUserButtonEvent(
                UPGRADE_NOW_FEATURE_DISCOVERY_MODAL_BTN.ID,
                UPGRADE_NOW_FEATURE_DISCOVERY_MODAL_BTN.ACTION
              );
              dispatch(toggleUpgradeToProModal(true));
              setShowProFeatureDiscoveryModal(false);
              eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
            }}
            id={UPGRADE_NOW_FEATURE_DISCOVERY_MODAL_BTN.ID}
            className="w-44"
          >
            Upgrade now
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
export default ProFeatureDiscoveryModal;
