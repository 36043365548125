import { useEffect } from "react";

const useKeyboardShortcutForEditorUndoRedo = (
  onUndo: () => void,
  onRedo: () => void
) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        event.preventDefault();
        onUndo();
      } else if ((event.metaKey || event.ctrlKey) && event.key === "y") {
        event.preventDefault();
        onRedo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup function
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onUndo, onRedo]); // Dependency array to re-bind if handlers change
};

export default useKeyboardShortcutForEditorUndoRedo;
