import { createCommand, Command } from "@/views/editor/EditorHistoryReducer";

export const addLogosCommand = (
  metaData: any,
  addLogo: (metData: any) => void,
  removeLogo: (id: string | number) => void
): Command => {
  return createCommand({
    executeAction: () => addLogo(metaData),
    undoAction: () => removeLogo(metaData?.item?.id),
  });
};

export const deleteLogoCommand = (
  metaData: any,
  addLogo: (metData: any) => void,
  removeLogo: (id: string | number) => void
): Command => {
  return createCommand({
    executeAction: () => removeLogo(metaData?.item?.id),
    undoAction: () => addLogo(metaData),
  });
};

export const modifyLogoOnCanvasCommand = (
  metaData: any,
  applyLogoModification: (metData: any) => void
): Command => {
  const { newMediaData, oldMediaData } = metaData;
  return createCommand({
    executeAction: () => applyLogoModification(newMediaData),
    undoAction: () => applyLogoModification(oldMediaData),
  });
};

export const addSocialCalloutCommand = (
  metaData: any,
  addSocialCallout: (metData: any) => void,
  removeSocialCallout: (id: string | number) => void
): Command => {
  const { item } = metaData;
  return createCommand({
    executeAction: () => addSocialCallout(item),
    undoAction: () => removeSocialCallout({ ...item, isUndoOperation: true }),
  });
};

export const removeSocialCalloutCommand = (
  metaData: any,
  addSocialCallout: (metData: any) => void,
  removeSocialCallout: (id: string | number) => void
): Command => {
  const { item } = metaData;
  return createCommand({
    executeAction: () =>
      removeSocialCallout({ ...item, isUndoOperation: true }),
    undoAction: () => addSocialCallout(item),
  });
};
