import { useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper";

import { XMarkIcon } from "@heroicons/react/24/solid";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";

import {
  toggleUploadVideoModal,
  updateOnBoardingDataById,
  updateUserSelectedLayouts,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useAddUserPreferenceData from "@/api/useAddUserPreferenceData";
import useDeleteProject from "@/api/useDeleteProject";
import useGetUserPreference from "@/api/useGetUserPreference";

import { parseUserPreferenceData } from "@/helpers";

import { OnBoardingStep, VideoLayout } from "@/enums";

import BaseModal from "@/components/BaseModal/BaseModal";

import CompletedOnboardingSteps from "@/views/home/components/CompletedOnBoardingSteps";
import LayoutPreference from "@/views/home/components/LayoutPreference";
import PersonalizeVideoForm from "@/views/home/components/PersonalizeVideoForm";
import TemplatePreference from "@/views/home/components/TemplatePreference";
import OnBoardingUploadFile from "@/views/home/components/UploadVideoModal/OnBoardingUploadFile";

import "./OnBoardingModal.styles.scss";

import PersonalizeIconActive from "@/assets/icons/personalize-icon-active.svg";
import PersonalizeIcon from "@/assets/icons/personalize-icon.svg";
import SettingsIconActive from "@/assets/icons/settings-icon-active.svg";
import SettingsIcon from "@/assets/icons/settings-icon.svg";
import VideoIcon from "@/assets/icons/video-icon.svg";

const OnBoardingModal = () => {
  const [activeOnBoardingStep, setActiveOnBoardingStep] = useState(
    OnBoardingStep.UPLOAD_FILE
  );

  const [savePreference, setSavePreference] = useState(false);

  const queryClient = useQueryClient();

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const showUploadVideoModal = useAppSelector(
    (state) => state.homeState.uploadVideoModalVisible
  );

  const onBoardingData = useAppSelector(
    (state) => state.homeState.onBoardingData
  );

  const userSocialMediaHandles = useAppSelector(
    (state) => state.homeState.userSocialMediaHandles
  );

  const onBoardingId = useAppSelector(
    (state) => state.homeState.currentOnBoardingId
  );

  const dispatch = useAppDispatch();

  const { mutate: addUserPreferenceData } = useAddUserPreferenceData();

  const { data: userPreferenceData, isLoading: isUserPreferenceDataLoading } =
    useGetUserPreference(uid);

  const cancelProjectSuccessCB = (isCloseModal = true) => {
    resetDefaultsOnBoardingFlow();
    if (isCloseModal) {
      handelToggleVideoUploadModal(false);
    }
  };

  const syncUserCommonPreferencesWithCurrentProject = () => {
    addUserPreferenceData({
      userId: uid,
      userPreferenceData: {
        baseTemplates: parseUserPreferenceData(
          onBoardingData[onBoardingId].baseTemplates
        ),
        selectedLayouts: onBoardingData[onBoardingId].selectedLayouts,
        socialMediaHandles: userSocialMediaHandles,
        colorPallets: userPreferenceData?.colorPallets || [],
        createdAt: new Date(),
        savePreference: savePreference,
        isVisitedBrandKitPage:
          userPreferenceData?.isVisitedBrandKitPage || false,
        isSeenConfetti: userPreferenceData?.isSeenConfetti || false,
        userHasExistingProject:
          userPreferenceData?.userHasExistingProject || true,
        allowLowResImport: userPreferenceData?.allowLowResImport || true,
        languagePreference: userPreferenceData?.languagePreference,
      },
    });
  };

  const { mutate, isLoading } = useDeleteProject(cancelProjectSuccessCB);

  const handelToggleVideoUploadModal = (state: boolean) => {
    dispatch(toggleUploadVideoModal(state));
  };

  const stepsStyles = {
    activeBgColor: "#EBF5FF",
    activeTextColor: "#1C64F2",
    completedBgColor: "#f3f4f6",
    completedTextColor: "#111928",
    inactiveBgColor: "#f3f4f6",
    inactiveTextColor: "#f3f4f6",
    size: "2em",
    circleFontSize: "1rem",
    labelFontSize: "0.875rem",
    borderRadius: "6px",
    fontWeight: "400",
  };

  const connectorStyles = {
    disabledColor: "#9CA3AF",
    activeColor: "#9CA3AF",
    completedColor: "#9CA3AF",
    size: 0.5,
    stepSize: "5em",
    style: "solid",
  };

  const handleChangeActiveStep = (step: number) => {
    setActiveOnBoardingStep(step);
  };

  // Extra layer of validation to remove any unwanted templates localUserBasePreferences
  const validationForSelectedTemplates = () => {
    let updatedData = { ...onBoardingData[onBoardingId].baseTemplates };

    Object.keys(onBoardingData[onBoardingId].baseTemplates).forEach((key) => {
      if (!onBoardingData[onBoardingId].selectedLayouts.includes(key)) {
        updatedData = {
          ...updatedData,
          [key]: [],
        };
      }
    });

    dispatch(
      updateOnBoardingDataById({
        id: onBoardingId,
        data: { baseTemplates: updatedData },
      })
    );
  };

  useEffect(() => {
    setSavePreference(userPreferenceData?.savePreference || false);
  }, [userPreferenceData]);

  const resetDefaultsOnBoardingFlow = () => {
    if (!savePreference) {
      dispatch(updateUserSelectedLayouts([]));
    }
    queryClient.invalidateQueries({ queryKey: ["all-projects"] });

    setActiveOnBoardingStep(OnBoardingStep.UPLOAD_FILE);
  };

  const handelCancelUpload = () => {
    const selectedLayouts = userPreferenceData?.selectedLayouts || [];

    dispatch(updateUserSelectedLayouts(selectedLayouts));
    if (
      Object.values(onBoardingData).filter((p) => p.processingVideoData).length
    ) {
      const video = onBoardingData[onBoardingId].processingVideoData;
      if (video?.uploader) {
        video.uploader?.cancelAll();
      }
    }
    onBoardingData[onBoardingId]?.projectId &&
      mutate(onBoardingData[onBoardingId]?.projectId);
    // handelToggleVideoUploadModal(false);
    queryClient.invalidateQueries({ queryKey: ["all-projects"] });

    setActiveOnBoardingStep(OnBoardingStep.UPLOAD_FILE);
  };

  const getPreferredTemplatesCount = (arr: any) =>
    arr.filter((x: any) => x.isPreferred).length;

  const getSelectedTemplatesCount = () => {
    let templateSelectionStateCompletedArray: any = {};

    if (onBoardingData[onBoardingId]) {
      const { baseTemplates } = onBoardingData[onBoardingId];

      Object.keys(baseTemplates).forEach((key) => {
        if (
          baseTemplates[key]?.length &&
          onBoardingData[onBoardingId].selectedLayouts.includes(key) &&
          key !== VideoLayout.LAYOUT_9_16_2
        ) {
          templateSelectionStateCompletedArray = {
            ...templateSelectionStateCompletedArray,
            [key]: getPreferredTemplatesCount(
              key === VideoLayout.LAYOUT_9_16_1
                ? [
                    ...baseTemplates[key],
                    ...baseTemplates[VideoLayout.LAYOUT_9_16_2],
                  ]
                : baseTemplates[key]
            ),
          };
        }
      });
    }
    return templateSelectionStateCompletedArray;
  };

  const checkIfTemplateSelectionStateIsCompleted = ({
    layout,
    checkIfReadyForNextStep,
  }: any) => {
    let templateSelectionStateCompletedArray: any = getSelectedTemplatesCount();

    if (layout) {
      return templateSelectionStateCompletedArray[layout];
    }

    if (checkIfReadyForNextStep) {
      const arr: any = Object.values(templateSelectionStateCompletedArray);
      return arr.every((item: number) => item >= 3);
    }

    return templateSelectionStateCompletedArray;
  };

  const renderOnBoardingStep = () => {
    if (userPreferenceData?.userHasExistingProject) {
      switch (activeOnBoardingStep) {
        case OnBoardingStep.PERSONALIZED_VIDEO:
          return (
            <PersonalizeVideoForm
              handleChangeActiveStep={handleChangeActiveStep}
              validationForSelectedTemplates={validationForSelectedTemplates}
              userHasExistingProject={
                userPreferenceData?.userHasExistingProject
              }
              handelCancelUpload={handelCancelUpload}
              isLoading={isLoading}
            />
          );
        case OnBoardingStep.COMPLETE_ONBOARDING:
          return (
            <CompletedOnboardingSteps
              handelToggleVideoUploadModal={handelToggleVideoUploadModal}
              syncUserCommonPreferencesWithCurrentProject={
                syncUserCommonPreferencesWithCurrentProject
              }
              resetDefaultsOnBoardingFlow={resetDefaultsOnBoardingFlow}
              setSavePreference={setSavePreference}
              savePreference={savePreference}
            />
          );
        default:
          return <></>;
      }
    } else {
      switch (activeOnBoardingStep) {
        case 1:
          return (
            <LayoutPreference
              handleChangeActiveStep={handleChangeActiveStep}
              handelCancelUpload={handelCancelUpload}
              isLoading={isLoading}
            />
          );
        case OnBoardingStep.SELECT_TEMPLATES:
          return (
            <TemplatePreference
              getSelectedTemplatesCount={getSelectedTemplatesCount}
              handleChangeActiveStep={handleChangeActiveStep}
              checkIfTemplateSelectionStateIsCompleted={
                checkIfTemplateSelectionStateIsCompleted
              }
            />
          );
        case OnBoardingStep.PERSONALIZED_VIDEO:
          return (
            <PersonalizeVideoForm
              handleChangeActiveStep={handleChangeActiveStep}
              validationForSelectedTemplates={validationForSelectedTemplates}
            />
          );
        case OnBoardingStep.COMPLETE_ONBOARDING:
          return (
            <CompletedOnboardingSteps
              handelToggleVideoUploadModal={handelToggleVideoUploadModal}
              syncUserCommonPreferencesWithCurrentProject={
                syncUserCommonPreferencesWithCurrentProject
              }
              resetDefaultsOnBoardingFlow={resetDefaultsOnBoardingFlow}
              setSavePreference={setSavePreference}
              savePreference={savePreference}
            />
          );
        default:
          return <></>;
      }
    }
  };

  const settingsIcon =
    activeOnBoardingStep === 1 ||
    activeOnBoardingStep === OnBoardingStep.SELECT_TEMPLATES
      ? SettingsIconActive
      : SettingsIcon;

  if (showUploadVideoModal) {
    return (
      <BaseModal
        isModalOpen={showUploadVideoModal}
        notClosable={true}
      >
        <div
          className={clsx(
            "relative h-[90vh] max-w-lg items-center sm:w-[95vw] sm:max-w-[60rem]",
            activeOnBoardingStep === OnBoardingStep.UPLOAD_FILE
              ? "max-h-[620px]"
              : "max-h-[800px]",
            !isUserPreferenceDataLoading &&
              !userPreferenceData?.userHasExistingProject &&
              "sm:!max-w-[90rem]"
          )}
        >
          {activeOnBoardingStep !== OnBoardingStep.COMPLETE_ONBOARDING &&
          activeOnBoardingStep !== OnBoardingStep.UPLOAD_FILE ? (
            <div className="onboarding_modal_stepper">
              <Stepper
                stepClassName="single_step"
                styleConfig={stepsStyles}
                connectorStyleConfig={connectorStyles}
                activeStep={activeOnBoardingStep}
              >
                <Step>
                  <div className="flex w-full items-center space-x-1">
                    <img
                      className="h-4 w-4"
                      src={VideoIcon}
                    />
                    <p className={`text-xs  ${"text-gray-700"}`}>
                      Import Video
                    </p>
                  </div>
                </Step>
                {userPreferenceData?.userHasExistingProject ? null : (
                  <Step>
                    <div className="flex w-full items-center space-x-1">
                      <img
                        className="h-4 w-4"
                        src={settingsIcon}
                      />
                      <p
                        className={clsx(
                          "text-xs",
                          activeOnBoardingStep === 1 ||
                            activeOnBoardingStep ===
                              OnBoardingStep.SELECT_TEMPLATES
                            ? "font-semibold text-[#1C64F2]"
                            : "text-gray-700"
                        )}
                      >
                        Set Preferences
                      </p>
                    </div>
                  </Step>
                )}

                <Step>
                  <div className="flex items-center space-x-1">
                    <img
                      className="h-4 w-4"
                      src={
                        activeOnBoardingStep ===
                        OnBoardingStep.PERSONALIZED_VIDEO
                          ? PersonalizeIconActive
                          : PersonalizeIcon
                      }
                    />
                    <p
                      className={`text-xs  ${
                        activeOnBoardingStep ===
                        OnBoardingStep.PERSONALIZED_VIDEO
                          ? "font-semibold text-[#1C64F2]"
                          : "text-gray-700"
                      }`}
                    >
                      Personalize Videos
                    </p>
                  </div>
                </Step>
              </Stepper>
            </div>
          ) : null}

          {activeOnBoardingStep === OnBoardingStep.UPLOAD_FILE ||
          activeOnBoardingStep === OnBoardingStep.COMPLETE_ONBOARDING ? (
            <button
              type="button"
              className="absolute top-4 right-2.5 z-10 ml-auto inline-flex items-center rounded-lg p-1.5 text-sm text-gray-400 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white focus:outline-none"
              onClick={() => {
                if (
                  activeOnBoardingStep === OnBoardingStep.COMPLETE_ONBOARDING
                ) {
                  syncUserCommonPreferencesWithCurrentProject();
                  resetDefaultsOnBoardingFlow();
                }
                handelToggleVideoUploadModal(false);
              }}
            >
              <XMarkIcon className="h-7 w-7 text-gray-500 hover:bg-gray-100 hover:text-gray-700 rounded" />
              <span className="sr-only">Close modal</span>
            </button>
          ) : null}
          <div
            className={`overflow-y-auto overflow-x-hidden ${
              activeOnBoardingStep === OnBoardingStep.UPLOAD_FILE
                ? "h-full"
                : "h-[90%]"
            } `}
          >
            {" "}
            <OnBoardingUploadFile
              handleChangeActiveStep={handleChangeActiveStep}
              cancelProjectSuccessCB={cancelProjectSuccessCB}
              isVisible={activeOnBoardingStep === OnBoardingStep.UPLOAD_FILE}
              userHasExistingProject={
                userPreferenceData?.userHasExistingProject
              }
            />
            {renderOnBoardingStep()}
          </div>
        </div>
      </BaseModal>
    );
  }
  return null;
};

export default OnBoardingModal;
