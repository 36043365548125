import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useEffectOnce } from "react-use";

import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { DocumentTextIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { Badge, Button, Spinner } from "flowbite-react";

import { store } from "@/store";
import {
  toggleSaveDraft,
  toggleShowDownloadPreferenceModal,
  toggleShowSaveTemplateModal,
  updateIsUserChangeLayout,
} from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";

import { EDITOR_INTERACTION_DATA } from "@/constants/amplitude";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import { isAdminTemplateEditor, zE } from "@/helpers";

import {
  trackEditorInteractionEvent,
  trackLayoutChangeEvent,
} from "@/utils/amplitudeAnalytcs";

import { ABTest } from "@/enums";

import { EditorHistoryProvider } from "@/views/editor/EditorHistoryContext";
// import Banner from "@/components/Banner";
import UploadVideoModal from "@/views/home/components/UploadVideoModal";

import AspectRatioChangeDropdown from "./AspectRatioChangeDropdown";
import UndoRedoButton from "./UndoRedoButton";

import SaveTemplateIcon from "@/assets/icons/save-template.svg";
import VideoAILogo from "@/assets/images/video_ai_logo.png";

const {
  SAVE_DRAFT_EDITOR_TOP_BTN,
  SAVE_TEMPLATE_EDITOR_TOP_BTN,
  EDITOR_RENDER_VIDEO_BTN,
} = BUTTON_IDS.EDITOR_LAYOUT;

const EditorLayout = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const isUndoRedoEnabled =
    useFeatureIsOn(ABTest.EDITOR_UNDO_REDO as string) ||
    store.getState().editorState.isUndoRedoEnabledNewUser;

  const [microContentGist, setMicroContentGist] = useState(null);

  const isRenderingVideo = useAppSelector(
    (state) => state.editorState.isRenderingVideo
  );

  const currentProject = useAppSelector(
    (state) => state.homeState.currentSelectedProject
  );

  const currentMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const isShortVideo = useAppSelector((state) => state.homeState.isShortVideo);

  const enableSceneChange = useAppSelector(
    (state) => state.editorState.enableSceneChange
  );

  const { selectedEditorDraft } = useAppSelector((state) => state.draftState);

  useEffectOnce(() => {
    zE("webWidget", "helpCenter:setSuggestions", {
      search: "editor",
    });
  });

  useEffect(() => {
    // Load this only once when editor is loaded
    // Since, user can this and the navigation text will also change
    if (currentMicroContent?.gist) {
      setMicroContentGist(currentMicroContent.gist);
    }
  }, []);

  return (
    <EditorHistoryProvider>
      <main className="mx-auto h-[100vh] w-full bg-[#e8e8ee] overflow-hidden">
        {/* <Banner bannerVariant="outrage" /> */}
        <div>
          <div className="relative flex h-[4.5rem] items-center justify-between lg:px-6">
            <nav
              className="flex py-4"
              aria-label="Breadcrumb"
            >
              <ol className="flex items-center space-x-1 md:space-x-3">
                <li>
                  <p
                    onClick={() => {
                      dispatch(updateIsUserChangeLayout(false));
                      navigate("/");
                    }}
                    className="flex cursor-pointer items-center lg:order-2 lg:justify-center"
                  >
                    <img
                      src={VideoAILogo}
                      className="mr-1 h-6 sm:h-7"
                      alt="VidyoAI Logo"
                    />
                    <span className="self-center whitespace-nowrap text-lg font-semibold dark:text-white">
                      vidyo.ai
                    </span>
                  </p>
                </li>

                {isShortVideo ? null : (
                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-6 w-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <p
                        onClick={() => {
                          dispatch(updateIsUserChangeLayout(false));
                          navigate(`/home/project/${currentProject?.id}`);
                        }}
                        className="ml-1 max-w-[10rem] cursor-pointer truncate font-medium text-gray-500 hover:text-gray-600 md:ml-2"
                      >
                        {currentProject?.title}
                      </p>
                    </div>
                  </li>
                )}

                <li>
                  <div className="flex items-center">
                    <svg
                      className="h-6 w-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <p className="ml-1  max-w-[12rem] cursor-default truncate font-semibold text-gray-700 hover:text-gray-800  md:ml-2 ">
                      {microContentGist}
                    </p>
                  </div>
                </li>

                {selectedEditorDraft?.fromDownloadPage ? null : (
                  <li className="flex relative">
                    <Button
                      disabled={isRenderingVideo}
                      size="sm"
                      color="gray"
                      className="bg-transparent text-primary-600 hover:bg-blue-100 w-48 border-gray-300"
                      onClick={() => {
                        dispatch(toggleSaveDraft(true));
                        trackEditorInteractionEvent(
                          EDITOR_INTERACTION_DATA.SAVE_DRAFT.EVENT_KEY,
                          true
                        );
                      }}
                      id={SAVE_DRAFT_EDITOR_TOP_BTN.ID}
                    >
                      <DocumentTextIcon className="w-4 h-4 mr-1" />
                      {selectedEditorDraft?.id ? "Update Draft" : "Save Draft"}

                      <Badge
                        className="left-0 ml-2"
                        color="success"
                      >
                        NEW
                      </Badge>
                    </Button>
                  </li>
                )}
              </ol>
            </nav>

            <div className="flex items-center space-x-4">
              {isUndoRedoEnabled && <UndoRedoButton />}
              <AspectRatioChangeDropdown />
              {isAdminTemplateEditor() ? null : (
                <Button
                  disabled={isRenderingVideo || enableSceneChange}
                  size="sm"
                  color="gray"
                  className={clsx(
                    isRenderingVideo || enableSceneChange
                      ? "hover:bg-transparent cursor-not-allowed opacity-70"
                      : "hover:bg-blue-100",
                    "bg-transparent border-primary-600 text-primary-600 flex items-center"
                  )}
                  onClick={() => {
                    dispatch(toggleShowSaveTemplateModal(true));
                    trackEditorInteractionEvent(
                      EDITOR_INTERACTION_DATA.SAVE_TEMPLATE.EVENT_KEY,
                      true
                    );
                  }}
                  id={SAVE_TEMPLATE_EDITOR_TOP_BTN.ID}
                >
                  <img
                    src={SaveTemplateIcon}
                    alt="save"
                    className="mr-1.5"
                  />
                  Save Template
                </Button>
              )}

              <Button
                size="sm"
                disabled={isRenderingVideo}
                id={EDITOR_RENDER_VIDEO_BTN.ID}
                className={clsx(isRenderingVideo && "cursor-wait opacity-70")}
                onClick={() => {
                  dispatch(toggleShowDownloadPreferenceModal(true));
                  trackEditorInteractionEvent(
                    EDITOR_INTERACTION_DATA.DOWNLOAD_VIDEO.EVENT_KEY,
                    true
                  );
                }}
              >
                {isRenderingVideo ? (
                  <div
                    role="status"
                    className="relative mx-auto flex w-max"
                  >
                    <Spinner className="mr-1 w-4 h-4 -mt-0.5" />
                    <span>Downloading Video</span>
                  </div>
                ) : (
                  <span>Download Video</span>
                )}
              </Button>
            </div>
          </div>

          <div
            className={`relative overflow-y-auto bg-white h-[calc(100vh_-_5rem) overflow-hidden`}
          >
            <Outlet />
          </div>
        </div>
        <UploadVideoModal />
        <ReactTooltip
          effect="solid"
          padding="8px"
          arrowColor="transparent"
          className="bg-gray-900 text-sm text-white"
        />
      </main>
    </EditorHistoryProvider>
  );
};

export default EditorLayout;
