import { memo } from "react";
import { useOutletContext } from "react-router";

import { InformationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Checkbox } from "flowbite-react";
import PubSub from "pubsub-js";

import { useAppSelector } from "@/store/hooks";

import { formatSeconds, getSecondsFromHMSM } from "@/helpers";

import { EditorMediaStatus, SimpleAssetType } from "@/enums";

import VolumeControl from "@/components/AudioCard/VolumeControl";
import TimeField from "@/components/TimeField";

import { useEditorHistory } from "@/views/editor/EditorHistoryContext";
import { HistoryActionTypes } from "@/views/editor/EditorHistoryReducer";
import {
  AudioAssetTimeMetadata,
  TimeUpdateMetadataTypes,
  audioAssetTimeUpdateCommand,
  mediaEndTimeUpdate,
  mediaFullLengthTimeUpdateCommand,
  mediaStartTimeUpdate,
  updateAudioAssetVolumeCommand,
} from "@/views/editor/commands/mediaCommand";
import { EDITOR_MEDIA_LOAD_STATE } from "@/views/editor/constant";

import { MediaCard } from "../MediaCard";

type AppliedMediaCardProps = {
  assetId: string | number;
  assetUrl: string;
  assetType: SimpleAssetType;
  startTime: number;
  endTime: number;
  isFullLength?: boolean;
  mediaVolume?: number;
  assetName?: string;
};
type TimeEntity = "start" | "end";

type TimeFieldComponentProps = {
  value: number;
  isVideoCard?: boolean;
  isAudioCard?: boolean;
  assetId: string | number;
  inputType: TimeEntity;
  assetDuration?: number;
  isFullLength?: boolean;
};

type MediaCardQuickActionSectionProps = {
  isVideoCard: boolean;
  isAudioCard: boolean;
  isFullLength?: boolean;
  removeAsset: () => void;
  handleIsFullLengthUpdate: (value: boolean) => void;
  mediaVolume?: number;
  handleAudioVolumeUpdate?: (volume: number, oldVolume: number) => void;
};

const TimeFieldComponent: React.FC<TimeFieldComponentProps> = ({
  value,
  isVideoCard = false,
  assetId,
  inputType,
  isFullLength = false,
  isAudioCard = false,
}) => {
  const { onStartUpdate, onEndUpdate, audioAssetTimeUpdate } =
    useOutletContext<any>();

  const oldValue = value;

  const { editorDispatch } = useEditorHistory();

  const mediaStartUpdateCommand = (metaData: TimeUpdateMetadataTypes) => {
    const command = mediaStartTimeUpdate(metaData, onStartUpdate);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const mediaEndUpdateCommand = (metaData: TimeUpdateMetadataTypes) => {
    const command = mediaEndTimeUpdate(metaData, onEndUpdate);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handleAudioAssetTimeUpdate = (metaData: AudioAssetTimeMetadata) => {
    const command = audioAssetTimeUpdateCommand(metaData, audioAssetTimeUpdate);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const handleTimeChange = (ent: TimeEntity) => (value: string) => {
    const newTimeInSec = getSecondsFromHMSM(value);

    if (typeof newTimeInSec === "undefined") {
      return;
    }
    const newTime = newTimeInSec * 1000 + currentSelectedMicroContent.start;

    const setter = {
      start: mediaStartUpdateCommand,
      end: mediaEndUpdateCommand,
    };

    if (
      newTime >= currentSelectedMicroContent.start &&
      newTime <= currentSelectedMicroContent.end
    ) {
      if (isAudioCard) {
        const AudioTimeMetaData: AudioAssetTimeMetadata = {
          oldTime: {
            [ent]: oldValue,
          },
          newTime: {
            [ent]: newTime,
          },
          id: assetId,
          makeFullVideoLength: isFullLength,
        };
        handleAudioAssetTimeUpdate(AudioTimeMetaData);
        return;
      }
      const TimeMetaData: TimeUpdateMetadataTypes = {
        oldTime: oldValue,
        newTime: newTime,
        id: assetId,
        isBRoll: true,
      };

      setter[ent](TimeMetaData);
      return newTimeInSec.toString();
    }
    return;
  };
  const timeDifference = (value - currentSelectedMicroContent.start) / 1000;

  return (
    <TimeField
      disabled={isFullLength || isVideoCard}
      time={formatSeconds(timeDifference > 0 ? timeDifference : 0)}
      handleTimeChange={handleTimeChange(inputType)}
      className={clsx(isVideoCard && "bg-gray-100 opacity-40 ", "w-28")}
    />
  );
};

const MediaCardQuickActionSection: React.FC<MediaCardQuickActionSectionProps> =
  memo(
    ({
      isVideoCard,
      isAudioCard,
      isFullLength = false,
      removeAsset,
      handleIsFullLengthUpdate,
      mediaVolume = 30,
      handleAudioVolumeUpdate,
    }) => {
      return (
        <>
          {isAudioCard && (
            <div className="ml-2.5 2xl:ml-5">
              <VolumeControl
                volume={mediaVolume}
                setVolume={(volume: number) => {
                  if (handleAudioVolumeUpdate)
                    handleAudioVolumeUpdate(volume, mediaVolume);
                }}
              />
            </div>
          )}
          <div className="ml-2.5 mt-4 flex items-center justify-between 2xl:ml-5">
            {isVideoCard ? (
              <div className="flex items-center space-x-1">
                <InformationCircleIcon className="w-4 text-gray-500" />
                <p className="text-xs text-gray-500">
                  Audio will be muted for this clip
                </p>
              </div>
            ) : (
              <div className="flex items-center space-x-1.5">
                <Checkbox
                  checked={isFullLength}
                  onChange={(e) => handleIsFullLengthUpdate(e.target.checked)}
                />
                <p className="text-xs text-gray-500">
                  {isAudioCard
                    ? "Add for entire duration"
                    : "Show for entire duration"}
                </p>
              </div>
            )}

            <TrashIcon
              className="w-4 cursor-pointer text-red-500"
              onClick={removeAsset}
            />
          </div>
        </>
      );
    }
  );

const AppliedMediaCard: React.FC<AppliedMediaCardProps> = ({
  assetId,
  assetUrl,
  assetType,
  startTime,
  endTime,
  isFullLength,
  mediaVolume,
  assetName,
}) => {
  const {
    handleRemoveAsset,
    removeAudioAsset,
    updateIsFullVideoLength,
    audioAssetVolumeUpdate,
    audioAssetTimeUpdate,
  } = useOutletContext<any>();

  const { editorDispatch } = useEditorHistory();

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const removeAsset = () => {
    PubSub.publish(EDITOR_MEDIA_LOAD_STATE, {
      id: assetId,
      status: EditorMediaStatus.NOT_ADDED,
    });
    if (assetType === SimpleAssetType.AUDIO) {
      removeAudioAsset(assetId);
      return;
    }
    handleRemoveAsset(assetId, false, true);
  };

  const handleAudioAssetTimeUpdate = (metaData: AudioAssetTimeMetadata) => {
    const command = audioAssetTimeUpdateCommand(metaData, audioAssetTimeUpdate);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handleMediaAssetFullLengthUpdate = (
    value: any,
    assetId: string | number
  ) => {
    const metaData = {
      id: assetId,
      newValue: {
        times: {
          start: value ? currentSelectedMicroContent.start : startTime,
          end: value ? currentSelectedMicroContent.end : endTime,
        },
        isFullVideoLength: value,
      },
      oldValue: {
        times: {
          start: startTime,
          end: endTime,
        },
        isFullVideoLength: !value,
      },
    };

    console.log(metaData);

    const command = mediaFullLengthTimeUpdateCommand(
      metaData,
      updateIsFullVideoLength
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const handleIsFullLengthUpdate = (value: boolean) => {
    if (assetType === SimpleAssetType.AUDIO) {
      const audioTimeMetaData: AudioAssetTimeMetadata = {
        oldTime: {
          start: startTime,
          end: endTime,
        },
        newTime: {
          start: currentSelectedMicroContent.start,
          end: currentSelectedMicroContent.end,
        },
        id: assetId,
        makeFullVideoLength: value,
      };

      handleAudioAssetTimeUpdate(audioTimeMetaData);
    }
    handleMediaAssetFullLengthUpdate(value, assetId);
  };

  const handleAudioVolumeUpdate = (mediaVolume: number, oldVolume: number) => {
    const metaData = {
      newVolume: mediaVolume,
      oldVolume,
      assetId,
    };

    const command = updateAudioAssetVolumeCommand(
      metaData,
      audioAssetVolumeUpdate
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const isVideoCard = assetType === SimpleAssetType.VIDEO;
  const isAudioCard = assetType === SimpleAssetType.AUDIO;

  return (
    <div className=" mb-6 flex w-full items-center rounded-md border p-3.5 shadow-sm  2xl:p-4">
      <MediaCard
        url={assetUrl}
        mediaType={assetType}
        mediaCardVariant="small"
        volume={mediaVolume}
        assetName={assetName}
      />
      <div className="flex flex-col rounded-lg ">
        <div className="mb-1 ml-2.5 flex items-center 2xl:ml-5">
          <TimeFieldComponent
            value={startTime}
            assetId={assetId}
            inputType="start"
            isFullLength={isFullLength}
            isAudioCard={isAudioCard}
          />

          <p className="mx-1 text-gray-400">-</p>
          <TimeFieldComponent
            value={endTime}
            isVideoCard={isVideoCard}
            assetId={assetId}
            inputType="end"
            isFullLength={isFullLength}
            isAudioCard={isAudioCard}
          />
        </div>
        <MediaCardQuickActionSection
          isVideoCard={isVideoCard}
          isAudioCard={isAudioCard}
          removeAsset={removeAsset}
          isFullLength={isFullLength}
          handleIsFullLengthUpdate={handleIsFullLengthUpdate}
          mediaVolume={mediaVolume}
          handleAudioVolumeUpdate={handleAudioVolumeUpdate}
        />
      </div>
    </div>
  );
};

export default memo(AppliedMediaCard);
