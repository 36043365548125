import {
  SimpleAssetType,
  SocialMediaId,
  SubtitleStyle,
  TextAlignment,
  VideoLayout,
} from "@/enums";

const BACKGROUND_ASSET_IMAGE = SimpleAssetType.IMAGE;
const SOCIAL_MEDIA_LOGO = SimpleAssetType.IMAGE;
const OUTRO_ASSET_IMAGE = SimpleAssetType.IMAGE;
const OUTRO_ASSET_VIDEO = SimpleAssetType.VIDEO;
const AUDIO_ASSET = SimpleAssetType.AUDIO;

export const BACKGROUND = "background";
export const OUTLINE = "outline";
export const SHADOW = "shadow";
export const NONE = "none";

export const DEFAULT_OUTRO_LENGTH_IN_SEC = 3;
export const DEFAULT_COLON_SEPARATED_TIME_IN_MILLIS = "00:00:00.000";

export const DEFAULT_STROKE_WIDTH = 3;
export const MIN_STROKE_WIDTH = 1;
export const MAX_STROKE_WIDTH = 20;
export const NO_STROKE_WIDTH = 0;
export const MIN_TEXTBOX_RADIUS = 0;
export const MAX_TEXTBOX_RADIUS = 10;
export const MAX_TEXTBOX_PADDING = 15;
export const MIN_TEXTBOX_PADDING = 0;
export const DEFAULT_BLOCK_BACKGROUND_RADIUS = 4;
export const DEFAULT_TEXT_PADDING = 3;
export const DEFAULT_TEXT_MARGIN = 0;
export const DEFAULT_BLOCK_BACKGROUND_PADDING = 0;
export const DEFAULT_TEXT_BACKGROUND_RADIUS = 0;
export const TEXT_TRANSFORM = {
  CAPITALIZE: "CAPITALIZE",
  UPPERCASE: "UPPERCASE",
  LOWERCASE: "LOWERCASE",
  DEFAULT: "DEFAULT",
};
export const DEFAULT_SHADOW_PROPERTIES = {
  offsetX: "0",
  offsetY: "1",
  blur: "10",
  nonScaling: true,
};
export const MIN_SHADOW_OFFSET = -50;
export const MAX_SHADOW_OFFSET = 50;
export const MIN_SHADOW_BLUR = 0;
export const MAX_SHADOW_BLUR = 25;
export const DEFAULT_BACKGROUND_COLOR = "transparent";

export const MIN_LINE_HEIGHT = 1;
export const MAX_LINE_HEIGHT = 3;

export const DEFAULT_PROGRESS_BAR_HEIGHT = 30;

export const EDITOR_MEDIA_LOAD_STATE = "EDITOR_MEDIA_LOAD_STATE";

export const editorSubRoutes = {
  TEMPLATES: "templates",
  SUBTITLES: "subtitles",
  ELEMENTS: "elements",
  TEXTS: "texts",
  BACKGROUND: "background",
  SCENE_CHANGE: "scene-change",
  HASHTAGS: "caption",
  MEDIA: "media",
};

export const fontFaces = [
  {
    label: "Montserrat",
    value: "Montserrat",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Open Sans",
    value: "Open Sans",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Poppins",
    value: "Poppins",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Montserrat Black",
    value: "Montserrat-Black",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: false,
  },
  {
    label: "Komika",
    value: "KOMIKAX_",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: true,
  },
  {
    label: "THEBOLDFONT",
    value: "THEBOLDFONT",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: true,
  },
  {
    label: "Balder Regular",
    value: "BalderLL",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: true,
  },
  {
    label: "Futura Extra Black",
    value: "Futura Extra Black font",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: false,
  },
  {
    label: "Helvetica",
    value: "HelveticaNowDisplay-Bold",
    boldAllowed: false,
    italicAllowed: true,
    underlineAllowed: false,
    uppercase: false,
  },
  {
    label: "Gothic Bold",
    value: "GOTHICB",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: false,
  },
  {
    label: "Roboto",
    value: "Roboto",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Bangers",
    value: "Bangers",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: true,
  },
  {
    label: "Bebas Neue",
    value: "Bebas Neue",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: true,
  },
  {
    label: "PT Sans",
    value: "PT Sans",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
  },
  {
    label: "Fredoka One",
    value: "Fredoka One",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: false,
  },
  {
    label: "Lato",
    value: "Lato",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Oswald",
    value: "Oswald",
    boldAllowed: true,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: false,
  },
  {
    label: "Russo One",
    value: "Russo One",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: false,
  },
  {
    label: "Averia Sans Libre",
    value: "Averia Sans Libre",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Goldman",
    value: "Goldman",
    boldAllowed: true,
    italicAllowed: false,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Sriracha",
    value: "Sriracha",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Koulen",
    value: "Koulen",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: false,
    uppercase: true,
  },
  {
    label: "League Spartan",
    value: "League Spartan",
    boldAllowed: true,
    italicAllowed: false,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Archivo Black",
    value: "Archivo Black",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Cabin",
    value: "Cabin",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Josefin Sans",
    value: "Josefin Sans",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Lora",
    value: "Lora",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Merriweather",
    value: "Merriweather",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Oxygen",
    value: "Oxygen",
    boldAllowed: true,
    italicAllowed: false,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Playfair Display",
    value: "Playfair Display",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Questrial",
    value: "Questrial",
    boldAllowed: false,
    italicAllowed: false,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Quicksand",
    value: "Quicksand",
    boldAllowed: true,
    italicAllowed: false,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Raleway",
    value: "Raleway",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Roboto Condensed",
    value: "Roboto Condensed",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Roboto Slab",
    value: "Roboto Slab",
    boldAllowed: true,
    italicAllowed: false,
    underlineAllowed: true,
    uppercase: false,
  },
  {
    label: "Source Serif Pro",
    value: "Source Serif Pro",
    boldAllowed: true,
    italicAllowed: true,
    underlineAllowed: true,
    uppercase: false,
  },
];

export const colorsObj = {
  black: "#000000",
  white: "#ffffff",
  yellow: "#ffe600",
  gray: "#808080",
};

export const INIT_TEXT_STYLES = {
  alignment: TextAlignment.CENTER,
  font_face: "Open Sans",
  font_size: 32,
  font_color: colorsObj.white,
  effect_type: NONE,
  effect_color: colorsObj.black,
  effect_transparency: 0,
  underline: false,
  italic: false,
  bold: false,
  line_height: MIN_LINE_HEIGHT,
  text_align: "center",
  noEffect: true,
  shadow: {
    enabled: false,
    color: colorsObj.black,
    config: {
      blur: DEFAULT_SHADOW_PROPERTIES.blur,
      offsetX: DEFAULT_SHADOW_PROPERTIES.offsetX,
      offsetY: DEFAULT_SHADOW_PROPERTIES.offsetY,
    },
  },
  stroke: {
    enabled: false,
    color: colorsObj.black,
    config: {
      width: DEFAULT_STROKE_WIDTH,
    },
  },
  textBgColor: {
    enabled: false,
    color: colorsObj.black,
    radius: DEFAULT_TEXT_BACKGROUND_RADIUS,
  },
  blockBackground: {
    enabled: false,
    color: colorsObj.gray,
    radius: DEFAULT_BLOCK_BACKGROUND_RADIUS,
    padding: DEFAULT_BLOCK_BACKGROUND_PADDING,
  },
  padding: {
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
  },
  margin: {
    right: 0,
    left: 0,
    top: 0,
    // acts as line-gap
    bottom: 0,
  },
  textTransform: TEXT_TRANSFORM.LOWERCASE,
};

export const INIT_SUBS_CONFIG = {
  coordinate_left: 0,
  coordinate_top: 0,
  video_width: 0,
  video_height: 0,
  style: {
    type: SubtitleStyle.DEFAULT,
    color: colorsObj.yellow,
  },
  ...INIT_TEXT_STYLES,
};

export const INIT_SUB_EMOJI_CONFIG = {
  top: 0,
  left: 0,
  fontSize: 40,
  maxHeight: 40,
  width: 40,
};

export const SOCIAL_MEDIA_HANDLES = [
  {
    url: "https://storage.googleapis.com/vidyo-ai-static/preload-images/youtube_logo.png",
    id: SocialMediaId.YOUTUBE,
    textContent: "@Youtube",
    labelName: "YouTube Handle",
    placeHolder: "Eg. John's Workshop",
    type: SOCIAL_MEDIA_LOGO,
    showAtOnboarding: true,
  },
  {
    url: "https://storage.googleapis.com/vidyo-ai-static/preload-images/fb_logo.png",
    id: SocialMediaId.FACEBOOK,
    textContent: "@Facebook",
    labelName: "Facebook Handle",
    placeHolder: "Eg. John Doe",
    type: SOCIAL_MEDIA_LOGO,
    showAtOnboarding: true,
  },
  {
    url: "https://storage.googleapis.com/vidyo-ai-static/preload-images/insta_logo.png",
    id: SocialMediaId.INSTAGRAM,
    textContent: "@Instagram",
    labelName: "Instagram Handle",
    placeHolder: "Eg. john_doe",
    type: SOCIAL_MEDIA_LOGO,
    showAtOnboarding: true,
  },
  {
    url: "https://storage.googleapis.com/vidyo-ai-static/preload-images/linkedin_logo.png",
    id: SocialMediaId.LINKEDIN,
    textContent: "LinkedIn",
    labelName: "LinkedIn Handle",
    placeHolder: "Eg. John Doe",
    type: SOCIAL_MEDIA_LOGO,
    showAtOnboarding: true,
  },
  {
    url: "https://storage.googleapis.com/vidyo-ai-static/preload-images/twitter_logo.png",
    id: SocialMediaId.TWITTER,
    textContent: "@Twitter",
    labelName: "Twitter Handle",
    placeHolder: "Eg. @JohnDoe",
    type: SOCIAL_MEDIA_LOGO,
    showAtOnboarding: true,
  },
  {
    url: "https://storage.googleapis.com/vidyo-ai-static/preload-images/4362958_tiktok_logo_social%20media_icon.png",
    id: SocialMediaId.TIKTOK,
    textContent: "@TikTok",
    labelName: "Tiktok Handle",
    placeHolder: "Eg. @JohnDoe",
    type: SOCIAL_MEDIA_LOGO,
    showAtOnboarding: true,
  },
  {
    url: "https://storage.googleapis.com/vidyo-ai-static/preload-images/snapchat-square-color-icon.png",
    id: SocialMediaId.SNAPCHAT,
    textContent: "snap_chat",
    labelName: "Snap Handle",
    placeHolder: "Eg. snap_chat",
    type: SOCIAL_MEDIA_LOGO,
    showAtOnboarding: true,
  },
  {
    url: "https://storage.googleapis.com/vidyo-ai-static/preload-images/twitch-6860918_640.png",
    id: SocialMediaId.TWITCH,
    textContent: "Twitch",
    labelName: "Twitch Handle",
    placeHolder: "Eg. JohnDoe",
    type: SOCIAL_MEDIA_LOGO,
    showAtOnboarding: true,
  },
  {
    url: "https://storage.googleapis.com/vidyo-ai-static/preload-images/pinterest-circle.png",
    id: SocialMediaId.PINTEREST,
    textContent: "@Pinterest",
    labelName: "Pinterest Handle",
    placeHolder: "Eg. @JohnDoe",
    type: SOCIAL_MEDIA_LOGO,
    showAtOnboarding: false,
  },
];

export const SOCIAL_MEDIA_HANDLES_MAP = {
  [SocialMediaId.YOUTUBE]: "YouTube Handle",
  [SocialMediaId.FACEBOOK]: "Facebook Handle",
  [SocialMediaId.INSTAGRAM]: "Instagram Handle",
  [SocialMediaId.LINKEDIN]: "LinkedIn Handle",
  [SocialMediaId.TWITTER]: "Twitter Handle",
  [SocialMediaId.TIKTOK]: "Tiktok Handle",
};

export const VIDEO_OUTRO = {
  [VideoLayout.LAYOUT_16_9]: [
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/1.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-1",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/2.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-2",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/3.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-3",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/4.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-4",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/5.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-5",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Horizontal%201.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-6",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Horizontal%202.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-7",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Horizontal%203.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-8",
    },
  ],
  [VideoLayout.LAYOUT_9_16_1]: [
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/P1.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p1",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/P2.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p2",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/P3.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p3",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/P4.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p4",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/P5.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p5",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Blue_Follow%20us%20to%20watch%20BW.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p6",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/vidyo-outro-2.jpeg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p7",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%201%20follow.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p8",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%201%20subscribe.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p9",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%202%20follow.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p10",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%202%20subscribe.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p11",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%203%20Follow.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p12",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%203%20subscribe.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p13",
    },
  ],
  [VideoLayout.LAYOUT_9_16_2]: [
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/P1.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p14",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/P2.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p15",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/P3.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p16",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/P4.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p17",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/P5.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p18",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Blue_Follow%20us%20to%20watch%20BW.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p19",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/vidyo-outro-2.jpeg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-p20",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%201%20follow.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p21",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%201%20subscribe.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p22",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%202%20follow.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p23",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%202%20subscribe.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p24",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%203%20Follow.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p25",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Outro%203%20subscribe.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-p26",
    },
  ],
  [VideoLayout.LAYOUT_1_1]: [
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/S1.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-s1",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/S2.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-s2",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/S3.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-s3",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/S4.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-s4",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/S5.jpg",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-s5",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Square_Blue_Follow%20us%20to%20watch%20BW.png",
      type: OUTRO_ASSET_IMAGE,
      uid: "editor-outro-s6",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/outros/Square%201.mp4",
      type: OUTRO_ASSET_VIDEO,
      uid: "editor-outro-s7",
    },
  ],
};

export const StockBackgrounds = {
  [VideoLayout.LAYOUT_16_9]: [
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/151d827a-5031-4da3-a0a3-22283e11749b.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "O6WZC5uLl8K_BLZRunoUF",
    },
  ],
  [VideoLayout.LAYOUT_9_16_1]: [
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/bfd9ddfc-f38e-4a5d-85df-8a128bf518cf.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "5uwHp8nDHm0V3lYU3yUtk",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/2295cd81-b2ab-4d8d-91a9-ffa57198d102.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "AbTY59k_A1Jpc3U4uDwRP",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/7bcf713c-10bf-4003-8553-5989aaf31a4d.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "JpnXDGcLnIb2lmELWVHtI",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/09050b29-14bb-4b8f-803a-928aab96d381.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "YHJNGN4iHWJC13p6cnyGr",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/bb4d2475-0644-48d9-8dfe-2dc7416b30cf.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "cHXF_C6czQ-jKi4k7G8Vc",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/10a0e925-16f8-4ed3-aace-9c379f26258f.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "fg5_YR0al3j0Xszy4iD3m",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/98d331c1-88f9-47b7-97e9-6dec321b6ec7.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "oDvaM-DjSRp6gmDpOAl2j",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/2b15412c-6c2d-4f12-912c-d651d0095a6c.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "q99QxrErKciMXyFtnHJnk",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/b380af91-802d-492f-b136-021f9bb67b51.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "unPT9EQY1MToLovEnSUMk",
    },
  ],
  [VideoLayout.LAYOUT_9_16_2]: [
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/d192b9eb-80dc-4438-a2b2-fbe279f037c6.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "9jCNcfY44s6X7xc2SiebU",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/12cac6c7-f776-404d-9a70-0600719f9e4c.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "UMCPIRdFJvmqa2zYbAsEc",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/c5f6e2c4-a91b-4d94-834c-7c7557aa25d1.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "W2DhOTvOgG8hOSd6arRYL",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/7a933d99-89a8-427e-8d07-d9769f467383.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "eKPFvq6Y7xIoxi0rRMgwY",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/7a933d99-89a8-427e-8d07-d9769f467383.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "xcANsDF7pJVj1nFmLCWL_",
    },
  ],
  [VideoLayout.LAYOUT_1_1]: [
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/99b667af-e80a-41a0-944b-a8eb72002fb3.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "AgejDYyEmiO3rzJFJW4Oy",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/whizai-design.png",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "MIuxrck18RNCi9TFGPey8",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/98b74f68-3695-4ce2-85f4-ba687789a315.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "X5WA6DYzeq3vVXx4rFoUV",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/b8517e6f-dfa4-4325-9f1c-e45bc82d605b.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "wUupgrtYDdR8w__YDSGff",
    },
    {
      url: "https://storage.googleapis.com/vidyo-ai-static/backgrounds/a812f11b-952c-4517-ba73-ecf6d034385c.jpeg",
      type: BACKGROUND_ASSET_IMAGE,
      uid: "yogWcjVmOtfFsQuhvuKaX",
    },
  ],
};

export const VidyoAICustomFonts = [
  {
    remote_url:
      "https://storage.googleapis.com/vidyo-bucket/font/81f042d2-cdb2-4ee7-a10d-ebb9cbe0874b.ttf",
    asset_name: "Montserrat-Black",
  },
  {
    remote_url:
      "https://storage.googleapis.com/vidyo-bucket/font/72e7c354-23f4-4e33-9c29-352ffe5b09b5.ttf",
    asset_name: "THEBOLDFONT",
  },
  {
    remote_url:
      "https://storage.googleapis.com/vidyo-bucket/font/d32c9011-40bc-4802-9fed-7de91dc73471.ttf",
    asset_name: "BalderLL",
  },
  {
    remote_url:
      "https://storage.googleapis.com/vidyo-bucket/font/4a4222e1-8137-4db7-8e93-e0e7952f290d.ttf",
    asset_name: "Futura Extra Black font",
  },
  {
    remote_url:
      "https://storage.googleapis.com/vidyo-bucket/font/69990227-de46-4ece-bb12-18891be9469a.ttf",
    asset_name: "HelveticaNowDisplay-Bold",
  },
  {
    remote_url:
      "https://storage.googleapis.com/vidyo-bucket/font/d2b696be-a123-463d-92ec-88f51c7532d1.ttf",
    asset_name: "GOTHICB",
  },
  {
    remote_url:
      "https://storage.googleapis.com/vidyo-bucket/font/c0c800cc-d030-45cb-a273-2a4059c30e89.ttf",
    asset_name: "KOMIKAX_",
  },
];

export const MIN_THRESHOLD_BETWEEN_SUBTITLES = 0.5; // seconds, we don't want to remove subtitles if the gap between them is less than this

export const STOCK_AUDIO_ASSETS = [
  {
    url: "https://storage.googleapis.com/vidyo-bucket-stage/music/abstract-fashion-pop-131283.mp3",
    type: AUDIO_ASSET,
    id: "editor-audio-1",
  },
  {
    url: "https://storage.googleapis.com/vidyo-bucket-stage/music/Future-Glider-Brian-Bolger.mp3",
    type: AUDIO_ASSET,
    id: "editor-audio-2",
  },
  {
    url: "https://storage.googleapis.com/vidyo-bucket-stage/music/whip-110235.mp3",
    type: AUDIO_ASSET,
    id: "editor-audio-3",
  },
  {
    url: "https://storage.googleapis.com/vidyo-bucket-stage/music/Coast-Anno-Domini-Beats.mp3",
    type: AUDIO_ASSET,
    id: "editor-audio-4",
  },
];

export const SAFE_PADDING_FOR_TEXT = 200;
export const NUMBER_TO_PREVENT_TEXT_BLEED = 10;
export const INTRO_OUTRO = "intro-outro";
