import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { saveValueToLocalStorage } from "@/helpers";

import Banner from "@/components/Banner/Banner";

import VideoAILogo from "@/assets/images/video_ai_logo.png";

type HeaderTypes = {};

const AuthHeader: FC = (props: HeaderTypes) => {
  const navigate = useNavigate();

  const onLogoClick = () => {
    saveValueToLocalStorage("homePageOffset", 0);
    console;
    navigate("/");
  };

  return (
    <>
      <header className="bg-white dark:bg-gray-700 z-[60] relative">
        <nav className="border-gray-200 bg-white px-4 py-4 dark:bg-gray-800 lg:px-6">
          <div className="mx-auto grid max-w-screen-2xl grid-cols-2 items-center">
            <div
              className="col-span-3 flex w-full items-center justify-between lg:order-1 lg:col-span-1 lg:w-auto"
              id="mobile-menu-2"
            >
              <ul className="mt-4 flex flex-col  items-center lg:mt-0 lg:flex-row lg:space-x-8">
                <li>
                  <p
                    onClick={onLogoClick}
                    className="flex cursor-pointer items-center lg:order-2 lg:justify-center"
                  >
                    <img
                      src={VideoAILogo}
                      className="mr-1 h-6 sm:h-6"
                      alt="VidyoAI Logo"
                    />
                    <span className="self-center whitespace-nowrap text-lg font-semibold dark:text-white">
                      vidyo.ai
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default AuthHeader;
