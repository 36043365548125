import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "flowbite-react";

import { INTELLI_CLIP } from "@/constants";

export const IntelliClipIndicator = (): JSX.Element => {
  return (
    <div className="flex gap-2">
      <div className=" flex items-center gap-1">
        <div className="font-normal text-gray-500 text-sm">{INTELLI_CLIP}</div>
        <Tooltip
          content={`${INTELLI_CLIP}: Best bits of a video and combines them into short clips.`}
          placement="top"
        >
          <InformationCircleIcon className="w-5" />
        </Tooltip>
      </div>
    </div>
  );
};
