import { toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppDispatch } from "@/store/hooks";

import { ANALYTICS_CONSTANTS } from "@/constants/amplitude";

import { eventsDataToRedux } from "@/utils/amplitudeAnalytcs";

import { BrandKitMenu } from "@/enums";

import ProFeatureImg from "@/assets/images/pro-only-template.svg";

const ProOnlyFeature = ({ assetTypeText }: { assetTypeText: string }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="col-span-2 mt-28 px-6 text-center text-sm text-[#5E6473]">
      <img
        src={ProFeatureImg}
        className="mx-auto"
      />
      {assetTypeText === BrandKitMenu.COLOR_PALETTE ? (
        <p className="mx-auto mt-4 mb-6 w-96">
          <span className="font-semibold">Colors palette</span> helps you save
          your brand color palette in one place for easy access.
        </p>
      ) : (
        <p className="mx-auto mt-4 mb-6 w-96">
          <span className="font-semibold">Custom fonts</span> helps you maintain
          brand guidelines when creating videos on our platform
        </p>
      )}

      {/*
      <p className=" mt-4 mb-1">
        Click on the <span className="font-semibold">“Save Template”</span>{" "}
        button in the editor to save a template and then view it here.
      </p> */}

      <p className="mb-4">
        This feature is available for{" "}
        <span className="font-semibold">PRO users</span> only.
      </p>

      <button
        type="button"
        id="upgrade-now-templates-tab"
        className={`my-6 mx-auto flex w-40 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300`}
        onClick={() => {
          dispatch(toggleUpgradeToProModal(true));
          eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
        }}
      >
        Upgrade now
      </button>
    </div>
  );
};

export default ProOnlyFeature;
