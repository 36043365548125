import { EditorCommandType } from "@/constants/editor-commands";

import {
  SelectionHandle,
  VideoPosition,
  VideoPositionAfterCanvasModification,
} from "@/interfaces";

import { createCommand, Command } from "@/views/editor/EditorHistoryReducer";

export const changeClipStartOrEndTimeCommand = (
  metaData: {
    newClipTimeMetadata: {
      startTime: number;
      endTime: number;
      selectionHandle: SelectionHandle;
    };
    oldClipTimeMetadata: {
      startTime: number;
      endTime: number;
      selectionHandle: SelectionHandle;
    };
  },
  updateClipLength: (clipTime: any) => void
): Command => {
  const { newClipTimeMetadata, oldClipTimeMetadata } = metaData;
  return createCommand({
    executeAction: () => updateClipLength(newClipTimeMetadata),
    undoAction: () => updateClipLength(oldClipTimeMetadata),
    commandType: EditorCommandType.TrimClip,
  });
};

export const changeClipAspectRatio = (
  metaData: any,
  updateClipAspectRatio: (clipAspectRatio: any) => void
): Command => {
  const { newClipAspectRatio, oldClipAspectRatio } = metaData;
  return createCommand({
    executeAction: () => updateClipAspectRatio(newClipAspectRatio),
    undoAction: () => updateClipAspectRatio(oldClipAspectRatio),
  });
};

export const adjustVideoPositionOnCanvasCommand = (
  metaData: VideoPositionAfterCanvasModification,
  updateVideoPositionOnCanvas: (
    videoPosition: VideoPosition,
    isBottomVideo: boolean
  ) => void
): Command => {
  const {
    currentVideoPosition,
    prevVideoPosition,
    isBottomVideo = false,
  } = metaData;
  return createCommand({
    executeAction: () =>
      updateVideoPositionOnCanvas(currentVideoPosition, isBottomVideo),
    undoAction: () =>
      updateVideoPositionOnCanvas(prevVideoPosition, isBottomVideo),
    commandType: EditorCommandType.AdjustVideoElementOnCanvas,
  });
};
