import { useState } from "react";
import ReactTooltip from "react-tooltip";

import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { Spinner } from "flowbite-react";
import parseSRT from "parse-srt";

import {
  updateCurrentSelectedMicroContent,
  updateShowPreviewClipsSideModal,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetUserTemplates from "@/api/useGetUserTemplates";

import { EDITOR_TEMPLATE_DIMENSIONS } from "@/constants";

import { reverseArrayForPreferredTemplates } from "@/helpers";
import { updateTemplateDefaultTexts } from "@/helpers/text";

import { EditorAppliedTemplate } from "@/interfaces";

import { TemplateTabVariant, TemplateVariant, VideoLayout } from "@/enums";

import TemplatesEmpty from "@/views/home/components/TemplatePreviewSideMenu/TemplatesEmpty";
import TemplateStaticPreview from "@/views/home/components/TemplateStaticPreview";

const templateContainerStyles = {
  [VideoLayout.LAYOUT_16_9]: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: "2vw",
    gridAutoRows: "minmax(10vh, auto)",
    margin: "2vh 0",
  },
  [VideoLayout.LAYOUT_1_1]: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: "2vw",
    gridAutoRows: "minmax(10vh, auto)",
    margin: "2vh 0",
  },
  [VideoLayout.LAYOUT_9_16_1]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "2vw",
    gridAutoRows: "minmax(10vh, auto)",
    margin: "2vh 0",
  },
  [VideoLayout.LAYOUT_9_16_2]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "2vw",
    gridAutoRows: "minmax(10vh, auto)",
    margin: "2vh 0",
  },
};

export const TemplatesTab = ({
  setActivePreviewTab,
}: {
  setActivePreviewTab: (value: string | null) => void;
}) => {
  const dispatch = useAppDispatch();

  const [currentSelectedTab, setCurrentSelectedTab] = useState(
    TemplateTabVariant.DEFAULT_TEMPLATE
  );

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const currentSelectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );

  const isUserChangedLayout = useAppSelector(
    (state) => state.editorState.isUserChangedLayout
  );

  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const enableSceneChange = useAppSelector(
    (state) => state.editorState.enableSceneChange
  );

  const userTemplateData = useGetUserTemplates(uid);
  const defaultBaseTemplates = useGetDefaultBaseTemplates();

  const getBaseTemplates = (layout: VideoLayout) => {
    if (
      currentSelectedTab === TemplateTabVariant.USER_TEMPLATE &&
      userTemplateData?.data
    ) {
      if (Object.keys(userTemplateData?.data).includes(layout)) {
        return Object.values(userTemplateData?.data[layout]);
      } else {
        return [];
      }
    }

    if (
      defaultBaseTemplates?.data &&
      Object.keys(defaultBaseTemplates?.data).includes(layout)
    ) {
      // @ts-ignore
      let templates = Object.values(defaultBaseTemplates?.data[layout]);
      return templates;
    }
    return [];
  };

  const handelApplyTemplate = (template: any) => {
    let currentAppliedTemplate: any = getBaseTemplates(
      currentSelectedLayout
    ).filter((item: any) => item.id === currentSelectedMicroContent.id)[0];

    if (currentAppliedTemplate?.id !== template.id) {
      const templateData = {
        id: template.id,
        face_coord: template.face_coord || [],
        start: template.start,
        end: template.end,
        srt_string: template.srt_string,
        imageUrl: template.imageUrl,
        hasTwoFace: template.hasTwoFace,
        gist: template.gist,
        text: template.text,
        chapter_end: template.chapter_end,
        chapter_start: template.chapter_start,
        backgroundColor: template.backgroundColor,
        name: template.name || "",
        isDefault: template.isDefault || false,
        // carry forward clip properties
        userClipRateExist: template.userClipRateExist,
        bookmarked: template.bookmarked,
        seen: template.seen,
        tag: template.tag,
        clip_src: template.clip_src,
      };
      dispatch(updateCurrentSelectedMicroContent(templateData));
    }
  };

  const getTemplatesForSelectedLayout = (layout: VideoLayout) => {
    const finalTemplateArr: EditorAppliedTemplate[] = [];

    let currentAppliedTemplate: any = getBaseTemplates(layout).filter(
      (item: any) => item.id === currentSelectedMicroContent.id
    )[0];

    currentAppliedTemplate = {
      ...currentAppliedTemplate,
      imageUrl: currentSelectedMicroContent.imageUrl,
      srt_string: currentSelectedMicroContent.srt_string,
      hasTwoFace: currentSelectedMicroContent.hasTwoFace,
      texts: currentAppliedTemplate?.texts.length
        ? updateTemplateDefaultTexts(
            currentAppliedTemplate.texts,
            currentSelectedMicroContent.gist
          )
        : [],
      gist: currentSelectedMicroContent.gist,
      face_coord: currentSelectedMicroContent.face_coord,
      start: currentSelectedMicroContent.start,
      end: currentSelectedMicroContent.end,
      chapter_end: currentSelectedMicroContent.chapter_end,
      chapter_start: currentSelectedMicroContent.chapter_start,
      text: currentSelectedMicroContent.text,
      // carry forward clip properties
      userClipRateExist: currentSelectedMicroContent.userClipRateExist,
      bookmarked: currentSelectedMicroContent.bookmarked,
      seen: currentSelectedMicroContent.seen,
      tag: currentSelectedMicroContent.tag,
      clip_src: currentSelectedMicroContent.clip_src,
    };

    getBaseTemplates(layout).forEach((template: any) => {
      if (isUserChangedLayout) {
        const customTemplate = {
          ...template,
          imageUrl: currentAppliedTemplate.imageUrl,
          srt_string: currentAppliedTemplate.srt_string,
          hasTwoFace: false,
          texts: template?.texts.length
            ? updateTemplateDefaultTexts(
                template.texts,
                currentAppliedTemplate.gist
              )
            : [],
          gist: currentAppliedTemplate.gist,
          face_coord: currentAppliedTemplate.face_coord,
          start: currentAppliedTemplate.start,
          end: currentAppliedTemplate.end,
          chapter_end: currentAppliedTemplate.chapter_end,
          chapter_start: currentAppliedTemplate.chapter_start,
          text: currentSelectedMicroContent.text,
          subtitle: {
            ...template.subtitle,
            text: parseSRT(currentAppliedTemplate.srt_string)?.length
              ? parseSRT(currentAppliedTemplate.srt_string)[0].text
              : "",
          },
          // carry forward clip properties
          userClipRateExist: currentSelectedMicroContent.userClipRateExist,
          bookmarked: currentSelectedMicroContent.bookmarked,
          seen: currentSelectedMicroContent.seen,
          tag: currentSelectedMicroContent.tag,
          clip_src: currentSelectedMicroContent.clip_src,
        };
        finalTemplateArr.push(customTemplate);
      } else {
        const customTemplate = {
          ...template,
          imageUrl: currentAppliedTemplate.imageUrl,
          srt_string: currentAppliedTemplate.srt_string,
          hasTwoFace:
            currentSelectedMicroContent?.face_coord?.length > 1 ? true : false,
          texts: template?.texts.length
            ? updateTemplateDefaultTexts(
                template.texts,
                currentAppliedTemplate.gist
              )
            : [],
          gist: currentAppliedTemplate.gist,
          face_coord: currentAppliedTemplate.face_coord,
          start: currentAppliedTemplate.start,
          end: currentAppliedTemplate.end,
          chapter_end: currentAppliedTemplate.chapter_end,
          chapter_start: currentAppliedTemplate.chapter_start,
          text: currentSelectedMicroContent.text,
          subtitle: {
            ...template.subtitle,
            text: parseSRT(currentAppliedTemplate.srt_string)?.length
              ? parseSRT(currentAppliedTemplate.srt_string)[0].text
              : "",
          },
          // carry forward clip properties
          userClipRateExist: currentSelectedMicroContent.userClipRateExist,
          bookmarked: currentSelectedMicroContent.bookmarked,
          seen: currentSelectedMicroContent.seen,
          tag: currentSelectedMicroContent.tag,
          clip_src: currentSelectedMicroContent.clip_src,
        };

        finalTemplateArr.push(customTemplate);
      }
    });

    return reverseArrayForPreferredTemplates(finalTemplateArr);
  };

  return (
    <div
      className="absolute h-full w-[27%] bg-[#F3F4F6] top-0 right-0 overflow-auto z-10"
      style={{ boxShadow: "-5px 0 10px -10px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="flex px-4 pt-6 pb-4 relative">
        <div>
          <p className="text-xl font-semibold">Change Template</p>
        </div>
        <div className="absolute right-9 top-7">
          <XMarkIcon
            onClick={() => {
              dispatch(updateShowPreviewClipsSideModal(false));
              setActivePreviewTab(null);
            }}
            className="cursor-pointer"
            height={22}
            width={22}
          />
        </div>
      </div>
      <div className="sticky top-0 z-20 w-full p-2  bg-[#F3F4F6]">
        <ul className="-mb-px flex w-full transition-transform">
          <li
            className={clsx(
              "w-[50%] cursor-pointer whitespace-nowrap text-center  rounded-l-md",
              currentSelectedTab === TemplateTabVariant.DEFAULT_TEMPLATE
                ? `bg-[#C3DDFD]`
                : `bg-white`
            )}
            onClick={() => {
              setCurrentSelectedTab(TemplateTabVariant.DEFAULT_TEMPLATE);
            }}
          >
            <p className="inline-block p-3">All Templates</p>
          </li>

          <li
            className={clsx(
              "w-[50%] cursor-pointer select-none whitespace-nowrap text-center rounded-r-md",
              currentSelectedTab === TemplateTabVariant.USER_TEMPLATE
                ? `bg-[#C3DDFD]`
                : `bg-white`
            )}
            onClick={() => {
              setCurrentSelectedTab(TemplateTabVariant.USER_TEMPLATE);
            }}
            data-tip={
              enableSceneChange
                ? "This feature is unavailable when using CutMagic"
                : ""
            }
          >
            <p className="inline-block p-3">My Templates</p>
            <ReactTooltip
              effect="solid"
              padding="8px"
              arrowColor="transparent"
              className="bg-gray-900 text-sm text-white"
            />
          </li>
        </ul>
      </div>

      {userTemplateData.isFetching ||
      defaultBaseTemplates.isFetching ||
      defaultBaseTemplates.isLoading ||
      userTemplateData.isLoading ? (
        <div className="mt-60 flex justify-center">
          <div>
            <Spinner className="mx-auto h-8 w-8" />
          </div>
        </div>
      ) : (
        <>
          {
            <div
              style={templateContainerStyles[currentSelectedLayout]}
              className="justify-items-center p-2 pt-0"
            >
              <>
                {" "}
                {getTemplatesForSelectedLayout(currentSelectedLayout)
                  ?.length ? (
                  getTemplatesForSelectedLayout(currentSelectedLayout).map(
                    (template) => (
                      <TemplateStaticPreview
                        template={template}
                        key={template.id}
                        handelChangeTemplate={handelApplyTemplate}
                        variant={TemplateVariant.SMALL}
                        dimensions={
                          EDITOR_TEMPLATE_DIMENSIONS[currentSelectedLayout]
                        }
                        layout={currentSelectedLayout}
                        showDuration={false}
                      />
                    )
                  )
                ) : (
                  <TemplatesEmpty />
                )}
              </>
            </div>
          }
        </>
      )}
    </div>
  );
};
