import { useDispatch } from "react-redux";

import { useSelectedClips } from "@/context/ReviewClips";
import clsx from "clsx";

import { updateCaption } from "@/store/editorSlice";

import { ANALYTICS_CONSTANTS, OVERVIEW_CONSTANT } from "@/constants/amplitude";

import { eventsDataToRedux } from "@/utils/amplitudeAnalytcs";

import { PreviewScreenClipTypes, VideoLayout } from "@/enums";

type TabProps = {
  isActive: boolean;
  label: string;
  onClick: () => void;
  count?: number;
  disabled?: boolean;
};

type TabListProps = {
  currentType: PreviewScreenClipTypes;
  setCurrentType: (value: PreviewScreenClipTypes) => void;
  microContent: any;
  onVideoClipChange: (clip: any, layout: VideoLayout, key: string) => void;
};

const Tab = ({ isActive, label, onClick, count, disabled }: TabProps) => (
  <div
    className={clsx(
      "h-12 flex flex-1 items-center justify-center border-r   text-sm",
      isActive ? "bg-[#C3DDFD]" : "bg-white",
      disabled ? "opacity-75" : "cursor-pointer"
    )}
    onClick={() => {
      disabled ? null : onClick();
    }}
  >
    {count ? (
      <span
        className={clsx(
          "h-5 w-8 text-xs flex items-center justify-center mr-2 rounded-lg",
          isActive ? "bg-[#1E429F] text-white" : "bg-[#E1EFFE] text-[#1E429F]"
        )}
      >
        {count}
      </span>
    ) : null}
    {label}
  </div>
);

const TabList = ({
  currentType,
  setCurrentType,
  microContent,
  onVideoClipChange,
}: TabListProps) => {
  const { selectModeOn } = useSelectedClips();
  const dispatch = useDispatch();

  // on tab change, set the first clip of that type as active
  const setActiveClip = (clipType: string) => {
    const firstClip = microContent[clipType]?.[0];
    if (!firstClip) return;
    const layout =
      clipType === PreviewScreenClipTypes.CHAPTERS
        ? VideoLayout.LAYOUT_16_9
        : firstClip.hasTwoFace
        ? VideoLayout.LAYOUT_9_16_2
        : VideoLayout.LAYOUT_9_16_1;

    onVideoClipChange(firstClip, layout, firstClip.clipId);
  };

  return (
    <div className="flex border rounded-l-md rounded-r-md">
      {microContent?.chapters?.length ? (
        <Tab
          label="Chapters"
          count={microContent?.chapters?.length}
          isActive={currentType === PreviewScreenClipTypes.CHAPTERS}
          onClick={() => {
            eventsDataToRedux(
              ANALYTICS_CONSTANTS.OVERVIEW_TYPE,
              OVERVIEW_CONSTANT.CHAPTERS
            );
            setCurrentType(PreviewScreenClipTypes.CHAPTERS);
            // clear AI Description on clip type change
            dispatch(updateCaption({}));
            setActiveClip(PreviewScreenClipTypes.CHAPTERS);
          }}
          disabled={selectModeOn}
        />
      ) : null}

      {microContent?.preview_data?.length ? (
        <Tab
          label="Shorts"
          count={microContent?.preview_data?.length}
          isActive={currentType === PreviewScreenClipTypes.SHORTS}
          disabled={selectModeOn}
          onClick={() => {
            eventsDataToRedux(
              ANALYTICS_CONSTANTS.OVERVIEW_TYPE,
              OVERVIEW_CONSTANT.SHORTS
            );
            setCurrentType(PreviewScreenClipTypes.SHORTS);
            setActiveClip(PreviewScreenClipTypes.SHORTS);
          }}
        />
      ) : null}

      <Tab
        label="Entire Video"
        isActive={currentType === PreviewScreenClipTypes.FULL_VIDEO}
        onClick={() => {
          setCurrentType(PreviewScreenClipTypes.FULL_VIDEO);
          eventsDataToRedux(
            ANALYTICS_CONSTANTS.OVERVIEW_TYPE,
            OVERVIEW_CONSTANT.FULL_VIDEO
          );
          setActiveClip(PreviewScreenClipTypes.FULL_VIDEO);
        }}
        disabled={selectModeOn}
      />
    </div>
  );
};

export default TabList;
