import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useClickAway,
  useToggle,
  useUnmount,
  useUpdateEffect,
} from "react-use";

import {
  ArrowDownTrayIcon,
  CheckIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useQueryClient } from "@tanstack/react-query";
import { Tooltip } from "flowbite-react";

import { updateCurrentSelectedMicroContent } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";
import useDeleteRenderedVideo from "@/api/useDeleteRenderedVideo";
import useGetDraftById from "@/api/useGetDraftById";

import { EXPORT_PREFERENCE } from "@/constants/amplitude";

import {
  downloadS3Url,
  getPresignedUrl,
  getValueFromLocalStorage,
  saveValueToLocalStorage,
  sleep,
} from "@/helpers";
import { applyCurrentDraft } from "@/helpers/draft";

import {
  eventsDataToRedux,
  trackClipExportEvent,
  trackEditClipEvent,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { DownloadedClip } from "@/interfaces";

import { PlanType, ScreenName, DownloadedClipType } from "@/enums";

import { ShareVideoModal } from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/ShareVideoModal";

const dimensions = {
  [DownloadedClipType.PORTRAIT]: { width: 252, height: 448 },
  [DownloadedClipType.SQUARE]: {
    width: 350,
    height: 350,
  },
  [DownloadedClipType.LANDSCAPE]: {
    width: 448,
    height: 252,
  },
  [DownloadedClipType.BULK]: {
    width: 448,
    height: 252,
  },
};

const DownloadVideoCard = ({
  downloadClip,
  clipType,
  setShowProFeatureDiscoveryModal,
  allDownloads,
}: {
  downloadClip: DownloadedClip;
  clipType: DownloadedClipType;
  setShowProFeatureDiscoveryModal: (show: boolean) => void;
  allDownloads: any[];
}) => {
  const {
    renderedVideoUrl,
    projectId,
    status,
    filename,
    render_task_id,
    clip_id,
  } = downloadClip;

  const params = new URLSearchParams(
    decodeURIComponent(window.location.search)
  );
  const code = params.get("code");

  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );

  const queryClient = useQueryClient();
  const { mutate } = useDeleteRenderedVideo();

  const [isLoading, setIsLoading] = useState(true);
  const [fetchDraft, toggleFetchDraft] = useToggle(false);
  const [showShareModal, setShowShareModal] = useState<any>({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);

  const [videoUrl, setVideoUrl] = useState<string>();
  const [deleteVideoModal, setDeleteVideoModal] = useState<boolean>(false);

  const pollAbortController = useRef<any>(null);
  const deleteModalRef = useRef<any>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    data: draftData,
    isLoading: renderDraftDataLoading,
    error,
  } = useGetDraftById(render_task_id, true, fetchDraft);

  const openShareModalId = getValueFromLocalStorage("open-share-modal-id");

  useEffect(() => {
    if (openShareModalId) {
      setShowShareModal({
        [openShareModalId]: true,
      });
    }
  }, []);

  const pollForRenderVideo = async (renderTaskId: string) => {
    try {
      let controller;
      if (!pollAbortController.current) {
        controller = new AbortController();
        pollAbortController.current = controller;
      } else {
        controller = pollAbortController.current;
      }

      const response: any = await api.get(ApiEndpoints.GET_RENDER_VIDEO, {
        params: {
          render_task_id: renderTaskId,
          project_id: projectId,
        },
        signal: controller.signal,
      });

      if (response.data.status === "submitted") {
        await sleep(10000);
        await pollForRenderVideo(renderTaskId);
      } else if (response.data.status === "complete") {
        queryClient.invalidateQueries({ queryKey: ["all-downloads"] });
      } else {
        queryClient.invalidateQueries({ queryKey: ["all-downloads"] });
      }
    } catch (e: any) {
      if (e?.message === "canceled") {
        pollAbortController.current = null;
      }
    }
  };

  useEffect(() => {
    if (status === "complete" && !videoUrl) {
      const getVideoUrl = async () => {
        const url = await getPresignedUrl(
          renderedVideoUrl,
          projectId,
          filename
        );

        setVideoUrl(url);
      };

      getVideoUrl();
    }
    if (status === "submitted") {
      pollForRenderVideo(render_task_id);
    }
  }, [status]);

  useEffect(() => {
    if (code) {
      setShowShareModal({
        [allDownloads[0].render_task_id]: true,
      });
    }
  }, [code]);

  useUnmount(() => {
    pollAbortController.current && pollAbortController.current.abort();
  });

  const handleDownload = async () => {
    downloadS3Url(renderedVideoUrl, filename, projectId);
    trackClipExportEvent(projectId, clip_id, EXPORT_PREFERENCE.DOWNLOAD);
  };

  useClickAway(deleteModalRef, () => {
    setDeleteVideoModal(false);
  });

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };

  const deleteVideoHandler = () => {
    mutate(render_task_id);
  };

  const handleShareClips = () => {
    setShowShareModal((prevState: any) => ({
      ...prevState,
      [render_task_id]: true,
    }));
    saveValueToLocalStorage("open-share-modal-id", render_task_id);
  };

  useUpdateEffect(() => {
    if (!fetchDraft) {
      return;
    }
    if (fetchDraft && planType === PlanType.FREE) {
      setShowProFeatureDiscoveryModal(true);
      toggleFetchDraft(false);
      return;
    }
    if (!renderDraftDataLoading && draftData === null) {
      showNotification("Draft not found!", notificationType.WARN);
      toggleFetchDraft(false);
      return;
    }
    if (!renderDraftDataLoading && draftData) {
      const templateData = {
        id: draftData.id,
        face_coord: draftData.face_coord || [],
        start: draftData.start,
        end: draftData.end,
        srt_string: "",
        imageUrl: draftData.imageUrl,
        hasTwoFace: draftData.hasTwoFace,
        gist: draftData.gist || "",
        text: "",
        chapter_end: draftData.chapter_end,
        chapter_start: draftData.chapter_start,
        backgroundColor: draftData?.backgroundColor,
        clipId: draftData?.clipId,
        tag: draftData?.tag,
        clip_src: draftData?.clip_src,
      };
      dispatch(updateCurrentSelectedMicroContent(templateData));

      applyCurrentDraft({ ...draftData, fromDownloadPage: true });

      toggleFetchDraft(false);

      trackEditClipEvent(draftData, ScreenName.DOWNLOADS);

      navigate(`/editor/${draftData.project.id}/templates`);
    }
  }, [draftData, renderDraftDataLoading, fetchDraft]);

  return (
    <div
      className="flex flex-col content-center justify-center gap-3 rounded-xl bg-white shadow-2xl"
      style={{
        width: dimensions[clipType].width,
      }}
    >
      {isLoading && (
        <div
          style={{
            width: dimensions[clipType].width,
            height: dimensions[clipType].height,
          }}
          className="flex flex-col items-center justify-center bg-gray-100"
        >
          <svg
            aria-hidden="true"
            className="mr-2 h-10 w-10 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          {!renderedVideoUrl && (
            <p className="mt-4 text-center text-sm text-gray-500">
              Please wait while we are preparing your clip...
            </p>
          )}
        </div>
      )}
      {videoUrl && (
        <div className="relative">
          <div className="relative">
            {deleteVideoModal && (
              <div
                style={{
                  width: dimensions[clipType].width,
                  height: dimensions[clipType].height,
                }}
                className="absolute rounded-xl bg-black opacity-60"
              ></div>
            )}

            <video
              style={{
                width: dimensions[clipType].width,
                height: dimensions[clipType].height,
                display: isLoading ? "none" : "block",
              }}
              className={`rounded-xl bg-gray-100`}
              src={`${videoUrl}${videoUrl ? "#t=0.1" : ""}`} //Safari hack
              onLoadedMetadata={() => setIsLoading(false)}
              preload="metadata"
              muted // Safari hack
              autoPlay={false} // Safari hack
              playsInline // Safari hack
              onClick={handleShareClips}
            />
          </div>
        </div>
      )}

      <p className="truncate px-2 text-gray-800">
        {filename?.replace(".mp4", "")}
      </p>

      <div className="p-2 flex items-center justify-between">
        {showDeleteConfirmation ? (
          <>
            <span className="text-base font-medium mr-5">Confirm Delete?</span>
            <button
              type="button"
              onClick={deleteVideoHandler}
              className={`inline-flex items-center justify-center  
          rounded bg-white border border-red-600 h-8 w-8 focus:outline-none mr-5 cursor-pointer`}
            >
              <CheckIcon className="w-5 h-5 text-red-700" />
            </button>
            <button
              type="button"
              onClick={handleDeleteCancel}
              className={`inline-flex items-center justify-center  
          rounded bg-white border border-green-500 h-8 w-8 focus:outline-none cursor-pointer`}
            >
              <XMarkIcon className="w-5 h-5 text-red-700" />
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              onClick={handleShareClips}
              className={`inline-flex items-center justify-center  
          rounded-lg bg-blue px-7 py-2 text-center text-sm 
          font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none ${
            isLoading ? "cursor-not-allowed opacity-50 pointer-events-none" : ""
          }`}
              id="share-clips-downloads-page"
            >
              Share
            </button>
            <div className="flex items-center justify-between gap-[5px]">
              <button
                type="button"
                onClick={handleDownload}
                disabled={isLoading}
                className={`inline-flex items-center justify-center  
          rounded-lg bg-white w-3 px-5 py-2 text-center text-sm 
          font-medium text-blue-600  hover:bg-gray-50 focus:outline-none ml-3 ${
            isLoading ? "cursor-not-allowed opacity-50" : ""
          }`}
                id="download-clip-downloads-page"
              >
                <Tooltip
                  content={"Download Video"}
                  className={""}
                >
                  <ArrowDownTrayIcon className="h-5 w-5 text-gray-700" />
                </Tooltip>
              </button>
              <Tooltip
                content={"Edit Video"}
                className={""}
              >
                <button
                  type="button"
                  onClick={toggleFetchDraft}
                  disabled={isLoading}
                  className={`inline-flex items-center justify-center  
                rounded bg-white h-9 w-9 hover:bg-gray-50 focus:outline-none mr-2 ${
                  isLoading ? "cursor-not-allowed opacity-50" : ""
                }`}
                  id="edit-clip-social-media-modal"
                >
                  <PencilSquareIcon
                    className="w-5 h-5 text-green-500"
                    strokeWidth={2}
                  />
                </button>
              </Tooltip>

              <Tooltip
                content={"Delete Video"}
                className={""}
              >
                <button
                  type="button"
                  onClick={handleDeleteClick}
                  disabled={isLoading}
                  className={`inline-flex items-center justify-center  
                rounded bg-white h-9 w-9 hover:bg-gray-50 focus:outline-none mr-2 ${
                  isLoading ? "cursor-not-allowed opacity-50" : ""
                }`}
                  id="delete-clip-social-media-modal"
                >
                  <TrashIcon className="h-5 w-5 cursor-pointer fill-red-500 " />
                </button>
              </Tooltip>
            </div>
          </>
        )}
        {showShareModal[render_task_id] && (
          <ShareVideoModal
            showShareModal={showShareModal[render_task_id]}
            downloadClip={downloadClip}
            clipType={clipType}
            setShowShareModal={setShowShareModal}
            toggleFetchDraft={toggleFetchDraft}
          />
        )}
      </div>
    </div>
  );
};

export default DownloadVideoCard;
