import { useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { Tooltip } from "flowbite-react";

import { changeSelectedLayout } from "@/store/editorSlice";
import {
  updateCurrentSelectedMicroContent,
  updateCurrentSelectedMicroContentGist,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { updateMicroContentData } from "@/api/requests";
import useUpdateProject from "@/api/useUpdateProject";

import {
  trackLayoutChangeEvent,
  trackRenameClipEvent,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { ScreenName, VideoLayout } from "@/enums";

const FULL_VIDEO = "full_video";

const CHAPTER_TYPE = "chapter";
const SHORT_TYPE = "short";

type TemplatePreviewTitleProps = {
  projectData: any;
  clipDeleteLoading: boolean;
  onVideoClipChange: any;
};

const TemplatePreviewTitle = ({
  onVideoClipChange,
  projectData,
  clipDeleteLoading,
}: TemplatePreviewTitleProps) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { mutate, error } = useUpdateProject();

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );
  const currentSelectedProject = useAppSelector(
    (state) => state.homeState.currentSelectedProject
  );
  const showPreviewClipsSideModal = useAppSelector(
    (state) => state.homeState.showPreviewClipsSideModal
  );
  const selectedLayout = useAppSelector(
    (state) => state.editorState.selectedLayout
  );

  const currentSelectedClip = useAppSelector(
    (state) => state.homeState.currentSelectedClip
  );

  const [projectTitle, setProjectTitle] = useState<string>(projectData.title);
  const [inputValue, setInputValue] = useState<string>(
    currentSelectedMicroContent?.gist || ""
  );

  const onAspectRatioChange = (layout: VideoLayout) => {
    if (selectedLayout !== layout) {
      dispatch(changeSelectedLayout(layout));
      onVideoClipChange(currentSelectedMicroContent, layout, "", true);
      trackLayoutChangeEvent(layout, ScreenName.REVIEW_CLIPS);
    }
  };

  const saveGist = async () => {
    if (inputValue && inputValue.length) {
      if (inputValue !== currentSelectedMicroContent?.gist) {
        await updateMicroContentData({
          project_id: currentSelectedProject?.id,
          start: currentSelectedMicroContent.start,
          end: currentSelectedMicroContent.end,
          gist: inputValue,
          type:
            selectedLayout === VideoLayout.LAYOUT_16_9
              ? CHAPTER_TYPE
              : SHORT_TYPE,
        });
        queryClient.invalidateQueries({ queryKey: ["micro-content-data"] });
        dispatch(updateCurrentSelectedMicroContentGist(inputValue));
        showNotification("Title saved", notificationType.SUCCESS);
        trackRenameClipEvent(currentSelectedMicroContent, inputValue);
      }
    }
  };

  const projectTitleSave = async () => {
    if (projectTitle !== projectData.title) {
      const updatedProjectData = { ...projectData, title: projectTitle };
      mutate(updatedProjectData);

      if (error) {
        showNotification(
          "Failed to update title, please try again",
          notificationType.FAIL
        );
      } else {
        showNotification("Title saved", notificationType.SUCCESS);
      }
    }
  };

  const inputStyle = {
    width: `${
      inputValue && inputValue.length > 10 ? inputValue.length * 15 : 200
    }px`,
    maxWidth: "70%",
  };

  const toggleClipBookmark = async () => {
    let microContentUpdatedObject = {
      project_id: currentSelectedProject?.id,
      start: currentSelectedMicroContent.start,
      end: currentSelectedMicroContent.end,
      type: selectedLayout === VideoLayout.LAYOUT_16_9 ? "chapter" : "short",
      bookmarked: !currentSelectedMicroContent.bookmarked,
    };
    try {
      await updateMicroContentData(microContentUpdatedObject);
      dispatch(
        updateCurrentSelectedMicroContent({
          bookmarked: !currentSelectedMicroContent.bookmarked,
        })
      );
      queryClient.invalidateQueries({ queryKey: ["micro-content-data"] });
    } catch (error) {
      // display nothing to user if there is an error
    }
  };

  useEffect(() => {
    setInputValue(currentSelectedMicroContent?.gist || "");
  }, [currentSelectedMicroContent?.gist]);

  return (
    <div
      className={`bg-white flex justify-center items-center relative z-10 py-6 px-10 ${
        showPreviewClipsSideModal ? "w-[65%]" : "w-full"
      }`}
    >
      {currentSelectedClip !== FULL_VIDEO && !clipDeleteLoading && (
        <div
          className="border-[#0E9F6E] text-[#0E9F6E] border absolute left-7 top-1/2 -translate-y-1/2 px-5 py-2.5 rounded-lg cursor-pointer hover:bg-[#0E9F6E] hover:text-white"
          onClick={toggleClipBookmark}
        >
          {currentSelectedMicroContent?.bookmarked ? (
            <p className="text-sm ">Remove From 🤎</p>
          ) : (
            <p className="text-sm ">Add to 🤎</p>
          )}
        </div>
      )}

      {/* title */}
      <div className="flex flex-col justify-center items-center w-[80%] flex-2">
        <div className="flex justify-center items-center w-full">
          {currentSelectedClip === FULL_VIDEO ? (
            <input
              className={`text-2xl font-semibold border-white border hover:border-gray-400 rounded-md py-2 px-1 text-center placeholder:text-md placeholder:text-gray-300 w-[50vw] mx-3`}
              onBlur={projectTitleSave}
              value={projectTitle}
              onChange={(event: any) => setProjectTitle(event.target.value)}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            />
          ) : (
            <input
              className={`text-2xl font-semibold border-white border hover:border-gray-400 rounded-md py-2 px-1 text-center placeholder:text-md placeholder:text-gray-300 ${
                showPreviewClipsSideModal ? "w-[65%]" : "w-full ml-48"
              }
              ${
                showPreviewClipsSideModal &&
                currentSelectedMicroContent?.bookmarked
                  ? "ml-28"
                  : "ml-12"
              }`}
              value={!clipDeleteLoading ? inputValue : ""}
              onBlur={saveGist}
              style={inputStyle}
              onChange={(event: any) => setInputValue(event.target.value)}
              placeholder="Untitled clip"
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            />
          )}
        </div>
      </div>

      {/* aspect tabs */}
      {!showPreviewClipsSideModal && currentSelectedClip !== FULL_VIDEO && (
        <div className="flex ml-4">
          {/* icons */}
          <Tooltip
            content="Square"
            placement="bottom"
            className="z-10"
          >
            <div
              className={`p-3 border border-[#E5E7EB] rounded-l-md cursor-pointer ${
                selectedLayout === VideoLayout.LAYOUT_1_1
                  ? "bg-[#1C64F2] text-white"
                  : "bg-white hover:bg-blue-50 "
              }`}
              onClick={() => {
                onAspectRatioChange(VideoLayout.LAYOUT_1_1);
              }}
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                stroke={`${
                  selectedLayout === VideoLayout.LAYOUT_1_1
                    ? "#ffffff"
                    : "#000000"
                }`}
                fill="none"
              >
                <path
                  id="Vector"
                  d="M3 6.2002V17.8002C3 18.9203 3 19.4796 3.21799 19.9074C3.40973 20.2837 3.71547 20.5905 4.0918 20.7822C4.5192 21 5.07899 21 6.19691 21H17.8031C18.921 21 19.48 21 19.9074 20.7822C20.2837 20.5905 20.5905 20.2837 20.7822 19.9074C21 19.48 21 18.921 21 17.8031V6.19691C21 5.07899 21 4.5192 20.7822 4.0918C20.5905 3.71547 20.2837 3.40973 19.9074 3.21799C19.4796 3 18.9203 3 17.8002 3H6.2002C5.08009 3 4.51962 3 4.0918 3.21799C3.71547 3.40973 3.40973 3.71547 3.21799 4.0918C3 4.51962 3 5.08009 3 6.2002Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Tooltip>
          <Tooltip
            content="Reel"
            placement="bottom"
            className="z-10"
          >
            <div
              className={`p-3 border border-[#E5E7EB] cursor-pointer ${
                selectedLayout === VideoLayout.LAYOUT_9_16_1
                  ? "bg-[#1C64F2] text-white"
                  : "bg-white hover:bg-blue-50 "
              }`}
              onClick={() => {
                onAspectRatioChange(VideoLayout.LAYOUT_9_16_1);
              }}
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                preserveAspectRatio="xMidYMid meet"
                className="rotate-90 "
              >
                <path
                  d="M8.5 22.5A3.5 3.5 0 0 0 5 26v48a3.5 3.5 0 0 0 3.5 3.5h83A3.5 3.5 0 0 0 95 74V26a3.5 3.5 0 0 0-3.5-3.5h-83zm3.5 7h76v41H12v-41z"
                  fill={`${
                    selectedLayout === VideoLayout.LAYOUT_9_16_1
                      ? "#fff"
                      : "#000"
                  }`}
                ></path>
              </svg>
            </div>
          </Tooltip>

          <Tooltip
            content="Portrait Split"
            placement="bottom"
            className="z-10"
          >
            <div
              className={`p-3 border border-[#E5E7EB] cursor-pointer ${
                selectedLayout === VideoLayout.LAYOUT_9_16_2
                  ? "bg-[#1C64F2] text-white"
                  : "bg-white hover:bg-blue-50 "
              }`}
              onClick={() => {
                onAspectRatioChange(VideoLayout.LAYOUT_9_16_2);
              }}
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                preserveAspectRatio="xMidYMid meet"
                className="rotate-90 "
              >
                <path
                  d="M8.5 22.5A3.5 3.5 0 0 0 5 26v48a3.5 3.5 0 0 0 3.5 3.5h83A3.5 3.5 0 0 0 95 74V26a3.5 3.5 0 0 0-3.5-3.5h-83zm3.5 7h76v41H12v-41z"
                  fill={`${
                    selectedLayout === VideoLayout.LAYOUT_9_16_2
                      ? "#fff"
                      : "#000"
                  }`}
                ></path>
                <line
                  x1="50"
                  y1="25"
                  x2="50"
                  y2="75"
                  stroke={`${
                    selectedLayout === VideoLayout.LAYOUT_9_16_2
                      ? "#fff"
                      : "#000"
                  }`}
                  stroke-width="6"
                />
              </svg>
            </div>
          </Tooltip>

          <Tooltip
            content="Landscape"
            placement="bottom"
            className="z-10"
          >
            <div
              className={`p-3 border border-[#E5E7EB] rounded-r-md cursor-pointer ${
                selectedLayout === VideoLayout.LAYOUT_16_9
                  ? "bg-[#1C64F2] text-white"
                  : "bg-white hover:bg-blue-50 "
              }`}
              onClick={() => {
                onAspectRatioChange(VideoLayout.LAYOUT_16_9);
              }}
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M8.5 22.5A3.5 3.5 0 0 0 5 26v48a3.5 3.5 0 0 0 3.5 3.5h83A3.5 3.5 0 0 0 95 74V26a3.5 3.5 0 0 0-3.5-3.5h-83zm3.5 7h76v41H12v-41z"
                  fill={`${
                    selectedLayout === VideoLayout.LAYOUT_16_9 ? "#fff" : "#000"
                  }`}
                ></path>
              </svg>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default TemplatePreviewTitle;
