import { useState } from "react";

import { GoogleAuthProvider } from "firebase/auth";
import { Spinner } from "flowbite-react";

import { signInWithFirebase } from "@/utils/FirebaseHelpers";

const GoogleAuth = ({
  setDisplayModal,
  setFirebaseUserData,
  isSmall,
}: {
  setDisplayModal: (value: boolean) => void;
  setFirebaseUserData: (value: {}) => void;
  isSmall?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const signInWithGoogle = (e: any) => {
    e.preventDefault();
    const provider = new GoogleAuthProvider();
    signInWithFirebase(
      provider,
      setIsLoading,
      setDisplayModal,
      setFirebaseUserData
    );
  };

  return (
    <button
      className="px-4 py-2.5 border flex gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150 w-full justify-center"
      onClick={signInWithGoogle}
      id="sign-in-with-google"
      disabled={isLoading}
    >
      {isLoading ? (
        <>
          <Spinner className="h-4 w-4" />
        </>
      ) : (
        <>
          <img
            className="w-5 h-5"
            src="https://www.svgrepo.com/show/475656/google-color.svg"
            alt="google logo"
          />
          <span className="text-gray-800 text-sm font-medium">
            {isSmall ? "Google" : "Sign in with Google"}
          </span>
        </>
      )}
    </button>
  );
  // return (
  //   <button
  //     className={`h-11 border-none shrink inline-flex w-full items-center justify-center rounded-md border text-sm font-medium text-gray-900  focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400  dark:focus:ring-gray-700 bg-white transition-colors ${
  //       isLoading
  //         ? " border-black/60 opacity-60"
  //         : "hover:bg-[#206ae8] hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white"
  //     }`}
  //     id="sign-in-with-google"
  //     disabled={isLoading}
  //   >
  //     {isLoading ? (
  //       <Spinner className="h-4 w-4" />
  //     ) : (
  //       <>
  //         <img
  //           className="w-6 h-6"
  //           src="https://www.svgrepo.com/show/475656/google-color.svg"
  //           loading="lazy"
  //           alt="google logo"
  //         />
  //         <span>Login with Google</span>
  //       </>
  //     )}
  //   </button>
  // );
};

export default GoogleAuth;
