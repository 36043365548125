import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import { getBaseUrl, getCRUDBaseUrl } from "@/helpers";

import { auth } from "@/utils/firebase";

// Default config for the axios instance
const axiosParams = {
  // Set different base URL based on the environment
  baseURL: getBaseUrl(),
};

// Create axios instance with default params
const axiosInstance = axios.create(axiosParams);

axiosInstance.interceptors.request.use(async (request: any) => {
  // this exculed other apis other than ours
  // excluding revoke-access api
  if (
    (request.url.includes("http") && !request.url.includes(getCRUDBaseUrl())) ||
    request.url.includes("revoke-access") ||
    request.url.includes("pexels.vidyoai.workers")
  ) {
    return request;
  }

  const user = auth.currentUser;

  if (user) {
    try {
      const token = await user.getIdToken(false);
      // Force refresh is false
      request.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      console.log("Error obtaining auth token in interceptor, ", error);
    }
    return request;
  }
});

// Main api function
const api = (axios: AxiosInstance) => {
  return {
    get: <T>(url: string, config: AxiosRequestConfig = {}) =>
      axios.get<T>(url, config),
    delete: <T>(url: string, config: AxiosRequestConfig = {}) =>
      axios.delete<T>(url, config),
    post: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) =>
      axios.post<T>(url, body, config),
    patch: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) =>
      axios.patch<T>(url, body, config),
    put: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) =>
      axios.put<T>(url, body, config),
  };
};

export default api(axiosInstance);
