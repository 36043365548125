import { useState } from "react";
import { useEffectOnce, useUpdateEffect, useWindowSize } from "react-use";

import { Pagination, Spinner } from "flowbite-react";

import useGetBulkDownloads from "@/api/useGetBulkDownloads";
import useGetDownloads from "@/api/useGetDownloads";

import { zE } from "@/helpers";

import { DownloadedClipType } from "@/enums";

import HorizontalMenuTabContainer from "@/components/MenuTab/HorizontalMenuTabContainer";
import HorizontalMenuTabItem from "@/components/MenuTab/HorizontalMenuTabItem";

import DownloadVideoCard, {
  BulkExportClipsCard,
} from "@/views/home/components/DownloadVideoCard";
import NoDownloads from "@/views/home/components/NoDownloads";
import { ProFeatureDiscoveryModal } from "@/views/home/components/ProFeatureDiscoveryModal";

// @ts-ignore
const responsiveClasses = {
  [DownloadedClipType.PORTRAIT]: "md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5",
  [DownloadedClipType.SQUARE]:
    "md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4",
  [DownloadedClipType.LANDSCAPE]:
    "md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3",
  [DownloadedClipType.BULK]: "md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3",
};

const LAPTOP_VIEW = 1024;
const SMALL_SCREEN_VIEW = 1280;
const LARGE_SCREEN_VIEW = 1536;
const NUMBER_OF_ROWS = 2;

const Downloads = () => {
  const { width } = useWindowSize();
  const [showProFeatureDiscoveryModal, setShowProFeatureDiscoveryModal] =
    useState(false);

  const [activeDownloadClipTypeTab, setActiveDownloadClipTypeTab] = useState(
    DownloadedClipType.PORTRAIT
  );
  const [pageOffset, setPageOffset] = useState(1);
  const { data: allDownloads, isLoading, isFetching } = useGetDownloads();
  const {
    data: bulkDownloads,
    isLoading: isBulkDownloadsLoading,
    isFetching: isBulkDownloadsFetching,
  } = useGetBulkDownloads();

  const isDownloadsEmpty =
    !isLoading &&
    !isBulkDownloadsLoading &&
    allDownloads &&
    bulkDownloads &&
    !allDownloads[DownloadedClipType.SQUARE].length &&
    !allDownloads[DownloadedClipType.LANDSCAPE].length &&
    !allDownloads[DownloadedClipType.PORTRAIT].length &&
    !bulkDownloads.length;

  useEffectOnce(() => {
    zE("webWidget", "helpCenter:setSuggestions", {
      search: "download",
    });
  });

  useUpdateEffect(() => {
    const hasDownloads = (type: DownloadedClipType) => {
      return (
        // @ts-ignore
        allDownloads && allDownloads[type] && allDownloads[type].length > 0
      );
    };

    const allTypesEmpty = () => {
      return (
        !hasDownloads(DownloadedClipType.SQUARE) &&
        !hasDownloads(DownloadedClipType.LANDSCAPE) &&
        !hasDownloads(DownloadedClipType.PORTRAIT)
      );
    };

    const hasAnyBulkDownloads = () => {
      return (
        !isBulkDownloadsLoading && bulkDownloads && bulkDownloads.length > 0
      );
    };

    if (!isLoading && allDownloads) {
      if (hasAnyBulkDownloads() && allTypesEmpty()) {
        setActiveDownloadClipTypeTab(DownloadedClipType.BULK);
        return;
      }

      if (
        !hasDownloads(DownloadedClipType.PORTRAIT) &&
        !hasDownloads(DownloadedClipType.SQUARE)
      ) {
        setActiveDownloadClipTypeTab(DownloadedClipType.LANDSCAPE);
      } else if (
        !hasDownloads(DownloadedClipType.LANDSCAPE) &&
        !hasDownloads(DownloadedClipType.PORTRAIT)
      ) {
        setActiveDownloadClipTypeTab(DownloadedClipType.SQUARE);
      }
    }
  }, [isLoading, isFetching, isBulkDownloadsLoading, isBulkDownloadsFetching]);

  const handlePageChange = (newOffset: number) => {
    setPageOffset(newOffset);
  };

  const getDownloadsLength = () => {
    if (activeDownloadClipTypeTab === DownloadedClipType.PORTRAIT)
      return allDownloads
        ? allDownloads[DownloadedClipType.PORTRAIT].length
        : 0;
    else if (activeDownloadClipTypeTab === DownloadedClipType.LANDSCAPE)
      return allDownloads
        ? allDownloads[DownloadedClipType.LANDSCAPE].length
        : 0;
    else if (activeDownloadClipTypeTab === DownloadedClipType.SQUARE)
      return allDownloads ? allDownloads[DownloadedClipType.SQUARE].length : 0;
    else return bulkDownloads.length;
  };

  const getVideosPerRow = () => {
    if (activeDownloadClipTypeTab === DownloadedClipType.PORTRAIT) {
      if (width >= SMALL_SCREEN_VIEW) return 5;
      else if (width >= LAPTOP_VIEW) return 3;
      else return 2;
    } else if (activeDownloadClipTypeTab === DownloadedClipType.SQUARE) {
      if (width >= LARGE_SCREEN_VIEW) return 4;
      else if (width >= SMALL_SCREEN_VIEW) return 3;
      else return 2;
    }
    if (width >= SMALL_SCREEN_VIEW) return 3;
    else return 2;
  };

  const getTotalPages = () => {
    const videosPerRow = getVideosPerRow();
    const downloadsLength = getDownloadsLength();
    if (downloadsLength <= videosPerRow * NUMBER_OF_ROWS) {
      return 1;
    } else return Math.ceil(downloadsLength / (videosPerRow * NUMBER_OF_ROWS));
  };

  const displayVideos = () => {
    const videosPerRow = getVideosPerRow();

    if (activeDownloadClipTypeTab === DownloadedClipType.BULK) {
      return (
        bulkDownloads &&
        bulkDownloads
          .slice(
            (pageOffset - 1) * videosPerRow * NUMBER_OF_ROWS,
            pageOffset * videosPerRow * NUMBER_OF_ROWS
          )
          .map((download: any) => (
            <BulkExportClipsCard
              key={`${download.created_at}`}
              download={download}
            />
          ))
      );
    }

    return (
      allDownloads &&
      allDownloads?.[activeDownloadClipTypeTab]
        .slice(
          (pageOffset - 1) * videosPerRow * NUMBER_OF_ROWS,
          pageOffset * videosPerRow * NUMBER_OF_ROWS
        )
        .map((download) => (
          <DownloadVideoCard
            key={`${download.createdAt}`}
            downloadClip={download}
            clipType={activeDownloadClipTypeTab}
            setShowProFeatureDiscoveryModal={setShowProFeatureDiscoveryModal}
            allDownloads={allDownloads?.[activeDownloadClipTypeTab]}
          />
        ))
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="flex h-full w-full flex-col justify-between">
          <div
            role="status"
            className="relative flex h-full w-full flex-col items-center justify-center"
          >
            <Spinner size="lg" />
          </div>
        </div>
      ) : (
        <>
          {isDownloadsEmpty ? (
            <NoDownloads />
          ) : (
            <div className="relative flex h-full w-full flex-col justify-between">
              <div>
                <HorizontalMenuTabContainer>
                  {allDownloads &&
                    allDownloads[DownloadedClipType.PORTRAIT].length > 0 && (
                      <HorizontalMenuTabItem
                        label={`Portrait (${
                          allDownloads[DownloadedClipType.PORTRAIT].length
                        })`}
                        isActive={
                          activeDownloadClipTypeTab ===
                          DownloadedClipType.PORTRAIT
                        }
                        onClick={() =>
                          setActiveDownloadClipTypeTab(
                            DownloadedClipType.PORTRAIT
                          )
                        }
                      />
                    )}
                  {allDownloads &&
                    allDownloads[DownloadedClipType.SQUARE].length > 0 && (
                      <HorizontalMenuTabItem
                        label={`Square (${
                          allDownloads[DownloadedClipType.SQUARE].length
                        })`}
                        isActive={
                          activeDownloadClipTypeTab ===
                          DownloadedClipType.SQUARE
                        }
                        onClick={() =>
                          setActiveDownloadClipTypeTab(
                            DownloadedClipType.SQUARE
                          )
                        }
                      />
                    )}
                  {allDownloads &&
                    allDownloads[DownloadedClipType.LANDSCAPE].length > 0 && (
                      <HorizontalMenuTabItem
                        label={`Landscape (${
                          allDownloads[DownloadedClipType.LANDSCAPE].length
                        })`}
                        isActive={
                          activeDownloadClipTypeTab ===
                          DownloadedClipType.LANDSCAPE
                        }
                        onClick={() =>
                          setActiveDownloadClipTypeTab(
                            DownloadedClipType.LANDSCAPE
                          )
                        }
                      />
                    )}
                  {bulkDownloads?.length > 0 && (
                    <HorizontalMenuTabItem
                      label={`Bulk downloads (${bulkDownloads?.length})`}
                      isActive={
                        activeDownloadClipTypeTab === DownloadedClipType.BULK
                      }
                      onClick={() =>
                        setActiveDownloadClipTypeTab(DownloadedClipType.BULK)
                      }
                    />
                  )}
                </HorizontalMenuTabContainer>

                <section className="relative bg-white py-6 px-5">
                  <div
                    className={`grid place-items-center gap-8 ${responsiveClasses[activeDownloadClipTypeTab]}`}
                  >
                    <>{displayVideos()}</>
                  </div>
                </section>
              </div>

              {getDownloadsLength() > getVideosPerRow() * NUMBER_OF_ROWS && (
                <div className="flex items-center justify-center text-center pb-6">
                  <Pagination
                    currentPage={pageOffset}
                    totalPages={getTotalPages()}
                    onPageChange={handlePageChange}
                    showIcons={true}
                    previousLabel=""
                    nextLabel=""
                    className="pagination-container"
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
      <ProFeatureDiscoveryModal
        showProFeatureDiscoveryModal={showProFeatureDiscoveryModal}
        setShowProFeatureDiscoveryModal={setShowProFeatureDiscoveryModal}
      />
    </>
  );
};

export default Downloads;
