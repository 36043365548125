import { createCommand, Command } from "@/views/editor/EditorHistoryReducer";

export const addMediaCommand = (
  metaData: any,
  addMedia: (metData: any) => void,
  removeMedia: (id: string | number) => void
): Command => {
  return createCommand({
    executeAction: () => addMedia(metaData),
    undoAction: () => removeMedia(metaData?.item?.id),
  });
};

export const deleteMediaCommand = (
  metaData: any,
  addMedia: (metData: any) => void,
  removeMedia: (id: string | number) => void
): Command => {
  return createCommand({
    executeAction: () => addMedia(metaData),
    undoAction: () => removeMedia(metaData?.item?.id),
    metadata: metaData,
  });
};

export const modifyMediaOnCanvasCommand = (
  metaData: any,
  applyMediaModification: (metData: any) => void
): Command => {
  const { newMediaData, oldMediaData } = metaData;
  return createCommand({
    executeAction: () => applyMediaModification(newMediaData),
    undoAction: () => applyMediaModification(oldMediaData),
    metadata: metaData,
  });
};

export const addAudioAssetCommand = (
  metaData: any,
  addMedia: (metData: any) => void,
  removeMedia: (id: string | number) => void
): Command => {
  return createCommand({
    executeAction: () => addMedia(metaData?.item),
    undoAction: () => removeMedia(metaData?.item?.id),
    metadata: metaData,
  });
};

export const updateAudioAssetVolumeCommand = (
  metaData: any,
  updateAudioAssetVolume: (volume: number, assetId: string) => void
): Command => {
  const { newVolume, oldVolume, assetId } = metaData;
  return createCommand({
    executeAction: () => updateAudioAssetVolume(newVolume, assetId),
    undoAction: () => updateAudioAssetVolume(oldVolume, assetId),
  });
};

export type TimeUpdateMetadataTypes = {
  newTime: any;
  oldTime: any;
  id: string | number;
  isBRoll: boolean;
};

export const mediaStartTimeUpdate = (
  metaData: TimeUpdateMetadataTypes,
  updateStartTime: (time: any, id: any, isBRoll: boolean) => void
): Command => {
  const { newTime, oldTime, id, isBRoll = false } = metaData;
  return createCommand({
    executeAction: () => updateStartTime(newTime, id, isBRoll),
    undoAction: () => updateStartTime(oldTime, id, isBRoll),
  });
};

export const mediaEndTimeUpdate = (
  metaData: any,
  mediaEndTimeUpdate: (time: any, id: any, isBRoll: boolean) => void
): Command => {
  const { newTime, oldTime, id, isBRoll = false } = metaData;
  return createCommand({
    executeAction: () => mediaEndTimeUpdate(newTime, id, isBRoll),
    undoAction: () => mediaEndTimeUpdate(oldTime, id, isBRoll),
    metadata: metaData,
  });
};

export const mediaFullLengthTimeUpdateCommand = (
  metaData: any,
  mediaFullLengthTimeUpdate: (
    isFullLength: boolean,
    id: string | number,
    isBRoll: boolean,
    value: any
  ) => void
): Command => {
  const { newValue, oldValue, id, isBRoll = true } = metaData;

  return createCommand({
    executeAction: () =>
      mediaFullLengthTimeUpdate(
        newValue?.isFullVideoLength,
        id,
        isBRoll,
        newValue?.times
      ),
    undoAction: () =>
      mediaFullLengthTimeUpdate(
        oldValue?.isFullVideoLength,
        id,
        isBRoll,
        oldValue?.times
      ),
    metadata: metaData,
  });
};

export type AudioAssetTimeMetadata = {
  newTime: {
    start?: number;
    end?: number;
  };
  oldTime: {
    start?: number;
    end?: number;
  };
  id: string | number;
  makeFullVideoLength: boolean;
};

export const audioAssetTimeUpdateCommand = (
  metaData: AudioAssetTimeMetadata,
  updateAudioAssetTime: (
    time: {
      start?: number;
      end?: number;
    },
    id: string | number,
    makeFullVideoLength: boolean
  ) => void
): Command => {
  const { newTime, oldTime, id, makeFullVideoLength = false } = metaData;
  return createCommand({
    executeAction: () => updateAudioAssetTime(newTime, id, makeFullVideoLength),
    undoAction: () => updateAudioAssetTime(oldTime, id, !makeFullVideoLength),
  });
};
