import gtag from "ga-gtag";

import { isProd, stringToSha256 } from "@/helpers";

export const downloadAndRenderConversionGtag = () => {
  gtag("event", "conversion", {
    send_to: "AW-10975442012/u_XFCPa6vr0YENzov_Eo",
  });
};

export const newUserSignUpGtag = async (user: any) => {
  if (isProd()) {
    const email: any = await stringToSha256(user.email);

    (window as any).dataLayer?.push({
      event: "newuser_signup",
      user_id: user.uid,
      email,
    });
  }
};

export const purchaseConversionGtag = async (
  userEmail: string,
  value: number,
  transaction_id: string
) => {
  const email: any = await stringToSha256(userEmail);

  (window as any).dataLayer?.push({
    event: "order_success",
    order_value: value,
    order_id: transaction_id,
    email,
  });

  try {
    (window as any)?.twq("event", "tw-o759p-oe8cb", {
      email_address: email, // use this to pass a user's email address
    });
  } catch (error) {
    console.error("twitter conversion event logging failed", error);
  }
};

export const importVideoConversionGtag = () => {
  gtag("event", "conversion", {
    send_to: "AW-10975442012/40T2CPO6vr0YENzov_Eo",
  });
};
