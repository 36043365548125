import { FC, Fragment, useEffect, useState } from "react";
import { useToggle } from "react-use";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useQueryClient } from "@tanstack/react-query";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Box from "@uppy/box";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Dropbox from "@uppy/dropbox";
// import GoogleDrive from "@uppy/google-drive";
// import Instagram from "@uppy/instagram";
import { Dashboard as DashboardComponent } from "@uppy/react";
import Url from "@uppy/url";
import clsx from "clsx";
import { nanoid } from "nanoid";

import {
  toggleAddMoreMinModal,
  toggleUpgradeToProModal,
  updateCurrentOnBoardingId,
  updateOnBoardingDataById,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";
import { completeUserFileUpload } from "@/api/requests";
import useAddUserPreferenceData from "@/api/useAddUserPreferenceData";
import useGetUserPreference from "@/api/useGetUserPreference";
import usePostYTLink from "@/api/useUploadYoutubeVideo";
import useUserConsumedProcessingTime from "@/api/useUserConsumedProcessingTime";

import {
  BUFFER_TIME_FREE_USER_IN_MINS,
  BUFFER_TIME_PAID_USER_IN_MINS,
  FREE_USER_UPLOAD_FILE_SIZE_LIMIT,
  PRO_USER_UPLOAD_FILE_SIZE_LIMIT,
} from "@/constants";
import {
  AMPLITUDE_CLICK_EVENTS,
  ANALYTICS_CONSTANTS,
  IMPORT_PREFERENCE,
} from "@/constants/amplitude";
import { languageList } from "@/constants/on-boarding";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import {
  calculateRemainingProcessingTime,
  isValidYoutubeUrl,
  generateUploadedVideoUrl,
} from "@/helpers";

import {
  addMoreMinutesButtonClick,
  eventsDataToRedux,
  trackProjectImportEvent,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { importVideoConversionFbq } from "@/utils/fbq";
import { importVideoConversionGtag } from "@/utils/gtag";
import { showNotification } from "@/utils/showNotification";

import { OnBoardingStep, PlanType, SimpleAssetType } from "@/enums";

import Spinner from "@/components/Loader/Spinner";

import YoutubeIcon from "@/assets/icons/social/youtube.svg";

const { GENERATE_CLIP_CONTINUE } = BUTTON_IDS.ON_BOARDING_UPLOAD_FILE;

type OnBoardingUploadFileProps = {
  handleChangeActiveStep: any;
  cancelProjectSuccessCB: (closeModal: boolean) => void;
  isVisible: boolean;
  userHasExistingProject?: boolean;
};

const OnBoardingUploadFile: FC<OnBoardingUploadFileProps> = ({
  handleChangeActiveStep,
  cancelProjectSuccessCB,
  isVisible,
  userHasExistingProject,
}) => {
  const onBoardingId = nanoid();

  const queryClient = useQueryClient();

  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [isVideoMetaDataLoading, toggleIsVideoMetaDataLoading] =
    useToggle(false);
  const [error, setError] = useState("");
  const [showBuyMoreMinutes, setShowBuyMoreMinutes] = useState(false);
  const [isYoutubeUrlValid, setIsYoutubeUrlValid] = useState<boolean>(false);
  const [isInfoChecked, setIsInfoChecked] = useState<boolean>(true);
  const [currentLangSelected, setCurrentLangSelected] = useState<any>(null);
  const [languageNotSelectedError, setLanguageNotSelectedError] = useState("");

  const { data: usageData } = useUserConsumedProcessingTime();

  const dispatch = useAppDispatch();

  const { uid } = useAppSelector((state) => state.authState.currentUser);
  const { data: firestoreUserPreferenceData } = useGetUserPreference(uid);
  const { mutate: addUserPreferenceData } = useAddUserPreferenceData();

  const currentUserSubscriptionDetails = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const planType = currentUserSubscriptionDetails.planType;

  const isPaidUser = planType !== PlanType.FREE;

  function createUppy(uppyID: string) {
    const uppy = new Uppy({
      id: uppyID,
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ["video/mp4", "video/webm", "video/quicktime"],
        maxFileSize:
          planType === PlanType.FREE
            ? FREE_USER_UPLOAD_FILE_SIZE_LIMIT
            : PRO_USER_UPLOAD_FILE_SIZE_LIMIT,
      },
      autoProceed: false,
      debug: false,
    });

    uppy.use(Box, {
      companionUrl: ApiEndpoints.UPPY_ENDPOINT,
    });
    uppy.use(Dropbox, {
      companionUrl: ApiEndpoints.UPPY_ENDPOINT,
    });
    // uppy.use(GoogleDrive, {
    //   companionUrl: ApiEndpoints.UPPY_ENDPOINT,
    // });
    uppy.use(Url, {
      companionUrl: ApiEndpoints.UPPY_ENDPOINT,
    });
    uppy.use(AwsS3Multipart, {
      limit: 5,
      companionUrl: ApiEndpoints.UPPY_ENDPOINT,
      allowedMetaFields: ["name", "type"],
    });

    uppy.on("complete", async (result) =>
      onSuccessfulUpload(result, onBoardingId)
    );

    uppy.on("file-added", (file) => {
      const video = document.createElement(SimpleAssetType.VIDEO);

      if (file.source === "react:Dashboard") {
        const originalFileName = file.name;
        file.name = nanoid() + "." + file.extension;
        file.meta.name = file.name;
        file.meta.originalFileName = originalFileName;

        video.addEventListener("loadedmetadata", () => {
          URL.revokeObjectURL(video.src); // Clean up the object URL

          file.meta.videoHeight = video.videoHeight;
          file.meta.videoWidth = video.videoWidth;

          if (!video.videoHeight && !video.videoWidth) {
            uppy.info(
              `The video you're trying to upload is not supported in this browser for playback. Please try uploading another video or consider using a different browser.`,
              "error",
              10000
            );
            const uppyButton = document.querySelector(
              "button.uppy-StatusBar-actionBtn--upload"
            );
            if (uppyButton) {
              uppyButton.setAttribute("disabled", "disabled");
            }
          }

          const maxDurationInSeconds = calculateRemainingProcessingTime(
            usageData,
            planType === PlanType.FREE
              ? BUFFER_TIME_FREE_USER_IN_MINS
              : BUFFER_TIME_PAID_USER_IN_MINS
          );

          if (video.duration > maxDurationInSeconds) {
            uppy.removeFile(file.id);
            showNotification(
              `The video you're trying to upload exceeds the processing minutes remaining on your account.`,
              notificationType.FAIL,
              true,
              10000,
              true,
              upgradeToProElement()
            );
          }
        });
        video.src = URL.createObjectURL(file.data);
      }
    });

    uppy.on("file-removed", (file, reason) => {
      const uppyInfoElement = document.querySelector(
        "div.uppy-Informer-animated"
      );
      uppyInfoElement && uppyInfoElement.remove();
    });

    function onError(
      onBoardingId: string,
      isUserCancelled = false,
      uppyInstance: any
    ) {
      cancelProjectSuccessCB(false);

      if (isUserCancelled) {
        const updatedOnBoardingDataById = {
          processingVideoData: null,
        };
        dispatch(
          updateOnBoardingDataById({
            id: onBoardingId,
            data: updatedOnBoardingDataById,
          })
        );
      } else {
        // show error
        const updatedOnBoardingDataById = {
          processingVideoData: {
            isProcessing: false,
            isProcessingFailed: true,
            id: onBoardingId,
          },
        };
        dispatch(
          updateOnBoardingDataById({
            id: onBoardingId,
            data: updatedOnBoardingDataById,
          })
        );
      }

      !isUserCancelled &&
        showNotification(
          "Error while uploading file, Please try reuploading the file",
          notificationType.FAIL
        );
    }

    uppy.once("error", (err) => {
      onError(onBoardingId, false, uppy);
    });

    const handleStartUpload = (onBoardingId: string, uppy: any) => {
      addOnBoardingData({
        id: onBoardingId,
        uploader: uppy,
      });
      handleChangeActiveStep(
        userHasExistingProject
          ? OnBoardingStep.PERSONALIZED_VIDEO
          : OnBoardingStep.SELECT_LAYOUTS
      );
    };

    uppy.on("upload", () => handleStartUpload(onBoardingId, uppy));

    uppy.on("upload-error", (file, error, response) => {
      console.log("upload-error", error, response);
    });

    // uppy.on("upload-progress", (file, progress) => {
    //   handleUpdateOnBoardingData({
    //     progressPercentage: (progress.bytesUploaded / progress.bytesTotal) * 100,
    //   });
    // });

    uppy.once("cancel-all", () => onError(onBoardingId, true, uppy));

    uppy.on("restriction-failed", (file, error) => {
      const fileSizeError = getFileSizeErrorMsg(file?.size!);
      const isAllowedType = ["mp4", "webm", "mov"].includes(file?.extension!);
      if (fileSizeError) {
        uppy.info(fileSizeError, "error", 10000);
      } else if (!isAllowedType) {
        uppy.info(
          "Uhh! This File type is not supported. We only support mp4, mov and webm files for now",
          "error",
          10000
        );
      } else {
        uppy.info(error.message, "error", 10000);
      }
    });

    return uppy;
  }

  const [uppy] = useState(() => createUppy(onBoardingId));

  const refetchProjectCB = () => {
    queryClient.invalidateQueries({ queryKey: ["all-projects"] });
  };

  useEffect(() => {
    uppy.setOptions({
      onBeforeUpload: (files) => {
        if (!isPaidUser || currentLangSelected?.title) {
          return files;
        }
        uppy.info("Select your language before you proceed", "error", 3000);
        setLanguageNotSelectedError("Select your language before you proceed");
        return false;
      },
      meta: {
        lang: isPaidUser ? currentLangSelected?.language : "en",
      },
    });
  }, [currentLangSelected?.title]);

  const getFileSizeErrorMsg = (fileSize: number) => {
    const fileSizeInBytes =
      planType === PlanType.FREE
        ? FREE_USER_UPLOAD_FILE_SIZE_LIMIT
        : PRO_USER_UPLOAD_FILE_SIZE_LIMIT;
    if (fileSize && fileSize > fileSizeInBytes) {
      if (planType !== PlanType.FREE) {
        return "Uhh! This video is too large. Currently, we only support videos up to 15GB. Please try again with a smaller video.";
      } else {
        return "Uhh! This video is too large. Please upload a video less than 5 GB or update to Pro to upload larger videos.";
      }
    }
    return null;
  };

  const upgradeToProElement = () => {
    return (
      <>
        <span
          className="underline font-medium cursor-pointer mx-1"
          onClick={() => {
            if (isPaidUser) {
              dispatch(toggleAddMoreMinModal(true));
              addMoreMinutesButtonClick(
                AMPLITUDE_CLICK_EVENTS.UPLOAD_VIDEO_MODAL
              );
            } else {
              dispatch(toggleUpgradeToProModal(true));
              eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
            }
          }}
          id="click-here-to-buy"
        >
          Click here
        </span>
        {isPaidUser ? "to buy more minutes" : "to upgrade"}
      </>
    );
  };

  const onSuccessfulUpload = async (result: any, onBoardingId: string) => {
    let reqData = result.successful[0];

    updateUserPreference({
      isInfoChecked,
      languagePreference: reqData.meta.lang || null,
    });

    const data = await completeUserFileUpload({
      remote_url: generateUploadedVideoUrl({
        responseBody: reqData?.response?.body || reqData?.response?.uploadURL,
      }),
      asset_type: reqData.meta.type,
      asset_name: reqData.meta.originalFileName || reqData.meta?.name,
      video_height: reqData.meta.videoHeight,
      video_width: reqData.meta.videoWidth,
      lang: reqData?.meta?.lang || "en",
      asset_source:
        reqData.source === "react:Dashboard"
          ? IMPORT_PREFERENCE.LOCAL_DEVICE
          : reqData.source,
    }).catch((err) => {
      showNotification("Error while creating project", notificationType.FAIL);
    });

    const updatedOnBoardingDataById = {
      projectId: data?.project_id,
      progressPercentage: 100,
      // processingVideoData: null,
    };
    trackProjectImportEvent(reqData.source);
    importVideoConversionFbq();

    dispatch(
      updateOnBoardingDataById({
        id: onBoardingId,
        data: updatedOnBoardingDataById,
      })
    );

    refetchProjectCB();
  };

  const addOnBoardingData = (processingVideoData: any) => {
    dispatch(
      updateOnBoardingDataById({
        id: onBoardingId,
        data: {
          key: onBoardingId,
          selectedLayouts: [],
          baseTemplates: {},
          progressPercentage: 0,
          processingVideoData,
          createdAt: new Date().toISOString(),
        },
      })
    );
    dispatch(updateCurrentOnBoardingId(onBoardingId));
  };

  const handleUpdateOnBoardingData = (updatedData: any, Id?: any) => {
    dispatch(
      updateOnBoardingDataById({
        id: Id || onBoardingId,
        data: { ...updatedData },
      })
    );
  };

  const youtubeMutateErrorCB = (error: any, Id?: any) => {
    cancelProjectSuccessCB(false);

    handleUpdateOnBoardingData(
      {
        processingVideoData: null,
      },
      Id
    );

    showNotification(
      error.response.data.detail === "Could not download youtube video"
        ? "Sorry, this YouTube video seems to have some problems. Please try and upload the video instead."
        : error.response.data.detail || "Something went wrong!",
      notificationType.FAIL
    );
  };

  const youtubeMutateSuccessCB = (data: any, Id?: any) => {
    handleUpdateOnBoardingData(
      {
        projectId: data.project_id,
      },
      Id
    );

    setYoutubeUrl("");
  };

  const { mutate } = usePostYTLink(
    youtubeMutateSuccessCB,
    youtubeMutateErrorCB
  );

  const checkIfUserAllowedToProceed = (
    currentVideoDuration: any,
    successCB: any,
    fileSize?: number
  ) => {
    const { planType } = currentUserSubscriptionDetails;
    const fileSizeError = getFileSizeErrorMsg(fileSize!);

    if (fileSizeError) {
      setError(fileSizeError);
    } else if (
      currentVideoDuration <=
      calculateRemainingProcessingTime(
        usageData,
        planType === PlanType.FREE
          ? BUFFER_TIME_FREE_USER_IN_MINS
          : BUFFER_TIME_PAID_USER_IN_MINS
      )
    ) {
      successCB && successCB();
    } else {
      setError(
        "The video you're trying to upload exceeds the processing minutes remaining on your account."
      );
      setShowBuyMoreMinutes(true);
    }
  };

  const getVideoDetailsFromYoutube = async (youtubeUrl: any) => {
    let requestBody;
    try {
      requestBody = {
        yt_url: youtubeUrl,
      };
      const resData = await api.post(
        ApiEndpoints.YOUTUBE_DATA_API,
        requestBody
      );
      toggleIsVideoMetaDataLoading(false);
      return resData.data;
    } catch (error) {
      console.error(
        "getVideoDetailsFromYoutube Failed to send data.",
        requestBody,
        error
      );
      // log as much info as possible of the error
      console.error(JSON.stringify(error));

      toggleIsVideoMetaDataLoading(false);
      throw error;
    }
  };

  const handleYoutubeInputChange = (youtubeUrl: string) => {
    const youtubeVideoId = isValidYoutubeUrl(youtubeUrl);
    setError("");
    setYoutubeUrl(youtubeUrl);
    setIsYoutubeUrlValid(youtubeVideoId ? true : false);
  };

  const getYoutubeErrorMsg = (errorCode: string) => {
    switch (errorCode) {
      case "livestream":
        return "Sorry, live Youtube videos are not supported. Please try another video instead.";

      case "private":
        return "Sorry, this is a private Youtube videos. Please try another video instead.";

      case "age_restricted":
        return "Sorry, this Youtube video is restricted for certain age groups. Please try another video instead.";

      case "members_only":
        return "Sorry, this Youtube video is restricted to members or subscribers of a particular channel. Please try another video instead.";

      case "recording_unavailable":
      case "unavailable":
        return "Sorry, this Youtube video is no longer available. Please try another video instead.";

      case "region_blocked":
        return "Sorry, this Youtube video is not available in your geographical location. Please try another video instead.";

      default:
        return "Sorry, this YouTube video seems to have some problems. Please try and upload the video instead.";
    }
  };

  const handleUploadYouTubeVideo = async () => {
    setError("");
    setLanguageNotSelectedError("");
    const youtubeVideoId = isValidYoutubeUrl(youtubeUrl);

    if (youtubeVideoId) {
      // addOnBoardingData(null);

      try {
        // added here to save the youtube import preference to firestore
        updateUserPreference({
          isInfoChecked,
          languagePreference: currentLangSelected?.language || null,
        });

        const ytVideoDetails: any = await getVideoDetailsFromYoutube(
          youtubeUrl
        );

        const { reason, title, duration, available } = ytVideoDetails || {};

        const ytOption = {
          id: onBoardingId,
          isYoutubeLocal: true,
          video_id: youtubeVideoId,
          title: title,
          duration: duration,
        };

        const videoDuration = duration / 1000 || 0;

        if (available) {
          checkIfUserAllowedToProceed(videoDuration, () => {
            addOnBoardingData(ytOption);
            handleChangeActiveStep(
              userHasExistingProject
                ? OnBoardingStep.PERSONALIZED_VIDEO
                : OnBoardingStep.SELECT_LAYOUTS
            );
            mutate({
              ytLink: youtubeUrl,
              onBoardingId,
              lang: isPaidUser ? currentLangSelected?.language : "en",
            });

            return;
          });
        } else {
          showNotification(
            getYoutubeErrorMsg(reason),
            notificationType.WARN,
            false
          );
          handleUpdateOnBoardingData({
            processingVideoData: null,
          });
        }
      } catch {
        showNotification(
          "Please try again with another video!",
          notificationType.FAIL,
          false
        );
        handleUpdateOnBoardingData({
          processingVideoData: null,
        });
      }
    } else {
      //  write some code
      showNotification("Invalid youtube URL", notificationType.WARN, false);
      toggleIsVideoMetaDataLoading(false);
    }
  };

  const handleContinue = () => {
    if (!currentLangSelected?.title && isPaidUser) {
      setLanguageNotSelectedError("Select your language before you proceed");
      return;
    }
    toggleIsVideoMetaDataLoading(true);
    if (youtubeUrl) {
      handleUploadYouTubeVideo();
      trackProjectImportEvent("youtube");
      importVideoConversionFbq();
    }
  };

  const updateUserPreference = ({
    isInfoChecked,
    languagePreference,
  }: {
    isInfoChecked: boolean;
    languagePreference: string;
  }) => {
    const requestData = {
      ...firestoreUserPreferenceData,
      allowLowResImport: isInfoChecked,
      languagePreference: languagePreference,
    };
    addUserPreferenceData({
      userId: uid,
      userPreferenceData: requestData,
    });
  };

  const handleCheckbox = () => {
    setIsInfoChecked((prevState) => !prevState);
  };

  useEffect(() => {
    if (
      !currentLangSelected?.title &&
      firestoreUserPreferenceData?.languagePreference &&
      isPaidUser
    ) {
      const language = firestoreUserPreferenceData?.languagePreference || "";
      // @ts-ignore
      setCurrentLangSelected(language ? languageList[language] : null);
    }
  }, [firestoreUserPreferenceData?.languagePreference, isPaidUser]);

  return (
    <div
      className={`relative h-full rounded dark:bg-gray-700 ${
        isVisible ? "" : "hidden"
      }`}
      id="dashboardContainer"
    >
      <div
        className="mx-auto pb-6 px-4 lg:px-6 bg-white flex flex-col items-center justify-center h-full"
        style={{ width: "780px", height: "620px", maxWidth: "780px" }}
      >
        <h2 className="mb-6 text-center text-2xl font-medium tracking-tight text-gray-600 dark:text-white sm:text-3xl -mt-6">
          Import your video
        </h2>
        <div className="">
          {/* <p className="text-red-500 text-xs mt-2 text-center">
            We are currently experiencing difficulties with importing from
            YouTube. Our team is actively working on a solution and we apologize
            for any inconvenience caused. Please try again later.
          </p> */}

          <div className="flex space-x-2 relative">
            <span className="w-full absolute z-10 top-8 flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                />
              </svg>
            </span>

            {/* <H /> */}
            <DashboardComponent
              uppy={uppy}
              width={730}
              height={275}
              proudlyDisplayPoweredByUppy={false}
              showProgressDetails={true}
              plugins={["Box", "GoogleDrive", "Dropbox", "Url"]}
              note="mp4, m4v, mov & webm ONLY - 5GB for Free and 15GB for PRO"
            />
            {/* <p className="absolute text-gray-500 text-xs w-full text-center bottom-5">
              Coming Soon: Dropbox and OneDrive 🚀
            </p> */}
          </div>

          {error && (
            <span className="text-xs text-red-500">
              {error}
              {showBuyMoreMinutes && upgradeToProElement()}
            </span>
          )}
        </div>
        <div className="relative flex w-full items-center py-5">
          <div className="flex-grow border-t border-gray-200"></div>
          <span className="mx-2 flex-shrink text-xs font-light text-gray-500">
            OR
          </span>
          <div className="flex-grow border-t border-gray-200"></div>
        </div>
        <div className="relative w-full flex justify-between items-center">
          <div className="relative w-full">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center ">
              <img
                src={YoutubeIcon}
                alt="Youtube Link"
                className="w-8 h-6 pl-1.5"
              />
            </div>
            <input
              type="search"
              className={clsx(
                "block w-full h-12 rounded border border-gray-300 bg-none p-2.5  text-sm text-gray-900 focus:outline-none  placeholder:font-light placeholder:text-gray-500 rounded-r-none border-r-0 pl-9 focus:ring-0",
                youtubeUrl && !isYoutubeUrlValid
                  ? "focus:border-red-500 border-red-500"
                  : "focus:border-blue-300"
              )}
              value={youtubeUrl}
              onChange={(e) => handleYoutubeInputChange(e.target.value)}
              placeholder="Enter YouTube link (Beta)"
              // disabled
            />
          </div>
          <button
            type="button"
            onClick={() => {
              handleContinue();
              importVideoConversionGtag();
            }}
            disabled={!youtubeUrl}
            className={clsx(
              "flex w-48 items-center justify-center rounded rounded-l-none bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none sm:py-3.5 transform transition ease-in-out",
              isVideoMetaDataLoading ? "cursor-wait opacity-75" : "",
              !youtubeUrl ? "cursor-not-allowed opacity-75" : "",
              youtubeUrl &&
                !isYoutubeUrlValid &&
                "border-red-500 bg-red-500 hover:bg-red-500"
            )}
            id={GENERATE_CLIP_CONTINUE.ID}
          >
            <div className="flex items-center justify-center">
              {isVideoMetaDataLoading && <Spinner className="h-5 w-5" />}
              Start Import
            </div>
          </button>
        </div>
        {youtubeUrl && isYoutubeUrlValid && (
          <>
            <span className="font-light text-orange-400 text-xs mt-1">
              Note: YouTube imports may be inconsistent due to occasional issues
              with YouTube’s servers. We recommend direct video uploads.
            </span>
            <p className="flex justify-start items-center mt-5 w-full transform ">
              <input
                checked={isInfoChecked}
                id="importInfoCheckbox"
                type="checkbox"
                value=""
                onChange={handleCheckbox}
                className="h-5 w-5 cursor-pointer rounded border-gray-300
                text-blue-600 focus:outline-none focus:ring-transparent"
                disabled={!youtubeUrl}
              />
              <label
                htmlFor="importInfoCheckbox"
                className="ml-2 cursor-pointer select-none text-base font-light text-gray-600 dark:text-gray-300"
              >
                If 1080p import is unavailable, import 720p.
              </label>
            </p>
          </>
        )}
        {isPaidUser && (
          <div className="flex items-center mt-4 w-full relative">
            <p className="font-thin text-gray-500 text-sm mr-2">
              Caption Language :{" "}
            </p>
            <div className="relative text-right !z-[1005]">
              <Menu
                as="div"
                className="relative inline-block text-left"
              >
                <div>
                  <Menu.Button
                    className={`inline-flex w-48 justify-between rounded-md  px-2 py-1.5 text-xs font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 border ${
                      languageNotSelectedError
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  >
                    {currentLangSelected?.title ? (
                      <div className="flex items-center">
                        <img
                          src={currentLangSelected?.imgSrc}
                          className="w-6 h-4 mt-0.5"
                        />
                        <span className="ml-2 mt-0.5">
                          {currentLangSelected?.title}
                        </span>
                      </div>
                    ) : (
                      <span
                        className={`mt-0.5 ${
                          languageNotSelectedError && "text-red-500"
                        }`}
                      >
                        Select caption language
                      </span>
                    )}

                    <ChevronDownIcon
                      className={`h-5 w-5  ${
                        languageNotSelectedError
                          ? "text-red-500 hover:text-red-600"
                          : "text-gray-500 hover:text-gray-600"
                      }`}
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-[50] right-0 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bottom-10">
                    <div className="">
                      {Object.values(languageList).map(function (item: any) {
                        return (
                          <Menu.Item key={item.id}>
                            {({ active }) => (
                              <button
                                className={`${
                                  active ? "bg-gray-100" : "text-gray-700"
                                } group flex w-full items-center px-2 py-3`}
                                onClick={() => {
                                  setCurrentLangSelected(item);
                                  setLanguageNotSelectedError("");
                                }}
                              >
                                <img
                                  src={item.imgSrc}
                                  className="w-6 h-4"
                                />
                                <p className="text-gray-700 ml-2">
                                  {item.title}
                                </p>
                              </button>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              {languageNotSelectedError && (
                <p className="text-red-500 text-xs absolute top-10 w-60 -left-1.5">
                  {languageNotSelectedError}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnBoardingUploadFile;
