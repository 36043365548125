import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { Menu, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { PencilSquareIcon, RocketLaunchIcon } from "@heroicons/react/24/solid";

import {
  toggleAddMoreMinModal,
  toggleManageSubscriptionModal,
} from "@/store/homeSlice";
import { useAppSelector } from "@/store/hooks";

import useUserConsumedProcessingTime from "@/api/useUserConsumedProcessingTime";

import {
  AMPLITUDE_CLICK_EVENTS,
  ANALYTICS_CONSTANTS,
} from "@/constants/amplitude";

import { calculateRemainingProcessingTimeInMinutes } from "@/helpers";

import { handelLogout } from "@/utils/FirebaseHelpers";
import {
  addMoreMinutesButtonClick,
  trackProfileIconClickEvent,
} from "@/utils/amplitudeAnalytcs";

import { PlanType, RouterPath } from "@/enums";

import { AccountDeleteModal } from "@/views/home/components/AccountDeleteModal";
import AddMoreMinModal from "@/views/home/components/AddMoreMinModal/AddMoreMinModal";
import { ManageSubscriptionModal } from "@/views/home/components/ManageSubscriptionModal";
import { PasswordChangeModal } from "@/views/home/components/PasswordChangeModal";
import { UpdateNameModal } from "@/views/home/components/UpdateNameModal";

const UserOptionDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [displayNameChangeModal, setDisplayNameChangeModal] =
    useState<boolean>(false);
  const [displayPasswordChangeModal, setDisplayPasswordChangeModal] =
    useState<boolean>(false);
  const [displayProjectDeleteModal, setAccountDeleteModal] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const currentUser = useAppSelector((state) => state.authState.currentUser);

  const userSubscription = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const { planType, isPaddle } = userSubscription;

  const { data: usageData, isLoading: usageDataIsLoading } =
    userSubscription && useUserConsumedProcessingTime();

  const remainingProcessingTime =
    calculateRemainingProcessingTimeInMinutes(usageData);

  const handelRedirectToSubscription = () => {
    navigate(RouterPath.MANAGE_SUBSCRIPTION);
  };

  const handleChangePassword = () => {
    setIsLoading(false);
    setDisplayPasswordChangeModal(true);
  };
  const handleNameChange = () => {
    setIsLoading(false);
    setDisplayNameChangeModal(true);
  };
  const handleAccountDelete = () => {
    trackProfileIconClickEvent(
      ANALYTICS_CONSTANTS.PROFIL_ICON_CLICKED.ACCOUNT_DELETE
    );
    setAccountDeleteModal(true);
  };

  return (
    <div className="flex items-center">
      <UpdateNameModal
        displayNameChangeModal={displayNameChangeModal}
        setDisplayNameChangeModal={setDisplayNameChangeModal}
      />
      <PasswordChangeModal
        displayPasswordChangeModal={displayPasswordChangeModal}
        setDisplayPasswordChangeModal={setDisplayPasswordChangeModal}
      />
      <AccountDeleteModal
        setAccountDeleteModal={setAccountDeleteModal}
        displayProjectDeleteModal={displayProjectDeleteModal}
      />
      <AddMoreMinModal remainingMins={remainingProcessingTime} />
      {planType !== PlanType.FREE && <ManageSubscriptionModal />}
      <Menu
        as="div"
        className="relative inline-block text-left"
      >
        <Menu.Button className={`flex`}>
          {currentUser?.photoURL ? (
            <img
              className="h-8 w-8 rounded-lg"
              src={currentUser?.photoURL}
              alt="Avatar"
              referrerPolicy="no-referrer"
            />
          ) : (
            <div className="relative h-8 w-8 overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-600">
              <svg
                className="absolute -left-1 h-10 w-10 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          )}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 top-10 z-[60] w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white px-1 py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {planType !== PlanType.FREE && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-blue-500 text-white" : "text-gray-600"
                    } flex w-full cursor-pointer items-center rounded-md border-none px-2 py-2 text-base`}
                    onClick={() => {
                      dispatch(toggleAddMoreMinModal(true));
                      addMoreMinutesButtonClick(
                        AMPLITUDE_CLICK_EVENTS.PROFILE_DROPDOWN_MENU
                      );
                    }}
                    id="user-menu-manage-subscription"
                  >
                    <RocketLaunchIcon className="h-5 w-5 mr-2" />
                    <p className="cursor-pointer text-sm font-medium focus:outline-none">
                      Add more minutes
                    </p>
                  </button>
                )}
              </Menu.Item>
            )}

            {planType !== PlanType.FREE && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-blue-500 text-white" : "text-gray-600"
                    } flex w-full cursor-pointer items-center rounded-md border-none px-2 py-2 text-base`}
                    onClick={() =>
                      isPaddle
                        ? dispatch(toggleManageSubscriptionModal(true))
                        : handelRedirectToSubscription()
                    }
                    id="user-menu-manage-subscription"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="mr-1.5 h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                      />
                    </svg>
                    <p className="cursor-pointer text-sm font-medium focus:outline-none">
                      Manage subscription
                    </p>
                  </button>
                )}
              </Menu.Item>
            )}

            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-600"
                  } flex w-full cursor-pointer items-center rounded-md px-2 py-2 text-base`}
                  onClick={handleNameChange}
                  id="user-menu-change-name"
                >
                  <PencilSquareIcon className="mr-1.5 h-5 w-5" />
                  <p className="cursor-pointer text-sm font-medium focus:outline-none">
                    Edit Name
                  </p>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-600"
                  } flex w-full cursor-pointer items-center rounded-md px-2 py-2 text-base`}
                  onClick={handleChangePassword}
                  id="user-menu-change-password"
                >
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={`${active && "white"}`}
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mr-1.5 h-5 w-5"
                  >
                    <path d="M288 416v-96a128 128 0 0 1 256 0v96h64v-96c0-106-86-192-192-192s-192 86-192 192v96zM512 704h-64v-64l384-384 64 64-384 384z" />
                    <path d="M544 736H416V608l160-160H192a64.19 64.19 0 0 0-64 64v320a64.19 64.19 0 0 0 64 64h448a64.19 64.19 0 0 0 64-64V576z" />
                  </svg>

                  <p className="cursor-pointer text-sm font-medium focus:outline-none">
                    Change password
                  </p>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-red-500"
                  } flex w-full cursor-pointer items-center rounded-md px-2 py-2 text-base`}
                  id="delete-user-card"
                  onClick={handleAccountDelete}
                >
                  <TrashIcon className="mr-1.5 text-red-500 w-5 h-5" />
                  <p className="cursor-pointer text-sm font-medium focus:outline-none">
                    Delete Account
                  </p>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-red-500"
                  } flex w-full cursor-pointer items-center rounded-md px-2 py-1.5 text-base`}
                  onClick={handelLogout}
                  id="user-menu-logout"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mr-1.5 h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                    />
                  </svg>
                  <p className=" cursor-pointer text-sm  font-medium focus:outline-none">
                    Logout
                  </p>
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
export default UserOptionDropdown;
