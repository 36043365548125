import { DocumentArrowUpIcon } from "@heroicons/react/24/outline";
import { Spinner } from "flowbite-react";

import { toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppDispatch } from "@/store/hooks";

import useGetAssetsCount from "@/api/useGetAssetsCount";

import { ANALYTICS_CONSTANTS } from "@/constants/amplitude";

import { eventsDataToRedux } from "@/utils/amplitudeAnalytcs";

import { ReactComponent as CrownIcon } from "@/assets/icons/crown.svg";

type UploadAssetButtonProps = {
  label: string;
  isLoading?: boolean;
  id: string;
  getInputProps: any;
  isDisabled?: boolean;
  isUploadFont?: boolean;
};
const UploadAssetButton = ({
  label,
  isLoading = false,
  id,
  getInputProps,
  isDisabled,
  isUploadFont,
}: UploadAssetButtonProps) => {
  const dispatch = useAppDispatch();

  const { data: assetCountData, isLoading: assetCountLoading } =
    useGetAssetsCount();

  const handlePaywallButtonClick = () => {
    dispatch(toggleUpgradeToProModal(true));
    eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
  };

  if (!assetCountLoading && !assetCountData?.isAllowedToUpload) {
    return (
      <button
        id={`free-user-consumed-${id}`}
        onClick={handlePaywallButtonClick}
        className={`flex w-full cursor-pointer flex-col items-center justify-center ${
          isUploadFont
            ? "rounded-lg border bg-white border-blue-500 hover:bg-blue-50"
            : "rounded-md bg-blue-600 hover:bg-blue-700"
        }`}
      >
        {" "}
        <p
          className={`flex items-center justify-center ${
            isUploadFont ? "text-blue-700" : "text-white"
          } font-medium text-sm py-3.5`}
        >
          {label} <CrownIcon className="ml-2 w-5 h-5" />
        </p>
      </button>
    );
  }

  return (
    <label
      htmlFor={id}
      className={`flex w-full cursor-pointer flex-col items-center justify-center rounded-md bg-blue-600
        ${isLoading && "opacity-60 hover:bg-blue-600 cursor-not-allowed"}
        ${
          isUploadFont
            ? "rounded-lg border bg-white text-center text-xs font-medium focus:outline-none border-blue-500 text-blue-500 hover:bg-blue-50"
            : "hover:bg-blue-700"
        }
      `}
    >
      <p
        className={`flex items-center justify-center ${
          isUploadFont ? "text-blue-700" : "text-white"
        } font-medium text-sm py-3`}
      >
        {label}{" "}
        {isLoading && (
          <Spinner
            className="w-4 ml-2"
            size="sm"
          />
        )}
      </p>

      <input
        {...getInputProps()}
        id={id}
        disabled={
          isDisabled || assetCountLoading || !assetCountData?.isAllowedToUpload
        }
      />
    </label>
  );
};

export default UploadAssetButton;
