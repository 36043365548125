import { toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { ANALYTICS_CONSTANTS } from "@/constants/amplitude";

import { eventsDataToRedux } from "@/utils/amplitudeAnalytcs";

import { PlanType } from "@/enums";

import NoTemplateImg from "@/assets/images/illustration-blank-canvas.svg";
import ProFeatureImg from "@/assets/images/pro-only-template.svg";

const EmptyTemplates = () => {
  const dispatch = useAppDispatch();

  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );
  const isPaidUser = planType !== PlanType.FREE;

  return (
    <>
      {isPaidUser ? (
        <div className="col-span-2 mt-40 px-6 text-center text-sm text-[#5E6473]">
          <img
            src={NoTemplateImg}
            className="mx-auto"
          />
          <p className="mx-auto mt-4 mb-6 w-96">
            <span className="font-semibold">My templates</span> are reusable
            templates that allow you to set and save custom styles, backgrounds,
            fonts, logos and more.
          </p>
          <p>
            Click on the <span className="font-semibold">“Save Template”</span>{" "}
            button in the editor to save a template and then view it here.
          </p>
        </div>
      ) : (
        <div className="col-span-2 mt-20 px-6 text-center text-sm text-[#5E6473]">
          <img
            src={ProFeatureImg}
            className="mx-auto"
          />
          <p className="mx-auto mt-4 mb-6 w-96">
            <span className="font-semibold">My templates</span> are reusable
            templates that allow you to set and save custom styles, backgrounds,
            fonts, logos and more.
          </p>

          <p className=" mt-4 mb-1">
            Click on the <span className="font-semibold">“Save Template”</span>{" "}
            button in the editor to save a template and then view it here.
          </p>

          <p className="mb-4">
            This feature is available for{" "}
            <span className="font-semibold">PRO users</span> only.
          </p>

          <button
            type="button"
            id="upgrade-now-templates-tab"
            className={`my-6 mx-auto flex w-40 items-center justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300`}
            onClick={() => {
              dispatch(toggleUpgradeToProModal(true));
              eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
            }}
          >
            Upgrade now
          </button>
        </div>
      )}
    </>
  );
};

export default EmptyTemplates;
