import { store } from "@/store";
import { setAllPaddlePlansPricing } from "@/store/authSlice";

import { getPaddlePlans } from "@/api/requests";

import { isProd } from "@/helpers/browser-storage";
import { getCurrencyByCountryCode } from "@/helpers/common";

import { COUNTRY_CURRENCY_BY_LOCATION } from "@/utils/constants";

import { PlanType } from "@/enums";

const isProduction = isProd();
const MONTHLY_PRO_PLAN_ID = isProduction ? 823167 : 49324;
const YEARLY_PRO_PLAN_ID = isProduction ? 826832 : 49326;
const YEARLY_PRO_PLUS_PLAN_ID = isProduction ? 827550 : 49328;
const MONTHLY_PRO_PLUS_PLAN_ID = isProduction ? 827549 : 49327;
const MONTHLY_STARTER_PLAN_ID = isProduction ? 840921 : 54944;
const YEARLY_STARTER_PLAN_ID = isProduction ? 840922 : 54945;
const MONTHLY_FULL_SERVICE_PLAN_ID = isProduction ? 844081 : 61208;
const YEARLY_FULL_SERVICE_PLAN_ID = isProduction ? 844080 : 61209;
const HALLOWEEN_PRO_PLUS_YEARLY_PLAN_ID = isProduction ? 853974 : 64851;
const HALLOWEEN_PRO_YEARLY_PLAN_ID = isProduction ? 853973 : 64850;

export const getPlanTypeByPlanId = (planId: number) => {
  if (
    planId === MONTHLY_PRO_PLAN_ID ||
    planId === HALLOWEEN_PRO_YEARLY_PLAN_ID ||
    planId === YEARLY_PRO_PLAN_ID
  ) {
    return PlanType.PRO;
  } else if (
    planId === MONTHLY_PRO_PLUS_PLAN_ID ||
    planId === HALLOWEEN_PRO_PLUS_YEARLY_PLAN_ID ||
    planId === YEARLY_PRO_PLUS_PLAN_ID
  ) {
    return PlanType.PRO_PLUS;
  } else if (
    planId === MONTHLY_STARTER_PLAN_ID ||
    planId === YEARLY_STARTER_PLAN_ID
  ) {
    return PlanType.STARTER;
  } else if (
    planId === MONTHLY_FULL_SERVICE_PLAN_ID ||
    planId === YEARLY_FULL_SERVICE_PLAN_ID
  ) {
    return PlanType.FULL_SERVICE;
  }
  return PlanType.FREE;
};

export const planIdInterval: any = {
  [MONTHLY_PRO_PLAN_ID]: "monthly",
  [YEARLY_PRO_PLAN_ID]: "yearly",
  [YEARLY_PRO_PLUS_PLAN_ID]: "yearly",
  [MONTHLY_PRO_PLUS_PLAN_ID]: "monthly",
  [MONTHLY_STARTER_PLAN_ID]: "monthly",
  [YEARLY_STARTER_PLAN_ID]: "yearly",
  [MONTHLY_FULL_SERVICE_PLAN_ID]: "monthly",
  [YEARLY_FULL_SERVICE_PLAN_ID]: "yearly",
  [HALLOWEEN_PRO_YEARLY_PLAN_ID]: "yearly",
  [HALLOWEEN_PRO_PLUS_YEARLY_PLAN_ID]: "yearly",
};

const paddleProductId: any = {
  [PlanType.PRO]: {
    monthly: MONTHLY_PRO_PLAN_ID,
    yearly: YEARLY_PRO_PLAN_ID,
  },
  [PlanType.PRO_PLUS]: {
    monthly: MONTHLY_PRO_PLUS_PLAN_ID,
    yearly: YEARLY_PRO_PLUS_PLAN_ID,
  },
  [PlanType.STARTER]: {
    monthly: MONTHLY_STARTER_PLAN_ID,
    yearly: YEARLY_STARTER_PLAN_ID,
  },
  [PlanType.FULL_SERVICE]: {
    monthly: MONTHLY_FULL_SERVICE_PLAN_ID,
    yearly: YEARLY_FULL_SERVICE_PLAN_ID,
  },
};

export const DEFAULT_PRICE_BY_PLAN_ID: any = {
  [MONTHLY_PRO_PLAN_ID]: "USD 29.99",
  [YEARLY_PRO_PLAN_ID]: "USD 299.99",
  [YEARLY_PRO_PLUS_PLAN_ID]: "USD 499.99",
  [MONTHLY_PRO_PLUS_PLAN_ID]: "USD 49.99",
  [MONTHLY_STARTER_PLAN_ID]: "USD 15",
  [YEARLY_STARTER_PLAN_ID]: "USD 150",
  [MONTHLY_FULL_SERVICE_PLAN_ID]: "USD 299",
  [YEARLY_FULL_SERVICE_PLAN_ID]: "USD 2999",
  [HALLOWEEN_PRO_PLUS_YEARLY_PLAN_ID]: "USD 300",
  [HALLOWEEN_PRO_YEARLY_PLAN_ID]: "USD 180",
};

export const getProductIdByPlanTypeAndDuration = (
  planType: PlanType,
  isAnnual: boolean
): number => {
  return paddleProductId[planType][isAnnual ? "yearly" : "monthly"];
};

export const getPaddlePriceByPlanId = (
  planId: number,
  isPaidAnnuallyPerMonth?: boolean
) => {
  if (!planId) {
    return 0;
  }
  const paddlePlans = store.getState().authState.paddlePlans;

  if (!paddlePlans || !paddlePlans[planId]) {
    return DEFAULT_PRICE_BY_PLAN_ID[planId];
  }

  const userSubscription = store.getState().authState.userSubscription;
  const paddleSubscription = store.getState().authState.paddleSubscription;

  let currency = COUNTRY_CURRENCY_BY_LOCATION.US; // default currency
  if (
    userSubscription.isPaddle &&
    userSubscription.planType !== PlanType.FREE &&
    paddlePlans
  ) {
    currency = paddleSubscription.last_payment?.currency || currency;
  } else {
    const country = store.getState().authState.country;
    currency = getCurrencyByCountryCode(country);
  }

  const paidAnnuallyPerMonthPrice = Math.ceil(
    paddlePlans[planId][currency] / 12
  );

  return isPaidAnnuallyPerMonth
    ? `${currency} ${paidAnnuallyPerMonthPrice.toString()?.replace(".00", "")}`
    : `${currency} ${paddlePlans[planId][currency]?.replace(".00", "")}`;
};

export const getPaddlePriceDetailsByPlanTypeAndInterval = (
  planType: PlanType,
  isAnnual: boolean
) => {
  if (planType === PlanType.FREE) {
    return 0;
  }

  const planId = getProductIdByPlanTypeAndDuration(planType, isAnnual);
  return getPaddlePriceByPlanId(planId);
};

export const loadPaddlePlans = async () => {
  const { response }: any = await getPaddlePlans();
  if (response?.length) {
    let reducedData = response.reduce((acc: any, cur: any) => {
      acc[cur.id] = cur.recurring_price;
      return acc;
    }, {});

    store.dispatch(setAllPaddlePlansPricing(reducedData));
  }
};

export const COST_PER_ADD_ON_MINUTE: any = {
  USD: 0.15,
  ARS: 34,
  AUD: 0.23,
  BRL: 0.75,
  CAD: 0.21,
  CNY: 1.05,
  CZK: 3.25,
  DKK: 1,
  EUR: 0.14,
  HKD: 1.2,
  HUF: 50.0,
  INR: 12.5,
  ILS: 0.55,
  JPY: 20.5,
  MXN: 2.75,
  TWD: 4.7,
  NZD: 0.25,
  NOK: 1.65,
  PLN: 0.65,
  GBP: 0.12,
  RUB: 12.5,
  SGD: 0.2,
  ZAR: 2.75,
  KRW: 200.0,
  SEK: 1.55,
  CHF: 0.14,
  THB: 5.15,
  TRY: 2.98,
  UAH: 5.6,
};
