import { useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";
import { useBoolean } from "react-use";

import {
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  SparklesIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Tooltip, Spinner } from "flowbite-react";
import parseSRT from "parse-srt";

import { updateCaption } from "@/store/editorSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import api from "@/api/api";
import { ApiEndpoints } from "@/api/constants/ApiEndPoints";
import { getCaptionForTranscript, postSocialMediaVideos } from "@/api/requests";
import { fetchSubtitles } from "@/api/useFetchSubtitles";

import { SOCIAL_MEDIA_GUIDELINES_LINK } from "@/constants";

import {
  removeValueFromLocalStorage,
  roundTimeToNextHour,
  sleep,
} from "@/helpers";
import { removeEmojisFromSrtString } from "@/helpers/text";

import {
  schedulePostsEventTrack,
  trackClipExportEvent,
} from "@/utils/amplitudeAnalytcs";
import { notificationType } from "@/utils/constants";
import { auth } from "@/utils/firebase";
import { showNotification } from "@/utils/showNotification";

import { RouterPath, SocialMediaTypes } from "@/enums";

import ExclamationCircle from "@/components/Icons/ExclamationCircle";

import { UploadPostToSocialMediaContext } from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/ShareVideoModal";
import { UploadPostPayloadType } from "@/views/home/components/DownloadVideoCard/SocialMediaSharing/types";

interface tiktokInteractionOptionsType {
  id: "comment" | "duet" | "stitch";
  label: "Comment" | "Duet" | "Stitch";
}

const tiktokInteractionOptions: tiktokInteractionOptionsType[] = [
  {
    id: "comment",
    label: "Comment",
  },
  {
    id: "duet",
    label: "Duet",
  },
  {
    id: "stitch",
    label: "Stitch",
  },
];

export const UploadPostsComponent = () => {
  const {
    isFreeAccount,
    activeSocialData,
    downloadClip,
    userInfo,
    videoDuration,
    handleUnlinkAccount,
    isRevokeAccessLoading,
    isPostUploading,
    isDraftUploading,
    setIsPostUploading,
    setIsDraftUploading,
    storePlatformOnLocalStorage,
    closeSuccessScreen,
    isUploadPostSuccess,
    isUploadDraftSuccess,
    setIsUploadDraftSuccess,
    setIsUploadPostSuccess,
    setScheduleDate,
    scheduleDate,
  } = useContext(UploadPostToSocialMediaContext);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pollAbortController = useRef<any>(null);

  const { uid } = useAppSelector((state) => state.authState.currentUser);

  const { projectId, clip_start, clip_end, renderedVideoUrl, clip_id } =
    downloadClip;

  const {
    creator_username,
    privacy_level_options,
    comment_disabled,
    duet_disabled,
    stitch_disabled,
    max_video_post_duration_sec,
    linkedAccounts,
  } = userInfo;
  const {
    maxCharForDescription,
    maxCharForCaptions,
    label,
    id: activeSocialId,
    maxCharForTitle,
  } = activeSocialData;

  const isTiktokActive = activeSocialId === SocialMediaTypes.TIKTOK;
  const isInstagramActive = activeSocialId === SocialMediaTypes.INSTAGRAM;
  const isFacebookActive = activeSocialId === SocialMediaTypes.FACEBOOK;
  const isYoutubeActive = activeSocialId === SocialMediaTypes.YOUTUBE;
  const isTwitterActive = activeSocialId === SocialMediaTypes.TWITTER;
  const isLinkedinActive = activeSocialId === SocialMediaTypes.LINKEDIN;

  const getPrivacySettingLabel = (value: string) =>
    value &&
    value
      .split("_")
      .map((word) => `${word[0]}${word.slice(1).toLowerCase()}`)
      .join(" ");
  const [description, setDescription] = useState<string>("");
  const [isDescriptionLoading, setIsDescriptionLoading] =
    useState<boolean>(false);
  const [tiktokInteractionAbilityDisabled, setInteractionAbility] = useState({
    comment: comment_disabled,
    duet: duet_disabled,
    stitch: stitch_disabled,
  });
  const [tiktokPrivacyLevel, settiktokPrivacyLevel] = useState<string>("");
  const [showUnlinkConfirmation, setShowUnlinkConfirmation] =
    useState<boolean>(false);
  const [shareInstaReelsToFeed, setShareInstaReelToFeed] = useBoolean(false);
  const [youtubeTitle, setYoutubeTitle] = useState<string>("");
  const [isYoutubeMadeForKids, setIsYoutubeMadeForKids] = useState<
    boolean | undefined
  >(undefined);
  const [isPostSchedule, setIsPostSchedule] = useState(false);

  const onDescriptionChange = (value: string) => {
    if (value.length > maxCharForDescription) {
      showNotification(
        `Please limit your description to ${maxCharForDescription} characters before posting`,
        notificationType.FAIL
      );
      return;
    }
    setDescription(value);
  };

  const handleTiktokInteractionOptions = (
    value: "comment" | "duet" | "stitch"
  ) => {
    setInteractionAbility((prevState) => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  const handleShareInstaReels = () => {
    setShareInstaReelToFeed((prevState: boolean) => !prevState);
  };

  const handleYoutubeMadeForKids = () => {
    setIsYoutubeMadeForKids((prevState: any) => !prevState);
  };

  const handleGenerateDescription = async () => {
    setIsDescriptionLoading(true);
    let subsArr: any = [];

    const { data }: any = await fetchSubtitles({
      chars_per_caption: 25,
      project_id: projectId,
      start_ts: clip_start,
      end_ts: clip_end,
      clip: true,
    });
    const srtString = data.srt_string;
    subsArr = parseSRT(srtString);

    try {
      if (subsArr.length === 0) {
        showNotification(
          "Enable subtitles to generate description",
          notificationType.FAIL
        );
        return;
      }
      const subsWithoutEmojis = removeEmojisFromSrtString(subsArr);
      // concat subs arr to text transcript
      const textTranscript = subsWithoutEmojis.map((sub) => sub.text).join(" ");

      const captionText: any =
        (await getCaptionForTranscript(
          textTranscript,
          label,
          maxCharForCaptions
        )) || [];

      if (captionText?.length === 0) {
        showNotification(
          "Could not generate description. Please try again",
          notificationType.FAIL
        );
        return;
      } else {
        onDescriptionChange(captionText);
        dispatch(
          updateCaption({
            transcript: textTranscript,
            text: captionText,
            social: activeSocialData,
          })
        );
      }
    } catch (error) {
      showNotification(
        "Cannot generate caption. Please try again",
        notificationType.FAIL
      );
    }
    setIsDescriptionLoading(false);
  };

  const onYoutubeTitleChange = (value: string) => {
    if (value.length > maxCharForTitle) {
      showNotification(
        `Please limit your title to ${maxCharForTitle} characters before posting`,
        notificationType.FAIL
      );
    } else {
      setYoutubeTitle(value);
    }
  };

  const onPrivacyChange = (value: string) => {
    settiktokPrivacyLevel(value);
  };

  // polling post-status api for every 3secs till we get response from webhooks
  const pollForTiktokSocialMediaUpdates = async (
    publishId: string,
    platformName: string
  ) => {
    try {
      let controller;
      if (!pollAbortController.current) {
        controller = new AbortController();
        pollAbortController.current = controller;
      } else {
        controller = pollAbortController.current;
      }

      const user = auth.currentUser;
      const token = await user!.getIdToken(false);

      const response: any = await api.get(
        ApiEndpoints.SOCIAL_MEDIA_POST_UPDATES(uid, publishId),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        storePlatformOnLocalStorage(platformName);

        if (isFreeAccount) {
          if (response.data.event === "post.publish.inbox_delivered") {
            setIsUploadDraftSuccess({
              [platformName]: true,
            });
          } else if (response.data.event === "post.publish.complete") {
            setIsUploadPostSuccess({
              [platformName]: true,
            });
            trackClipExportEvent(projectId, clip_id, label);
          }
        } else {
          setIsUploadPostSuccess({
            [platformName]: true,
          });
          trackClipExportEvent(projectId, clip_id, label);
        }
      } else if (
        response.status === 202 &&
        response.data?.message === "Webhooks response pending"
      ) {
        await sleep(3000);
        await pollForTiktokSocialMediaUpdates(publishId, platformName);
      } else {
        showNotification(
          `Something went wrong. Please try again.`,
          notificationType.FAIL
        );
      }
      setIsDraftUploading(false);
      setIsPostUploading(false);
    } catch (e: any) {
      setIsDraftUploading(false);
      setIsPostUploading(false);

      if (e?.message === "canceled") {
        pollAbortController.current = null;
      } else {
        showNotification(
          `Something went wrong. Please try again.`,
          notificationType.FAIL
        );
      }
    }
  };

  const handleFreeUploads = async (platformName: string) => {
    // made drafts false by default
    const isDrafts: boolean = false;

    if (isDrafts) {
      setIsDraftUploading(true);
    } else {
      setIsPostUploading(true);
    }

    let payload: UploadPostPayloadType = {
      user_id: uid,
      video_url: renderedVideoUrl,
      free_version: {
        draft: isDrafts,
      },
    };

    if (!isDrafts) {
      payload = {
        ...payload,
        free_version: {
          ...payload.free_version,
          direct_post_info: {
            title: description,
            privacy_level: tiktokPrivacyLevel,
            disable_duet: tiktokInteractionAbilityDisabled.duet,
            disable_comment: tiktokInteractionAbilityDisabled.comment,
            disable_stitch: tiktokInteractionAbilityDisabled.stitch,
          },
        },
      };
    }

    if (videoDuration <= max_video_post_duration_sec) {
      try {
        const response: any = await postSocialMediaVideos(payload);
        if (response.status === 200) {
          const publishId = response.data.data.publish_id;
          await pollForTiktokSocialMediaUpdates(publishId, platformName);
        }
      } catch (error: any) {
        const errorData = error.response.data;
        const errorMessage = errorData?.error;

        if (errorMessage) {
          showNotification(errorMessage, notificationType.FAIL);
          setIsPostUploading(false);
          return;
        }

        if (errorData?.includes("spam")) {
          showNotification(
            "You made too many posts which is encountered as spam risk. Please try again in sometime.",
            notificationType.FAIL
          );
        } else {
          showNotification(
            `Something went wrong. Please try again later.`,
            notificationType.FAIL
          );
        }

        if (isDrafts) {
          setIsDraftUploading(false);
        } else {
          setIsPostUploading(false);
        }
      }
    } else {
      showNotification(
        "Video duration is longer than expected",
        notificationType.FAIL
      );
      if (isDrafts) {
        setIsDraftUploading(false);
      } else {
        setIsPostUploading(false);
      }
    }
  };

  const getPaidUploadsPayload = () => {
    let payload: UploadPostPayloadType = {
      user_id: uid,
      video_url: renderedVideoUrl,
      paid_version: {},
    };
    if (scheduleDate) {
      payload["paid_version"] = {
        schedule_dt: new Date(scheduleDate).toISOString(),
      };
    }
    switch (activeSocialId) {
      case "tiktok": {
        payload["paid_version"] = {
          ...payload["paid_version"],
          tiktok_payload: {
            post_description: description,
            tikTokOptions: {
              privacyLevel: tiktokPrivacyLevel,
              disableDuet: tiktokInteractionAbilityDisabled.duet,
              disableComments: tiktokInteractionAbilityDisabled.comment,
              disableStitch: tiktokInteractionAbilityDisabled.stitch,
            },
          },
        };
        return payload;
      }
      case "facebook": {
        const isReels = videoDuration < 90;
        payload["paid_version"] = {
          ...payload["paid_version"],
          facebook_payload: {
            post_description: description,
            facebookOptions: {
              reels: isReels,
            },
          },
        };
        return payload;
      }
      case "instagram": {
        payload["paid_version"] = {
          ...payload["paid_version"],
          instagram_payload: {
            post_description: description,
            instagramOptions: {
              reels: true,
              shareReelsFeed: shareInstaReelsToFeed,
            },
          },
        };
        return payload;
      }
      case "linkedin": {
        payload["paid_version"] = {
          ...payload["paid_version"],
          linkedin_payload: {
            post_description: description,
          },
        };
        return payload;
      }
      case "twitter": {
        payload["paid_version"] = {
          ...payload["paid_version"],
          twitter_payload: {
            post_description: description,
            // long video false by default
            twitterOptions: {
              longVideo: false,
            },
          },
        };
        return payload;
      }
      case "youtube": {
        payload["paid_version"] = {
          ...payload["paid_version"],
          youtube_payload: {
            post_description: description,
            youtubeOptions: {
              title: youtubeTitle,
              visibility: "public",
              // shorts true by default
              shorts: true,
              madeForKids: isYoutubeMadeForKids === undefined ? false : true,
            },
          },
        };
        return payload;
      }
      default: {
        break;
      }
    }
  };

  const handlePaidUploads = async (platformName: string) => {
    setIsPostUploading(true);

    const payload = getPaidUploadsPayload();
    try {
      const response: any = await postSocialMediaVideos(payload!);
      if (response.status === 200) {
        const publishId = response.data.data.publish_id;
        if (publishId) {
          if (isTiktokActive || scheduleDate) {
            await pollForTiktokSocialMediaUpdates(publishId, platformName);
            scheduleDate &&
              schedulePostsEventTrack(projectId, clip_id, label, scheduleDate);
          } else {
            setIsUploadPostSuccess({
              [activeSocialId]: true,
            });
            setIsPostUploading(false);
            trackClipExportEvent(projectId, clip_id, label);
            storePlatformOnLocalStorage(platformName);
          }
        }
      }
    } catch (error: any) {
      const errorData = error.response.data;
      const errCode = errorData?.posts && errorData?.posts[0]?.errors[0]?.code;
      const errStatus = error.response.status;
      const errorMessage = errorData?.error;
      setIsPostUploading(false);

      if (errorMessage.includes("Missing post parameter")) {
        showNotification(
          "Please check if all the required fields are filled",
          notificationType.FAIL
        );
      } else if (errorMessage) {
        showNotification(errorMessage, notificationType.FAIL);
        return;
      }

      if (
        errStatus === 400 &&
        errorData?.message === "Cannot schedule on back dates & time"
      ) {
        showNotification(
          "Cannot schedule on back dates & time. Please change the schedule date!",
          notificationType.FAIL
        );
      } else if (errCode === 137) {
        showNotification(
          `Duplicate or similar content posted within the same three day period. Please try uploading different content`,
          notificationType.FAIL
        );
      } else {
        showNotification(
          `Something went wrong. Please try again later.`,
          notificationType.FAIL
        );
      }
    }
  };

  const handleUploadPost = (platformName: string) => {
    if (isFreeAccount) {
      handleFreeUploads(platformName);
    } else {
      handlePaidUploads(platformName);
    }
  };

  const handleUnlinkConfirm = () => {
    handleUnlinkAccount();
    setShowUnlinkConfirmation(false);
  };

  const getDisplayName = () => {
    if (isFreeAccount) {
      return creator_username;
    } else {
      if (linkedAccounts?.includes(activeSocialId)) {
        let userName = `${activeSocialId}_username`;
        return userInfo[userName];
      }
    }
  };

  const publishBtnDisabled = () => {
    if (isYoutubeActive) {
      return isYoutubeMadeForKids === null || !youtubeTitle || !description;
    } else if (isLinkedinActive) {
      return !description;
    } else {
      return false;
    }
  };

  const getPostsInfo = () => {
    let infoMsg = "";
    if (isTiktokActive) {
      infoMsg = `Only 2 videos per minute are allowed with an upper limit of 25 per day.`;
    } else if (isInstagramActive) {
      infoMsg = `Only 20 posts are allowed per day.`;
    } else if (isFacebookActive) {
      infoMsg = `Only 25 posts are allowed per day.`;
    } else if (isLinkedinActive) {
      infoMsg = `Only 50 posts are allowed per day.`;
    } else if (isYoutubeActive) {
      infoMsg = `Only 10 posts are allowed per day.`;
    } else if (isTwitterActive) {
      infoMsg = `Maximum allowed video length is of 2 minutes and 20 seconds. Only 100 posts are allowed per day`;
    } else {
      return null;
    }
    return (
      <span>
        {infoMsg}{" "}
        <a
          href={SOCIAL_MEDIA_GUIDELINES_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500"
        >
          Click to know more
        </a>
      </span>
    );
  };

  const handleCalendarChange = (value: any) => {
    setIsPostSchedule(value ? true : false);
    setScheduleDate(value);
  };

  const handleDefaultCalendarChange = (value: any) => {
    // if today's date round currentTime to next hour else set default time(12.00 am)
    const currentDate = new Date().getDate();
    const roundedTime = roundTimeToNextHour(new Date());
    if (value.getDate() === currentDate) {
      handleCalendarChange(roundedTime);
    } else {
      handleCalendarChange(value);
    }
  };

  useEffect(() => {
    let timer: any = null;
    if (closeSuccessScreen) {
      if (
        (isUploadDraftSuccess[activeSocialId] ||
          isUploadPostSuccess[activeSocialId]) &&
        !scheduleDate
      ) {
        timer = setTimeout(() => {
          setIsUploadDraftSuccess(false);
          setIsUploadPostSuccess(false);
          setDescription("");
          removeValueFromLocalStorage("lastPublishedPlatform");
        }, 3000);
      }
    }
    return () => clearTimeout(timer);
  }, [isUploadDraftSuccess, isUploadPostSuccess]);

  useEffect(() => {
    setInteractionAbility({
      comment: comment_disabled,
      duet: duet_disabled,
      stitch: stitch_disabled,
    });
  }, [userInfo]);

  if (isDraftUploading || isPostUploading) {
    return (
      <div className="mt-12 relative flex items-center justify-center flex-col gap-3 h-[200px]">
        <Spinner className="w-[32px] h-[32px]" />
        <div className="text-base font-medium text-black">
          {scheduleDate ? `Scheduling your post..` : `Publishing your video..`}
        </div>
      </div>
    );
  }

  if (
    isUploadDraftSuccess[activeSocialId] ||
    isUploadPostSuccess[activeSocialId]
  ) {
    return (
      <div className="mt-12 relative flex items-center justify-center flex-col gap-3 h-[200px]">
        <CheckCircleIcon className="text-green-500 w-[32px] h-[32px]" />
        <div className="text-base font-medium text-black">
          {scheduleDate ? (
            <p>
              Video scheduled successfully! Check{" "}
              <span
                onClick={() => navigate(RouterPath.SCHEDULED_POSTS)}
                className="cursor-pointer text-blue-500"
              >
                here
              </span>
            </p>
          ) : (
            "Video published successfully!"
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="mt-12 relative">
      <div className="flex items-center relative gap-[65px]">
        <h4 className="text-base font-medium">Posting as</h4>
        <div className="w-[50%] h-[42px] pt-2 flex items-center">
          {getDisplayName()}
          {getPostsInfo() && (
            <Tooltip
              content={getPostsInfo()}
              placement="right"
              className="w-56 inline-block"
            >
              <ExclamationCircle
                className="ml-2 text-yellow-400 hover:text-yellow-500 cursor-pointer"
                width={20}
                height={20}
              />
            </Tooltip>
          )}
        </div>
        <div className="absolute right-0.5">
          {showUnlinkConfirmation ? (
            <div className="flex items-center">
              <span className="text-sm font-medium mr-4">Are you sure?</span>
              <button
                type="button"
                onClick={handleUnlinkConfirm}
                className={`inline-flex items-center justify-center  
          rounded bg-white border border-red-600 h-8 w-8 focus:outline-none mr-4 cursor-pointer`}
              >
                <CheckIcon className="w-5 h-5 text-red-700" />
              </button>
              <button
                type="button"
                onClick={() => setShowUnlinkConfirmation(false)}
                className={`inline-flex items-center justify-center  
          rounded bg-white border border-green-500 h-8 w-8 focus:outline-none cursor-pointer`}
              >
                <XMarkIcon className="w-5 h-5 text-red-700" />
              </button>
            </div>
          ) : (
            <button
              className={clsx(
                "bg-none rounded h-9 text-xs font-medium text-red-500 flex items-center justify-center border border-red-500",
                isRevokeAccessLoading ? "w-32" : "w-28"
              )}
              onClick={() => setShowUnlinkConfirmation(true)}
            >
              {isRevokeAccessLoading && (
                <Spinner className="w-[16px] h-[16px] mr-2" />
              )}
              Unlink Account
            </button>
          )}
        </div>
      </div>
      <div className="mt-5 flex items-start relative gap-[15px]">
        <h4 className="text-sm font-medium w-[138px]">
          {isYoutubeActive ? (
            <span>
              Video Description
              <span className="text-red-400 -top-[10px] ml-[2px]">*</span>
            </span>
          ) : (
            "Video caption"
          )}
        </h4>
        <textarea
          value={isDescriptionLoading ? "Loading.." : description}
          id="descriptionInput"
          name="postDescription"
          rows={5}
          className="w-[80%] h-[145px] border border-gray-300 rounded mt-1 bg-gray-50 leading-tight text-sm font-normal text-gray-700 pr-[50px]"
          onChange={(event) => onDescriptionChange(event.target.value)}
          placeholder={`Add video description for ${activeSocialData.label} here
Generate with AI or just write your own`}
        />
        <button
          className="absolute top-[12px] right-[1.5%] w-[36px] h-[36px] border rounded border-gray-200 hover:bg-gray-200 flex items-center justify-center"
          onClick={handleGenerateDescription}
        >
          <Tooltip
            content={"Generate AI Description"}
            placement="right"
            className="w-28"
          >
            <SparklesIcon className="cursor-pointer w-6 h-6 text-yellow-300" />
          </Tooltip>
        </button>

        <span className="absolute text-xs font-medium text-gray-500 top-[125px] right-[1.5%] width-[125px]">
          {description.length} / {maxCharForDescription}
        </span>
      </div>
      {isYoutubeActive && (
        <div className="flex mt-5 gap-[52px] relative">
          <h4 className="text-base font-medium">
            Video Title
            <span className="text-red-400 -top-[10px] ml-[2px]">*</span>
          </h4>
          <input
            className="w-[507px] h-[50px] border border-gray-300 rounded mt-1 bg-gray-50 leading-tight text-sm font-normal text-gray-700 pl-[10px]"
            value={youtubeTitle}
            onChange={(e) => onYoutubeTitleChange(e.target.value)}
            placeholder="Youtube Title"
          />
          <span className="absolute text-xs font-medium text-gray-500 top-[30px] right-[1.5%]">
            {youtubeTitle.length} / {maxCharForTitle}
          </span>
        </div>
      )}
      {isTiktokActive && (
        <>
          <div className="flex mt-5 gap-[35px]">
            <h4 className="text-base font-medium">Allow users to</h4>
            <div>
              {tiktokInteractionOptions.map((option) => {
                const { id: optionId, label } = option;
                return (
                  <span key={optionId}>
                    <input
                      id={`tiktok-${optionId}`}
                      type="checkbox"
                      value=""
                      checked={!tiktokInteractionAbilityDisabled[optionId]}
                      onChange={() => handleTiktokInteractionOptions(optionId)}
                      className="h-4 w-4 cursor-pointer rounded border-gray-300 bg-gray-100 
                text-blue-600 focus:ring-2 focus:ring-blue-500 
                dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 mb-0.5"
                    />
                    <label
                      htmlFor={`tiktok-${optionId}`}
                      className="ml-2 mr-5 cursor-pointer text-sm font-medium text-gray-600 dark:text-gray-300 "
                    >
                      {label}
                    </label>
                  </span>
                );
              })}
            </div>
          </div>
          {/* Removing privacy settings since Ayrshare doesnt allow */}
          {/* <div className="flex mt-5">
            <h4 className="text-base font-medium">
              Privacy Setting
              <span className="text-red-400 -top-[10px] ml-[2px]">*</span>
            </h4>
            <div className="ml-4">
              {privacy_level_options?.map((type: string) => {
                return (
                  <span key={type}>
                    <input
                      onChange={() => onPrivacyChange(type)}
                      id={`tiktok-privacy-settings-${type}`}
                      type="radio"
                      checked={tiktokPrivacyLevel === type}
                      name={`tiktok-privacy-settings-${type}`}
                      className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 cursor-pointer"
                    />
                    <label
                      htmlFor={`tiktok-privacy-settings-${type}`}
                      className={`ml-2 mr-3 text-gray-600 dark:text-gray-300 text-sm cursor-pointer`}
                    >
                      {getPrivacySettingLabel(type)}
                    </label>
                  </span>
                );
              })}
            </div>
          </div> */}
        </>
      )}

      {!isFreeAccount && (
        <div className="flex mt-5 items-center">
          <h4 className="text-base font-medium">Schedule Post</h4>
          {scheduleDate ? (
            <div className="ml-8">
              <DatePicker
                selected={scheduleDate}
                onChange={(date) => handleCalendarChange(date)}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                minDate={new Date()}
                className="border-gray-300 rounded w-[195px] h-[40px] text-gray-600 focus:outline-none focus:ring-transparent focus:text-gray-900 hover:text-gray-900"
              />
            </div>
          ) : (
            <div className="ml-8 mt-1">
              <DatePicker
                selected={scheduleDate}
                onChange={(date) => handleDefaultCalendarChange(date)}
                customInput={
                  <CalendarIcon className="text-gray-600 w-6 h-6 cursor-pointer" />
                }
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                minDate={new Date()}
              />
            </div>
          )}
        </div>
      )}
      <div className="flex justify-end gap-3 mt-6 items-center">
        {/* Commenting drafts for now */}
        {/* <button
          className="bg-gray-800 cursor-pointer rounded w-28 h-8 text-xs font-medium text-blue-50"
          onClick={() => handleUploadPost(true)}
        >
          Upload as draft
        </button> */}
        {isInstagramActive && (
          <div>
            <input
              id={`instagram-share-reels-to-feed`}
              type="checkbox"
              value=""
              checked={shareInstaReelsToFeed}
              onChange={handleShareInstaReels}
              className="h-4 w-4 cursor-pointer rounded border-gray-300 bg-gray-100 
        text-blue-600 focus:ring-2 focus:ring-blue-500 
        dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 mb-0.5"
            />
            <label
              htmlFor={`instagram-share-reels-to-feed`}
              className="ml-2 mr-5 cursor-pointer text-sm font-medium text-gray-600 dark:text-gray-300 "
            >
              Share Reels in Feed
            </label>
          </div>
        )}
        {isYoutubeActive && (
          <div>
            <input
              id={`youtube-made-for-kids`}
              type="checkbox"
              value=""
              checked={isYoutubeMadeForKids}
              onChange={handleYoutubeMadeForKids}
              className="h-4 w-4 cursor-pointer rounded border-gray-300 bg-gray-100 
       text-blue-600 focus:ring-2 focus:ring-blue-500 
       dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 mb-0.5"
            />
            <label
              htmlFor={`youtube-made-for-kids`}
              className="ml-2 mr-5 cursor-pointer text-sm font-medium text-gray-600 dark:text-gray-300 "
            >
              Made for Kids
            </label>
          </div>
        )}
        <button
          className={clsx(
            "bg-primary-600 rounded cursor-pointer h-8 text-xs font-medium text-white w-[135px]",
            {
              "opacity-80 cursor-not-allowed pointer-events-none":
                publishBtnDisabled(),
            }
          )}
          onClick={() => {
            handleUploadPost(activeSocialId);
          }}
        >
          {isPostSchedule ? `Schedule Post` : ` Publish to ${label}`}
        </button>
      </div>
    </div>
  );
};
