import { useState } from "react";
import { useDispatch } from "react-redux";

import clsx from "clsx";

import { toggleUpgradeToProModal } from "@/store/homeSlice";
import { useAppSelector } from "@/store/hooks";

import { PlanType, ScheduledPostsMenu } from "@/enums";

import { CompletedPosts } from "./partials/completedPosts";
import { ScheduledPosts } from "./partials/scheduledPosts";

export const ScheduledPostsComponent = () => {
  const [currentWorkSpacetab, setCurrentWorkSpacetab] = useState<string>(
    ScheduledPostsMenu.SCHEDULED
  );

  const planType = useAppSelector(
    (state) => state.authState.userSubscription.planType
  );
  const dispatch = useDispatch();

  const isScheduledActive =
    currentWorkSpacetab === ScheduledPostsMenu.SCHEDULED;
  const isCompletedActive =
    currentWorkSpacetab === ScheduledPostsMenu.COMPLETED;

  const isFreeUser =
    planType === PlanType.FREE || planType === PlanType.STARTER;

  const handleUpgradeToPro = () => {
    dispatch(toggleUpgradeToProModal(true));
  };

  if (isFreeUser) {
    return (
      <div className="p-4 pt-[120px] h-full">
        <p className="mb-2 text-center text-3xl">🤩</p>
        <p className=" mb-8 text-center text-2xl font-bold text-gray-900">
          You've discovered a PRO feature!
        </p>

        <div className="mx-auto mb-10 w-fit">
          <p className="mx-auto w-[80%] text-center font-light text-gray-500">
            <span className="font-bold">PRO</span> users can schedule posts on
            social media platforms (Tiktok, Instagram, Twitter and more). To use
            this feature, <span className="font-bold">upgrade to PRO</span>
          </p>
          <div className="mt-8 mx-auto w-fit">
            <button
              className="flex items-center justify-center m-auto text-sm font-medium mt-10 bg-primary-600 hover:bg-primary-700 rounded text-white h-[40px] w-[180px]"
              onClick={handleUpgradeToPro}
            >
              Upgrade to PRO
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="sticky top-0 border-b  border-gray-200 bg-gray-100 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400 rounded-t-lg z-40">
        <ul className="-mb-px flex flex-wrap transition-colors">
          <li
            className="mr-2 cursor-pointer"
            onClick={() => setCurrentWorkSpacetab(ScheduledPostsMenu.SCHEDULED)}
          >
            <p
              className={clsx(
                "inline-block p-4 rounded-t-lg border-b-2",
                isScheduledActive
                  ? "border-blue-600 text-blue-600 dark:text-blue-500"
                  : "border-transparent hover:border-gray-300 hover:text-gray-600"
              )}
            >
              Scheduled
            </p>
          </li>
          <li
            className="mr-2  cursor-pointer"
            onClick={() => setCurrentWorkSpacetab(ScheduledPostsMenu.COMPLETED)}
          >
            <p
              className={clsx(
                "inline-block p-4 rounded-t-lg border-b-2",
                isCompletedActive
                  ? "border-blue-600 text-blue-600 dark:text-blue-500"
                  : "border-transparent hover:border-gray-300 hover:text-gray-600"
              )}
            >
              Completed
            </p>
          </li>
        </ul>
      </div>
      <div className="px-5 pb-8 pt-3 h-full">
        {isScheduledActive && <ScheduledPosts />}
        {isCompletedActive && <CompletedPosts />}
      </div>
    </>
  );
};
