import axios from "axios";

import { store } from "@/store";

import { PLAN_LABEL_TEXT } from "@/constants";
import { ANALYTICS_CONSTANTS, AUDIO_TYPE_USED } from "@/constants/amplitude";

import { ExportQuality, PlanType, SubtitleStyle, VideoLayout } from "@/enums";

import { convertToCamelCase } from "./common";

export const DATE_TIME = {
  HOUR: "hour",
  MINUTE: "minute",
  SECOND: "second",
  DATE: "date",
  MONTH: "month",
  YEAR: "year",
};

export const getOverview = (layout: VideoLayout) => {
  if (layout === VideoLayout.LAYOUT_16_9) return "chapters";
  else if (layout === VideoLayout.LAYOUT_1_1) return "square";
  else return "shorts";
};

export const getEffects = (obj: any) => {
  let returnObj = [];
  if (obj.blockBackground?.enabled)
    returnObj.push(ANALYTICS_CONSTANTS.BLOCK_BACKGROUND);
  if (obj.shadow?.enabled) returnObj.push(ANALYTICS_CONSTANTS.SHADOW);
  if (obj.stroke?.enabled) returnObj.push(ANALYTICS_CONSTANTS.OUTLINE);
  if (obj.textBgColor?.enabled)
    returnObj.push(ANALYTICS_CONSTANTS.TEXT_BACKGROUND);

  return returnObj;
};

export const textEffects = (texts: any) => {
  if (!texts.length) return [];

  const titleText = texts.find((text: any) => text.id === "title-text");
  if (titleText) return getEffects(titleText);
  else return getEffects(texts[0]);
};

export const checkAndReturnSubtitleStyleColor = (subtitleStyle: any) => {
  if (
    subtitleStyle.value === SubtitleStyle.RANDOM_WORD_COLOR_CHANGE ||
    subtitleStyle.value === SubtitleStyle.WORD_APPENDED ||
    subtitleStyle.value === SubtitleStyle.WORD_BACKGROUND_CHANGE ||
    subtitleStyle.value === SubtitleStyle.WORD_COLOR_CHANGE
  )
    return convertToCamelCase(subtitleStyle.color);
  else return "";
};

export const getBRollAssetId = (bRolls: [], type: string) => {
  return bRolls.flatMap((asset: any) => {
    return asset.assetType === type ? asset.id : [];
  }).length
    ? true
    : false;
};

export const getSocialLogos = (socialLogos: any) => {
  return socialLogos.length
    ? socialLogos.flatMap((socialHandle: any) => {
        return socialHandle.image.id;
      })
    : [];
};

export const getClipDuration = (
  currentSelectedMicroContent: any,
  isChapter: boolean
) => {
  if (isChapter)
    return (
      (currentSelectedMicroContent?.chapter_end -
        currentSelectedMicroContent?.chapter_start) /
      1000
    );
  else
    return (
      (currentSelectedMicroContent?.end - currentSelectedMicroContent?.start) /
      1000
    );
};

export const checkAndReturnStockBackground = (
  backgroundType: string,
  key: string,
  backgrounUrl: any,
  backgroundColor: any
) => {
  if (backgroundType === key) {
    return backgrounUrl ? backgrounUrl : backgroundColor;
  }
  return "";
};

export const getExportQualityResolution = (exportQuality: ExportQuality) => {
  switch (exportQuality) {
    case ExportQuality.HD:
      return "720p";
    case ExportQuality.FHD:
      return "1080p";
    case ExportQuality.UHD:
      return "4k";
  }
};

export const getPlanName = (planValue: PlanType) => {
  return PLAN_LABEL_TEXT[planValue];
};

export const getDateTime = (time: any, key: string) => {
  const date = new Date();
  switch (key) {
    case DATE_TIME.HOUR:
      return date.getUTCHours();
    case DATE_TIME.MINUTE:
      return date.getUTCMinutes();
    case DATE_TIME.SECOND:
      return date.getUTCSeconds();
    case DATE_TIME.DATE:
      return date.getUTCDate();
    case DATE_TIME.MONTH:
      return date.getUTCMonth();
    case DATE_TIME.YEAR:
      return date.getUTCFullYear();
  }
};

export const getMonthAsString = (month: any) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[month];
};
export const convertCurrencyIntoUSD = async (
  value: number,
  currency: string
) => {
  try {
    const options = {
      method: "GET",
      url: `https://openexchangerates.org/api/convert/${value}/${currency}/USD?prettyprint=true&app_id=d5f01e70fbc14429ab2dea04aacbe23c`,
      headers: { accept: "application/json" },
    };

    const response: any = await axios.request(options);
    return response.data.response;
  } catch (error: any) {
    console.error("Error fetching exchange rates:", error.message);
    return 0;
  }
};

export const convertToUSD = (value: any, currency: string, rates: any) => {
  if (!(currency in rates)) {
    return value;
  }
  return value / rates[currency];
};

export const getCurrentPlanEndTimeStamp = (
  subsStartDate: number,
  intervalInMonths: number
) => {
  const subscriptionStartedAt = new Date(subsStartDate);

  const nextBillingDate = new Date(subscriptionStartedAt);

  // Add the interval in months to the next billing date
  nextBillingDate.setMonth(nextBillingDate.getMonth() + intervalInMonths);

  // Check if the next billing date is in the next year
  if (nextBillingDate < subscriptionStartedAt) {
    nextBillingDate.setFullYear(nextBillingDate.getFullYear() + 1);
  }

  return {
    currentPlanEndDate: nextBillingDate.getDate(),
    currentPlanEndMonth: getMonthAsString(nextBillingDate.getMonth()),
    currentPlanEndYear: nextBillingDate.getFullYear(),
  };
};

export const getAudioIds = (audioData: any, key: string) => {
  if (!audioData.length) return [];

  if (key === AUDIO_TYPE_USED.BRAND_KIT_AUDIO) {
    return [key, ...audioData.map((audioClip: any) => audioClip.id)];
  } else {
    return audioData.map((audioClip: any) => audioClip.id);
  }
};

export const getDefaultUtmProperties = () => {
  const homeAnalytics = store.getState().amplitudeState.homeScreen;
  const utmData = homeAnalytics?.utmDataObj;

  // Initialize default values
  let utmCampaign = "none";
  let utmSource = "none";
  let utmMedium = "none";

  // Update values if they exist
  if (utmData) {
    utmCampaign = utmData?.utmCampaign || utmCampaign;
    utmSource = utmData?.utmSource || utmSource;
    utmMedium = utmData?.utmMedium || utmMedium;
  }

  // Special case: if none of the three are present
  if (
    !utmData ||
    (!utmData?.utmCampaign && !utmData?.utmSource && !utmData?.utmMedium)
  ) {
    utmCampaign = utmSource = utmMedium = "direct";
  }

  return { utmCampaign, utmMedium, utmSource };
};
