import { isProd, stringToSha256 } from "@/helpers";

const ttq = (window as any).ttq;

const EVENT_NAME = {
  NEW_USER_SIGNUP: "New user signup",
  IMPORT_VIDEO: "Import Video",
  EXPORT_VIDEO: "Export Video",
  NEW_SUBSCRIBER: "New Subscriber",
};

export const identifyUserTikTok = async (user: any) => {
  if (isProd()) {
    const hashedValues: any = await Promise.all([
      stringToSha256(user.email),
      stringToSha256(user.uid),
    ]).catch((e) => console.log("failed to convert string to sha256: ", e));

    ttq.identify({
      email: hashedValues[0],
      external_id: hashedValues[1],
    });
  }
};

export const newUserSignupTiktok = async (user: any) => {
  if (isProd()) {
    const hashedValues: any = await Promise.all([
      stringToSha256(user.email),
      stringToSha256(user.uid),
    ]).catch((e) => console.log("failed to convert string to sha256: ", e));

    ttq.track(EVENT_NAME.NEW_USER_SIGNUP, {
      email: hashedValues[0],
      external_id: hashedValues[1],
    });
  }
};

export const importVideoTrackTikTok = () => {
  if (isProd()) {
    ttq.track(EVENT_NAME.IMPORT_VIDEO);
  }
};

export const exportVideoTrackTikTok = () => {
  if (isProd()) {
    ttq.track(EVENT_NAME.EXPORT_VIDEO);
  }
};

export const purchaseConversionTrackTikTok = async (
  user: any,
  transactionId: any,
  value: any
) => {
  if (isProd()) {
    const hashedValues: any = await Promise.all([
      stringToSha256(user.email),
      stringToSha256(user.uid),
    ]).catch((e) => console.log("failed to convert string to sha256: ", e));

    if (hashedValues[0] && hashedValues[1]) {
      ttq.track(EVENT_NAME.NEW_SUBSCRIBER, {
        email: hashedValues[0],
        external_id: hashedValues[1],
        currency: "USD",
        order_id: transactionId,
        order_value: value,
      });
    }
  }
};
