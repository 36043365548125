import { useState, useEffect, FC } from "react";

const ImageLoader: FC<{
  src: string | undefined;
  originalUrl?: string;
}> = ({ src, originalUrl }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!src) return;
    const img = new Image();
    img.src = src;

    const handleLoad = () => {
      setIsLoading(false);
    };

    const handleError = () => {
      // setIsError(true);
    };

    img.addEventListener("load", handleLoad);
    img.addEventListener("error", handleError);

    return () => {
      img.removeEventListener("load", handleLoad);
      img.removeEventListener("error", handleError);
    };
  }, [src]);

  if (isError) {
    return <div>Error loading image</div>;
  }

  return (
    <>
      {isLoading && (
        <div className="w-full h-full bg-gradient-to-t from-gray-100 to-gray-200 animate-pulse absolute z-10"></div>
      )}
      <img
        src={src}
        alt="Media Content"
        loading="lazy"
        className={`${
          isLoading ? "hidden" : "object-scale-down w-full h-full"
        }`}
        onLoad={() => setIsLoading(false)}
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = originalUrl;
        }}
      />
    </>
  );
};

export default ImageLoader;
