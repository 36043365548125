import { memo } from "react";
import { useDropzone } from "react-dropzone";
import { useOutletContext } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useToggle } from "react-use";

import { useAppSelector } from "@/store/hooks";

import { uploadFileToS3 } from "@/api/requests";
import useUploadAsset from "@/api/useUploadAsset";

import {
  AssetTags,
  EditorMediaTab,
  EditorMediaTabMode,
  SimpleAssetType,
} from "@/enums";

import { UploadAssetButton } from "../UploadAssetButton";

const uploadAndFinalize = async (
  file: File,
  assetTag: AssetTags,
  userId: string,
  finalizeUpload: any,
  assetDuration: number | null = null
) => {
  const res = await uploadFileToS3(file);
  if (res?.s3Url) {
    await finalizeUpload({
      assetType: file.type,
      assetId: res.assetId,
      ownerId: userId,
      parentId: null,
      assetTag,
      data: {
        assetName: file.name,
        remoteUrl: res.s3Url,
        duration: assetDuration,
      },
    });
  }
  return res;
};

const MediaUploader = ({ addGalleryAssetCommand }: any) => {
  const [_, setSearchParams] = useSearchParams();

  const { handleAddAudioAsset, handleAddAsset } = useOutletContext<any>();
  const [isUploadLoading, toggleUploadLoading] = useToggle(false);

  const { mutate: finalizeUpload, isLoading: finalizeUploadLoading } =
    useUploadAsset();

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );
  const userId = useAppSelector((state) => state.authState.currentUser.uid);

  const handleChangeMediaMode = (type: EditorMediaTab) => {
    setSearchParams({
      type: type,
      mode: EditorMediaTabMode.BROWSE,
    });
  };

  const onDrop = async (acceptedFiles: any) => {
    const file = acceptedFiles?.[0];

    if (!file) {
      return;
    }

    try {
      toggleUploadLoading(true);

      const objURL = URL.createObjectURL(file);

      if (file.type?.includes(SimpleAssetType.VIDEO)) {
        Object.assign(file, {
          preview: objURL,
        });

        const video = document.createElement(SimpleAssetType.VIDEO);
        video.src = file.preview;
        video.preload = "metadata";
        video.muted = true;
        video.autoplay = false;
        video.playsInline = true;
        video.addEventListener("loadedmetadata", async () => {
          const res = await uploadAndFinalize(
            file,
            AssetTags.B_ROLL,
            userId,
            finalizeUpload,
            Math.round(video.duration) || 0
          );
          toggleUploadLoading(false);
          res?.s3Url &&
            addGalleryAssetCommand(
              {
                url: res?.public_url || res?.s3Url,
                file: null,
                id: res?.assetId,
                duration: Math.round(video.duration) || 0,
              },
              SimpleAssetType.VIDEO
            );

          handleChangeMediaMode(EditorMediaTab.VIDEO);
          removeAll();
        });
      } else if (file.type?.includes(SimpleAssetType.IMAGE)) {
        const res = await uploadAndFinalize(
          file,
          AssetTags.B_ROLL,
          userId,
          finalizeUpload
        );
        toggleUploadLoading(false);
        res?.s3Url &&
          addGalleryAssetCommand(
            {
              url: res?.public_url || res?.s3Url,
              file: null,
              id: res?.assetId,
            },
            SimpleAssetType.IMAGE
          );

        handleChangeMediaMode(EditorMediaTab.IMAGE);
        removeAll();
      } else if (file.type?.includes(SimpleAssetType.AUDIO)) {
        Object.assign(file, {
          preview: objURL,
        });

        const audio = document.createElement(SimpleAssetType.AUDIO);
        audio.src = file.preview;
        audio.preload = "metadata";
        audio.muted = true;

        audio.addEventListener("loadedmetadata", async () => {
          const res = await uploadAndFinalize(
            file,
            AssetTags.BG_MUSIC,
            userId,
            finalizeUpload,
            Math.round(audio.duration) * 1000 || 0
          );

          toggleUploadLoading(false);
          removeAll();

          if (res?.s3Url) {
            const audioDurationInMilliseconds =
              Math.floor(audio.duration) * 1000 || 0;
            let end =
              audioDurationInMilliseconds + currentSelectedMicroContent.start;

            // If the audio duration is greater than the microcontent duration
            if (
              audioDurationInMilliseconds >
              currentSelectedMicroContent.end -
                currentSelectedMicroContent.start
            ) {
              end = currentSelectedMicroContent.end; // Set the microcontent end as the end value
            }
            handleAddAudioAsset({
              url: res?.public_url || res?.s3Url,
              assetTag: AssetTags.BG_MUSIC,
              assetType: SimpleAssetType.AUDIO,
              id: res?.assetId,
              metaData: {
                start: currentSelectedMicroContent.start,
                end: end,
                duration: audioDurationInMilliseconds,
                volume: 100,
                isFullVideoLength: true,
              },
            });
            handleChangeMediaMode(EditorMediaTab.AUDIO);
          }
        });
      }
    } catch (e: any) {
      toggleUploadLoading(false);
      removeAll();
      console.log("Error while uploading B_ROLL", e);
      return;
    }
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef }: any =
    useDropzone({
      onDrop,
      accept: {
        "audio/mp3": [".mp3"],
        "audio/mpeg": [".mp3"],
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
        "video/mp4": [".mp4"],
      },
      maxFiles: 1,
    });

  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef.current) inputRef.current.value = "";
  };

  return (
    <div>
      <div
        className="mt-3 mb-5 flex w-full items-center justify-center"
        {...getRootProps()}
        onClick={(e) => e.stopPropagation()}
      >
        <UploadAssetButton
          label="Upload a media file"
          id="dropzone-file-media-editor"
          isLoading={isUploadLoading || finalizeUploadLoading}
          getInputProps={getInputProps}
          isDisabled={isUploadLoading || finalizeUploadLoading}
        />
      </div>
    </div>
  );
};

export default memo(MediaUploader);
