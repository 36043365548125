import { ApiEndpoints } from "@/api/constants";
import { createPaidUserOauthDetails } from "@/api/requests";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

import { DownloadedClipType, SocialName } from "@/enums";

import {
  FbIcon,
  InstagramIcon,
  TiktokIcon, // YoutubeIcon,
  YoutubeShortsIcon,
  LinkedinIcon,
  TwitterIcon,
} from "./icons";

export const socialMediaTabsList = [
  {
    id: "tiktok",
    label: SocialName.TIKTOK,
    icon: (fillColor: string) => <TiktokIcon fillColor={fillColor} />,
    maxCharForCaptions: 2200,
    maxCharForDescription: 2200,
  },
  {
    id: "facebook",
    label: SocialName.FACEBOOK,
    icon: (fillColor: string) => <FbIcon fillColor={fillColor} />,
    maxCharForCaptions: 200,
    maxCharForDescription: 200,
  },
  {
    id: "instagram",
    label: SocialName.INSTAGRAM,
    icon: (fillColor: string) => <InstagramIcon fillColor={fillColor} />,
    maxCharForCaptions: 150,
    maxCharForDescription: 2200,
  },
  {
    id: "linkedin",
    label: SocialName.LINKEDIN,
    icon: (fillColor: string) => <LinkedinIcon fillColor={fillColor} />,
    maxCharForCaptions: 1000,
    maxCharForDescription: 3000,
  },
  {
    id: "twitter",
    label: SocialName.TWITTER,
    icon: (fillColor: string) => <TwitterIcon fillColor={fillColor} />,
    maxCharForCaptions: 180,
    maxCharForDescription: 180,
  },
  {
    id: "youtube",
    label: SocialName.YOUTUBE_SHORTS,
    icon: (fillColor: string) => <YoutubeShortsIcon fillColor={fillColor} />,
    maxCharForCaptions: 150,
    maxCharForDescription: 5000,
    maxCharForTitle: 100,
  },
];

export const videoDimensions = {
  [DownloadedClipType.PORTRAIT]: {
    width: 220,
    height: 340,
    buttonPlacement: 150,
  },
  [DownloadedClipType.SQUARE]: {
    width: 350,
    height: 350,
    buttonPlacement: 150,
  },
  [DownloadedClipType.LANDSCAPE]: {
    width: 448,
    height: 252,
    buttonPlacement: 100,
  },
  [DownloadedClipType.BULK]: {
    width: 448,
    height: 252,
    buttonPlacement: 100,
  },
};

// get oauth details for paid user
export const createPaidUserOauth = async (user_id: string) => {
  try {
    const result = await createPaidUserOauthDetails(user_id);
    if (result.status === 200) {
      const response: any = result.data;
      const redirectUrl = response?.url;
      window.location.replace(
        `${redirectUrl}&redirect=${ApiEndpoints.AYRSHARE_REDIRECT_URL}`
      );
    }
  } catch (err: any) {
    showNotification(
      "Something went wrong. Please try again",
      notificationType.FAIL
    );
  }
};
