import { memo } from "react";

import { useSelectedClips } from "@/context/ReviewClips";
import { Button, Spinner } from "flowbite-react";

import {
  setCurrentSelectedClip,
  updateCurrentSelectedMicroContent,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useDeleteBulkClips from "@/api/useDeleteBulkClips";

import { PreviewScreenClipTypes } from "@/enums";

import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";

type MultipleClipsClipDeleteModalProps = {
  setIsClipDeleteModal: (value: boolean) => void;
  isClipDeleteModal: boolean;
  projectData: any;
  currentType: PreviewScreenClipTypes;
  setClipDeleteLoading: (value: boolean) => void;
};

const MultipleClipsDeleteConfirmationModal = ({
  setIsClipDeleteModal,
  isClipDeleteModal,
  projectData,
  currentType,
  setClipDeleteLoading,
}: MultipleClipsClipDeleteModalProps) => {
  const dispatch = useAppDispatch();

  const { selectedClipsCount, selectedClips, setSelectedClips } =
    useSelectedClips();

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const { mutate: deleteBulkClips, isLoading: deleteBulkClipsLoading } =
    useDeleteBulkClips();

  const deleteCurrentSelectedMicroContent = () => {
    const params = {
      projectId: projectData.id,
      clips: [],
    } as any;

    selectedClips.forEach((clip) => {
      params.clips.push({
        start: clip.start,
        end: clip.end,
        type:
          currentType === PreviewScreenClipTypes.CHAPTERS ? "chapter" : "short",
      });
    });

    deleteBulkClips(params, {
      onSuccess: () => {
        setClipDeleteLoading(true);
        setIsClipDeleteModal(false);
        dispatch(setCurrentSelectedClip(""));
        setSelectedClips(new Map());
        // add isDeleted property to the current selected micro content
        // so we can check it in the useEffect in the LeftSidebar.tsx
        dispatch(
          updateCurrentSelectedMicroContent({
            isDeleted: true,
          })
        );
      },
    });
  };

  return (
    <DeleteConfirmationModal
      showDeleteConfirmationModal={isClipDeleteModal}
      heading={`Do you really want to delete ${selectedClipsCount} ${
        selectedClipsCount > 1 ? "clips" : "clip"
      } ?`}
    >
      <div className="max-w-screen-sm w-full bg-white p-6 pb-4 pt-2">
        <div className="w-full relative">
          <p className="ml-2 mb-4 text-center text-gray-900">
            This action is <span className="text-red-600"> not reversible</span>{" "}
          </p>
        </div>
        <div className="mt-7 flex flex-row-reverse gap-10 md:gap-10">
          <Button
            id="delete-multiple-clips"
            color="failure"
            className="w-full rounded-lg text-center text-sm font-medium text-white"
            onClick={deleteCurrentSelectedMicroContent}
          >
            {deleteBulkClipsLoading ? (
              <Spinner
                size="sm"
                light={true}
              />
            ) : (
              <span>Delete</span>
            )}
          </Button>
          <Button
            id="cancel-multiple-clips-deletion"
            onClick={() => setIsClipDeleteModal(false)}
            color="light"
            className="w-full rounded-lg border border-red-500 bg-white text-center text-sm font-medium text-blue-500 hover:bg-blue-50"
          >
            Cancel
          </Button>
        </div>
      </div>
    </DeleteConfirmationModal>
  );
};
export default memo(MultipleClipsDeleteConfirmationModal);
