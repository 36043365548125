import { useEffect, useState } from "react";
import { useEffectOnce, useUnmount, useWindowSize } from "react-use";

import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { Pagination, Spinner } from "flowbite-react";

import {
  toggleUploadVideoModal,
  toggleUpgradeToProModal,
  updateBaseTemplates,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useCheckMobileScreen from "@/hooks/useCheckMobileScreen";

import { getAllProjects } from "@/api/requests";
import useGetAllProjects from "@/api/useGetAllProjects";
import useGetDefaultBaseTemplates from "@/api/useGetDefaultBaseTemplates";
import useGetEditorDrafts from "@/api/useGetEditorDrafts";
import useGetUserTemplates from "@/api/useGetUserTemplates";
import useUserConsumedProcessingTime from "@/api/useUserConsumedProcessingTime";

import { UPGRADE_MODAL_DEEPLINK } from "@/constants";
import { ANALYTICS_CONSTANTS } from "@/constants/amplitude";
import { SOCIAL_MEDIA_LIST_WELCOME_PAGE } from "@/constants/on-boarding";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import {
  isParallelUploadAllowed,
  zE,
  calculateRemainingProcessingTime,
  getAllBaseTemplates,
  forceUpgradeModalOnUser,
  saveValueToLocalStorage,
  getValueFromLocalStorage,
} from "@/helpers";

import {
  eventsDataToRedux,
  trackImportVideoClick,
} from "@/utils/amplitudeAnalytcs";

import { PlanType, WorkspaceMenu } from "@/enums";

import Banner from "@/components/Banner";
import Header from "@/components/Header";
import LogoSpinner from "@/components/Loader/LogoSpinner";
import Protick from "@/components/Protick";

import WelcomeScreen from "@/views/home/Welcome";
import AssetCard from "@/views/home/components/AssetCard";
import OnBoardingModal from "@/views/home/components/OnBoarding/OnBoardingModal";
import ProcessingLimitModal from "@/views/home/components/ProcessingLimitModal/ProcessingLimitModal";
import UploadVideoModal from "@/views/home/components/UploadVideoModal";

import Drafts from "./Drafts";

const TOTAL_ROWS = 2;
const SMALL_SCREEN_VIEW = 1024;
const LARGE_SCREEN_VIEW = 1280;
const IMPORT_VIDEO_CARD_BUFFER = 1;

const {
  HOME_TOP_UPLOAD_FILE_MODAL_BTN,
  HOME_TOP_UPLOAD_FILE_MODAL_BTN_SOCIAL,
  UPLOAD_VIDEO_CARD,
} = BUTTON_IDS.WORKSPACE;

const Workspace = () => {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const isMobileScreen = useCheckMobileScreen();

  const lastActivePageOffset = getValueFromLocalStorage("homePageOffset");
  const [pageOffset, setPageOffset] = useState(
    Number(lastActivePageOffset) || 0
  );
  const [pageLimit, setPagelimit] = useState(0);
  const [currentWorkSpacetab, setCurrentWorkSpacetab] = useState<string>(
    WorkspaceMenu.ALL_VIDEOS
  );
  const [isProcessingLimitModalOpen, setIsProcessingLimitModalOpen] =
    useState(false);

  const currentUser = useAppSelector((state) => state.authState.currentUser);
  const onBoardingData = useAppSelector(
    (state) => state.homeState.onBoardingData
  );
  const uid = useAppSelector((state) => state.authState.currentUser.uid);

  const currentUserSubscriptionDetails = useAppSelector(
    (state) => state.authState.userSubscription
  );

  const { planType } = currentUserSubscriptionDetails;

  useEffectOnce(() => {
    if (sessionStorage.getItem(UPGRADE_MODAL_DEEPLINK) === "visible") {
      dispatch(toggleUpgradeToProModal(true));
      sessionStorage.removeItem(UPGRADE_MODAL_DEEPLINK);
    }
  });

  useEffectOnce(() => {
    zE("webWidget", "helpCenter:setSuggestions", {
      search: "Upload",
    });
  });

  useEffect(() => {
    // show upgrade modal if user is on free plan and from a blacklisted country
    if (forceUpgradeModalOnUser()) {
      dispatch(toggleUpgradeToProModal(true));
    }
  }, [currentUserSubscriptionDetails]);

  const count =
    Object.values(onBoardingData).filter((p) => p.processingVideoData).length +
    IMPORT_VIDEO_CARD_BUFFER;

  const { data: allProjects, isLoading }: any = useGetAllProjects(
    () =>
      getAllProjects(
        currentUser.uid,
        getLimitForUploadingVideos(),
        calculatePageOffset()
      ),
    pageLimit,
    pageOffset,
    count,
    30000
  );

  const defaultBaseTemplates = useGetDefaultBaseTemplates();
  const userTemplatesData = useGetUserTemplates(uid);

  const { data: editorDraftsData, isLoading: editorDraftsLoading } =
    useGetEditorDrafts(planType === PlanType.FREE);

  useUnmount(() => dispatch(toggleUploadVideoModal(false)));

  // commenting for now, since this is re-rendering on page change
  // useEffect(() => {
  //   // while device resizing if there are no videos, set pageoffset to 0 so that it goes back to 1st page
  //   if (!allProjects?.total_records) {
  //     saveValueToLocalStorage("homePageOffset", 0);
  //     setPageOffset(0);
  //   }
  // }, [allProjects]);

  const getTotalVideosPerRequest = () => {
    let totalVideosPerRow;
    if (width >= LARGE_SCREEN_VIEW) totalVideosPerRow = 5;
    else if (width >= SMALL_SCREEN_VIEW) totalVideosPerRow = 4;
    else totalVideosPerRow = 3;

    return totalVideosPerRow * TOTAL_ROWS;
  };

  const getTotalPages = () => {
    // subtracting 1 because of import card on all pages
    const totalVideos = getTotalVideosPerRequest() - 1;
    const pagesCount = Math.ceil(allProjects?.total_records / totalVideos);

    if (!count) {
      return pagesCount;
    } else {
      if (allProjects?.total_records <= pagesCount * totalVideos)
        return pagesCount;
      else return pagesCount + 1;
    }
  };

  const handlePageChange = (nextOffset: number) => {
    setPageOffset(nextOffset - 1);
    saveValueToLocalStorage("homePageOffset", nextOffset - 1);
  };

  const calculatePageOffset = () => {
    const totalVideos = getTotalVideosPerRequest();
    if (!count) return pageOffset * totalVideos;
    else {
      if (pageOffset > 0) {
        return pageOffset * (totalVideos - count);
      } else return pageOffset;
    }
  };

  const getLimitForUploadingVideos = () => {
    if (!count) return pageLimit;
    else {
      // if (pageOffset > 0) return pageLimit;
      return pageLimit - count < 0 ? 0 : pageLimit - count;
    }
  };

  useEffect(() => {
    setPagelimit(getTotalVideosPerRequest());
  }, [width]);

  const { data: usageData, isLoading: usageDataIsLoading } =
    currentUserSubscriptionDetails && useUserConsumedProcessingTime();

  /**
   * @returns true if any of the api is loading
   */
  const isXhrLoading = () => {
    if (usageDataIsLoading || defaultBaseTemplates.isLoading || isLoading) {
      return true;
    }
    return false;
  };

  const handelOpenVideoUploadModal = () => {
    if (isXhrLoading()) {
      return;
    }

    if (!isParallelUploadAllowed(allProjects?.results, onBoardingData)) {
      setIsProcessingLimitModalOpen(true);
      return;
    }

    if (calculateRemainingProcessingTime(usageData) > 0) {
      const baseTemplates = getAllBaseTemplates(defaultBaseTemplates?.data);

      dispatch(updateBaseTemplates(baseTemplates));
      dispatch(toggleUploadVideoModal(true));
    } else {
      dispatch(toggleUpgradeToProModal(true));
      eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
    }
  };

  /**
   * @return true if project data is empty and there is no project in onBoardingData
   */
  const isProjectEmpty = () => {
    if (
      Object.values(onBoardingData).filter((p) => p.processingVideoData)?.length
    ) {
      return false;
    }

    if (allProjects?.total_records) {
      return false;
    }

    return true;
  };

  return (
    <>
      <Banner bannerVariant="demoLink" />
      <Header showHomeOnly={isProjectEmpty()} />
      {/* <div className="relative px-4 lg:px-6 bg-[#e8e8ee]"> */}
      <div className="mx-auto pb-3 w-full max-w-screen-2xl bg-[#e8e8ee] px-4 lg:px-6">
        <div className="flex justify-between py-5">
          <div className="flex items-center space-x-3">
            <p
              className={`truncate sm:tracking-wider ${
                isProjectEmpty() ? "max-w-[300px]" : "max-w-[210px]"
              } sm:max-w-none`}
            >
              <span className="text-xl sm:text-2xl">Welcome,</span>{" "}
              <span className="text-xl font-medium sm:text-2xl">
                {currentUser.displayName || currentUser.email} 👋🏼
              </span>
            </p>
            {planType && <Protick planType={planType} />}
          </div>

          {isMobileScreen ? <UploadVideoModal /> : <OnBoardingModal />}
          <ProcessingLimitModal
            isOpen={isProcessingLimitModalOpen}
            onClose={() => setIsProcessingLimitModalOpen(false)}
          />
        </div>
        {!isLoading && isProjectEmpty() && (
          <div className="my-8 flex items-center">
            <p className="mr-2 text-base font-semibold text-[#1E1E1E]">
              Create videos for :
            </p>

            <ul className="ml-2 flex space-x-4">
              {SOCIAL_MEDIA_LIST_WELCOME_PAGE.map((socialMedia) => {
                return (
                  <li
                    key={socialMedia.id}
                    className="flex h-12 cursor-pointer items-center justify-center space-x-3 rounded-3xl border border-[#C3DDFD] bg-white px-6 transition-colors hover:bg-[#F2F7FF]/70"
                    id={HOME_TOP_UPLOAD_FILE_MODAL_BTN_SOCIAL.ID}
                    onClick={() => {
                      handelOpenVideoUploadModal();
                      trackImportVideoClick();
                    }}
                  >
                    <img
                      src={socialMedia.icon}
                      alt={socialMedia.value}
                    />
                    <p className="text-sm font-semibold leading-4 tracking-wide text-[#455A64]">
                      {socialMedia.value}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {/* NOTE: change the height from 13rem to 10rem bellow
            while Banner is not present
        */}
        <div className="bg-white rounded-lg">
          {isLoading ||
          defaultBaseTemplates.isLoading ||
          userTemplatesData.isLoading ? (
            <div className="flex h-[calc(100vh_-_10rem)] w-full flex-col justify-between ">
              <div className="relative flex h-full w-full flex-col items-center justify-center">
                <LogoSpinner />
              </div>
            </div>
          ) : (
            <>
              {allProjects?.total_records ||
              Object.values(onBoardingData).filter((p) => p.processingVideoData)
                ?.length ? (
                <>
                  {/* NOTE: change the height from 13rem to 10rem bellow
                       while Banner is not present
                   */}
                  <div className="relative flex h-[calc(100vh_-_10rem)] w-full flex-col justify-between overflow-y-auto">
                    <div className="h-full">
                      <div className="sticky top-0 border-b  border-gray-200 bg-gray-100 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400 rounded-t-lg">
                        <ul className="-mb-px flex flex-wrap transition-colors">
                          <li
                            className="mr-2 cursor-pointer"
                            onClick={() =>
                              setCurrentWorkSpacetab(WorkspaceMenu.ALL_VIDEOS)
                            }
                          >
                            <p
                              className={`inline-block p-4 ${
                                currentWorkSpacetab === WorkspaceMenu.ALL_VIDEOS
                                  ? "border-blue-600 text-blue-600 dark:text-blue-500"
                                  : "border-transparent hover:border-gray-300 hover:text-gray-600"
                              } rounded-t-lg border-b-2`}
                            >
                              All Videos{" "}
                              <span>({allProjects?.total_records})</span>
                            </p>
                          </li>
                          <li
                            className={`mr-2  ${
                              editorDraftsLoading
                                ? "opacity-50 cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                            onClick={() => {
                              if (editorDraftsLoading) return;
                              setCurrentWorkSpacetab(WorkspaceMenu.DRAFTS);
                            }}
                          >
                            <p
                              className={`inline-block p-4 ${
                                currentWorkSpacetab === WorkspaceMenu.DRAFTS
                                  ? "border-blue-600 text-blue-600 dark:text-blue-500"
                                  : "border-transparent hover:border-gray-300 hover:text-gray-600"
                              } rounded-t-lg border-b-2`}
                            >
                              Drafts{" "}
                              {editorDraftsLoading ? (
                                <Spinner size="sm" />
                              ) : (
                                <span>
                                  ({(editorDraftsData as any)?.length || 0})
                                </span>
                              )}
                            </p>
                          </li>
                        </ul>
                      </div>
                      {currentWorkSpacetab === WorkspaceMenu.ALL_VIDEOS ? (
                        <div className="flex flex-col justify-between h-[calc(100%_-_4rem)] pb-2">
                          <section className="relative mb-6 px-4 pt-6">
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                              <div
                                className={`rounded-xl border bg-white shadow-md flex flex-col items-center justify-center cursor-pointer gap-4 `}
                                onClick={() => {
                                  handelOpenVideoUploadModal();
                                  trackImportVideoClick();
                                }}
                                id={UPLOAD_VIDEO_CARD.ID}
                              >
                                <ArrowUpTrayIcon className="h-12 w-12 text-blue-600" />
                                <div className="text-gray-500 font-medium">
                                  Import Video
                                </div>
                              </div>

                              {pageOffset === 0 && (
                                <>
                                  {Object.values(onBoardingData)
                                    .filter((p) => p.processingVideoData)
                                    .sort((a, b) =>
                                      b.createdAt?.localeCompare(a.createdAt)
                                    )
                                    .map((project) => {
                                      return (
                                        <AssetCard
                                          project={
                                            project.processingVideoData
                                              ?.uploader ||
                                            project.processingVideoData
                                          }
                                          key={project.key}
                                          handelOpenVideoUploadModal={
                                            handelOpenVideoUploadModal
                                          }
                                          localProjectID={
                                            project?.processingVideoData?.id
                                          }
                                          pageOffset={pageOffset}
                                        />
                                      );
                                    })}
                                </>
                              )}

                              {allProjects?.results?.map((project: any) => {
                                return (
                                  <AssetCard
                                    project={project}
                                    key={project.id}
                                    localProjectID={
                                      Object.values(onBoardingData).find(
                                        (p) => p.projectId === project.id
                                      )?.key
                                    }
                                    pageOffset={pageOffset}
                                  />
                                );
                              })}
                            </div>
                          </section>
                          <div className="pagination">
                            {getTotalPages() >= 2 && (
                              <div className="flex justify-center">
                                <Pagination
                                  currentPage={pageOffset + 1}
                                  totalPages={getTotalPages()}
                                  onPageChange={handlePageChange}
                                  showIcons={true}
                                  previousLabel=""
                                  nextLabel=""
                                  className="pagination-container"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <Drafts drafts={editorDraftsData || []} />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <WelcomeScreen />
              )}
            </>
          )}
        </div>
        <ProcessingLimitModal
          isOpen={isProcessingLimitModalOpen}
          onClose={() => setIsProcessingLimitModalOpen(false)}
        />
      </div>
    </>
  );
};

export default Workspace;
