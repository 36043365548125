import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "@/store/hooks";

import AuthHeader from "@/components/AuthHeader";

// import Banner from "@/components/Banner/Banner";

const AuthLayout = () => {
  const isAuthenticated = useAppSelector(
    (state) => state.authState.isAuthenticated
  );

  const location: any = useLocation();

  if (isAuthenticated) {
    return (
      <Navigate
        to={`${location?.state?.pathName ?? "/"}`}
        replace
      />
    );
  }

  return (
    <main className="mx-auto h-[100vh] w-full max-w-screen-2xl bg-[#e8e8ee]">
      {/* <Banner bannerVariant="outrage" /> */}
      <AuthHeader />
      <div className="relative px-4 lg:px-6">
        <div
          className={`flex items-center justify-between overflow-y-auto py-8 h-[calc(100vh-8rem)]`}
        >
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default AuthLayout;
