const fbq = (window as any).fbq;
const hostDomain = (window as any).location.host;

export const downloadAndRenderConversionFbq = () => {
  try {
    if (fbq) {
      fbq("trackCustom", "VideoDownload", {
        content_name: "Video Download Initiated",
        content_category: "Download",
        content_type: "Video",
        domain: hostDomain,
      });
    }
  } catch (error) {}
};

export const importVideoConversionFbq = () => {
  try {
    if (fbq) {
      fbq("trackCustom", "VideoImportInitiated", {
        content_name: "Video Import Button Click",
        content_category: "Button Click",
        content_type: "interaction",
        domain: hostDomain,
      });
    }
  } catch (error) {}
};

export const signupConversionFbq = () => {
  try {
    if (fbq) {
      fbq("trackCustom", "NewUserSignUp", {
        content_name: "New User Sign-Up",
        status: "Completed",
        domain: hostDomain,
      });
    }
  } catch (error) {}
};

export const purchaseConversionFbq = (
  value: number,
  transaction_id: string
) => {
  try {
    if (fbq) {
      fbq("trackCustom", "Purchase", {
        value: value,
        currency: "USD", // Replace with the correct currency code
        content_name: "Order Purchase",
        content_category: "Purchase",
        content_ids: [transaction_id],
        content_type: "product",
        domain: hostDomain,
      });
    }
  } catch (error) {}
};
