import React from "react";

import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Badge, Tooltip } from "flowbite-react";

export const LowResPreviewInfo: React.FC = () => {
  const message =
    "Video is compressed for preview. Original quality will be preserved when downloading.";
  return (
    <Badge
      color="success"
      className="flex justify-center py-1 w-52  absolute top-3 left-1/2 transform -translate-x-1/2 z-20"
    >
      <div className="flex justify-center">
        <Tooltip
          className="text-xs w-52 -ml-12"
          arrow={false}
          placement="bottom-start"
          content={<p className="text-xs">{message}</p>}
        >
          <InformationCircleIcon className="w-4 h-4 mr-1" />
        </Tooltip>
        <p className=" whitespace-nowrap">Low-res preview</p>
      </div>
    </Badge>
  );
};
