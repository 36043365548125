import { SVGProps } from "react";

const PlayHeadTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#3263E9"
      d="M9.802 14.254c-.726 1.51-2.878 1.51-3.604 0L.719 2.867A2 2 0 0 1 2.521 0H13.48a2 2 0 0 1 1.802 2.867L9.802 14.254Z"
    />
  </svg>
);
export default PlayHeadTriangle;
