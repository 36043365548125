import { useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useDebounce, useUpdateEffect } from "react-use";

import {
  MagnifyingGlassIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import PubSub from "pubsub-js";

import { useAppSelector } from "@/store/hooks";

import useGetAssets from "@/api/useGetAssets";
import usePexel from "@/api/usePexel";

import {
  ANALYTICS_CONSTANTS,
  EDITOR_INTERACTION_DATA,
} from "@/constants/amplitude";
import { stockMedia } from "@/constants/pexels-stock";

import {
  eventsDataToRedux,
  trackEditorInteractionEvent,
} from "@/utils/amplitudeAnalytcs";

import {
  SimpleAssetType,
  EditorMediaTab,
  AssetTags,
  EditorMediaStatus,
  EditorMediaTabMode,
} from "@/enums";

import { useEditorHistory } from "@/views/editor/EditorHistoryContext";
import { HistoryActionTypes } from "@/views/editor/EditorHistoryReducer";
import {
  addAudioAssetCommand,
  addMediaCommand,
} from "@/views/editor/commands/mediaCommand";

import { EDITOR_MEDIA_LOAD_STATE } from "../../constant";
import { BottomBRollPanel } from "./BottomBRollPanel";
import { MediaCard } from "./MediaCard";
import MediaUploader from "./MediaUploader";

import PexelTextLogo from "@/assets/images/editor/pexels-text-logo.svg";

const mapToSimpleAssetType = (type: EditorMediaTab): SimpleAssetType => {
  switch (type) {
    case EditorMediaTab.IMAGE:
      return SimpleAssetType.IMAGE;
    case EditorMediaTab.VIDEO:
      return SimpleAssetType.VIDEO;
    case EditorMediaTab.AUDIO:
      return SimpleAssetType.AUDIO;
    default:
      return SimpleAssetType.IMAGE;
  }
};

const MediaMenuList = [
  {
    id: EditorMediaTab.IMAGE,
    label: "Images",
  },
  {
    id: EditorMediaTab.VIDEO,
    label: "Videos",
  },
  {
    id: EditorMediaTab.AUDIO,
    label: "Audios",
  },
];

const renderStockMediaCard = (
  url: string,
  id: string | number,
  assetType: SimpleAssetType,
  isLastItem: boolean,
  ref: any,
  handleAddMedia: any,
  removeAsset: any
) => (
  <MediaCard
    {...(isLastItem ? { ref } : {})}
    url={url}
    key={id}
    mediaType={assetType}
    isStock
    id={id}
    handleAddMedia={handleAddMedia}
    removeAsset={removeAsset}
  />
);

const getStockAssetUrl = (assetType: SimpleAssetType, asset: any) => {
  let url: string = "";

  if (assetType === SimpleAssetType.IMAGE) {
    url = asset.src?.medium;
  } else if (assetType === SimpleAssetType.VIDEO) {
    url = asset.video_files?.[0]?.link;
  }

  return url;
};

const renderStockAssets = (
  assetType: SimpleAssetType,
  data: any,
  ref: any,
  handleAddMedia: any,
  removeAsset: any
) => {
  const isImageType = assetType === SimpleAssetType.IMAGE;
  const isVideoType = assetType === SimpleAssetType.VIDEO;
  const assetKey = isImageType ? "photos" : isVideoType ? "videos" : null;

  if (assetKey) {
    return data.pages.flatMap((page: any, pageIndex: number) =>
      page[assetKey].map((asset: any, i: number, arr: any[]) => {
        const isLastItem =
          data.pages.length - 1 === pageIndex && arr.length === i + 1;

        return renderStockMediaCard(
          getStockAssetUrl(assetType, asset),
          asset.id,
          assetType,
          isLastItem,
          ref,
          handleAddMedia,
          removeAsset
        );
      })
    );
  }

  return null;
};

const MediaTab = () => {
  const { editorDispatch } = useEditorHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [bottomPanelFlashDisable, setBottomPanelFlashDisable] = useState(false);

  const toggleDrawer = () => {
    if (!bottomPanelFlashDisable) {
      setBottomPanelFlashDisable(true);
    }
    setIsOpen(!isOpen);

    !isOpen &&
      trackEditorInteractionEvent(
        EDITOR_INTERACTION_DATA.MEDIA_CLICKED.EVENT_KEY,
        EDITOR_INTERACTION_DATA.MEDIA_CLICKED.ADDED_B_ROLLS_VIEWED
      );
  };

  const resetBottomPanelFlashDisable = () => {
    setBottomPanelFlashDisable(false);
  };

  const {
    bRolls,
    audioElements,
    handleAddAsset,
    handleAddAudioAsset,
    handleRemoveAsset,
    removeAudioAsset,
  } = useOutletContext<any>();

  const { ref, inView } = useInView({
    threshold: 0, // trigger when the element comes into view
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [currentTab, setCurrentTab] = useState(EditorMediaTab.IMAGE);

  useDebounce(
    () => {
      setDebouncedSearchQuery(searchQuery);
      eventsDataToRedux(ANALYTICS_CONSTANTS.EDITOR_MEDIA_PEXEL_SEARCH);
      // trackEditorInteractionEvent(
      //   EDITOR_INTERACTION_DATA.MEDIA_CLICKED.EVENT_KEY,
      //   EDITOR_INTERACTION_DATA.MEDIA_CLICKED.MEDIA_SEARCHED
      // );
    },
    1000,
    [searchQuery]
  );

  const currentSelectedMicroContent = useAppSelector(
    (state) => state.homeState.currentSelectedMicroContent
  );

  const currentPageTitle = searchParams.get("type");
  const isSearchMode = searchParams.get("mode") === "search";

  const { data, isLoading } = useGetAssets({
    assetTag:
      currentPageTitle === EditorMediaTab.AUDIO
        ? AssetTags.BG_MUSIC
        : AssetTags.B_ROLL,
  });

  const currentAssetType = mapToSimpleAssetType(
    currentPageTitle as EditorMediaTab
  );

  const isAudio = currentPageTitle === EditorMediaTab.AUDIO;

  const {
    data: dataPexel,
    isLoading: isLoadingPexel,
    fetchNextPage,
    hasNextPage,
    isSuccess,
  } = usePexel({
    assetType: currentAssetType,
    searchQuery: debouncedSearchQuery,
    disableQuery: !debouncedSearchQuery || isAudio,
  });

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleChangeMediaMode = (isSearch = false) => {
    setDebouncedSearchQuery("");
    setSearchQuery("");
    setSearchParams({
      type: currentPageTitle ? currentPageTitle : EditorMediaTab.IMAGE,
      mode: isSearch ? EditorMediaTabMode.SEARCH : EditorMediaTabMode.BROWSE,
    });
  };

  const updateMediaType = (mediaType: EditorMediaTab) => {
    setSearchParams({
      type: mediaType,
      mode: searchParams.get("mode") || EditorMediaTabMode.BROWSE,
    });
  };

  const assetList = useMemo(
    () =>
      data?.filter((file) => file.assetType.includes(currentAssetType)) || [],
    [data, currentAssetType, bRolls, JSON.stringify(audioElements)]
  );

  const stockMediaList = useMemo(
    () => stockMedia[currentAssetType],
    [currentPageTitle]
  );

  const filterAssetList = useMemo(() => {
    if (debouncedSearchQuery) {
      return assetList.filter((asset) => {
        const data = JSON.parse(asset.data);
        return data.asset_name
          .toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase());
      });
    }
    return [];
  }, [debouncedSearchQuery, assetList.length]);

  const handleAddGalleryAsset = (assetData: any) => {
    const { item, assetType, currentSelectedMicroContent } = assetData;
    handleAddAsset({
      url: item.url,
      file: null,
      assetId: item.id,
      assetTag:
        assetType === SimpleAssetType.AUDIO
          ? AssetTags.BG_MUSIC
          : AssetTags.B_ROLL,
      assetType: assetType,
      metaData: {
        start: currentSelectedMicroContent.start,
        end: assetType.includes(SimpleAssetType.VIDEO)
          ? item.duration * 1000 + currentSelectedMicroContent.start
          : currentSelectedMicroContent.end,
        isFullVideoLength: assetType.includes(SimpleAssetType.VIDEO)
          ? false
          : true,
        duration: assetType.includes(SimpleAssetType.VIDEO)
          ? item.duration
          : (currentSelectedMicroContent.end -
              currentSelectedMicroContent.start) /
            1000,
      },
    });
  };

  const addGalleryAssetCommand = (item: any, assetType: SimpleAssetType) => {
    const metadata = {
      item: { ...item },
      assetType,
      currentSelectedMicroContent: {
        start: currentSelectedMicroContent.start,
        end: currentSelectedMicroContent.end,
      },
    };
    const command = addMediaCommand(
      metadata,
      handleAddGalleryAsset,
      removeAsset
    );

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const addAudioAsset = (item: any) => {
    PubSub.publish(EDITOR_MEDIA_LOAD_STATE, {
      id: item.id,
      status: EditorMediaStatus.LOADING,
    });

    const audio = document.createElement(SimpleAssetType.AUDIO);
    audio.src = item.url;
    audio.preload = "metadata";
    audio.muted = true;

    audio.addEventListener("loadedmetadata", async () => {
      const audioDurationInMilliseconds =
        Math.floor(audio.duration) * 1000 || 0;
      let end = audioDurationInMilliseconds + currentSelectedMicroContent.start;

      // If the audio duration is greater than the microcontent duration
      if (
        audioDurationInMilliseconds >
        currentSelectedMicroContent.end - currentSelectedMicroContent.start
      ) {
        end = currentSelectedMicroContent.end; // Set the microcontent end as the end value
      }

      handleAddAudioAsset({
        url: item.url,
        assetTag: AssetTags.BG_MUSIC,
        assetType: SimpleAssetType.AUDIO,
        id: item.id,
        metaData: {
          start: currentSelectedMicroContent.start,
          end: end,
          duration: audioDurationInMilliseconds / 1000,
          // set the volume to 100 by default
          volume: 100,
          isFullVideoLength: true,
          asset_name: item?.asset_name,
        },
      });
      PubSub.publish(EDITOR_MEDIA_LOAD_STATE, {
        id: item.id,
        status: EditorMediaStatus.READY,
      });
    });
  };

  const handleAddAudioAssetToEditor = (item: any) => {
    const metadata = {
      item: { ...item },
      currentSelectedMicroContent: {
        start: currentSelectedMicroContent.start,
        end: currentSelectedMicroContent.end,
      },
    };
    const command = addAudioAssetCommand(metadata, addAudioAsset, removeAsset);

    command.execute();

    editorDispatch({ type: HistoryActionTypes.ADD_COMMAND, command });
  };

  const removeAsset = (assetId: string | number) => {
    PubSub.publish(EDITOR_MEDIA_LOAD_STATE, {
      id: assetId,
      status: EditorMediaStatus.NOT_ADDED,
    });

    if (currentAssetType === SimpleAssetType.AUDIO) {
      removeAudioAsset(assetId);
      return;
    }
    handleRemoveAsset(assetId, false, true);
  };

  let content;

  if (isAudio && debouncedSearchQuery) {
    content = [
      ...filterAssetList.map((asset) => {
        const data = JSON.parse(asset.data);

        if (isAudio) {
          return (
            <MediaCard
              url={data.remote_url}
              key={asset.id}
              id={asset.id}
              mediaType={asset.assetType as SimpleAssetType}
              isSelected={
                isAudio
                  ? audioElements.hasOwnProperty(asset.id)
                  : bRolls.some((bRoll: any) => bRoll.uid === asset.id)
              }
              handleAddMedia={
                isAudio ? handleAddAudioAssetToEditor : addGalleryAssetCommand
              }
              assetName={data?.asset_name}
              isFullWidthCard={isAudio}
              isUserUploadedAsset
              removeAsset={removeAsset}
            />
          );
        }

        return (
          <MediaCard
            url={data.remote_url}
            key={asset.id}
            mediaType={asset.assetType as SimpleAssetType}
            id={asset.id}
            handleAddMedia={addGalleryAssetCommand}
            assetName={data?.asset_name}
            removeAsset={removeAsset}
          />
        );
      }),
    ];
  } else if (isLoadingPexel) {
    content = Array.from({ length: 12 }, (_, i) => (
      <div
        key={i}
        className="h-28 w-28 animate-pulse rounded-md bg-gray-200 2xl:h-36 2xl:w-32"
      ></div>
    ));
  } else if (isSuccess && dataPexel) {
    const handleAddMedia = isAudio
      ? handleAddAudioAssetToEditor
      : addGalleryAssetCommand;
    content = [
      ...filterAssetList.map((asset) => {
        const data = JSON.parse(asset.data);
        return (
          <MediaCard
            url={data.remote_url}
            key={asset.id}
            mediaType={asset.assetType as SimpleAssetType}
            id={asset.id}
            handleAddMedia={handleAddMedia}
            removeAsset={removeAsset}
          />
        );
      }),
      ...renderStockAssets(
        currentAssetType,
        dataPexel,
        ref,
        handleAddMedia,
        removeAsset
      ),
    ];
  }

  const onTabChanged = (id: EditorMediaTab) => {
    updateMediaType(id);
    setCurrentTab(id);

    switch (id) {
      case EditorMediaTab.AUDIO:
        currentTab !== EditorMediaTab.AUDIO &&
          trackEditorInteractionEvent(
            EDITOR_INTERACTION_DATA.MEDIA_CLICKED.EVENT_KEY,
            EDITOR_INTERACTION_DATA.MEDIA_CLICKED.AUDIO_CLICKED
          );
        break;
      case EditorMediaTab.IMAGE:
        currentTab !== EditorMediaTab.IMAGE &&
          trackEditorInteractionEvent(
            EDITOR_INTERACTION_DATA.MEDIA_CLICKED.EVENT_KEY,
            EDITOR_INTERACTION_DATA.MEDIA_CLICKED.IMAGE_CLICKED
          );
        break;
      case EditorMediaTab.VIDEO:
        currentTab !== EditorMediaTab.VIDEO &&
          trackEditorInteractionEvent(
            EDITOR_INTERACTION_DATA.MEDIA_CLICKED.EVENT_KEY,
            EDITOR_INTERACTION_DATA.MEDIA_CLICKED.VIDEO_CLICKED
          );
        break;
      default:
        break;
    }
  };

  useUpdateEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return (
    <div
      className={clsx(
        "relative flex w-full flex-1 flex-col justify-between",
        isOpen && "overflow-hidden"
      )}
    >
      <div>
        <div
          className="sticky top-0 z-20  w-full bg-white"
          style={{
            boxShadow: "0px 4px 20px 0px #0000000A",
          }}
        >
          {isSearchMode ? (
            <div className="relative mb-3 ml-2 flex bg-white pt-4">
              <ChevronLeftIcon
                className="w-5 cursor-pointer text-gray-800"
                onClick={() => handleChangeMediaMode(false)}
              />
              <input
                type="search"
                placeholder={`Search ${currentPageTitle?.toLowerCase()}`}
                className="text-gray-8zzzz00 w-full rounded border-transparent bg-white px-1.5 pr-3 text-sm placeholder-gray-400 focus:border-transparent focus:ring-0"
                onChange={handleSearchInput}
                value={searchQuery}
                id="search-media"
                autoFocus
              />
            </div>
          ) : (
            <>
              <div className="flex items-center justify-between px-3 pt-6">
                <p className="text-lg text-gray-800">Media</p>
                <div
                  className="flex cursor-pointer items-center space-x-1.5 rounded-md border py-2 pl-3 pr-10"
                  onClick={() => handleChangeMediaMode(true)}
                >
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
                  <p className="text-sm font-normal text-gray-400">
                    Search media
                  </p>
                </div>
              </div>
              <div className="my-3 px-3">
                <MediaUploader
                  addGalleryAssetCommand={addGalleryAssetCommand}
                />
              </div>
            </>
          )}

          <ul className="flex justify-between">
            {MediaMenuList.map((mediaTab) => (
              <li
                className={clsx(
                  "w-1/3 cursor-pointer border-b-2 text-center text-gray-500 transition-all",
                  mediaTab.id === currentPageTitle
                    ? "border-b-blue-500 font-medium text-gray-950"
                    : "border-b-transparent hover:font-medium hover:text-gray-600"
                )}
                key={mediaTab.id}
                onClick={() => onTabChanged(mediaTab.id)}
              >
                <p className="mb-2">{mediaTab.label}</p>
              </li>
            ))}
          </ul>
        </div>

        <section className="w-full">
          {isLoading ? (
            <div className="mb-4 mt-6 px-3">
              <div
                className={clsx(
                  "grid",
                  isAudio ? "grid-cols-1 gap-y-6" : "grid-cols-3 gap-4"
                )}
              >
                {Array.from({ length: isAudio ? 4 : 12 }, (_, i) => (
                  <div
                    key={i}
                    className={clsx(
                      "animate-pulse rounded-md bg-gray-200",
                      isAudio
                        ? "col-span-1 h-24"
                        : "h-28 w-28 2xl:h-32 2xl:w-32"
                    )}
                  ></div>
                ))}
              </div>
            </div>
          ) : (
            <div className="my-5 pl-3 pr-2">
              {debouncedSearchQuery ? (
                <>
                  {content && content?.length ? (
                    <div
                      className={clsx(
                        "grid",
                        isAudio ? "grid-cols-1 gap-y-4" : "grid-cols-3 gap-4"
                      )}
                    >
                      {content}
                    </div>
                  ) : (
                    <div className="mt-36 flex items-center justify-center 2xl:mt-60">
                      <div className=" flex w-64 flex-col items-center">
                        <MagnifyingGlassIcon className="w-24 text-gray-400" />
                        <p className="text-center text-sm leading-6 text-gray-500">
                          We couldn't find any {currentAssetType} matching your
                          search
                        </p>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  {assetList.length > 0 && (
                    <p className="mb-4 text-sm font-medium  text-gray-600">
                      Uploaded {currentPageTitle?.toLowerCase()}
                    </p>
                  )}
                  <div
                    className={clsx(
                      "grid",
                      isAudio ? "grid-cols-1 gap-y-4" : "grid-cols-3 gap-4"
                    )}
                  >
                    {assetList.map((asset) => {
                      const data = JSON.parse(asset.data);
                      return (
                        <MediaCard
                          url={data.remote_url}
                          key={asset.id}
                          id={asset.id}
                          mediaType={asset.assetType as SimpleAssetType}
                          isSelected={
                            isAudio
                              ? audioElements.hasOwnProperty(asset.id)
                              : bRolls.some(
                                  (bRoll: any) => bRoll.uid === asset.id
                                )
                          }
                          handleAddMedia={
                            isAudio
                              ? handleAddAudioAssetToEditor
                              : addGalleryAssetCommand
                          }
                          assetName={data?.asset_name}
                          isFullWidthCard={isAudio}
                          isUserUploadedAsset
                          removeAsset={removeAsset}
                        />
                      );
                    })}
                  </div>
                  {assetList.length > 0 && (
                    <p className="mb-4 mt-6 h-px w-full bg-gray-300"></p>
                  )}
                  {currentPageTitle !== EditorMediaTab.AUDIO ? (
                    <a
                      href="https://www.pexels.com"
                      target="blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        src={PexelTextLogo}
                        className="mb-4"
                      />
                    </a>
                  ) : (
                    <p className="mb-4 text-sm font-medium  text-gray-600">
                      Stock {currentPageTitle?.toLowerCase()}
                    </p>
                  )}
                  <div
                    className={clsx(
                      "grid",
                      isAudio ? "grid-cols-1 gap-y-4" : "grid-cols-3 gap-4"
                    )}
                  >
                    {stockMediaList.map((asset: any) => {
                      return (
                        <MediaCard
                          url={
                            currentAssetType === SimpleAssetType.IMAGE
                              ? asset.src.medium
                              : isAudio
                              ? asset.url
                              : asset.video_files[0].link
                          }
                          key={asset.id}
                          id={asset.id}
                          mediaType={currentAssetType}
                          handleAddMedia={
                            isAudio
                              ? handleAddAudioAssetToEditor
                              : addGalleryAssetCommand
                          }
                          isFullWidthCard={isAudio}
                          removeAsset={removeAsset}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          )}
        </section>
      </div>

      <BottomBRollPanel
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        bottomPanelFlashDisable={bottomPanelFlashDisable}
        resetBottomPanelFlashDisable={resetBottomPanelFlashDisable}
      />
    </div>
  );
};

export default MediaTab;
