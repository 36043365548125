import { zE } from "@/helpers";

import MobileScreenImg from "@/assets/images/illustration-blank-canvas.svg";

const MobileApp = () => {
  zE("webWidget", "hide");

  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <img
        src={MobileScreenImg}
        className="mx-auto"
      />
      <div className="w-[280px] text-center">
        <p className=" text-black font-semibold text-center my-9 text-lg">
          vidyo.ai works best on Desktop
        </p>

        <p className="text-xs font-light">
          For the most optimal experience, vidyo.ai is available only for
          desktop / laptop right now.
        </p>
        <p className="text-xs font-light my-6">
          Please login again using your browser on any computer
        </p>
      </div>
    </div>
  );
};

export default MobileApp;
