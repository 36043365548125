import { useQuery } from "@tanstack/react-query";

import { getEditorDrafts } from "@/api/requests";

export default function useGetEditorDrafts(isFreePlan = false) {
  return useQuery({
    queryKey: ["editor-drafts", isFreePlan],
    queryFn: () => getEditorDrafts(isFreePlan),
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });
}
