import { IconPropType } from "@/utils/constants";

export const YoutubeShortsIcon = ({ fillColor }: IconPropType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={20}
    viewBox="0 0 16 19"
  >
    <path
      d="M10.266.422c1.875-.934 4.195-.254 5.183 1.52.985 1.773.266 3.964-1.61 4.898l-1.538.777c1.328.047 2.594.742 3.258 1.934.984 1.773.265 3.965-1.61 4.898L5.73 18.58c-1.875.933-4.195.253-5.18-1.52-.988-1.774-.269-3.965 1.606-4.899l1.543-.777c-1.328-.047-2.594-.742-3.258-1.934-.988-1.773-.265-3.965 1.61-4.898ZM5.832 6.578l4.973 2.934-4.973 2.922Zm0 0"
      style={{
        stroke: "none",
        fillRule: "evenodd",
        fill: fillColor,
        fillOpacity: 1,
      }}
    />
  </svg>
);
