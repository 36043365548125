import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateDraft } from "@/api/requests";

import { notificationType } from "@/utils/constants";
import { showNotification } from "@/utils/showNotification";

export default function useUpdateDraft(cb?: (draftData?: any) => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => updateDraft(data),
    onSuccess: (responseData: any) => {
      const { data } = responseData;
      const updatedDraftData = {
        ...data.data,
        id: data.draft_id,
      };
      if (cb) cb(updatedDraftData);
      queryClient.invalidateQueries({ queryKey: ["editor-drafts"] });
      queryClient.invalidateQueries({ queryKey: ["render-drafts"] });
    },
    onError: (error) => {
      console.log(error);
      showNotification(
        "Something went wrong, please try again!",
        notificationType.FAIL
      );
    },
  });
}
