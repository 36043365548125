import { Fragment, memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUpdateEffect } from "react-use";

import { Menu, Transition } from "@headlessui/react";
import { ClockIcon } from "@heroicons/react/24/outline";
import { SparklesIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { Tooltip, Badge } from "flowbite-react";

import {
  toggleAddMoreMinModal,
  toggleUpgradeToProModal,
} from "@/store/homeSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import useUserConsumedProcessingTime from "@/api/useUserConsumedProcessingTime";

import {
  AMPLITUDE_CLICK_EVENTS,
  ANALYTICS_CONSTANTS,
} from "@/constants/amplitude";
import { BUTTON_IDS } from "@/constants/segment-analytics";

import {
  isAdminTemplateEditor,
  calculateRemainingProcessingTimeInMinutes,
  getOrdinalDayOfMonthFromTimestamp,
  saveValueToLocalStorage,
} from "@/helpers";

import {
  addMoreMinutesButtonClick,
  eventsDataToRedux,
  trackDiscordIconClick,
  trackDownloadsTabClick,
  trackMediaTabClick,
  trackUpgradeButton,
  trackUserUsage,
  trackWhatsNewIconClick,
} from "@/utils/amplitudeAnalytcs";

import { PlanType, RouterPath, ScreenName } from "@/enums";

import UserOptionDropdown from "./UserOptionDropdown";

import DiscordIcon from "@/assets/icons/social/discord.svg";
import VideoAILogo from "@/assets/images/video_ai_logo.png";

type HeaderTypes = {
  showHomeOnly?: boolean;
};

const REMAINING_RED = 15;
const REMAINING_YELLOW = 30;

const { HEADER_OPEN_UPGRADE_MODAL } = BUTTON_IDS.HEADER;

const getLabelColorByRemainingProcessingTime = (remainingMins: number) => {
  if (remainingMins < REMAINING_RED) {
    return "text-red-800 bg-red-100";
  } else if (remainingMins < REMAINING_YELLOW) {
    return "text-yellow-800 bg-yellow-100";
  } else {
    return "text-green-800 bg-green-100";
  }
};

const Header = ({ showHomeOnly }: HeaderTypes) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const userSubscription = useAppSelector(
    (state) => state.authState.userSubscription
  );
  const {
    subscriptionStartedAt,
    planType,
    allowedMinutes,
    intervalInMonths,
    nextBillDate,
  } = userSubscription;

  const { data: usageData, isLoading: usageDataIsLoading } =
    userSubscription && useUserConsumedProcessingTime();

  const dispatch = useAppDispatch();
  const handelShowUpgradeModal = () => {
    dispatch(toggleUpgradeToProModal(true));
    eventsDataToRedux(ANALYTICS_CONSTANTS.PAYMENT_SCREEN_NAME);
  };

  const remainingProcessingTime =
    calculateRemainingProcessingTimeInMinutes(usageData);

  const nextBillDateTooltip =
    nextBillDate && planType !== PlanType.FREE
      ? `Renews on ${new Date(nextBillDate).toLocaleDateString()}`
      : null;

  const tooltip =
    intervalInMonths === 12
      ? nextBillDateTooltip
      : nextBillDateTooltip
      ? nextBillDateTooltip
      : `Renews on the ${
          subscriptionStartedAt
            ? getOrdinalDayOfMonthFromTimestamp(subscriptionStartedAt)
            : "1st"
        } of every month`;

  let remainingMins = `${remainingProcessingTime} / ${allowedMinutes}`;
  if (planType === PlanType.UNLIMITED) {
    remainingMins = "∞";
  }

  const handleLogoClick = () => {
    saveValueToLocalStorage("homePageOffset", 0);
    console;
    navigate("/");
  };

  useUpdateEffect(() => {
    if (!usageDataIsLoading) {
      trackUserUsage(usageData?.usage);
    }
  }, [usageDataIsLoading]);

  return (
    <>
      <header className="bg-white dark:bg-gray-700">
        {/* <AccountDeleteModal
        setAccountDeleteModal={setAccountDeleteModal}
        displayProjectDeleteModal={displayProjectDeleteModal}
      /> */}
        <nav className="border-gray-200 bg-white px-4 py-2 dark:bg-gray-800 lg:px-6 z-50 relative">
          <div className="mx-auto grid max-w-screen-2xl grid-cols-2 items-center">
            {!pathname.includes(RouterPath.ON_BOARDING) ? (
              <div className="order-3  col-span-1 flex gap-7 items-center justify-end">
                <div
                  className={
                    planType === PlanType.FREE
                      ? "cursor-default"
                      : "cursor-pointer"
                  }
                  onClick={() => {
                    if (planType !== PlanType.FREE) {
                      dispatch(toggleAddMoreMinModal(true));
                      addMoreMinutesButtonClick(AMPLITUDE_CLICK_EVENTS.HEADER);
                    }
                  }}
                >
                  {usageDataIsLoading || (
                    <Tooltip
                      content={tooltip}
                      className={tooltip ? "" : "hidden"}
                    >
                      <div
                        className={`flex items-center gap-1.5 px-3 py-1 text-sm font-semibold select-none rounded-md ${getLabelColorByRemainingProcessingTime(
                          remainingProcessingTime
                        )}`}
                      >
                        <ClockIcon className="w-4" />
                        {remainingMins + " mins left"}
                      </div>
                    </Tooltip>
                  )}
                </div>
                <button
                  id={HEADER_OPEN_UPGRADE_MODAL.ID}
                  className="relative inline-flex items-center justify-center 
                  p-0.5 overflow-hidden text-sm font-medium text-gray-900 
                  rounded-lg group bg-gradient-to-br from-purple-600 
                  to-blue-500 group-hover:from-purple-600 
                  group-hover:to-blue-500 hover:text-white dark:text-white 
                  focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
                  onClick={() => {
                    handelShowUpgradeModal();
                    trackUpgradeButton();
                  }}
                >
                  <span className="relative px-5 py-1 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                    Upgrade
                  </span>
                </button>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    window.open("https://discord.gg/M8rJ4c2zvZ", "_blank");
                    trackDiscordIconClick();
                  }}
                >
                  <img
                    src={DiscordIcon}
                    alt="discord"
                    width={27}
                    height={27}
                  />
                </div>
                <div className="flex items-center">
                  <Tooltip
                    content="What's new?"
                    className="font-normal"
                  >
                    <SparklesIcon
                      className="cursor-pointer w-6 text-orange-500"
                      onClick={() => {
                        window.open(
                          "https://vidyo-ai.notion.site/What-s-New-82529607e5e649b3b604a85079c9280a",
                          "_blank"
                        );
                        trackWhatsNewIconClick();
                      }}
                    />
                  </Tooltip>
                  <div className="mx-3 h-8 w-[1px] bg-gray-200"></div>
                  <UserOptionDropdown />
                </div>
              </div>
            ) : null}

            <div
              className="order-1 col-span-1 flex w-auto items-center justify-between"
              id="mobile-menu-2"
            >
              {pathname.includes(RouterPath.ON_BOARDING) ? (
                <ul className="flex flex-col  items-center md:flex-row md:space-x-8">
                  <li>
                    <p className="flex cursor-pointer items-center md:order-2 md:justify-center">
                      <img
                        src={VideoAILogo}
                        className="mr-1 h-6 sm:h-7"
                        alt="VidyoAI Logo"
                      />
                      <span className="self-center whitespace-nowrap text-lg font-semibold dark:text-white">
                        vidyo.ai
                      </span>
                    </p>
                  </li>
                </ul>
              ) : (
                <ul className="flex flex-col items-center sm:flex-row sm:space-x-6">
                  <li>
                    <p
                      onClick={handleLogoClick}
                      className="flex cursor-pointer items-center md:order-2 md:justify-center"
                    >
                      <img
                        src={VideoAILogo}
                        className="mr-1 h-6 sm:h-7"
                        alt="VidyoAI Logo"
                      />
                      <span className="self-center whitespace-nowrap text-lg font-semibold dark:text-white">
                        vidyo.ai
                      </span>
                    </p>
                  </li>
                  <li>
                    <Link to={RouterPath.HOME}>
                      <div
                        className={`hidden md:inline-block ${
                          pathname === RouterPath.HOME
                            ? "border-blue-600 font-semibold text-blue-600 dark:text-blue-500"
                            : "border-transparent hover:border-gray-300 hover:text-gray-600"
                        } rounded-t-lg`}
                      >
                        Home
                      </div>
                    </Link>
                  </li>
                  {showHomeOnly ? null : (
                    <li>
                      <Link
                        to={RouterPath.DOWNLOADS}
                        onClick={() => trackDownloadsTabClick()}
                      >
                        <div
                          className={`hidden md:inline-block  ${
                            pathname === RouterPath.DOWNLOADS
                              ? "border-blue-600 font-semibold text-blue-600 dark:text-blue-500"
                              : "border-transparent hover:border-gray-300 hover:text-gray-600"
                          }`}
                        >
                          Downloads
                          <span className="ml-2 px-1.5 py-0.5 rounded text-green-800 bg-green-100 text-sm">
                            New
                          </span>
                        </div>
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link to={RouterPath.SCHEDULED_POSTS}>
                      <div
                        className={`md:inline-block ${
                          pathname === RouterPath.SCHEDULED_POSTS
                            ? "border-blue-600 font-semibold text-blue-600 dark:text-blue-500"
                            : "border-transparent hover:border-gray-300 hover:text-gray-600"
                        } rounded-t-lg min-w-[195px]`}
                      >
                        Social Media Posts
                        <span className="ml-2 px-1.5 py-0.5 rounded text-green-800 bg-green-100 text-sm">
                          New
                        </span>
                      </div>
                    </Link>
                  </li>

                  {isAdminTemplateEditor() || showHomeOnly ? null : (
                    <li>
                      <div className="text-left">
                        <Menu
                          as="div"
                          className="relative hidden md:inline-block text-left"
                        >
                          {({ open }) => (
                            <>
                              <Menu.Button
                                className={`w-full focus:outline-none transition-transform duration-300 flex items-center ${
                                  pathname === RouterPath.BRAND_KIT ||
                                  pathname === RouterPath.MY_TEMPLATES
                                    ? "border-blue-600 font-semibold text-blue-600 dark:text-blue-500"
                                    : "whitespace-nowrap border-transparent hover:border-gray-300 hover:text-gray-600"
                                }`}
                              >
                                <span className="mr-1">Media</span>{" "}
                                <ChevronDownIcon
                                  className={clsx(
                                    open && "rotate-180",
                                    "h-4 w-5 mt-0.5"
                                  )}
                                  strokeWidth={3}
                                />
                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute -left-3 top-8 z-[60] w-40 origin-top-left rounded-md bg-white px-1 py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <Link
                                        to={RouterPath.BRAND_KIT}
                                        onClick={() => {
                                          trackMediaTabClick(
                                            ScreenName.BRAND_KIT
                                          );
                                        }}
                                      >
                                        <div
                                          className={`flex items-center justify-between mb-3 px-2 ${
                                            pathname === RouterPath.BRAND_KIT
                                              ? "border-blue-600 font-semibold text-blue-600 dark:text-blue-500"
                                              : "border-transparent hover:border-gray-300 hover:text-gray-700 text-gray-500 font-medium "
                                          }`}
                                        >
                                          Brand Kit
                                          <Badge
                                            color="purple"
                                            className="-mr-2"
                                          >
                                            NEW
                                          </Badge>
                                        </div>
                                      </Link>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <Link
                                        to={RouterPath.MY_TEMPLATES}
                                        onClick={() => {
                                          trackMediaTabClick(
                                            ScreenName.TEMPLATES
                                          );
                                        }}
                                      >
                                        <div
                                          className={`block mt-3.5 px-2 ${
                                            pathname === RouterPath.MY_TEMPLATES
                                              ? "border-blue-600 font-semibold text-blue-600 dark:text-blue-500"
                                              : "whitespace-nowrap border-transparent hover:border-gray-300 hover:text-gray-700 text-gray-500 font-medium"
                                          }`}
                                        >
                                          My Templates
                                        </div>
                                      </Link>
                                    )}
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      </div>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default memo(Header);
