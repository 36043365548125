import { fabric as fabricjs } from "fabric";
import FontFaceObserver from "fontfaceobserver";
import { nanoid } from "nanoid";

import { uploadFileToS3 } from "@/api/requests";

import { layoutsArray } from "@/constants/aspect-ratio";

import { adjustTextBoxHeight } from "@/helpers/editor";
import { getSubstringIndices } from "@/helpers/fabricjs/getSubstringIndices";

import {
  ClipPathProps,
  FontProperties,
  ProgressBarProps,
  SelectionStyles,
  TextboxProps,
  TextboxStyles,
  VideoProps,
} from "@/interfaces";

import {
  FontStyle,
  FontWeight,
  ImageType,
  STATUS,
  SimpleAssetType,
  TextEffect,
  TextElementType,
} from "@/enums";

import {
  INIT_TEXT_STYLES,
  DEFAULT_OUTRO_LENGTH_IN_SEC,
  colorsObj,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_SHADOW_PROPERTIES,
  DEFAULT_STROKE_WIDTH,
  NO_STROKE_WIDTH,
  DEFAULT_BLOCK_BACKGROUND_PADDING,
  INIT_SUBS_CONFIG,
  SAFE_PADDING_FOR_TEXT,
  NUMBER_TO_PREVENT_TEXT_BLEED,
  TEXT_TRANSFORM,
} from "@/views/editor/constant";

import {
  IndexInfo,
  getMultipleSubstringIndices,
} from "./getMultipleSubstringIndices";

const LAVENDER_INDIGO = "#8B3EFF";
const WHITE = "#FFFFFF";

export const initFabricGlobalProps = (fabric: any) => {
  fabric.Canvas.prototype.preserveObjectStacking = true;
  fabric.Canvas.prototype.controlsAboveOverlay = true;
  fabric.Canvas.prototype.selection = false;

  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.cornerColor = WHITE;
  fabric.Object.prototype.cornerSize = 12;
  fabric.Object.prototype.cornerStyle = "circle";
  fabric.Object.prototype.cornerStrokeColor = LAVENDER_INDIGO;
  fabric.Object.prototype.cornerStrokeWidth = 1;
  fabric.Object.prototype.borderColor = LAVENDER_INDIGO;
  fabric.Object.prototype.borderOpacityWhenMoving = 1;
  fabric.Object.prototype.borderScaleFactor = 2;
  fabric.Object.prototype.lockScalingFlip = true;

  // set fabric object control border properties
  fabric.Object.prototype.controls.mtr.visible = false;
  fabric.Object.prototype.controls.mt.visible = false;
  fabric.Object.prototype.controls.mb.visible = false;
  // set editing border color for text
  fabric.IText.prototype.editingBorderColor = LAVENDER_INDIGO;
  // fabric.IText.prototype.lineHeight = 1;
  fabric.IText.prototype.paintFirst = "stroke";
  fabric.IText.prototype.strokeWidth = DEFAULT_STROKE_WIDTH;

  // remove ml and mr control for Image object
  fabric.Image.prototype.controls.ml.visible = false;
  fabric.Image.prototype.controls.mr.visible = false;
  fabric.Image.prototype.strokeWidth = NO_STROKE_WIDTH;

  // remove corners for Rect object
  fabric.Rect.prototype.hasontrols = false;
  fabric.Rect.prototype.strokeWidth = NO_STROKE_WIDTH;
};

const getFontKey = (fontProperties: FontProperties) => {
  return `${fontProperties.fontFamily}-${fontProperties.fontWeight}-${fontProperties.fontStyle}`;
};

const loadedFonts: Record<string, { status: STATUS; cbQueue: VoidFunction[] }> =
  {};
const loadFontWithFontFaceObserverPromised = (
  fontProperties: FontProperties
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const fontKey = getFontKey(fontProperties);
    let shouldLoad = false;
    // load the font if the key does not exists on the map, if it does not exists then define the whole object
    // or it is not success (that means, idle, loading or error will be loaded again)
    if (
      !loadedFonts[fontKey] ||
      [STATUS.IDLE, STATUS.ERROR].includes(loadedFonts[fontKey].status)
    ) {
      shouldLoad = true;
      loadedFonts[fontKey] = {
        status: STATUS.LOADING,
        cbQueue: loadedFonts[fontKey]?.cbQueue || [],
      };
    }

    if (shouldLoad) {
      const myFont = new FontFaceObserver(fontProperties.fontFamily, {
        style: fontProperties.fontStyle,
        weight: fontProperties.fontWeight,
      });

      loadedFonts[fontKey].cbQueue.push(() => {
        resolve();
      });
      myFont
        .load(null, 5_000)
        .then(() => {
          // set font as loaded in session storage
          loadedFonts[fontKey].status = STATUS.SUCCESS;
          //Re-calculate font char width and add text background color
          loadedFonts[fontKey]?.cbQueue.forEach((cb) => cb());
          fabricjs.util.clearFabricFontCache();
        })
        .catch((error: any) => {
          console.debug(`font loading failed ${fontProperties.fontFamily}`);
          loadedFonts[fontKey].status = STATUS.ERROR;
          reject(JSON.stringify(error));
        });
    } else if (loadedFonts[fontKey].status === STATUS.LOADING) {
      loadedFonts[fontKey].cbQueue.push(() => {
        resolve();
      });
    } else {
      resolve();
    }
  });
};

export const getNewFabricStaticCanvas = (
  element: HTMLCanvasElement,
  backgroundColor?: string
) => {
  initFabricGlobalProps(fabricjs);
  const fabricCanvas = new fabricjs.StaticCanvas(element);
  backgroundColor && fabricCanvas.setBackgroundColor(backgroundColor);
  return fabricCanvas;
};

export const getNewFabricCanvas = (
  element: HTMLCanvasElement,
  backgroundColor?: string
) => {
  initFabricGlobalProps(fabricjs);
  const fabricCanvas = new fabricjs.Canvas(element);
  backgroundColor && fabricCanvas.setBackgroundColor(backgroundColor);
  return fabricCanvas;
};

const scaleIfRequired = (value?: number, scaleRatio?: number) => {
  if (value && scaleRatio) {
    return value * scaleRatio;
  }
  return value;
};

export const addStyleToTextObj = (
  textFabricObj: fabric.Textbox | fabric.Group,
  textStyles: TextboxStyles,
  fabricCanvas: fabric.Canvas | undefined = undefined
) => {
  if (textFabricObj instanceof fabricjs.Textbox) {
    // convert the above setting of properties to object in a single go
    (textFabricObj as fabric.Textbox).set({
      fontFamily: textStyles.fontFamily,
      fontSize: textStyles.fontSize,
      fontWeight: textStyles.fontWeight,
      fontStyle: textStyles.fontStyle,
      underline: textStyles.underline,
      textAlign: textStyles.textAlign,
      fill: textStyles.fill,
      lineHeight: textStyles.lineHeight || INIT_TEXT_STYLES.line_height,
    });

    // apply text effect
    let shadow;
    let textBackgroundColor;
    let stroke;
    let strokeWidth;
    let backgroundColor;

    if (textStyles.shadow.enabled) {
      shadow = new fabricjs.Shadow({
        ...DEFAULT_SHADOW_PROPERTIES,
        color: textStyles.shadow.color,
        ...textStyles.shadow.config,
      });
    }

    if (textStyles.stroke.enabled) {
      stroke = textStyles.stroke.color;
      strokeWidth = textStyles.stroke.config.width;
    }

    // shadow should
    if (textStyles.textBgColor.enabled && !textStyles.blockBackground.enabled) {
      textBackgroundColor = textStyles.textBgColor.color;
    }

    // convert the above text
    (textFabricObj as fabric.Textbox).set({
      stroke: stroke,
      strokeWidth: strokeWidth,
      textBackgroundColor: textBackgroundColor,
      backgroundColor: backgroundColor,
      shadow: shadow,
    });

    loadAndUseFontOnFabric(
      {
        fontFamily: textStyles.fontFamily,
        fontWeight: textStyles.fontWeight,
        fontStyle: textStyles.fontStyle,
      },
      textFabricObj as fabric.Textbox,
      fabricCanvas,
      false
    );
  } else if (textFabricObj instanceof fabricjs.Group) {
    // find textbox and rectangle element separately
    const findObject = (type: any) =>
      (textFabricObj as fabric.Group).getObjects().find((obj) => {
        if (obj instanceof type) return true;
        return false;
      });
    const textGroup = findObject(fabricjs.Group);
    const height = (textGroup?.height || 0) + DEFAULT_BLOCK_BACKGROUND_PADDING;
    const width = textGroup?.width;
    let shadow;
    if (textStyles.shadow.enabled) {
      shadow = new fabricjs.Shadow({
        ...DEFAULT_SHADOW_PROPERTIES,
        color: textStyles.shadow.color,
        ...textStyles.shadow.config,
      });
    }
    (textFabricObj as fabric.Group).set({
      shadow,
    });
    (textFabricObj as fabric.Group).forEachObject((obj: any) => {
      if (obj instanceof fabricjs.Textbox) {
        setProperties(obj, {
          fontSize: textStyles.fontSize,
          fontWeight: textStyles.fontWeight,
          fontStyle: textStyles.fontStyle,
          underline: textStyles.underline,
          textAlign: textStyles.textAlign || INIT_TEXT_STYLES.text_align,
          lineHeight: textStyles.lineHeight || INIT_TEXT_STYLES.line_height,
          fill: "transparent",
          top: 0,
          left: 0,
          originY: "center",
          originX: "center",
        });

        // apply text effect
        let textShadow;
        let textBackgroundColor;
        let stroke;
        let strokeWidth;

        if (textStyles.shadow.enabled && !textStyles.blockBackground.enabled) {
          textShadow = new fabricjs.Shadow({
            ...DEFAULT_SHADOW_PROPERTIES,
            color: textStyles.shadow.color,
            ...textStyles.shadow.config,
          });
        }

        if (textStyles.stroke.enabled) {
          stroke = textStyles.stroke.color;
          strokeWidth = textStyles.stroke.config.width;
        }

        if (textStyles.textBgColor.enabled) {
          textBackgroundColor = textStyles.textBgColor.color;
        }
        setProperties(obj, {
          stroke: stroke,
          strokeWidth: strokeWidth,
          textBackgroundColor: textBackgroundColor,
        });
        loadAndUseFontOnFabric(
          {
            fontFamily: textStyles.fontFamily,
            fontWeight: textStyles.fontWeight,
            fontStyle: textStyles.fontStyle,
          },
          obj,
          fabricCanvas,
          false
        );
      }

      if (obj instanceof fabricjs.Group) {
        (obj as fabric.Group).forEachObject((item: any) => {
          if (item instanceof fabricjs.Text) {
            setProperties(item, {
              fontSize: textStyles.fontSize,
              fontWeight: textStyles.fontWeight,
              fontStyle: textStyles.fontStyle,
              underline: textStyles.underline,
              textAlign: textStyles.textAlign || INIT_TEXT_STYLES.text_align,
              lineHeight: textStyles.lineHeight || INIT_TEXT_STYLES.line_height,
              fill: textStyles.fill,
            });

            // apply text effect
            let textShadow;
            let textBackgroundColor;
            let stroke;
            let strokeWidth;

            if (textStyles.shadow.enabled) {
              textShadow = new fabricjs.Shadow({
                ...DEFAULT_SHADOW_PROPERTIES,
                color: textStyles.shadow.color,
                ...textStyles.shadow.config,
              });
            }

            if (textStyles.stroke.enabled) {
              stroke = textStyles.stroke.color;
              strokeWidth = textStyles.stroke.config.width;
            }

            if (textStyles.textBgColor.enabled) {
              textBackgroundColor = textStyles.textBgColor.color;
            }
            setProperties(item, {
              stroke: stroke,
              strokeWidth: strokeWidth,
              textBackgroundColor: textBackgroundColor,
            });
            loadAndUseFontOnFabric(
              {
                fontFamily: textStyles.fontFamily,
                fontWeight: textStyles.fontWeight,
                fontStyle: textStyles.fontStyle,
              },
              item,
              fabricCanvas,
              false
            );
          }
          if (item instanceof fabricjs.Rect) {
          }
        });
      }

      if (obj instanceof fabricjs.Rect) {
        let backgroundColor;
        let radius;
        if (textStyles.blockBackground.enabled) {
          backgroundColor = textStyles.blockBackground.color;
          radius = textStyles.blockBackground.radius;
        }

        const stylesToSet = {
          height,
          width,
          rx: radius,
          ry: radius,
          fill: backgroundColor || "transparent",
        };
        setProperties(obj, stylesToSet);
      }
    });
  }
  return textFabricObj;
};

export const getClipPath = ({
  id,
  left = 0,
  top = 0,
  width,
  height,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  scaleRatio,
}: ClipPathProps) => {
  return new fabricjs.Rect({
    id: id || nanoid(),
    left: scaleIfRequired(left, scaleRatio),
    top: scaleIfRequired(top, scaleRatio),
    width: scaleIfRequired(width, scaleRatio),
    height: scaleIfRequired(height, scaleRatio),
    fill: backgroundColor,
    selectable: false,
    hasControls: false,
    absolutePositioned: true,
  });
};

export interface GroupedTextProps
  extends Omit<TextboxStyles, "fontSize" | "lineHeight"> {
  top: number;
  left: number;
  text: string;
  width: number;
  fontSize?: number;
  scaleText?: number;
  maxHeight?: number;
  lineHeight?: number;
  selectionStyle?: SelectionStyles;
  inRender?: boolean;
  padding: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
  margin: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
  clipPath?: fabric.Rect;
}

const getGroupedText = (originalProps: GroupedTextProps) => {
  let textToMatch: string;
  let highlightedText: fabric.Text;
  let props = originalProps ? JSON.parse(JSON.stringify(originalProps)) : {};
  let fontFamily = props.fontFamily;

  // adding some offset to accomodate the lowecase letters like g, p, q, etc.
  props.padding.bottom =
    props.padding?.bottom + (props.fontSize ? props.fontSize / 5 : 0);

  if (props.selectionStyle) {
    const { style, startIndex, endIndex } = props.selectionStyle;
    textToMatch = props.text.slice(startIndex, endIndex);
    // in case of WORD BACKGROUND, we need to add the rectangle rather than giving the text styles.
    if (style.textBackgroundColor && textToMatch) {
      // draw the text, get the height and width
      highlightedText = new fabricjs.Text(textToMatch, {
        fontFamily,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
      });
    }
  }

  const strokeWidth = props.stroke.enabled ? props.stroke.config.width : 0;
  const strokeColour = props.stroke.enabled ? props.stroke.color : "";

  // dummy text element to get the text lines
  const textEl = new fabricjs.Textbox(props.text, {
    fontFamily,
    fontSize: props.fontSize,
    left: 0,
    top: 0,
    width:
      props.width -
      (props.padding?.left +
        props.padding?.right +
        props.margin?.left +
        props.margin?.right),
    // these properties don't matter because this element is not rendered
    textBackgroundColor: "transparent",
    fill: "transparent",
    stroke: strokeColour,
    strokeWidth: strokeWidth,
    textAlign: props.textAlign || "center",
    lineHeight: props.lineHeight,
    fontWeight: props.fontWeight,
    underline: props.underline,
    paintFirst: "stroke",
  });

  // make shadows to apply as effect, pre text node creation
  // 1. Create text shadow if the shadow is enabled and the block background is not enabled and the text background is not enabled
  const shadow =
    props.shadow?.enabled && !props.blockBackground?.enabled
      ? new fabricjs.Shadow({
          color: props.shadow.color,
          nonScaling: false,
          offsetX: (
            props.shadow.config.offsetX || DEFAULT_SHADOW_PROPERTIES.offsetX
          ).toString(),
          offsetY: (
            props.shadow.config.offsetY || DEFAULT_SHADOW_PROPERTIES.offsetY
          ).toString(),
          blur: (
            props.shadow.config.blur || DEFAULT_SHADOW_PROPERTIES.blur
          ).toString(),
        })
      : null;

  let selectionStylesDataForBg:
    | ReturnType<typeof getSubstringIndices>
    | undefined = undefined;
  let selectionStylesDataForFill: IndexInfo[] | undefined = undefined;

  // if word highlight, then we use multiple substring indices
  if (props.selectionStyle?.style?.fill) {
    selectionStylesDataForFill = getMultipleSubstringIndices(
      textEl.textLines,
      props.selectionStyle.startIndex,
      props.selectionStyle.endIndex
    );
  } else if (props.selectionStyle?.style?.textBackgroundColor) {
    selectionStylesDataForBg = getSubstringIndices(
      textEl.textLines,
      props.selectionStyle.startIndex,
      props.selectionStyle.endIndex
    );
  }

  let offsetToAddInWidth = 0;

  if (props.selectionStyle && props.selectionStyle.style.textBackgroundColor) {
    offsetToAddInWidth = props.fontSize / 3;
  }

  const shapes = textEl.textLines.reduce(
    (acc: (fabric.Rect | fabric.Text)[], line: string, index: number) => {
      const textHeight = Math.floor(textEl.lineHeight * textEl.fontSize);
      const top =
        index *
        (textHeight +
          props.padding?.top +
          props.padding?.bottom +
          props.margin?.bottom);
      let selectionStylesToApply;

      // Make the text node for line I
      const text = new fabricjs.Text(line, {
        fontFamily,
        fontStyle: props.fontStyle,
        fontSize: props.fontSize,
        left: 0,
        top: top,
        fill: props.fill,
        stroke: strokeColour,
        strokeWidth: strokeWidth,
        width: textEl.width,
        editable: false,
        selectable: false,
        lineHeight: props.lineHeight,
        shadow: !props.textBgColor?.enabled && shadow,
        backgroundColor: "transparent",
        fontWeight: props.fontWeight,
        underline: props.underline,
        paintFirst: "stroke",
        strokeLineJoin: "round",
      });
      // create the outer 'margin' rect, note the position is negatively offset for padding & margin
      // and the width is sized from the dimensions of the text node plus 2 x (padding + margin).
      const rectMargin = new fabricjs.Rect({
        left: -1 * (props.padding?.left + props.margin?.left),
        top: top - (props.padding?.top + props.margin?.top),
        width:
          text.width +
          (props.padding?.left +
            props.padding?.right +
            (props.margin?.left + props.margin?.right)),
        height:
          textHeight +
          (props.padding?.top +
            props.padding?.bottom +
            // if this is the last line, don't add the bottom margin
            (props.margin?.top + props.margin?.bottom)),
        fill: "transparent",
        editable: false,
        backgroundColor: "transparent",
        selectable: false,
      });

      let rectPaddingLeft = -1 * props.padding?.left;

      // set the left position based on the text alignment
      // set the left position based on the text alignment
      if (props.textAlign === "right") {
        rectPaddingLeft = textEl.width - rectMargin.width;
        rectMargin.left = rectPaddingLeft;
        text.left = rectPaddingLeft + (props.padding.left + props.margin.left);
      } else if (props.textAlign === "center") {
        rectPaddingLeft = (textEl.width - rectMargin.width) / 2;
        rectMargin.left = rectPaddingLeft;
        text.left = rectPaddingLeft + (props.padding.left + props.margin.left);
      }
      const radiusToApply = props.textBgColor?.radius;

      const rectPadding = new fabricjs.Rect({
        width:
          text.width +
          (props.padding?.left + props.padding?.right) +
          offsetToAddInWidth,
        height: textHeight + (props.padding?.top + props.padding?.bottom),
        left: rectPaddingLeft + props.margin?.left - offsetToAddInWidth / 2,
        top: top - props.padding?.top,
        fill: props.textBgColor?.enabled
          ? props.textBgColor?.color
          : "transparent",
        rx: radiusToApply,
        ry: radiusToApply,
        editable: false,
        selectable: false,
        shadow: props.textBgColor?.enabled && shadow,
      });

      // Managing the highlighted text background
      let rectToAdd: fabric.Rect;
      if (
        props.selectionStyle?.style?.textBackgroundColor &&
        selectionStylesDataForBg?.arrayIndex === index
      ) {
        // we have to get the items before the text that is matched
        const textBeforeMatchWidth = new fabricjs.Text(
          selectionStylesDataForBg.beforeSubstring,
          {
            fontFamily,
            fontSize: props.fontSize,
            left: 0,
            top: 0,
            // these properties don't matter because this element is not rendered
            textBackgroundColor: "transparent",
            fill: "transparent",
            stroke: props.stroke?.enabled ? props.stroke.color : "",
            strokeWidth: props.stroke?.enabled ? props.stroke.config.width : 0,
            textAlign: props.textAlign || "center",
            lineHeight: props.lineHeight,
            fontWeight: props.fontWeight,
            underline: props.underline,
            paintFirst: "stroke",
          }
        ).width;

        if (props.selectionStyle?.style?.textBackgroundColor) {
          rectToAdd = new fabricjs.Rect({
            // Hightlighted text can be undefined because if there no text to highlight.
            // We will 0 in that case of the height.
            width: (highlightedText?.width || 0) + offsetToAddInWidth,
            height: textHeight + (props.padding.top + props.padding.bottom),
            fill: props.selectionStyle.style.textBackgroundColor,
            rx: 5,
            ry: 5,
            top: top - props.padding.top,
            left:
              text.left +
              (selectionStylesDataForBg.beforeSubstring !== ""
                ? textBeforeMatchWidth
                : 0) -
              offsetToAddInWidth / 2,
            selectable: false,
          });
        }
      } else if (
        props.selectionStyle?.style?.fill &&
        selectionStylesDataForFill?.[index]?.arrayIndex === index
      ) {
        const selection = selectionStylesDataForFill?.[index];
        selectionStylesToApply = {
          start: selection.startIndex,
          // offsetting for the last character, we are adding in the next if condition
          end: selection.endIndex,
          style: props.selectionStyle.style,
        };
      }
      if (selectionStylesToApply && selectionStylesToApply.end) {
        text.setSelectionStyles(
          selectionStylesToApply.style,
          selectionStylesToApply.start,
          selectionStylesToApply.end + 1
        );
      }

      // props.clipPath && text.set("clipPath", props.clipPath);

      return [
        ...acc,
        rectPadding,
        // @ts-expect-error no idea why this is throwing error
        ...(rectToAdd ? [rectToAdd] : []),
        text,
      ];
    },
    []
  );

  let left = 0;

  const finalMaxWidth = Math.max(
    ...shapes.map((shape: fabric.Rect | fabric.Text) => shape.width),
    props.width
  );

  // if the alignment is left then we have to add the left margin
  if (props.textAlign === "left") {
    left = -(finalMaxWidth / 2);
  }
  if (props.textAlign === "right") {
    left = finalMaxWidth / 2;
  }

  const finalGroup = new fabricjs.Group(shapes, {
    top: 0,
    left,
    originX: props.textAlign,
    originY: "center",
    meta: {
      width: finalMaxWidth,
      fontSize: props.fontSize,
      text: props.text,
    },
  });

  return finalGroup;
};

export const updateGroupedText = async (
  group: fabric.Group,
  props: Partial<GroupedTextProps>,
  callback?: (a: { fontSize: number; top: number; maxHeight: number }) => void,
  adjustHeight: boolean = false
) => {
  const groupedText = group?.getObjects()?.find((obj) => obj?.type === "group");
  const rect = group?.getObjects()?.find((obj) => obj?.type === "rect");
  let fontSize;
  let finalizedFontSize;
  let textCenter;
  let finalHeight;
  // first load the given font. Then move onto the next step.
  if (props.fontFamily && props.fontStyle && props.fontWeight) {
    loadFontWithFontFaceObserverPromised({
      fontFamily: props.fontFamily,
      fontStyle: props.fontStyle,
      fontWeight: props.fontWeight,
    }).catch(() => console.log("Font loading failed."));
  }
  if (groupedText) {
    // @ts-expect-error meta not defined
    const width = props.width || groupedText?.meta.width;
    // NOTE: if the props.scaleText is passed, we need to get the font size of text and find a new font size
    // based on the scale ratio. Then set the height.
    if (props.scaleText) {
      // scale default padding and default margin, and block bg padding according
      fontSize = parseFloat(
        // @ts-expect-error meta not defined
        (groupedText.meta.fontSize * props.scaleText).toFixed(2)
      );
    } else {
      fontSize = props.fontSize;
    }

    const text =
      props?.textTransform === TEXT_TRANSFORM.UPPERCASE
        ? // @ts-expect-error meta not defined
          (props.text ?? groupedText.meta?.text)?.toUpperCase()
        : // @ts-expect-error meta not defined
          props.text ?? groupedText.meta?.text;

    let updatedGroupedText: fabric.Group = getGroupedText({
      ...(props as GroupedTextProps),
      fontSize,
      text,
      width: width,
    });

    // NOTE: we have to decide what makes the width of the group.
    const finalWidth =
      Math.max(
        (updatedGroupedText.width || 0) + NUMBER_TO_PREVENT_TEXT_BLEED,
        width || 0
      ) + (props.blockBackground?.padding || 0);

    finalHeight =
      (updatedGroupedText.height || 0) + (props.blockBackground?.padding || 0);

    finalizedFontSize = fontSize;

    // We have to bring the height of the group inside the maxHeight
    if (adjustHeight && props.maxHeight && finalizedFontSize) {
      /**
       * TODO: Need to check why the bellow loop is running infinite times.
       * For now adding some checks to break the loop.
       */
      let iterationCount = 0;
      const MAX_ITERATIONS = 100;
      const MIN_FONT_SIZE = 5;

      const maxHeight = props.maxHeight;
      // lower the height of element until is less than maxHeight
      while (maxHeight < finalHeight) {
        finalizedFontSize = Math.floor(finalizedFontSize - 1);

        // Setting a Minimum Font Size
        if (finalizedFontSize <= MIN_FONT_SIZE) {
          console.warn(
            "Minimum font size reached, breaking out of loop.",
            finalizedFontSize,
            text
          );
          break;
        }

        // Limit the Number of Iterations
        if (iterationCount >= MAX_ITERATIONS) {
          console.warn(
            "Max iterations reached, breaking out of loop.",
            iterationCount,
            text
          );
          break;
        }

        updatedGroupedText = getGroupedText({
          ...(props as GroupedTextProps),
          fontSize: finalizedFontSize,
          // @ts-expect-error meta not defined
          text: props.text ?? groupedText.meta?.text,
          width: width,
        });
        finalHeight =
          (updatedGroupedText.height || 0) +
          (props.blockBackground?.padding || 0);

        iterationCount++;
      }
      // we have to make items in the center
      if (typeof group.top !== "undefined") {
        textCenter =
          group.top + (maxHeight - finalHeight + finalizedFontSize / 5);
        group.set("top", textCenter);
      }
      // call the callback to update the font size.
    }
    group.remove(groupedText);
    const inRender = props.inRender;
    group.add(updatedGroupedText);
    // while rendering the group, we have to add some padding to the group
    // so the shadows render in the group and no part gets cropped.
    setProperties(group, {
      height: finalHeight + (inRender ? SAFE_PADDING_FOR_TEXT : 0),
      width: finalWidth + (inRender ? SAFE_PADDING_FOR_TEXT : 0),
      scaleX: 1,
      scaleY: 1,
    });
    if (rect) {
      // make shadows to apply as effect, pre text node creation
      // 1. Create text shadow if the shadow is enabled and the block background is not enabled and the text background is not enabled
      const shadow =
        props?.shadow?.enabled && props.blockBackground?.enabled
          ? new fabricjs.Shadow({
              color: props.shadow.color,
              nonScaling: false,
              offsetX: (
                props.shadow.config.offsetX || DEFAULT_SHADOW_PROPERTIES.offsetX
              ).toString(),
              offsetY: (
                props.shadow.config.offsetY || DEFAULT_SHADOW_PROPERTIES.offsetY
              ).toString(),
              blur: (
                props.shadow.config.blur || DEFAULT_SHADOW_PROPERTIES.blur
              ).toString(),
            })
          : null;
      setProperties(rect, {
        width: finalWidth,
        height: finalHeight,
        fill: props.blockBackground?.enabled
          ? props.blockBackground?.color
          : "transparent",
        rx: props.blockBackground?.radius,
        ry: props.blockBackground?.radius,
        shadow,
      });
    }
  }
  if (callback && finalizedFontSize && textCenter && finalHeight) {
    callback({
      fontSize: finalizedFontSize,
      top: textCenter,
      maxHeight: finalHeight,
    });
  }
  return group;
};

export const getTextbox = (textboxProps: TextboxProps) => {
  const {
    id,
    left = 0,
    top = 0,
    width,
    // No idea what this does
    maxHeight,
    text = "",
    style,
    type = TextElementType.NORMAL_TEXT,
    scaleRatio,
    clipPath,
  } = textboxProps;

  const scaleWidth = scaleIfRequired(width, scaleRatio);

  const blockPadding = style?.blockBackground?.padding || 0;

  const textGroup = getGroupedText({
    ...(style as GroupedTextProps),
    text,
    ...(scaleWidth && { width: scaleWidth }),
    scaleText: scaleRatio,
    clipPath,
  });

  // make shadows to apply as effect, pre text node creation
  // 1. Create text shadow if the shadow is enabled and the block background is not enabled and the text background is not enabled
  const shadow =
    style?.shadow?.enabled && style.blockBackground?.enabled
      ? new fabricjs.Shadow({
          color: style.shadow.color,
          nonScaling: false,
          offsetX: (
            style.shadow.config.offsetX || DEFAULT_SHADOW_PROPERTIES.offsetX
          ).toString(),
          offsetY: (
            style.shadow.config.offsetY || DEFAULT_SHADOW_PROPERTIES.offsetY
          ).toString(),
          blur: (
            style.shadow.config.blur || DEFAULT_SHADOW_PROPERTIES.blur
          ).toString(),
        })
      : null;
  // wrapper for text, to apply styles
  let wrapper = new fabricjs.Rect({
    left: 0,
    top: 0,
    width: textGroup.width + blockPadding,
    height: textGroup.height + blockPadding,
    originX: "center",
    originY: "center",
    fill: style?.blockBackground?.enabled
      ? style.blockBackground.color
      : "transparent",
    shadow,
  });

  let renderableText = new fabricjs.Group([wrapper, textGroup], {
    selectable: true,
    left: scaleIfRequired(left, scaleRatio),
    top: scaleIfRequired(top, scaleRatio),
    id: id || nanoid(),
    editable: false,
    type,
    currentText: text,
    // clipPath,
  });

  // set width once style are applied
  renderableText.set({
    left: scaleIfRequired(left, scaleRatio),
    top: scaleIfRequired(top, scaleRatio),
  });

  if (type !== TextElementType.SUBTITLE_EMOJI) {
    renderableText.setControlVisible("ml", true);
    renderableText.setControlVisible("mr", true);
  }

  renderableText.bringToFront(textGroup);
  return renderableText;
};

export const getFabricVideo = (videoProps: VideoProps) => {
  const {
    id,
    left = 0,
    top = 0,
    videoElement,
    scaleRatio,
    clipPath,
    onModified,
    selectable = true,
    hasControls = true,
    type = "",
    assetType = SimpleAssetType.IMAGE,
    assetTag,
  } = videoProps;
  const video = new fabricjs.Image(videoElement, {
    id: id || nanoid(),
    left: scaleIfRequired(left, scaleRatio),
    top: scaleIfRequired(top, scaleRatio),
    renderOnAddRemove: false,
    selectable,
    hasControls,
    type,
    assetType,
    assetTag,
  });
  onModified && video.on("modified", onModified);
  clipPath && video.set("clipPath", clipPath);

  return video;
};

export const getProgressBar = (progressBarProps: ProgressBarProps) => {
  const {
    id,
    left = 0,
    top = 0,
    width = 0,
    maxWidth,
    height,
    fill,
    scaleRatio,
    clipPath,
  } = progressBarProps;
  const progressBar = new fabricjs.Rect({
    id: id || nanoid(),
    left: scaleIfRequired(left, scaleRatio),
    top: scaleIfRequired(top, scaleRatio),
    width: scaleIfRequired(width, scaleRatio),
    height: scaleIfRequired(height, scaleRatio),
    maxWidth: scaleIfRequired(maxWidth, scaleRatio),
    fill: fill,
    lockScalingX: true,
    lockMovementX: true,
  });
  clipPath && progressBar.set("clipPath", clipPath);
  return progressBar;
};

const loadFontWithFontFaceObserver = (
  fontProperties: FontProperties,
  onSuccess?: () => void,
  onError?: () => void
) => {
  const fontKey = `${fontProperties.fontFamily}-${fontProperties.fontWeight}-${fontProperties.fontStyle}`;
  const fontLoaded = loadedFonts[fontKey];
  if (!fontLoaded) {
    const myFont = new FontFaceObserver(fontProperties.fontFamily, {
      style: fontProperties.fontStyle,
      weight: fontProperties.fontWeight,
    });
    myFont
      .load()
      .then(() => {
        onSuccess && onSuccess();
        // set font as loaded in session storage
        loadedFonts[fontKey].status = STATUS.SUCCESS;
      })
      .catch(() => {
        console.debug(`Font loading failed: ${fontProperties.fontFamily}`);
        onError && onError();
      });
  } else {
    onSuccess && onSuccess();
  }
};

export const loadAndUseFontOnFabric = (
  fontProperties: FontProperties,
  fabricObject: fabric.Textbox,
  fabricCanvas?: fabric.Canvas | fabric.StaticCanvas,
  adjustHeight: boolean = true,
  onSuccess?: () => void
) => {
  loadFontWithFontFaceObserver(fontProperties, () => {
    if (fabricObject) {
      setFabricElementTextProperties(fabricObject, {
        fontFamily: fontProperties.fontFamily,
        fontWeight: fontProperties.fontWeight,
        fontStyle: fontProperties.fontStyle,
      });
      //Re-calculate font char width and add text background color
      fabricjs.util.clearFabricFontCache();
      fabricElementFunctionExecutor(fabricObject, "initDimensions");
      adjustHeight && adjustTextBoxHeight(fabricObject);
      fabricCanvas && fabricCanvas.renderAll();
      onSuccess && onSuccess();
    }
  });
};
export const loadFont = (
  fabricEl: fabric.Group,
  fontStyles: GroupedTextProps,
  cb?: VoidFunction,
  adjustHeight?: boolean,
  setFontSize?: (fontSize: number, top: number, maxHeight: number) => void
) => {
  const fontProperties = {
    fontFamily: fontStyles.fontFamily,
    fontWeight: fontStyles.fontWeight,
    fontStyle: fontStyles.fontStyle,
  };
  loadFontWithFontFaceObserverPromised(fontProperties)
    .then(() => {
      return updateGroupedText(
        fabricEl,
        fontStyles,
        ({ fontSize, top, maxHeight }) => {
          // adjust the fontsize of the text
          setFontSize && setFontSize(fontSize, top, maxHeight);
        },
        adjustHeight
      );
    })
    .then(() => {
      fabricjs.util.clearFabricFontCache();
      cb && cb();
    })
    .catch((err) => {
      console.debug(`Font loading failed: ${fontProperties}`);
    });
};

export const getVideoClipPath = (
  fabricObj: any,
  fabricActiveObj: any,
  isAdmin: boolean
) => {
  const clipPath: any = {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  };
  const templateHeightWidth = fabricObj?.getObjects()[0]?.getBoundingRect() || {
    width: 0,
    height: 0,
  };

  const objHeight = isAdmin
    ? fabricActiveObj.getBoundingRect().height
    : fabricActiveObj.clipPath.height;
  const objWidth = isAdmin
    ? fabricActiveObj.getBoundingRect().width
    : fabricActiveObj.clipPath.width;

  let objTop = isAdmin
    ? fabricActiveObj.top - templateHeightWidth.top
    : fabricActiveObj.clipPath.top - templateHeightWidth.top;

  if (objTop < 0) {
    let height = objHeight + objTop;
    if (height > templateHeightWidth.height) {
      height = templateHeightWidth.height;
    }
    clipPath.height = height;
    objTop = 0;
  } else {
    clipPath.height = objHeight;
    if (objHeight > templateHeightWidth.height - objTop) {
      clipPath.height = templateHeightWidth.height - objTop;
    }
  }
  clipPath.top = objTop;

  let objLeft = isAdmin
    ? fabricActiveObj.left - templateHeightWidth.left
    : fabricActiveObj.clipPath.left - templateHeightWidth.left;
  if (objLeft < 0) {
    let width = objWidth + objLeft;
    if (width > templateHeightWidth.width) {
      width = templateHeightWidth.width;
    }
    clipPath.width = width;
    objLeft = 0;
  } else {
    clipPath.width = objWidth;
    if (objWidth > templateHeightWidth.width - objLeft) {
      clipPath.width = templateHeightWidth.width - objLeft;
    }
  }
  clipPath.left = objLeft;

  return clipPath;
};

export const getTextEffectType = (config: typeof INIT_SUBS_CONFIG) => {
  // determine text effect
  // can be shadow, outline, background
  let textEffect: TextEffect = TextEffect.NONE;

  if (config.shadow?.enabled) {
    textEffect = TextEffect.SHADOW;
  } else if (config.stroke?.enabled) {
    textEffect = TextEffect.OUTLINE;
  } else if (config.textBgColor?.enabled) {
    textEffect = TextEffect.BACKGROUND;
  }
  return textEffect;
};

export const getTextEffectColor = (config: typeof INIT_SUBS_CONFIG) => {
  let textEffectColor: any = null;
  if (config.shadow?.enabled) {
    textEffectColor = config.shadow.color;
  } else if (config.stroke?.enabled) {
    textEffectColor = config.stroke.color;
  } else if (config.textBgColor) {
    textEffectColor = config.textBgColor.color;
  }
  return textEffectColor;
};

export function updateStylesWithNewProperties(styles: any) {
  if (!styles) {
    return styles;
  }

  if (
    !styles.hasOwnProperty("effect_type") ||
    !styles.hasOwnProperty("effect_color")
  ) {
    return {};
  }

  if (styles.hasOwnProperty("noEffect")) {
    return styles;
  }

  const updatedStylesWithNewOptions = {
    noEffect: styles.effect_type === TextEffect.NONE,
    shadow: {
      enabled: styles.effect_type === TextEffect.SHADOW,
      color: styles.effect_color || colorsObj.black,
      config: {
        blur: DEFAULT_SHADOW_PROPERTIES.blur,
        offsetX: DEFAULT_SHADOW_PROPERTIES.offsetX,
        offsetY: DEFAULT_SHADOW_PROPERTIES.offsetY,
      },
    },
    stroke: {
      enabled: styles.effect_type === TextEffect.OUTLINE,
      color: styles.effect_color || colorsObj.black,
      config: {
        width: DEFAULT_STROKE_WIDTH,
      },
    },
    textBgColor: {
      ...INIT_TEXT_STYLES.textBgColor,
      enabled: styles.effect_type === TextEffect.BACKGROUND,
      color: styles.effect_color || colorsObj.black,
    },
    blockBackground: {
      ...INIT_TEXT_STYLES.blockBackground,
      enabled: false,
      color: colorsObj.gray,
      ...styles.blockBackground,
    },
  };

  return { ...styles, ...updatedStylesWithNewOptions };
}

export const getTextProperties = (
  textObj: any,
  templateHeightWidth: any,
  config: typeof INIT_SUBS_CONFIG
) => {
  let textProps: any = {};

  if (textObj) {
    textProps.top = textObj.top - templateHeightWidth.top;
    textProps.left = textObj.left - templateHeightWidth.left;
    textProps.textBackgroundColor = textObj.textBackgroundColor;
    // get font properties from textbox
    const {
      fill,
      fontFamily,
      textAlign,
      fontSize,
      text,
      underline,
      fontWeight,
      width,
      lineHeight,
      maxHeight,
    } = getFabricElementTextProperties(textObj, [
      "fill",
      "fontFamily",
      "textAlign",
      "fontSize",
      "text",
      "fontWeight",
      "underline",
      "width",
      "height",
      "lineHeight",
      "maxHeight",
      "fontWeight",
      "fontStyle",
    ]);
    textProps = {
      ...textProps,
      fontColor: fill,
      fontFamily,
      textAlign,
      fontSize,
      text,
      bold: fontWeight === FontWeight.BOLD,
      underline,
      italic: fontWeight === FontStyle.ITALIC,
      width,
      lineHeight,
      maxHeight,
    };
    textProps.effect_type = getTextEffectType(config);
    textProps.effect_color = getTextEffectColor(config);
  }

  return updateStylesWithNewProperties(textProps);
};

export const getTextPropertiesFromTextStyle = (
  textStyle: any,
  templateProps: any
) => {
  const textProps: any = {};

  if (textStyle) {
    textProps.top = textStyle.coordinate_top - templateProps.top;
    textProps.left = textStyle.coordinate_left - templateProps.left;
    textProps.textBackgroundColor = textStyle.effect_color;
    textProps.fontColor = textStyle.font_color;
    textProps.fill = textStyle.font_color;
    textProps.fontFamily = textStyle.font_face;
    textProps.textAlign = textStyle.alignment;
    textProps.fontSize = textStyle.font_size;
    textProps.bold = textStyle.bold;
    textProps.underline = textStyle.underline;
    textProps.effect_type = textStyle.effect_type;
    textProps.effect_color = textStyle.effect_color;
    textProps.italic = textStyle.italic;
    textProps.width = textStyle.width;
    textProps.maxHeight = textStyle.maxHeight;
    textProps.lineHeight = textStyle.line_height;
    textProps.shadow = textStyle.shadow;
    textProps.stroke = textStyle.stroke;
    textProps.textBgColor = textStyle.textBgColor;
    textProps.blockBackground = textStyle.blockBackground;
    textProps.noEffect = textStyle.noEffect;
    textProps.margin = textStyle.margin;
    textProps.padding = textStyle.padding;
    textProps.textTransform = textStyle.textTransform;
  }
  return updateStylesWithNewProperties(textProps);
};

export const getBackgroundImage = (fabricObj: any) => {
  // iterate fabric objects to get object with id 'backgroundImage'
  const backgroundImage = fabricObj
    .getObjects()
    .find((obj: any) => obj.id === "backgroundImage");
  // get background image url
  const backgroundImageUrl = backgroundImage?.getSrc() || null;
  return backgroundImageUrl;
};

export const hasTwoFace = (fabricObj: any) => {
  // iterate fabric objects to get objects with videoLink
  const videoObjects = fabricObj.getObjects().filter((obj: any) => {
    return obj.videoLink;
  });

  return videoObjects.length === 2;
};

export const getProgressBarProperties = (
  fabricObjects: any,
  templateHeightWidth: any
) => {
  const properties: any = {
    // top: 301,
    // left: 0,
    // fill: "#FFFFFF",
    // height: 10,
    // width: 277,
  };
  if (fabricObjects.progressBarRect) {
    properties.top =
      fabricObjects.progressBarRect.top - templateHeightWidth.top;
    properties.left =
      fabricObjects.progressBarRect.left - templateHeightWidth.left;
    properties.width = fabricObjects.progressBarRect.totalWidth || 0;
    properties.height = fabricObjects.progressBarRect.height;
    properties.fill = fabricObjects.progressBarRect.fill;
  }
  return properties;
};

export const getImageProperties = async (
  imgObj: any,
  templateHeightWidth: any
) => {
  const imageProps: any = {};

  if (imgObj) {
    imageProps.top = imgObj.top - templateHeightWidth.top;
    imageProps.left = imgObj.left - templateHeightWidth.left;
    imageProps.width = imgObj.getBoundingRect().width;
    imageProps.height = imgObj.getBoundingRect().height;
    imageProps.url = imgObj?.url || imgObj.getSrc();
    imageProps.id = imgObj.id;
    imageProps.assetType = imgObj.assetType;
    imageProps.assetTag = imgObj.assetTag;
    imageProps.duration = imgObj.duration;
  }

  if (imgObj.file) {
    const res = await uploadFileToS3(imgObj.file);
    imageProps.url = res?.s3Url;
  }

  return imageProps;
};

export const getSocialsProperties = async (
  socialMediaHandles: any,
  fabricObj: any,
  templateHeightWidth: any,
  textsObj: any,
  isDraft = false
) => {
  const socials: any = [];

  for (const socialProps of socialMediaHandles) {
    const socialObj: any = {};

    for (const obj of fabricObj.getObjects()) {
      if (obj?.id?.includes(socialProps.id)) {
        if (obj?.type === SimpleAssetType.IMAGE) {
          socialObj.image = await getImageProperties(obj, templateHeightWidth);
        }

        if (obj?.type === TextElementType.SOCIAL_HANDLE_TEXT) {
          const textCanvasObj: any = textsObj[obj.id];
          socialObj.text = getTextPropertiesFromTextStyle(
            textCanvasObj.style,
            templateHeightWidth
          );
          delete socialObj.text.text;
          socialObj.text.content = getTextFromTheGroup(obj);
          if (isDraft) {
            socialObj.text.duration = textCanvasObj.end - textCanvasObj.start;
            socialObj.text.start = textCanvasObj.start;
            socialObj.text.end = textCanvasObj.end;
            socialObj.text.text = textCanvasObj.content;
            socialObj.text.id = textCanvasObj.id;
            socialObj.text.isFullVideoLength =
              textCanvasObj.isFullVideoLength || false;
          }
        }
      }
    }
    socials.push(socialObj);
  }

  return socials;
};

export const getUserUploadedImageProperties = async (
  fabricObj: any,
  templateHeightWidth: any,
  logoRef: any,
  assetTag: any
) => {
  const images: any = [];

  for (const obj of fabricObj.getObjects()) {
    if (obj.type === ImageType.USER_UPLOADED && obj.assetTag === assetTag) {
      obj.file = logoRef[obj.id]?.file;
      obj.assetType = logoRef[obj.id]?.assetType;
      obj.assetTag = logoRef[obj.id]?.assetTag;
      obj.url = logoRef[obj.id]?.url;
      const imgProps = await getImageProperties(obj, templateHeightWidth);
      images.push(imgProps);
    }
  }

  return images;
};

export const getUserUploadedBRollsProperties = async ({
  templateHeightWidth,
  bRollsRef,
  assetTag,
  isTemplate = false,
  isDraft = false,
}: {
  templateHeightWidth: any;
  bRollsRef: any;
  assetTag: any;
  isTemplate?: boolean;
  isDraft?: boolean;
}) => {
  //  isTemplate is true when we are getting bRolls for template
  // For Now we can not save video Brolls in template
  const bRolls: any = [];
  const bRollsArr: any[] = isTemplate
    ? Object.values(bRollsRef).filter(
        (obj: any) => obj.assetType !== SimpleAssetType.VIDEO
      )
    : Object.values(bRollsRef).map((obj) => obj);
  // const bRollsArr: any[] = Object.values(bRollsRef).map((obj) => obj);

  for (const obj of bRollsArr) {
    if (obj.assetTag === assetTag) {
      obj.file = obj?.file;
      obj.assetType = obj?.assetType;
      obj.assetTag = obj?.assetTag;
      obj.url = obj?.url;
      const imgProps = await getImageProperties(obj.asset, templateHeightWidth);
      imgProps.metaData = {
        ...obj.metaData,
        isTemplate: isTemplate,
        isDraft: isDraft,
      };
      imgProps.url = obj.url;
      imgProps.assetType = obj.assetType;
      imgProps.assetTag = obj.assetTag;
      bRolls.push(imgProps);
    }
  }

  return bRolls;
};

export const getTextsProperties = (
  templateHeightWidth: any,
  textsObj: any,
  textFabricObj: any
) => {
  const textsProperties: any = [];
  const canvasTexts = Object.values(textsObj);

  for (let index = 0; index < canvasTexts.length; index++) {
    const textCanvasObj: any = canvasTexts[index];

    if (!textCanvasObj.isSocialText) {
      const textProps = getTextPropertiesFromTextStyle(
        textCanvasObj.style,
        templateHeightWidth
      );
      const textConfiguration = textsObj[textCanvasObj.id];
      const textObj = textFabricObj[textCanvasObj.id];
      textProps.duration = textConfiguration.end - textConfiguration.start;
      textProps.start = textConfiguration.start;
      textProps.end = textConfiguration.end;
      textProps.text = textConfiguration.content;
      textProps.id = textCanvasObj.id;
      textProps.isFullVideoLength =
        textConfiguration.isFullVideoLength || false;
      if (textObj) {
        textProps.maxHeight = textObj.height;
      }
      textsProperties.push(textProps);
    }
  }
  return textsProperties;
};

export const getVideosProperties = (
  fabricObj: any,
  fabricObjects: any,
  hasTwoFace: boolean,
  isAdmin = false
) => {
  const videosProperties: any = [];
  const clipPath: any = getVideoClipPath(
    fabricObj,
    fabricObjects.videoElUp,
    isAdmin
  );
  videosProperties.push({
    clipPath,
  });
  if (hasTwoFace) {
    const clipPath: any = getVideoClipPath(
      fabricObj,
      fabricObjects.videoElDown,
      isAdmin
    );
    videosProperties.push({
      clipPath,
    });
  }

  return videosProperties;
};

export const getOutro = (outro: any) => {
  if (outro) {
    return {
      url: outro.url,
      type: outro.type || "Image",
      duration: outro.duration || DEFAULT_OUTRO_LENGTH_IN_SEC,
    };
  }
  return null;
};

export const getIntro = () => {
  // TODO
  return null;
};

export const modifyVideoPropertiesForCutMagic = (videoProps: any[]) => {
  if (videoProps.length < 2) return videoProps;
  let firstProps = videoProps[0];
  firstProps.clipPath = {
    ...firstProps.clipPath,
    height: firstProps.clipPath.height * 2,
  };
  return [firstProps];
};

const updateTemplateWithNewStyles = (template: any) => {
  const newData = { ...template };

  if (newData.subtitle) {
    newData.subtitle = updateStylesWithNewProperties({ ...newData.subtitle });
  }

  newData.socials = newData.socials?.map((social: any) => {
    const newSocial = { ...social };

    if (newSocial.text) {
      newSocial.text = updateStylesWithNewProperties({ ...newSocial.text });
    }
    return newSocial;
  });

  newData.texts = newData.texts.map((text: any) => {
    text = text ? updateStylesWithNewProperties({ ...text }) : text;
    return text;
  });
  return newData;
};

export const updatedUserBaseTemplatesWithNewStyles = (baseTemplates: any) => {
  let newBaseTemplates = {};

  layoutsArray.forEach((layout) => {
    newBaseTemplates = {
      ...newBaseTemplates,
      [layout]: Object.values(baseTemplates[layout]).map((template: any) => {
        return updateTemplateWithNewStyles(template);
      }),
    };
  });

  return newBaseTemplates;
};

// these functions can be composed
export const clearFabricElementCache = (
  fbElement: fabric.Textbox | fabric.Group
) => {
  if (fbElement instanceof fabricjs.Textbox) {
    (fbElement as fabric.Textbox)._clearCache();
  } else if (fbElement instanceof fabricjs.Group) {
    (fbElement as fabric.Group).forEachObject((obj: any) => {
      obj._clearCache?.();
    });
  }
};

export const setFabricElementTextProperties = (
  fbElement: fabric.Textbox | fabric.Group,
  properties: Partial<fabric.Textbox>
) => {
  if (
    fbElement instanceof fabricjs.Textbox ||
    fbElement instanceof fabricjs.Text
  ) {
    (fbElement as fabric.Textbox).set(properties);
  } else if (fbElement instanceof fabricjs.Group) {
    (fbElement as fabric.Group).forEachObject((obj: any) => {
      if (obj instanceof fabricjs.Textbox) {
        Object.keys(properties).forEach((key) => {
          // @ts-expect-error not typed properly
          obj.set(key, properties[key]);
        });
      }
      if (obj instanceof fabricjs.Group) {
        const updatedGroupedText = updateGroupedText(
          obj,
          properties as unknown as GroupedTextProps
        );
      }
    });
  }
};

export const setFabricElementRectProperties = (
  fbElement: fabric.Textbox | fabric.Group,
  properties: Partial<fabric.Rect>
) => {
  if (fbElement instanceof fabricjs.Textbox) {
    return;
  } else if (fbElement instanceof fabricjs.Group) {
    (fbElement as fabric.Group).forEachObject((obj) => {
      if (obj instanceof fabricjs.Rect) {
        Object.keys(properties).forEach((key) => {
          // @ts-expect-error no typed properly
          obj.set(key, properties[key]);
        });
      }
    });
  }
};

type textProperties =
  | "fontSize"
  | "text"
  | "maxHeight"
  | "top"
  | "_text"
  | "fill"
  | "fontFamily"
  | "textAlign"
  | "underline"
  | "fontWeight"
  | "textLines"
  | "lineHeight"
  | "fontStyle"
  | "width";
type wrapperProperties = "scaleX" | "scaleY" | "height";

// convert wrapper properties into array programmaticaly instead of hardcoding it

export const getFabricElementTextProperties = (
  fbElement: fabric.Textbox | fabric.Group,
  properties: (textProperties | wrapperProperties)[]
): Record<string, any> => {
  let foundProperties = {};
  if (
    fbElement instanceof fabricjs.Textbox ||
    fbElement instanceof fabricjs.Text
  ) {
    properties.forEach((property) => {
      foundProperties = {
        ...foundProperties,
        [property]: (fbElement as fabric.Textbox).get(property as any),
      };
    });
  } else if (fbElement instanceof fabricjs.Group) {
    (fbElement as fabric.Group).forEachObject((obj: any) => {
      if (obj instanceof fabricjs.Textbox) {
        properties.forEach((property) => {
          foundProperties = {
            ...foundProperties,
            [property]: obj.get(property),
          };
        });
      }
    });
  }
  return foundProperties;
};

export const getWrapperProperties = (
  fbElement: fabric.Textbox | fabric.Group,
  properties: (textProperties | wrapperProperties)[]
) => {
  let foundProperties = {};
  if (fbElement instanceof fabricjs.Textbox) {
    properties.forEach((property) => {
      foundProperties = {
        ...foundProperties,
        [property]: (fbElement as fabric.Textbox).get(property as any),
      };
    });
  } else if (fbElement instanceof fabricjs.Group) {
    (fbElement as fabric.Group).forEachObject((obj) => {
      if (obj instanceof fabricjs.Rect) {
        properties.forEach((property) => {
          foundProperties = {
            ...foundProperties,
            [property]: obj.get(property as any),
          };
        });
      }
    });
  }
  return foundProperties;
};

export const fabricElementFunctionExecutor = (
  fbElement: any,
  func: string,
  ...args: any[]
) => {
  if (fbElement instanceof fabricjs.Textbox) {
    fbElement[func](...args);
  } else if (fbElement instanceof fabricjs.Group) {
    fbElement.forEachObject((obj: any) => {
      if (obj instanceof fabricjs.Textbox) {
        obj[func](...args);
      }
      if (obj instanceof fabricjs.Group) {
        obj.forEachObject((item: any) => {
          if (item instanceof fabricjs.Text && item[func]) {
            item[func](...args);
          }
        });
      }
    });
  }
};

export const setProperties = (fabricEl: any, properties: any) => {
  Object.keys(properties).forEach((key) => {
    fabricEl.set(key, properties[key]);
  });
};

export const getFontSizeFromGroupText = (group: fabric.Group): number => {
  let fontSize: number = 0;
  group.forEachObject((obj) => {
    if (obj instanceof fabricjs.Group) {
      // @ts-expect-error meta not defined
      fontSize = obj.meta.fontSize;
    }
  });
  return fontSize;
};

export const getWidthFromGroupText = (group: fabric.Group): number => {
  let width: number = 0;
  group.forEachObject((obj) => {
    if (obj instanceof fabricjs.Group) {
      // @ts-expect-error meta not defined
      width = obj.meta.width;
    }
  });
  return width;
};

export const getTextFromTheGroup = (group: fabric.Group): string => {
  let text: string = "";
  group.forEachObject((obj) => {
    if (obj instanceof fabricjs.Group) {
      // @ts-expect-error meta not defined
      text = obj.meta.text;
    }
  });
  return text;
};
